import { FolderLine } from '@dropbox/dig-icons/assets';
import { getConnectorDisplayNameFromConnectorInfo } from '@mirage/shared/connectors';
import { getLocalFileTimestamp } from '../util/resultUtil';
import { MetadataItem } from './MetadataItem';
import { TruncatedFilePath } from './TruncatedFilePath';

import type { SearchResult } from '@mirage/service-dbx-api';

type LocalFileMetadataProps = {
  result: SearchResult;
};

export const LocalFileMetadata = ({ result }: LocalFileMetadataProps) => {
  const appName = getConnectorDisplayNameFromConnectorInfo(
    result.connectorInfo,
  );
  const timestamp = getLocalFileTimestamp(result);
  const pathSegments = result?.desktopLocalFileMetadata?.pathSegments || [];

  return (
    <>
      {appName && <MetadataItem title={appName} />}
      {pathSegments.length > 0 && (
        <MetadataItem
          title={<TruncatedFilePath pathSegments={pathSegments} />}
          iconSrc={FolderLine}
        />
      )}
      {timestamp && <MetadataItem title={timestamp} />}
    </>
  );
};
