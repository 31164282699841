import { dash } from '@dropbox/api-v2-client';
import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';

import type { SearchResult } from '@mirage/service-dbx-api';

export type CalendarEventState = 'in_progress' | 'upcoming' | 'not_relevant';

export const getMinutesAndHoursUntilOrFromStartOfEvent = (
  event: dash.SearchResult | SearchResult,
  eventState: CalendarEventState,
): { hours: number; minutes: number } => {
  const currentTime = Date.now();
  const startTime =
    (event as dash.SearchResult)?.start_time ??
    (event as SearchResult)?.startTime ??
    0;
  // If the event has already started, get the time since
  let minutes = 0;
  let hours = 0;
  if (eventState === 'in_progress') {
    minutes = Math.max(
      Math.round((currentTime - startTime) / ONE_MINUTE_IN_MILLIS),
      0,
    );
  } else if (eventState === 'upcoming') {
    minutes = Math.max(
      Math.round((startTime - currentTime) / ONE_MINUTE_IN_MILLIS),
      0,
    );
  }

  if (minutes > 60) {
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
  }

  return { hours, minutes };
};

export const getEventTimeString = (
  eventState: CalendarEventState,
  hours: number,
  minutes: number,
) => {
  // Don't show 0 minutes until or ago
  switch (eventState) {
    case 'in_progress':
      if (hours === 0 && minutes === 0) {
        return i18n.t('now');
      }
      return hours > 0
        ? i18n.t('hours_and_minutes_ago', {
            hours: hours,
            minutes: minutes,
          })
        : i18n.t('minutes_ago', {
            minutes: minutes,
          });
    case 'upcoming':
      if (hours === 0 && minutes === 0) {
        minutes = 1;
      }
      return hours > 0
        ? i18n.t('hours_and_minutes_until', {
            hours: hours,
            minutes: minutes,
          })
        : i18n.t('minutes_until', {
            minutes: minutes,
          });
  }
};
