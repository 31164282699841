import { dash_feed } from '@dropbox/api-v2-client';
import { ActivityFeedFilters } from '../types';

export const getActivityObjectType = (activity: dash_feed.ActivityItem) => {
  return activity.object?.details?.object_details?.['.tag'];
};

export const isStackActivityObject = (
  activity: dash_feed.ActivityItem,
): boolean => {
  return getActivityObjectType(activity) === 'stack';
};

// Returns true if activity feed filters are set, false otherwise
export const areFiltersSet = (filters: ActivityFeedFilters): boolean => {
  if (filters.isOnlyMine) {
    return true;
  }

  if (filters.applications) {
    return true;
  }

  if (filters.actor) {
    return true;
  }

  return false;
};
