import { Avatar } from '@dropbox/dig-components/avatar';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AnalyticsLine,
  AppsLine,
  CommentLine,
  DownloadLine,
  EncryptedLine,
  FlagLine,
  HelpLine,
  LockLine,
  PersonLine,
  PhoneSupportLine,
  SettingsLine,
  SignOutLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Click_DashAvatarOption } from '@mirage/analytics/events/types/click_dash_avatar_option';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { AvatarDropdown } from '@mirage/search/General/Avatar';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { openURL } from '@mirage/service-platform-actions';
import { getAbbreviatedFullName, getInitials } from '@mirage/shared/account';
import { openManageCookiesPanel } from '@mirage/shared/privacy';
import {
  CANNY_FEEDBACK,
  DESKTOP_DOWNLOAD_URL,
  HELP_CENTER,
  MANAGE_ACCOUNT_URL,
  PRIVACY_AND_LEGAL,
  SUPPORT_URL,
} from '@mirage/shared/urls';
import { isDevBuildChannel } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import { useAtomValue } from 'jotai';
import { ReactNode } from 'react';
import { navigationAvatarAtom } from './atoms';

import type { OverlayPlacement } from '@dropbox/dig-components/overlay';
import type { DropdownItem } from '@mirage/search/General/Dropdown';

type Props = {
  expanded?: boolean;
  menuPlacement?: OverlayPlacement;
  rightAccessory?: ReactNode;
  onApps?: () => void;
  onSettings?: () => void;
  onFeatures?: () => void;
  onDeveloper?: () => void;
  onSignOut?: () => void;
};

export default function NavigationAvatar({
  expanded = false,
  menuPlacement = 'bottom-end',
  rightAccessory,
  onApps,
  onSettings,
  onFeatures,
  onDeveloper,
  onSignOut,
}: Props) {
  const account = useDropboxAccount();
  const { showSettings, showLogout, showPrivacyBanner, showDownloadDesktop } =
    useAtomValue(navigationAvatarAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const inAppFeedbackEnabled = convertFeatureValueToBool(
    useFeatureFlagValue(`dash_2024_06_14_app_feedback`),
  );

  const menuSections: DropdownItem[][] = [];

  if (account) {
    menuSections.push([
      {
        key: 'avatar-profile',
        title: getAbbreviatedFullName(
          account?.name?.given_name,
          account?.name?.surname,
        ),
        subtitle: account?.email,
        leftAccessory: (
          <Avatar src={account?.profile_photo_url} hasNoOutline={true}>
            {getInitials(account?.name?.given_name, account?.name?.surname)}
          </Avatar>
        ),
      },
    ]);
  }

  const mainSection: DropdownItem[] = [];
  if (onApps) {
    mainSection.push({
      key: 'apps',
      title: i18n.t('apps'),
      leftAccessory: <UIIcon src={AppsLine} />,
      onClick: () => onApps(),
      analyticsAttr: {
        'data-uxa-log': createUxaElementId('apps_button', {
          actionSurfaceComponent: 'avatar_dropdown',
        }),
      },
    });
  }

  if (onSettings && showSettings) {
    mainSection.push({
      key: 'settings',
      title: i18n.t('open_settings'),
      leftAccessory: <UIIcon src={SettingsLine} />,
      onClick: () => onSettings(),
      analyticsAttr: {
        'data-uxa-log': createUxaElementId('settings_button', {
          actionSurfaceComponent: 'avatar_dropdown',
        }),
      },
    });
  }

  if (onFeatures) {
    mainSection.push({
      key: 'features',
      title: (
        <span style={{ color: 'var(--dig-color__primary__base)' }}>
          Features
        </span>
      ),
      leftAccessory: (
        <span style={{ color: 'var(--dig-color__primary__base)' }}>
          <UIIcon src={FlagLine} />
        </span>
      ),
      onClick: () => onFeatures(),
    });
  }

  if (onDeveloper) {
    mainSection.push({
      key: 'developer',
      title: (
        <span style={{ color: 'var(--dig-color__primary__base)' }}>
          Developer
        </span>
      ),
      leftAccessory: (
        <span style={{ color: 'var(--dig-color__primary__base)' }}>
          <UIIcon src={AnalyticsLine} />
        </span>
      ),
      onClick: () => onDeveloper(),
    });
  }

  if (showDownloadDesktop) {
    mainSection.push({
      key: 'download_desktop',
      title: i18n.t('download_desktop'),
      leftAccessory: <UIIcon src={DownloadLine} />,
      onClick: () => {
        reportPapEvent(
          PAP_Click_DashAvatarOption({
            dashAvatarOptionType: 'download_desktop',
          }),
        );
        openURL(DESKTOP_DOWNLOAD_URL);
      },
      analyticsAttr: {
        'data-uxa-log': createUxaElementId('download_desktop_button', {
          actionSurfaceComponent: 'avatar_dropdown',
        }),
      },
    });
  }

  if (mainSection && mainSection.length > 0) {
    menuSections.push(mainSection);
  }

  let menuActions: Array<DropdownItem> = [];

  menuActions.push({
    key: 'manage-account',
    title: i18n.t('manage_account'),
    leftAccessory: <UIIcon src={PersonLine} />,
    onClick: () => {
      reportPapEvent(
        PAP_Click_DashAvatarOption({
          dashAvatarOptionType: 'manage_account',
        }),
      );
      openURL(MANAGE_ACCOUNT_URL);
    },
    analyticsAttr: {
      'data-uxa-log': createUxaElementId('manage_account_button', {
        actionSurfaceComponent: 'avatar_dropdown',
      }),
    },
  });

  menuActions.push({
    key: 'help-center',
    title: i18n.t('settings_visit_help_center'),
    leftAccessory: <UIIcon src={HelpLine} />,
    onClick: () => {
      reportPapEvent(
        PAP_Click_DashAvatarOption({
          dashAvatarOptionType: 'help_center',
        }),
      );
      openURL(HELP_CENTER);
    },
    analyticsAttr: {
      'data-uxa-log': createUxaElementId('help_center_button', {
        actionSurfaceComponent: 'avatar_dropdown',
      }),
    },
  });
  if (!inAppFeedbackEnabled) {
    menuActions.push({
      key: 'feedback',
      title: i18n.t('settings_send_feedback'),
      leftAccessory: <UIIcon src={CommentLine} />,
      onClick: () => {
        reportPapEvent(
          PAP_Click_DashAvatarOption({
            dashAvatarOptionType: 'send_feedback',
          }),
        );
        openURL(CANNY_FEEDBACK);
      },
      analyticsAttr: {
        'data-uxa-log': createUxaElementId('feedback_button', {
          actionSurfaceComponent: 'avatar_dropdown',
        }),
      },
    });
  }

  menuActions.push({
    key: 'support',
    title: i18n.t('settings_get_support'),
    leftAccessory: <UIIcon src={PhoneSupportLine} />,
    onClick: () => {
      reportPapEvent(
        PAP_Click_DashAvatarOption({
          dashAvatarOptionType: 'get_support',
        }),
      );
      openURL(SUPPORT_URL);
    },
    analyticsAttr: {
      'data-uxa-log': createUxaElementId('support_button', {
        actionSurfaceComponent: 'avatar_dropdown',
      }),
    },
  });

  menuActions.push({
    key: 'privacy',
    title: i18n.t('settings_privacy_policy'),
    leftAccessory: <UIIcon src={LockLine} />,
    onClick: () => {
      reportPapEvent(
        PAP_Click_DashAvatarOption({
          dashAvatarOptionType: 'privacy_policy',
        }),
      );
      openURL(PRIVACY_AND_LEGAL);
    },
    analyticsAttr: {
      'data-uxa-log': createUxaElementId('privacy_policy_button', {
        actionSurfaceComponent: 'avatar_dropdown',
      }),
    },
  });

  if (!isDevBuildChannel(EnvCtx.buildChannel) && showPrivacyBanner) {
    // Only display in non-dev web app with stormcrow enabled.
    menuActions = [
      ...menuActions,
      {
        key: 'manage_cookies',
        title: i18n.t('manage_cookies'),
        leftAccessory: <UIIcon src={EncryptedLine} />,
        onClick: () => {
          reportPapEvent(
            PAP_Click_DashAvatarOption({
              dashAvatarOptionType: 'cookies',
            }),
          );
          openManageCookiesPanel();
        },
      },
    ];
  }

  if (onSignOut && showLogout) {
    menuActions = [
      ...menuActions,
      {
        key: 'logout',
        title: i18n.t('logout'),
        leftAccessory: <UIIcon src={SignOutLine} />,
        onClick: () => {
          reportPapEvent(
            PAP_Click_DashAvatarOption({
              dashAvatarOptionType: 'log_out',
            }),
          );
          onSignOut();
        },
        analyticsAttr: {
          'data-uxa-log': createUxaElementId('logout_button', {
            actionSurfaceComponent: 'avatar_dropdown',
          }),
        },
      },
    ];
  }

  menuSections.push(menuActions);

  return (
    <AvatarDropdown
      expanded={expanded}
      rightAccessory={rightAccessory}
      givenName={account?.name?.given_name}
      surname={account?.name?.surname}
      email={account?.email}
      photoSrc={account?.profile_photo_url}
      config={{ sections: menuSections }}
      placement={menuPlacement}
    />
  );
}
