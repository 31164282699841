import type { TransObjShape } from '@mirage/translations/types';

export default {
  file: {
    message: 'File',
    description: 'file',
  },
  show_dash: {
    message: 'Show Dash',
    description: 'menu item to show Dash',
  },
  hide_dash: {
    message: 'Hide Dash',
    description: 'menu item to hide Dash',
  },
  quit_dash: {
    message: 'Quit Dash',
    description: 'menu item to quit Dash',
  },
  check_for_updates: {
    message: 'Check for updates',
    description: 'menu item to check for updates',
  },
  navigate_to_settings: {
    message: 'Settings',
    description: 'Menu takes user to in app settings page',
  },
  developer: {
    message: 'Developer',
    description: 'menu item for the dev submenu',
  },
  toggle_product_analytics_log: {
    message: 'Toggle Product Analytics Log',
    description: 'menu item to toggle product analytics log',
  },
  package_log_files: {
    message: 'Export Log Files',
    description: 'menu item to export log files',
  },
  update_not_available_title: {
    message: 'Application Up to Date',
    description: 'toast message when no updates are available',
  },
  update_not_available_body: {
    message: 'No updates available',
    description: 'toast message when no updates are available',
  },
  login_authenticating_error: {
    message: 'Unfortunately, we ran into an error with authentication',
    description: 'Status displayed to user after error authenticating',
  },
  login_networking_error: {
    message: 'Unfortunately, we ran into an error with networking',
    description: 'Status displayed to user after having a networking error',
  },
  local_file_settings_preferences_section: {
    message: 'Preferences',
    description: 'Local file settings preferences section header',
  },
  local_file_settings_system_section: {
    message: 'System folders',
    description: 'Local file settings system folders section header',
  },
  local_file_settings_user_section: {
    message: 'User folders',
    description: 'Local file settings user folders section header',
  },
  local_file_add_user_folder: {
    message: 'Add folder',
    description: 'Remove button text for local file user folders',
  },
  local_file_remove_user_folder: {
    message: 'Remove',
    description: 'Remove button text for local file user folders',
  },
  local_file_permission_denied: {
    message:
      'Permission to access {{directory}} denied. Please go to ‘System Preferences > Security & Privacy > Files and Folders’ to enable access.',
    description: "Toast message when a user doesn't have access to a directory",
  },
  enable_node_inspector: {
    message: 'Node Inspector',
    description: 'the menu item that toggles the node inspector for debugging',
  },
  search_prompt: {
    message: 'Search your content',
    description: 'Placeholder text for the search input text box',
  },
  search_placeholder_base: {
    message: 'Search',
    description: 'Prefix text for placeholder',
  },
  search_placeholder_default: {
    message: 'your content',
    description: 'Default suffix text for placeholder',
  },
  search_query_byline: {
    message: 'Search Dash',
    description: 'Placeholder text for the search input text box',
  },
  connected_apps_indicator_tooltip: {
    message: 'See all apps you can search',
    description:
      'Tooltip when hovering over the connected apps indicator in the search field',
  },
  loading: {
    message: 'Loading...',
    description: 'Generic text for when something is loading',
  },
  error_label_and_text: {
    message: 'Error: {{errorText}}',
    description: 'Error message shown when an operation fails',
  },
  search_timed_out: {
    message: 'Couldn’t reach Dash',
    description: 'Error message shown when a search times out',
  },
  no_search_results_title: {
    message: 'We couldn’t find any matches for your search',
    description:
      'User message to indicate that search did not return any results',
  },
  no_search_results_subtitle: {
    message:
      'Try changing keywords or removing some filters to get better results',
    description:
      'User message to indicate that search did not return any results',
  },
  clear_all_filters: {
    message: 'Clear all filters',
    description: 'Button text to clear all filters',
  },
  more_actions_title: {
    message: 'MORE ACTIONS',
    description: 'Title for the more actions section on search results page',
  },
  calendar_empty_text: {
    message: 'Your day is clear',
    description:
      'Text indicating that the user has no more meetings for the day',
  },
  app_updated_time_ago: {
    message: '{{appName}} · {{timeAgo}}',
    description: 'Label for an appName and how long ago it was last updated',
  },
  home: {
    message: 'Home',
    description: 'Label for the homepage',
  },
  chat: {
    message: 'Chat',
    description: 'Label for chat',
  },
  ask: {
    message: 'Ask',
    description: 'Label for ask',
  },
  stacks: {
    message: 'Stacks',
    description: 'Label for stacks',
  },
  pinned: {
    message: 'Pinned',
    description: 'Label for pinned stacks',
  },
  pinned_stacks: {
    message: 'Pinned stacks',
    description: 'Label for pinned stacks',
  },
  unpin: {
    message: 'Unpin',
    description: 'Button tooltip for unpinning a stack',
  },
  sections: {
    message: 'Sections',
    description: 'Title for stack sections card',
  },
  sections_subtitle: {
    message: 'Create sections to organize your stacks',
    description: 'Subtitle description for sections card',
  },
  add_section: {
    message: 'Add section',
    description: 'Label to create a new section',
  },
  stack_item_already_exists: {
    message: 'Item already exists in stack',
    description:
      'Notification message for item already existing in a stack when trying to add an item',
  },
  adding_stack_item: {
    message: 'Adding item to stack',
    description: 'Notification message for adding new item to a stack',
  },
  stack_item_added: {
    message: 'Item added to stack',
    description: 'Notification message for new item added to a stack',
  },
  failed_to_add_stack_item: {
    message: 'Failed to add item to stack',
    description:
      'Notification message for failing to add a new item added to a stack',
  },
  add_a_section: {
    message: 'Add a section',
    description: 'Tooltip to create a new section',
  },
  create: {
    message: 'Create',
    description: 'Label to create a new entity',
  },
  new_stack_item_added: {
    message: 'New stack item added.',
    description: 'Snackbar text showing new stack item has been added',
  },
  open_in_new_tab: {
    message: 'Open link in new tab',
    description: 'Tooltip text to open the link in a new tab',
  },
  paste_a_link: {
    message: 'Paste a link',
    description:
      'Placeholder text to ask user to paste a link into the text input',
  },
  search_link_placeholder: {
    message: 'Search for a file, doc, or link',
    description:
      'Placeholder text to ask user to enter search text to find links to add',
  },
  search_item_placeholder: {
    message: 'Search or paste a link',
    description:
      'Placeholder text to ask user to enter search text to find items to add',
  },
  icon_for_item: {
    message: 'Icon for item',
    description: 'Tooltip for a stack item’s icon',
  },
  stack_sections_limit_reached: {
    message: 'No more sections can be added',
    description:
      'Tooltip for a disable button to say that no more stack sections can be added',
  },
  recent: {
    message: 'Recent',
    description: 'Label for recent stacks',
  },
  page_not_found: {
    message: 'Hmmm, that page does not exist…',
    description: 'Message to show user when a requested page does not exist',
  },
  signin_cta: {
    message: 'Sign in with Dropbox',
    description: 'Label for the sign in button',
  },
  or_create_account_cta: {
    message: 'or <0>create an account</0>',
    description:
      "Label for the create account button. The text goes like 'Sign in with Dropbox <newline> or <a>create an account</a>.'",
  },
  or_create_or_choose_account_cta: {
    message: 'or <0>create/choose an account to use</0>',
    description:
      "Label for the choose account button. The text goes like 'Sign in with Dropbox <newline> or <a>create/choose an account to use</a>.'",
  },
  sign_out: {
    message: 'Sign out',
    description: 'Label for the sign out button',
  },
  sign_in: {
    message: 'Sign in',
    description: 'Label for the sign in page',
  },
  sign_up: {
    message: 'Sign up',
    description: 'Label for public stacks button CTA to sign up',
  },
  no_meetings_syncing: {
    message: 'Syncing... Just a few seconds left',
    description:
      'Message when we have no meetings and are waiting for the calendar connectors to sync',
  },
  no_meetings_loading: {
    message: 'Loading... Just a moment',
    description:
      'Message when we have no meetings and are waiting for the app to retrieve the data',
  },
  no_meetings_all_done: {
    message: 'All done!',
    description: 'Message when no more meetings are present in the day',
  },
  no_meetings_breaktime: {
    message: 'Time for a break.',
    description: 'Message when no more meetings are present in the day',
  },
  all_day_events_one: {
    message: '{{count}} all day event',
    description: 'The number of all day events to view',
  },
  all_day_events_few: {
    message: '{{count}} all day events',
    description: 'The number of all day events to view',
  },
  all_day_events_many: {
    message: '{{count}} all day events',
    description: 'The number of all day events to view',
  },
  all_day_events_other: {
    message: '{{count}} all day events',
    description: 'The number of all day events to view',
  },
  show_all_day_events: {
    message: 'Show all day events',
    description: "Button tooltip text to show the user's all day events",
  },
  hide_all_day_events: {
    message: 'Hide all day events',
    description: "Button tooltip text to show the user's all day events",
  },
  up_next: {
    message: 'Up next',
    description: 'Title for the calendar module',
  },
  upcoming_meetings: {
    message: 'Upcoming Meetings',
    description: 'Text for upcoming meetings on the meeting module',
  },
  upcoming_events: {
    message: 'Upcoming events',
    description: 'Text for upcoming events on the calendar module',
  },
  open_in_calendar_app: {
    message: 'Open in calendar',
    description: 'Button text for viewing a calendar event in the calendar app',
  },
  join_meeting: {
    message: 'Join meeting',
    description: 'Button text for opening a meeting link',
  },
  join_meeting_short: {
    message: 'Join',
    description: 'Button text for opening a meeting link',
  },
  now: {
    message: 'Now',
    description: 'Label for the current time',
  },
  today: {
    message: 'Today',
    description: 'Label for the current day',
  },
  minutes_remaining: {
    message: '{{minutes}} min left',
    description: 'Minutes remaining in a meeting',
  },
  hours_and_minutes_remaining: {
    message: '{{hours}} hours {{minutes}} min left',
    description: 'Hours and minutes remaining in a meeting',
  },
  hours_and_minutes_until: {
    message: 'in {{hours}} hours {{minutes}} min',
    description: 'Hours and minutes until a meeting',
  },
  minutes_until: {
    message: 'in {{minutes}} min',
    description: 'minutes until a meeting',
  },
  hours_and_minutes_ago: {
    message: '{{hours}} hours {{minutes}} min ago',
    description: 'hours and minutes since a meeting started',
  },
  minutes_ago: {
    message: '{{minutes}} min ago',
    description: 'minutes since a meeting started',
  },
  all_day: {
    message: 'All day, {{day}}',
    description: 'Text for an all day event',
  },
  all_day_multi_day: {
    message: 'All day, {{start}} - {{end}}',
    description: 'Text for an all day event that spans multiple days',
  },
  meeting_times: {
    message: '{{startTime}} - {{endTime}}',
    description: 'Button text for opening a meeting link',
  },
  updated_less_than_one_minute: {
    message: 'Updated less than one minute ago',
    description: 'Message for time elapsed',
  },
  updated_minutes_ago_one: {
    message: 'Updated {{count}} minute ago',
    description: 'Message for time elapsed',
  },
  updated_minutes_ago_other: {
    message: 'Updated {{count}} minutes ago',
    description: 'Message for time elapsed',
  },
  updated_hours_ago_one: {
    message: 'Updated {{count}} hour ago',
    description: 'Message for time elapsed',
  },
  updated_hours_ago_few: {
    message: 'Updated {{count}} hours ago',
    description: 'Message for time elapsed',
  },
  updated_hours_ago_many: {
    message: 'Updated {{count}} hours ago',
    description: 'Message for time elapsed',
  },
  updated_hours_ago_other: {
    message: 'Updated {{count}} hours ago',
    description: 'Message for time elapsed',
  },
  updated_days_ago_one: {
    message: 'Updated {{count}} day ago',
    description: 'Message for time elapsed',
  },
  updated_days_ago_few: {
    message: 'Updated {{count}} days ago',
    description: 'Message for time elapsed',
  },
  updated_days_ago_many: {
    message: 'Updated {{count}} days ago',
    description: 'Message for time elapsed',
  },
  updated_days_ago_other: {
    message: 'Updated {{count}} days ago',
    description: 'Message for time elapsed',
  },
  updated_ago: {
    message: 'Updated {{timeAgo}}',
    description: 'Message for time elapsed',
  },
  only_you: {
    message: 'Only you',
    description:
      'Text indicating that the user is the only person with access to this stack.',
  },
  shared_with_you_two_people: {
    message: 'Shared with you & {{otherMemberName}}',
    description: 'Text enumerating two members on a stack.',
  },
  shared_with_you_n_people: {
    message: 'Shared with {{otherMemberName}} & {{numOthers}} others',
    description: 'Text enumerating all of the members on a stack.',
  },
  shared_with: {
    message: 'Shared with {{name}}',
    description: 'Text indicating who a stack was just shared with',
  },
  undo: {
    message: 'Undo',
    description: 'Button text for undoing an action.',
  },
  shared: {
    message: 'Shared',
    description: 'Text indicating that a stack has been shared.',
  },
  people: {
    message: 'people',
    description:
      'text for the filter chip allowing the user to filter stacks by owners',
  },
  latest: {
    message: 'latest',
    description: 'text for the filter chip allowing the user to sort stacks',
  },
  all_stacks: {
    message: 'All stacks',
    description: 'text for the filter chip allowing the user to filter stacks',
  },
  shared_with_me: {
    message: 'Shared with me',
    description: 'text for the filter chip allowing the user to filter stacks',
  },
  my_stacks: {
    message: 'My stacks',
    description: 'text for the filter chip allowing the user to filter stacks',
  },
  archived: {
    message: 'Archived',
    description:
      'Text for the filter chip allowing the user to filter stacks and only show the archived ones',
  },
  created_by: {
    message: 'Created by',
    description:
      'Text label shown before the owner chip in a stack header subtitle',
  },
  created_by_dropbox_dash: {
    message: 'Created for you by Dropbox Dash',
    description:
      'Text label shown in a stack header subtitle when the stack is a welcome stack',
  },
  back: {
    message: 'Back',
    description:
      'Button shown on top of a full screen view of a stack allowing the user to th return to the list of stacks',
  },
  change_stack_icon: {
    message: 'Change stack icon',
    description:
      'Tooltip text for trigger to change the stack icon to an emoji',
  },
  copy_link: {
    message: 'Copy link',
    description: 'Text for copying a sharing link of a stack or stack item.',
  },
  summarize: {
    message: 'Ask about file',
    description: 'Text for asking Dash to summarize a result item',
  },
  share: {
    message: 'Share',
    description: 'Tooltip text for sharing a stack.',
  },
  edit: {
    message: 'Edit',
    description: 'Button label that will edit the pdf file.',
  },
  pin: {
    message: 'Pin',
    description: 'Menu label for pinning a stack',
  },
  pin_stack: {
    message: 'Pin stack',
    description: 'Action button for pinning a new stack',
  },
  unpin_stack: {
    message: 'Unpin stack',
    description: 'Action button for pinning a new stack',
  },
  delete: {
    message: 'Delete',
    description: 'Button label that will delete a file.',
  },
  answers_input_placeholder: {
    message: 'Ask a question',
    description:
      'Placeholder for the input field in answers explicit conversation',
  },
  answers_input_placeholder_attachment: {
    message: 'Ask about this content',
    description:
      'Placeholder for the input field in answers explicit conversation with an attachment',
  },
  answers_empty_title: {
    message: 'Get answers about your work',
    description:
      'Title shown on the empty state of answers explicit conversation',
  },
  answers_empty_subtitle: {
    message:
      'Dash searches your connected apps to write summarized answers to your questions.',
    description:
      'Subtitle shown on the empty state of answers explicit conversation',
  },
  answers_summarize_query: {
    message: 'Please summarize this.',
    description:
      'The prompt that will be shown to user for summarizing document',
  },
  answers_disclaimer_text: {
    message:
      'This experimental feature is powered by third-party AI and information quality may vary.',
    description: 'Disclaimer text shown on answers',
  },
  was_this_helpful: {
    message: 'Was this helpful?',
    description: 'Notice beside answer feedback buttons',
  },
  learn_more: {
    message: 'Learn more',
    description: 'Label for learn more',
  },
  stack_open_emoji_picker: {
    message: 'Open stack emoji picker',
    description: 'Label for the button that open the stack emoji picker',
  },
  beta: {
    message: 'Beta',
    description: 'Label for beta features',
  },
  network_error_title: {
    message: 'Could not connect to the Internet',
    description: 'Modal title when Dash is unable to connect to the network',
  },
  network_error_message: {
    message: 'Please check your Internet connection and try again.',
    description: 'Modal message when Dash is unable to connect to the network',
  },
  user_ineligible_title: {
    message: 'Dash not available',
    description: 'Modal title when user is ineligible to use Dash',
  },
  user_ineligible_message: {
    message:
      'Sorry, Dash is currently not available due to the following reason:',
    description: 'Modal message when user is ineligible to use Dash',
  },
  user_ineligible_button: {
    message: 'Leave Dash app',
    description: 'Modal button to leave the Dash app',
  },
  answers_header_title: {
    message: 'Answers',
    description: 'Title shown on the header of answers card',
  },
  answers_feedback_incorrect: {
    message: 'Incorrect Answer',
    description: 'Option shown on answers feedback modal',
  },
  answers_feedback_incorrect_sources: {
    message: 'Irrelevant Sources',
    description: 'Option shown on answers feedback modal',
  },
  answers_feedback_misunderstood: {
    message: 'Misunderstood My Question',
    description: 'Option shown on answers feedback modal',
  },
  answers_source_logo_alt: {
    message: '{{connectorName}} logo',
    description: 'Alt text for image for answer source',
  },
  customize_modal_title: {
    message: 'Customize your start page',
    description: 'Modal title for customizing the start page',
  },
  on: {
    message: 'On',
    description: 'Label to indicate some setting is turned on',
  },
  off: {
    message: 'Off',
    description: 'Label to indicate some setting is turned off',
  },
  toggle_setting: {
    message: 'Toggle setting',
    description: 'Tooltip text for a control to turn a setting on or off',
  },
  new_stack_suggestions: {
    message: 'New stack Suggestions',
    description: 'Title for new stack suggestions',
  },
  new_stack_suggestions_customize_description: {
    message:
      'Dynamically generated stacks based on the content you access and search for in Dash.',
    description: 'Description for customizing the new stack suggestions',
  },
  get_started: {
    message: 'Get started',
    description: 'Label for get started',
  },
  ask_dash: {
    message: 'Ask Dash',
    description: 'Label for ask dash',
  },
  working_on_it: {
    message: 'Working on it...',
    description: 'Label for working on it loader',
  },
  copy: {
    message: 'Copy',
    description: 'Label for copy',
  },
  helpful: {
    message: 'Helpful',
    description: 'Label for marking something as helpful',
  },
  not_helpful: {
    message: 'Not helpful',
    description: 'Label for marking something as not helpful',
  },
  feedback_title: {
    message: 'Feedback',
    description: 'Title for providing feedback',
  },
  feedback_subtitle: {
    message:
      'Thanks for your feedback. Can you tell us more about why this wasn’t helpful?',
    description: 'Subtitle for providing feedback',
  },
  feedback_other_placeholder: {
    message: 'What other feedback do you have?',
    description: "Placeholder in 'other' feedback",
  },
  feedback_other_error_max_length_exceeded: {
    message: 'Feedback must be less than {{maxLength}} characters',
    description: 'Error message when feedback exceeds max length characters',
  },
  enter_query: {
    message: 'Enter a query',
    description: 'Placeholder for input field',
  },
  related_questions: {
    message: 'Related questions',
    description: 'Label for related questions',
  },
  cancel: {
    message: 'Cancel',
    description: 'Label for cancel',
  },
  show_all_sources: {
    message: 'Show all sources...',
    description: 'Label for show all sources button',
  },
  summarizing: {
    message: 'Summarizing...',
    description: 'Label for summarizing loader',
  },
  mention_someone: {
    message: 'Mention someone',
    description: 'Label for mentionable input trigger',
  },
  mention_event: {
    message: 'Mention an event',
    description: 'Label for mentionable input trigger',
  },
  answers_error_message: {
    message:
      'Sorry, I wasn’t able to do that for some reason, but I’m learning how to avoid mistakes like this in the future. In the meantime, you can always try again.',
    description: 'Error message shown on answers conversation',
  },
  summarize_error_message: {
    message:
      'Sorry, I couldn’t summarize that document for some reason, but I’m learning how to avoid mistakes like this in the future.',
    description: 'Error message shown on answers summarizaton',
  },
  'no_answer_message.part1': {
    message:
      'Sorry, I couldn’t find the answer to your question. I’m either still learning about your content or don’t have access to the apps required to find the answer. Try',
    description: 'Part 1 of the message we show when no answer is provided',
  },
  'no_answer_message.part2': {
    message: 'connecting more apps',
    description: 'Part 2 of the message we show when no answer is provided',
  },
  'no_answer_message.part3': {
    message:
      'and asking again, or using the search bar to find anything I might have missed.',
    description: 'Part 3 of the message we show when no answer is provided',
  },
  'no_answer_message.part4': {
    message: ' I also tried to find a few items that might be helpful.',
    description: 'Part 4 of the message we show when no answer is provided',
  },
  num_items_zero: {
    message: 'No items',
    description: 'Number of items with plurals',
  },
  num_items_one: {
    message: '{{count}} item',
    description: 'Number of items with plurals',
  },
  num_items_few: {
    message: '{{count}} items',
    description: 'Number of items with plurals',
  },
  num_items_many: {
    message: '{{count}} items',
    description: 'Number of items with plurals',
  },
  num_items_other: {
    message: '{{count}} items',
    description: 'Number of items with plurals',
  },
  num_others_one: {
    message: '{{count}} other',
    description: 'Number of other items with plurals',
  },
  num_others_few: {
    message: '{{count}} others',
    description: 'Number of other items with plurals',
  },
  num_others_many: {
    message: '{{count}} others',
    description: 'Number of other items with plurals',
  },
  num_others_other: {
    message: '{{count}} others',
    description: 'Number of other items with plurals',
  },
  num_members_zero: {
    message: 'No members',
    description: 'Number of members with plurals',
  },
  num_members_one: {
    message: '{{count}} member',
    description: 'Number of members with plurals',
  },
  num_members_few: {
    message: '{{count}} members',
    description: 'Number of members with plurals',
  },
  num_members_many: {
    message: '{{count}} members',
    description: 'Number of members with plurals',
  },
  num_members_other: {
    message: '{{count}} members',
    description: 'Number of members with plurals',
  },
  apps: {
    message: 'Apps',
    description:
      'button user can click to view a page that displays their connected apps',
  },
  close: {
    message: 'Close',
    description: 'Label and/or arial label for the close button',
  },
  collapse: {
    message: 'Collapse',
    description:
      'Screen reader friendly label to indicate that the button is used to expand or collapse the sidebar',
  },
  expand: {
    message: 'Expand',
    description:
      'Screen reader friendly label to indicate that the button is used to expand or collapse the sidebar',
  },
  forward: {
    message: 'Forward',
    description: 'Label for forward navigation button in the header',
  },
  create_stack_modal_name_placeholder: {
    message: 'Name this stack',
    description: 'Placeholder for the stack name input',
  },
  create_stack_modal_cancel_button: {
    message: 'Cancel',
    description: 'Cancel button of the create stack button',
  },
  create_stack: {
    message: 'Create stack',
    description: 'Label to create a new stack',
  },
  add_apps: {
    message: 'Add apps',
    description: 'Button text that takes the user to the connectors page',
  },
  add_stack: {
    message: 'Add stack',
    description: 'Label to add a new stack',
  },
  search_label: {
    message: 'Search',
    description: 'Label for search button to trigger opening of search input',
  },
  search_stacks: {
    message: 'Search stacks',
    description: 'Placeholder text on an input field for searching stacks',
  },
  search_in_stack: {
    message: 'Search for content in this stack',
    description: 'Placeholder text on an input field for searching in a stack',
  },
  recent_stacks: {
    message: 'Recent stacks',
    description: 'Header text for showing recent stacks',
  },
  recents: {
    message: 'Recents',
    description: 'Header text for showing recent items',
  },
  recent_content: {
    message: 'Recent Content',
    description: 'Header text for showing recent items',
  },
  recent_content_customize_description: {
    message:
      'Files, docs, links, and stacks that you might find interesting based on your activity.',
    description: 'Message text for showing recent items',
  },
  sort: {
    message: 'Sort',
    description: 'Label for sorting',
  },
  sort_option_label: {
    message: 'Sort by',
    description: 'Label for sorting menu',
  },
  sort_option_section: {
    message: 'Section',
    description: 'Label for sorting menu item for sections sort option',
  },
  sort_option_recent: {
    message: 'Last Modified',
    description:
      'Label for sorting menu item for most recent item first sort option',
  },
  sort_option_alpha: {
    message: 'Name (A-Z)',
    description: 'Label for sorting menu item for alphabetical sort option',
  },
  sort_option_sorting_by: {
    message: 'Sorting by',
    description:
      'Label for text showing which option is being used to sort content',
  },
  sort_option_toggle_direction: {
    message: 'Toggle sort direction',
    description: 'Label for button to toggle sort direction',
  },
  sort_option_clear: {
    message: 'Clear sorting',
    description: 'Label for button to clear sort',
  },
  filter_option_label: {
    message: 'Filter by',
    description: 'Label for filtering menu',
  },
  section_management_label: {
    message: 'Section management - coming soon',
    description:
      'Label for section management icon in stack detail page toolbar',
  },
  density_option_grid_label: {
    message: 'Grid view - coming soon',
    description:
      'Label for the grid view option in the stack detail page toolbar',
  },
  density_option_list_label: {
    message: 'List view',
    description:
      'Label for the list view option in the stack detail page toolbar',
  },
  multiselect_option_label: {
    message: 'Multiselect - coming soon',
    description:
      'Label for the multiselect option in the stack detail page toolbar',
  },
  click_to_start_adding_items: {
    message: 'Click to start adding items',
    description: 'Prompt to user to choose first item to add to stack',
  },
  click_to_add_another_item: {
    message: 'Click to add another item',
    description: 'Prompt to user to choose another item to add to stack',
  },
  stack_color_picker: {
    message: 'Stack color picker',
    description: 'Label for the stack color picker.',
  },
  stack_color_picker_color: {
    message: '{{color}}',
    description: "Label for the '{{color}}' option in stack color picker.",
  },
  stack_color_picker_unknown: {
    message: 'Unknown color',
    description: 'Label for an unkown color option in stack color picker.',
  },
  modal_title: {
    message: 'Share “{{stackName}}”',
    description: 'Share modal title',
  },
  share_this_stack: {
    message: 'Share this stack',
    description:
      'Title for sharing a stack module on the full page stack screen.',
  },
  invite_someone_via_email: {
    message: 'Invite someone via email',
    description: 'Placeholder text in the sharing text field.',
  },
  share_via_email: {
    message: 'Share via email',
    description: 'Text for sharing on the sharing modal.',
  },
  sharing_ellipses: {
    message: 'Sharing...',
    description: 'Text for sharing on the sharing modal.',
  },
  suggested_collaborators: {
    message: 'Suggested collaborators',
    description: 'Text above the list of suggested collaborators.',
  },
  invite_people: {
    message: 'Invite people',
    description: 'Placeholder text for the sharing search bar',
  },
  leave: {
    message: 'Leave',
    description: 'Button text for leaving a stack',
  },
  leave_stack: {
    message: 'You left this stack. Returning to your stacks page...',
    description: 'Snackbar text for leaving a stack',
  },
  remove: {
    message: 'Remove',
    description: 'Button text for removing an item from a stack',
  },
  add: {
    message: 'Add',
    description: 'Clickable text that allows the user to add a new item',
  },
  owner: {
    message: 'Owner',
    description: 'Title for the owner of a stack.',
  },
  can_edit: {
    message: 'Can edit',
    description: 'Menu option for sharing a stack with edit permissions.',
  },
  can_view: {
    message: 'Can view',
    description: 'Menu option for sharing a stack with view permissions.',
  },
  who_can_access: {
    message: 'Link sharing',
    description: 'Text to let users know who can access stacks or stack items.',
  },
  mismatched_permissions_warning_team: {
    message:
      'This person may still be able to edit, because the stack is set to anyone in this team with the link can edit.',
    description:
      'Tooltip text for a warning icon that displays if a shared stack member has more restrictive permissions that the global stack link.',
  },
  mismatched_permissions_warning_public: {
    message:
      'This person can still edit, because the stack is set to anyone with the link can edit.',
    description:
      'Tooltip text for a warning icon that displays if a shared stack member has more restrictive permissions that the global stack link.',
  },
  link_copied2: {
    message: 'Link copied',
    description: 'A message after a link is copied.',
  },
  send: {
    message: 'Send',
    description: 'Button text for sharing a stack with specific emails.',
  },
  account: {
    message: 'Account',
    description: "Label for the user's account",
  },
  public_accesslevel: {
    message: 'Public',
    description:
      'Access level for a stack or a stack item that everybody with the link can access.',
  },
  public_accesslevel_description: {
    message: 'Anyone on the web can access the stack contents',
    description: 'Description for the public permission.',
  },
  private_accesslevel: {
    message: 'Private',
    description:
      'Access level for a stack or a stack item that only invited members can access.',
  },
  private_accesslevel_multiple_users: {
    message: 'Shared',
    description:
      'Access level for a stack or a stack item that only invited members can access when there are 2 or more members on the stack.',
  },
  private_accesslevel_description: {
    message: 'Only you can access',
    description:
      'Description for the private access level when only you have access.',
  },
  private_accesslevel_with_shares_description: {
    message: 'Only you and people invited can access the stack contents',
    description:
      'Description for the private access level when others have been invited.',
  },
  company_accesslevel: {
    message: 'Team',
    description: 'Description for the company access level.',
  },
  company_accesslevel_description: {
    message: 'Anyone at {{teamName}} can access the stack contents',
    description: 'Description for the company access level.',
  },
  company_accesslevel_description_no_team_name: {
    message: 'Anyone at your org can access the stack contents',
    description:
      'Description for the company access level with missing team name.',
  },
  failed_to_add_sharing_members: {
    message: 'Failed to add one or more emails.',
    description: 'Snackbar text after a stack share has one or more failures.',
  },
  failed_to_remove_sharing_member: {
    message: 'Failed to remove email.',
    description: 'Snackbar text after removing a member from a stack fails.',
  },
  failed_to_change_permissions: {
    message: 'Failed to change permissions.',
    description:
      'Snackbar text after changing a link or user’s permissions fails.',
  },
  send_email_notification: {
    message: 'Send email notification',
    description:
      'Helper text next to a toggle indicating if the user will send an email notification when sharing a stack.',
  },
  add_a_note: {
    message: 'Add a note',
    description:
      'Placeholder text on a text area that will be used to send custom text when sharing a stack.',
  },
  dismiss: {
    message: 'Dismiss',
    description: 'Text on button to dismiss something',
  },
  feedback_received: {
    message: 'Thanks for your feedback! Your responses help Dash improve.',
    description: 'Text shown after user provides feedback',
  },
  feedback_failed: {
    message: 'Failed to submit feedback. Please try again later.',
    description: 'Text shown after feedback is unable to send',
  },
  for_you: {
    message: 'For You',
    description: 'Title of the for you module',
  },
  copied_answer: {
    message: 'Copied answer to clipboard',
    description: 'Text shown after user copies an answer',
  },
  answers_ftu_title: {
    message: 'Save time with Q&A',
    description: 'Title for the answers first time use tooltip',
  },
  answers_ftu_text: {
    message:
      'Instead of searching through all your files, ask a question about your work.',
    description: 'Text for the answers first time use tooltip',
  },
  answers_ftu_button: {
    message: 'Got it',
    description: 'Button text for the answers first time use tooltip',
  },
  create_stack_suggested_items_spinner_text: {
    message: 'Finding related items...',
    description:
      'Placeholder text for loading spinner while fetching related content on create stack modal',
  },
  create_stack_suggested_items_empty_text: {
    message: 'Give this stack a name to get content suggestions',
    description:
      'Placeholder text for suggested items section when no content available',
  },
  stack_item_typeahead_subtitle_badge: {
    message: 'Stacks',
    description: 'Text for subtitle badge for stack items in typeahead',
  },
  suggested_stacks_title: {
    message: 'Stacks for you',
    description: 'Title text for suggested stacks',
  },
  suggested_stacks_subtitle: {
    message: 'Pre-made stacks based on your work',
    description: 'Subtitle text for suggested stacks',
  },
  add_description: {
    message: 'Add a description...',
    description: 'Placeholder text for adding a description to a stack item',
  },
  add_link_description: {
    message: 'Add link description',
    description: 'Tooltip text for adding a description to a link',
  },
  expand_link_description: {
    message: 'Expand link description',
    description: 'Tooltip text for expanding a description to a link',
  },
  collapse_link_description: {
    message: 'Collapse link description',
    description: 'Tooltip text for collapsing a description to a link',
  },
  edit_link_description: {
    message: 'Edit link description',
    description: 'Tooltip text for editing a description to a link',
  },
  delete_link_description: {
    message: 'Delete link description',
    description: 'Tooltip text for deleting a description to a link',
  },
  save_link_description: {
    message: 'Save link description',
    description: 'Tooltip text for saving a description to a link',
  },
  add_to_stack: {
    message: 'Add to stack',
    description: 'Button text for adding an item to a stack',
  },
  add_to_section: {
    message: 'Add to Section',
    description: 'Button text for adding an item to a section',
  },
  add_to_current_stack: {
    message: 'Add to current stack',
    description:
      'Button text for adding an item to the current stack from suggestions box',
  },
  suggested_by_dash: {
    message: 'Suggested by Dash',
    description: 'Title for the left column in Start Page V3',
  },
  confirm_delete_stack_title: {
    message: 'Delete this stack?',
    description:
      'Text asking the user if they are sure they want to delete the stack',
  },
  confirm_delete_stack_body: {
    message: 'All items will be removed and the stack will be deleted.',
    description:
      'Text letting the user know that confirming the current prompt will delete this stack',
  },
  error_updating_stack_item: {
    message: 'Failed to update stack item',
    description:
      'Generic error message for when the user failed to update the stack item',
  },
  stack_title_placeholder: {
    message: 'Stack name',
    description: 'The placeholder at the top of the stackview page',
  },
  stack_description_placeholder: {
    message: 'Add a description to this stack...',
    description: 'The placeholder at the top of the stackview page',
  },
  more: {
    message: 'More',
    description: 'Label for more actions',
  },
  copied_link_to_clipboard: {
    message: 'Link copied to clipboard.',
    description:
      'Snackbar text to indicate that a link has been successfully copied to the clipboard.',
  },
  copied_math_to_clipboard: {
    message: 'Result copied to clipboard.',
    description:
      'Snackbar text to indicate that a math answer has been successfully copied to the clipboard.',
  },
  item_deleted: {
    message: 'Stack item deleted.',
    description: 'Snackbar message for when a user deletes a stack item.',
  },
  edit_stack_item_modal_title: {
    message: 'Edit stack item',
    description: 'Modal title for editing a stack item.',
  },
  save: {
    message: 'Save',
    description: 'Button text to save a file to Dropbox.',
  },
  stack_item_updated: {
    message: 'Stack item updated.',
    description: 'Snackbar shown when the user updates a stack item name.',
  },
  stack_successfully_created: {
    message: '{{stackName}} was successfully created.',
    description: 'Snackbar shown when the user created a new stack.',
  },
  stack_successfully_deleted: {
    message: 'The stack was successfully deleted.',
    description: 'Snackbar shown when the user deletes a stack.',
  },
  stack_successfully_deleted_with_stackname: {
    message: '{{stackName}} was successfully deleted.',
    description: 'Snackbar shown when the user deletes a stack.',
  },
  stack_pinned_successfully: {
    message: 'Stack pinned successfully.',
    description: 'Snackbar shown when the user pins a stack.',
  },
  stack_unpinned_successfully: {
    message: 'Stack unpinned successfully.',
    description: 'Snackbar shown when the user unpins a stack.',
  },
  stack_pinned: {
    message: 'Stack pinned',
    description: 'Tooltip text when a stack is pinned',
  },
  stack_unpinned: {
    message: 'Stack unpinned',
    description: 'Tooltip text when a stack is unpinned',
  },
  rename: {
    message: 'Rename',
    description: 'Button text to rename an item',
  },
  rename_modal_title: {
    message: 'Rename stack item',
    description: 'Modal title to rename an item',
  },
  rename_title_placeholder: {
    message: 'Title',
    description: 'Placeholder text while renaming a stack',
  },
  new_stack: {
    message: 'New stack',
    description: 'Title of create stack modal',
  },
  untitled_stack: {
    message: 'Untitled',
    description: 'Default name for a new stack',
  },
  create_stack_snackbar_title: {
    message: 'Created {{name}}',
    description: 'Button label for viewing a ’stack’ with name {{name}}.',
  },
  add_to_stack_snackbar_title: {
    message: 'Added to {{name}}.',
    description: 'Snackbar text for adding stack items to a stack',
  },
  view_button: {
    message: 'View',
    description: 'Button label for viewing an object',
  },
  manage_account: {
    message: 'Manage account',
    description: "Button text for managing the user's dropbox account",
  },
  open_settings: {
    message: 'Settings',
    description: 'Button text for opening settings',
  },
  settings_visit_help_center: {
    message: 'Visit help center',
    description: 'Button text for visiting help center',
  },
  settings_send_feedback: {
    message: 'Send feedback',
    description: 'Button text for sending feedback',
  },
  settings_get_support: {
    message: 'Get support',
    description: 'Button text for getting support',
  },
  settings_privacy_policy: {
    message: 'Privacy policy',
    description: 'Button text for visiting help center',
  },
  illegal_characters_warning: {
    message: 'Special characters like /, \\, and emojis are not allowed',
    description:
      'A warning when the user enters illegal characters in the stack name',
  },
  input_too_long: {
    message: 'Too many characters',
    description:
      'A warning when the user enters too many characters in the input field',
  },
  login: {
    message: 'Log in',
    description: 'Label for the login page',
  },
  logout: {
    message: 'Log out',
    description: 'Button text for logging out',
  },
  splash_page_title: {
    message: 'Work across all your apps in one place',
    description: 'Title for splash page',
  },
  splash_page_subtitle: {
    message:
      'Join meetings, open emails, and find files across your desktop and the cloud.',
    description: 'Subtitle for splash page',
  },
  logging_in_loading_message: {
    message: 'Getting things ready for you…',
    description: 'Status message when logging in',
  },
  logging_in_error_message: {
    message: 'We ran into an error',
    description: 'Status message when an error occurs while logging in',
  },
  continue_to_log_in: {
    message: 'Continue to log in',
    description: 'Continue to log in call to action on login page',
  },
  logging_in_try_again: {
    message: 'Try again',
    description: 'Try again button displayed to the user after a login error',
  },
  hide_search_result: {
    message: 'Result hidden',
    description:
      'Snackbar message when a user selects hide action on a search result',
  },
  checking_for_updates: {
    message: 'Checking for updates...',
    description:
      'Snackbar message when a user opts to check for updates manually',
  },
  read_only_section_tooltip: {
    message: 'Only editors can add sections',
    description: 'Tooltip when an editor tries to create a section',
  },
  rename_section: {
    message: 'Rename section',
    description: 'label for renaming a section',
  },
  remove_section: {
    message: 'Remove section',
    description: 'label for removing a section',
  },
  add_items: {
    message: 'Add items',
    description: 'label for adding an item to a section',
  },
  connectors_section_connected_with_counter: {
    message: 'Added ({{count}})',
    description:
      'Header for the list of connections in the settings page where users can connect and disconnect apps',
  },
  connectors_section_connected: {
    message: 'Added',
    description:
      'Header for the list of connections in the settings page where users can connect and disconnect apps',
  },
  connectors_section_no_connections_title: {
    message: 'No apps added yet',
    description:
      'Title for page informing the user they have not connected any Dash apps yet',
  },
  connectors_section_no_connections_message: {
    message: 'Add apps to access their content in Dash',
    description:
      'Message for page informing the user they have not connected any Dash apps yet',
  },
  connectors_section_popular: {
    message: 'Popular',
    description:
      'Header for the list of popular connectors in the settings page where users can connect and disconnect apps',
  },
  connectors_section_all: {
    message: 'All',
    description:
      'Header for the list of all available connectors in the settings page where users can connect and disconnect apps',
  },
  connected: {
    message: 'Connected',
    description: 'Label indicating that a connector is connected',
  },
  connect: {
    message: 'Connect',
    description: 'Label for a button that allows users to connect to a service',
  },
  disconnect: {
    message: 'Disconnect',
    description:
      'Label for a button that allows users to disconnect from a service',
  },
  connect_app: {
    message: 'Connect app',
    description:
      'Tooltip label for a button that allows users to connect to a service',
  },
  disconnect_title: {
    message: 'Disconnect {{serviceName}}',
    description: 'Title displayed in the disconnect modal',
  },
  disconnect_modal_text: {
    message:
      'The {{serviceName}} account for {{userEmail}} will be disconnected. You can reconnect anytime.',
    description: 'Text displayed in the disconnect modal',
  },
  disconnect_modal_text_without_email: {
    message:
      'The {{serviceName}} account will be disconnected. You can reconnect anytime.',
    description: 'Text displayed in the disconnect modal',
  },
  disconnect_modal_text_for_web_extension: {
    message:
      'The {{webExtensionName}} will be removed. You can reconnect anytime.',
    description:
      'Text displayed in the disconnect modal for disconnecting web extensions',
  },
  disconnect_cancel: {
    message: 'Cancel',
    description: 'Label for a button that allows the user to close the modal',
  },
  reauthenticate: {
    message: 'Reconnect',
    description:
      'Label for a button that allows users to retry authenticating to a failing a service',
  },
  connector_status_connected: {
    message: 'Connected',
    description:
      'Label letting the user know they are connected and authenticated to a service',
  },
  connector_status_sync_in_progress: {
    message: 'Sync in progress...',
    description:
      'Label letting the user know their connection to this service is currently syncing data',
  },
  connector_status_sync_failed: {
    message: 'Sync failed',
    description:
      'Label letting the user know their connection to this service has failed to authenticate and will require the user to reauthenticate',
  },
  connector_status_expired: {
    message: 'Expired',
    description:
      'Label letting the user know their connection to this service has failed to authenticate and will require the user to reauthenticate',
  },
  connector_status_reconnecting: {
    message: 'Reconnecting…',
    description:
      'Label letting the user know that a reconnection to a third party service is currently in progress',
  },
  connector_status_disconnecting: {
    message: 'Disconnecting…',
    description:
      'Label letting the user know that a disconnection from a third party connector is currently in progress',
  },
  connectors_settings_browser_description: {
    message: 'Search tabs and bookmarks',
    description:
      'Description for browser that can be connected to Dropbox Dash',
  },
  connectors_settings_disconnected_from_service: {
    message: '{{appName}} has been disconnected',
    description:
      'Notification telling the user they have successfully disconnected one of their apps, previously linked to Dash',
  },
  connectors_settings_error_disconnecting: {
    message: '{{appName}} couldn’t be disconnected',
    description:
      'Notification telling the user that a dash app disconnection as failed',
  },
  connector_settings_delay_notice: {
    message: 'This is taking longer than expected. Please wait',
    description:
      'Notification telling the user that a dash app operating is taking longer than expected.',
  },
  add_more_connectors: {
    message:
      'Connect your files to find and get instant answers from your work.',
    description:
      'Text on search typeahead to prompt user to add more connectors',
  },
  add_first_app: {
    message: 'Add your first app',
    description: 'Text on button to prompt user to add some connectors',
  },
  connectors_settings_reconnected_from_service: {
    message: '{{appName}} has been reconnected',
    description:
      'Notification telling the user they have successfully reconnected one of their apps',
  },
  connectors_settings_error_reconnecting: {
    message: '{{appName}} couldn’t be reconnected',
    description:
      'Notification telling the user that a dash app reconnection as failed',
  },
  connectors_settings_error_reconnecting_cleanup: {
    message:
      '{{appName}} was reconnected with an issue. You may disconnect the expired account',
    description:
      'Notification telling the user that a dash app reconnection as succeeded with an error',
  },
  connectors_settings_connected_to_service: {
    message: '{{appName}} was successfully connected',
    description:
      'Notification telling the user they are connected to a dash app',
  },
  not_found_page_title: {
    message: 'Page not found',
    description: 'Page title for a requested page that could not be found',
  },
  not_found: {
    message: 'Sorry, we could not find the page you are looking for.',
    description: 'Message showing the requested page could not be found',
  },
  move_to_section: {
    message: 'Move to section',
    description: 'Move stack item to section',
  },
  default_section: {
    message: 'Default section',
    description: 'Menu item to move stack item to default section',
  },
  add_section_empty_message: {
    message: 'Add sections to help organize your work',
    description: 'CTA message asking user to add sections',
  },
  dash_glyph: {
    message: 'Toggle AI Panel',
    description: 'Label for the Dash glyph button that toggles the AI panel',
  },
  add_connectors: {
    message:
      'Instantly find files, docs, emails, calendar events, and more with a single search.',
    description:
      'Text on search typeahead to prompt user to add some connectors',
  },
  view_all_apps: {
    message: 'View all apps',
    description: 'Text on button that takes user to list of all connectors',
  },
  no_calendars: {
    message: 'Never lose track of your day',
    description: 'Text on card if user has not added any calendar connectors',
  },
  no_calendar_events: {
    message: 'No upcoming meetings',
    description: 'Text on card if user has upcoming calendar events',
  },
  enjoy_your_time: {
    message: 'Enjoy some you time.',
    description: 'Text on card if user has upcoming calendar events',
  },
  connect_a_calendar: {
    message:
      'Add your Google or Outlook calendar to quickly view, prepare, and join meetings.',
    description:
      'Text on card that prompts user to connect a calendar connector',
  },
  connect_a_calendar_v2: {
    message: 'Check your daily schedule, prepare for, and join meetings.',
    description:
      'Text on card that prompts user to connect a calendar connector',
  },
  add_calendar: {
    message: 'Add calendar',
    description:
      'Text on CTA that opens modal to allow user to connect a calendar',
  },
  add_your_calendar: {
    message: 'Add your calendar',
    description: 'Title text on modal where user can add a calendar connector',
  },
  select_calendar: {
    message: 'Select the calendar you use most.',
    description:
      'Description text on on modal where user can add a calendar connector',
  },
  calendar_icon: {
    message: 'calendar icon',
    description: 'ARIA label that describes calendar icon',
  },
  lightbulb_icon: {
    message: 'lightbulb icon',
    description: 'ARIA label that describes lightbulb icon',
  },
  celebrate_icon: {
    message: 'celebration icon',
    description: 'ARIA label that describes celebrate icon',
  },
  stacks_empty_state_title: {
    message: 'No stacks created yet',
    description: 'Title text on empty page when user has no stacks',
  },
  stacks_empty_state_message: {
    message:
      'Use stacks to store, discover, and share all your important resources in one location',
    description: 'Message text on empty page when user has no stacks',
  },
  archived_stacks_empty_state_title: {
    message: 'No archived stacks yet',
    description: 'Title text on empty page when user has no archived stacks',
  },
  archived_stacks_empty_state_message: {
    message:
      'Archived stacks remain accessible and shareable but won’t send notifications or clutter your list',
    description: 'Message text on empty page when user has no archived stacks',
  },
  shared_stacks_empty_state_title: {
    message: 'Nothing shared with you yet',
    description: 'Title text on empty page when user has no shared stacks',
  },
  shared_stacks_empty_state_message: {
    message: 'Stacks shared with you will show up here',
    description: 'Message text on empty page when user has no shared stacks',
  },
  create_stacks: {
    message: 'Click + to create your first stack.',
    description:
      'Title text on stacks page, instructing user on how to create a stack.',
  },
  stack_description: {
    message:
      'Let’s change that: Add items manually or use the suggestions to get this stack started.',
    description:
      'Description text on empty stack page when user has no items in stack',
  },
  suggested_stack_empty_state_description: {
    message:
      'Check back later for suggestions based on your connected apps and activity.',
    description:
      'Description text on empty state card when there are no suggested items',
  },
  aria_dismiss_icon: {
    message: 'Dismiss icon',
    description: 'ARIA label that describes dismiss icon',
  },
  aria_add_icon: {
    message: 'Add icon {{connectorName}}',
    description: 'ARIA label that describes add icon',
  },
  migration_update_title: {
    message: 'Welcome to the new Dash experience!',
    description: 'Title for migration modal',
  },
  update_list_header: {
    message: 'What to expect',
    description: 'Header for bulleted list of changes',
  },
  ui_update_intro_blurb_desktop: {
    message:
      'We’ve updated your Dash experience - making it easier to use and more convenient than ever before.',
    description: 'Blurb for introducing the ui',
  },
  ui_update_intro_blurb_web: {
    message:
      'We’ve updated your Dash experience - making it easier to use and more accessible than ever before.',
    description: 'Blurb for introducing the ui',
  },
  ui_update_bullet_1_desktop: {
    message: 'Open Dash on desktop, as a new tab in your browser, or using ',
    description: 'First bullet point for ui update notification part 1',
  },
  ui_update_bullet_1_web: {
    message:
      'Open Dash on desktop, a new tab in your browser, or using dash.ai with one click',
    description: 'First bullet point for ui update notification part 1',
  },
  ui_update_bullet_2_desktop: {
    message: 'Access all Dash features on any surface',
    description: 'Second bullet point for ui update notification',
  },
  ui_update_bullet_2_web: {
    message: 'Experience the full power of Dash features on desktop and web',
    description: 'Second bullet point for ui update notification',
  },
  ui_update_bullet_3_desktop: {
    message: 'Organize your work content effortlessly with a sleek new design',
    description: 'Third bullet point for ui update notification',
  },
  ui_update_bullet_3_web: {
    message:
      'Organize your work content effortlessly with a brand new, modular design',
    description: 'Third bullet point for ui update notification',
  },
  ai_privacy_policy_disclaimer: {
    message:
      'Dropbox Dash uses artificial intelligence (AI) technology created and maintained by third-parties, such as OpenAI, to find answers, create summaries, and give suggestions based on your activity. Dropbox is committed to keeping your information secure.',
    description: "Disclaimer blurb for Dash's usage of AI",
  },
  privacy_policy_prompt_header: {
    message: 'To learn more, visit ',
    description: 'Header for prompting to read the privacy policy',
  },
  privacy_policy_capitalized: {
    message: 'Privacy Policy',
    description: 'Privacy Policy',
  },
  acknowledge_button_cta: {
    message: 'Got it',
    description: 'Button text for acknowledging.',
  },
  try_it_now_button_cta: {
    message: 'Try it now',
    description: 'Button text for acknowledging and trying it now.',
  },
  last_updated_filter_title: {
    message: 'Updated',
    description: 'Title for last updated filter',
  },
  last_updated_filter_any_date: {
    message: 'Any date',
    description:
      'Filter search results to those last updated over any date range',
  },
  last_updated_filter_last_24_hours: {
    message: 'Last 24 hours',
    description:
      'Filter search results to those last updated over the last 24 hours',
  },
  last_updated_filter_last_7_days: {
    message: 'Last 7 days',
    description:
      'Filter search results to those last updated over the last 7 days',
  },
  last_updated_filter_last_30_days: {
    message: 'Last 30 days',
    description:
      'Filter search results to those last updated over the last 30 days',
  },
  last_updated_filter_last_90_days: {
    message: 'Last 90 days',
    description:
      'Filter search results to those last updated over the last 90 days',
  },
  last_updated_filter_last_12_months: {
    message: 'Last 12 months',
    description:
      'Filter search results to those last updated over the last 12 months',
  },
  last_updated_filter_custom: {
    message: 'Custom',
    description:
      'Filter search results to those last updated over a custom date range',
  },
  last_updated_filter_modal_title: {
    message: 'Custom date range',
    description: 'Select a custom date range for filtering search results',
  },
  last_updated_filter_modal_from: {
    message: 'From',
    description: 'Start date of custom date range',
  },
  last_updated_filter_modal_to: {
    message: 'To',
    description: 'End date of custom date range',
  },
  last_updated_filter_modal_cancel: {
    message: 'Cancel',
    description: 'Cancel selection of custom date range',
  },
  last_updated_filter_modal_apply: {
    message: 'Apply',
    description: 'Apply currently selected custom date range',
  },
  activation_recent_work_title: {
    message: 'Jump back in to recent work',
    description: 'Title text on recent work activation card',
  },
  activation_recent_work_blurb: {
    message:
      'Add the browser extension to view recent work and get smart suggestions.',
    description: 'Body text on recent work activation card',
  },
  activation_recent_work_blurb_v2: {
    message: 'Access recent tabs across apps by adding the extension.',
    description: 'Body text on recent work activation card',
  },
  add_dash_extension: {
    message: 'Add Dash Extension',
    description: 'CTA text for adding the browser extension.',
  },
  add_dash_to: {
    message: 'Add to {{browserName}}',
    description: 'Leading text for Add Dash to ### string',
  },
  ask_follow_up: {
    message: 'Ask a follow up...',
    description: 'Text on the button to ask follow up question',
  },
  dash_premium_experience_banner_title: {
    message:
      'You’re enjoying a free trial of Dash Premium. In the coming months, you’ll need a paid plan to access these features.',
    description:
      'Message to let the user know that the free trial is coming, and for now the users can enjoy Dash Premium features for free',
  },
  open_dash_keyboard_shortcut: {
    message: 'Open Dash',
    description: 'Text beside the CMD + E shortcut user education banner',
  },
  typeahead_shortcut_create: {
    message: 'Create',
    description: 'Text beside /new typeahead shortcut user education banner',
  },
  syncing_dropbox_time_left: {
    message: 'Syncing recent Dropbox content',
    description:
      'Text on footer explaining dropbox is syncing and time left to finish.',
  },
  dash_value_prop_answers: {
    message: 'Search and get answers about your content',
    description: 'Title text on card explaining value proposition of Dash.',
  },
  dash_value_prop_answers_description: {
    message:
      'Ask questions, and Dash will gather and summarize relevant info from your apps.',
    description: 'Text on card explaining value proposition of Dash.',
  },
  dash_value_prop_stacks: {
    message: 'Organize content into stacks',
    description: 'Title text on card explaining value proposition of Dash.',
  },
  dash_value_prop_stacks_description: {
    message:
      'Easily group important info together from different apps with the help of AI.',
    description: 'Text on card explaining value proposition of Dash.',
  },
  dash_value_prop_connectors: {
    message: 'Get better results with more apps',
    description: 'Title text on card explaining value proposition of Dash.',
  },
  dash_value_prop_connectors_description: {
    message:
      'Answers get smarter, more search results, and advanced stacks the more apps you add.',
    description: 'Text on card explaining value proposition of Dash.',
  },
  dash_value_prop_platform: {
    message: 'Use Dash in your browser or desktop',
    description: 'Title text on card explaining value proposition of Dash.',
  },
  dash_value_prop_platform_description: {
    message:
      'Download the desktop app or install the browser extension to quickly access Dash.',
    description: 'Text on card explaining value proposition of Dash.',
  },
  manage_cookies: {
    message: 'Cookies & CCPA Preferences',
    description: 'Text on menu action for manage cookies',
  },
  managed_by_admin: {
    message: 'managed by admin',
    description:
      'Label letting the user that the admin manages a given connection',
  },
  search_or_add_link: {
    message: 'Search or add a link',
    description: 'Placeholder text for adding an item to a stack',
  },
  suggested_links: {
    message: 'Suggested links',
    description: 'Label above the suggested links',
  },
  no_search_results: {
    message: 'No results for that search term',
    description: 'Label text for no search results',
  },
  clear_search: {
    message: 'Clear search',
    description: 'Label for clearing the search query',
  },
  clear_chat: {
    message: 'Clear chat',
    description: 'Label for clearing the chat',
  },
  query_suggestion_reason: {
    message: 'Because you connected {{connectorName}}',
    description: 'Query suggestion label for a singular connector',
  },
  query_suggestion_calendar_today_title: {
    message: 'Try asking Dash, “What’s on my calendar today?”',
    description: 'Query suggestion header text for any calendar app',
  },
  query_suggestion_summarize_file_storage_title: {
    message: 'Tell Dash to “Summarize...” one of your recent docs',
    description: 'Query suggestion to summarize file storage app documents',
  },
  query_suggestion_search_file_storage_title: {
    message:
      'Try searching a keyword from a recent file. For example, “Company OKR”',
    description: 'Query suggestion header text for file storage apps',
  },
  query_suggestion_any_app_title: {
    message:
      'Try asking, “What can Dash do?” to learn more about how Dash works',
    description: 'Query suggestion header text for any app',
  },
  query_suggestion_count: {
    message: '{{startIndex}} of {{endIndex}}',
    description: 'Defines place within set of query suggestions',
  },
  next_query_suggestion: {
    message: 'Next query suggestion',
    description:
      'aria text on the next navigation button within the Query Suggestion Prompt',
  },
  previous_query_suggestion: {
    message: 'Previous query suggestion',
    description:
      'aria text on the previous navigation button within the Query Suggestion Prompt',
  },
  settings_general: {
    message: 'General',
    description: 'tab title for the settings page',
  },
  settings_apps: {
    message: 'Apps',
    description: 'tab title for the settings page',
  },
  settings_hide_app_on_start: {
    message: 'Show Dash on startup',
    description: 'should app show on startup',
  },
  settings_show_dash_in_dock_darwin: {
    message: 'Show in dock',
    description: 'macos version of show in dock',
  },
  settings_show_dash_in_dock_win32: {
    message: 'Show on taskbar',
    description: 'windows version of show in dock',
  },
  settings_app_to_nearest_window_darwin: {
    message: 'Show on current screen (experimental)',
    description:
      'In show taskbar icon mode, app will attempt to show on any screen',
  },
  settings_app_to_nearest_window_win32: {
    message: 'Show on current screen (experimental)',
    description:
      'In show dock icon mode, app will attempt to show on any screen',
  },
  settings_show_in_tray_darwin: {
    message: 'Show in menubar',
    description: 'macos version of show in tray',
  },
  settings_show_in_tray_win32: {
    message: 'Show in system tray',
    description: 'windows version of show in tray',
  },
  settings_disable_dash_as_new_tab: {
    message: 'Disable Dash as new tab',
    description: 'Title for settings to disable Dash new tab',
  },
  settings_keyboard_shortcuts: {
    message: 'Keyboard shortcuts',
    description: 'tab title for the settings page',
  },
  app_shortcut: {
    message: 'Open/Hide Dash',
    description: 'App shortcut label',
  },
  settings_settings_shortcut_label: {
    message: 'Open settings',
    description: 'shortcut label for opening settings',
  },
  settings_keyboard_shortcuts_for_label: {
    message: 'current keyboard shortcut {{shortcutLabel}}',
    description: 'Label for current shortcut',
  },
  settings_keyboard_shortcuts_aria_for_action: {
    message: 'Type a keyboard shortcut for {{label}}',
    description: 'Explains what the shortcut does',
  },
  settings_keyboard_shortcuts_placeholder_type_shortcut: {
    message: 'Type shortcut',
    description: 'placeholder for keyboard shortcut input edit',
  },
  settings_keyboard_shortcuts_cancel: {
    message: 'Cancel',
    description: 'tooltip for keyboard settings cancel',
  },
  settings_keyboard_shortcuts_aria_cancel: {
    message: 'Cancel editing {{label}}',
    description: 'cancel editing keyboard setting',
  },
  settings_keyboard_shortcuts_editing: {
    message: 'Editing keyboard shortcut for {{label}}',
    description: 'used between action and shortcut',
  },
  settings_keyboard_shortcuts_edit: {
    message: 'Edit',
    description: 'tooltip for keyboard settings edit',
  },
  settings_keyboard_shortcuts_aria_edit: {
    message: 'Edit keyboard shortcut {{label}}',
    description: 'edit keyboard setting for label',
  },
  settings_keyboard_shortcuts_reset: {
    message: 'Reset',
    description: 'tooltip for keyboard settings reset',
  },
  settings_keyboard_shortcuts_aria_reset: {
    message: 'Reset to default shortcut',
    description: 'reset keyboard setting',
  },
  settings_keyboard_shortcuts_save: {
    message: 'Save',
    description: 'tooltip for keyboard settings save',
  },
  settings_keyboard_shortcuts_aria_save: {
    message: 'Save keyboard shortcut',
    description: 'aria Save shortcut',
  },
  settings_kb_error_no_valid_modifier: {
    message: 'Missing modifier key',
    description: 'keyboard setting error, is missing a modifier',
  },
  settings_kb_error_no_valid_char: {
    message: 'Missing valid letter',
    description: 'keyboard setting error, missing valid character',
  },
  settings_kb_error_reserved: {
    message: 'Shortcut is reserved',
    description: 'keyboard setting error, reserved shortcut',
  },
  settings_kb_error_multi_letter: {
    message: 'Only one letter permitted',
    description: 'keyboard setting error, only one letter',
  },
  settings_local_files: {
    message: 'Local Files',
    description: 'tab title for the local files settings page',
  },
  settings_manage_apps: {
    message: 'Open Dash settings',
    description:
      'label for the button shown in the desktop app which opens the settings page where users can manage their connectors',
  },
  settings_manage_apps_title: {
    message: 'Manage apps in your browser',
    description:
      'title for describing to user in the desktop app to open settings page in web app to manage connectors',
  },
  settings_manage_apps_subtitle: {
    message: 'Add or remove apps in Dash Settings on the web',
    description:
      'subtitle for describing to user in the desktop app to open settings page in web app to manage connectors',
  },
  suggestions_box_title: {
    message: 'Suggestions',
    description: 'Title of the suggestions box',
  },
  settings_manage_apps_icon_title: {
    message: 'Laptop Icon',
    description: 'Alt title of laptop icon for accessibility purposes',
  },
  disable_dash_new_tab_tooltip_graphic_alt: {
    message: 'Browser extension context menu',
    description: 'Alt text for the new tab graphic',
  },
  disable_dash_new_tab_tooltip_title: {
    message: 'Remove Dash as your new tab in browser extension settings',
    description:
      'Tooltip title for instructions on how to disable the Dash new tab',
  },
  disable_dash_new_tab_tooltip_text: {
    message: 'To get your old ‘new tab’ back, follow these steps:',
    description:
      'Tooltip text for instructions on how to disable the Dash new tab',
  },
  disable_dash_new_tab_tooltip_step_1: {
    message: 'Go to browser extensions',
    description:
      'Tooltip text for step 1 of instructions on how to disable the Dash new tab',
  },
  disable_dash_new_tab_tooltip_step_2: {
    message: 'Click the 3-dot menu on Dropbox Dash',
    description:
      'Tooltip text for step 2 of instructions on how to disable the Dash new tab',
  },
  disable_dash_new_tab_tooltip_step_3: {
    message: 'Click ‘Use Dash start page (ON)’ to toggle it',
    description:
      'Tooltip text for step 3 of instructions on how to disable the Dash new tab',
  },
  suggestions_box_subtitle: {
    message: 'More content from your apps related to this stack',
    description: 'Description of the suggestions box',
  },
  aria_search_result_more: {
    message: 'More actions',
    description: 'ARIA label for more actions button',
  },
  aria_search_result_rendered: {
    message: 'Loaded search results',
    description: 'ARIA live announcement for when search results have loaded',
  },
  aria_search_results_more_action: {
    message: 'Search for {{query}} in {{displayName}}',
    description: 'ARIA query suggestions when no results',
  },
  aria_answers_question_send: {
    message: 'Submit question',
    description: 'ARIA button for submitting question',
  },
  aria_answers_announce_show_all_sources: {
    message: 'All sources, expanded',
    description: 'ARIA label for announcement all sources are shown',
  },
  aria_answers_user_query: {
    message: 'User query',
    description: "ARIA label user's query in conversation",
  },
  aria_answers_system_answer: {
    message: 'Answer',
    description: "ARIA label dash's answer in conversation",
  },
  aria_settings_privacy_policy: {
    message: 'Open Dropbox privacy policy in a new browser tab',
    description: 'ARIA text in settings for opening privacy policy',
  },
  single_step_onboarding_title: {
    message: 'Let’s start by adding apps',
    description: 'Title for the single step onboarding',
  },
  single_step_onboarding_subtitle: {
    message:
      'We recommend 3-5 apps for the best experience. You can always add more later.',
    description: 'Subtitle for the single step onboarding',
  },
  single_step_onboarding_v2_subtitle: {
    message:
      'Search and get answers across all your apps—we recommend at least 3 for the best experience. You can always add or remove apps later.',
    description: 'Subtitle for the single step onboarding',
  },
  onboarding_connectors_section_popular: {
    message: 'Popular apps',
    description:
      'Header for the list of popular connectors in the onboarding page',
  },
  onboarding_connectors_section_all: {
    message: 'All apps',
    description:
      'Header for the list of all available connectors in the onboarding page',
  },
  continue_button: {
    message: 'Continue',
    description: 'Button text for continuing the onboarding',
  },
  continue_button_disabled_instructions: {
    message: 'Add at least 1 app to continue',
    description:
      'Instructions for the user to select at least one app to continue the onboarding',
  },
  skip_button: {
    message: 'Add later',
    description: 'Button text for skipping the onboarding',
  },
  privacy_info: {
    message:
      'Dropbox is SOC2-compliant and committed to handling your information with care. Learn about how we use and protect your data in our <ppLink>Privacy Policy</ppLink> and <faqLink>Privacy Policy FAQ</faqLink>.',
    description:
      'Information about privacy relevant to the Dash onboarding onboarding flow.',
  },
  more_apps_coming_soon: {
    message: 'More apps coming soon!',
    description:
      'Text informing users that more connector apps are coming soon',
  },
  team_onboarding_title: {
    message: 'Add apps to find your content in one place',
    description: 'Title for the team onboarding',
  },
  team_onboarding_description: {
    message:
      'Save time with Dash by searching your content in one place instead of 10.',
    description: 'Description for the team onboarding',
  },
  team_onboarding_privacy_prompt: {
    message: 'Learn more about our <pButton>privacy measures and AI</pButton>.',
    description: 'Link for Dash privacy information',
  },
  privacy_modal_title: {
    message: 'Learn how Dash uses your data',
    description: 'Title for the privacy policy modal',
  },
  privacy_soc2_title: {
    message: 'Dropbox Dash is a SOC 2 compliant product',
    description: 'Title for SOC2 compliance',
  },
  privacy_soc2_subtitle: {
    message:
      'Dash being SOC 2 compliant means that it meets the high standards for managing customer data set by the AICPA.',
    description: 'Subtitle for SOC2 compliance',
  },
  privacy_soc2_description: {
    message:
      'The SOC 2 framework is a benchmark for security, availability, processing integrity, confidentiality, and privacy. Compliance indicates that Dash has established and follows strict information security policies and procedures, encompassing the secure management of data to protect the interests of the organization and the privacy of its clients. For Dash users, this means they can trust that their data is handled with care and rigorous security measures are in place to protect it.',
    description: 'Explanation of SOC2 compliance',
  },
  privacy_soc2_security_practice_link_title: {
    message: 'Verifying our Security Practices',
    description: "Link to learn more about Dash's security practices",
  },
  privacy_soc2_soc_link_title: {
    message: 'What SOC reports are and why they’re important',
    description: 'Link to learn more about SOC',
  },
  privacy_privacy_first_title: {
    message: 'Your privacy comes first',
    description: 'Title for privacy first section',
  },
  privacy_privacy_first_subtitle: {
    message: 'You’re in charge of your data with Dropbox.',
    description: 'Subtitle for privacy first section',
  },
  privacy_privacy_first_description1: {
    message:
      'Dash only collects data that is needed to provide you access, debug the product in case anything goes wrong, give you feature access in Dash, and improve the product. As a Dropbox product, we are committed to protecting your data and keeping it secure—hundreds of millions of users and businesses trust Dropbox for their most important information.',
    description: 'First description for privacy first section',
  },
  privacy_privacy_first_description2: {
    message:
      'If you would like to submit a data access request or object to the processing of your personal data, please email us at <ppLink>privacy@dropbox.com</ppLink>.',
    description: 'Second description for privacy first section',
  },
  privacy_privacy_first_data_link_title: {
    message: 'Privacy Policy',
    description: 'Link to Dropbox privacy policy',
  },
  privacy_dash_ai_title: {
    message: 'How Dropbox Dash uses AI',
    description: 'Title for use of AI in Dash',
  },
  privacy_dash_ai_description: {
    message:
      'Dropbox Dash uses AI, which is supported by the third-party service, OpenAI. This helps us provide you with certain features you use in Dash, such as summarization and suggestions. We also use data to help improve the quality of our AI-powered features. When we use data for either purpose, it is anonymized and not traceable to you. Data sent to OpenAI is never used to train OpenAI models, and is deleted after 30 days.',
    description: 'Description for use of AI in Dash',
  },
  privacy_dash_ai_link_title: {
    message: 'Our AI Principles',
    description: 'Link to learn more about Dropbox AI principles',
  },
  privacy_dash_product_title: {
    message: 'Dash is a Dropbox product',
    description: 'Title for Dash product section',
  },
  privacy_dash_product_subtitle: {
    message:
      'Dropbox Dash adheres to the same high privacy and security standards as Dropbox.',
    description: 'Subtitle for Dash product section',
  },
  privacy_dash_product_promise_title: {
    message: 'Our promise to you:',
    description: 'Title for Dash product promise section',
  },
  privacy_dash_product_promise_item1: {
    message: 'We will never sell your data',
    description: 'First promise item for Dash product promise section',
  },
  privacy_dash_product_promise_item2: {
    message:
      'Employees at Dropbox can’t access your data unless you explicitly give them permission to (e.g., when speaking to Customer Support).',
    description: 'Second promise item for Dash product promise section',
  },
  privacy_dash_product_promise_item3: {
    message:
      'Your content remains your own—confidential or proprietary data is always yours, and protecting it is our top priority.',
    description: 'Third promise item for Dash product promise section',
  },
  privacy_dash_product_promise_item4: {
    message:
      'We’ll never alter the content, home, or organization of your content in the original app unless you’ve directly asked us to.',
    description: 'Fourth promise item for Dash product promise section',
  },
  privacy_need_more_info_title: {
    message: 'Need help or more info?',
    description: 'Title for section with link or links for more information',
  },
  admin_managed_connectors_title: {
    message: 'Managed by admin',
    description: 'Title for the admin managed connectors section',
  },
  onboarding_connectors_available_apps: {
    message: 'Available apps',
    description:
      'Header for the list of available connectors in the onboarding page',
  },
  connected_by_admin_description: {
    message: 'Added by your company',
    description: 'Description for the admin managed connectors',
  },
  added_by_user_description: {
    message: 'Added by you',
    description: 'Description for the user managed connectors',
  },
  jtbd_welcome_title: {
    message: 'Welcome, {{firstName}}',
    description: "Title with welcome message with user's name",
  },
  jtdb_tell_us_about_yourself_title: {
    message: 'Tell us about yourself',
    description:
      'Title on the onboarding page to prompt user to tell us about themselves',
  },
  jtbd_type_of_work_question: {
    message: 'What type of work do you do?',
    description: 'Question to ask user about the type of work they do',
  },
  jtbd_type_of_work_placeholder: {
    message: 'Select One',
    description: 'Placeholder text for the type of work dropdown',
  },
  jtbd_type_of_work_communications: {
    message: 'Communications',
    description: 'Option for user to select if they work in communications',
  },
  jtbd_type_of_work_consultant: {
    message: 'Consultant',
    description: 'Option for user to select if they work as a consultant',
  },
  jtbd_type_of_work_creative: {
    message: 'Creative',
    description: 'Option for user to select if they work in a creative field',
  },
  jtbd_type_of_work_educator: {
    message: 'Educator',
    description: 'Option for user to select if they work as an educator',
  },
  jtbd_type_of_work_finance: {
    message: 'Finance',
    description: 'Option for user to select if they work in finance',
  },
  jtbd_type_of_work_human_resources: {
    message: 'Human Resources',
    description: 'Option for user to select if they work in human resources',
  },
  jtbd_type_of_work_legal: {
    message: 'Legal',
    description: 'Option for user to select if they work in legal',
  },
  jtbd_type_of_work_marketing: {
    message: 'Marketing',
    description: 'Option for user to select if they work in marketing',
  },
  jtbd_type_of_work_operations: {
    message: 'Operations',
    description: 'Option for user to select if they work in operations',
  },
  jtbd_type_of_work_sales: {
    message: 'Sales',
    description: 'Option for user to select if they work in sales',
  },
  jtbd_type_of_work_software: {
    message: 'Software',
    description: 'Option for user to select if they work in software',
  },
  jtbd_type_of_work_student: {
    message: 'Student',
    description: 'Option for user to select if they are a student',
  },
  jtbd_type_of_work_product: {
    message: 'Product',
    description: 'Option for user to select if they work in product',
  },
  jtbd_type_of_work_design: {
    message: 'Design',
    description: 'Option for user to select if they work in design',
  },
  jtbd_type_of_work_engineering: {
    message: 'Engineering',
    description: 'Option for user to select if they work in engineering',
  },
  jtbd_type_of_work_other_option: {
    message: 'Other',
    description: 'Option for user to select if they work in a field not listed',
  },
  jtbd_work_with_question: {
    message: 'Who do you usually collaborate with?',
    description: 'Question to ask user about who they work with',
  },
  jtbd_work_with_teammates: {
    message: 'Teammates',
    description: 'Option for user to select if they work with teammates',
  },
  jtbd_work_with_clients: {
    message: 'Clients',
    description: 'Option for user to select if they work with clients',
  },
  jtbd_work_with_self: {
    message: 'Just me',
    description: 'Option for user to select if they work alone',
  },
  jtbd_relevant_question_title: {
    message: 'Which is most relevant to you?',
    description:
      'Title on the onboarding page to ask user which is most revelant to them',
  },
  jtdb_select_one_title: {
    message: 'Select one',
    description:
      'Title on the onboarding page to ask user to select one answer',
  },
  jtbd_type_search: {
    message: 'Wasting time searching for things',
    description: 'Option for user to select if they need help with search',
  },
  jtbd_type_organize: {
    message: 'Feeling disorganized',
    description:
      'Option for user to select if they need help with organization',
  },
  jtbd_type_meetings: {
    message: 'Joining video meetings on time',
    description: 'Option for user to select if they need help with meetings',
  },
  jtbd_type_not_sure: {
    message: 'Not sure, just checking things out',
    description:
      'Option for user to select if they are not sure what they need help with',
  },
  show_more_answer: {
    message: 'Show more',
    description: 'Button text to show more of a truncated answer',
  },
  show_less_answer: {
    message: 'Show less',
    description: 'Button text to show less of an expanded answer',
  },
  settings_section_theme: {
    message: 'Theme',
    description: 'Settings title for Theme section',
  },
  settings_section_preferences: {
    message: 'Preferences',
    description: 'Settings title for Preferences section',
  },
  settings_section_more: {
    message: 'More',
    description: 'Settings title for More section',
  },
  settings_section_debug: {
    message: 'Debug',
    description: 'Settings title for debug section',
  },
  settings_dash_version_copy_text: {
    message: 'Dash Version: {{appVersion}}',
    description:
      'Text copied to the clipboard when copying the application version',
  },
  settings_version_label: {
    message: 'Version {{appVersion}}',
    description: 'Label displayed for application version in Settings',
  },
  settings_version_label_for_organizations: {
    message: 'Version {{appVersion}} - Managed by your organization',
    description:
      'Label displayed for application version in Settings for organizations',
  },
  settings_version_tooltip_click_to_copy: {
    message: 'Click to copy version to the clipboard',
    description: 'Settings version tooltip on hover',
  },
  settings_version_snackbar_copied_to_clipboard: {
    message: 'Dash version copied to clipboard',
    description:
      'Settings snackbar after clicking to copy version to clipboard',
  },
  settings_check_for_updates: {
    message: 'Check for updates',
    description: 'Label for check for updates button in Settings',
  },
  settings_legal_and_privacy: {
    message: 'Legal and privacy',
    description: 'Label for legal and privacy button in Settings',
  },
  settings_reset_and_quit: {
    message: 'Reset and quit',
    description: 'Label for Reset and quit button in Settings',
  },
  settings_reset_and_quit_confirm_title: {
    message: 'Reset Dash?',
    description: 'Reset and quit dialog title in Settings',
  },
  settings_reset_and_quit_confirm_text: {
    message:
      'This will log you out, clear your search activity, and restore default preferences. Apps will not be disconnected. To disconnect your apps, click ‘Manage apps’ before resetting.',
    description: 'Reset and quit dialog text in Settings',
  },
  settings_reset_and_quit_confirm_button: {
    message: 'Reset',
    description: 'Reset and quit dialog reset button in Settings',
  },
  settings_quit: {
    message: 'Quit Dash',
    description: 'Label for Quit button in Settings',
  },
  settings_quit_confirm_title: {
    message: 'Quit Dash?',
    description: 'Quit dialog title in Settings',
  },
  settings_quit_confirm_text: {
    message: 'Quit',
    description: 'Quit dialog text in Settings',
  },
  settings_title: {
    message: 'Settings',
    description: 'Settings panel title',
  },
  settings_theme_system: {
    message: 'System',
    description: 'Follow system theme settings option',
  },
  settings_theme_aria_label_system: {
    message: 'follow system theme option',
    description: 'Follow system theme settings radio option',
  },
  settings_theme_dark: {
    message: 'Dark',
    description: 'dark theme settings option',
  },
  settings_theme_aria_label_dark: {
    message: 'dark theme option',
    description: 'dark theme settings radio option',
  },
  settings_theme_light: {
    message: 'Light',
    description: 'light theme settings option',
  },
  settings_theme_aria_label_light: {
    message: 'light theme option',
    description: 'light theme settings radio option',
  },
  settings_enable_dev_tools: {
    message: 'Enable DevTools',
    description: 'Enable developer tools settings option',
  },
  clear_filters: {
    message: 'Clear all filters',
    description: 'Button for clearing all filters',
  },
  filter_by_app: {
    message: 'Filter by app',
    description: 'Title for app filter on search filter bar',
  },
  select_all: {
    message: 'Select all',
    description: 'Button for selecting all options in a filter',
  },
  deselect_all: {
    message: 'Deselect all',
    description: 'Button for deselecting all options in a filter',
  },
  settings_enable_debug_mode: {
    message: 'Enable debug mode',
    description: 'Enable debug mode settings option',
  },
  settings_enable_annotation_mode: {
    message: 'Enable annotation mode',
    description: 'Enable annotation mode settings option',
  },
  copied_debug_results: {
    message: 'Copied results to clipboard.',
    description:
      'Snackbar text to indicate that a results for debugging have been successfully copied to the clipboard.',
  },
  download_desktop: {
    message: 'Download desktop',
    description: 'Button text for download desktop version of dash',
  },
  connected_apps_issue: {
    message:
      'Dash has upgraded to a better authentication service, which means you need to reconnect your apps.',
    description:
      'Banner text notifying the user that one or more of their connections has an issue.',
  },
  connected_apps_issue_reauth: {
    message:
      'Dash has lost conection to one or more of your apps, and they need to be reconnected.',
    description:
      'Banner text notifying the user that one or more of their connections needs to be reconnected.',
  },
  connected_apps_issue_ack_btn: {
    message: 'Re-sync apps',
    description:
      'A button on a banner which notifies the user of connected app issues which takes the user to the settings page.',
  },
  onboarding_add_files_btn: {
    message: 'Add your files',
    description: 'Button text for adding dropbox connector',
  },
  onboarding_add_later_btn: {
    message: 'Add later',
    description: 'Button text for skipping adding connectors',
  },
  onboarding_one_click_title: {
    message: 'Give Dash permission to access your Dropbox files',
    description: 'Title text for one-click onboarding experiment',
  },
  onboarding_one_click_blurb: {
    message:
      'Ask questions about your content, and Dash will search all your files to write an answer. You can add more apps later.',
    description: 'Blurb text for one-click onboarding experiment',
  },
  see_all: {
    message: 'See all',
    description:
      'Label for the button allowing the user to view all of the items in a collection',
  },
  see_all_with_more_count: {
    message: 'See all ({{count}} more)',
    description:
      'Label for the button allowing the user to view all of the items in a collection and how many will be shown',
  },
  see_fewer: {
    message: 'See fewer',
    description:
      'Label for the button allowing the user to hide a portion of the items in a collection',
  },
  see_all_apps: {
    message: 'See all apps',
    description:
      'Label for the button allowing the user to open the apps page from the welcome banner',
  },
  create_stack_modal_activation_blurb: {
    message:
      'A stack is a curated collection of related content across your apps. Keep it private or share with your team.',
    description:
      'Blurb for an activation banner shown in the modal where the user can create a new stack',
  },
  stacks_typeahead_subtitle: {
    message: 'Stack',
    description: 'Subtitle for stack result in typeahead search.',
  },
  stacks_typeahead_default_title: {
    message: 'Stack',
    description: 'Title for stack result in typeahead search.',
  },
  suggested_stacks: {
    message: 'Suggested stacks',
    description: 'Module title for a list of automatically generated stacks',
  },
  updates_from_connector: {
    message: 'From {{connector}}',
    description:
      'Module title for a list of recently updated content from a specific connector (eg Dropbox or Gmail)',
  },
  suggestions_subtitle: {
    message: 'Based on your browser & file history',
    description:
      'Module subtitle for a list of automatically generated suggested links',
  },
  refresh: {
    message: 'Refresh',
    description: 'Tooltip for refresh suggestions.',
  },
  stacks_connector_empty_alt: {
    message: 'No stack items',
    description: 'Alt text for the stack details empty state image',
  },
  stacks_connector_empty_cta: {
    message: 'Nothing in this stack yet',
    description: 'CTA message for empty state of stacks connector',
  },
  stacks_no_search_results_cta: {
    message: 'No matching results',
    description: 'Alt text for the stack details empty state image',
  },
  result_open_button_text: {
    message: 'Open',
    description: 'Text of the button for opening a result',
  },
  stack_result_subtext_title: {
    message: 'Stack',
    description: 'Subtext title for stack result',
  },
  select_calendar_you_use_most: {
    message: 'Select the calendar you use the most.',
    description: 'Subtitle within add your calendar modal',
  },
  your_admin_has_not_enabled_calendars: {
    message: 'There are no calendar apps available to be connected.',
    description:
      'Error message displayed when user tries to connect a calendar before an admin has enabled it.',
  },
  app_connected: {
    message: '{{connectorName}} has been connected',
    description: 'Snackbar displayed when an app has been connected',
  },
  edit_stack_details: {
    message: 'Edit stack Details',
    description: "Button label for editing a stack's details",
  },
  copy_link_to_stack: {
    message: 'Copy link to stack',
    description: 'Button label for copying a link to a stack',
  },
  stack_theme: {
    message: 'Stack Theme',
    description: 'Label for the stack color picker setting',
  },
  delete_stack: {
    message: 'Delete stack',
    description: 'Button label for deleting a stack',
  },
  aria_remove_previous_query: {
    message: 'Remove result from history',
    description:
      'ARIA label for the remove button beside search history items insearch typeahead',
  },
  tooltip_remove_previous_query: {
    message: 'Remove',
    description:
      'Tooltip label for the remove button beside search history items insearch typeahead',
  },
  search_result_recent_file: {
    message: 'Recent file',
    description: 'Subtitle label for recent file search result',
  },
  shortcuts_section_title: {
    message: 'Shortcuts',
    description: 'Title of the shortcuts section on desktop homepage',
  },
  shortcuts_section_title_apps: {
    message: 'Add apps',
    description:
      'Title of the apps section in the shortcuts section on desktop homepage',
  },
  shortcuts_section_title_calendar: {
    message: 'Connect your calendar',
    description:
      'Title of the calendar section in the shortcuts section on desktop homepage',
  },
  shortcuts_section_title_extension: {
    message: 'Install browser extension',
    description:
      'Title of the extension section in the shortcuts section on desktop homepage',
  },
  shortcuts_section_title_stacks: {
    message: 'Create stack',
    description:
      'Title of the stacks section in the shortcuts section on desktop homepage',
  },
  shortcuts_section_title_help: {
    message: 'Visit help center',
    description:
      'Title of the help section in the shortcuts section on desktop homepage',
  },
  create_section: {
    message: 'Create Section',
    description: 'Text for create section button',
  },
  failed_to_create_stack: {
    message: 'Failed to create stack. Try again later.',
    description: 'Snackbar text after a creating a stack fails.',
  },
  failed_to_create_stack_item: {
    message: 'Failed to create stack item. Try again later.',
    description: 'Snackbar text after a creating a stack item fails.',
  },
  connect_apps_message: {
    message: 'For the best experience, we recommend adding at least 3 apps.',
    description: 'Message on connect apps section on start page',
  },
  hide_card: {
    message: 'Hide',
    description:
      'Text on button that hides a specific button on the start page card',
  },
  add_browser_extension: {
    message: 'Add Dash for {{browserName}}',
    description: 'Text on button opens browser extension install link',
  },
  browser_extension_install_message: {
    message: 'Make Dash your new tab and navigate recent tabs',
    description: 'Text displayed on browser extension card on start page',
  },
  calendar_empty_state_info_text: {
    message: 'See upcoming events and join meetings from your homepage',
    description: 'Text displayed within calendar empty state in start page',
  },
  link_dash_eligibility_article: {
    message: 'For more information, visit our <0>Help Center</0>.',
    description:
      'Text on modal that provides more information on why users cannot access Dash',
  },
  no_results: {
    message: 'No results',
    description: 'Generic text to indicate that there are no results',
  },
  confirm_skip_adding_apps_modal_title: {
    message: 'Continue without adding apps?',
    description:
      'Title of modal confirming user wants to continue without adding apps',
  },
  confirm_skip_adding_apps_modal_text_line_1: {
    message: 'Dash can’t provide a delightful experience until you add apps.',
    description:
      'First line modal text confirming user wants to continue without adding apps',
  },
  confirm_skip_adding_apps_modal_text_line_2: {
    message: 'If you’re having second thoughts, here’s what you’ll be missing:',
    description:
      'Second line of modal text confirming user wants to continue without adding apps',
  },
  confirm_skip_adding_apps_modal_text_mobile: {
    message:
      'Dash can’t provide a delightful experience until you add apps. Here’s what you’ll be missing:',
    description:
      'Mobile modal text confirming user wants to continue without adding apps',
  },
  search: {
    message: 'Search',
    description: 'Name of the search feature',
  },
  search_feature_description: {
    message: 'Instantly find content across apps ',
    description: 'Description of the search feature',
  },
  answers: {
    message: 'Answers',
    description: 'Name of the answers feature',
  },
  answers_feature_description: {
    message: 'Get AI answers about your work',
    description: 'Description of the answers feature',
  },
  calendar: {
    message: 'Calendar',
    description: 'Name of the calendar feature',
  },
  calendar_feature_description: {
    message: 'Switch between deep work and meetings easily',
    description: 'Description of the answers feature',
  },
  calendar_customize_description: {
    message:
      'View all your daily meetings, get notifications and join calls without leaving Dash.',
    description: 'Description of the calendar module in customize view',
  },
  skip_for_now: {
    message: 'Skip for now',
    description: 'Skip for now',
  },
  see_more: {
    message: 'more',
    description: 'Text on button that shows more of a truncated answer',
  },
  see_less: {
    message: 'less',
    description: 'Text on button that shows less of an expanded answer',
  },
  desktop_application_subtitle: {
    message: 'Application',
    description:
      'Subtitle shown on typeahead results that show a desktop application (local app)',
  },
  connect_title: {
    message: '{{displayName}}',
    description: 'Title displayed in the connect modal',
  },
  connect_title_subheading: {
    message: '{{briefDescription}}',
    description: 'Description for the Airtable connection',
  },
  connect_input_instructions: {
    message: 'To find your {{displayName}} API key, see your {{link}}.',
    description: 'Description displayed in the connect modal',
  },
  connect_input_label: {
    message: '{{displayName}} Subdomain',
    description: 'Description displayed in the connect modal',
  },
  subdomain_connect_label: {
    message: '{{displayName}} Subdomain',
    description: 'Description displayed in the connect modal for subdomain',
  },
  subdomain_label: {
    message: 'Subdomain',
    description: 'Label for the subdomain input field',
  },
  api_key_connect_label: {
    message: 'API Key',
    description: 'Label displayed in the connect modal for API Key',
  },
  api_key_label: {
    message: 'api_key_1234',
    description: 'Placeholder in the connect modal for API Key',
  },
  team_name_connect_label: {
    message: 'Team Name',
    description: 'Label displayed in the connect modal for Team Name',
  },
  team_name_label: {
    message: 'Marketing Team',
    description: 'Placeholder displayed in the connect modal for Team Name',
  },
  site_url_label: {
    message: 'Site URL',
    description: 'Label for the site URL input field',
  },
  site_url_connect_label: {
    message: '{{displayName}} Site URL',
    description: 'Description displayed in the connect modal for site URL',
  },
  connect_label: {
    message: 'Connect {{displayName}}',
    description: 'Label for a button that initiates the connection process',
  },
  connect_button_label: {
    message: 'Connect',
    description: 'Label for a button that initiates the connection process',
  },
  cancel_button_label: {
    message: 'Cancel',
    description: 'Label for a button that cancels the connection process',
  },
  zendesk_prompt: {
    message:
      'Enter your Zendesk subdomain, e.g. https://mycompany.zendesk.com.',
    description: 'Zendesk auth prompt',
  },
  onboarding_connectors_section_recommended: {
    message: 'Recommended',
    description:
      'Header for the list of recommended connectors in the onboarding page',
  },
  ecosystem_connector_dropbox_title: {
    message: 'Dropbox',
    description: 'Title for the Dropbox ecosystem connector',
  },
  ecosystem_connector_dropbox_description: {
    message: 'Search files, folders, and Paper docs',
    description: 'Description for the Dropbox ecosystem connector',
  },
  ecosystem_connector_google_suite_title: {
    message: 'Google Suite',
    description: 'Title for the Google Suite ecosystem connector',
  },
  ecosystem_connector_google_suite_description: {
    message: 'Search Google Drive, Gmail, and Calendar',
    description: 'Description for the Google Suite ecosystem connector',
  },
  ecosystem_connector_microsoft_suite_title: {
    message: 'Microsoft Suite',
    description: 'Title for the Microsoft Suite ecosystem connector',
  },
  ecosystem_connector_microsoft_suite_description: {
    message: 'Search OneDrive, Outlook, and Calendar',
    description: 'Description for the Microsoft Suite ecosystem connector',
  },
  new_section: {
    message: 'New Section',
    description: 'Placeholder for new section',
  },
  could_not_open_popup: {
    message:
      'Could not open popup. Please allow your browser to open it and try again.',
    description: 'Error message when browser fails to open a popup',
  },
  error_getting_oauth_url: {
    message: 'Error getting OAuth URL. Please try again.',
    description: 'Error message when an OAuth URL request fails',
  },
  error_creating_new_paragon_connection: {
    message:
      'Could not create {{appName}} connection using Paragon. Please try again.',
    description: 'Error message when a new Paragon connection fails to create',
  },
  error_detecting_new_connection: {
    message: 'Could not detect new {{appName}} connection. Please try again.',
    description: 'Error message when a new connection is not detected',
  },
  error_detecting_new_paragon_connection: {
    message:
      'Could not detect new {{appName}} connection using Paragon. Please try again.',
    description: 'Error message when a new Paragon connection is not detected',
  },
  scroll_right: {
    message: 'Scroll right',
    description: 'Aria-label for scroll button',
  },
  scroll_left: {
    message: 'Scroll left',
    description: 'Aria-label for scroll button',
  },
  get_started_checklist_dismiss: {
    message: 'Dismiss checklist',
    description: 'Text on onboarding checklist dismiss button',
  },
  get_started_with_dash: {
    message: 'Get started with Dash',
    description: 'Text on onboarding checklist get started button',
  },
  get_started_find_content_title: {
    message: 'Search for content',
    description:
      'Title on checklist item to perform your first search checklist item',
  },
  get_started_find_content_message: {
    message: 'Try searching for a recent doc from one of your connected apps.',
    description:
      'Text within checklist item describing steps to perform your first search checklist item',
  },
  get_started_find_content_cta: {
    message: 'Try it now',
    description:
      'Call to action button text on perform your first search checklist item',
  },
  get_started_explore_getting_started_stack_title: {
    message: 'Explore your “Getting started with Dash” stack',
    description:
      'Title on checklist item to explore getting started stack checklist item',
  },
  get_started_explore_getting_started_stack_message: {
    message:
      'Jumpstart your Dash journey and discover key features with this pre-built stack made just for you.',
    description:
      'Text within checklist item describing steps to explore getting started stack checklist item',
  },
  get_started_explore_getting_started_stack_cta: {
    message: 'Show me',
    description:
      'Call to action button text on explore getting started stack checklist item',
  },
  get_started_create_stack_title: {
    message: 'Create your first stack',
    description: 'Title on checklist item to create a stack checklist item',
  },
  get_started_create_stack_message: {
    message:
      'Think of stacks as a flexible folder that lets you gather scattered info. This stack will be private until you choose to share it.',
    description:
      'Text within checklist item describing steps to create a stack checklist item',
  },
  get_started_create_stack_cta: {
    message: 'Create stack',
    description: 'Call to action button text on create stack checklist item',
  },
  get_started_get_the_most_title: {
    message: 'Get the most out of Dash',
    description:
      'Title on checklist item to install extension and desktop app checklist item',
  },
  get_started_get_the_most_message: {
    message:
      'Search even faster on your computer with Dash’s desktop app and extension.',
    description:
      'Text within checklist item describing steps toinstall extension and desktop app checklist item',
  },
  get_started_get_the_most_message_desktop: {
    message: 'Search even faster on your computer with Dash’s desktop app.',
    description:
      'Text within checklist item describing steps to the desktop app checklist item',
  },
  get_started_get_the_most_cta: {
    message: 'Extension',
    description:
      'Call to action button text on extension and desktop app checklist item',
  },
  get_started_get_the_most_alt_cta: {
    message: 'Desktop app',
    description:
      'Secondary Call to action button text on extension and desktop app checklist item',
  },
  get_started_add_an_app_title: {
    message: 'Add an app',
    description: 'Title on checklist item to add an app checklist item',
  },
  get_started_add_an_app_message: {
    message: 'Choose the first apps you want to search across.',
    description:
      'Text within checklist item describing steps to add an app checklist item',
  },
  get_started_add_an_app_cta: {
    message: 'Add app',
    description: 'Call to action button text on add an app checklist item',
  },
  get_started_learn_more: {
    message: 'Learn more',
    description:
      'Text displayed on secondary button within getting started checklist item',
  },
  get_started_complete_title: {
    message: 'Nice—you’ve got the basics!',
    description:
      'Title displayed on the congratulatory getting started checklist on completion',
  },
  get_started_complete_message: {
    message: 'Keep exploring Dash',
    description:
      'Text displayed on the congratulatory getting started checklist on completion',
  },
  get_started_complete_add_extension: {
    message: 'Add extension',
    description:
      'Text displayed on the congratulatory getting started checklist to prompt user to install extension',
  },
  get_started_complete_install_desktop_app: {
    message: 'Install desktop app',
    description:
      'Text displayed on the congratulatory getting started checklist to prompt user to install desktop app',
  },
  public_stack_footer_log_in: {
    message: 'Log in to your account',
    description: 'Bold CTA text on public stack page footer',
  },
  public_stack_footer_suffix: {
    message: ' to get full access to Dash',
    description: 'CTA text on public stack page footer',
  },
  public_stack_sign_in_cta_title: {
    message: 'Sign in to Dash',
    description: 'CTA title text on public stack page hover overlay',
  },
  public_stack_sign_in_cta: {
    message: 'Sign in to Dash to have AI help organize your content for you.',
    description: 'CTA text on public stack page hover overlay',
  },
  public_stack_sign_in_to_share_cta: {
    message: 'Sign in to Dash to share this stack',
    description: 'CTA text on public stack page share button tooltip',
  },
  public_stack_sign_in_to_add_item_cta: {
    message: 'Sign in to Dash to add to this stack',
    description: 'CTA text on public stack page add-to-stack button tooltip',
  },
  public_stack_learn_about_dash: {
    message: 'Learn about Dash',
    description: 'CTA text on public stack page footer button',
  },
  nobody: {
    message: 'No one',
    description: 'Default avatar name when user is unknown or undefined',
  },
  stack_share_access_public_description: {
    message: 'Anyone with the link',
    description: 'Description of which people can access some content.',
  },
  stack_share_access_team_description: {
    message: 'Only people at {{team}}',
    description: 'Description of which people can access some content.',
  },
  stack_share_access_team_description_fallback: {
    message: 'Only people on your team',
    description: 'Description of which people can access some content.',
  },
  stack_share_access_private_description: {
    message: 'Only people invited',
    description: 'Description of which people can access some content.',
  },
  calendar_events_view_more: {
    message: 'View all',
    description: 'Text on button that shows more calendar events',
  },
  calendar_events_view_less: {
    message: 'View less',
    description: 'Text on button that shows less calendar events',
  },
  download: {
    message: 'Download',
    description: 'button text for download nav link',
  },
  download_dash: {
    message: 'Download Dash',
    description: 'header text for Download Dash upsell',
  },
  desktop_app: {
    message: 'Desktop app',
    description: "header text for Download Dash upsell's desktop section",
  },
  tab_manager_browser_extension: {
    message: 'Browser extension',
    description: "header text for Download Dash upsell's extension section",
  },
  install_upsell: {
    message: 'Install',
    description:
      "button text for Download Dash upsell's desktop section call to action",
  },
  install_again: {
    message: 'Install again',
    description:
      "button text for Download Dash upsell's desktop section call to action if the app has already been installed",
  },
  search_your_local_files: {
    message: 'Search your local files in Dash',
    description: "paragraph text for Download Dash upsell's desktop section",
  },
  add_extension: {
    message: 'Add extension',
    description:
      "button text for Download Dash upsell's extension section call to action",
  },
  add_again: {
    message: 'Add again',
    description:
      "button text for Download Dash upsell's extension section call to action if the extension has already been installed",
  },
  search_and_organize_your_tabs: {
    message: 'Search your tabs, bookmarks, and browser history',
    description: "paragraph text for Download Dash upsell's extension section",
  },
  archive_stack: {
    message: 'Archive stack',
    description: 'Text on button that shows what stack to archive',
  },
  unarchive_stack: {
    message: 'Unarchive stack',
    description: 'Text on button that shows what stack to unarchive',
  },
  confirm_archive_stack_title: {
    message: 'Are you sure you want to archive this stack?',
    description: 'Modal title on whether to archive this stack',
  },
  confirm_archive_stack_body: {
    message:
      'Moving this stack to archive will disable all notifications and updates for everyone who this stack is shared with.',
    description: 'Body text for archive stack confirmation',
  },
  confirm_unarchive_stack_title: {
    message: 'Are you sure you want to unarchive this stack?',
    description: 'Body text for unarchive stack confirmation',
  },
  confirm_unarchive_stack_body: {
    message:
      'Unarchiving this stack will re-enable all notifications and updates for everyone who this stack is shared with.',
    description: 'Body text for archive stack confirmation',
  },
  archived_stack_snackbar: {
    message: 'Archived “{{stackName}}”',
    description: 'Snackbar text for archiving a stack',
  },
  unarchived_stack_snackbar: {
    message: 'Unarchived “{{stackName}}”',
    description: 'Snackbar text for unarchiving a stack',
  },
  move: {
    message: 'Move',
    description: 'Button text for move handles',
  },
  feedback_modal_title: {
    message: 'How can we help?',
    description: 'Title for inapp feedback modal',
  },
  feedback_modal_blurb: {
    message:
      'Send feedback to our team to help us create a better Dash experience.',
    description: 'Blurb for feedback modal',
  },
  feedback_modal_contact_blurb: {
    message: 'If you need help with an issue, please contact <0>suppport</0>.',
    description: 'Blurb for feedback modal support',
  },
  feedback_modal_type_dropdown_default: {
    message: 'Feedback type',
    description: 'Default dropdown text for feedback modal',
  },
  feedback_modal_cta_text: {
    message: 'Send',
    description: 'Button text for cta on feedback modal',
  },
  feedback_modal_title_placeholder: {
    message: 'Title: What is this feedback about?',
    description: 'Button text for cta on feedback modal',
  },
  feedback_modal_body_placeholder: {
    message: 'Describe your feedback here.',
    description: 'Button text for cta on feedback modal',
  },
  feedback_modal_attach_screentshot_cta: {
    message: 'Attach screenshot',
    description: 'Button text for attaching a screenshot to the feedback modal',
  },
  feedback_modal_remove_image: {
    message: 'Remove image',
    description: 'Text for removing image from feedback',
  },
  feedback_screenshot_failed: {
    message: 'Failed to attach image. Please try again later.',
    description: 'Text shown after feedback screenshot is unable to attach',
  },
  expand_stack_item_summary: {
    message: 'Summarize',
    description: 'Tooltip text for summarizing a stack item',
  },
  collapse_stack_item_summary: {
    message: 'Collapse Summary',
    description: 'Tooltip text for collapsing a stack item summary',
  },
  stack_item_summary_source: {
    message: 'Dash Summary',
    description: 'Text for source attribution of generated stack item summary',
  },
  stack_item_summary_regenerate: {
    message: 'Regenerate',
    description: 'Button label for regenerating a stack item summary',
  },
  stack_item_summary_visibility_user_only: {
    message: 'Only visible to you',
    description: 'Tooltip text for stack item summary visibility icon',
  },
  stack_item_summary_loading_summary: {
    message: 'Summarizing...',
    description: 'Text displayed while a stack item summary is being generated',
  },
  doc_summary_qna_ask_about_file: {
    message: 'Ask about file',
    description: 'Title when showing summarize_qna',
  },
  doc_summary_qna_related_questions: {
    message: 'Related questions',
    description: 'Related questions section of summarize_qna',
  },
  doc_summary_qna_what_are_the_key_takeaways: {
    message: 'What are the key takeaways?',
    description: 'Question for summarize_qna',
  },
  doc_summary_qna_can_you_find_any_next_steps: {
    message: 'Can you find any next steps?',
    description: 'Question for summarize_qna',
  },
  doc_summary_qna_loading_summary: {
    message: 'Summarizing...',
    description: 'Text displayed while a doc summary is being generated',
  },
  doc_summary_qna_loading_answer: {
    message: 'Fetching answer...',
    description: 'Text displayed while a doc summary is being generated',
  },
  doc_summary_qna_input_placeholder: {
    message: 'Ask something about this file',
    description: 'Description when showing summarize_qna input',
  },
  doc_summary_qna_generated_using_third_party_ai: {
    message: 'Generated using third party AI. Click to learn more',
    description: 'Tooltip displayed in doc summary qna rail',
  },
  doc_summary_unexpected_error: {
    message: 'Something went wrong.',
    description: 'Unexpected error from doc summary',
  },
  doc_summary_qna_feedback_irrelevant_answer: {
    message: 'Didn’t answer question',
    description: 'Feedback option for irrelevant answer in summaryQnA',
  },
  doc_summary_qna_feedback_incorrect: {
    message: 'Factually incorrect (Hallucination)',
    description: 'Feedback option for incorrect answer in summaryQnA',
  },
  doc_summary_qna_feedback_key_details_missing: {
    message: 'Key details missing',
    description: 'Feedback option for key details missing in summaryQnA',
  },
  doc_summary_qna_feedback_verbose: {
    message: 'Answer too verbose',
    description: 'Feedback option for verbose answer in summaryQnA',
  },
  doc_summary_qna_feedback_misunderstood: {
    message: 'Answer wasn’t coherent',
    description: "Feedback option wasn't coherent in summaryQnA",
  },
  doc_summary_qna_feedback_other_option: {
    message: 'Other',
    description: 'Feedback option for other in summaryQnA',
  },
  doc_summary_qna_question_reset: {
    message: 'Reset',
    description:
      'ARIA button label for resetting the question input summaryQnA',
  },
  doc_summary_qna_ask_new_question: {
    message: 'Ask new question',
    description: 'Button label for asking a new question in summaryQnA',
  },
  doc_summary_qna_error_something_went_wrong: {
    message: 'Sorry, something went wrong.',
    description:
      'Error message displayed when an unexpected error occurs for doc summary/qna',
  },
  stack_item_qna_loading_answer: {
    message: 'Answering...',
    description: 'Text displayed while a stack item answer is being generated',
  },
  stack_item_qna_input_placeholder: {
    message: 'Ask a question…',
    description:
      'Placeholder text for the input field to ask a question about a stack item',
  },
  stack_item_qna_submit_tooltip: {
    message: 'Submit',
    description:
      'Tooltip text for the submit button to ask a question about a stack item',
  },
  stack_item_qna_question_source_prefix: {
    message: 'Asked by',
    description:
      'Prefix for the source attribution of a question asked about a stack item',
  },
  stack_item_qna_summary_unexpected_error: {
    message: 'An unexpected error occurred.',
    description:
      'Error message displayed when an unexpected error occurs for stack item summary/qna',
  },
  creating_paper_doc: {
    message: 'Creating Paper Doc...',
    description: 'User is creating their paper doc',
  },
  failed_paper_doc_creation: {
    message: 'Failed to create Paper Doc',
    description: 'Paper Doc cannot be created',
  },
  paper_doc_creation_placeholder: {
    message: 'Enter a Paper Doc filename',
    description: 'Placeholder for paper doc creation',
  },
  search_inline_cta_local_file: {
    message: 'Open on desktop',
    description:
      'Inline call-to-action for the search input field for local file search',
  },
  search_inline_cta_stack: {
    message: 'Open stack',
    description: 'Inline call-to-action for the search input field for Stacks',
  },
  search_inline_cta_stack_item: {
    message: 'Open stack item',
    description:
      'Inline call-to-action for the search input field for Stack items',
  },
  search_inline_cta_search_result: {
    message: 'Open in {{appName}}',
    description:
      'Inline call-to-action for the search input field for recommended results',
  },
  search_inline_cta_desktop_app: {
    message: 'Open',
    description:
      'Inline call-to-action for the search input field for desktop app search (long version)',
  },
  search_inline_cta_shortcut: {
    message: 'Open',
    description:
      'Inline call-to-action for the search input field for shortcuts',
  },
  people_search_empty: {
    message: 'Begin typing to search',
    description: 'Placeholder text for the people search input field',
  },
  people_search_no_results: {
    message: 'No results found',
    description: 'Text shown when there are no results for people search',
  },
  people_search_chip_placeholder: {
    message: 'People',
    description: 'Placeholder for the people search chip',
  },
  people_search_input_placeholder: {
    message: 'Search for people',
    description: 'Placeholder text for the people search input field',
  },
  people_filter_anyone_option: {
    message: 'Anyone',
    description: 'Option that represents deselecting the people filter',
  },
  shared_with_me_stacks_empty_state_title: {
    message: 'No stacks shared with me',
    description: 'Title for the invited stacks empty state',
  },
  shared_with_me_stacks_empty_state_subtitle: {
    message: 'This shows stacks shared with you by others.',
    description: 'Subtitle for the invited stacks empty state',
  },
  shared_with_me_stacks_empty_state_description: {
    message:
      'Stacks can be shared via links or direct invitation. You can leave a shared stack anytime.',
    description: 'Description for the invited stacks empty state',
  },
  clone_stack: {
    message: 'Duplicate stack',
    description: 'Text on button that shows what stack to duplicate',
  },
  confirm_clone_stack_title: {
    message: 'Sharing permissions won’t transfer',
    description: 'Modal title on whether to clone this stack',
  },
  confirm_clone_stack_body: {
    message:
      'After duplicating the stack, users from the current stack won’t have immediate access to the new one. You will need to manually invite and share the duplicated stack if access is needed.',
    description: 'Body text for archive stack confirmation',
  },
  clone_stack_snackbar_pending: {
    message: 'Duplicating “{{stackName}}”',
    description: 'Snackbar text for starting a duplication',
  },
  clone_stack_snackbar_error: {
    message: 'Error duplicating “{{stackName}}”',
    description: 'Snackbar text for error in duplicating a stack',
  },
  clone_stack_snackbar_error_already_ongoing: {
    message: 'Already duplicating “{{stackName}}”',
    description: 'Snackbar text for when a duplication is already ongoing',
  },
  clone_stack_snackbar_success: {
    message: 'Duplicated “{{stackName}}”',
    description: 'Snackbar text for successfully duplicated a stack',
  },
  sharing_typeahead_empty_hint: {
    message: 'Enter a valid email address',
    description:
      'Hint text for the sharing typeahead when no suggestions have been returned and the value is not a valid email address',
  },
  sharing_typeahead_completion_hint: {
    message: 'Press ‘Enter’ to select this email address',
    description: 'Hint text for the sharing typeahead completion',
  },
  activity_feed_header_title: {
    message: 'Activity Feed',
    description: 'Title for the activity feed module',
  },
  activity_feed_filters_by_person_label: {
    message: 'Filter by person',
    description:
      'Label to show next to above the filter by person menu in the activity feed',
  },
  activity_feed_filters_by_person_everyone: {
    message: 'Everyone',
    description:
      'Filter option for activity feed to show activities for everyone',
  },
  activity_feed_filters_by_person_me: {
    message: 'Only me',
    description:
      'Filter option for activity feed to show only activities related to the user',
  },
  activity_feed_item_type_stack: {
    message: 'Stack',
    description: 'Label shown when the activity object was a Stack',
  },
  activity_feed_action_edit_stack_name_owner: {
    message:
      '{{actorName}} changed the title of your “{{oldTitle}}” stack to “<stackLink>{{newTitle}}</stackLink>”',
    description:
      'Activity displayed when someone else changes the title of your stack.',
  },
  activity_feed_action_edit_stack_description_owner: {
    message:
      '{{actorName}} changed the description of your <stackLink>{{stackName}}</stackLink> stack from “{{oldDescription}}” to “{{newDescription}}”',
    description:
      'Activity displayed when someone else changes the description of your stack.',
  },
  activity_feed_action_archive_stack_owner: {
    message:
      '{{actorName}} archived your <stackLink>{{stackName}}</stackLink> stack',
    description:
      'Activity displayed when someone else changes the description of your stack.',
  },
  activity_feed_action_share_stack_with_you: {
    message:
      '{{actorName}} shared <stackLink>{{stackName}}</stackLink> stack with you',
    description: 'Activity displayed when a stack is shared with the user.',
  },
  activity_feed_action_share_file_with_you: {
    message:
      '{{actorName}} shared <stackLink>{{objectName}}</stackLink> with you',
    description: 'Activity displayed when an object is shared with the user.',
  },
  activity_feed_action_add_stack_item_owner: {
    message:
      '{{actorName}} added <addedItemLink>{{addedItemName}}</addedItemLink> to your <stackLink>{{stackName}}</stackLink> stack',
    description:
      'Activity displayed when someone else adds a stack item from your stack.',
  },
  activity_feed_action_remove_stack_item_owner: {
    message:
      '{{actorName}} removed <removedItemLink>{{removedItemName}}</removedItemLink> from your <stackLink>{{stackName}}</stackLink> stack',
    description:
      'Activity displayed when someone else removes a stack item from your stack.',
  },
  activity_feed_action_edit_file: {
    message: '{{actorName}} modified <fileLink>{{fileName}}</fileLink>',
    description: 'Activity displayed when a file is modified by someone.',
  },
  activity_feed_action_edit_file_me: {
    message: 'You modified <fileLink>{{fileName}}</fileLink>',
    description: 'Activity displayed when a file is modified by the user.',
  },
  activity_feed_action_edit_file_no_actor: {
    message: '<fileLink>{{fileName}}</fileLink> was modified',
    description:
      "Activity displayed when a file is modified but we don't know who did it.",
  },
  activity_feed_end_of_feed_title: {
    message: 'That’s all for now',
    description:
      'Message displayed on top line when user scrolls to end of feed',
  },
  activity_feed_end_of_feed_subtitle: {
    message: 'Check back later for more updates',
    description:
      'Message displayed on bottom line when user scrolls to end of feed',
  },
  activity_feed_empty_state_text_reset_filters: {
    message:
      'We don’t have any new content for you - try resetting your filters or refreshing later.',
    description:
      'Text displayed when the activity feed is empty and filters are set.',
  },
  activity_feed_empty_state_text_reset_filters_button: {
    message: 'Reset filters',
    description:
      'Button label for resetting your filters in the activity feed empty state.',
  },
  summarize_tip_title: {
    message: 'Ask about this file',
    description:
      'Title of the tip displayed when the search call to action is clicked',
  },
  summarize_tip_message: {
    message:
      'Dash will summarize your files and compose a concise answer for you. Try it now.',
    description:
      'Message on the tip displayed when the search call to action is clicked',
  },
  settings_banner_title: {
    message: 'Search even faster. Enable Dash wherever you work.',
    description:
      'Title displayed in the feature banner within the settings page',
  },
  settings_banner_message: {
    message:
      'Save time by utilizing keyboard shortcuts on your desktop to search Dash even quicker. Set Dash as your new tab with the browser extension to streamline your workflow.',
    description:
      'Message displayed in the feature banner within the settings page',
  },
  settings_banner_alt: {
    message: 'Searching faster',
    description:
      'Aria label for the image in the feature banner within the settings page',
  },
  settings_banner_image_alt: {
    message: 'Settings banner',
    description:
      'Alt text for the image in the feature banner within the settings page',
  },
  extension_call_to_action: {
    message: 'Browser extension',
    description: 'Call to action button text to install the browser extension',
  },
  desktop_app_call_to_action: {
    message: 'Desktop app',
    description:
      'Call to action button text to install the desktop application',
  },
  content_type_filter_title: {
    message: 'Type',
    description: 'Title for the content type filter',
  },
  content_type_filter_title_with_count: {
    message: 'Type • {{count}}',
    description: 'Title for the content type filter',
  },
  share_stack: {
    message: 'Share stack',
    description: 'Share stack Button',
  },
  result_created_by: {
    message: 'Created by',
    description: 'Shown next to creator metadata on search results',
  },
  result_updated_by: {
    message: 'Updated by',
    description: 'Shown next to creator metadata on search results',
  },
  result_sent_by: {
    message: 'Sent by',
    description: 'Shown next to sender metadata on search results',
  },
  stacks_banner_title: {
    message: 'Stacks are the home for all your key resources',
    description: 'Title displayed in the feature banner within the stacks page',
  },
  stacks_banner_message: {
    message:
      'From employee onboarding materials to client hand-off documents, you can use stacks to group, discover, and share important resources in one location. Your stacks remain private until you decide to share them.',
    description:
      'Message displayed in the feature banner within the stacks page',
  },
  stacks_banner_alt: {
    message: 'Stacks banner logo',
    description:
      'Message displayed in the feature banner within the stacks page',
  },
  stacks_banner_call_to_action: {
    message: 'Create a stack',
    description:
      'Call to action button text to create a stack on the stacks banner',
  },
  num_attendees_one: {
    message: '{{count}} attendee',
    description: 'Number of meeting attendees',
  },
  num_attendees_few: {
    message: '{{count}} attendees',
    description: 'Number of meeting attendees',
  },
  num_attendees_many: {
    message: '{{count}} attendees',
    description: 'Number of meeting attendees',
  },
  num_attendees_other: {
    message: '{{count}} attendees',
    description: 'Number of meeting attendees',
  },
  one_known_attendee_one: {
    message: '{{attendee_one}} and {{count}} other',
    description: 'One named attendee and a number of other attendees',
  },
  one_known_attendee_few: {
    message: '{{attendee_one}} and {{count}} others',
    description: 'One named attendee and a number of other attendees',
  },
  one_known_attendee_many: {
    message: '{{attendee_one}} and {{count}} others',
    description: 'One named attendee and a number of other attendees',
  },
  one_known_attendee_other: {
    message: '{{attendee_one}} and {{count}} others',
    description: 'One named attendee and a number of other attendees',
  },
  two_known_attendees_zero: {
    message: '{{attendee_one}} and {{attendee_two}}',
    description: 'Two named attendees and no other attendees',
  },
  two_known_attendees_one: {
    message: '{{attendee_one}}, {{attendee_two}}, and {{count}} other',
    description: 'Two named attendees and a number of other attendees',
  },
  two_known_attendees_few: {
    message: '{{attendee_one}}, {{attendee_two}}, and {{count}} others',
    description: 'Two named attendees and a number of other attendees',
  },
  two_known_attendees_many: {
    message: '{{attendee_one}}, {{attendee_two}}, and {{count}} others',
    description: 'Two named attendees and a number of other attendees',
  },
  two_known_attendees_other: {
    message: '{{attendee_one}}, {{attendee_two}}, and {{count}} others',
    description: 'Two named attendees and a number of other attendees',
  },
  created_by_me: {
    message: 'Created by me',
    description: 'Chip label for stacks created by the user',
  },
  welcome_modal_title_step_0: {
    message: 'Welcome to Dropbox Dash!',
    description: 'Intro title for the welcome modal',
  },
  welcome_modal_message_step_0: {
    message:
      'Use Dash to quickly find, gather, and summarize content from the apps you already use, all in one place.  Take a look at some key features by clicking “Next” below.',
    description: 'Intro message for the welcome modal',
  },
  welcome_modal_title_step_1: {
    message: 'A home page built for focus',
    description: 'Title for the home page step of the welcome modal',
  },
  welcome_modal_message_step_1: {
    message:
      'Your home page allows you to view your schedule at a glance and get up to speed on important work.',
    description: 'Message for the home page step of the welcome modal',
  },
  welcome_modal_title_step_2: {
    message: 'Search and ask about your files',
    description: 'Title for the search step of the welcome modal',
  },
  welcome_modal_message_step_2: {
    message:
      'Search Dash to find exactly what you need across all your connected apps. You can also ask questions about a file to get answers related to your work.',
    description: 'Message for the search step of the welcome modal',
  },
  welcome_modal_title_step_3: {
    message: 'Use stacks to get organized',
    description: 'Title for the stacks step of the welcome modal',
  },
  welcome_modal_message_step_3: {
    message:
      'Stacks help you group different types of content by project, team, or personal preference—whether it’s files, apps, or browser links.',
    description: 'Message for the stacks step of the welcome modal',
  },
  welcome_modal_spotlight_alt: {
    message: 'Welcome Modal Spotlight',
    description: 'Alt text for the main image of the welcome modal',
  },
  next: {
    message: 'Next',
    description: 'Action text that is to navigate to the next element in list',
  },
  logout_screen_title: {
    message: 'Find your content in one place',
    description: 'Title of logout screen',
  },
  logout_screen_purchaser_note: {
    message:
      'Your admin has purchased a seat for you on Dropbox Dash. Click ’Continue to log in’ to join your team.',
    description:
      'Note to the user on logout screen that their admin has purchased them a seat.',
  },
  logout_screen_copyright: {
    message: 'Dropbox Dash \u00A9 {{copyrightYear}}',
    description: 'Copyright note on the logout screen',
  },
  stack_item_updated_ago: {
    message: 'Updated {{timeAgo}}',
    description:
      'Message displayed to indicate when stack item was last updated',
  },
  activity_feed_empty_state_text: {
    message: 'See the latest activity from your connected apps',
    description: 'Text on the activity feed empty state',
  },
  recommended_header: {
    message: 'Recommended',
    description: 'Header for the list of recommended stack items',
  },
  create_stack_tour_name_stack_title: {
    message: 'Name your stack',
    description: 'Title of the name your stack tour item',
  },
  create_stack_tour_name_stack_message: {
    message:
      'Often, people use stacks to organize important content like onboarding resources, project docs, client handoffs, and more.',
    description: 'Message on the name your stack tour item',
  },
  create_stack_tour_more_files_title: {
    message: 'Add more than just files',
    description: 'Title of the file selector tour item',
  },
  create_stack_tour_more_files_message: {
    message: 'Type to search for the content you want to add, or paste a link.',
    description: 'Message on the file selector tour item',
  },
  skip_tour: {
    message: 'Skip tour',
    description: 'Skip tour call to action',
  },
  next_tour: {
    message: 'Next',
    description: 'Next button tour call to action',
  },
  done_tour: {
    message: 'Done',
    description: 'Done button tour call to action to dismiss tour as finished',
  },
  all_day_event: {
    message: '{{startDate}} - {{endDate}}',
    description: 'the date for an all day event',
  },
  initial_sync_message_single: {
    message: '{{connector}} is still syncing. Results may be incomplete.',
    description:
      'Message shown to the user if 1 app is syncing on initial sync',
  },
  initial_sync_message_with_count: {
    message:
      '{{connectorCount}} new apps are still syncing. Results may be incomplete.',
    description:
      'Message shown to the user if more than 3 apps are syncing on initial sync',
  },
  initial_sync_message_list: {
    message: '{{connectorList}} are still syncing. Results may be incomplete.',
    description:
      'Message shown to the user if 3 or less apps are syncing on initial sync',
  },
  conjunction_and: {
    message: 'and',
    description: 'Separator in a list of terms',
  },
  error: {
    message: 'Error',
    description: 'Generic error text to use in error messages',
  },
  retry_search: {
    message: 'Retry your search',
    description: 'Retry search button text',
  },
  cannot_have_more_than_max_keystrokes_keystrokes: {
    message: 'Cannot have more than {{MAX_KEYSTROKES}} keystrokes',
    description:
      'Error message when the user has typed too many keystrokes in a hotkey',
  },
  company_apps_empty_state_title: {
    message: 'Your company hasn’t added any apps yet',
    description: 'Title displayed if there are no configured apps',
  },
  company_apps_empty_state_message: {
    message:
      'Your company selects and adds the apps you can use in Dash. Contact your admin for more apps.',
    description: 'Message displayed if there are no configured apps',
  },
  file_type_filter_any_option: {
    message: 'Any type',
    description: 'Option to filter by any file type',
  },
} as const satisfies TransObjShape;
