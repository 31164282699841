import { Overlay } from '@dropbox/dig-components/overlay';
import { TypeaheadKeyboardShortcutBanner } from '@mirage/education/TypeaheadKeyboardShortcutBanner';
import { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';
import { ActivationTypes } from '@mirage/service-connectors/useActivationForConnectors';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useScrollToTypeaheadItem } from '../hooks/useScrollToTypeaheadItem';
import { TypeaheadConnectorActivation } from './TypeaheadConnectorActivation';
import style from './TypeaheadOverlay.module.css';
import TypeaheadResultRow from './TypeaheadResultRow';

type Props = {
  open?: boolean;
  anchor: React.RefObject<HTMLElement>;
  query: string;
  results?: Array<TypeaheadResult>;
  selectedIdx?: number;
  setSelectedIdx: (i: number) => void; // can be removed when dash_2024_07_23_typeahead_launch_indicator is removed
  onResultIndexClicked: (i: number) => void;
  onListItemView?: (item: TypeaheadResult, index: number) => void;
  isMouseActive?: boolean;
  activationType?: ActivationTypes;
  children?: React.ReactNode;
  fullWidth?: boolean;
  setTypeaheadOpen?: (open: boolean) => void;
  disableActivation?: boolean;
  isAugustStartPageEnabled?: boolean;
};

export default function TypeaheadOverlay({
  open = true,
  anchor,
  results = [],
  selectedIdx = 0,
  setSelectedIdx,
  onResultIndexClicked,
  query = '',
  onListItemView,
  isMouseActive = true,
  activationType,
  children,
  fullWidth = false,
  setTypeaheadOpen,
  disableActivation = false,
  isAugustStartPageEnabled = false,
}: Props) {
  const isDarwin = EnvCtx?.platform === 'darwin';
  const paragraphObserver = useRef<IntersectionObserver>();
  const visibleResultsRef = useRef<Set<string>>(new Set());
  const { itemRefs, listContainerRef } = useScrollToTypeaheadItem(
    selectedIdx,
    results,
  );

  useEffect(() => {
    if (onListItemView === undefined) return;
    if (!open) {
      // Clear visible results in order to ensure that we are logging visible results properly
      visibleResultsRef.current = new Set();
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const nVisibleResults: string[] = [];
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const { target } = entry;
            const entryUuid = target.getAttribute('data-x-uuid');
            if (entryUuid) {
              nVisibleResults.push(entryUuid);
              if (!visibleResultsRef.current.has(entryUuid)) {
                const index = results.findIndex((r) => r.uuid === entryUuid);
                if (index >= 0) {
                  const item = results[index];
                  onListItemView(item, index);
                }
              }
            }
          }
        });
        visibleResultsRef.current = new Set([
          ...visibleResultsRef.current,
          ...nVisibleResults,
        ]);
      },
      { threshold: 0.75 },
    );
    paragraphObserver.current = observer;

    return () => {
      paragraphObserver.current = undefined;
    };
  }, [results, open, onListItemView]);

  return (
    <>
      {open && (
        <div ref={listContainerRef}>
          <Overlay
            className={classnames(style.overlay, {
              [style.opened]: open,
              [style.activationOverlay]: activationType,
              [style.fullWidthSearchbar]: fullWidth,
              [style.augustRevision]: isAugustStartPageEnabled,
            })}
            placement="bottom"
            anchorRef={anchor}
            setWidthSameAsAnchor={true}
            boundaryRef={listContainerRef}
          >
            {children}
            <ul
              className={classnames(style.listContainer, {
                [style.animateFadeIn]: open,
              })}
            >
              {results.map((result, i) => (
                <TypeaheadResultRow
                  result={result}
                  key={result.uuid}
                  isSelected={i === selectedIdx}
                  setIsSelected={() => setSelectedIdx(i)}
                  onClick={() => {
                    onResultIndexClicked(i);
                  }}
                  query={query}
                  ref={itemRefs[i]}
                  observer={paragraphObserver.current}
                  isMouseActive={isMouseActive}
                  fullWidth={fullWidth}
                />
              ))}
              {!disableActivation && activationType && (
                <TypeaheadConnectorActivation
                  activationType={activationType}
                  setTypeaheadOpen={setTypeaheadOpen}
                />
              )}
            </ul>
            <div className={style.banner}>
              <TypeaheadKeyboardShortcutBanner isDarwin={isDarwin} />
            </div>
          </Overlay>
        </div>
      )}
    </>
  );
}
