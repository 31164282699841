import { ContentIcon } from '@dropbox/dash-component-library';
import { Chip } from '@dropbox/dig-components/chip';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CalculatorLine,
  ClockLine,
  CloseLine,
  LinkLine,
  SearchLine,
  StacksLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { BASE_SEARCH_RESULTS_URL } from '@mirage/search/hooks/useQueryParams';
import { Highlighter } from '@mirage/search/SearchResults/Highlighter';
import { TypeaheadResultSubtitle } from '@mirage/search/SearchResults/Metadata/TypeaheadResultSubtitle';
import {
  ShortcutSubtitle,
  ShortcutTitle,
} from '@mirage/search/SearchResults/ShortcutTitle';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { tagged } from '@mirage/service-logging';
import {
  copyToClipboard,
  openLocalPath,
  openURL,
} from '@mirage/service-platform-actions';
import { stackGetShareId } from '@mirage/service-stacks/service/utils';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { getKeyMaps } from '@mirage/shared/hotkeys/hotkeysKeyMap';
import { URLShortcutIcon, UrlSrcIcon } from '@mirage/shared/icons';
import DesktopApplicationIcon from '@mirage/shared/icons/DesktopApplicationIcon';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { PrettyShortcuts } from '@mirage/shared/keyboard-shortcuts/PrettyShortcuts';
import { CREATE_STACK_ACTION_UUID } from '@mirage/shared/search/url-shortcut';
import { showSnackbar } from '@mirage/shared/snackbar';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import i18n from '@mirage/translations';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type { LaunchMethod } from '@mirage/analytics/events/enums/launch_method';
import type { useTypeaheadSearch } from '@mirage/service-typeahead-search/hooks';

const logger = tagged('useConvertToTypeaheadResults');

export type TypeaheadResult = {
  type: typeahead.ResultType;
  uuid: string;
  scoredResult: typeahead.ScoredResult;
  icon: JSX.Element | null;
  title: JSX.Element | string;
  subtitle?: JSX.Element | string;
  launchResult?: (launchMethod: LaunchMethod) => void;
  removeResult?: () => void;
  copyResult?: () => void;
  priority?: number;
  accessory?: JSX.Element | null;
  /** overwrites accessory if it's defined */
  selectedAccessory?: JSX.Element | null;
  /** overwrites accessory or selectedAccessory if either is defined */
  hoverAccessory?: JSX.Element | null;
};

type UseTypeaheadSearch = ReturnType<typeof useTypeaheadSearch>;

export function useConvertToTypeaheadResults({
  typeaheadQuery,
  setTypeaheadQuery,
  shouldSearchBeBoosted,
  handleSearchSubmit,
  removePreviousQuery,
  onInteractedWithResult,
  papLogResultOpen,
  papLogResultCopy,
}: {
  typeaheadQuery: string;
  setTypeaheadQuery: (typeaheadQuery: string) => void;
  shouldSearchBeBoosted: boolean;
  handleSearchSubmit: (
    result: typeahead.ScoredPreviousQuery | typeahead.ScoredSuggestedQuery,
    isDefaultSearchDash: boolean,
  ) => void;
  removePreviousQuery: (result: typeahead.ScoredPreviousQuery) => void;
  /** Fired for either a copy or open on everything but "search dash" (SuggestedQuery) */
  onInteractedWithResult: (result: typeahead.ScoredResult) => void;
  papLogResultOpen: UseTypeaheadSearch['papLogResultOpen'];
  papLogResultCopy: UseTypeaheadSearch['papLogResultCopy'];
}) {
  const platform = EnvCtx.platform;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isSERP = pathname === BASE_SEARCH_RESULTS_URL;

  const enableTypeaheadLaunchIndicatorRules =
    useFeatureFlagValue('dash_2024_07_23_typeahead_launch_indicator') === 'ON';

  return useCallback(
    (searchResults: typeahead.ScoredResult[]): TypeaheadResult[] =>
      searchResults
        .map((result) => {
          const baseResult = {
            type: result.type,
            uuid: result.uuid,
            scoredResult: result,
          } satisfies Partial<TypeaheadResult>;

          switch (result.type) {
            case typeahead.ResultType.DesktopFile: {
              return {
                ...baseResult,
                icon: <FileContentIcon content={result.result} />,
                title: result.result.title,
                subtitle: i18n.t('search_result_recent_file'),
                launchResult: (launchMethod: LaunchMethod) => {
                  if (result.result.url) {
                    openLocalPath(result.result.url);
                    papLogResultOpen(result, launchMethod);
                    onInteractedWithResult(result);
                    if (enableTypeaheadLaunchIndicatorRules && !isSERP) {
                      setTypeaheadQuery('');
                      navigate('/');
                    }
                  }
                },
              };
            }
            case typeahead.ResultType.DesktopApplication:
              return {
                ...baseResult,
                icon: (
                  <DesktopApplicationIcon result={result.result} size={32} />
                ),
                title: result.result.title,
                subtitle: i18n.t('desktop_application_subtitle'),
                launchResult: (launchMethod: LaunchMethod) => {
                  if (result.result.url) {
                    openLocalPath(result.result.url);
                    papLogResultOpen(result, launchMethod);
                    onInteractedWithResult(result);
                    if (enableTypeaheadLaunchIndicatorRules && !isSERP) {
                      setTypeaheadQuery('');
                      navigate('/');
                    }
                  }
                },
              };
            case typeahead.ResultType.Stack:
              return {
                ...baseResult,
                icon: <UIIcon src={StacksLine} />,
                title:
                  result.result.stack_data?.name ||
                  i18n.t('stacks_typeahead_default_title'),
                subtitle: i18n.t('stacks_typeahead_subtitle'),
                launchResult: (launchMethod: LaunchMethod) => {
                  navigate(`/stacks/${stackGetShareId(result.result) ?? ''}`);
                  papLogResultOpen(result, launchMethod);
                  onInteractedWithResult(result);
                  if (enableTypeaheadLaunchIndicatorRules && !isSERP) {
                    setTypeaheadQuery('');
                  }
                },
                copyResult: () => {
                  if (result.result.sharing_data?.shared_link?.url) {
                    copyToClipboard(result.result.sharing_data.shared_link.url);
                    showSnackbar({ title: i18n.t('copied_link_to_clipboard') });
                    papLogResultCopy(result);
                    onInteractedWithResult(result);
                  }
                },
              };
            case typeahead.ResultType.StackItem:
              return {
                ...baseResult,
                icon: (
                  <ContentIcon
                    icon={
                      result.result.url ? (
                        <UrlSrcIcon
                          name="Stack item"
                          size="100%"
                          iconUrl={faviconSrcForSrcUrl(result.result.url)}
                        />
                      ) : (
                        <UIIcon src={LinkLine} />
                      )
                    }
                    hasBackground={true}
                    size="medium"
                  />
                ),
                title: result.result.name,
                subtitle:
                  result.result.stackIds.length > 0 ? (
                    <Chip variant="standard" size="small">
                      <Chip.IconAccessory>
                        <UIIcon src={StacksLine} />
                      </Chip.IconAccessory>
                      <Chip.Content>
                        {result.result.stackIds.length === 1 &&
                        result.result.stackIds[0].name
                          ? result.result.stackIds[0].name
                          : `${result.result.stackIds.length} ${i18n.t(
                              'stack_item_typeahead_subtitle_badge',
                            )}`}
                      </Chip.Content>
                    </Chip>
                  ) : undefined,
                launchResult: (launchMethod: LaunchMethod) => {
                  if (result.result.url) {
                    openURL(result.result.url);
                    papLogResultOpen(result, launchMethod);
                    onInteractedWithResult(result);
                    if (enableTypeaheadLaunchIndicatorRules && !isSERP) {
                      setTypeaheadQuery('');
                      navigate('/');
                    }
                  }
                },
                copyResult: () => {
                  if (result.result.url) {
                    copyToClipboard(result.result.url);
                    showSnackbar({ title: i18n.t('copied_link_to_clipboard') });
                    papLogResultCopy(result);
                    onInteractedWithResult(result);
                  }
                },
              };
            case typeahead.ResultType.SearchResult:
            case typeahead.ResultType.Recommendation:
              return {
                ...baseResult,
                icon: <FileContentIcon content={result.result} size="medium" />,
                title: result.result.title,
                subtitle: <TypeaheadResultSubtitle result={result} />,
                launchResult: (launchMethod: LaunchMethod) => {
                  if (result.result.url) {
                    openURL(result.result.url);
                    papLogResultOpen(result, launchMethod);
                    onInteractedWithResult(result);
                    if (enableTypeaheadLaunchIndicatorRules && !isSERP) {
                      setTypeaheadQuery('');
                      navigate('/');
                    }
                  }
                },
                copyResult: () => {
                  if (result.result.url) {
                    copyToClipboard(result.result.url);
                    showSnackbar({ title: i18n.t('copied_link_to_clipboard') });
                    papLogResultCopy(result);
                    onInteractedWithResult(result);
                  }
                },
              };
            case typeahead.ResultType.PreviousQuery: {
              const accessory = (
                <IconButtonWithTooltip
                  aria-label={i18n.t('aria_remove_previous_query')}
                  variant="transparent"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    removePreviousQuery(result);
                  }}
                  tooltipProps={{
                    title: i18n.t('tooltip_remove_previous_query'),
                  }}
                >
                  <UIIcon src={CloseLine} />
                </IconButtonWithTooltip>
              );
              return {
                ...baseResult,
                icon: <UIIcon src={ClockLine} />,
                title: result.result.query,
                subtitle: '',
                launchResult: (launchMethod: LaunchMethod) => {
                  handleSearchSubmit(result, false);
                  papLogResultOpen(result, launchMethod);
                  onInteractedWithResult(result);
                },
                removeResult: () => {
                  removePreviousQuery(result);
                },
                selectedAccessory: accessory,
                hoverAccessory: accessory,
              };
            }
            case typeahead.ResultType.SuggestedQuery:
              return {
                ...baseResult,
                icon: <UIIcon src={SearchLine} />,
                title: result.result.query,
                subtitle: i18n.t('search_query_byline'),
                launchResult: (launchMethod: LaunchMethod) => {
                  handleSearchSubmit(result, true);
                  papLogResultOpen(result, launchMethod);
                },
                priority:
                  isSERP || shouldSearchBeBoosted || typeaheadQuery.length < 3
                    ? 1
                    : 0,
                accessory: (
                  <PrettyShortcuts
                    shortcut={getKeyMaps(platform).typeahead.searchQuery}
                    isDarwin={platform === 'darwin'}
                  />
                ),
                selectedAccessory: <></>, // Don't show anything when selected
                copyResult: () => {
                  if (result.result.query?.length > 0) {
                    copyToClipboard(result.result.query);
                  }
                },
              };
            case typeahead.ResultType.URLShortcut:
              return {
                ...baseResult,
                icon: <URLShortcutIcon result={result.result} />,
                title: (
                  <ShortcutTitle titles={result.result.parameters.title} />
                ),
                subtitle: (
                  <ShortcutSubtitle
                    hotword={result.result.parameters.activeHotword}
                    subtitle={
                      <Highlighter
                        title={result.result.parameters.url}
                        highlightWords={result.result.parameters.highlightWords}
                      />
                    }
                  />
                ),
                launchResult: (launchMethod: LaunchMethod) => {
                  if (result.uuid === CREATE_STACK_ACTION_UUID) {
                    // create stack
                    navigate('/stacks/new');
                    papLogResultOpen(result, launchMethod);
                    onInteractedWithResult(result);
                    setTypeaheadQuery('');
                    return;
                  }
                  if (result.result.parameters.url) {
                    openURL(result.result.parameters.url);
                    papLogResultOpen(result, launchMethod);
                    onInteractedWithResult(result);
                    if (enableTypeaheadLaunchIndicatorRules && !isSERP) {
                      setTypeaheadQuery('');
                      navigate('/');
                    }
                  }
                },
                copyResult: () => {
                  if (result.result.parameters.url) {
                    copyToClipboard(result.result.parameters.url);
                    showSnackbar({ title: i18n.t('copied_link_to_clipboard') });
                    papLogResultCopy(result);
                    onInteractedWithResult(result);
                  }
                },
                // Give this result priority over others. We want URL shortcuts appearing 1st in result list
                priority: 1,
              };
            case typeahead.ResultType.MathCalculation: {
              const copyMath = () => {
                copyToClipboard(result.result.answer);
                showSnackbar({
                  title: i18n.t('copied_math_to_clipboard'),
                });
                papLogResultCopy(result);
                onInteractedWithResult(result);
              };

              return {
                ...baseResult,
                icon: <UIIcon src={CalculatorLine} />,
                title: (
                  <Highlighter
                    title={`${typeaheadQuery.trim().replace(/\s+/g, ' ')} = ${
                      result.result.answer
                    }`}
                    highlightWords={[`= ${result.result.answer}`]}
                  />
                ),
                subtitle: 'Calculator',
                launchResult: copyMath,
                copyResult: copyMath,
                // Give this result priority over others. We want math appearing 1st in result list
                priority: 1,
              };
            }
            default:
              result satisfies never;
              logger.warn('Unrecognized typeahead result type');
              return result;
          }
        })
        .sort(handleSortByPriority)
        .filter((result) => !!result),
    [
      enableTypeaheadLaunchIndicatorRules,
      handleSearchSubmit,
      isSERP,
      navigate,
      onInteractedWithResult,
      papLogResultCopy,
      papLogResultOpen,
      platform,
      removePreviousQuery,
      setTypeaheadQuery,
      shouldSearchBeBoosted,
      typeaheadQuery,
    ],
  );
}

const handleSortByPriority = (a?: TypeaheadResult, b?: TypeaheadResult) => {
  // Assign 0 as default if 'priority' is undefined
  const aPriority = a?.priority ?? 0;
  const bPriority = b?.priority ?? 0;

  return bPriority - aPriority;
};
