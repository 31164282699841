import { Card, CardContentsProps } from '@dropbox/dash-component-library';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import classnames from 'classnames';
import styles from './ModuleCard.module.css';

interface ModuleCardProps {
  children: React.ReactNode;
  breakout?: CardContentsProps['breakout'];
  className?: string;
  style?: React.CSSProperties;
  withHeader?: React.ReactNode;
  onClick?: () => void;
  isLink?: boolean;
}

export const ModuleCard = ({
  children,
  breakout = 'standard',
  className,
  style,
  withHeader,
  onClick,
  isLink,
}: ModuleCardProps) => {
  return (
    <Card
      variant="outline"
      style={style}
      isLink={isLink || !!onClick}
      className={classnames(
        styles.card,
        {
          [styles.hasHeader]: !!withHeader,
        },
        className,
      )}
    >
      {withHeader}
      <Card.Contents
        onClick={onClick}
        breakout={breakout}
        onKeyDown={onClick && onKeyDownCommitFn(onClick)}
        className={classnames(styles.cardContents, {
          [styles.cardBreakoutSmall]: breakout === 'small',
          [styles.cardBreakoutXSmall]: breakout === 'xsmall',
          [styles.cardBreakoutStandard]: breakout === 'standard',
          [styles.cardBreakoutLarge]: breakout === 'large',
          [styles.cardBreakoutXLarge]: breakout === 'xlarge',
          [styles.cardLink]: !!onClick,
        })}
      >
        {children}
      </Card.Contents>
    </Card>
  );
};
