import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { RotateLeftLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { CloseDashChatType } from '@mirage/analytics/events/enums/close_dash_chat_type';
import { DashAnswersFeedbackTag } from '@mirage/analytics/events/enums/dash_answers_feedback_tag';
import { DashQueryType } from '@mirage/analytics/events/enums/dash_query_type';
import { PAP_Click_DashAnswersFeedback } from '@mirage/analytics/events/types/click_dash_answers_feedback';
import { PAP_Click_DashAnswersSource } from '@mirage/analytics/events/types/click_dash_answers_source';
import { PAP_Click_DashAnswersTaggedFeedback } from '@mirage/analytics/events/types/click_dash_answers_tagged_feedback';
import { PAP_Copy_DashAnswers } from '@mirage/analytics/events/types/copy_dash_answers';
import { PAP_Shown_DashNoAnswers } from '@mirage/analytics/events/types/shown_dash_no_answers';
import {
  ANSWER_DWELL_END_REASONS,
  ANSWERS_SESSION_END_REASONS,
} from '@mirage/analytics/session/session-utils';
import {
  AnswersEmptyState,
  Conversation,
  ErrorMessage,
  FullPageAnswersEmptyState,
  MessageSecondaryFeedback,
  QueryInput,
  SpinnerSystemMessage,
  SystemMessage,
  UserMessage,
} from '@mirage/conversations';
import { answersSecondaryFeedbackOptions } from '@mirage/conversations/FeedbackUtils/FeedbackUtils';
import { Role } from '@mirage/conversations/types';
import { useAnswersConversation } from '@mirage/mosaics/AnswersConversation/useAnswersConversation';
import { getAnswerStringForLogging } from '@mirage/mosaics/AnswersConversation/utils';
import { DynamicPanelPapProperties } from '@mirage/mosaics/DynamicPanel/atoms';
import { AnswerResponse } from '@mirage/service-dbx-api/service/answers';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { copyToClipboard } from '@mirage/service-platform-actions';
import { openResult } from '@mirage/service-result-actions';
import { usePageVisibility } from '@mirage/shared/hooks/usePageVisibility';
import { FeedbackOptions } from '@mirage/shared/types';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './AnswersConversation.module.css';

import type { SearchResult } from '@mirage/service-dbx-api';

type AnswersConversationProps = {
  onClose?: (closeChatType: CloseDashChatType) => void;
  isFullPage?: boolean;
  enableSuggestedQuestions?: boolean;
  papData?: DynamicPanelPapProperties;
  onOpenFeedbackSnackbar: () => void;
  onOpenCopyTextSnackbar: () => void;
  attachment?: SearchResult;
  isSummarize?: boolean;

  // To pre-populate the first Q&A pair
  initialQuery?: string;
  initialAnswer?: AnswerResponse;
};

const enum PageModes {
  initial, // no query, null state
  conversation, // conversation is ready to display

  loading,
}

const AnswersConversation: React.FC<AnswersConversationProps> = ({
  onClose,
  isFullPage = false,
  onOpenFeedbackSnackbar,
  onOpenCopyTextSnackbar,
  attachment,
  isSummarize = false,
  papData,
  enableSuggestedQuestions,

  initialQuery,
  initialAnswer,
}) => {
  const [pageMode, setPageMode] = useState<PageModes>(PageModes.initial);
  const [showSecondaryFeedbackModal, setShowSecondaryFeedbackModal] =
    useState(false);
  const actionSurfaceComponent = isFullPage ? undefined : 'ask_dialog';
  const setFocusRef = useRef<() => void>();
  const scrollToBottomRef = useRef<() => void>();
  const enableMultiTurn = !!useFeatureFlagValue('dash_answers_multiturn');

  const {
    conversation,
    fetchAnswersConversation,
    answerLoggingId,
    submittedQuery,
    numQuestionsAsked,
    dashAnswerRequestId,
    resetConversation,
  } = useAnswersConversation({
    attachment,
    papData,
    actionSurfaceComponent,
    initialQuery,
    initialAnswer,
    enableMultiTurn,
  });

  const {
    reportPapEvent,
    explicitAnswersSessionManager,
    explicitAnswerDwellManager,
    searchSessionManager,
  } = useMirageAnalyticsContext();

  useEffect(() => {
    // Focus the input
    setFocusRef.current?.();
  }, []);

  useEffect(() => {
    // Scroll to the bottom of the conversation when new messages get added
    scrollToBottomRef.current?.();
  }, [conversation.length]);

  useEffect(() => {
    // If we're picking up from an initial conversation, set page mode and focus input
    if (initialQuery && initialAnswer) {
      setPageMode(PageModes.conversation);
    }
  }, [initialQuery, initialAnswer]);

  useEffect(() => {
    explicitAnswersSessionManager.extendOrCreateSession('new_session');

    return () => {
      explicitAnswersSessionManager.endSession(
        ANSWERS_SESSION_END_REASONS.EXITED,
      );
      explicitAnswerDwellManager.endSession(ANSWER_DWELL_END_REASONS.EXITED);
    };
  }, []);

  useEffect(() => {
    explicitAnswersSessionManager.updateProperties({
      entryPoint: papData?.entryPoint,
      dashAnswerRequestId,
    });
  }, [papData?.entryPoint, dashAnswerRequestId]);

  const isPageVisible = usePageVisibility();
  useEffect(() => {
    if (isPageVisible && pageMode === PageModes.conversation) {
      // Start dwell again only if answer is in view
      explicitAnswerDwellManager.extendOrCreateSession();
      explicitAnswerDwellManager.updateProperties({
        entryPoint: papData?.entryPoint,
        actionSurfaceComponent,
      });
    } else if (!isPageVisible) {
      // User has switched tabs / minimized window / minimized app, we should end the sessions
      explicitAnswerDwellManager.endSession(
        ANSWER_DWELL_END_REASONS.LOST_FOCUS,
      );
    }
  }, [isPageVisible]);

  const fetchAndSummarize = async () => {
    setPageMode(PageModes.loading);
    await fetchAnswersConversation({
      query: i18n.t('answers_summarize_query'),
      queryType: 'user_entry',
    });
    setPageMode(PageModes.conversation);
  };

  useEffect(() => {
    if (attachment && isSummarize) {
      fetchAndSummarize();
    }
  }, [attachment, isSummarize]);

  const handleSubmit = async (
    query: string,
    queryType: DashQueryType = 'user_entry',
  ) => {
    explicitAnswersSessionManager.extendOrCreateSession();
    setPageMode(PageModes.loading);
    await fetchAnswersConversation({
      query,
      queryType,
    });
    setPageMode(PageModes.conversation);
  };

  const handleClickFeedback = (feedback: FeedbackOptions) => {
    explicitAnswersSessionManager.extendOrCreateSession();
    reportPapEvent(
      PAP_Click_DashAnswersFeedback({
        queryString: submittedQuery,
        answerString: getAnswerStringForLogging(conversation),
        dashAnswerFeedback: feedback,
        answerId: answerLoggingId,
        numQuestionsAsked,
        entryPoint: papData?.entryPoint,
        dashAnswersSessionId:
          explicitAnswersSessionManager.getSessionIdOrUndefined(),
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        dashAnswerRequestId,
        actionSurfaceComponent,
        featureLine: 'answers',
      }),
    );
    if (feedback === FeedbackOptions.Negative) {
      setShowSecondaryFeedbackModal(true);
    } else {
      onOpenFeedbackSnackbar();
    }
  };

  const handleClickSecondaryFeedback = (feedback: DashAnswersFeedbackTag) => {
    explicitAnswersSessionManager.extendOrCreateSession();
    reportPapEvent(
      PAP_Click_DashAnswersTaggedFeedback({
        queryString: submittedQuery,
        answerString: getAnswerStringForLogging(conversation),
        dashAnswersFeedbackTag: feedback,
        answerId: answerLoggingId,
        numQuestionsAsked,
        entryPoint: papData?.entryPoint,
        dashAnswersSessionId:
          explicitAnswersSessionManager.getSessionIdOrUndefined(),
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        dashAnswerRequestId,
        actionSurfaceComponent,
        featureLine: 'answers',
      }),
    );
    setShowSecondaryFeedbackModal(false);
    onOpenFeedbackSnackbar();
  };

  const handleCloseSecondaryFeedback = () => {
    setShowSecondaryFeedbackModal(false);
    onOpenFeedbackSnackbar();
  };

  const handleSourceClick = async (source: SearchResult) => {
    explicitAnswersSessionManager.extendOrCreateSession();
    logClickAnswersSource();
    await openResult(source);
  };
  // Similar to handleSourceClick, but shouldn't log a source click for it.
  const handleAttachmentClick = async (attachment: SearchResult) => {
    explicitAnswersSessionManager.extendOrCreateSession();
    logClickAnswersSource(true);
    await openResult(attachment);
  };

  const handleCopyText = (text: string) => {
    copyToClipboard(text);
    onOpenCopyTextSnackbar();

    reportPapEvent(
      PAP_Copy_DashAnswers({
        entryPoint: papData?.entryPoint,
        queryString: submittedQuery,
        answerString: getAnswerStringForLogging(conversation),
        answerId: answerLoggingId,
        numQuestionsAsked,
        dashAnswersSessionId:
          explicitAnswersSessionManager.getSessionIdOrUndefined(),
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        dashAnswerRequestId,
        actionSurfaceComponent,
        featureLine: 'answers',
      }),
    );
  };

  const handleShownEmptyState = useCallback(() => {
    reportPapEvent(
      PAP_Shown_DashNoAnswers({
        entryPoint: papData?.entryPoint,
        queryString: submittedQuery,
        answerId: answerLoggingId,
        numQuestionsAsked,
        dashAnswersSessionId:
          explicitAnswersSessionManager.getSessionIdOrUndefined(),
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        actionSurfaceComponent,
        featureLine: 'answers',
        dashAnswerRequestId,
      }),
    );
  }, [
    actionSurfaceComponent,
    answerLoggingId,
    numQuestionsAsked,
    dashAnswerRequestId,
    explicitAnswersSessionManager,
    reportPapEvent,
    papData?.entryPoint,
    searchSessionManager,
    submittedQuery,
  ]);

  const handleReset = () => {
    explicitAnswersSessionManager.endSession(
      ANSWERS_SESSION_END_REASONS.CLEAR_CHAT,
    );
    resetConversation();
    setPageMode(PageModes.initial);
    setFocusRef.current?.();
  };

  const logClickAnswersSource = (isAttachment = false) => {
    reportPapEvent(
      PAP_Click_DashAnswersSource({
        queryString: submittedQuery,
        answerString: getAnswerStringForLogging(conversation),
        answerId: answerLoggingId,
        numQuestionsAsked,
        entryPoint: papData?.entryPoint,
        dashAnswersSessionId:
          explicitAnswersSessionManager.getSessionIdOrUndefined(),
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        dashAnswerRequestId,
        actionSurfaceComponent,
        featureLine: 'answers',
        isAttachment,
      }),
    );
  };

  const showResetButton =
    enableMultiTurn &&
    pageMode === PageModes.conversation &&
    conversation.length > 0;

  return (
    <>
      <div
        className={classNames(styles.conversationContainer, {
          [styles.fullPageContainer]: isFullPage,
        })}
      >
        {!isFullPage && onClose && (
          <Conversation.RailHeader
            onClose={onClose}
            onReset={showResetButton ? handleReset : undefined}
          />
        )}

        {showResetButton && isFullPage && (
          <div className={styles.resetContainer}>
            <Button
              variant="opacity"
              onClick={handleReset}
              withIconStart={<UIIcon src={RotateLeftLine} />}
            >
              {i18n.t('clear_chat')}
            </Button>
          </div>
        )}

        {pageMode === PageModes.initial ? (
          <EmptyState
            isFullPage={isFullPage}
            enableSuggestedQuestions={enableSuggestedQuestions}
            onSelectSuggestion={(text) => {
              handleSubmit(text, 'get_started_suggestion');
            }}
          />
        ) : (
          <Conversation.MultiTurnMessageList
            scrollToBottomRef={scrollToBottomRef}
            className={classNames(styles.contentWrapper, styles.messageList, {
              [styles.fullPageContentWrapper]: isFullPage,
            })}
          >
            {conversation.map((message, index): JSX.Element => {
              switch (message.role) {
                case Role.User:
                  return (
                    <UserMessage
                      message={message}
                      key={index}
                      attachment={attachment}
                      onAttachmentClick={handleAttachmentClick}
                    />
                  );
                case Role.System:
                case Role.Empty:
                  return (
                    <SystemMessage
                      message={message}
                      key={index}
                      onShownEmptyState={handleShownEmptyState}
                      onClickFeedback={
                        message.role === Role.System
                          ? handleClickFeedback
                          : undefined
                      }
                      onCopyMessage={() =>
                        typeof message.content == 'string'
                          ? handleCopyText(message.content)
                          : undefined
                      }
                      onSourceClick={handleSourceClick}
                    />
                  );
                case Role.Error:
                  return <ErrorMessage message={message} key={index} />;
                // implicit `satisfies never` by specifying return type
              }
            })}

            {pageMode === PageModes.loading && <SpinnerSystemMessage />}
          </Conversation.MultiTurnMessageList>
        )}

        <div
          className={classNames(styles.contentWrapper, {
            [styles.fullPageContentWrapper]: isFullPage,
          })}
        >
          <div>
            <QueryInput
              setFocusRef={setFocusRef}
              onSubmit={handleSubmit}
              loading={pageMode === PageModes.loading}
              attachment={attachment}
              onAttachmentClick={handleAttachmentClick}
              onChange={() => {
                // If session times out, we'll start a new one with an appropriate start reason
                explicitAnswersSessionManager.extendOrCreateSession(
                  'type_query',
                );
                explicitAnswersSessionManager.updateProperties({
                  entryPoint: papData?.entryPoint,
                  dashAnswerRequestId,
                  actionSurfaceComponent: isFullPage ? undefined : 'ask_dialog',
                });
                explicitAnswerDwellManager.endSession(
                  ANSWER_DWELL_END_REASONS.NEW_QUESTION,
                );
              }}
              placeholder={
                attachment
                  ? i18n.t('answers_input_placeholder_attachment')
                  : i18n.t('answers_input_placeholder')
              }
            />
          </div>
        </div>
      </div>

      <MessageSecondaryFeedback
        isOpen={showSecondaryFeedbackModal}
        onClose={handleCloseSecondaryFeedback}
        feedbackOptions={answersSecondaryFeedbackOptions}
        onClickFeedback={handleClickSecondaryFeedback}
        actionSurfaceComponent="ask_dialog"
      />
    </>
  );
};

export default AnswersConversation;

const EmptyState = ({
  isFullPage,
  onSelectSuggestion,
  enableSuggestedQuestions,
}: {
  isFullPage?: boolean;
  onSelectSuggestion: (text: string) => void;
  enableSuggestedQuestions?: boolean;
}) => {
  return (
    <div
      className={classNames(styles.contentWrapper, {
        [styles.fullPageContentWrapper]: isFullPage,
      })}
    >
      {isFullPage ? (
        <FullPageAnswersEmptyState
          onSelectSuggestion={onSelectSuggestion}
          enableSuggestedQuestions={enableSuggestedQuestions}
        />
      ) : (
        <AnswersEmptyState
          title={i18n.t('answers_empty_title')}
          subtitle={i18n.t('answers_empty_subtitle')}
        />
      )}
    </div>
  );
};
