import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import useSearch from '@mirage/service-search/hooks/useSearch';
import { createContext, useContext } from 'react';

import type { SearchContext as SearchContextType } from '@mirage/service-search/hooks/useSearch';
import type { SearchOptions } from '@mirage/service-search/service';

const SearchContext = createContext<SearchContextType | undefined>(undefined);

interface SearchProviderProps {
  children: React.ReactNode;
}

export const SearchContextProvider: React.FC<SearchProviderProps> = ({
  children,
}) => {
  // localMixinThreshold

  const localMixinThresholdFlag = useFeatureFlagValue(
    'dash_server_result_pinning_threshold',
  );
  const localMixinThreshold =
    typeof localMixinThresholdFlag === 'number' ? localMixinThresholdFlag : 0;

  // isLocalFilesEnabled

  const isLocalFilesExpEnabled = useFeatureFlagValue(
    'dash_search_source_local_files',
  );
  const isLocalFilesEnabled = convertFeatureValueToBool(isLocalFilesExpEnabled);

  // useSlottedSearchRanking

  const useSlottedSearchRankingFlag = useFeatureFlagValue(
    'dash_2024_08_09_slotted_search_ranking',
    false,
  );
  const useSlottedSearchRanking = convertFeatureValueToBool(
    useSlottedSearchRankingFlag,
  );

  const options: SearchOptions = {
    localMixinThreshold,
    isLocalFilesEnabled,
    useSlottedSearchRanking,
  };
  const searchContextValue = useSearch(options);

  return (
    <SearchContext.Provider value={searchContextValue}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = (): SearchContextType => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error(
      'useSearchContext must be used within a SearchContextProvider',
    );
  }
  return context;
};
