import { Button } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { useIsPublicSharingAllowed } from '@mirage/service-stack-admin-settings/hooks';
import i18n from '@mirage/translations';
import { useEffect, useState } from 'react';
import { UnthemedWrapper } from '../UnthemedWrapper';
import { PermissionsMenu } from './PermissionsMenu';
import styles from './ShareModal.module.css';
import { SharingStackPermission, StackAccessLevel, StackInfo } from './Types';
import {
  accessLevelIconSrcForMemberCount,
  accessLevelTitleForMemberCount,
  descriptionForStackAccessLevel,
  hasStackWritePermissions,
  serverAccessLevelFromAccessLevel,
  textForPermission,
  titleForAccessLevel,
} from './Utils';

export const AccessRow = ({
  stackInfo,
  handleUpdateStackSharedLink,
  openSnackbar,
}: {
  stackInfo: StackInfo;
  handleUpdateStackSharedLink: (
    accessLevel: StackAccessLevel,
    permission: SharingStackPermission,
  ) => Promise<boolean>;
  openSnackbar: (message: string) => void;
}) => {
  const isPublicSharingAllowed = useIsPublicSharingAllowed();
  const stackAccessLevel = serverAccessLevelFromAccessLevel(
    stackInfo.accessLevel,
  );

  // If the stack has a team name, include the team permission as an option
  const allAccessLevels: StackAccessLevel[] = stackInfo.teamName
    ? [StackAccessLevel.INVITED, StackAccessLevel.TEAM]
    : [StackAccessLevel.INVITED];
  if (
    isPublicSharingAllowed ||
    stackInfo.accessLevel === StackAccessLevel.PUBLIC
  ) {
    allAccessLevels.push(StackAccessLevel.PUBLIC);
  }

  const [isLoading, setIsLoading] = useState(false);

  const setStackAccessLevel = async (newAccessLevel: StackAccessLevel) => {
    if (newAccessLevel === stackInfo.accessLevel) {
      return;
    }
    setIsLoading(true);
    const result = await handleUpdateStackSharedLink(
      newAccessLevel,
      stackInfo.linkPermission,
    );
    if (!result) {
      openSnackbar(i18n.t('failed_to_change_permissions'));
    }
    setIsLoading(false);
  };

  const setStackPermissions = async (
    newLinkPermission: SharingStackPermission,
  ) => {
    if (stackInfo.linkPermission === newLinkPermission) {
      return;
    }
    setIsLoading(true);
    const result = await handleUpdateStackSharedLink(
      stackInfo.accessLevel,
      newLinkPermission,
    );
    if (!result) {
      openSnackbar(i18n.t('failed_to_change_permissions'));
    }
    setIsLoading(false);
  };

  useEffect(() => setIsLoading(false), [stackInfo.latestHash]);

  return (
    <>
      <div className={styles.dividerLine}></div>
      <Text color="faint" size="small" isBold className={styles.whoCanAccess}>
        {i18n.t('who_can_access')}
      </Text>
      <div className={styles.accessLevelContainer}>
        <div className={styles.permissionContainer}>
          {!isLoading && (
            <div className={styles.permissionIconContainer}>
              <UIIcon
                src={accessLevelIconSrcForMemberCount(stackInfo.members.length)}
                size="standard"
              />
            </div>
          )}
          <div className={styles.permissionDescriptionContainer}>
            {hasStackWritePermissions(stackInfo.permission) ? (
              <>
                <UnthemedWrapper>
                  <Menu.Wrapper
                    onSelection={(accessLevel: StackAccessLevel) =>
                      setStackAccessLevel(accessLevel)
                    }
                  >
                    {({ getContentProps, getTriggerProps }) => (
                      <>
                        <Button
                          {...getTriggerProps()}
                          size="large"
                          withDropdownIcon
                          variant="transparent"
                          isLoading={isLoading}
                          hasNoUnderline
                        >
                          <Text
                            size="small"
                            isBold
                            variant="label"
                            className={styles.standardText}
                          >
                            {titleForAccessLevel(
                              stackInfo.accessLevel,
                              stackInfo.teamName,
                              stackInfo.members.length,
                            )}
                          </Text>
                        </Button>
                        <Menu.Content
                          {...getContentProps()}
                          placement="bottom-start"
                        >
                          <Menu.Segment>
                            {allAccessLevels.map((level) => (
                              <Menu.SelectItem
                                key={level}
                                value={level}
                                selected={level === stackInfo.accessLevel}
                              >
                                {titleForAccessLevel(
                                  level,
                                  stackInfo.teamName,
                                  stackInfo.members.length,
                                )}
                              </Menu.SelectItem>
                            ))}
                          </Menu.Segment>
                        </Menu.Content>
                      </>
                    )}
                  </Menu.Wrapper>
                </UnthemedWrapper>
                <Text
                  size="xsmall"
                  variant="paragraph"
                  style={{ marginBottom: '4px' }}
                  className={styles.standardText}
                >
                  {!isLoading &&
                    descriptionForStackAccessLevel(
                      stackAccessLevel,
                      stackInfo.teamName,
                    )}
                </Text>
              </>
            ) : (
              <>
                <Text
                  isBold={true}
                  size="small"
                  variant="label"
                  className={styles.standardText}
                >
                  {accessLevelTitleForMemberCount(stackInfo.members.length)}
                </Text>
                <Text
                  size="small"
                  variant="paragraph"
                  className={styles.standardText}
                >
                  {descriptionForStackAccessLevel(
                    stackAccessLevel,
                    stackInfo.teamName,
                  )}
                </Text>
              </>
            )}
          </div>
        </div>
        {stackInfo.accessLevel !== StackAccessLevel.INVITED && (
          <>
            {hasStackWritePermissions(stackInfo.permission) ? (
              !isLoading && (
                <PermissionsMenu
                  sharePermissions={stackInfo.linkPermission}
                  onSelectSharePermissions={setStackPermissions}
                  withFaintStyle={true}
                  buttonProps={{
                    variant: 'transparent',
                    hasNoUnderline: true,
                    className: styles.faintText,
                  }}
                  isLocalUserMenu={false}
                  marchUpdateEnabled={false}
                />
              )
            ) : (
              <Text className={styles.faintText} size="small" isBold>
                {textForPermission(stackInfo.linkPermission)}
              </Text>
            )}
          </>
        )}
      </div>
    </>
  );
};
