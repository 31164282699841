import { Title } from '@dropbox/dig-components/typography';
import { Box, Stack } from '@dropbox/dig-foundations';
import { ConnectPrompt } from '@mirage/settings/components/connectors/ConnectPrompt';
import { Skeletons } from '@mirage/settings/components/connectors/Skeleton';
import { useConnectConnector } from '@mirage/settings/hooks/connectors/useConnectConnector';
import { connectionsByTypeAtom } from '@mirage/shared/atoms/connections';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { ShowSnackbar } from '@mirage/shared/snackbar/types';
import { UIConnector, UIWebExtension } from '@mirage/shared/types';
import { useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import { Connector } from './Connector';

type Props = Readonly<{
  title: string;
  connectors?: UIConnector[];
  browserExtensionData?: UIWebExtension[];
  showDescriptions?: boolean;
  showSnackbar: ShowSnackbar;
}>;

export function OnboardingConnectors({
  title,
  connectors,
  browserExtensionData,
  showDescriptions = false,
  showSnackbar,
}: Props) {
  const connectConnector = useConnectConnector();
  const isMobile = useIsMobileSize();
  // Making sure that the snackbar doesn't overlap with the main CTA button container for mobile layout of single step onboarding variants
  const snackbarMarginBottom = isMobile ? 60 : 0;

  const [useConnectPrompt, setUseConnectPrompt] = useState<UIConnector | null>(
    null,
  );

  const onConnectInitiate = async (connector: UIConnector): Promise<void> => {
    if (connector?.auth_details?.prompt_configuration) {
      setUseConnectPrompt(connector);
    } else {
      const result = await connectConnector(connector);
      showSnackbar({
        title: result.message,
        marginBottom: snackbarMarginBottom,
      });
    }
  };

  const onConnectPromptSubmit = async (
    connector: UIConnector | undefined,
    values: Record<string, string>,
  ): Promise<void> => {
    if (connector) {
      setUseConnectPrompt(null);
      const result = await connectConnector(connector, values);
      showSnackbar({
        title: result.message,
        marginBottom: snackbarMarginBottom,
      });
    }
  };

  const connectionsByType = useAtomValue(connectionsByTypeAtom);

  const header = useMemo(
    () => (
      <Box display="block">
        <Title size="small">{title}</Title>
      </Box>
    ),
    [title],
  );

  if (!connectors || !browserExtensionData) {
    return (
      <>
        {header}
        <Skeletons length={3} />
      </>
    );
  }

  return (
    <>
      {header}
      <Stack gap="16px">
        {connectors.map((connector) => {
          const { branding, loading } = connector;
          const type = connector.id_attrs?.type || '';

          return (
            <Connector
              connected={!!connectionsByType[type]}
              description={
                (showDescriptions && connector.branding?.brief_description) ||
                ''
              }
              key={connector.branding?.display_name}
              label={connector.branding?.display_name ?? ''}
              icon_src={branding?.icon_src}
              loading={loading}
              onClick={() => onConnectInitiate(connector)}
              type={type}
            />
          );
        })}
      </Stack>
      {useConnectPrompt && (
        <ConnectPrompt
          connector={useConnectPrompt}
          onSubmit={(values) => onConnectPromptSubmit(useConnectPrompt, values)}
          onCancel={() => setUseConnectPrompt(null)}
        />
      )}
    </>
  );
}
