import { Text } from '@dropbox/dig-components/typography';
import { TitleToken } from '@mirage/shared/search/url-shortcut';
import styles from './ShortcutTitle.module.css';

import type { ReactNode } from 'react';

type ShortcutTitleProps = {
  titles: TitleToken[];
};

export const ShortcutTitle = ({ titles }: ShortcutTitleProps) => {
  return (
    <>
      {titles.map((component, i) => {
        if (component.type === 'string') {
          return <Text key={i}>{component.value}</Text>;
        }

        return (
          <div className={styles.paramContainer} key={i}>
            <Text isBold={component.matched}>{component.value}</Text>
          </div>
        );
      })}
    </>
  );
};

export function ShortcutSubtitle({
  hotword,
  subtitle,
}: {
  hotword: ReactNode;
  subtitle: ReactNode;
}) {
  return (
    <>
      <div className={styles.paramContainer}>
        <Text>{hotword}</Text>
      </div>{' '}
      <Text>&middot; {subtitle}</Text>
    </>
  );
}
