import { stacks } from '@dropbox/api-v2-client';
import { useResizeObserver } from '@dropbox/dig-components/hooks';
import { Menu } from '@dropbox/dig-components/menu';
import { TextInputRefObject } from '@dropbox/dig-components/text_fields';
import { UIIcon } from '@dropbox/dig-icons';
import { SearchLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Initiate_SearchForStack } from '@mirage/analytics/events/types/initiate_search_for_stack';
import { PAP_View_DashStack } from '@mirage/analytics/events/types/view_dash_stack';
import { useAllStacksAugustRevisionEnabled } from '@mirage/august-revision-hook';
import {
  defaultStackPageSortPreference,
  StackFilterOption,
  StackSortPreference,
} from '@mirage/service-settings/service/types';
import {
  stackDerivePAPProps,
  stackGetShareId,
} from '@mirage/service-stacks/service/utils';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { useResolveWidthProp } from '@mirage/shared/responsive/breakpoints';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import i18n from '@mirage/translations';
import { WorkingSetCardWrapper } from '@mirage/working-set';
import classNames from 'classnames';
import { useSetAtom } from 'jotai';
import {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsArchiveStackEnabled } from '../FullScreenStack/hooks';
import { FullWidthSearchBar } from '../FullWidthSearchBar';
import {
  ALL_STACKS_GRID_THREE_COLUMN_BREAKPOINT,
  ALL_STACKS_GRID_TWO_COLUMN_BREAKPOINT,
  ALL_STACKS_GRID_TWO_COLUMN_WIDTH,
} from '../Helpers/Utils';
import { useStacksByPredicate } from '../hooks';
import { AllStacksPreset, SortIconMenu } from '../SortIconMenu';
import { stackSearchStacksAtom } from './atoms';
import styles from './ControlBar.module.css';
import CreateStackButtonCard from './CreateStackButtonCard';
import { SearchMenuContent } from './SearchMenuContent';
import { StackFilterChips } from './StackFilterChips';
import { useStackPagePreferences } from './useStackPagePreferences';

const Wrapper = forwardRef(
  ({ children }: { children: React.ReactNode }, ref: Ref<HTMLDivElement>) => {
    const augustRevision = useAllStacksAugustRevisionEnabled();

    return !augustRevision ? (
      <WorkingSetCardWrapper innerClassName={styles.wrapper} ref={ref}>
        {children}
      </WorkingSetCardWrapper>
    ) : (
      <div ref={ref}>
        <TwoColumnGridCard
          settingId="allstacks_action_bar"
          cardTypeProps={{
            cardType: CardHeaderType.CUSTOM,
            customHeader: <div className={styles.header}>{children}</div>,
          }}
          isAlwaysCollapsed={false}
          showDividerLine={false}
          className={styles.augustWrapper}
        />
      </div>
    );
  },
);
Wrapper.displayName = 'Wrapper';

export const ControlBar = ({ hasNoStacks }: { hasNoStacks: boolean }) => {
  const augustRevision = useAllStacksAugustRevisionEnabled();
  const isArchivedStacksEnabled = useIsArchiveStackEnabled();
  const {
    stackPageSortPreference: sortPreference,
    setStackPageSortPreference: setSettings,
  } = useStackPagePreferences();
  const numColumns =
    useResolveWidthProp({
      [ALL_STACKS_GRID_TWO_COLUMN_BREAKPOINT]: 1,
      [ALL_STACKS_GRID_THREE_COLUMN_BREAKPOINT]: 2,
    }) ?? 3;
  const handleSortSelect = (value: StackSortPreference) => {
    setSettings(value);
  };

  const [searchMenuIsOpen, setSearchMenuIsOpen] = useState(false);

  const [showActionDropdown, setShowActionDropdown] = useState(false);

  const { nodeRef: wrapperRef, observerEntry } = useResizeObserver();
  const width = observerEntry && observerEntry.contentRect?.width;
  const breakpoint = augustRevision ? 805 : ALL_STACKS_GRID_TWO_COLUMN_WIDTH;

  useEffect(() => {
    if (!width) {
      return;
    }

    if (width <= breakpoint) {
      setShowActionDropdown(true);
    } else if (width > breakpoint) {
      setShowActionDropdown(false);
    }
  }, [width, breakpoint]);

  return (
    <Wrapper ref={wrapperRef}>
      <div className={styles.actionWrapper}>
        <div
          className={classNames(
            styles.actionGroup,
            augustRevision && styles.augustActionGroup,
          )}
        >
          <SearchIconMenu
            augustRevision={augustRevision}
            menuIsOpen={searchMenuIsOpen}
            setMenuIsOpen={setSearchMenuIsOpen}
          />
        </div>
        {(augustRevision || isArchivedStacksEnabled) && (
          <div
            className={classNames(
              styles.actionGroup,
              augustRevision && styles.augustActionGroup,
              searchMenuIsOpen
                ? styles.hideActionGroup
                : styles.showActionGroup,
            )}
          >
            <StackFilterChips
              defaultFilter={StackFilterOption.ALL}
              collapsed={showActionDropdown}
            />
          </div>
        )}
        <div
          className={classNames(
            styles.actionGroup,
            augustRevision && styles.augustActionGroup,
            searchMenuIsOpen ? styles.hideActionGroup : styles.showActionGroup,
          )}
        >
          <SortIconMenu
            augustRevision={augustRevision}
            sortPreference={sortPreference}
            onSortSelect={handleSortSelect}
            optionsPreset={AllStacksPreset}
            defaultOption={defaultStackPageSortPreference}
          />
        </div>
      </div>
      <CreateStackButtonCard
        hasNoStacks={hasNoStacks}
        isMobileSize={numColumns < 2}
      />
    </Wrapper>
  );
};

const SearchIconMenu = ({
  menuIsOpen,
  setMenuIsOpen,
  augustRevision,
}: {
  augustRevision: boolean;
  menuIsOpen: boolean;
  setMenuIsOpen: (x: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { reportPapEvent } = useMirageAnalyticsContext();

  const textInputRef = useRef<TextInputRefObject | null>(null);
  const [inputString, setInputString] = useState('');
  const setStacks = useSetAtom(stackSearchStacksAtom);
  const trimmedInputString = inputString.trim();
  const { stacks, stackItemsMap } = useStacksByPredicate((stack) => {
    return trimmedInputString
      ? stack.stack_data?.name
          ?.toLowerCase()
          .includes(trimmedInputString.toLowerCase()) ?? false
      : false;
  });

  useEffect(() => {
    if (trimmedInputString !== '') {
      setStacks(stacks ?? []);
    } else {
      setStacks(null);
    }
  }, [trimmedInputString, setStacks, stacks]);

  const onSelectStack = useCallback(
    (stack: stacks.Stack) => {
      reportPapEvent(
        PAP_View_DashStack({
          ...stackDerivePAPProps(stack),
          actionSurfaceComponent: 'stacks',
          featureLine: 'stacks',
          numberOfLinks:
            stackItemsMap && stack.namespace_id
              ? stackItemsMap[stack.namespace_id].length
              : 0,
        }),
      );
      // dispatchElementClicked(
      //   createUxaElementId('stack', {
      //     featureLine: 'stacks',
      //   }),
      // );
      navigate(`/stacks/${stackGetShareId(stack)}`);
    },
    [navigate, reportPapEvent, stackItemsMap],
  );

  useEffect(() => {
    if (menuIsOpen) {
      reportPapEvent(
        PAP_Initiate_SearchForStack({
          dashActionSurface: 'stacks_page',
          featureLine: 'stacks',
        }),
      );
      textInputRef.current?.focus();
    } else {
      setInputString('');
    }
  }, [menuIsOpen, reportPapEvent]);

  if (!augustRevision) {
    return (
      <Menu.Wrapper
        onToggle={(event) => {
          setMenuIsOpen(event.isOpen);
          if (event.isOpen) {
            setInputString('');
          }
        }}
      >
        {({ getContentProps, getTriggerProps, closeMenu }) => (
          <div>
            <IconButtonWithTooltip
              shape={augustRevision ? 'circular' : 'standard'}
              variant={augustRevision ? 'opacity' : 'borderless'}
              tooltipProps={{
                title: i18n.t('search'),
              }}
              {...getTriggerProps()}
            >
              <UIIcon src={SearchLine} />
            </IconButtonWithTooltip>
            <SearchMenuContent
              getContentProps={getContentProps}
              textInputRef={textInputRef}
              inputString={inputString}
              setInputString={setInputString}
              searchResults={stacks?.slice(0, 5) ?? []}
              onSelectResult={onSelectStack}
              isLoading={false}
              closeMenu={() => closeMenu({})}
            />
          </div>
        )}
      </Menu.Wrapper>
    );
  }

  return (
    <>
      <IconButtonWithTooltip
        shape="circular"
        variant="opacity"
        tooltipProps={{
          title: i18n.t('search'),
        }}
        onClick={() => setMenuIsOpen(true)}
      >
        <UIIcon src={SearchLine} />
      </IconButtonWithTooltip>
      {menuIsOpen && (
        <FullWidthSearchBar
          textInputRef={textInputRef}
          placeholder={i18n.t('search_stacks')}
          inputString={inputString ?? ''}
          setInputString={setInputString}
          handleMenuClose={() => {
            setMenuIsOpen(false);
          }}
        />
      )}
    </>
  );
};
