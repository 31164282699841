import { Card } from '@dropbox/dash-component-library';
import { Badge } from '@dropbox/dig-components/badge';
import { ProgressBar } from '@dropbox/dig-components/progress_indicators';
import { Text } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { DashAnswersFeedbackTag } from '@mirage/analytics/events/enums/dash_answers_feedback_tag';
import { FeatureLine } from '@mirage/analytics/events/enums/feature_line';
import { PAP_Click_DashAnswersFeedback } from '@mirage/analytics/events/types/click_dash_answers_feedback';
import { PAP_Click_DashAnswersHide } from '@mirage/analytics/events/types/click_dash_answers_hide';
import { PAP_Click_DashAnswersShow } from '@mirage/analytics/events/types/click_dash_answers_show';
import { PAP_Click_DashAnswersSource } from '@mirage/analytics/events/types/click_dash_answers_source';
import { PAP_Click_DashAnswersTaggedFeedback } from '@mirage/analytics/events/types/click_dash_answers_tagged_feedback';
import { PAP_Copy_DashAnswers } from '@mirage/analytics/events/types/copy_dash_answers';
import { PAP_Shown_DashSearchResult } from '@mirage/analytics/events/types/shown_dash_search_result';
import { ANSWER_DWELL_END_REASONS } from '@mirage/analytics/session/session-utils';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { ChatEntryPoint } from '@mirage/conversations/types';
import { Link } from '@mirage/link/Link';
import { copyToClipboard } from '@mirage/service-platform-actions';
import { openResult } from '@mirage/service-result-actions';
import {
  MultiAnswerResponse,
  QuestionAndAnswerResponse,
  QuestionAndAnswerSource,
} from '@mirage/shared/answers/multi-answer';
import { usePageVisibility } from '@mirage/shared/hooks/usePageVisibility';
import useSearchQueryId from '@mirage/shared/hooks/useSearchQueryId';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { FeedbackOptions } from '@mirage/shared/types';
import { SECURITY_BINDER_URL } from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { Fragment, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { Answer } from './Answer';
import styles from './MultiAnswerCard.module.css';

const featureLine: FeatureLine = 'multi_answers';

type MultiAnswerCardProps = {
  mutliAnswerResponse: MultiAnswerResponse | undefined;
  loading?: boolean;
  query: string;
  onShowFeedbackSnackbar: () => void;
  onShowCopyMessageSnackbar: () => void;
  annotationMode?: boolean;
  onOpenAnnotationModal: (
    feedback: FeedbackOptions,
    answer: QuestionAndAnswerResponse,
  ) => void;
};

export const MultiAnswerCard: React.FC<MultiAnswerCardProps> = ({
  mutliAnswerResponse,
  loading,
  query,
  onShowFeedbackSnackbar,
  onShowCopyMessageSnackbar,
  annotationMode = false,
  onOpenAnnotationModal,
}) => {
  const isPageVisible = usePageVisibility();
  const isCardVisible = useRef(false);
  const isMobile = useIsMobileSize();
  const ref: React.MutableRefObject<null> = useRef(null);
  const { searchQueryUuid } = useSearchQueryId();
  const { reportPapEvent, implicitAnswerDwellManager, searchSessionManager } =
    useMirageAnalyticsContext();

  const isEmpty =
    !mutliAnswerResponse?.answers || mutliAnswerResponse?.answers.length == 0;
  const shouldDisplay = loading || !isEmpty;

  // Ensure we don't log end session events when component re-renders due to loading or change in answers
  const canToggleSession = !isEmpty && !loading;

  // Create answer ID for logging purposes
  const answerLoggingId = useRef(uuid());

  useEffect(() => {
    if (canToggleSession) {
      // Create dwell session
      implicitAnswerDwellManager.createSession();
      implicitAnswerDwellManager.updateProperties({
        queryString: query,
        entryPoint: ChatEntryPoint.search_bar_inline,
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        searchRequestId: mutliAnswerResponse?.requestUuid,
        featureLine,
      });

      // Log shown event for each answer
      mutliAnswerResponse?.answers?.map((answer) => {
        reportPapEvent(
          PAP_Shown_DashSearchResult({
            queryString: query,
            answerString: answer.answer,
            answerId: answerLoggingId.current,
            numAnswerSources: answer.sources.length,
            entryPoint: ChatEntryPoint.search_bar_inline,
            searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
            searchRequestId: mutliAnswerResponse?.requestUuid,
            searchQueryUuid,
            featureLine,
          }),
        );
      });
    }

    return () => {
      if (canToggleSession) {
        implicitAnswerDwellManager.endSession(ANSWER_DWELL_END_REASONS.EXITED);
      }
    };
    // Disabling because we do not want to log whenever `query` or `searchQueryId` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mutliAnswerResponse,
    reportPapEvent,
    canToggleSession,
    searchSessionManager,
    implicitAnswerDwellManager,
  ]);

  useEffect(() => {
    if (!ref.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Answer card is visible in viewport
          isCardVisible.current = true;
          implicitAnswerDwellManager.extendOrCreateSession();
          implicitAnswerDwellManager.updateProperties({
            queryString: query,
            entryPoint: ChatEntryPoint.search_bar_inline,
            searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
            searchQueryUuid: searchQueryUuid,
            searchRequestId: mutliAnswerResponse?.requestUuid,
            featureLine,
          });
        } else {
          // User has scrolled the answer card out of view. We should end the session
          isCardVisible.current = false;
          implicitAnswerDwellManager.endSession(
            ANSWER_DWELL_END_REASONS.SCROLLED,
          );
        }
      });
    });

    observer.observe(ref.current);
    const refCurrent = ref.current;
    return () => {
      if (refCurrent) {
        observer.unobserve(refCurrent);
      }
    };
  }, [
    ref,
    mutliAnswerResponse?.requestUuid,
    searchQueryUuid,
    query,
    implicitAnswerDwellManager,
    searchSessionManager,
  ]);

  useEffect(() => {
    if (isPageVisible && isCardVisible.current) {
      // User switched back to the page and answer card is in view
      implicitAnswerDwellManager.extendOrCreateSession();
      implicitAnswerDwellManager.updateProperties({
        queryString: query,
        entryPoint: ChatEntryPoint.search_bar_inline,
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        searchQueryUuid: searchQueryUuid,
        searchRequestId: mutliAnswerResponse?.requestUuid,
        featureLine,
      });
    } else if (!isPageVisible) {
      // User has switched tabs / minimized window / minimized app, we should end the sessions
      implicitAnswerDwellManager.endSession(
        ANSWER_DWELL_END_REASONS.LOST_FOCUS,
      );
    }
  }, [
    implicitAnswerDwellManager,
    isPageVisible,
    mutliAnswerResponse?.requestUuid,
    searchQueryUuid,
    query,
    searchSessionManager,
  ]);

  const getCommonPapFields = () => {
    return {
      queryString: query,
      answerId: answerLoggingId.current,
      entryPoint: ChatEntryPoint.search_bar_inline,
      featureLine: 'multi_answers' as FeatureLine,
      searchQueryUuid,
      searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      searchRequestId: mutliAnswerResponse?.requestUuid,
    };
  };

  const handleCopyMessage = (item: QuestionAndAnswerResponse) => {
    onShowCopyMessageSnackbar();
    copyToClipboard(item.answer || '');

    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Copy_DashAnswers({
        answerPositionIndex: item.position,
        answerString: item.answer,
        ...getCommonPapFields(),
      }),
    );
  };

  const handleSourceClick = async (
    item: QuestionAndAnswerResponse,
    source: QuestionAndAnswerSource,
  ) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersSource({
        answerPositionIndex: item.position,
        answerString: item.answer,
        ...getCommonPapFields(),
      }),
    );

    await openResult(source);
  };

  const handleClickFeedback = (
    item: QuestionAndAnswerResponse,
    feedback: FeedbackOptions,
  ) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersFeedback({
        answerPositionIndex: item.position,
        answerString: item.answer,
        dashAnswerFeedback: feedback,
        ...getCommonPapFields(),
      }),
    );
    if (annotationMode && feedback === FeedbackOptions.Negative) {
      onOpenAnnotationModal(feedback, item);
    }
  };

  const handleClickSecondaryFeedback = (
    item: QuestionAndAnswerResponse,
    feedback: DashAnswersFeedbackTag,
  ) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersTaggedFeedback({
        answerPositionIndex: item.position,
        answerString: item.answer,
        dashAnswersFeedbackTag: feedback,
        ...getCommonPapFields(),
      }),
    );
  };

  const handleExpand = (item: QuestionAndAnswerResponse) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersShow({
        answerPositionIndex: item.position,
        ...getCommonPapFields(),
      }),
    );
  };

  const handleCollapse = (item: QuestionAndAnswerResponse) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersHide({
        answerPositionIndex: item.position,
        ...getCommonPapFields(),
      }),
    );
  };

  if (!shouldDisplay) {
    return null;
  }

  return (
    <>
      <Card
        variant="outline"
        className={classNames(styles.container, {
          [styles.isMobile]: isMobile,
        })}
      >
        <Card.Header
          withMargin={!loading}
          title={
            <>
              <span>{i18n.t('answers_header_title')}</span>
              <Badge className={styles.badge}>{i18n.t('beta')}</Badge>
            </>
          }
        />
        <div ref={ref}>
          {mutliAnswerResponse?.answers?.map((answer, index) => {
            return (
              <Fragment key={answer.conversationId}>
                <Answer
                  answer={answer}
                  answerId={answer.conversationId}
                  expandedByDefault={index == 0}
                  onShowFeedbackSnackbar={onShowFeedbackSnackbar}
                  onCopyMessage={handleCopyMessage}
                  onSourceClick={handleSourceClick}
                  onClickFeedback={handleClickFeedback}
                  onClickSecondaryFeedback={handleClickSecondaryFeedback}
                  onCollapse={() => {
                    handleCollapse(answer);
                  }}
                  onExpand={() => {
                    handleExpand(answer);
                  }}
                  annotationMode={annotationMode}
                />
                <div className={styles.divider} />
              </Fragment>
            );
          })}
          {!loading && (
            <Text
              size="small"
              className={styles.experimentalNoticeText}
              color="faint"
            >
              {i18n.t('answers_disclaimer_text')}
              &nbsp;
              <Link
                href={SECURITY_BINDER_URL}
                data-uxa-log={createUxaElementId('learn_more_link', {
                  actionSurfaceComponent: 'inline_answer',
                  featureLine: 'multi_answers',
                })}
              >
                {i18n.t('learn_more')}
              </Link>
            </Text>
          )}
        </div>
        {loading && (
          <div className={styles.progressBar}>
            <ProgressBar in isIndeterminate />
          </div>
        )}
      </Card>
      {annotationMode && (
        <Card className={styles.container}>
          <Text size="small" className={styles.experimentalNoticeText}>
            Help improve the quality of answers by adding annotations. Tap the
            thumbs up or down icons on each answer to add more detail.
            <br />
            <br />
            <em>Only visible because you work at Dropbox.</em>
          </Text>
        </Card>
      )}
    </>
  );
};
