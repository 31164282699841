import { Text } from '@dropbox/dig-components/typography';
import i18n from '@mirage/translations';
import { Source } from './Source';
import { ConversationMessage } from './types';
import styles from './UserMessage.module.css';

import type { SearchResult } from '@mirage/service-dbx-api';

type UserMessageProps = {
  message: ConversationMessage;
  attachment?: SearchResult;
  onAttachmentClick?: (attachment: SearchResult) => void;
};

export const UserMessage = ({
  message,
  attachment,
  onAttachmentClick,
}: UserMessageProps) => {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.message}>
          {attachment && (
            <Source
              sourceMaxWidth="350px"
              source={attachment}
              onClick={() => onAttachmentClick?.(attachment)}
            />
          )}
          <Text
            className={styles.styledText}
            aria-live="polite"
            aria-label={i18n.t('aria_answers_user_query')}
          >
            {message.content}
          </Text>
        </div>
      </div>
    </div>
  );
};
