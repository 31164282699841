import {
  BOLT_ORIGIN_DEVBOX_TMPL,
  BOLT_ORIGIN_PROD,
  getDevboxUrl,
  GROWTHBOOK_API_KEY_DEVBOX,
  GROWTHBOOK_API_KEY_PROD,
  SPRIG_ENVIRONMENT_ID_DEV,
  SPRIG_ENVIRONMENT_ID_PROD,
} from "@mirage/shared/util/build-vars";
// Build-injected variables.
import { BuildChannel } from "@mirage/shared/util/types";

import packageJson from "../../package.json";

export type Environment = "devbox" | "devprod" | "canary" | "staging" | "prod";

// All our custom application environment variables must be prefixed with "REACT_APP_"

const ENV = process.env.REACT_APP_ENV as Environment;

if (process.env.NODE_ENV !== "test" && !ENV) {
  throw new Error(`REACT_APP_ENV must be specified during runtime`);
}

// Use REACT_APP_DEVBOX to specify a custom devbox hostname.
// E.g. export REACT_APP_DEVBOX=joshwilson-coder-dash-grande.dev.corp.dropbox.com
//   or export REACT_APP_DEVBOX=joshwilson-coder-dash-grande
// Otherwise use REACT_APP_USER to use the default devbox hostname
// of "$USER-coder-dbx.dev.corp.dropbox.com".
// E.g. export REACT_APP_USER=${USER}
const USER = process.env.REACT_APP_USER;
const DEVBOX =
  process.env.REACT_APP_DEVBOX?.replace(/(\.dev\.corp\.dropbox\.com$)/, "") ||
  (USER && `${USER}-coder-dbx`);
if (ENV === "devbox" && !DEVBOX) {
  throw new Error(
    "REACT_APP_USER or REACT_APP_DEVBOX must be specified for devbox runtime",
  );
}

// This must be a non-empty string to support Amplify environment variables
const REACT_APP_OAUTH_REDIRECT_URL =
  process.env.REACT_APP_OAUTH_REDIRECT_URL === "null"
    ? ""
    : process.env.REACT_APP_OAUTH_REDIRECT_URL;

if (ENV in ["staging", "prod"] && REACT_APP_OAUTH_REDIRECT_URL) {
  throw new Error(
    `REACT_APP_OAUTH_REDIRECT_URL cannot be used in non-development environments`,
  );
}

/** Get with default value that can be overridden by environment. */
function getValue(
  overrides: { DEFAULT: string } & Partial<Record<Environment, string>>,
): string {
  return overrides[ENV] || overrides.DEFAULT;
}

/** Get value in given map where all environments must be specified. */
function valueForEnv<T extends string>(map: Record<Environment, T>): T {
  return map[ENV];
}

const buildChannel = valueForEnv<BuildChannel>({
  devbox: "development",
  devprod: "development",
  canary: "canary",
  staging: "staging",
  prod: "production",
});

const getBuildVersion = (): string => {
  // In case REACT_APP_ENV is ever undefined
  const safeEnv = ENV || "unknown_environment";

  if (buildChannel === "development") {
    // We don't update the package.json version, so this will probably
    // remain constant.
    return `${safeEnv}-${packageJson.version}`;
  }

  // REACT_APP_COMMIT_ID is set in amplify.yml in the repo root for staging and prod builds
  const commitId = process.env.REACT_APP_COMMIT_ID
    ? process.env.REACT_APP_COMMIT_ID.slice(0, 9) // no need full 40 character hash
    : "unknown_commit";

  // Ex. prod-1234abcd
  return `${safeEnv}-${commitId}`;
};

export const Config = {
  ENV,
  BUILD_VERSION: getBuildVersion(),

  BUILD_CHANNEL: buildChannel,

  DEVBOX,

  OAUTH_CLIENT_KEY: getValue({
    DEFAULT: "72vmju6skshq5bn",
    devbox: "devboxdashdevkey",
    prod: "uhmfsztolr8jrh6",
  }),

  OAUTH_CLIENT_SECRET: getValue({
    DEFAULT: "8oe806y5d2a2tih",
    devbox: "devboxdashdevsecret",
    prod: "731yighrg3dzyuk",
  }),

  /** sprig api token */
  SPRIG_ENVIRONMENT_ID: getValue({
    DEFAULT: SPRIG_ENVIRONMENT_ID_DEV,
    prod: SPRIG_ENVIRONMENT_ID_PROD,
  }),

  /** datadog api information */
  DATADOG_CLIENT_TOKEN: "pubabd59852e68e81b5936317b239942ba9",
  DATADOG_SERVICE_ID: "dash-web",

  GROWTHBOOK_CLIENT_KEY: getValue({
    DEFAULT: GROWTHBOOK_API_KEY_DEVBOX, // dev
    staging: GROWTHBOOK_API_KEY_PROD,
    prod: GROWTHBOOK_API_KEY_PROD,
  }),

  API_SERVER: getValue({
    DEFAULT: "",
    devbox: `${DEVBOX}.dev.corp.dropbox.com`,
  }),

  // For webapp, the oauth redirect url is always the current host.
  STAGE_HOST: location.host,

  BOLT_URL: getValue({
    DEFAULT: BOLT_ORIGIN_PROD,
    devbox: getDevboxUrl(BOLT_ORIGIN_DEVBOX_TMPL, DEVBOX ?? ""),
  }),

  // Should only be set for prototype apps.
  // Lets one piggy-back off an already deployed front-end app's oauth flow.
  // Makes use of the redirectToPathEncoded OAuth "state" parameter.
  // This should be the whole literal url of the prototype AWS Lambda
  // that we've provided for the Dash Development Dropbox API app's Oauth2
  // redirect list.
  // Its current value is https://5mzqqh39h4.execute-api.us-east-2.amazonaws.com/prototype/AmplifyDashWebPrototypesOAuthRedirect
  OAUTH_REDIRECT_URL: getValue({
    DEFAULT: "",
    devbox: REACT_APP_OAUTH_REDIRECT_URL,
    devprod: REACT_APP_OAUTH_REDIRECT_URL,
    canary: REACT_APP_OAUTH_REDIRECT_URL,
  }),
};
