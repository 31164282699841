import { Split } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Shown_ActivityFeed } from '@mirage/analytics/events/types/shown_activity_feed';
import { refreshActivityFeed } from '@mirage/service-feed';
import { useAreActivityFeedFiltersEnabled } from '@mirage/service-feed/hooks/features';
import { useActivityFeed } from '@mirage/service-feed/hooks/useActivityFeed';
import { ActivityFeedFilters } from '@mirage/service-feed/types';
import { ModuleHeader } from '@mirage/shared/two-column-grid/ModuleHeader';
import i18n from '@mirage/translations';
import { useMountEffect } from '@react-hookz/web';
import { ActivityList } from '../ActivityList/ActivityList';
import { UserActivityFeedFilters } from '../UserActivityFeedFilters/UserActivityFeedFilters';

const useRefreshActivityFeed = () => {
  // currently we are refreshing the activity feed every time the page loads, and not incrementally
  useMountEffect(() => {
    refreshActivityFeed();
  });
};

const useReportShownDashStartPage = () => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  useMountEffect(() => {
    reportPapEvent(
      PAP_Shown_ActivityFeed({
        actionSurfaceComponent: 'activity_feed',
        featureLine: 'activity_feed',
      }),
    );
  });
};

export const UserActivityFeedModule = () => {
  useRefreshActivityFeed();
  useReportShownDashStartPage();

  const showFilters = useAreActivityFeedFiltersEnabled();

  const {
    filters,
    setFilters,
    clearFilters,
    activities,
    isRefreshing,
    loadMore,
    hasMore,
    isLoadingMore,
  } = useActivityFeed();

  const showLoadingPlaceholders =
    (isRefreshing && !activities) || isLoadingMore;

  return (
    <Split direction="vertical" gap="8">
      <Split.Item>
        <UserActivityFeedHeader
          showFilters={showFilters}
          filters={filters}
          setFilters={setFilters}
        />
      </Split.Item>
      <Split.Item>
        <ActivityList
          showLoadingPlaceholders={showLoadingPlaceholders}
          activities={activities || []}
          hasMore={!!hasMore}
          onReachEnd={loadMore}
          filters={filters}
          clearFilters={clearFilters}
        />
      </Split.Item>
    </Split>
  );
};

type UserActivityFeedHeaderProps = {
  showFilters: boolean;
  filters: ActivityFeedFilters;
  setFilters: (filters: ActivityFeedFilters) => void;
};

const UserActivityFeedHeader = ({
  showFilters,
  filters,
  setFilters,
}: UserActivityFeedHeaderProps) => {
  return (
    <ModuleHeader
      title={i18n.t('activity_feed_header_title')}
      actions={
        showFilters && (
          <UserActivityFeedFilters filters={filters} setFilters={setFilters} />
        )
      }
    />
  );
};
