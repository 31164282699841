import { stacks } from '@dropbox/api-v2-client';
import {
  StackItem,
  StackItemShortcutWithMetadata,
} from '@mirage/service-stacks/service/types';
import {
  asShortcuts,
  stackForDiffingForOnlyRenderedData,
} from '@mirage/service-stacks/service/utils';
import { atom } from 'jotai';
import { isEqual } from 'lodash';
import { SectionsActionType } from './types';

const shadowStackStateAtom = atom<stacks.Stack | null>(null);
// Keep track of previous stack state to detect changes
const shadowStackPreviousStateAtom = atom<stacks.Stack | null>(null);

// For testing, because we cannot modifiy the derived shadownStackAtom
export const _jestShadowStackStateAtom = shadowStackStateAtom;

export const shadowStackAtom = atom<
  stacks.Stack | null,
  [stacks.Stack | null],
  void
>(
  (get) => get(shadowStackStateAtom),
  // Only set the stack if the stack data has meaningfully changed
  (get, set, update) => {
    const previousStack = get(shadowStackStateAtom);
    const prevDiffStack = previousStack
      ? stackForDiffingForOnlyRenderedData(previousStack)
      : null;
    const currDiffStack = update
      ? stackForDiffingForOnlyRenderedData(update)
      : null;
    if (!isEqual(prevDiffStack, currDiffStack)) {
      set(shadowStackPreviousStateAtom, previousStack);
      set(shadowStackStateAtom, update);
    }
  },
);

export const shadowIsNewStackAtom = atom<boolean>(
  (get) => get(shadowStackAtom) === null,
);

export const shadowStackItemsAtom = atom<StackItem[] | null>(null);

export const shadowStackItemShortcutsAtom = atom<
  StackItemShortcutWithMetadata[] | null
>((get) => {
  const items = get(shadowStackItemsAtom);
  if (items) {
    return asShortcuts(items);
  }
  return null;
});

export const shadowSearchStackItemsAtom = atom<
  stacks.StackItemShortcut[] | null
>(null);

// If there are no results for the in stack search query,
// store the query here so AddStackItemBox can search for it
export const shadowSearchStackItemsNoResultsQueryAtom = atom<string | null>(
  null,
);

export const shadowStackNamespaceIdAtom = atom<string | null>(
  (get) => get(shadowStackAtom)?.namespace_id ?? null,
);

export const shadowStackHasWritePermissionsAtom = atom<boolean>((get) => {
  // Can always edit new stack
  if (get(shadowIsNewStackAtom)) {
    return true;
  }

  const permission = get(shadowStackAtom)?.permission?.['.tag'];

  return permission !== 'read';
});

export const _shadowSectionsStateAtom = atom<stacks.Section[] | null>(null);

export const _linkSectionsMapStateAtom = atom<Map<
  string,
  StackItemShortcutWithMetadata[]
> | null>(null);

export const shadowCurrentSectionIdAtom = atom<string | null>(null);
export const shadowCurrentActionAtom = atom<SectionsActionType | null>(null);
export const shadowMutationRequestIdAtom = atom<string | null>(null);
export const shadowSessionIdAtom = atom<string | undefined>(undefined);
