import { Chip, ChipSelectedStyle } from '@dropbox/dig-components/chip';
import { Menu } from '@dropbox/dig-components/menu';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ArchiveLine,
  CheckmarkLine,
  LockLine,
  PersonMultipleLine,
  SliderLine,
  StacksLine,
} from '@dropbox/dig-icons/assets';
import { useAllStacksAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { StackFilterOption } from '@mirage/service-settings/service/types';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { isDefined } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import { useCallback, useMemo } from 'react';
import { useIsArchiveStackEnabled } from '../FullScreenStack/hooks';
import { useStackPagePreferences } from './useStackPagePreferences';

const StackFilterChip = ({
  children,
  onClick,
  isSelected: isSelectedProp,
  iconAccessory,
  augustRevision,
}: {
  children: React.ReactNode;
  onClick: () => void;
  isSelected?: boolean;
  iconAccessory?: React.ReactNode;
  augustRevision: boolean;
}) => {
  let selectedStyle: ChipSelectedStyle | undefined = 'stroke';
  let isSelected = isSelectedProp;
  if (!augustRevision) {
    selectedStyle = isSelected ? 'fill' : 'stroke';
    isSelected = true;
  }
  return (
    <Chip
      onClick={onClick}
      variant={!augustRevision ? 'transparent' : undefined}
      isSelected={isSelected}
      selectedStyle={selectedStyle}
    >
      {!augustRevision && iconAccessory && (
        <Chip.IconAccessory>{iconAccessory}</Chip.IconAccessory>
      )}
      <Chip.Content>{children}</Chip.Content>
    </Chip>
  );
};

const stacksFilterMetadata = (
  augustRevision: boolean,
  isArchivedStacksEnabled: boolean,
) => {
  return [
    {
      key: StackFilterOption.ALL,
      icon: StacksLine,
      label: i18n.t('all_stacks'),
    },
    {
      key: StackFilterOption.MINE,
      icon: LockLine,
      label: i18n.t(augustRevision ? 'created_by_me' : 'my_stacks'),
    },
    {
      key: StackFilterOption.SHARED,
      icon: PersonMultipleLine,
      label: i18n.t('shared_with_me'),
    },
    isArchivedStacksEnabled
      ? {
          key: StackFilterOption.ARCHIVED,
          icon: ArchiveLine,
          label: i18n.t('archived'),
        }
      : undefined,
  ].filter(isDefined);
};

export const StackFilterChips = ({
  filterPreference,
  defaultFilter,
  collapsed = false,
}: {
  filterPreference?: StackFilterOption;
  defaultFilter?: StackFilterOption;
  collapsed?: boolean;
}) => {
  const { stackPageFilterPreference, setStackPageFilterPreference } =
    useStackPagePreferences();
  const selectedFilter =
    filterPreference ?? stackPageFilterPreference ?? defaultFilter;
  const augustRevision = useAllStacksAugustRevisionEnabled();

  const handleClick = useCallback(
    (key: StackFilterOption) => {
      setStackPageFilterPreference(key);

      // TODO: reserve for PAP metrics call
    },
    [setStackPageFilterPreference],
  );

  const isArchivedStacksEnabled = useIsArchiveStackEnabled();
  const filtersMetadata = useMemo(() => {
    return stacksFilterMetadata(augustRevision, isArchivedStacksEnabled);
  }, [augustRevision, isArchivedStacksEnabled]);

  // Return dropdown with menu options instead of chips
  if (collapsed) {
    return (
      <Menu.Wrapper onSelection={handleClick}>
        {({ getContentProps, getTriggerProps }) => (
          <>
            <IconButtonWithTooltip
              tooltipProps={{
                title: i18n.t('filter_option_label'),
              }}
              {...getTriggerProps()}
              variant="borderless"
              withDropdownIcon
            >
              <UIIcon src={SliderLine} />
            </IconButtonWithTooltip>
            <Menu.Content {...getContentProps()} minWidth={236}>
              <Menu.Segment withLabel={i18n.t('filter_option_label')}>
                {filtersMetadata.map(({ key, icon, label }) => (
                  <Menu.ActionItem
                    key={key}
                    value={key}
                    withLeftAccessory={<UIIcon src={icon} />}
                    withRightAccessory={
                      selectedFilter === key && <UIIcon src={CheckmarkLine} />
                    }
                    style={{ alignItems: 'center' }}
                  >
                    {label}
                  </Menu.ActionItem>
                ))}
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
    );
  }

  return (
    <>
      {filtersMetadata.map(({ key, icon, label }) => {
        return (
          <StackFilterChip
            key={key}
            onClick={() => {
              handleClick(key);
            }}
            isSelected={selectedFilter === key}
            iconAccessory={<UIIcon src={icon} />}
            augustRevision={augustRevision}
          >
            {label}
          </StackFilterChip>
        );
      })}
    </>
  );
};
