import { BoltDriver } from '@mirage/bolt/driver';
import { BoltData, createChannelStatesFromBoltData } from '@mirage/bolt/utils';
import { isDefined, nonEmpty } from '@mirage/shared/util/tiny-utils';

import type { stacks } from '@dropbox/api-v2-client';

const BOLT_APP_ID = 'stacks_bolt';

export class StacksBolt {
  private readonly boltDriver: BoltDriver;

  public constructor(
    boltOrigin: string,
    isDev: boolean,
    isTest: boolean,
    private readonly performSync: (hlcMicros?: number) => Promise<void>,
  ) {
    this.boltDriver = new BoltDriver(
      boltOrigin,
      isDev ? (s) => console.log(s) : () => {},
      isTest,
    );
  }

  public async initOnce(
    userBoltData: stacks.BoltData | undefined,
    stacks: stacks.Stack[],
    mode: 'doFirstSync' | 'skipFirstSync',
  ) {
    if (this.boltDriver.started) {
      if (mode === 'skipFirstSync') {
        this.resetAllBoltData(
          [...stacks.map((s) => s.bolt_data)].concat(userBoltData ?? []),
        );
      }
      return;
    }

    this.resetAllBoltData(
      [...stacks.map((s) => s.bolt_data)].concat(userBoltData ?? []),
    );

    this.boltDriver.startListening(
      (revision: string) => this.performSync(Number(revision)),
      () => this.performSync(),
    );

    if (mode === 'doFirstSync') {
      this.performSync();
    }
  }

  private static mapToBoltData(
    boltData: (stacks.BoltData | undefined)[],
  ): BoltData[] {
    return boltData.filter(isDefined).map((b) => ({
      boltToken: b.bolt_token ?? '',
      boltUniqueId: nonEmpty(b.bolt_unique_id, 'bolt_unique_id'),
      revision: nonEmpty(b.revision, 'revision'),
    }));
  }

  /** Reset the Bolt channel states to the given list of stacks. */
  public resetAllBoltData(boltData: (stacks.BoltData | undefined)[]) {
    this.boltDriver.setChannelStates(
      createChannelStatesFromBoltData(
        BOLT_APP_ID,
        StacksBolt.mapToBoltData(boltData),
      ),
    );
  }

  public stop() {
    this.boltDriver.stopListening();
  }
}
