import { Badge } from '@dropbox/dig-components/badge';
import { Button } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { Checkbox } from '@dropbox/dig-components/controls';
import { Menu } from '@dropbox/dig-components/menu';
import { UIIcon } from '@dropbox/dig-icons';
import { ChevronDownLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { getConnectorNameForAttr } from '@mirage/shared/connectors';
import i18n from '@mirage/translations';
import { useMemo } from 'react';
import styles from './AppFilterDropdown.module.css';
import { FilterProps } from './types';

type Props = {
  filters?: FilterProps[];
  onDeselectAll: () => void;
};

export const AppFilterDropdown = ({ filters, onDeselectAll }: Props) => {
  const activeFilters = useMemo(() => {
    return filters?.filter((f) => f.selected) ?? [];
  }, [filters]);

  const activeFilterCount = activeFilters.length;
  const activeFilterOverflowCount = activeFilters.length - 3;

  if (!filters) {
    return null;
  }
  return (
    <Menu.Wrapper closeOnSelection={false}>
      {({ getContentProps, getTriggerProps, closeMenu }) => (
        <>
          <Chip
            {...getTriggerProps()}
            isSelected={activeFilterCount > 0}
            selectedStyle="stroke"
            data-testid={'app-filter-chip'}
          >
            <Chip.Content>
              {activeFilterCount == 0 ? (
                i18n.t('filter_by_app')
              ) : (
                <div className={styles.activeFilterIconContainer}>
                  {activeFilters.map((f) => f.icon).slice(0, 3)}
                  {activeFilterOverflowCount > 0 && (
                    <Badge>+ {activeFilterOverflowCount}</Badge>
                  )}
                </div>
              )}
            </Chip.Content>
            <Chip.IconAccessory>
              <UIIcon src={ChevronDownLine} />
            </Chip.IconAccessory>
          </Chip>

          <Menu.Content {...getContentProps()}>
            <Menu.Segment
              withLabel={
                <>
                  {i18n.t('filter_by_app')}
                  <Button
                    size="small"
                    variant="transparent"
                    onClick={() => {
                      closeMenu({});
                      onDeselectAll();
                    }}
                  >
                    {activeFilterCount > 0 && i18n.t('deselect_all')}
                  </Button>
                </>
              }
            >
              {filters.map((filter: FilterProps, index) => (
                <Menu.ActionItem
                  className={styles.appFilterMenuItem}
                  key={index}
                  value={filter.selected}
                  role="menuitemcheckbox"
                  onClick={filter.onSelect}
                  onSubmit={filter.onSelect}
                  onKeyDown={(a) => {
                    filter.onKeyDown?.(a);
                  }}
                  aria-checked={filter.selected}
                  data-testid={`FilterCheckbox-${getConnectorNameForAttr(
                    (filter.label as string) || '',
                  )}`}
                  data-uxa-log={createUxaElementId(
                    `filter_checkbox_${filter.key}`,
                    {
                      actionSurfaceComponent: 'filter_checkbox',
                      featureLine: 'search',
                    },
                  )}
                  withLeftAccessory={
                    <div className={styles.appFilterLeftAccessory}>
                      <Checkbox
                        checked={filter.selected}
                        readOnly
                        tabIndex={-1}
                      />
                      {filter.icon}
                    </div>
                  }
                >
                  {filter.label}
                </Menu.ActionItem>
              ))}
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
