import { stacks } from '@dropbox/api-v2-client';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { StackSortOption } from '@mirage/service-settings/service/types';
import { useStackByIdSortSettings } from '@mirage/service-settings/useStackByIdSortSettings';
import { DEFAULT_SECTION_ID } from '@mirage/service-stacks/service/utils';
import { useAtomValue } from 'jotai';
import {
  FC,
  HTMLProps,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { DroppableEmptySection } from '../DragAndDrop/DroppableEmptySection';
import { shadowSearchStackItemsAtom } from '../fullPageShadowAtoms';
import { useShadowLinkSectionsMap, useShadowSections } from '../hooks';
import { SearchResultsStackItemsSection } from './SearchResultsStackItemsSection';
import { Section } from './Section';
import styles from './Section.module.css';
import { SortedStackItemsSection } from './SortedStackItemsSection';

interface SectionProps {
  namespaceId: string;
  onEditItem: (item: stacks.StackItemShortcut) => void;
}

export const Sections: React.FC<SectionProps> = ({
  namespaceId,
  onEditItem,
}) => {
  const augustRevision = useStackPageAugustRevisionEnabled();
  const { defaultSection, otherSections } = useShadowSections();
  const [linkSectionMap] = useShadowLinkSectionsMap();
  const { sortPreference } = useStackByIdSortSettings(namespaceId);
  const searchResults = useAtomValue(shadowSearchStackItemsAtom);

  /**
   * Keep track of the DroppableEmptySection that the user is currently hovering over.
   * The DND library seems to not always fire when a user has stopped hovering over
   * a DroppableEmptySection, so we are keeping track of this state in the parent
   * to ensure that only one DroppableEmptySection is highlighted at a time.
   */
  const [emptySectionIdHoveringOver, setEmptySectionIdHoveringOver] = useState<
    string | null
  >(null);

  const renderSection = useCallback(
    (section: stacks.Section): ReactElement => {
      const sectionId = section.id ?? DEFAULT_SECTION_ID;
      const items = linkSectionMap ? linkSectionMap.get(sectionId) ?? [] : [];
      return (
        <>
          <Section
            key={sectionId}
            section={section}
            items={items}
            onEditItem={onEditItem}
            augustRevision={augustRevision}
          />
          <DroppableEmptySection
            sectionId={sectionId}
            index={0}
            isHoveringForMoment={emptySectionIdHoveringOver === sectionId}
            setIsHoveringForMoment={(hovering: boolean) => {
              if (hovering) {
                setEmptySectionIdHoveringOver(sectionId);
              } else {
                setEmptySectionIdHoveringOver(null);
              }
            }}
            mustCreateNewSection={
              // when we have an empty default section, use this "empty space" to fill
              // the default section with items instead of creating a new section
              !(sectionId === DEFAULT_SECTION_ID && items.length === 0)
            }
          />
        </>
      );
    },
    [linkSectionMap, onEditItem, emptySectionIdHoveringOver, augustRevision],
  );

  let stackContent: ReactNode;

  if (augustRevision && searchResults) {
    stackContent = (
      <SearchResultsStackItemsSection
        items={searchResults}
        onEditItem={onEditItem}
      />
    );
  } else if (
    sortPreference !== undefined &&
    sortPreference.option !== StackSortOption.SECTION
  ) {
    stackContent = <SortedStackItemsSection onEditItem={onEditItem} />;
  } else {
    stackContent = [
      defaultSection && renderSection(defaultSection),
      ...otherSections.map((section) => renderSection(section)),
    ];
  }

  return (
    <Component className={styles.sectionsContainer}>{stackContent}</Component>
  );
};

// Needed while still supporting legacy <div> vs new revision's VerticalExpandable component
type ComponentType = HTMLProps<HTMLDivElement>;
const Component: FC<ComponentType> = (props) => <>{props.children}</>;
