import { SurveyContextProvider } from "@mirage/feedback/hooks";
import { WebWelcomeModalV2 } from "@mirage/growth/components/modals";
import useDropboxAccount from "@mirage/service-auth/useDropboxAccount";
import { logout } from "@mirage/service-logout";
import { logPageLoadMilestoneOnce } from "@mirage/service-operational-metrics/page-load";
import { SearchContextProvider } from "@mirage/service-search/hooks/useSearchContext";
import { privacyCategoryStateAtom } from "@mirage/shared/privacy";
import { useIsHoverFriendly } from "@mirage/shared/responsive/hover";
import { StartPageFocusStateLoader } from "@mirage/webapp/analytics/sessionManagers/StartPageFocusStateLoader";
import { WebLayout } from "@mirage/webapp/components/WebLayout";
import { getEligibilityFromCache } from "@mirage/webapp/gating/Eligibility";
import { WebEligibilityCheck } from "@mirage/webapp/gating/WebEligibilityCheck";
import { useInitAuthedJobs } from "@mirage/webapp/hooks/useInitAuthedJobs";
import { isConsentAccepted } from "@mirage/webapp/hooks/usePrivacyModule";
import { PageType } from "@mirage/webapp/routeTypes";
import { useAtomValue } from "jotai";

import { useRoute } from "../Routes";
import { Config } from "../shared/config";
import { sharedWorkerDatabase } from "../shared-worker/sharedWorkerStorage";

import { ExtensionConnector } from "./ExtensionConnector";

function getEligibilityFromStore() {
  return getEligibilityFromCache(sharedWorkerDatabase);
}

export const AuthedContainer: React.FC = () => {
  logPageLoadMilestoneOnce("AuthedContainer start");

  const route = useRoute();
  const account = useDropboxAccount();
  const privacyCategoryState = useAtomValue(privacyCategoryStateAtom);
  const isAnalyticsEnabled = isConsentAccepted(privacyCategoryState);

  useInitAuthedJobs(route.pattern, Config.DEVBOX);

  const isHoverFriendly = useIsHoverFriendly();

  if (route.pageType === PageType.LOGOUT) {
    return <route.Component />;
  }

  return (
    <SurveyContextProvider
      surface="webapp"
      environmentId={Config.SPRIG_ENVIRONMENT_ID}
      email={account?.email}
      disabled={!isAnalyticsEnabled}
      publicAccountId={
        account?.public_account_id && atob(account?.public_account_id)
      }
    >
      <SearchContextProvider>
        <ExtensionConnector />
        <WebEligibilityCheck
          getEligibilityFromStore={getEligibilityFromStore}
          logout={logout}
        />

        {route.pageType !== PageType.SETUP && isHoverFriendly && (
          // Don't show the welcome modal in touch-only screens or the onboarding page
          // to ensure that the first time users will see it will be when they're ready
          // to interact with the app features
          <WebWelcomeModalV2 size="standard" />
        )}

        <StartPageFocusStateLoader />

        {route.wholePage ? (
          <route.Component />
        ) : (
          <WebLayout>
            <route.Component />
          </WebLayout>
        )}
      </SearchContextProvider>
    </SurveyContextProvider>
  );
};
