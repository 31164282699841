import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useListenForTypeaheadReset(resetTypeaheadState: VoidFunction) {
  const [params, setSearchParams] = useSearchParams();
  const resetTypeahead = params.get('resetTypeahead');
  useEffect(() => {
    if (resetTypeahead === 'true') {
      resetTypeaheadState();
      setSearchParams((params) => {
        params.delete('resetTypeahead');
        return params;
      });
    }
  }, [resetTypeahead, setSearchParams, resetTypeaheadState]);
}
