import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import type { SearchFilter } from '@mirage/shared/search/search-filters';

export const SEARCH_QUERY_PARAM = 'query';
export const SEARCH_FILTERS_PARAM = 'filters';
export const BASE_SEARCH_RESULTS_URL = '/search_results';

export function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function getSearchFromURLParams(urlParams: URLSearchParams) {
  const query = urlParams.get(SEARCH_QUERY_PARAM) || '';
  const filtersParam = urlParams.get(SEARCH_FILTERS_PARAM);
  const filters: SearchFilter[] = filtersParam ? JSON.parse(filtersParam) : [];
  return {
    query,
    filters,
  };
}

export function generateSearchURL(
  query: string,
  filters?: SearchFilter[],
  baseURL: string = BASE_SEARCH_RESULTS_URL,
) {
  const urlParams = new URLSearchParams();
  urlParams.set(SEARCH_QUERY_PARAM, query);

  if (filters) {
    urlParams.set(SEARCH_FILTERS_PARAM, JSON.stringify(filters));
  }

  return `${baseURL}?${urlParams.toString()}`;
}
