// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1371
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSearchResultType } from '../enums/dash_search_result_type';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';
import type { SearchResultSource } from '../enums/search_result_source';
import type { TypeaheadResultType } from '../enums/typeahead_result_type';

// Event_name: 'copy.dash_search'
// Description: When a user copies a single dash search result. Not to be confused with copy.dash_search_results (https://pap.pp.dropbox.com/#/events/1462), which is when the user copies *all* search results.
// Owner: otc-growth
export type Copy_DashSearch = PAPEventGeneric<
  'dash',
  'copy',
  'dash_search',
  {
    // The contents of a search query
    queryString?: string;
    // What rank an item is in
    resultRank?: number;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash connector an event is associated with, e.g. Trello.
    dashConnectorId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // Number of results returned to user
    resultCount?: number;
    // The type of a Dash search result
    dashSearchResultType?: DashSearchResultType;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // Distinguishes between various data sources when performing a search in Dash
    searchResultSource?: SearchResultSource;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // Unique identifier (UUIDv5 ) of the search result
    resultUuid?: string;
    // unique id for a /dash/search query. Used by the ML team to get feedback on search result ranking
    searchRequestId?: string;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The surface in which an action was taken on Dash.
    dashActionSurface?: DashActionSurface;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Identifier for a search query
    searchQueryUuid?: string;
    // Type of result being acted upon within typeahead field
    typeaheadResultType?: TypeaheadResultType;
    // Set to true when the search option is selected from typeahead search
    isTypeahead?: boolean;
  }
>;

export function PAP_Copy_DashSearch(
  properties?: Copy_DashSearch['properties'],
): Copy_DashSearch {
  return <Copy_DashSearch>{
    class: 'dash',
    action: 'copy',
    object: 'dash_search',
    properties,
  };
}
