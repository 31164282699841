import { useSurveyContext } from '@mirage/feedback/hooks';
import { SurveyEvent } from '@mirage/feedback/types';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { sleepMs } from '@mirage/shared/util/tiny-utils';
import { useEffect } from 'react';

export const usePublishHomepageSurveyEvent = () => {
  const { publishEvent } = useSurveyContext();
  const dashResearchSentimentFlag = useFeatureFlagValue(
    'dash_research_sentiment',
  );

  useEffect(() => {
    const publishAfterDelay = async () => {
      await sleepMs(1500);
      publishEvent(SurveyEvent.OpenStartPage);
    };
    if (dashResearchSentimentFlag === 'ON') {
      publishAfterDelay();
    }
  }, [publishEvent, dashResearchSentimentFlag]);
};
