// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2557
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'open.dash_ask_page'
// Description: Fires when a user clicks the chat button to open the ask page
// Owner: dash-web
export type Open_DashAskPage = PAPEventGeneric<
  'dash',
  'open',
  'dash_ask_page',
  never
>;

export function PAP_Open_DashAskPage(
  properties?: Open_DashAskPage['properties'],
): Open_DashAskPage {
  return <Open_DashAskPage>{
    class: 'dash',
    action: 'open',
    object: 'dash_ask_page',
    properties,
  };
}
