import { IconButton } from '@dropbox/dig-components/buttons';
import { IconButtonGroup } from '@dropbox/dig-components/combinations';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ChevronDownLine,
  ChevronUpLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { DashAnswersFeedbackTag } from '@mirage/analytics/events/enums/dash_answers_feedback_tag';
import {
  MessageActions,
  MessageSecondaryFeedback,
} from '@mirage/conversations';
import { answersSecondaryFeedbackOptions } from '@mirage/conversations/FeedbackUtils/FeedbackUtils';
import { Markdown } from '@mirage/conversations/Markdown/Markdown';
import {
  QuestionAndAnswerResponse,
  QuestionAndAnswerSource,
} from '@mirage/shared/answers/multi-answer';
import { FeedbackOptions } from '@mirage/shared/types';
import { KeyCodes } from '@mirage/shared/util/constants';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { useEffect, useState } from 'react';
import styles from './Answer.module.css';

type AnswerProps = {
  answer: QuestionAndAnswerResponse;
  answerId: string;
  expandedByDefault: boolean;
  onSourceClick: (
    item: QuestionAndAnswerResponse,
    result: QuestionAndAnswerSource,
  ) => void;
  onShowFeedbackSnackbar: () => void;
  onCopyMessage: (item: QuestionAndAnswerResponse) => void;
  onClickFeedback: (
    item: QuestionAndAnswerResponse,
    feedback: FeedbackOptions,
  ) => void;
  onClickSecondaryFeedback: (
    item: QuestionAndAnswerResponse,
    feedback: DashAnswersFeedbackTag,
  ) => void;
  onExpand: () => void;
  onCollapse: () => void;
  annotationMode?: boolean;
};

export const Answer: React.FC<AnswerProps> = ({
  answer,
  expandedByDefault,
  onSourceClick,
  onCopyMessage,
  onClickFeedback,
  onClickSecondaryFeedback,
  onShowFeedbackSnackbar,
  onExpand,
  onCollapse,
  annotationMode = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isSecondaryModalOpen, setIsSecondaryModalOpen] = useState(false);

  const handleToggleExpandCollapse = () => {
    if (isExpanded) {
      onCollapse();
    } else {
      onExpand();
    }
    setIsExpanded((old) => !old);
  };

  const handleCloseSecondaryModal = () => {
    setIsSecondaryModalOpen(false);

    onShowFeedbackSnackbar();
  };

  const handleClickFeedback = (
    item: QuestionAndAnswerResponse,
    feedback: FeedbackOptions,
  ) => {
    onClickFeedback(item, feedback);
    if (annotationMode) {
      return;
    }

    if (feedback === FeedbackOptions.Negative) {
      setIsSecondaryModalOpen(true);
    } else {
      onShowFeedbackSnackbar();
    }
  };

  const handleClickSecondaryFeedback = (
    item: QuestionAndAnswerResponse,
    feedback: DashAnswersFeedbackTag,
  ) => {
    onClickSecondaryFeedback(item, feedback);
    setIsSecondaryModalOpen(false);
    onShowFeedbackSnackbar();
  };

  useEffect(() => {
    setIsExpanded(expandedByDefault);
  }, [expandedByDefault]);

  return (
    <div>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Text
          isBold
          className={styles.question}
          onClick={handleToggleExpandCollapse}
        >
          {answer.question}
        </Text>
        <IconButtonGroup size="medium">
          {({ getButtonProps }) => (
            <IconButton
              {...getButtonProps()}
              variant="borderless"
              onClick={handleToggleExpandCollapse}
            >
              <UIIcon src={isExpanded ? ChevronUpLine : ChevronDownLine} />
            </IconButton>
          )}
        </IconButtonGroup>
      </Box>

      {isExpanded && (
        <Box>
          <Markdown body={answer.answer} />
          <div className={styles.sourcesContainer}>
            {answer.sources.map((source) => (
              <DigTooltip key={source.id3p} title={source.title}>
                <button
                  className={styles.sourceButton}
                  onClick={() => {
                    onSourceClick(answer, source);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === KeyCodes.enter) {
                      onSourceClick(answer, source);
                    }
                  }}
                >
                  <img
                    className={styles.sourceIcon}
                    alt={i18n.t('answers_source_logo_alt', {
                      connectorName: source.connectorName,
                    })}
                    src={source.iconUrl}
                  />
                  <Text className={styles.sourceTitle} size="small" isBold>
                    {source.title}
                  </Text>
                </button>
              </DigTooltip>
            ))}
          </div>

          <Box display={'flex'} paddingTop={'12'}>
            <MessageSecondaryFeedback
              isOpen={isSecondaryModalOpen}
              onClose={handleCloseSecondaryModal}
              feedbackOptions={answersSecondaryFeedbackOptions}
              onClickFeedback={(feedback) => {
                handleClickSecondaryFeedback(answer, feedback);
              }}
              actionSurfaceComponent="inline_answer"
            />
            <MessageActions
              onClickFeedback={(feedback) => {
                handleClickFeedback(answer, feedback);
              }}
              onCopyMessage={() => {
                onCopyMessage(answer);
              }}
              actionSurfaceComponent="inline_answer"
            />
          </Box>
        </Box>
      )}
    </div>
  );
};
