import { Button } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { SwitcherLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useIsPublicSharingAllowed } from '@mirage/service-stack-admin-settings/hooks';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { iconSrcForAccessLevel } from '../Helpers/Utils';
import { UnthemedWrapper } from '../UnthemedWrapper';
import {
  NewPermissionIcon,
  POSSIBLE_SHARING_PERMISSIONS,
} from './PermissionsMenu';
import styles from './ShareModal.module.css';
import { SharingStackPermission, StackAccessLevel, StackInfo } from './Types';
import {
  accessLevelTitleForMemberCount,
  descriptionForStackAccessLevel,
  hasStackWritePermissions,
  newTitleForAccessLevel,
  serverAccessLevelFromAccessLevel,
  textForPermission,
} from './Utils';

const SwitcherIcon = (
  <UIIcon
    src={SwitcherLine}
    className={classNames(styles.faintText, styles.tinyIcon)}
  />
);

const AccessIcon = ({ level }: { level: StackAccessLevel }) => (
  <UIIcon src={iconSrcForAccessLevel(level)} className={styles.tinyIcon} />
);

export const AccessRowV2 = ({
  stackInfo,
  handleUpdateStackSharedLink,
  openSnackbar,
}: {
  stackInfo: StackInfo;
  handleUpdateStackSharedLink: (
    accessLevel: StackAccessLevel,
    permission: SharingStackPermission,
  ) => Promise<boolean>;
  openSnackbar: (message: string) => void;
}) => {
  const isPublicSharingAllowed = useIsPublicSharingAllowed(false);

  // If the stack has a team name, include the team permission as an option
  const allAccessLevels: StackAccessLevel[] = stackInfo.teamName
    ? [StackAccessLevel.INVITED, StackAccessLevel.TEAM]
    : [StackAccessLevel.INVITED];
  if (
    isPublicSharingAllowed ||
    stackInfo.accessLevel === StackAccessLevel.PUBLIC
  ) {
    allAccessLevels.push(StackAccessLevel.PUBLIC);
  }

  const [isLoading, setIsLoading] = useState(false);

  const setStackAccessLevel = async (newAccessLevel: StackAccessLevel) => {
    if (newAccessLevel === stackInfo.accessLevel) {
      return;
    }
    setIsLoading(true);
    const result = await handleUpdateStackSharedLink(
      newAccessLevel,
      stackInfo.linkPermission,
    );
    if (!result) {
      openSnackbar(i18n.t('failed_to_change_permissions'));
    }
    setIsLoading(false);
  };

  const setStackPermissions = async (
    newLinkPermission: SharingStackPermission,
  ) => {
    if (stackInfo.linkPermission === newLinkPermission) {
      return;
    }
    setIsLoading(true);
    const result = await handleUpdateStackSharedLink(
      stackInfo.accessLevel,
      newLinkPermission,
    );
    if (!result) {
      openSnackbar(i18n.t('failed_to_change_permissions'));
    }
    setIsLoading(false);
  };

  useEffect(() => setIsLoading(false), [stackInfo.latestHash]);
  const [leftButtonWidth, setLeftButtonWidth] = useState(0);

  return (
    <>
      <div className={styles.accessLevelContainer}>
        <div className={styles.permissionContainer2}>
          {hasStackWritePermissions(stackInfo.permission) ? (
            <>
              <div>
                <UnthemedWrapper>
                  <Menu.Wrapper
                    onSelection={(accessLevel: StackAccessLevel) =>
                      setStackAccessLevel(accessLevel)
                    }
                    className={styles.menu}
                  >
                    {({ getContentProps, getTriggerProps }) => (
                      <div
                        style={{ width: '100%' }}
                        ref={(el) => {
                          setLeftButtonWidth(
                            el?.getBoundingClientRect().width ?? 0,
                          );
                        }}
                      >
                        <Button
                          variant="outline"
                          size="xlarge"
                          {...getTriggerProps()}
                          className={classNames(
                            styles.button,
                            styles.leftButton,
                          )}
                          withIconStart={
                            <AccessIcon level={stackInfo.accessLevel} />
                          }
                          withIconEnd={SwitcherIcon}
                          disabled={isLoading}
                        >
                          <div>
                            <Text
                              isBold
                              variant="label"
                              color={isLoading ? 'faint' : 'standard'}
                            >
                              {newTitleForAccessLevel(
                                stackInfo.accessLevel,
                                stackInfo.teamName,
                              )}
                            </Text>
                            <Menu.Content
                              {...getContentProps()}
                              placement="bottom-start"
                              className={styles.accessMenu}
                              style={{ width: leftButtonWidth }}
                            >
                              <Menu.Segment>
                                {allAccessLevels.map((level) => (
                                  <Menu.ActionItem
                                    key={level}
                                    value={level}
                                    withLeftAccessory={
                                      <AccessIcon level={level} />
                                    }
                                    className={styles.accessMenuRow}
                                  >
                                    <div>
                                      <Text>
                                        {newTitleForAccessLevel(
                                          level,
                                          stackInfo.teamName,
                                        )}
                                      </Text>
                                    </div>
                                  </Menu.ActionItem>
                                ))}
                              </Menu.Segment>
                            </Menu.Content>
                          </div>
                        </Button>
                      </div>
                    )}
                  </Menu.Wrapper>
                </UnthemedWrapper>
              </div>
              <div>
                <UnthemedWrapper>
                  <Menu.Wrapper
                    onSelection={(permission: SharingStackPermission) =>
                      setStackPermissions(permission)
                    }
                    className={styles.menu}
                  >
                    {({ getContentProps, getTriggerProps }) => (
                      <Button
                        variant="outline"
                        size="xlarge"
                        {...getTriggerProps()}
                        className={classNames(
                          styles.button,
                          styles.rightButton,
                        )}
                        withIconEnd={SwitcherIcon}
                        disabled={isLoading}
                      >
                        <div>
                          <Text
                            isBold
                            variant="label"
                            color={isLoading ? 'faint' : 'standard'}
                          >
                            {textForPermission(stackInfo.linkPermission)}
                          </Text>
                          <Menu.Content
                            {...getContentProps()}
                            placement="bottom-end"
                            className={styles.permissionMenu}
                          >
                            <Menu.Segment>
                              {POSSIBLE_SHARING_PERMISSIONS.map((perm) => (
                                <Menu.ActionItem
                                  key={perm}
                                  value={perm}
                                  withRightAccessory={
                                    <NewPermissionIcon
                                      permission={perm}
                                      withFaintStyle={false}
                                    />
                                  }
                                >
                                  <Text>{textForPermission(perm)}</Text>
                                </Menu.ActionItem>
                              ))}
                            </Menu.Segment>
                          </Menu.Content>
                        </div>
                      </Button>
                    )}
                  </Menu.Wrapper>
                </UnthemedWrapper>
              </div>
            </>
          ) : (
            <>
              <Text
                isBold={true}
                size="small"
                variant="label"
                className={styles.standardText}
              >
                {accessLevelTitleForMemberCount(stackInfo.members.length)}
              </Text>
              <Text
                size="small"
                variant="paragraph"
                className={styles.standardText}
              >
                {descriptionForStackAccessLevel(
                  serverAccessLevelFromAccessLevel(stackInfo.accessLevel),
                  stackInfo.teamName,
                )}
              </Text>
            </>
          )}
        </div>
      </div>
    </>
  );
};
