import { stacks } from '@dropbox/api-v2-client';
import { AvailableThemeOverridesStrict } from '@dropbox/dig-foundations';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDefaultColorIndex, getTheme } from './utils';

export const useCurrentBackgroundTheme = (stack?: stacks.Stack | null) => {
  const location = useLocation();
  const [theme, setTheme] = useState<AvailableThemeOverridesStrict | undefined>(
    undefined,
  );

  useEffect(() => {
    let colorIndex;

    if (location.pathname === '/stacks/new') {
      colorIndex = getDefaultColorIndex();
    }

    if (stack && stack.stack_data?.color_index !== undefined) {
      colorIndex = stack.stack_data?.color_index;
    }

    if (colorIndex !== undefined) {
      setTheme(getTheme(colorIndex).subtle);
    } else {
      setTheme(undefined);
    }
  }, [location.pathname, stack]);

  return theme;
};
