import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type { Service } from '@mirage/service-local-file-settings/service';

const service = services.get<Service>(ServiceId.LOCAL_FILE_SETTINGS);

export async function toggleSystemDirEnabled(id: string, value: boolean) {
  return rx.firstValueFrom(service.toggleSystemDirEnabled(id, value));
}

export async function toggleLocalFilesEnabled(value: boolean) {
  return rx.firstValueFrom(service.toggleLocalFilesEnabled(value));
}

export async function openFolderPicker() {
  return rx.firstValueFrom(service.openFolderPicker());
}

export async function removeCustomFolder(id: string) {
  return rx.firstValueFrom(service.removeCustomFolder(id));
}

export async function getLocalFileSettings() {
  return rx.firstValueFrom(service.getLocalFileSettings());
}

export const listenForLocalFileSettingChanges = service.listen;
