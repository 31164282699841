import { Avatar } from '@dropbox/dig-components/avatar';
import { ClickOutside } from '@dropbox/dig-components/click_outside';
import { Overlay } from '@dropbox/dig-components/overlay';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import {
  TextInput,
  TextInputRefObject,
} from '@dropbox/dig-components/text_fields';
import { Typeahead } from '@dropbox/dig-components/typeahead';
import { UIIcon } from '@dropbox/dig-icons';
import { CheckmarkLine } from '@dropbox/dig-icons/assets';
import { getInitialsFromPerson, getPersonTitle } from '@mirage/shared/account';
import { KeyCodes } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './PeopleFilterTypeahead.module.css';
import { PeopleSearchStatus, usePeopleSearch } from './usePeopleSearch';

import type { PersonObject } from '@mirage/shared/search/search-filters';

const MAX_PEOPLE_FILTER_RESULTS = 5;

type PeopleFilterTypeaheadProps = {
  onPersonSelection: (person: PersonObject | undefined, query: string) => void;
  selectedPerson: PersonObject | undefined;
  onClose: () => void;
  isOpen: boolean;
  anchorRef: React.RefObject<HTMLButtonElement>;
};

export const PeopleFilterTypeahead = ({
  onPersonSelection,
  selectedPerson,
  onClose,
  isOpen,
  anchorRef,
}: PeopleFilterTypeaheadProps) => {
  const [query, setQuery] = useState('');
  const {
    handleSearchPeople,
    peopleSearchStatus,
    peopleResults,
    peopleSuggestions,
  } = usePeopleSearch();
  const isZeroQuery = query.length === 0;

  // does not contain the selected user (if any) because it will be displayed in a separate section.
  const suggestedPeopleForZeroQueryWithoutSelectedPerson: PersonObject[] =
    useMemo(
      () => [
        {
          type: 'anyone', // "Anyone" user, represents deselecting the People filter
          email: '',
        },
        ...peopleSuggestions.filter((user) =>
          selectedPerson ? selectedPerson.email !== user.email : true,
        ),
      ],

      [selectedPerson, peopleSuggestions],
    );

  const inputRef = useRef<TextInputRefObject>(null);

  useEffect(() => {
    setQuery(''); // Reset query
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    handleSearchPeople(e.target.value);
  };

  const handleOverlayKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case KeyCodes.escape:
      case KeyCodes.tab:
        e.preventDefault();
        e.stopPropagation();
        onClose();
        anchorRef?.current?.focus();
        break;
    }
  };

  const handleSelection = (_person: PersonObject | undefined) => {
    let person = _person;
    if (person?.email === selectedPerson?.email) {
      person = undefined;
    }
    onPersonSelection(person, query);
  };

  // Determines if we show the free-form email query result row
  // This appears if users type in an email address that may not match an existing user
  const showEmailQueryResult = query.includes('@');

  if (!isOpen) {
    return null;
  }

  return (
    <ClickOutside isActive shouldPropagateMouseEvents onClickOutside={onClose}>
      <Overlay
        offsetDistance={4}
        anchorRef={anchorRef}
        placement="bottom-start"
        onKeyDown={handleOverlayKeyDown}
        auto
      >
        <Typeahead.Wrapper onSelection={handleSelection} omitOverlay>
          {({ getTriggerProps, getContentProps }) => (
            <div className={styles.container}>
              <div className={styles.input}>
                <TextInput
                  {...getTriggerProps({
                    onChange: handleInputChange,
                  })}
                  ref={inputRef}
                  value={query}
                  placeholder={i18n.t('people_search_input_placeholder')}
                  aria-label={i18n.t('people_search_empty')}
                />
              </div>

              <Typeahead.Container
                {...getContentProps()}
                open // force the results open, regardless of whether the input is focused
                // Typeahead's isEmptyQuery actually means "should we display the emptyPrompt".
                isEmptyQuery={
                  !isZeroQuery &&
                  peopleResults.length === 0 &&
                  !showEmailQueryResult &&
                  peopleSearchStatus !== PeopleSearchStatus.LOADING
                }
                emptyPrompt={
                  <Typeahead.EmptyPrompt
                    placeholderText={i18n.t('people_search_no_results')}
                  />
                }
              >
                {selectedPerson && isZeroQuery && (
                  <Typeahead.Results
                    results={[selectedPerson]}
                    renderRow={(person) => (
                      <PeopleFilterTypeaheadRow
                        person={person}
                        isSelected
                        inputRef={inputRef}
                      />
                    )}
                  />
                )}

                {showEmailQueryResult && (
                  // A dedicated result row for free-form email input to ensure it appears on top
                  <Typeahead.Results
                    results={[undefined]}
                    renderRow={() => (
                      <PeopleFilterTypeaheadRow
                        person={{ email: query }}
                        isSelected={false}
                        inputRef={inputRef}
                      />
                    )}
                  />
                )}
                {peopleSearchStatus === PeopleSearchStatus.LOADING ? (
                  <div className={styles.spinner}>
                    <Spinner />
                  </div>
                ) : (
                  <Typeahead.Results
                    maxResults={MAX_PEOPLE_FILTER_RESULTS}
                    initialResults={MAX_PEOPLE_FILTER_RESULTS}
                    results={
                      isZeroQuery
                        ? suggestedPeopleForZeroQueryWithoutSelectedPerson
                        : peopleResults
                    }
                    renderRow={(person) => (
                      <PeopleFilterTypeaheadRow
                        person={person}
                        isSelected={
                          (person.type === 'anyone' && !selectedPerson) ||
                          (selectedPerson &&
                            selectedPerson?.email === person?.email)
                        }
                        inputRef={inputRef}
                      />
                    )}
                  />
                )}
              </Typeahead.Container>
            </div>
          )}
        </Typeahead.Wrapper>
      </Overlay>
    </ClickOutside>
  );
};

type PeopleFilterTypeaheadRowProps = {
  person: PersonObject;
  isSelected?: boolean;
  inputRef: React.RefObject<TextInputRefObject>;
};

const PeopleFilterTypeaheadRow = ({
  person,
  isSelected,
  inputRef,
}: PeopleFilterTypeaheadRowProps) => {
  const initials = getInitialsFromPerson(person);

  const selectedCheckbox = useMemo(() => {
    return isSelected ? (
      <UIIcon src={CheckmarkLine} />
    ) : (
      // Setting prop to true will make sure the accessory is always rendered even
      // if empty, so items are always aligned regardless of check icon or not
      true
    );
  }, [isSelected]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent): void => {
      // Shift + Tab should focus the search input
      if (e.shiftKey && e.key === KeyCodes.tab) {
        e.preventDefault();
        e.stopPropagation();
        inputRef?.current?.focus();
      }
    },
    [inputRef],
  );

  if (person.type === 'anyone') {
    // "Anyone" user, represents deselecting the People filter
    return (
      <Typeahead.Row
        key="anyone"
        value={undefined}
        withTitle={i18n.t('people_filter_anyone_option')}
        withLeftAccessory={selectedCheckbox}
        onKeyDown={onKeyDown}
      />
    );
  }

  const title = getPersonTitle(person);
  // If display name doesn't exist, we'll show email as subtext
  const subtitle = person.displayName ? person.email : undefined;

  return (
    <Typeahead.Row
      key={person.email}
      value={person}
      withTitle={title}
      withSubtitle={subtitle}
      withRightAccessory={
        <Avatar
          size="small"
          src={person.profilePhotoUrl}
          hasNoOutline
          alt={initials}
        >
          {initials}
        </Avatar>
      }
      withLeftAccessory={selectedCheckbox}
      onKeyDown={onKeyDown}
    />
  );
};
