import type { APIRoute } from './types';

/**
 * Note that there isn't a way right now to programmatically get the server
 * timeout config, so we apply some heuristics to
 */
export function getTimeoutMsForApi<Route extends APIRoute>(
  apiName: Route,
): number {
  if (apiName.startsWith('stacks')) {
    return 30_000;
  }
  if (apiName.startsWith('dcs')) {
    return 60_000;
  }
  if (apiName.startsWith('sharing')) {
    return 60_000;
  }
  if (apiName === 'contextEngineGetAnswersForQuery') {
    return 120_000; // this one takes very long
  }
  if (apiName.startsWith('contextEngine')) {
    return 60_000;
  }

  // Atlas default: 10s.
  return 10_000;
}
