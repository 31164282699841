import { ChatEntryPoint } from '@mirage/conversations/types';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { useDashTitle } from '@mirage/shared/hooks/DashTitle';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import { AnswersConversation } from '../AnswersConversation';
import styles from './AnswersPage.module.css';

const AnswersPage = () => {
  useDashTitle(i18n.t('ask'));

  const enableSuggestedQuestions = !!useFeatureFlagValue(
    'dash_answers_suggested_questions',
  );

  return (
    <div className={styles.container}>
      <AnswersConversation
        isFullPage={true}
        enableSuggestedQuestions={enableSuggestedQuestions}
        papData={{ entryPoint: ChatEntryPoint.ask_dash_button }}
        onOpenFeedbackSnackbar={() => {
          showSnackbar({
            title: i18n.t('feedback_received'),
          });
        }}
        onOpenCopyTextSnackbar={() => {
          showSnackbar({ title: i18n.t('copied_answer') });
        }}
      />
    </div>
  );
};

export default AnswersPage;
