import { IconButton } from '@dropbox/dig-components/buttons';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine, DropboxLine, SearchLine } from '@dropbox/dig-icons/assets';
import { GlyphLogo } from '@dropbox/dig-logos';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { useResizeObserver } from '@react-hookz/web';
import classNames from 'classnames';
import {
  ChangeEvent,
  forwardRef,
  MouseEvent,
  RefObject,
  useEffect,
  useState,
} from 'react';
import { ConnectedAppsIndicator } from './ConnectedAppsIndicator';
import { useTypeaheadAutocomplete } from './hooks/useTypeaheadAutocomplete';
import styles from './SearchHeader.module.css';

import type { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';

const AUTOCOMPLETE_WIDTH_RATIO = 0.65;

type Placeholder = {
  key: string;
  content: React.ReactNode;
};

type SearchHeaderProps = {
  query: string;
  onQueryUpdate: (query: string) => void;
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onCloseTypeahead: () => void;
  onShouldBoostSearchToTop: (shouldBeBoosted: boolean) => void;
  onOpenConnectors: () => void;
  shouldFocusOnRender?: boolean;
  disabled?: boolean;
  focused?: boolean;
  typeaheadIsOpen?: boolean;
  fullWidth?: boolean;
  animatePlaceholder?: boolean;
  placeholderTexts?: string[];
  inputRef: RefObject<HTMLInputElement>;
  selectedIdx: number;
  searchResults: TypeaheadResult[] | null;
  shouldSearchBeBoosted: boolean;
  isDarwin?: boolean;
};

export const SearchHeader = forwardRef<HTMLDivElement, SearchHeaderProps>(
  (
    {
      query,
      onQueryUpdate,
      onClick,
      onFocus,
      onCloseTypeahead,
      onShouldBoostSearchToTop,
      onOpenConnectors,
      shouldFocusOnRender = false,
      disabled = false,
      focused = false,
      typeaheadIsOpen = false,
      fullWidth = false,
      animatePlaceholder = false,
      placeholderTexts = [],
      inputRef,
      selectedIdx,
      searchResults,
      shouldSearchBeBoosted,
      isDarwin = true,
    }: SearchHeaderProps,
    ref,
  ) => {
    const [active, setActive] = useState(shouldFocusOnRender && !disabled);
    // Temporary solution. Not all modals are handled via useModal() yet
    const isModalOpen = document.getElementsByClassName('dig-Modal').length > 0;
    const [hasFocused, setHasFocused] = useState(false);
    const [placeholderWidth, setPlaceholderWidth] = useState<number>();
    const isMobileSize = useIsMobileSize();

    const {
      inputValue,
      onSearchInputKeyDown,
      inlineCTAText,
      suffixPosition,
      shouldHighlightCTA,
      fakeAutocompleteText,
      selectedIcon,
    } = useTypeaheadAutocomplete(
      query,
      searchResults,
      selectedIdx,
      inputRef,
      typeaheadIsOpen,
      shouldSearchBeBoosted,
      onShouldBoostSearchToTop,
    );

    const defaultPlaceholder: Placeholder = hasFocused
      ? {
          key: 'default-placeholder',
          content: i18n.t('search_placeholder_default'),
        }
      : {
          key: 'default-placeholder',
          content: (
            <>
              {i18n.t('search_placeholder_default')}&nbsp;&nbsp;
              <div className={styles.placeholderKeyCap}>
                <div className={styles.placeholderKeyCapSymbol}>⇥</div>
                {isDarwin ? 'TAB' : 'Tab'}
              </div>
            </>
          ),
        };

    const placeholders: Placeholder[] = [
      defaultPlaceholder,
      ...placeholderTexts.map((text) => ({ content: text, key: text })),
    ];
    const [hasStablePlaceholder, setHasStablePlaceholder] = useState(false);
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    const [cachedPlaceholder, setCachedPlaceholder] =
      useState<Placeholder | null>(null);

    const handleResize = () => {
      if (inputRef.current) {
        setPlaceholderWidth(
          inputRef.current.clientWidth * AUTOCOMPLETE_WIDTH_RATIO,
        );
      }
    };
    useResizeObserver(inputRef, handleResize);

    // Don't start rotating the placeholder until we've had it stable for at least one cycle.

    useEffect(() => {
      if (!animatePlaceholder || placeholders.length <= 1) return;

      const id = setInterval(() => {
        if (
          inputRef?.current === document.activeElement &&
          inputRef?.current?.value === '' &&
          hasFocused
        ) {
          setHasStablePlaceholder(true);
          setCachedPlaceholder(null);
          setSuggestionIndex((prev) => (prev + 1) % placeholders.length);
        }
      }, 4000);

      return () => {
        clearInterval(id);
      };
    }, [animatePlaceholder, placeholders.length, inputRef, hasFocused]);

    const resetPlaceholderAnimation = () => {
      if (suggestionIndex % placeholders.length > 0) {
        setCachedPlaceholder(placeholders.at(suggestionIndex) || null);
      }
      setSuggestionIndex(0);
    };

    const currentSuggestion: Placeholder =
      placeholders.at(suggestionIndex) ?? defaultPlaceholder;
    const previousSuggestion: Placeholder | undefined =
      cachedPlaceholder ??
      placeholders.at(suggestionIndex - 1) ??
      defaultPlaceholder;

    useEffect(() => {
      function handleVisibilityChange() {
        if (!inputRef.current) return;
        if (document.visibilityState !== 'visible') {
          inputRef.current?.blur();
          return;
        }
        inputRef.current.select();
      }

      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () =>
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
    }, [inputRef]);

    useEffect(() => {
      shouldFocusOnRender && inputRef.current?.focus();
    }, [inputRef, shouldFocusOnRender]);

    const handleFocus = () => {
      if (!hasFocused) {
        setHasFocused(true);
      }

      if (onFocus) {
        onFocus();
      }
    };

    /**
     * Form Handlers
     */

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
      resetPlaceholderAnimation();
      onQueryUpdate(event.target.value);
    };

    const handleClearSearch = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onQueryUpdate('');
      inputRef.current?.focus();

      if (isMobileSize) {
        onCloseTypeahead();
      }
    };

    /**
     * Render
     */
    return (
      <div
        className={classNames(styles.searchHeaderContainer, {
          [styles.active]: active,
          [styles.extendBottom]: typeaheadIsOpen,
          [styles.fullWidthSearchbar]: fullWidth,
          [styles.typeaheadIsOpen]: typeaheadIsOpen,
        })}
        ref={ref}
        onMouseEnter={() => !disabled && setActive(true)}
        onMouseLeave={() => !disabled && setActive(false)}
        onMouseOver={() => !disabled && setActive(true)}
        onFocus={() => void 0}
      >
        <label
          className={classNames(styles.searchBarContainer, {
            [styles.disabled]: disabled,
            [styles.extendBottom]: typeaheadIsOpen,
            [styles.focused]: focused,
            [styles.fullWidthSearchbar]: fullWidth,
          })}
          htmlFor="search-bar"
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
        >
          <div
            className={classNames(styles.searchIconContainer, {
              [styles.fullWidthSearchbar]: fullWidth,
            })}
          >
            {selectedIcon ? (
              selectedIcon
            ) : fullWidth ? (
              <GlyphLogo
                src={DropboxLine}
                size={56}
                style={{ height: '49px', width: '49px' }}
                color="var(--dig-color__text__base)"
              />
            ) : (
              <UIIcon
                src={SearchLine}
                size="medium"
                color={
                  disabled
                    ? 'var(--dig-color__disabled__base)'
                    : 'var(--dig-color__text__subtle)'
                }
              />
            )}
          </div>
          <div
            className={classNames(styles.searchInputContainer, {
              [styles.fullWidthSearchbar]: fullWidth,
            })}
          >
            <input
              className={classNames(styles.searchInputEl, {
                [styles.disabled]: disabled,
                [styles.fullWidthSearchbar]: fullWidth,
                [styles.searchInputElHiddenText]: suffixPosition === 0,
              })}
              ref={inputRef}
              value={inputValue}
              type="text"
              id="search-bar"
              onInput={handleSearchChange}
              onKeyDown={onSearchInputKeyDown}
              onFocus={handleFocus}
              onClick={onClick}
              onBlur={resetPlaceholderAnimation}
              placeholder={i18n.t('search_prompt')}
              disabled={isModalOpen || disabled}
              autoComplete="off"
              tabIndex={isModalOpen ? undefined : 1}
              data-uxa-log={createUxaElementId('typeahead_input', {
                actionSurface: 'search_typeahead',
                actionSurfaceComponent: 'search_bar',
                featureLine: 'search',
              })}
              data-uxa-interactions="click change"
            />
            {inlineCTAText && suffixPosition != null ? (
              <span
                className={classNames(styles.suffixElement, {
                  [styles.fullWidthSearchbar]: fullWidth,
                  [styles.autocompleteHighlight]: shouldHighlightCTA,
                })}
                style={{ left: `${suffixPosition}px` }}
              >
                <span
                  className={classNames(
                    styles.fakeAutocomplete,
                    styles.searchInputEl,
                    {
                      [styles.disabled]: disabled,
                      [styles.fullWidthSearchbar]: fullWidth,
                      [styles.autocompleteHighlight]: shouldHighlightCTA,
                    },
                  )}
                  style={{
                    maxWidth: placeholderWidth
                      ? `${placeholderWidth}px`
                      : undefined,
                  }}
                >
                  {suffixPosition === 0 && fakeAutocompleteText}
                </span>
                {inlineCTAText}
              </span>
            ) : null}
            <Box
              as="span"
              pointerEvents="none"
              whiteSpace="nowrap"
              overflow="hidden"
              position="absolute"
              height="100%"
              width="100%"
              color="Text Subtle"
              fontSize={fullWidth ? 'Title Medium' : 'Text Large'}
              display="flex"
              fontFamily="Text"
              aria-hidden={true}
              className={classNames(styles.fakePlaceholder, {
                [styles.hasStablePlaceholder]: hasStablePlaceholder,
              })}
            >
              {i18n.t('search_placeholder_base')}
              <Box
                display="inline-flex"
                position="relative"
                flexGrow={1}
                alignItems="center"
              >
                &nbsp;
                <Box
                  as="span"
                  position="absolute"
                  display="inline-flex"
                  alignItems="center"
                  key={currentSuggestion.key}
                  className={styles.currentSuggestion}
                >
                  &nbsp;{currentSuggestion.content}
                </Box>
                {animatePlaceholder && placeholders.length > 1 && (
                  <Box
                    as="span"
                    position="absolute"
                    key={previousSuggestion.key}
                    className={styles.previousSuggestion}
                  >
                    &nbsp;{previousSuggestion.content}
                  </Box>
                )}
              </Box>
            </Box>
          </div>

          <div className={styles.searchActionsContainer}>
            {query.length === 0 && (
              <ConnectedAppsIndicator onOpenConnectors={onOpenConnectors} />
            )}

            {query && !disabled && (
              <DigTooltip title={i18n.t('clear_search')} placement="bottom-end">
                <IconButton
                  variant="borderless"
                  onClick={handleClearSearch}
                  type="reset"
                  style={{ borderRadius: '4px' }}
                  data-uxa-log={createUxaElementId('clear_button', {
                    actionSurface: 'search_typeahead',
                    actionSurfaceComponent: 'search_bar',
                    featureLine: 'search',
                  })}
                >
                  <UIIcon src={CloseLine} />
                </IconButton>
              </DigTooltip>
            )}
          </div>
        </label>
      </div>
    );
  },
);

SearchHeader.displayName = 'SearchHeader';
