import { dash_feed } from '@dropbox/api-v2-client';
import { ActivityFeedFilters } from '../types';

import type { APIv2Callable } from '@mirage/service-dbx-api/service';

export async function listActivityFeed(
  callApiV2: APIv2Callable,
  limit?: number,
  cursor?: string,
  last_viewed_ts?: number,
  activityFeedFilters?: ActivityFeedFilters,
): Promise<dash_feed.ListActivityFeedResponse> {
  let filters: dash_feed.ActivityFeedFilters | undefined;
  if (activityFeedFilters) {
    // DASHWEB-3960 - protobuf treats empty arrays the same as undefined
    //    but product requirement says when there are no applications selected we should return no results
    //    however, the API returns all applications when none are passed (so that when the user hasn't made a selection yet it shows everything)
    //    so we special case it here
    if (
      activityFeedFilters.applications &&
      activityFeedFilters.applications.length === 0
    ) {
      return {
        items: [],
      };
    }

    const actor = activityFeedFilters.actor
      ? { email: activityFeedFilters.actor.email }
      : undefined;

    const applications = (activityFeedFilters.applications || []).map(
      (application) => ({ application }),
    );

    filters = {
      only_mine: activityFeedFilters.isOnlyMine,
      applications,
      actor,
    };
  }

  const args: dash_feed.ListActivityFeedArg = {
    limit,
    cursor,
    last_viewed_ts,
    filters,
  };

  return callApiV2('dashFeedListActivityFeed', args);
}
