import { tagged } from '@mirage/service-logging';
import { useSetConnection } from '@mirage/shared/atoms/connections';
import { showSnackbar } from '@mirage/shared/snackbar';
import { UIConnection, UIConnector } from '@mirage/shared/types';
import i18n from '@mirage/translations';
import { useConnectConnector } from '../connectors/useConnectConnector';
import { useDisconnectConnection } from './useDisconnectConnection';

const logger = tagged('useReauthenticateConnection');

export const useReauthenticateConnection = () => {
  const setConnection = useSetConnection();
  const connectConnector = useConnectConnector();
  const disconnectConnection = useDisconnectConnection();

  return (connection: UIConnection, connector: UIConnector) => {
    return reauthenticateConnection(
      connection,
      connector,
      setConnection,
      connectConnector,
      disconnectConnection,
    );
  };
};

export async function reauthenticateConnection(
  connection: UIConnection,
  connector: UIConnector,
  setConnection: ReturnType<typeof useSetConnection>,
  connectConnector: ReturnType<typeof useConnectConnector>,
  disconnectConnection: ReturnType<typeof useDisconnectConnection>,
): Promise<{ success: boolean }> {
  const id = connection.id_attributes?.id;

  try {
    if (!id || !connector) throw new Error('missing id or connector');

    // Mark connection as loading. This is unmarked by connectConnector.
    setConnection(id, { ...connection, loading: true });
    const connectResult = await connectConnector(connector);

    if (!connectResult.success) {
      showSnackbar({
        title: i18n.t('connectors_settings_error_reconnecting', {
          appName: connection.branding?.display_name,
        }),
      });
      return { success: false };
    }

    if (connectResult.connectionId !== id) {
      // TODO Implement explicit reauthentication with Janus (i.e. do not remove the connection).
      const disconnectResult = await disconnectConnection(connection);

      if (!disconnectResult.success) {
        // Reconnect occurred but cleanup failed.
        showSnackbar({
          title: i18n.t('connectors_settings_error_reconnecting_cleanup', {
            appName: connection.branding?.display_name,
          }),
        });
        return { success: false };
      }
    }

    showSnackbar({
      title: i18n.t('connectors_settings_reconnected_from_service', {
        appName: connection.branding?.display_name,
      }),
    });
    return { success: true };
  } catch (e) {
    logger.error(e);
    if (id) setConnection(id, { ...connection, loading: false });
    showSnackbar({
      title: i18n.t('connectors_settings_error_disconnecting', {
        appName: connection.branding?.display_name,
      }),
    });
    return { success: false };
  }
}
