import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Snackbar } from '@dropbox/dig-components/snackbar';
import { Text } from '@dropbox/dig-components/typography';
import { ThemeProvider } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine, InfoLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Select_DashSearchResultLabels } from '@mirage/analytics/events/types/select_dash_search_result_labels';
import { AnnotationIntroModal } from '@mirage/mosaics/SearchPage/Annotations/AnnotationIntroModal';
import { useOnboardingValues } from '@mirage/service-onboarding/hooks';
import { set as setSettingValue } from '@mirage/service-settings';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AnnotationsConfirmation.module.css';
import { AnnotationURLModal } from './AnnotationURLModal';

const SEEN_ANNOTATION_INTRO_MODAL = 'seenAnnotationIntroModal';

export const AnnotationsConfirmation = ({
  searchRequestId,
  query,
}: {
  searchRequestId?: string;
  query: string;
}) => {
  const { reportPapEvent, searchAttemptSessionManager, searchSessionManager } =
    useMirageAnalyticsContext();
  const navigate = useNavigate();
  const [showURLModal, setShowURLModal] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const { getOnboardingValue, setOnboardingValue } = useOnboardingValues();

  useEffect(() => {
    getOnboardingValue(SEEN_ANNOTATION_INTRO_MODAL).then((seen) => {
      if (!seen) {
        setShowIntroModal(true);
        setOnboardingValue(SEEN_ANNOTATION_INTRO_MODAL, true);
      }
    });
  }, [getOnboardingValue, setOnboardingValue]);

  const handleSubmitURL = (url: string) => {
    reportPapEvent(
      PAP_Select_DashSearchResultLabels({
        queryString: query,
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        searchAttemptId: searchAttemptSessionManager.getSessionIdOrUndefined(),
        searchRequestId,
        goldenResult: url,
      }),
    );
  };

  const handleExit = () => {
    navigate('/');
    setSettingValue('annotationMode', 0);
  };

  return (
    <>
      <ThemeProvider mode="dark">
        <div className={styles.container}>
          <Snackbar open preferComposition className={styles.snackbar}>
            <Snackbar.Content>
              <IconButton
                variant="transparent"
                onClick={() => setShowIntroModal(true)}
              >
                <UIIcon src={InfoLine} />
              </IconButton>
              <Snackbar.Message>
                <Text isBold>
                  You are in annotation mode.{' '}
                  <Button
                    variant="transparent"
                    href="#"
                    onClick={() => setShowIntroModal(true)}
                  >
                    Click here to learn more.
                  </Button>
                </Text>
              </Snackbar.Message>
              <Snackbar.Actions>
                <Button
                  variant="outline"
                  onClick={() => setShowURLModal(true)}
                  className={styles.urlButton}
                >
                  Add missing URLs
                </Button>
              </Snackbar.Actions>
              <Snackbar.Actions>
                <Button
                  variant="borderless"
                  onClick={handleExit}
                  withIconStart={<UIIcon src={CloseLine} />}
                >
                  Disable annotation mode
                </Button>
              </Snackbar.Actions>
            </Snackbar.Content>
          </Snackbar>
        </div>
      </ThemeProvider>
      <AnnotationURLModal
        isOpen={showURLModal}
        onClose={() => setShowURLModal(false)}
        onSubmitURL={handleSubmitURL}
      />
      <AnnotationIntroModal
        open={showIntroModal}
        onClose={() => setShowIntroModal(false)}
      />
    </>
  );
};
