import styled from '@emotion/styled';
import * as React from 'react';

const variance = {
  dark: `
    color: var(--color__inverse__faint__text);
    background-color: var(--color__inverse__elevated__background);
    border-color: #5B5650;
  `,
  darkFixed: `
    color: rgba(247, 245, 242, 0.6);
    background-color: #2B2929;
    border-color: #2B2929;
  `,
  light: `
    color: var(--color__faint__text);
    background-color: var(--color__elevated__background);
    border-color: var(--color__faint__border);
  `,
  lightFixed: `
    color: rgba(82, 74, 62, 0.82);
    background-color: rgba(251, 250, 249, 1);
    border-color: rgba(166, 145, 113, 0.2);
  `,
  primaryBtn: `
    color: var(--color__core__secondary);
    background-color: var(--color__inverse__core__accent);
    border-color: var(--color__faint__border);
  `,
  primaryBtnDisabled: `
    color: var(--color__faint__text);
    background-color: rgba(247, 245, 242, 0.3);
    border-color: var(--color__faint__border);
  `,
  opacity: `
    color: var(--color__faint__text);
    background-color: var(--color__opacity--1);
    border-color: var(--color__standard__border);
  `,
};

export type KeyCapVariant =
  | 'dark'
  | 'darkFixed'
  | 'light'
  | 'lightFixed'
  | 'primaryBtn'
  | 'primaryBtnDisabled'
  | 'opacity';

export type KeyCapCasing = 'uppercase' | 'lowercase' | 'capitalize';

type Props = Readonly<{
  casing: KeyCapCasing;
  children: React.ReactNode;
  /** used to set font-size for symbols to get proper size */
  symbol?: boolean;
  variant?: KeyCapVariant;
  onClick?: () => void;
}>;

export default function KeyCap({
  children,
  symbol = false,
  variant = 'light',
  casing,
  onClick,
}: Props) {
  return (
    <KeyCapDiv
      variant={variant}
      symbol={symbol}
      className="keyCap" // used in panel
      casing={casing}
      onClick={onClick}
    >
      {children}
    </KeyCapDiv>
  );
}

const symbol = `
  font-size: 18px;
`;

const KeyCapDiv = styled.div`
  font-family: var(--type__body__monospace--fontfamily);
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  border-radius: var(--dig-spacing__dimension__4);

  display: inline-flex;
  justify-content: center;
  align-items: center;

  min-width: var(--dig-spacing__dimension__24);
  min-height: var(--dig-spacing__dimension__24);
  border: 1px solid var(--dig-color__border__subtle);
  padding: 0px var(--dig-spacing__dimension__4);
  box-sizing: border-box;
  text-transform: ${(props) => props.casing};
  ${(props: Props) => variance[props.variant ?? 'light']}
  ${(props: Props) => (props.symbol ? symbol : '')}
`;
