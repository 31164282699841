import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { tagged } from '@mirage/service-logging';
import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import { register, unregister } from '@mirage/shared/util/jobs';
import WithDefaults from '@mirage/storage/with-defaults';
import _ from 'lodash';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import {
  LastViewedStackInfo,
  RecentBrowserContent,
  RecentConnectorContent,
  RecentContent,
} from '../types';
import { browse, getRecentContent } from './api';

import type { APIv2Callable } from '@mirage/service-dbx-api/service';
import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

const SYNC_RECENT_ENTITIES_JOB_NAME = 'recent-entities-sync';
const SYNC_INTERVAL_MS = ONE_MINUTE_IN_MILLIS * 3;

export type RecentData<Content extends RecentContent> = {
  isLoading: boolean;
  content: Content[];
};

export type Service = ReturnType<typeof provideRecentContentService>;

export type StoredRecentContent = {
  // Old local storage keys were content, contentV2
  browserHistoryV3: RecentBrowserContent[];
  recentContentV3: RecentConnectorContent[];
  lastViewedStackInfo: LastViewedStackInfo;
  lastBrowserHistoryFetchTimestamp?: number; // Timestamp of the last browser history fetch
  lastRecentEntitiesFetchTimestamp?: number; // Timestamp of the last recent entities fetch
};

type DbxApiServiceContract = {
  callApiV2: APIv2Callable;
};

type LogoutServiceContract = {
  listenForLogout(service: string): Observable<boolean>;
};

const logger = tagged('recent-content');

export default function provideRecentContentService(
  rawStorage: KVStorage<StoredRecentContent>,
  { callApiV2 }: DbxApiServiceContract,
  logoutService: LogoutServiceContract,
) {
  const adapter = new WithDefaults(rawStorage, {
    browserHistoryV3: [],
    recentContentV3: [],
    lastViewedStackInfo: {},
    lastBrowserHistoryFetchTimestamp: 0,
    lastRecentEntitiesFetchTimestamp: 0,
  });

  const loadingBrowserHistory$ = new rx.BehaviorSubject<boolean>(true);
  const browserHistoryContent$ = new rx.BehaviorSubject<RecentBrowserContent[]>(
    [],
  );
  const loadingRecentEntities$ = new rx.BehaviorSubject<boolean>(true);
  const recentRecentEntities$ = new rx.BehaviorSubject<
    RecentConnectorContent[]
  >([]);

  // Load data from storage. In theory this could race with a network fetch but
  // local read should almost always be faster. We could probably clean this up
  // by having the storage adapter expose an observable instead.
  adapter
    .get('browserHistoryV3')
    .then((history) => browserHistoryContent$.next(history));
  adapter
    .get('recentContentV3')
    .then((content) => recentRecentEntities$.next(content));

  const browserHistory$ = rx
    .combineLatest([browserHistoryContent$, loadingBrowserHistory$])
    .pipe(op.map(([content, isLoading]) => ({ content, isLoading })));

  const recentEntities$ = rx
    .combineLatest([recentRecentEntities$, loadingRecentEntities$])
    .pipe(op.map(([content, isLoading]) => ({ content, isLoading })));

  async function refreshBrowserHistory() {
    const currentTime = Date.now();
    const lastFetchTime =
      (await adapter.get('lastBrowserHistoryFetchTimestamp')) || 0;

    // In the case user opens multiple tabs / refreshes page, don't fetch again
    if (currentTime - lastFetchTime < SYNC_INTERVAL_MS) {
      loadingBrowserHistory$.next(false);
      return;
    }

    try {
      loadingBrowserHistory$.next(true);
      const recentBrowserHistory = await getRecentContent(callApiV2);
      if (recentBrowserHistory) {
        await adapter.set('browserHistoryV3', recentBrowserHistory);
        browserHistoryContent$.next(recentBrowserHistory);
      }
    } finally {
      loadingBrowserHistory$.next(false);
      adapter.set('lastBrowserHistoryFetchTimestamp', currentTime);
    }
  }

  async function refreshRecents() {
    const currentTime = Date.now();
    const lastFetchTime =
      (await adapter.get('lastRecentEntitiesFetchTimestamp')) || 0;

    // In the case user opens multiple tabs / refreshes page, don't fetch again
    if (currentTime - lastFetchTime < SYNC_INTERVAL_MS) {
      loadingRecentEntities$.next(false);
      return;
    }

    try {
      loadingRecentEntities$.next(true);
      let recents: RecentConnectorContent[] = [];

      const recentsResults = await browse(callApiV2);
      recents = [...recents, ...recentsResults];

      logger.info(
        `Fetched ${recents.length} recent connector entities. By connector:`,
        JSON.stringify(
          Object.fromEntries(
            _.chain(recents)
              .groupBy((c) => c.connectorInfo.connectorName)
              .entries()
              .map(([name, content]) => [name, content.length])
              .value(),
          ),
          null,
          2,
        ),
      );

      if (recents.length > 0) {
        const orderedRecents = _.orderBy(recents, (c) => c.visit_ms, 'desc');
        await adapter.set('recentContentV3', orderedRecents);
        recentRecentEntities$.next(orderedRecents);
      }
    } finally {
      loadingRecentEntities$.next(false);
      adapter.set('lastRecentEntitiesFetchTimestamp', currentTime);
    }
  }

  async function refreshRecentContent() {
    await Promise.all([refreshRecents(), refreshBrowserHistory()]);
  }

  function recentBrowserHistory(): Observable<
    RecentData<RecentBrowserContent>
  > {
    return browserHistory$;
  }

  function recentEntities(): Observable<RecentData<RecentConnectorContent>> {
    return recentEntities$;
  }

  async function latestLastViewedStackInfo(): Promise<LastViewedStackInfo> {
    return await adapter.get('lastViewedStackInfo');
  }

  async function reportViewedStack(namespaceId: string): Promise<void> {
    const currentInfo = await adapter.get('lastViewedStackInfo');
    currentInfo[namespaceId] = Date.now();
    await adapter.set('lastViewedStackInfo', currentInfo);
  }

  async function startSyncRecentEntities() {
    register(SYNC_RECENT_ENTITIES_JOB_NAME, SYNC_INTERVAL_MS, true, () =>
      refreshRecentContent(),
    );
  }

  async function cancelSyncRecentEntities() {
    unregister(SYNC_RECENT_ENTITIES_JOB_NAME);
  }

  function tearDown() {
    cancelSyncRecentEntities();
    adapter.clear();
  }

  logoutService.listenForLogout(ServiceId.RECENT_CONTENT).subscribe(() => {
    tearDown();
  });

  return services.provide(
    ServiceId.RECENT_CONTENT,
    {
      recentBrowserHistory,
      recentEntities,
      refreshRecentContent,
      latestLastViewedStackInfo,
      reportViewedStack,
      startSyncRecentEntities,
      cancelSyncRecentEntities,
    },
    [ServiceId.DBX_API],
  );
}
