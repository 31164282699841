import { Text } from '@dropbox/dig-components/typography';
import { TargetMissSpot } from '@dropbox/dig-illustrations';
import i18n from '@mirage/translations';
import styles from './ErrorBox.module.css';

export const ErrorBox = () => {
  return (
    <div className={styles.errorBoxContainer}>
      <TargetMissSpot altText="Error" width="160px" />
      <Text isBold size="small">
        {i18n.t('doc_summary_qna_error_something_went_wrong')}
      </Text>
    </div>
  );
};
