import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Skeleton } from '@dropbox/dig-components/skeleton';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Dismiss_DashEmptyStateComponent } from '@mirage/analytics/events/enums/dismiss_dash_empty_state_component';
import { PAP_Open_DashEmptyStateComponent } from '@mirage/analytics/events/types/open_dash_empty_state_component';
import { PAP_Shown_DashCard } from '@mirage/analytics/events/types/shown_dash_card';
import { PAP_Shown_DashEmptyStateComponent } from '@mirage/analytics/events/types/shown_dash_empty_state_component';
import { useConnectorsUI } from '@mirage/mosaics/SettingsPage/useConnectorsUI';
import { useOnboardingValues } from '@mirage/service-onboarding/hooks';
import {
  HomePageModule,
  MetricPageName,
} from '@mirage/service-operational-metrics/module/constants';
import { useRecordModuleLatency } from '@mirage/service-operational-metrics/module/module';
import {
  getFreshLoginTags,
  useFreshLoginTags,
} from '@mirage/service-operational-metrics/module/tags';
import { logPageLoadMilestoneOnce } from '@mirage/service-operational-metrics/page-load';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import { UIConnector } from '@mirage/shared/types';
import i18n from '@mirage/translations';
import { useEffect, useState } from 'react';
import { AppChip } from '../AppChip/AppChip';
import { useGetSuggestedApps } from '../hooks/useGetSuggestedApps';
import { useImpressionLogging } from '../WorkingSetCard/useImpressionLogging';
import styles from './ConnectAppsModule.module.css';

const actionSurfaceComponent = 'connector_upsell_module';
const featureLine = 'connector';

const MAX_SUGGESTED_APPS = 3;

export const ConnectAppsModule = () => {
  logPageLoadMilestoneOnce(`ConnectAppsModule start`);

  const { openConnectors } = useConnectorsUI();
  const [dismissed, setDismissed] = useState(true);
  const [hidden, setHidden] = useState(true);
  const { suggestedApps, refreshConnectionsList } =
    useGetSuggestedApps(MAX_SUGGESTED_APPS);
  const { reportPapEvent } = useMirageAnalyticsContext();

  const { getOnboardingValue, setOnboardingValue } = useOnboardingValues();

  const onHideButtonClick = () => {
    setDismissed(true);
    setOnboardingValue('dismissedConnectApps', true);
    reportPapEvent(
      PAP_Dismiss_DashEmptyStateComponent({
        actionSurfaceComponent,
        featureLine,
      }),
    );
  };

  useEffect(() => {
    const getDismissed = async () => {
      const isDismissed = await getOnboardingValue('dismissedConnectApps');
      setDismissed(isDismissed);
      if (!isDismissed) {
        reportPapEvent(
          PAP_Shown_DashEmptyStateComponent({
            actionSurfaceComponent,
            featureLine,
          }),
        );
      }
    };
    getDismissed();
  }, [getOnboardingValue, reportPapEvent]);

  useEffect(() => {
    suggestedApps.length === 0 ? setHidden(true) : setHidden(false);
  }, [suggestedApps]);

  const hide = dismissed || hidden;

  useImpressionLogging(
    !hide,
    'connector_upsell_module',
    PAP_Shown_DashCard({
      actionSurfaceComponent,
      featureLine,
      dashCardType: 'connector_upsell_module',
    }),
  );

  if (hide) return <></>;

  const handleSeeAllClick = () => {
    openConnectors();
    reportPapEvent(
      PAP_Open_DashEmptyStateComponent({
        actionSurfaceComponent,
        featureLine,
      }),
    );
  };

  return (
    <TwoColumnGridCard
      settingId="connect-apps"
      cardTypeProps={{
        cardType: CardHeaderType.CUSTOM,
        customHeader: <CardHeader onHideButtonClick={onHideButtonClick} />,
      }}
      className={styles.container}
      showDividerLine={false}
    >
      <Box className={styles.contentContainer}>
        {suggestedApps.length === 0 ? (
          <LoadingApps />
        ) : (
          <SuggestedApps
            suggestedApps={suggestedApps}
            refreshConnectionsList={refreshConnectionsList}
          />
        )}
        <Button
          className={styles.allAppsButton}
          variant="transparent"
          onClick={handleSeeAllClick}
        >
          {i18n.t('see_all_apps')}
        </Button>
      </Box>
    </TwoColumnGridCard>
  );
};

const SuggestedApps = ({
  suggestedApps,
  refreshConnectionsList,
}: {
  suggestedApps: UIConnector[];
  refreshConnectionsList: () => void;
}) => {
  const freshLoginTags = useFreshLoginTags();

  const { markModuleLoadComplete } = useRecordModuleLatency(
    MetricPageName.HOME_V2,
    HomePageModule.CONNECT_APPS,
    freshLoginTags || getFreshLoginTags,
  );

  useEffect(() => {
    markModuleLoadComplete();
  }, [markModuleLoadComplete]);

  return (
    <>
      {suggestedApps.map((connector) => (
        <AppChip
          className={styles.suggestedChip}
          connector={connector}
          key={connector.id_attrs?.type}
          actionSurface="start_page"
          actionSurfaceComponent="connector_upsell_module"
          onConnected={refreshConnectionsList}
        />
      ))}
    </>
  );
};

const LoadingApps = () => {
  return (
    <>
      {[...Array(MAX_SUGGESTED_APPS)].map((_, i) => (
        <Skeleton.Box
          key={i}
          shouldAnimate
          height={46}
          withBorderRadius="small"
          className={styles.suggestedSkeletonChip}
        />
      ))}
    </>
  );
};

const CardHeader = ({
  onHideButtonClick,
}: {
  onHideButtonClick: () => void;
}) => {
  return (
    <div className={styles.headerContainer}>
      <Text
        variant="label"
        size="medium"
        tagName="h2"
        className={styles.appCardTitle}
      >
        {i18n.t('connect_apps_message')}
      </Text>
      <div className={styles.iconContainer}>
        <IconButton
          variant="borderless"
          shape="circular"
          onClick={onHideButtonClick}
        >
          <UIIcon src={CloseLine} aria-label={i18n.t('hide_card')} />
        </IconButton>
      </div>
    </div>
  );
};
