import { Button } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import React from 'react';
import styles from './FeatureBanner.module.css';

interface Props {
  title: string;
  description: string;
  onDismiss: () => void;
  primaryAsset: React.ReactNode;
  mobileAsset?: React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
}

export const FeatureBanner: React.FC<Props> = ({
  title,
  description,
  primaryAsset,
  mobileAsset,
  onDismiss,
  actions,
  className,
}) => {
  const isMobile = useIsMobileSize();
  const asset = mobileAsset && isMobile ? mobileAsset : primaryAsset;

  return (
    <Box className={classnames(styles.wrapper, className)}>
      <Box className={styles.primaryImage}>{asset}</Box>
      <Box className={styles.content} display="flex" flexDirection="column">
        <Title size="small" weightVariant="emphasized" className={styles.title}>
          {title}
        </Title>
        <Text size="medium" tagName="p" className={styles.description}>
          {description}
        </Text>
        <Box className={styles.actionsContainer}>
          {actions}
          <Button
            variant="borderless"
            className={styles.dismissButton}
            onClick={onDismiss}
          >
            {i18n.t('dismiss')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
