import { FolderLine } from '@dropbox/dig-icons/assets';
import { getConnectorDisplayNameFromConnectorInfo } from '@mirage/shared/connectors';
import { Recommendation } from '@mirage/shared/search/recommendation';
import {
  PersonObject,
  SearchFilter,
} from '@mirage/shared/search/search-filters';
import {
  getPersonForMetadata,
  getTimeAgoStringFromTimestamp,
} from '../util/resultUtil';
import { MetadataItem } from './MetadataItem';
import { PersonMetadata } from './PersonMetadata';

import type { SearchResult } from '@mirage/service-dbx-api';

type DefaultMetadataProps = {
  result: SearchResult | Recommendation;
  onClickPerson?: (person: PersonObject) => void;
  activeFilters?: SearchFilter[];
};

export const DefaultMetadata = ({
  result,
  onClickPerson,
  activeFilters,
}: DefaultMetadataProps) => {
  const {
    connectorInfo,
    providerUpdateAtMs,
    parent,
    sender,
    creator,
    lastModifier,
  } = result;
  const appName = getConnectorDisplayNameFromConnectorInfo(connectorInfo);
  const timeAgoString = getTimeAgoStringFromTimestamp(providerUpdateAtMs);
  const parentDisplayName = parent?.displayName;

  const renderPersonMetadata = () => {
    const { person, i18nKey } = getPersonForMetadata({
      sender,
      creator,
      lastModifier,
      activeFilters,
    });

    if (!i18nKey || !person) {
      return null;
    }

    return (
      <MetadataItem
        title={
          <PersonMetadata
            i18nKey={i18nKey}
            person={person}
            onClickPerson={onClickPerson}
          />
        }
      />
    );
  };

  return (
    <>
      {appName && <MetadataItem title={appName} />}
      {parentDisplayName && (
        <MetadataItem title={parentDisplayName} iconSrc={FolderLine} />
      )}
      {timeAgoString && <MetadataItem title={timeAgoString} />}
      {renderPersonMetadata()}
    </>
  );
};
