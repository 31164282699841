import { stacks, users } from '@dropbox/api-v2-client';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Change_StackSharePermissions } from '@mirage/analytics/events/types/change_stack_share_permissions';
import { PAP_Open_DashShareModal } from '@mirage/analytics/events/types/open_dash_share_modal';
import { PAP_Send_DashShareModal } from '@mirage/analytics/events/types/send_dash_share_modal';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { useOperationalMetrics } from '@mirage/service-operational-metrics/hooks';
import { useIsPublicSharingAllowed } from '@mirage/service-stack-admin-settings/hooks';
import { previewStack } from '@mirage/service-stacks';
import {
  stackDerivePAPProps,
  stackGetShareId,
} from '@mirage/service-stacks/service/utils';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import { useAtom } from 'jotai';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PAP_Close_DashShareModal } from '../../analytics/events/types/close_dash_share_modal';
import { useCopyStackUrlToClipboard } from '../CopyStackUrl';
import { shadowStackAtom } from '../FullScreenStack/fullPageShadowAtoms';
import {
  addOrUpdateSharingMembers,
  createOrUpdateStackSharedLink,
  getSharingMembers,
  removeSharingMember,
} from './Api';
import { ShareModalInternal } from './ShareModalInternal';
import {
  SharingMemberKeyWithPermission,
  SharingStackPermission,
  StackAccessLevel,
  StackInfo,
  StackMember,
  StackPermission,
} from './Types';
import {
  coerceStackAccessLevel,
  coerceStackLinkPermission,
  coerceStackPermission,
  getSharingMemberIdentifier,
  getSubjectIdentifier,
  serverStackPermissionFromStackPermission,
  sortStackMembers,
  stackMemberFromSharingMember,
  stackMemberFromUser,
} from './Utils';

export function useShareModal({ stack }: { stack: stacks.Stack | null }) {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const userAccount = useDropboxAccount();
  const copyToClipboard = useCopyStackUrlToClipboard(stack);
  const [open, setOpen] = useState(false);

  // Only make the api call when the sharing modal is opened
  const isPublicSharingAllowed = useIsPublicSharingAllowed(open);

  const openShareModal = useCallback(() => {
    if (stack) {
      setOpen(true);
      reportPapEvent(
        PAP_Open_DashShareModal({
          ...stackDerivePAPProps(stack),
          actionSurfaceComponent: 'stacks',
          featureLine: 'stacks',
          isPublicSharingAllowed: isPublicSharingAllowed,
        }),
      );
    }
  }, [stack, reportPapEvent, isPublicSharingAllowed]);

  const shareModalJsx =
    userAccount && open && stack ? (
      <ShareModal
        userAccount={userAccount}
        stack={stack}
        onClose={() => setOpen(false)}
        copyToClipboard={copyToClipboard}
        isOpen
        isPublicSharingAllowed={!!isPublicSharingAllowed}
      />
    ) : null;

  return { openShareModal, shareModalJsx };
}

const convertStackPermissionToPapPermission = (permission: StackPermission) => {
  switch (permission) {
    case 'owner':
      return 'owner';
    case 'write':
      return 'editor';
    default:
      return 'viewer';
  }
};

const ShareModal = ({
  stack: remoteStack,
  isOpen,
  onClose,
  copyToClipboard,
  userAccount,
  isPublicSharingAllowed,
}: {
  stack: stacks.Stack;
  isOpen: boolean;
  onClose: () => void;
  copyToClipboard: () => void;
  userAccount: users.FullAccount;
  isPublicSharingAllowed: boolean;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  // Maintain local modifications in a local copy of the stack so that updates
  // will reflect immediately in the UI. Server-side updates might interfere
  // with the local stack, so we won't propagate changes from server back to
  // the local state. Set the shadow stack required by
  // FullScreenStack/Header.tsx and FullScreenStackV2.tsx.
  const [stack, setStack] = useAtom(shadowStackAtom);
  const shareId = useMemo(
    () => (stack ? nonNil(stackGetShareId(stack), 'shareId') : ''),
    [stack],
  );
  const reloadStack = useCallback(() => {
    setTimeout(() => previewStack(shareId, { refresh: true }), 500);
  }, [shareId]);

  // Only copy the server-side stack to the local stack on modal open and
  // if the stack has changed.
  useEffect(() => {
    if (
      isOpen &&
      (stack?.namespace_id !== remoteStack.namespace_id ||
        (stack?.max_hlc_micros ?? 0) < (remoteStack.max_hlc_micros ?? 0))
    ) {
      setStack(remoteStack);
    }
  }, [
    isOpen,
    remoteStack,
    stack?.max_hlc_micros,
    setStack,
    stack?.namespace_id,
  ]);

  // Maintain mapping of contact display names to allow instant UI update.
  const contactDisplayNameById = useRef<{ [id: string]: string }>({});

  const currentMember = userAccount ? stackMemberFromUser(userAccount) : null;

  const [linkPermission, setLinkPermission] =
    useState<SharingStackPermission | null>(null);
  const [accessLevel, setAccessLevel] = useState<StackAccessLevel | null>(null);

  const { stats: addMembersStats } = useOperationalMetrics('addSharingMembers');

  const groupSharingEnabled =
    useFeatureFlagValue('dash_2024_06_19_share_stacks_with_groups') === 'ON';
  const handleGetSharingMembers = useCallback(
    async (searchQuery: string) => {
      const members = await getSharingMembers(searchQuery, groupSharingEnabled);
      for (const member of members) {
        contactDisplayNameById.current[getSharingMemberIdentifier(member)] =
          member.displayName;
      }
      return members;
    },
    [groupSharingEnabled],
  );

  const stackInfo: StackInfo | null = useMemo(() => {
    if (!stack || !currentMember || !accessLevel || !linkPermission) {
      return null;
    }
    const members = sortStackMembers(
      stack.sharing_data?.members
        ?.filter(
          (member) =>
            member?.subject?.['.tag'] === 'user' ||
            (groupSharingEnabled && member?.subject?.['.tag'] === 'group'),
        )
        ?.map((member) => stackMemberFromSharingMember(member)) ?? [
        currentMember,
      ],
    );

    return {
      name: stack.stack_data?.name ?? '',
      namespaceId: stack.namespace_id ?? '',
      teamName: stack.sharing_data?.shared_link?.team_name,
      latestHash: stack.latest_stack_cache_id ?? '',
      permission: coerceStackPermission(stack?.permission),
      linkPermission,
      accessLevel,
      members,
    };
  }, [currentMember, accessLevel, linkPermission, stack, groupSharingEnabled]);

  const addMembersApiCall = useCallback(
    async (
      sendNotifications: boolean,
      notificationMessage: string,
      members: SharingMemberKeyWithPermission[],
      onComplete: () => void,
      onFailure: () => void,
    ) => {
      if (!stack || !stackInfo) {
        return;
      }

      try {
        const startMs = performance.now();
        const sharedWithGroup = members.some(
          (member) => member.memberKey['.tag'] === 'group',
        );
        const sharedWithExternal = members.some(
          (member) =>
            member.memberKey['.tag'] === 'user' &&
            !('displayName' in member.memberKey),
        );
        const result = await addOrUpdateSharingMembers(
          stackInfo.namespaceId,
          members,
          sendNotifications,
          notificationMessage,
        );
        reloadStack();
        const durationMs = performance.now() - startMs;
        addMembersStats('api_only', durationMs, { numMembers: members.length });

        if (Number(result?.added?.length) > 0) {
          reportPapEvent(
            PAP_Send_DashShareModal({
              ...stackDerivePAPProps(stack),
              stackEmailNotificationsOn: sendNotifications,
              stackPermissions: convertStackPermissionToPapPermission(
                stackInfo.permission,
              ),
              featureLine: 'stacks',
              isPublicSharingAllowed: isPublicSharingAllowed,
              stackSendShareType: 'entered_by_user',
              sharedWithGroup,
              sharedWithExternal,
            }),
          );
        }

        const hasChanged =
          Number(result?.added?.length) ||
          Number(result?.denied?.length) ||
          Number(result?.updated?.length);

        if (hasChanged) {
          reportPapEvent(
            PAP_Change_StackSharePermissions({
              ...stackDerivePAPProps(stack),
              stackPermissions: convertStackPermissionToPapPermission(
                stackInfo.permission,
              ),
              stackAccessLevel: stackInfo.accessLevel,
              featureLine: 'stacks',
              isPublicSharingAllowed: isPublicSharingAllowed,
            }),
          );
        }
      } catch {
        onFailure();
      } finally {
        onComplete();
      }
    },
    [
      addMembersStats,
      isPublicSharingAllowed,
      reportPapEvent,
      stack,
      stackInfo,
      reloadStack,
    ],
  );

  const handleShareStackWithMembers = useCallback(
    async (
      sendNotifications: boolean,
      notificationMessage: string,
      members: SharingMemberKeyWithPermission[],
      onFailure: () => void,
    ) => {
      if (!stack || !stackInfo) {
        return;
      }

      const startMs = performance.now();

      // Update member permissions in local stack.
      const newStack = cloneDeep(stack);
      const permissionById = Object.fromEntries(
        members.map((m) => [
          getSharingMemberIdentifier(m.memberKey),
          m.permission,
        ]),
      );
      const newSharingData = nonNil(newStack.sharing_data, 'newSharingData');
      const newMembers =
        newSharingData.members || (newSharingData.members = []);
      const existingIds = new Set<string>();

      for (const member of newMembers) {
        const id = getSubjectIdentifier(member.subject);
        if (!id) continue;
        existingIds.add(id);

        const permission = permissionById[id];
        if (permission) {
          member.permission =
            serverStackPermissionFromStackPermission(permission);
        }
      }

      // Add new sharing members.
      for (const { memberKey, permission } of members) {
        const id = getSharingMemberIdentifier(memberKey);
        if (existingIds.has(id)) continue;

        // Ok to be undefined here. Fetch on best effort basis.
        const displayName = contactDisplayNameById.current[id];

        // Translate our SharingMemberKeys to stacks.StackSharingSubject objects
        newMembers.push({
          subject: { ...memberKey, display_name: displayName },
          permission: serverStackPermissionFromStackPermission(permission),
        });
      }

      newStack.max_hlc_micros = Date.now() * 1000;
      setStack(newStack);

      // Having the data updated in the UI is the actual user-felt latency.
      const durationMs = performance.now() - startMs;
      addMembersStats('ui_updated', durationMs, { numMembers: members.length });

      const onComplete = () => {
        const durationMs = performance.now() - startMs;
        addMembersStats('e2e', durationMs, { numMembers: members.length });
      };

      // Add api call to async queue.
      void addMembersApiCall(
        sendNotifications,
        notificationMessage,
        members,
        onComplete,
        onFailure,
      );
    },
    [stack, stackInfo, setStack, addMembersStats, addMembersApiCall],
  );

  const handleRevokeStackMember = useCallback(
    async (member: StackMember) => {
      if (!stack || !stackInfo) return undefined;

      const memberIdentifier = getSharingMemberIdentifier(member);
      // Remove member in local stack.
      const newStack = {
        ...stack,
        sharing_data: {
          ...stack.sharing_data,
          members: (stack.sharing_data?.members ?? []).filter(
            (m) => getSubjectIdentifier(m.subject) !== memberIdentifier,
          ),
        },
      };

      newStack.max_hlc_micros = Date.now() * 1000;
      setStack(newStack);

      const result = await removeSharingMember(stackInfo.namespaceId, member);

      if (result) {
        reloadStack();
      } else {
        // Undo failed update.
        setStack(stack);
      }

      return result;
    },
    [setStack, stack, stackInfo, reloadStack],
  );

  const handleUpdateStackSharedLink = useCallback(
    async (
      accessLevel: StackAccessLevel,
      permission: SharingStackPermission,
    ) => {
      if (!stack || !stackInfo) {
        return false;
      }

      try {
        const result = await createOrUpdateStackSharedLink(
          stackInfo.namespaceId,
          permission,
          accessLevel,
        );

        if (result) {
          reloadStack();

          reportPapEvent(
            PAP_Change_StackSharePermissions({
              ...stackDerivePAPProps(stack),
              stackPermissions: convertStackPermissionToPapPermission(
                stackInfo.permission,
              ),
              // Use the updated access level instead of the old one
              stackAccessLevel: accessLevel,
              featureLine: 'stacks',
              isPublicSharingAllowed: isPublicSharingAllowed,
            }),
          );

          setAccessLevel(accessLevel);
          setLinkPermission(permission);
          return true;
        }
      } catch {
        // ignore
      }
      return false;
    },
    [stackInfo, reportPapEvent, stack, isPublicSharingAllowed, reloadStack],
  );

  useEffect(() => {
    setAccessLevel(
      coerceStackAccessLevel(stack?.sharing_data?.shared_link?.access_level),
    );
    setLinkPermission(
      coerceStackLinkPermission(stack?.sharing_data?.shared_link?.permission),
    );
  }, [stack]);

  if (!currentMember || !stackInfo) {
    return null;
  }

  return (
    <ShareModalInternal
      isOpen={isOpen}
      onCancel={() => {
        if (stack) {
          reportPapEvent(
            PAP_Close_DashShareModal({
              ...stackDerivePAPProps(stack),
              featureLine: 'stacks',
            }),
          );
        }
        onClose();
      }}
      currentMember={currentMember}
      stackInfo={stackInfo}
      getSharingMembers={handleGetSharingMembers}
      handleCopyStackShareLink={copyToClipboard}
      handleShareStackWithMembers={handleShareStackWithMembers}
      handleRevokeStackMember={handleRevokeStackMember}
      handleUpdateStackSharedLink={handleUpdateStackSharedLink}
    />
  );
};
