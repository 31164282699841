import styled from '@emotion/styled';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import React from 'react';

export const LoadingResult: React.FC = () => {
  const isDarkMode = useTheme();
  return (
    <ResultContainer isDarkMode={isDarkMode}>
      <HeaderContainer>
        <IconShimmer />
        <VerticalTextContainer>
          <TextShimmer height="16px" width="300px" />
          <TextShimmer height="12px" width="88px" />
        </VerticalTextContainer>
      </HeaderContainer>
      <VerticalTextContainer>
        <TextShimmer height="12px" width="616px" />
        <TextShimmer height="12px" width="543px" />
      </VerticalTextContainer>
    </ResultContainer>
  );
};

export const LoadingTypeaheadResult: React.FC = () => {
  const isDarkMode = useTheme();
  return (
    <TypeaheadResultContainer isDarkMode={isDarkMode}>
      <IconShimmer size="24px" />
      <TextShimmer height="14px" width="100%" />
    </TypeaheadResultContainer>
  );
};

export const LoadingResultList = ({ count = 10 }: { count: number }) => {
  const loadingList = React.useMemo(
    () =>
      Array.from({ length: count }, (v, i) => (
        <LoadingResult key={`loading-result-${i}`} /> // Make sure to provide a unique key prop
      )),
    [count],
  );

  return <>{loadingList}</>;
};

const ShimmerListItem = styled.li<{ isDarkMode: boolean }>`
  position: relative;
  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent,
      ${(props) =>
        props.isDarkMode
          ? 'rgba(36, 33, 33, 0.2)'
          : 'rgba(255, 255, 255, 0.3)'},
      transparent
    );
    animation: shimmer 1.5s infinite;
  }
`;

const ResultContainer = styled(ShimmerListItem)`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 98px;
  padding: 16px 24px;
  gap: 10px;
  border-radius: 8px;
`;

const TypeaheadResultContainer = styled(ShimmerListItem)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

const VerticalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const IconShimmer = styled.div<{ size?: string }>`
  flex-shrink: 0;
  width: ${({ size }) => size ?? '40px'};
  height: ${({ size }) => size ?? '40px'};
  border-radius: 50%;
  background-color: var(--dig-color__opacity__surface);
`;

const TextShimmer = styled.div<{ height: string; width: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: ${({ height }) => height};
  max-height: ${({ height }) => height};
  background-color: var(--dig-color__opacity__surface);
  border-radius: 4px;
`;

LoadingResult;
