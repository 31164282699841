import { Text } from '@dropbox/dig-components/typography';
import { ConversationMessage, Role } from '@mirage/conversations/types';
import { Link } from '@mirage/link/Link';
import { useConnectorsUI } from '@mirage/mosaics/SettingsPage/useConnectorsUI';
import i18n from '@mirage/translations';

import type { dash } from '@dropbox/api-v2-client';
import type { SearchResult } from '@mirage/service-dbx-api';

export const buildSystemResponse = ({
  content = '',
  sources,
  isSummarize = false,
  isError = false,
}: {
  content?: string;
  sources?: SearchResult[];
  isSummarize?: boolean;
  isError?: boolean;
}) => {
  let responseRole = Role.System;
  let responseContent: string | JSX.Element = content;

  if (!content) {
    responseRole = Role.Empty;
    responseContent = isSummarize ? (
      i18n.t('summarize_error_message')
    ) : (
      <NoAnswerMessage hasSources={!!sources?.length} />
    );
  }

  if (isError) {
    responseRole = Role.Error;
    responseContent = isSummarize
      ? i18n.t('summarize_error_message')
      : i18n.t('answers_error_message');
  }

  return [
    {
      role: responseRole,
      content: responseContent,
      sources,
    },
  ];
};

// Construct attachment array in the format API expects
export const buildAttachmentsForAPI = (id?: string): dash.Attachment[] => {
  if (!id) {
    return [];
  }

  return [
    {
      attachment_type: {
        '.tag': 'indexed_document',
        id,
      },
    },
  ];
};

export const NoAnswerMessage = ({ hasSources }: { hasSources: boolean }) => {
  const { openConnectors } = useConnectorsUI();

  return (
    <Text>
      {i18n.t('no_answer_message.part1')}{' '}
      <Link onClick={() => openConnectors()} href="#">
        {i18n.t('no_answer_message.part2')}
      </Link>{' '}
      {i18n.t('no_answer_message.part3')}
      {hasSources && i18n.t('no_answer_message.part4')}
    </Text>
  );
};

export const getAnswerStringForLogging = (
  conversation: ConversationMessage[],
): string => {
  const systemResponse = conversation.find((c) => c.role === Role.System);
  // Since content can technically be JSX element, add a check here for string
  return typeof systemResponse?.content === 'string'
    ? systemResponse?.content
    : '';
};

export const normalizeQuestion = (question: string): string => {
  return question.replace(/^\s+|\s+$/g, '');
};
