import type { DashSurface } from '@mirage/analytics/events/enums/dash_surface';

// As per Dropbox's security policy, do NOT add emails as an attribute
export type SurveyAttributes = {
  dashSurface?: DashSurface;
  isInternal?: boolean;
};

export const enum SurveyEvent {
  AppOpened = 'dash_open_app',
  SearchComplete = 'dash_search_complete',
  DebugTriggered = 'dash_debug_trigger',
  OpenStartPage = 'dash_open_start_page',
}
