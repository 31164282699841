import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Shown_DashSurvey } from '@mirage/analytics/events/types/shown_dash_survey';
import { Sprig } from '@mirage/feedback/sprig';
import { SurveyEvent } from '@mirage/feedback/types';
import { useCallback, useEffect, useState } from 'react';

import type { DashSurface } from '@mirage/analytics/events/enums/dash_surface';

export type SprigSurveyContext = {
  publishEvent: (event: SurveyEvent) => void;
};

type SprigSurveyProps = {
  surface: DashSurface;
  environmentId: string;
  email?: string;
  disabled?: boolean;
  publicAccountId?: string;
};

export default function useSprigSurvey({
  surface,
  environmentId,
  email,
  disabled,
  publicAccountId,
}: SprigSurveyProps): SprigSurveyContext {
  const [sprig, setSprig] = useState<null | Sprig>(null);
  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    if (disabled) {
      return;
    }

    const sprigAPI = new Sprig(environmentId, publicAccountId);
    // Overload any sprig listeners if desired
    //   Example: sprigAPI.onSurveyPresented = onSurveyPresented;
    sprigAPI.onSurveyPresented = () => {
      reportPapEvent(
        PAP_Shown_DashSurvey({
          dashSurface: surface,
          externalUserId: publicAccountId,
        }),
      );
    };
    setSprig(sprigAPI);
  }, [environmentId, disabled, publicAccountId, surface, reportPapEvent]);

  const publishEvent = useCallback(
    (event: SurveyEvent) => {
      if (sprig) {
        sprig.publishEvent(event, {
          isInternal: email ? email.endsWith('@dropbox.com') : false,
          dashSurface: surface,
        });
      }
    },
    [sprig, surface, email],
  );

  return { publishEvent };
}
