import { fetchTypeaheadRecommendations } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { namespace } from '@mirage/service-operational-metrics';
import {
  CacheKey,
  TypeaheadCache,
} from '@mirage/service-typeahead-search/service/typeahead-cache';
import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import { register, unregister } from '@mirage/shared/util/jobs';

const logger = tagged('typeahead/sync-recommendations');

const SYNC_RECOMMENDATIONS_JOB_NAME = 'recommendations-sync';
const SYNC_INTERVAL_MS = ONE_MINUTE_IN_MILLIS * 5;

const DEBUG = false;
DEBUG satisfies false;

export function start(cache: TypeaheadCache) {
  register(SYNC_RECOMMENDATIONS_JOB_NAME, SYNC_INTERVAL_MS, true, () =>
    sync(cache),
  );
}

export function cancel() {
  unregister(SYNC_RECOMMENDATIONS_JOB_NAME);
}

// sync, clear, and insert logic
const metrics = namespace('typeahead');
async function sync(cache: TypeaheadCache) {
  return fetchTypeaheadRecommendations()
    .then((recommendations) => {
      metrics.stats('sync/count', recommendations.length);
      metrics.counter('sync/status', 1, {
        status: 'success',
      });

      if (DEBUG) {
        logger.debug(
          `fetchTypeaheadRecommendations`,
          recommendations.slice(0, 3),
        );
      }

      // don't clear existing recommendations if we don't get fresh recommendations
      if (recommendations.length === 0) return;

      return cache
        .clear(CacheKey.Recommendations)
        .then(() => cache.cacheRecommendations(recommendations));
    })
    .catch((e) => {
      metrics.counter('sync/status', 1, {
        status: 'error',
      });
      return Promise.reject(e);
    });
}
