import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { DashQueryType } from '@mirage/analytics/events/enums/dash_query_type';
import { PAP_Click_DashSubmitQuestion } from '@mirage/analytics/events/types/click_dash_submit_question';
import { PAP_Shown_DashSearchResult } from '@mirage/analytics/events/types/shown_dash_search_result';
import { ConversationMessage, Role } from '@mirage/conversations/types';
import {
  buildAttachmentsForAPI,
  buildSystemResponse,
  getAnswerStringForLogging,
} from '@mirage/mosaics/AnswersConversation/utils';
import { DynamicPanelPapProperties } from '@mirage/mosaics/DynamicPanel/atoms';
import * as dbxApiService from '@mirage/service-dbx-api';
import { AnswerResponse } from '@mirage/service-dbx-api/service/answers';
import useSearchQueryId from '@mirage/shared/hooks/useSearchQueryId';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import type { SearchResult } from '@mirage/service-dbx-api';

type useAnswersConversationProps = {
  attachment?: SearchResult;
  papData?: DynamicPanelPapProperties;
  actionSurfaceComponent?: ActionSurfaceComponent;
  initialQuery?: string;
  initialAnswer?: AnswerResponse;
  enableMultiTurn: boolean;
};

export const useAnswersConversation = ({
  attachment,
  papData,
  actionSurfaceComponent,
  initialAnswer,
  initialQuery,
  enableMultiTurn = false,
}: useAnswersConversationProps) => {
  const [conversation, setConversation] = useState<ConversationMessage[]>([]);

  const {
    reportPapEvent,
    explicitAnswersSessionManager,
    searchSessionManager,
    explicitAnswerDwellManager,
  } = useMirageAnalyticsContext();
  const { searchQueryUuid } = useSearchQueryId();
  // Track the num of questions asked in a given answers session
  const numQuestionsAsked = useRef(0);
  const numAnswersProvided = useRef(0);
  const [requestId, setRequestId] = useState('');
  const [conversationId, setConversationId] = useState('');
  const [answerLoggingId, setAnswerLoggingId] = useState('');
  const [submittedQuery, setSubmittedQuery] = useState('');

  useEffect(() => {
    if (initialAnswer && initialQuery) {
      // Build the initial conversation
      const userMessage = { role: Role.User, content: initialQuery };
      const systemMessage = buildSystemResponse({
        content: initialAnswer.answer,
        sources: initialAnswer.sources,
        isSummarize: false,
      });
      setConversation([userMessage, ...systemMessage]);
    }
  }, [initialAnswer, initialQuery]);

  const fetchAnswersConversation = async ({
    query,
    queryType = 'user_entry',
  }: {
    query: string;
    queryType?: DashQueryType;
  }): Promise<undefined> => {
    // For multi turn, append user message
    if (enableMultiTurn && conversationId) {
      setConversation((prev) => [...prev, { role: Role.User, content: query }]);
    } else {
      // Reset
      setConversation([{ role: Role.User, content: query }]);
    }

    numQuestionsAsked.current += 1;
    explicitAnswersSessionManager.updateProperties({
      numQuestionsAsked: numQuestionsAsked.current,
      actionSurfaceComponent,
    });
    const answerId = uuid();
    setAnswerLoggingId(answerId);
    setSubmittedQuery(query);

    try {
      const attachments = buildAttachmentsForAPI(attachment?.uuid);
      const {
        messages,
        requestId,
        conversationId: _conversationId,
      } = await dbxApiService.fetchConversation(
        query,
        attachments,
        conversationId,
      );
      setRequestId(requestId);

      if (enableMultiTurn) {
        setConversationId(_conversationId);
      }

      // Get last message in array - probably not needed, just in case
      const answerMessage = messages[messages.length - 1];
      const response = buildSystemResponse({
        content: answerMessage.answer,
        sources: answerMessage.sources,
        isSummarize: attachments.length > 0,
      });

      reportPapEvent(
        PAP_Click_DashSubmitQuestion({
          queryString: query,
          numQuestionsAsked: numQuestionsAsked.current,
          answerId: answerId,
          entryPoint: papData?.entryPoint,
          dashAnswersSessionId:
            explicitAnswersSessionManager.getSessionIdOrUndefined(),
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
          dashQueryType: queryType,
          searchRequestId: papData?.searchRequestId,
          resultPosition: papData?.resultPosition,
          resultUuid: papData?.resultUuid,
          actionSurfaceComponent,
          featureLine: 'answers',
          dashAnswerRequestId: requestId,
          searchQueryUuid,
        }),
      );

      reportPapEvent(
        PAP_Shown_DashSearchResult({
          queryString: query,
          numQuestionsAsked: numQuestionsAsked.current,
          answerString: getAnswerStringForLogging(response),
          numAnswerSources: answerMessage.sources.length,
          answerId: answerId,
          entryPoint: papData?.entryPoint,
          dashAnswersSessionId:
            explicitAnswersSessionManager.getSessionIdOrUndefined(),
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
          dashAnswerRequestId: requestId,
          actionSurfaceComponent,
          featureLine: 'answers',
        }),
      );

      numAnswersProvided.current += 1;
      explicitAnswersSessionManager.updateProperties({
        numAnswersProvided: numAnswersProvided.current,
        dashAnswerRequestId: requestId,
        actionSurfaceComponent,
      });
      explicitAnswerDwellManager.createSession();
      explicitAnswerDwellManager.updateProperties({
        queryString: query,
        answerString: getAnswerStringForLogging(response),
        numQuestionsAsked: numQuestionsAsked.current,
        numAnswerSources: answerMessage.sources.length,
        answerId: answerId,
        dashAnswersSessionId:
          explicitAnswersSessionManager.getSessionIdOrUndefined(),
        entryPoint: papData?.entryPoint,
        dashAnswerRequestId: requestId,
        actionSurfaceComponent,
      });
      setConversation((prev) => [...prev, ...response]);
    } catch {
      const response = buildSystemResponse({
        isError: true,
      });
      setConversation((prev) => [...prev, ...response]);
    }
    return;
  };

  const resetConversation = () => {
    setConversation([]);
    setConversationId('');
    setRequestId('');
  };

  return {
    fetchAnswersConversation,
    conversation,
    answerLoggingId,
    submittedQuery,
    numQuestionsAsked: numQuestionsAsked.current,
    dashAnswerRequestId: requestId,
    resetConversation,
  };
};
