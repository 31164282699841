import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { SEARCH_ATTEMPT_END_REASONS } from '@mirage/analytics/session/session-utils';
import { useMarkChecklistItemComplete } from '@mirage/growth/onboarding/getting-started-checklist/useOnboardingChecklist';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { tagged } from '@mirage/service-logging';
import { namespace } from '@mirage/service-operational-metrics';
import { measureLatency } from '@mirage/service-operational-metrics/measure-latency';
import { performSearch } from '@mirage/service-search';
import { searchConfig } from '@mirage/service-search/service';
import debounce from 'lodash.debounce';
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { slottedSearchRanking } from './slotted-search';

import type {
  ScoredResult,
  SearchOptions,
} from '@mirage/service-search/service';
import type { SearchFilter } from '@mirage/shared/search/search-filters';
import type { Reducer } from 'react';

export const enum ResultDisplayState {
  INITIAL, // Before any results are shown
  FIRST_BATCH_SHOWN, // After the first batch (~1-7) is shown
  SECOND_BATCH_SHOWN, // After the second batch (~7-10) is shown
  ALL_RESULTS_SHOWN, // All results are shown
}

type SearchState = {
  query: string;
  filters: SearchFilter[];
  loading: boolean;
  error: Error | null;
  // results that have been returned from the server, but not yet displayed in the UI
  resultsQueue: ScoredResult[];
  // Results that should be rendered in the UI
  resultsToDisplay: ScoredResult[];
  resultDisplayState: ResultDisplayState; // Dictates how many batches of results we've shown to user
  localMixinPosition: number;
};

enum SearchActionType {
  START_SEARCH = 'START_SEARCH',
  RECEIVE_RESULTS = 'RECEIVE_RESULTS',
  SHOW_SECOND_BATCH = 'SHOW_SECOND_BATCH',
  FINISH_SEARCH = 'FINISH_SEARCH',
  SET_ERROR = 'SET_ERROR',
  RESET_QUERY = 'RESET_QUERY',
}

const METRIC_NAMESPACE = 'search-ui';
const metrics = namespace(METRIC_NAMESPACE);
const logger = tagged('useSearch');
const DEBUG = false;
DEBUG satisfies false;

type Action =
  | {
      type: SearchActionType.START_SEARCH;
      payload: { searchQuery: string; filters: SearchFilter[] };
    }
  | {
      type: SearchActionType.RECEIVE_RESULTS;
      payload: {
        results: ScoredResult[];
        localMixinPosition: number;
        useSlottedSearchRanking: boolean;
      };
    }
  | {
      type: SearchActionType.SHOW_SECOND_BATCH;
      payload: {
        useSlottedSearchRanking: boolean;
      };
    }
  | {
      type: SearchActionType.FINISH_SEARCH;
      payload: {
        useSlottedSearchRanking: boolean;
      };
    }
  | { type: SearchActionType.SET_ERROR; payload: Error | null }
  | { type: SearchActionType.RESET_QUERY };

export type SearchContext = {
  query: string;
  filters: SearchFilter[];
  results: Array<ScoredResult>;
  loading: boolean;
  error: Error | null;
  resetQuery: () => void;
  handleSearch: (query: string, filters?: SearchFilter[]) => Promise<void>;
  resultDisplayState: ResultDisplayState;
};

const initialState: SearchState = {
  query: '',
  filters: [],
  loading: false,
  error: null,
  resultsQueue: [],
  resultsToDisplay: [],
  resultDisplayState: ResultDisplayState.INITIAL,
  localMixinPosition: 0,
};

const searchReducer: Reducer<SearchState, Action> = (state, action) => {
  logger.info('Handling reducer action', action.type);
  switch (action.type) {
    case SearchActionType.START_SEARCH: {
      const { searchQuery, filters } = action.payload;
      return {
        ...initialState,
        query: searchQuery,
        filters,
        loading: true,
      };
    }
    case SearchActionType.RECEIVE_RESULTS: {
      // eslint-disable-next-line prefer-const
      let { resultDisplayState, resultsQueue, resultsToDisplay } = state;
      const { results, localMixinPosition, useSlottedSearchRanking } =
        action.payload;

      // dedupe incoming results against known results in queue and previously
      // rendered to the user
      const incoming = dedupeAgainst(
        [...resultsQueue, ...resultsToDisplay],
        // XXX: these ideally are not duplicated, but filter here just in case
        // the upstream returns duplicate results to us
        dedupe(results),
      );

      // ...append and sort incoming results to our render queue
      resultsQueue = orderByScore([...resultsQueue, ...incoming]);

      const newResultsToDisplay = [...resultsToDisplay];
      const newResultsQueue = [...resultsQueue];

      // We haven't shown any results yet -- let's show some pinned results immediately
      if (resultDisplayState === ResultDisplayState.INITIAL) {
        return {
          ...state,
          resultsToDisplay: useSlottedSearchRanking
            ? slottedSearchRanking(resultsQueue.slice(0, localMixinPosition))
            : resultsQueue.slice(0, localMixinPosition),
          resultsQueue: resultsQueue.slice(localMixinPosition),
          resultDisplayState: ResultDisplayState.FIRST_BATCH_SHOWN,
          localMixinPosition,
        };
      }

      return {
        ...state,
        resultsQueue: newResultsQueue,
        resultsToDisplay: useSlottedSearchRanking
          ? slottedSearchRanking(newResultsToDisplay)
          : newResultsToDisplay,
        localMixinPosition,
      };
    }
    case SearchActionType.SHOW_SECOND_BATCH: {
      const {
        resultDisplayState,
        localMixinPosition,
        resultsToDisplay,
        resultsQueue,
      } = state;
      const { useSlottedSearchRanking } = action.payload;

      if (resultDisplayState === ResultDisplayState.FIRST_BATCH_SHOWN) {
        // We have shown the initial batch of pinned results but it hasn't filled up the page.
        // Now let's show a bucket of upstream results up to P10, if needed.
        const remainingCountAboveFold = Math.max(
          searchConfig.minPinnedResults - localMixinPosition,
          0,
        );
        return {
          ...state,
          resultsToDisplay: useSlottedSearchRanking
            ? slottedSearchRanking([
                ...resultsToDisplay,
                ...resultsQueue.slice(0, remainingCountAboveFold),
              ])
            : [
                ...resultsToDisplay,
                ...resultsQueue.slice(0, remainingCountAboveFold),
              ],
          resultsQueue: resultsQueue.slice(remainingCountAboveFold),
          resultDisplayState: ResultDisplayState.SECOND_BATCH_SHOWN,
        };
      }

      return { ...state };
    }
    case SearchActionType.FINISH_SEARCH: {
      const { useSlottedSearchRanking } = action.payload;
      return {
        ...state,
        loading: false,
        resultsToDisplay: useSlottedSearchRanking
          ? slottedSearchRanking([
              ...state.resultsToDisplay,
              ...state.resultsQueue,
            ])
          : [...state.resultsToDisplay, ...state.resultsQueue],
        resultsQueue: [],
        resultDisplayState: ResultDisplayState.ALL_RESULTS_SHOWN,
      };
    }
    case SearchActionType.SET_ERROR:
      return { ...state, error: action.payload, loading: false };
    case SearchActionType.RESET_QUERY: {
      return {
        ...state,
        query: '',
      };
    }
    default:
      action satisfies never;
      return state;
  }
};

let performSearchSubscription: Subscription;

export default function useSearch(options: SearchOptions): SearchContext {
  const [state, dispatch] = useReducer(searchReducer, initialState);
  const { resultDisplayState } = state;
  const { searchAttemptSessionManager } = useMirageAnalyticsContext();
  const markGettingStartedTaskComplete = useMarkChecklistItemComplete();
  const { getConnections } = useConnectors();

  const [activeSearch, setActiveSearch] = useState(false);
  const [searchStartTimestamp, setSearchStartTimestamp] = useState(-1);
  const [isFirstBatch, setIsFirstBatch] = useState(true);

  useEffect(() => {
    // when we initially mount we need to wait until a search has been issued
    if (!activeSearch) return;
    // ignore emissions past first batch flush
    if (!isFirstBatch) return;
    // ignore tracking metrics if we have an empty state and we are actively
    // waiting for results to load
    if (!state.resultsToDisplay.length && state.loading) return;

    const ttfr = performance.now() - searchStartTimestamp;
    logger.info('Reporting TTFR of', ttfr);
    metrics.stats('ttfr', ttfr);

    setIsFirstBatch(false);
  }, [isFirstBatch, state, activeSearch, searchStartTimestamp]);

  // we allow a short time to collect results from upstream after the server results are shown
  // this block manages that timing
  const collectionTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const stopCollectionTimer = () => {
      if (collectionTimeoutRef.current) {
        clearTimeout(collectionTimeoutRef.current);
      }
    };

    // If first batch has already been shown, let's try and show 2nd batch
    // after a delay to ensure some more upstream results have trickled in
    if (resultDisplayState === ResultDisplayState.FIRST_BATCH_SHOWN) {
      stopCollectionTimer();
      collectionTimeoutRef.current = setTimeout(() => {
        dispatch({
          type: SearchActionType.SHOW_SECOND_BATCH,
          payload: { useSlottedSearchRanking: options.useSlottedSearchRanking },
        });
      }, searchConfig.secondBatchResultDelayMs);
    } else {
      stopCollectionTimer();
    }

    return () => stopCollectionTimer();
  }, [options.useSlottedSearchRanking, resultDisplayState]);

  const resetQuery = useCallback(() => {
    dispatch({
      type: SearchActionType.RESET_QUERY,
    });
  }, []);

  const handleSearch = useMemo(
    () =>
      debounce(
        async (searchQuery: string, filters: SearchFilter[] = []) => {
          if (!searchQuery) {
            searchAttemptSessionManager.endSession(
              SEARCH_ATTEMPT_END_REASONS.QUERY_MANUALLY_CLEARED,
            );
            return;
          }

          logger.info('Starting search...');

          const t0 = performance.now();
          setSearchStartTimestamp(t0);

          if (performSearchSubscription?.unsubscribe) {
            performSearchSubscription.unsubscribe();
          }

          setIsFirstBatch(true);
          setActiveSearch(true);
          dispatch({
            type: SearchActionType.START_SEARCH,
            payload: { searchQuery, filters },
          });

          let failed = false;
          const connections = await measureLatency(
            METRIC_NAMESPACE,
            'get-connectors',
            getConnections,
          );

          const t3 = performance.now();
          performSearchSubscription = performSearch(
            searchQuery,
            connections,
            filters,
            options,
          )
            .pipe(
              finalize(() => {
                metrics.counter('status', 1, {
                  status: failed ? 'error' : 'success',
                });
              }),
            )
            .subscribe({
              next: (res) => {
                const { results, localMixinPosition } = res;
                logger.info(
                  'Search results batch received of length',
                  results.length,
                );
                if (results.length === 0) return;
                dispatch({
                  type: SearchActionType.RECEIVE_RESULTS,
                  payload: {
                    results,
                    localMixinPosition,
                    useSlottedSearchRanking: options.useSlottedSearchRanking,
                  },
                });
                metrics.stats('source-loaded', performance.now() - t3);
              },
              error: (err) => {
                failed = true;
                dispatch({ type: SearchActionType.SET_ERROR, payload: err });
                setActiveSearch(false);
                logger.info('Error fetching search results');
                logger.error('Search error', err);
              },
              complete: () => {
                markGettingStartedTaskComplete('perform_dash_search_module');
                dispatch({
                  type: SearchActionType.FINISH_SEARCH,
                  payload: {
                    useSlottedSearchRanking: options.useSlottedSearchRanking,
                  },
                });
                setActiveSearch(false);
                metrics.stats('search-complete', performance.now() - t0);
                metrics.stats('all-sources-loaded', performance.now() - t3);
                logger.info('Search complete');
              },
            });
        },
        500,
        { leading: true },
      ),
    [
      getConnections,
      markGettingStartedTaskComplete,
      options,
      searchAttemptSessionManager,
    ],
  );

  useEffect(() => {
    if (options.useSlottedSearchRanking) {
      validateOrderIsCorrect(state.resultsToDisplay);
    }
  }, [options.useSlottedSearchRanking, state.resultsToDisplay]);

  const resultsSubset = state.resultsToDisplay.slice(
    0,
    searchConfig.maxResultsToDisplay,
  );

  return {
    query: state.query,
    filters: state.filters,
    results: resultsSubset,
    loading: state.loading,
    error: state.error,
    handleSearch: handleSearch,
    resetQuery,
    resultDisplayState,
  };
}

function orderByScore(results: ScoredResult[]): ScoredResult[] {
  return results.sort(({ score: lscore }, { score: rscore }) => {
    return rscore - lscore;
  });
}

/**
 * used to validate that the server results are in the correct order
 *
 * I decided to keep this in just in case we ever want to easily validate this
 * behavior for a while, but eventually it can probably be removed once the
 * 'dash_2024_08_09_slotted_search_ranking' feature flag is removed
 *
 * This function takes 1-2ms to run on my M1 macbook pro
 */
function validateOrderIsCorrect(results: ScoredResult[]) {
  function arraysMatchByIdentity<T>(array1: T[], array2: T[]): boolean {
    if (array1.length !== array2.length) {
      return false;
    }
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
    return true;
  }

  const actualOrder = [...results]
    .filter((result) => !!result.relevanceScore)
    .map((result) => ({
      uuid: result.uuid,
      relevanceScore: result.relevanceScore,
      title: result.title,
      source: result.searchResultSource,
    }));
  const expectedOrder = [...actualOrder].sort(
    ({ relevanceScore: lscore }, { relevanceScore: rscore }) => {
      return rscore - lscore;
    },
  );

  if (!arraysMatchByIdentity(actualOrder, expectedOrder)) {
    logger.debug('Server items were displayed out of expected order!');
    if (DEBUG) {
      logger.debug('actual order: ', actualOrder);
      logger.debug('expected order: ', expectedOrder);
    }
  }
}

function dedupe(results: ScoredResult[]): ScoredResult[] {
  const seen = new Set();
  return results.filter((result) => {
    if (seen.has(result.uuid)) return false;
    if (result.recurringEventId && seen.has(result.recurringEventId)) {
      return false;
    }
    seen.add(result.uuid);
    if (result.recurringEventId) seen.add(result.recurringEventId);
    return true;
  });
}

function dedupeAgainst(
  haystack: ScoredResult[],
  results: ScoredResult[],
): ScoredResult[] {
  const [uuids, id3ps] = [new Set(), new Set()];
  for (const { uuid, id3p } of haystack) {
    uuids.add(uuid);
    if (id3p) id3ps.add(id3p);
  }
  return results.filter((result) => {
    if (uuids.has(result.uuid)) return false;
    if (result.id3p && id3ps.has(result.id3p)) return false;
    if (result.recurringEventId && uuids.has(result.recurringEventId)) {
      return false;
    }
    return true;
  });
}
