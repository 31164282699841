import { SurveyEvent } from '@mirage/feedback/types';
import { sprig } from '@sprig-technologies/sprig-browser';
import noop from 'lodash/noop';

import type { SurveyAttributes } from '@mirage/feedback/types';

// The Sprig SDK has made their WindowSprig type unassignable to SprigAPI

export class Sprig {
  private _sprig: WindowSprig | null = null;
  private environmentId: string;
  private userId?: string;

  public constructor(environmentId: string, userId?: string) {
    this.environmentId = environmentId;
    this.userId = userId;
  }

  /** Callbacks for Sprig event listeners */
  public onSurveyPresented: () => void = noop;
  public onSurveyClosed: () => void = noop;

  private get sprigAPI(): WindowSprig {
    if (!this._sprig) {
      this._sprig = sprig.configure({
        environmentId: this.environmentId,
        maxHeight: '50%',
        dismissOnPageChange: true,
        customStyles: `
          .ul-app__container {
            overflow-y: scroll;
          }
          .ul-question {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #1E1919;
          }
          .ul-caption {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: rgba(82, 74, 62, 0.82);
          }
        `,
      });
      this.initEventListeners();
    }
    return this._sprig;
  }

  private initEventListeners(): void {
    if (this._sprig) {
      this._sprig.addListener(
        this._sprig.UPDATES.SURVEY_PRESENTED,
        this.onSurveyPresented,
      );
      this._sprig.addListener(
        this._sprig.UPDATES.SURVEY_CLOSED,
        this.onSurveyClosed,
      );
    }
  }

  public async publishEvent(
    event: SurveyEvent,
    attributes: SurveyAttributes,
  ): Promise<void> {
    // https://jira.dropboxer.net/browse/ASKSEC-11542
    // security approves to use public id for Sprig.
    // We send this userId in shown.dash_survey PAP events for correlation.
    if (this.userId) {
      this.sprigAPI.setUserId(this.userId);
    }
    this.sprigAPI.setAttributes(attributes);
    this.sprigAPI.track(event);
  }
}
