import { Badge } from '@dropbox/dig-components/badge';
import { IconButton } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine, InfoLine, Twinkle2Line } from '@dropbox/dig-icons/assets';
import { CloseDashChatType } from '@mirage/analytics/events/enums/close_dash_chat_type';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { SECURITY_BINDER_URL } from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import styles from './RailHeader.module.css';

export type RailHeaderProps = {
  onClose: (closeChatType: CloseDashChatType) => void;
  onReset?: () => void;
  openURL?: (url: string) => void;
};

export const RailHeader = ({ onClose, openURL }: RailHeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <UIIcon src={Twinkle2Line} />
        <Text variant="label" size="medium" isBold>
          {i18n.t('doc_summary_qna_ask_about_file')}
        </Text>
        <Badge>{i18n.t('beta')}</Badge>
      </div>
      {!!openURL && (
        <IconButtonWithTooltip
          tooltipProps={{
            title: i18n.t('doc_summary_qna_generated_using_third_party_ai'),
          }}
          size="medium"
          variant="transparent"
          aria-label={i18n.t('learn_more')}
          onClick={() => openURL(SECURITY_BINDER_URL)}
          data-uxa-log={createUxaElementId('learn_more_link', {
            actionSurfaceComponent: 'ask_dialog',
            featureLine: 'answers',
          })}
        >
          <UIIcon src={InfoLine} />
        </IconButtonWithTooltip>
      )}
      <IconButton
        size="medium"
        variant="transparent"
        aria-label={i18n.t('close')}
        onClick={() => onClose('close_chat')}
        data-uxa-log={createUxaElementId('close_chat_button', {
          actionSurfaceComponent: 'ask_dialog',
          featureLine: 'answers',
        })}
      >
        <UIIcon src={CloseLine} />
      </IconButton>
    </div>
  );
};
