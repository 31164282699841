import { stacks, users } from '@dropbox/api-v2-client';
import { AvatarSizes } from '@dropbox/dig-components/avatar';
import { useAllStacksAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { DashFacepile } from '@mirage/dash-component-library/components/DashFacepile';
import { getDefaultColorIndex } from '@mirage/dash-component-library/themes/Stacks';
import { StacksLoadingContainer } from '@mirage/mosaics/StacksLoading/StacksLoadingContainer';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import {
  asShortcut,
  stackDerivePAPProps,
  stackGetShareId,
  stackItemGetName,
} from '@mirage/service-stacks/service/utils';
import { isDefined } from '@mirage/shared/util/tiny-utils';
import { WorkingSetCardData } from '@mirage/working-set';
import { useAtomValue } from 'jotai';
import orderBy from 'lodash/orderBy';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import Actions from '../FullScreenStack/Actions';
import {
  EMOJI_CLEARED_SENTINEL_VALUE,
  membersFromStack,
  stackIsPinned,
} from '../Helpers/Utils';
import {
  useSortedStacks,
  useSortedStacksWithItemsBySortOption,
} from '../hooks';
import {
  accessLevelIconSrcForMemberCount,
  accessLevelTitleForAccessLevel,
} from '../ShareModal/Utils';
import { StacksBanner } from '../StacksBanner/StacksBanner';
import styles from './AllStacks.module.css';
import AllStacksGrid from './AllStacksGrid';
import { AllStacksV2 } from './AllStacksV2';
import { stackSearchStacksAtom } from './atoms';
import { useStackPagePreferences } from './useStackPagePreferences';

export const AllStacks = ({
  onSelectStack,
}: {
  onSelectStack: (stack: stacks.Stack, numOfLinks: number) => void;
}) => {
  const currentAccount = useDropboxAccount();

  const {
    stackPageSortPreference: sortPreference,
    stackPageFilterPreference: filterPreference,
  } = useStackPagePreferences();
  const sortedStacks = useSortedStacks();
  const { stacks: filteredSortedStacks, stackItemsMap } =
    useSortedStacksWithItemsBySortOption(sortPreference, filterPreference);
  const searchResultStacks = useAtomValue(stackSearchStacksAtom);

  const [menuOpenForStackId, setMenuOpenForStackId] = useState<string>();

  const augustRevision = useAllStacksAugustRevisionEnabled();

  const convertStacksToWorkingSetCardData = useCallback(
    (stacks: stacks.Stack[]) => {
      return stacks.map((stack: stacks.Stack) => {
        return convertStackToWorkingSetCardData(
          stack,
          stacks,
          onSelectStack,
          stackItemsMap,
          menuOpenForStackId,
          currentAccount,
          augustRevision,
          setMenuOpenForStackId,
        );
      });
    },
    [
      augustRevision,
      currentAccount,
      menuOpenForStackId,
      onSelectStack,
      stackItemsMap,
    ],
  );

  const [workingSetCardData, pinnedSetCardData]: [
    WorkingSetCardData[],
    WorkingSetCardData[],
  ] = useMemo((): [WorkingSetCardData[], WorkingSetCardData[]] => {
    const stacksToList = searchResultStacks ?? filteredSortedStacks ?? [];
    const pinnedStacks = sortedStacks?.filter(stackIsPinned);
    return [
      convertStacksToWorkingSetCardData(stacksToList),
      convertStacksToWorkingSetCardData(pinnedStacks ?? []),
    ];
  }, [
    convertStacksToWorkingSetCardData,
    filteredSortedStacks,
    searchResultStacks,
    sortedStacks,
  ]);

  return (
    <div className={styles.wrapper}>
      <StacksBanner className={styles.featureBanner} />
      <StacksLoadingContainer loading={sortPreference === undefined}>
        {augustRevision ? (
          <AllStacksV2
            workingSetCardData={workingSetCardData}
            pinnedSetCardData={pinnedSetCardData}
            filterPreference={filterPreference}
          />
        ) : (
          <AllStacksGrid
            workingSetCardData={workingSetCardData}
            filterPreference={filterPreference}
          />
        )}
      </StacksLoadingContainer>
    </div>
  );
};

const convertStackToWorkingSetCardData = (
  stack: stacks.Stack,
  stacks: stacks.Stack[],
  onSelectStack: (stack: stacks.Stack, numOfLinks: number) => void,
  stackItemsMap?: { [nsid: string]: stacks.StackItem[] },
  menuOpenForStackId?: string,
  currentAccount?: users.FullAccount,
  augustRevision?: boolean,
  setMenuOpenForStackId?: Dispatch<SetStateAction<string | undefined>>,
) => {
  const members = membersFromStack(stack);
  const stackItems = stackItemsMap?.[stack.namespace_id ?? ''] ?? [];

  return {
    namespaceId: stack.namespace_id ?? '',
    id: stackGetShareId(stack) ?? '',
    title: stack.stack_data?.name ?? '',
    lastModifiedMs: (stack.last_modified_time_utc_sec ?? 0) * 1000,
    maxHlcMs: (stack.max_hlc_micros ?? 0) / 1000,
    colorIndex: stack.stack_data?.color_index ?? getDefaultColorIndex(),
    emoji: stack.stack_data?.emoji ?? EMOJI_CLEARED_SENTINEL_VALUE,
    isPinned: stackIsPinned(stack),
    menuIsOpen: menuOpenForStackId === stack.namespace_id,
    debugData: {
      pinnedSortKey: stack.user_data?.pinned_sort_key,
      pinnedMs: stack.user_data?.pinned_ms,
      maxHlc: stack.max_hlc_micros,
    },
    memberCount: members.length,
    accessLevelIcon: accessLevelIconSrcForMemberCount(
      stack.sharing_data?.members?.length ?? 0,
    ),
    accessLevel: accessLevelTitleForAccessLevel(
      stack?.sharing_data?.shared_link?.access_level,
      stack?.sharing_data?.members?.length ?? 0,
    ),
    links: orderBy(
      stackItems.map(asShortcut).filter(isDefined),
      [(item) => item.last_modified_time_utc_sec],
      ['desc'],
    ).map((stackItem) => {
      const { url, api_file_id } = stackItem;
      return {
        url: url ?? '',
        id: api_file_id ?? '',
        name: stackItemGetName(stackItem, stacks),
      };
    }),
    // This use case is probably fine: Due to naming convention this
    // cannot be directly rendered as a react component; instead it must
    // be invoked and the return value is rendered.
    // eslint-disable-next-line react/no-unstable-nested-components
    getMembersComponent: (size: AvatarSizes = 'medium') => (
      <DashFacepile
        maxMembersToShow={3}
        members={members}
        size={size}
        itemClassName={styles.facePileItem}
      />
    ),

    stackDerivedPAPProps: stackDerivePAPProps(stack),
    actionMenuComponent: currentAccount && (
      <Actions
        stack={stack}
        iconColor="var(--dig-color__text__subtle)"
        currentAccount={currentAccount}
        setMenuOpenForStackId={setMenuOpenForStackId}
        showPinnedIcon={!augustRevision}
      />
    ),
    onClickCard: () => onSelectStack(stack, stackItems.length),
  };
};
