import { stacks } from '@dropbox/api-v2-client';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import {
  createUxaElementId,
  dispatchElementClicked,
} from '@mirage/analytics/uxa';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { LinkComponent, LinkList } from '@mirage/link-list';
import { ListItemSize } from '@mirage/link-list/types';
import { stackDerivePAPProps } from '@mirage/service-stacks';
import { stackItemGetName } from '@mirage/service-stacks/service/utils';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import { useCopyStackItemUrlToClipboard } from '@mirage/stacks/CopyStackUrl';
import { getStackItemMetadata } from '@mirage/stacks/Helpers/Utils';
import { useSortedStacks } from '@mirage/stacks/hooks';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import React, { useCallback, useState } from 'react';
import { EmptyStateBox, EmptyStateBoxType } from '../Connectors/EmptyStateBox';
import { shadowStackHasWritePermissionsAtom } from '../fullPageShadowAtoms';
import styles from './NoSectionsStackItems.module.css';
import { StackItemActionMenu } from './StackItemActionMenu';

export const NoSectionsStackItems = ({
  stack,
  items,
  header,
  onEditItem,
}: {
  stack: stacks.Stack;
  items: stacks.StackItemShortcut[];
  header?: React.ReactNode;
  onEditItem: (item: stacks.StackItemShortcut) => void;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const allStacks = useSortedStacks();
  const hasWritePermissions = useAtomValue(shadowStackHasWritePermissionsAtom);
  const augustRevision = useStackPageAugustRevisionEnabled();

  const [actionMenuOpenForItemId, setActionMenuOpenForItemId] = useState<
    string | null
  >(null);

  const onCopyLink = useCopyStackItemUrlToClipboard(stack);
  const openLink = useCallback(() => {
    if (stack) {
      reportPapEvent(
        PAP_Open_DashLink({
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
        }),
      );
    }
    dispatchElementClicked(
      createUxaElementId('stack_item', {
        featureLine: 'stacks',
      }),
    );
  }, [reportPapEvent, stack]);

  if (items.length === 0) {
    return (
      <EmptyStateBox
        hasWritePermissions={hasWritePermissions}
        type={EmptyStateBoxType.NO_SEARCH_RESULTS}
      />
    );
  }

  return (
    <TwoColumnGridCard
      settingId="no_sections_stack_items"
      isAlwaysCollapsed={false}
      showDividerLine={false}
      cardTypeProps={{
        cardType: CardHeaderType.CUSTOM,
        customHeader: header ? (
          <div className={styles.headerContainer}>{header}</div>
        ) : null,
      }}
      className={classNames(
        augustRevision && styles.augustRevisionSectionContainer,
      )}
    >
      <LinkList listItemSize={ListItemSize.Large} skipOrdering>
        {(items ?? []).map((item) => (
          <LinkComponent
            key={item.api_file_id}
            link={{
              id: item.api_file_id,
              title: stackItemGetName(item, allStacks),
              url: nonNil(item.url, 'item.url'),
              sortKey: item.sort_key,
            }}
            onOpenLink={openLink}
            listItemSize={ListItemSize.XLarge}
            accessoryComponent={
              <StackItemActionMenu
                key={item.api_file_id}
                stack={stack}
                item={item}
                setActionMenuOpen={(isOpen) =>
                  setActionMenuOpenForItemId(
                    isOpen ? item.api_file_id ?? null : null,
                  )
                }
                onCopyLink={onCopyLink}
                onEditItem={onEditItem}
                hasWritePermissions={hasWritePermissions || false}
              />
            }
            shouldShowHoverState={actionMenuOpenForItemId === item.api_file_id}
            metadata={getStackItemMetadata(item)}
          />
        ))}
      </LinkList>
    </TwoColumnGridCard>
  );
};
