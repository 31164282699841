import { stacks, users } from '@dropbox/api-v2-client';
import { UIIcon } from '@dropbox/dig-icons';
import {
  PinFill,
  PinLine,
  ShareArrowLine,
  ShareLine,
} from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Pin_DashStack } from '@mirage/analytics/events/types/pin_dash_stack';
import { PAP_Unpin_DashStack } from '@mirage/analytics/events/types/unpin_dash_stack';
import { createUxaElementId } from '@mirage/analytics/uxa';
import {
  stackDerivePAPProps,
  upsertToggleStackIsPinned,
} from '@mirage/service-stacks';
import {
  ButtonWithTooltip,
  CoreTooltipProps,
} from '@mirage/shared/buttons/ButtonWithTooltip';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { showSnackbar } from '../../shared/snackbar';
import {
  ActionsMenu,
  ActionsMenuProps,
  SetMenuOpenForStackId,
} from '../ActionsMenu';
import { stackIsArchived } from '../Helpers/Utils';
import { useShareModal } from '../ShareModal/ShareModal';
import styles from './Actions.module.css';
import { ArchiveConfirmationModal } from './ArchiveConfirmationModal';
import { CloneConfirmationModal } from './CloningConfirmationModal';
import { useArchiveStack } from './hooks';
import { useCloneStack } from './useCloneStack';
import { asStackUpsertId, newStackPapEvent } from './utils';

import type { MenuPlacement } from '@dropbox/dig-components/menu';

export type ActionsProps = Omit<ActionsMenuProps, 'onShare' | 'stack'> & {
  stack: stacks.Stack | null;
  currentAccount?: users.FullAccount;
  triggerEditStackRef?: React.RefObject<HTMLInputElement>;
  showShareLabeledIcon?: boolean;
  shareButtonTooltipProps?: CoreTooltipProps;
  mutationRequestId?: string;
  sessionId?: string;
};

const actionSurfaceComponent = 'stacks';
const featureLine = 'stacks';

const useToggleStackPin = (
  stack: stacks.Stack | null,
  mutationRequestId?: string,
  sessionId?: string,
) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isNewStack = !stack?.namespace_id;

  return useCallback(async () => {
    const { nsId } = await upsertToggleStackIsPinned(
      asStackUpsertId(stack?.namespace_id, mutationRequestId ?? null),
    );
    if (!isNewStack && stack?.user_data?.is_pinned) {
      reportPapEvent(
        PAP_Unpin_DashStack({
          ...stackDerivePAPProps(stack),
          actionSurfaceComponent,
          featureLine,
        }),
      );

      showSnackbar({ title: i18n.t('stack_unpinned') });
    } else {
      if (!isNewStack) {
        reportPapEvent(
          PAP_Pin_DashStack({
            ...stackDerivePAPProps(stack),
            actionSurfaceComponent,
            featureLine,
          }),
        );
      } else {
        reportPapEvent(newStackPapEvent(nsId, sessionId));
      }

      showSnackbar({ title: i18n.t('stack_pinned') });
    }
  }, [stack, reportPapEvent, isNewStack, mutationRequestId, sessionId]);
};

export default function Actions({
  stack,
  currentAccount,
  setMenuOpenForStackId,
  iconColor,
  menuPlacement,
  showPinnedIcon = true,
  triggerEditStackRef,
  showShareLabeledIcon,
  shareButtonTooltipProps = { title: '' },
  mutationRequestId,
  sessionId,
}: ActionsProps) {
  const { openShareModal, shareModalJsx } = useShareModal({
    stack,
  });
  const isNewStack = !stack?.namespace_id;
  const toggleStackPin = useToggleStackPin(stack, mutationRequestId, sessionId);

  const onPin = () => {
    toggleStackPin();
  };

  const onEditStack = () => {
    triggerEditStackRef?.current?.click();
  };
  const isStackPinned = stack?.user_data?.is_pinned;
  const showShareIconOnly = !showShareLabeledIcon;

  const {
    isArchiveStacksEnabled,
    isArchiveModalOpen,
    setIsArchiveModalOpen,
    toggleArchiveStackStatus,
  } = useArchiveStack(stack);

  const {
    isCloning,
    isCloneStacksEnabled,
    isCloneStackModalOpen,
    setIsCloneStackModalOpen,
    handleCloneStack,
  } = useCloneStack(stack);

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        {showShareIconOnly && !isNewStack && (
          <IconButtonWithTooltip
            tooltipProps={{
              title: i18n.t('share'),
            }}
            variant="transparent"
            onClick={openShareModal}
            data-testid="ShareStack"
            aria-label={i18n.t('share')}
            data-uxa-log={createUxaElementId('share_button', {
              actionSurfaceComponent,
              featureLine,
            })}
            data-uxa-entity-id={stack.namespace_id}
          >
            <UIIcon
              src={ShareArrowLine}
              style={iconColor ? { color: iconColor } : {}}
            />
          </IconButtonWithTooltip>
        )}
        {showPinnedIcon && (
          <IconButtonWithTooltip
            tooltipProps={{
              title: i18n.t(isStackPinned ? 'unpin_stack' : 'pin_stack'),
            }}
            variant="transparent"
            onClick={onPin}
            data-testid="PinStack"
            aria-label={i18n.t(isStackPinned ? 'unpin' : 'pin')}
            data-uxa-log={createUxaElementId('pin_button', {
              actionSurfaceComponent,
              featureLine,
            })}
            disabled={!currentAccount}
            data-uxa-entity-id={stack?.namespace_id}
          >
            <UIIcon
              src={isStackPinned ? PinFill : PinLine}
              style={iconColor ? { color: iconColor } : {}}
            />
          </IconButtonWithTooltip>
        )}

        {
          <ActionsMenu
            stack={stack}
            currentAccount={currentAccount}
            onEdit={triggerEditStackRef ? onEditStack : undefined}
            onArchive={
              isArchiveStacksEnabled
                ? () => setIsArchiveModalOpen(true)
                : undefined
            }
            onClone={
              isCloneStacksEnabled
                ? () => setIsCloneStackModalOpen(true)
                : undefined
            }
            isCloning={isCloning}
            setMenuOpenForStackId={setMenuOpenForStackId}
            iconColor={iconColor}
            menuPlacement={menuPlacement}
            showColorPicker
            mutationRequestId={mutationRequestId}
          />
        }
        {isArchiveStacksEnabled && isArchiveModalOpen && (
          <ArchiveConfirmationModal
            isArchived={stackIsArchived(stack)}
            isOpen
            onConfirm={async () => {
              setIsArchiveModalOpen(false);
              await toggleArchiveStackStatus({});
            }}
            onCancel={() => {
              setIsArchiveModalOpen(false);
            }}
          />
        )}
        {isCloneStacksEnabled && (
          <CloneConfirmationModal
            isOpen={isCloneStackModalOpen}
            onConfirm={async () => {
              await handleCloneStack(uuidv4());
              setIsCloneStackModalOpen(false);
            }}
            onCancel={() => {
              setIsCloneStackModalOpen(false);
            }}
          />
        )}
        {showShareLabeledIcon && !isNewStack && (
          <ButtonWithTooltip
            variant="outline"
            data-testid="ShareStack"
            onClick={openShareModal}
            data-uxa-log={createUxaElementId('share_button', {
              actionSurfaceComponent,
              featureLine,
            })}
            data-uxa-entity-id={stack.namespace_id}
            withIconStart={
              <UIIcon
                src={ShareArrowLine}
                style={iconColor ? { color: iconColor } : {}}
              />
            }
            tooltipProps={shareButtonTooltipProps}
            disabled={!currentAccount}
          >
            {i18n.t('share')}
          </ButtonWithTooltip>
        )}
      </div>
      {shareModalJsx}
    </div>
  );
}

interface HeaderActionsProps {
  stack: stacks.Stack | null;
  menuPlacement?: MenuPlacement;
  mutationRequestId?: string;
  sessionId?: string;
  currentAccount?: users.FullAccount;
  iconColor?: string;
  setMenuOpenForStackId?: SetMenuOpenForStackId;
}

export const HeaderActions: React.FC<HeaderActionsProps> = ({
  stack,
  menuPlacement,
  mutationRequestId,
  sessionId,
  currentAccount,
  iconColor,
  setMenuOpenForStackId,
}) => {
  const toggleStackPin = useToggleStackPin(stack, mutationRequestId, sessionId);
  const {
    isArchiveStacksEnabled,
    isArchiveModalOpen,
    setIsArchiveModalOpen,
    toggleArchiveStackStatus,
  } = useArchiveStack(stack);

  const {
    isCloning,
    isCloneStacksEnabled,
    isCloneStackModalOpen,
    setIsCloneStackModalOpen,
    handleCloneStack,
  } = useCloneStack(stack);

  const isStackPinned = stack?.user_data?.is_pinned;

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <IconButtonWithTooltip
          tooltipProps={{
            title: i18n.t(isStackPinned ? 'unpin_stack' : 'pin_stack'),
          }}
          variant="transparent"
          onClick={toggleStackPin}
          data-testid="PinStack"
          aria-label={i18n.t(isStackPinned ? 'unpin' : 'pin')}
          data-uxa-log={createUxaElementId('pin_button', {
            actionSurfaceComponent,
            featureLine,
          })}
          disabled={!currentAccount}
          data-uxa-entity-id={stack?.namespace_id}
        >
          <UIIcon
            src={isStackPinned ? PinFill : PinLine}
            style={iconColor ? { color: iconColor } : {}}
          />
        </IconButtonWithTooltip>

        {
          <ActionsMenu
            stack={stack}
            currentAccount={currentAccount}
            onArchive={
              isArchiveStacksEnabled
                ? () => setIsArchiveModalOpen(true)
                : undefined
            }
            onClone={
              isCloneStacksEnabled
                ? () => setIsCloneStackModalOpen(true)
                : undefined
            }
            isCloning={isCloning}
            setMenuOpenForStackId={setMenuOpenForStackId}
            iconColor={iconColor}
            menuPlacement={menuPlacement}
            showColorPicker
            mutationRequestId={mutationRequestId}
            useGearIcon
          />
        }
        {isArchiveStacksEnabled && isArchiveModalOpen && (
          <ArchiveConfirmationModal
            isArchived={stackIsArchived(stack)}
            isOpen
            onConfirm={async () => {
              setIsArchiveModalOpen(false);
              await toggleArchiveStackStatus({});
            }}
            onCancel={() => {
              setIsArchiveModalOpen(false);
            }}
          />
        )}
        {isCloneStacksEnabled && (
          <CloneConfirmationModal
            isOpen={isCloneStackModalOpen}
            onConfirm={async () => {
              await handleCloneStack(uuidv4());
              setIsCloneStackModalOpen(false);
            }}
            onCancel={() => {
              setIsCloneStackModalOpen(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

interface ShareStackButtonProps {
  stack: stacks.Stack;
  shareButtonTooltipProps?: CoreTooltipProps;
  iconColor?: string;
  disabled?: boolean;
}

export const ShareStackButton: React.FC<ShareStackButtonProps> = ({
  stack,
  iconColor,
  shareButtonTooltipProps = { title: '' },
  disabled,
}) => {
  const { shareModalJsx, openShareModal } = useShareModal({ stack });
  return (
    <>
      <ButtonWithTooltip
        variant="outline"
        data-testid="ShareStack"
        onClick={openShareModal}
        data-uxa-log={createUxaElementId('share_button', {
          actionSurfaceComponent,
          featureLine,
        })}
        data-uxa-entity-id={stack.namespace_id}
        withIconStart={
          <UIIcon
            src={ShareLine}
            style={iconColor ? { color: iconColor } : {}}
          />
        }
        tooltipProps={shareButtonTooltipProps}
        disabled={disabled}
      >
        {i18n.t('share_stack')}
      </ButtonWithTooltip>
      {shareModalJsx}
    </>
  );
};
