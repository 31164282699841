import { SettingsPage } from '@mirage/mosaics/SettingsPage';
import { ShowSettingsSections } from '@mirage/mosaics/SettingsPage/SettingsPage';
import { useDevFeatures } from '@mirage/service-auth/useDevFeatures';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { defaultSettings } from '@mirage/service-settings/service';
import useSettings, { DefaultKeys } from '@mirage/service-settings/useSettings';
import { isExtensionInstalled } from '@mirage/settings/utils/webExtensionsHelpers';
import { isIframe } from '@mirage/shared/util/tiny-utils';
import { useMemo } from 'react';

export const Settings: React.FC<{ selectedTab?: string }> = ({
  selectedTab,
}) => {
  const { settings } = useSettings(DefaultKeys);
  const enableDevFeatures = useDevFeatures();

  const showSettingsSections: ShowSettingsSections = useMemo(() => {
    return {
      general: {
        theme: true,
        // No need to get dynamic status of extension installation.
        webPreferences:
          // Show when the extension is installed.
          isExtensionInstalled() ||
          // Assume that when inside an iframe, it is running with the browser
          // extension (most likely true).
          isIframe() ||
          // Show when user is running the extension native webapp.
          EnvCtx.surface === 'extension',
        debug: enableDevFeatures,
      },
      apps: { showConnectorsList: true },
      internalFeatures: enableDevFeatures,
      internalDeveloper: enableDevFeatures,
      localFiles: false,
    };
  }, [enableDevFeatures]);

  return (
    <SettingsPage
      settings={settings ?? defaultSettings}
      showSettingsSections={showSettingsSections}
      defaultTab={selectedTab}
    />
  );
};
