import { useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import {
  isUserIneligibleAtom,
  eligibleCheckDoneForPublicAccountIdAtom,
} from "@mirage/eligibility/useEligibilityCheck";
import { getLoginPathWithReturnRedirectURLParam } from "@mirage/service-auth";
import useDropboxAccount from "@mirage/service-auth/useDropboxAccount";
import { useIsLoggedIn } from "@mirage/service-auth/useDropboxAuthentication";
import { tagged } from "@mirage/service-logging";
import {
  isUsersFirstLogin,
  markDashUserFirstUseTimestamp,
} from "@mirage/service-onboarding";
import { hasUserCompletedOnboarding } from "@mirage/service-onboarding/hasUserCompletedOnboarding";
import { DROPBOX_ONBOARDING_START_URL } from "@mirage/shared/urls";
import { isIframe, sleepForever } from "@mirage/shared/util/tiny-utils";
import { AppRoute, PageType, RoutePath } from "@mirage/webapp/routeTypes";
import { useAtomValue } from "jotai";

import { ROUTES } from "../Routes";

const logger = tagged("useAuthCheck");

export function useAuthCheck(route: AppRoute) {
  const navigate = useNavigate();
  const loggedIn = useIsLoggedIn();
  const account = useDropboxAccount();
  const eligibleCheckDoneForPublicAccountId = useAtomValue(
    eligibleCheckDoneForPublicAccountIdAtom,
  );
  const isUserIneligible = useAtomValue(isUserIneligibleAtom);
  const isUserCheckedAndEligible =
    eligibleCheckDoneForPublicAccountId !== undefined &&
    eligibleCheckDoneForPublicAccountId === account?.public_account_id &&
    !isUserIneligible;

  // Extract properties from route because the `route` object changes on every
  // render, and should not be used as a useEffect dependency.
  const { requiresAuthentication, pageType } = route;

  useEffect(() => {
    // If the user is not allowed to use the app, navigate to not found.
    if (loggedIn) {
      // No need to await for completion.
      void onLoggedIn(pageType, navigate, isUserCheckedAndEligible);
    } else {
      // No need to await for completion.
      void onLoggedOut(pageType, navigate, requiresAuthentication);
    }
  }, [
    loggedIn,
    navigate,
    requiresAuthentication,
    pageType,
    isUserCheckedAndEligible,
  ]);

  return loggedIn;
}

// exported for testing
export async function onLoggedIn(
  pageType: PageType,
  navigate: NavigateFunction,
  isUserCheckedAndEligible: boolean,
) {
  logger.debug("onLoggedIn() - pageType", pageType);
  // If the user is logged in and lands on the login page, navigate to main page.
  if (pageType === PageType.LOGIN) {
    logger.debug("onLoggedIn() - navigate to main page (pageType is LOGIN)");
    navigate(`${ROUTES.HOME.path}${window.location.search}`);
    return;
  }

  if (pageType === PageType.LOGOUT || pageType === PageType.OAUTH) {
    return;
  }

  // Eligible users on non-special Page Types
  const firstTimeUser = await isUsersFirstLogin(); // cached value

  logger.debug(
    "onLoggedIn() - is first time user =",
    firstTimeUser ? "true" : "false",
  );

  if (isUserCheckedAndEligible && firstTimeUser) {
    await markDashUserFirstUseTimestamp();

    if (pageType !== PageType.SETUP) {
      const userCompletedOnboarding = await hasUserCompletedOnboarding();
      if (!userCompletedOnboarding) {
        logger.debug("onLoggedIn() - redirect to onboarding start url");
        window.location.assign(DROPBOX_ONBOARDING_START_URL);
      }
    }
  }
}

async function onLoggedOut(
  pageType: PageType,
  navigate: NavigateFunction,
  requiresAuthentication: boolean,
) {
  logger.debug("onLoggedOut() - pageType", pageType);
  // User is not logged in.
  if (!requiresAuthentication) {
    logger.debug("onLoggedOut() - authentication not required");
    // Allow page to render.
    return;
  }

  // Start oauth flow for embedded mode.
  if (isIframe()) {
    // open an oauth sub-window -- communication backwards will
    // have to be done at the oauth redirect level
    window.open(
      window.location.origin + "/login",
      "_blank",
      "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=900,height=750,noopener,noreferrer",
    );

    const oauthCompleteHandler = (message: MessageEvent) => {
      if (message.data !== "oauth-complete") return;
      window.removeEventListener("message", oauthCompleteHandler);
      window.location.assign(window.location.origin + RoutePath.EMBED_OPEN);
    };

    window.addEventListener("message", oauthCompleteHandler);

    // Make sure it won't enter into the web app while redirecting
    await sleepForever;
  }

  if (pageType !== PageType.LOGIN) {
    const loginPath = await getLoginPathWithReturnRedirectURLParam();
    logger.debug("onLoggedOut() - navigating to new path", loginPath);
    navigate(loginPath);
  }
}
