import { List } from '@dropbox/dig-components/list';
import { Stack } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Collapse_CalendarItem } from '@mirage/analytics/events/types/collapse_calendar_item';
import { PAP_Expand_CalendarItem } from '@mirage/analytics/events/types/expand_calendar_item';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { Dispatch, SetStateAction } from 'react';
import { CalendarEventComponent } from './CalendarEventComponent';
import styles from './CalendarEventList.module.css';
import { MOTION_SETTINGS } from './constants';

import type { CalendarEvent } from './utils';

export type CalendarEventListProps = {
  events: CalendarEvent[];
  expandedEventId: string;
  setExpandedEventId: Dispatch<SetStateAction<string>>;
  // Whether the event list should be hidden. This is to allow for animation of expand/collapse action.
  hidden?: boolean;
  // Whether it's the first event list in the list of event lists
  isFirst?: boolean;
};

export const CalendarEventList = ({
  events,
  expandedEventId,
  setExpandedEventId,
  hidden = false,
  isFirst = false,
}: CalendarEventListProps) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const earliestStartTime = events?.[0]?.startTime || 0;

  const handleSelect = (uuid: string, i: number) => {
    setExpandedEventId((prev) => {
      // Ignore "collapse" click if first event in first list
      if (prev === uuid && isFirst && i === 0) {
        return prev;
      }

      const [result, papEvent] =
        prev === uuid
          ? ['', PAP_Collapse_CalendarItem]
          : [uuid, PAP_Expand_CalendarItem];
      reportPapEvent(
        papEvent({
          meetingId: uuid,
          featureLine: 'calendar',
          actionSurfaceComponent: 'calendar',
        }),
      );
      return result;
    });
  };

  return (
    <AnimatePresence initial={false}>
      {!hidden && (
        <motion.div {...MOTION_SETTINGS} className={styles.eventList}>
          <List paddingY="0" isSelectable>
            <Stack gap="Micro Small">
              <LayoutGroup id="event">
                {events.map((event, i) => {
                  const expanded = expandedEventId === event.uuid;
                  const isEarliestEvent =
                    isFirst && event.startTime === earliestStartTime;
                  return (
                    <List.Item
                      key={event.uuid}
                      className={styles.eventListItem}
                      tabIndex={0}
                      onClick={() => handleSelect(event.uuid, i)}
                      onKeyDown={onKeyDownCommitFn(() =>
                        handleSelect(event.uuid, i),
                      )}
                      data-testid={'calendar-event-list-item'}
                    >
                      <CalendarEventComponent
                        {...{ event, expanded, isEarliestEvent }}
                      />
                    </List.Item>
                  );
                })}
              </LayoutGroup>
            </Stack>
          </List>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
