import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { Link } from '@mirage/link/Link';

type ActivityLinkProps = {
  href: string;
  children?: React.ReactNode;
};

// A link that handles opening URLs safely in the activity feed
export const ActivityLink = ({ href, children }: ActivityLinkProps) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  return (
    <Link
      href={href}
      onOpen={() => {
        reportPapEvent(
          PAP_Open_DashLink({
            actionSurfaceComponent: 'activity_feed',
            featureLine: 'activity_feed',
          }),
        );
      }}
    >
      {children}
    </Link>
  );
};
