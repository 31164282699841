import {
  ONE_HOUR_IN_MILLIS,
  ONE_MINUTE_IN_MILLIS,
  ONE_SECOND_IN_MILLIS,
} from '@mirage/shared/util/constants';

export const MIN_EVENTS_TO_INCLUDE_IN_ABRIDGED = 3;
export const MAX_EVENTS_TO_INCLUDE_IN_ABRIDGED = 5;
export const MAX_TIME_TO_INCLUDE_IN_ABRIDGED = ONE_HOUR_IN_MILLIS * 2;
export const UPCOMING_EVENT_THRESHOLD = ONE_MINUTE_IN_MILLIS * 10;
export const OLD_EVENT_THRESHOLD = ONE_MINUTE_IN_MILLIS * 10;
export const ONDECK_EVENT_THRESHOLD = ONE_HOUR_IN_MILLIS;
export const ALL_DAY_EVENT_THRESHOLD = ONE_HOUR_IN_MILLIS * 6;
export const EVENT_TIME_DELTA_REFRESH_INTERVAL = ONE_SECOND_IN_MILLIS * 5;
export const CURRENT_EVENTS_REFRESH_INTERVAL = ONE_SECOND_IN_MILLIS * 15;
export const EMPTY_STATE_LOGGING_DELAY = ONE_SECOND_IN_MILLIS * 1;
export const MAX_FACES_IN_FACEPILE = 3;
export const ALL_DAY_TITLE_BLOCKLIST = [
  // Out-of-office
  'Out of office', // Default title from Google for "Out of office" events
  'OOO', // Common shorthand

  // Work locations
  'Home',
  'Office',
];

/**
 * Default settings for our framer-motion animations.
 */
export const MOTION_SETTINGS = {
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  // Transition params do not take in CSS variables, as they are JS triggered
  // animations, so we need to hardcode the values here.
  // var(--duration__micro) = 333ms
  // var(--easing__transition) = cubic-bezier(0.66, 0, 0, 1)
  transition: { duration: 0.333, ease: [0.66, 0, 0, 1] },
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
};
