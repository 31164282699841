import { Avatar as DigAvatar } from '@dropbox/dig-components/avatar';
import { Button } from '@dropbox/dig-components/buttons';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import styled from '@emotion/styled';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAPActionSurface } from '@mirage/analytics/events/base/event';
import { PAP_Click_DashAvatarIcon } from '@mirage/analytics/events/types/click_dash_avatar_icon';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { Dropdown } from '@mirage/search/General/Dropdown';
import { getAbbreviatedFullName, getInitials } from '@mirage/shared/account';
import { ReactNode } from 'react';

import type { MenuPlacement } from '@dropbox/dig-components/menu';
import type { DropdownConfig } from '@mirage/search/General/Dropdown';

type Props = {
  expanded?: boolean;
  rightAccessory?: ReactNode;
  photoSrc?: string;
  givenName?: string;
  surname?: string;
  email?: string;
  config: DropdownConfig;
  placement?: MenuPlacement;
};

export default function AvatarDropdown({
  expanded = false,
  rightAccessory,
  photoSrc,
  givenName,
  surname,
  email,
  config,
  placement = 'top-start',
}: Props) {
  const { reportPapEvent } = useMirageAnalyticsContext();

  return (
    <Dropdown
      anchor={
        <AvatarContainer
          variant="borderless"
          data-testid="avatar-container"
          data-uxa-log={createUxaElementId('avatar_button', {
            actionSurfaceComponent: 'avatar_dropdown',
          })}
        >
          <DigAvatar src={photoSrc} hasNoOutline={true} size="small">
            {getInitials(givenName, surname)}
          </DigAvatar>
          {rightAccessory}
          {expanded && (
            <TextContainer>
              {givenName && surname && (
                <Text variant="label">
                  <Truncate>
                    {getAbbreviatedFullName(givenName, surname)}
                  </Truncate>
                </Text>
              )}
              {email && (
                <Text variant="label" color="faint">
                  {email}
                </Text>
              )}
            </TextContainer>
          )}
        </AvatarContainer>
      }
      config={config}
      placement={placement}
      closeOnMouseLeave={false}
      onToggle={(open) => {
        if (open) {
          reportPapEvent(
            PAP_Click_DashAvatarIcon({
              actionSurface: PAPActionSurface.START_PAGE,
            }),
          );
        }
      }}
    />
  );
}

const AvatarContainer = styled(Button)`
  border-radius: var(--dig-spacing__micro__xsmall);
  box-sizing: border-box;
  height: max-content;

  .dig-Button-content {
    display: flex;
    align-items: center;
    gap: var(--dig-spacing__micro__small);
    padding: var(--dig-spacing__micro__xsmall);
    height: max-content;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: left;
`;
