import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { Service } from '@mirage/service-cloud-docs/service';
import * as rx from 'rxjs';

import type { CloudDocTypeArgs } from '@mirage/service-cloud-docs/service';

export const service = services.get<Service>(ServiceId.CLOUD_DOCS);

export function createDoc(
  docType: CloudDocTypeArgs,
  filename: string,
): Promise<{ url: string; filename: string }> {
  return rx.firstValueFrom(service.createDoc(docType, filename));
}
