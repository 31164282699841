import { stacks } from '@dropbox/api-v2-client';
import {
  ClockLine,
  RewindLine,
  TextStyleLine,
  UnorderedListLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { PAP_Create_DashNewStack } from '@mirage/analytics/events/types/create_dash_new_stack';
import { StackSortOption } from '@mirage/service-settings/service/types';
import { StackUpsertId } from '@mirage/service-stacks/service';
import {
  DEFAULT_SECTION,
  DEFAULT_SECTION_ID,
  stackItemComputeNameFromFields,
  toValidFileName,
} from '@mirage/service-stacks/service/utils';
import { nonNil, sortKeyCompare } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';

export const StackSortOptionMetadata = {
  [StackSortOption.RECENT]: {
    icon: ClockLine,
    label: i18n.t('sort_option_recent'),
  },
  [StackSortOption.ALPHA]: {
    icon: TextStyleLine,
    label: i18n.t('sort_option_alpha'),
  },
  [StackSortOption.SECTION]: {
    icon: UnorderedListLine,
    label: i18n.t('sort_option_section'),
  },
  [StackSortOption.VIEWED]: {
    icon: RewindLine,
    label: 'Last Viewed',
  },
};

export const areStackItemsOrdered = (items: stacks.StackItemShortcut[]) => {
  if (!items.every((item) => !!item.sort_key)) {
    return false;
  }
  return new Set(items.map((item) => item.sort_key)).size === items.length;
};

export const getMaxStackItemSortKey = (items: stacks.StackItemShortcut[]) => {
  if (areStackItemsOrdered(items) && items.length > 0) {
    const sortKeys = items.map((item) => item.sort_key ?? '');
    sortKeys.sort(sortKeyCompare);
    return sortKeys[sortKeys.length - 1];
  }
  return null;
};

const sortStackItems = (items: stacks.StackItemShortcut[]) => {
  if (areStackItemsOrdered(items)) {
    // confirm that the sort keys are unique (hence orderable)
    items.sort((item1, item2) => {
      const a = item1.sort_key;
      const b = item2.sort_key;
      if (a === undefined || b === undefined) {
        return 0;
      }
      return sortKeyCompare(a, b);
    });
    return;
  }
  items.sort((a, b) =>
    stackItemComputeNameFromFields(a).localeCompare(
      stackItemComputeNameFromFields(b),
      'en',
      { sensitivity: 'base' },
    ),
  );
};

export const initLinkSectionMap = (
  sections: stacks.Section[],
  items: stacks.StackItemShortcut[],
) => {
  const map = new Map<string, stacks.StackItemShortcut[]>();
  const knownSectionIds = new Set(sections.map((section) => section.id));

  for (const item of items) {
    let sectionId = item.parent_section_id ?? DEFAULT_SECTION_ID;
    if (!knownSectionIds.has(sectionId)) {
      sectionId = DEFAULT_SECTION_ID;
    }
    let links = map.get(sectionId);
    if (!links) {
      links = [];
      map.set(sectionId, links);
    }
    links.push(item);
  }

  for (const links of map.values()) {
    sortStackItems(links);
  }

  return map;
};

export const initSections = (
  inputSections: stacks.Section[] | null,
): stacks.Section[] => {
  const sections = inputSections ?? [];
  const defaultSection =
    sections.find((section) => section.id === DEFAULT_SECTION_ID) ??
    DEFAULT_SECTION;
  const otherSections = sections.filter(
    (section) => section.id !== DEFAULT_SECTION_ID,
  );
  return [defaultSection, ...otherSections];
};

export const hasItemWithUrlInSection = (
  items: stacks.StackItemShortcut[],
  toFind: { url: string; sectionId: string },
) => {
  return items.find(
    (item) =>
      item.url === toFind.url && item.parent_section_id === toFind.sectionId,
  );
};

export function splitOutDefaultSection(sections: stacks.Section[]) {
  let defaultSection: stacks.Section | undefined;
  const restOfSections: stacks.Section[] = [];

  for (const section of sections) {
    if (!section.id || section.id === DEFAULT_SECTION_ID) {
      defaultSection = section;
    } else {
      restOfSections.push(section);
    }
  }

  return [defaultSection, restOfSections] as const;
}

export const asStackUpsertId = (
  nsId: string | undefined,
  mutationRequestId: string | null,
): StackUpsertId => {
  return {
    type: nsId ? 'nsId' : 'requestId',
    id: nsId ? nsId : nonNil(mutationRequestId, 'mutationRequestId'),
  };
};

export const newStackPapEvent = (
  nsId: string | undefined,
  sessionId: string | undefined,
  numberOfLinks: number = 0,
) =>
  PAP_Create_DashNewStack({
    isOwner: true,
    isShared: false,
    stackId: nsId,
    numberOfLinks,
    createStackSessionId: sessionId,
    featureLine: 'stacks',
    actionSurfaceComponent: 'stacks',
    numSuggestions: 0,
  });

// Remove illegal characters from the stack name during creation or edit
export const sanitizedStackName = (stackName: string): string => {
  return toValidFileName(stackName);
};
