import { Metadata } from '@dropbox/dash-component-library';
import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Split } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { PinFill } from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Collapse_DashCard } from '@mirage/analytics/events/types/collapse_dash_card';
import { PAP_Expand_DashCard } from '@mirage/analytics/events/types/expand_dash_card';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { DashStackIcon } from '@mirage/dash-component-library/components/DashStackIcon';
import {
  TileCard,
  TileCardGrid,
} from '@mirage/dash-component-library/components/TileCard';
import { StackFilterOption } from '@mirage/service-settings/service/types';
import { toggleStackIsPinned } from '@mirage/service-stacks';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { ModuleHeader } from '@mirage/shared/two-column-grid/ModuleHeader';
import { TwoColumnGrid } from '@mirage/shared/two-column-grid/TwoColumnGrid';
import i18n from '@mirage/translations';
import { WorkingSetCardData } from '@mirage/working-set/types';
import classnames from 'classnames';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { AllStacksList } from '../AllStacksList/AllStacksList';
import { useDynamicExpandableContent } from '../FullScreenStack/useDynamicExpandableContent';
import styles from './AllStacksV2.module.css';
import { ControlBar } from './ControlBar';

const MAX_VISIBLE_COUNT = 6;

export const AllStacksV2 = ({
  workingSetCardData,
  pinnedSetCardData,
  filterPreference,
}: {
  workingSetCardData: WorkingSetCardData[];
  pinnedSetCardData: WorkingSetCardData[];
  filterPreference?: StackFilterOption;
}) => {
  return (
    <TwoColumnGrid
      containerRowGap="Macro Large"
      mainRowGap="Macro Small"
      headerChildren={<PinnedStacks workingSetCardData={pinnedSetCardData} />}
      mainChildren={
        <>
          <ControlBar hasNoStacks={false} />
          <AllStacksList
            workingSetCardData={workingSetCardData}
            filterPreference={filterPreference}
          />
        </>
      }
    />
  );
};

// TODO consolidate this with useDynamicExpandableContent
const computeVisibleStacks = (
  wrapperRef: React.RefObject<HTMLDivElement>,
  queryForCard: string,
) => {
  if (!wrapperRef.current) {
    return 0;
  }

  const cards = wrapperRef.current.querySelectorAll(
    queryForCard,
  ) as NodeListOf<HTMLDivElement>;

  let visible = 0;
  cards.forEach((card) => {
    if (
      card.offsetTop + card.offsetHeight <=
      wrapperRef.current!.clientHeight
    ) {
      visible++;
    }
  });

  return visible;
};

const PinnedStacks = ({
  workingSetCardData: allPinnedStacks,
}: {
  workingSetCardData: WorkingSetCardData[];
}) => {
  const queryForCard = `div.${styles.pinnedCard}`;
  const {
    expanded,
    setExpanded,
    showMore,
    numVisible,
    wrapperRef,
    setShowMore,
  } = useDynamicExpandableContent(queryForCard);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const [observedVisibleCards, setObservedVisibleCards] = useState(0);

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    const mutationObserver = new MutationObserver(() => {
      const scrollHeight = wrapperRef.current?.scrollHeight || 0;
      const height = wrapperRef.current?.clientHeight || 0;

      // Show more button if content height is greater than wrapper height
      if (scrollHeight > height) {
        setShowMore(true);
      } else {
        setShowMore(false);
      }
      setObservedVisibleCards(computeVisibleStacks(wrapperRef, queryForCard));
    });
    mutationObserver.observe(wrapperRef.current, { childList: true });
    return () => {
      mutationObserver.disconnect();
    };
  }, [expanded, queryForCard, setShowMore, wrapperRef]);

  const pinnedStacks = useMemo(() => {
    return allPinnedStacks.slice(0, 2 * MAX_VISIBLE_COUNT);
  }, [allPinnedStacks]);

  if (pinnedStacks.length === 0) {
    return null;
  }

  const handleToggle = () => {
    if (expanded) {
      reportPapEvent(
        PAP_Collapse_DashCard({
          featureLine: 'stacks',
          actionSurfaceComponent: 'stacks',
          dashCardType: 'pinned_stacks',
        }),
      );
    } else {
      reportPapEvent(
        PAP_Expand_DashCard({
          featureLine: 'stacks',
          actionSurfaceComponent: 'stacks',
          dashCardType: 'pinned_stacks',
        }),
      );
    }
    setExpanded(!expanded);
  };

  return (
    <Split direction="vertical" gap="8">
      <Split.Item>
        <ModuleHeader
          title={i18n.t('pinned_stacks')}
          actions={
            (showMore || expanded) && (
              <Button variant="transparent" size="small" onClick={handleToggle}>
                {i18n.t(expanded ? 'show_less_answer' : 'show_more_answer')}
              </Button>
            )
          }
        />
      </Split.Item>
      <Split.Item>
        <TileCardGrid
          expanded={expanded}
          className={styles.pinnedWrapper}
          tileCardClassName={styles.pinnedCard}
          isLoading={false}
          ref={wrapperRef}
        >
          {pinnedStacks.map((stackData, i) => (
            <PinnedStackCard
              stackData={stackData}
              key={stackData.id}
              // Need to hide card so we aren't able to tab to cards hidden by overflow
              hidden={i >= Math.max(numVisible, observedVisibleCards)}
            />
          ))}
        </TileCardGrid>
      </Split.Item>
    </Split>
  );
};

const actionSurfaceComponent = 'stacks';
const featureLine = 'stacks';

const PinnedStackCard = ({
  stackData,
  hidden,
  style,
}: {
  stackData: WorkingSetCardData;
  hidden?: boolean;
  style?: CSSProperties;
}) => {
  const hasMultipleMembers = stackData.memberCount > 1;
  return (
    <TileCard
      className={styles.pinnedCard}
      hidden={hidden}
      interactiveAccessoryClassName={styles.interactiveAccessory}
      onClick={stackData.onClickCard}
      clickPath={`/stacks/${stackData.id}`}
      titleLinesBeforeTruncate={2}
      style={style}
      icon={
        <DashStackIcon
          size="medium"
          colorIndex={stackData.colorIndex}
          emoji={stackData.emoji}
        />
      }
      title={stackData.title}
      withAccessory={
        <IconButtonWithTooltip
          tooltipProps={{
            title: i18n.t('unpin_stack'),
          }}
          variant="borderless"
          onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            await toggleStackIsPinned(stackData.namespaceId);
          }}
          data-testid="PinStack"
          aria-label={i18n.t('unpin')}
          data-uxa-log={createUxaElementId('pin_button', {
            actionSurfaceComponent,
            featureLine,
          })}
          data-uxa-entity-id={stackData.namespaceId}
          size="medium"
        >
          <Text color="subtle" className={styles.pinButtonIcon}>
            <UIIcon src={PinFill} />
          </Text>
        </IconButtonWithTooltip>
      }
      withInteractiveAccessory
      metadata={
        <Metadata
          size="small"
          withDividers="blank"
          className={classnames(
            styles.pinnedCardMetadata,
            !hasMultipleMembers && styles.accessIcon,
          )}
        >
          {hasMultipleMembers ? (
            <Metadata.Item>
              {stackData.getMembersComponent('small')}
            </Metadata.Item>
          ) : (
            <>
              <Metadata.Item>
                <UIIcon src={stackData.accessLevelIcon} />
              </Metadata.Item>
              <Metadata.Item>
                <Text color="subtle">{stackData.accessLevel}</Text>
              </Metadata.Item>
            </>
          )}
        </Metadata>
      }
    />
  );
};
