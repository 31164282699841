import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { DashFilterName } from '@mirage/analytics/events/enums/dash_filter_name';
import { PAP_Select_DashFilter } from '@mirage/analytics/events/types/select_dash_filter';
import {
  focusResultAtIdx,
  focusSearchInput,
} from '@mirage/mosaics/GlobalNav/KeyboardNavigation';
import { FilterBar } from '@mirage/search';
import { getContentTypeDropdownVariant } from '@mirage/search/Header/ContentTypeFilter/ContentTypeFilterDropdown';
import { useActivationForConnectors } from '@mirage/service-connectors/useActivationForConnectors';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { ConnectorIcon } from '@mirage/shared/icons';
import {
  ContentTypeFilter,
  getConnectorFilters,
  getContentTypeFilters,
  getLastUpdatedFilter,
  getPersonFilter,
  merge,
  PersonFilter,
  SearchFilter,
  sortDisplayFilters,
} from '@mirage/shared/search/search-filters';
import { KeyCodes } from '@mirage/shared/util/constants';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';

import type { Connector } from '@mirage/service-dbx-api/service';
import type {
  ConnectorFilter,
  LastUpdatedFilter,
} from '@mirage/shared/search/search-filters';

type Props = {
  connectors: Connector[];
  activeFilters?: SearchFilter[];
  onFilterChange?: (filters: SearchFilter[]) => void;
};

interface Filter {
  key: string;
  label: string;
  icon: JSX.Element;
  onSelect: () => void;
  selected: boolean;
}

export default function SearchFilters({
  connectors = [],
  activeFilters = [],
  onFilterChange,
}: Props) {
  const isPersonFilterEnabled =
    useFeatureFlagValue('dash_2024_06_20_search_people_filter') === 'ON';
  const contentTypeFilterExperimentValue = useFeatureFlagValue(
    'dash_2024_07_08_search_content_type_filter',
  );

  // active connector filters
  const [activeConnectorFilters, setActiveConnectorFilters] = useState<
    ConnectorFilter[]
  >(getConnectorFilters(activeFilters));

  // active last updated filter, one or none selected
  const [activeUpdatedAtFilter, setActiveUpdatedAtFilter] = useState<
    LastUpdatedFilter | undefined
  >(getLastUpdatedFilter(activeFilters));

  // active person filter, one or none selected
  const [activePersonFilter, setActivePersonFilter] = useState<
    PersonFilter | undefined
  >(getPersonFilter(activeFilters));

  const [activeContentTypeFilters, setActiveContentTypeFilters] = useState<
    ContentTypeFilter[]
  >(getContentTypeFilters(activeFilters));

  useEffect(() => {
    setActiveConnectorFilters(getConnectorFilters(activeFilters));
    setActiveContentTypeFilters(getContentTypeFilters(activeFilters));
    setActiveUpdatedAtFilter(getLastUpdatedFilter(activeFilters));
    setActivePersonFilter(getPersonFilter(activeFilters));
  }, [activeFilters]);

  const { noConnectors, someConnectors } = useActivationForConnectors();

  function clearConnectorFilters() {
    setActiveConnectorFilters([]);
    if (onFilterChange) {
      onFilterChange(
        merge(
          [],
          activeUpdatedAtFilter,
          activePersonFilter,
          activeContentTypeFilters,
        ),
      );
    }
  }

  function clearContentTypeFilters() {
    setActiveContentTypeFilters([]);
    if (onFilterChange) {
      onFilterChange(
        merge(
          activeConnectorFilters,
          activeUpdatedAtFilter,
          activePersonFilter,
          [],
        ),
      );
    }
  }

  function clearAllFilters() {
    setActiveConnectorFilters([]);
    setActiveUpdatedAtFilter(undefined);
    setActivePersonFilter(undefined);
    if (onFilterChange) {
      onFilterChange(merge([], undefined, undefined, []));
    }
  }

  function isActiveConnectorFilter(filter: ConnectorFilter) {
    return activeConnectorFilters.some((afilter) => isEqual(afilter, filter));
  }

  const isActiveContentTypeFilter = (filter: ContentTypeFilter) =>
    activeContentTypeFilters.some((afilter) => afilter.id === filter.id);

  function toggleActiveConnectorFilters(targetFilter: ConnectorFilter) {
    let updatedFilters;

    // toggle off an already selected filter
    if (isActiveConnectorFilter(targetFilter)) {
      updatedFilters = activeConnectorFilters.filter((afilter) => {
        return !isEqual(afilter, targetFilter);
      });
    }
    // toggle on filter
    else {
      updatedFilters = [...activeConnectorFilters];
      updatedFilters.push(targetFilter);
    }

    setActiveConnectorFilters(updatedFilters);

    if (onFilterChange) {
      onFilterChange(
        merge(
          updatedFilters,
          activeUpdatedAtFilter,
          activePersonFilter,
          activeContentTypeFilters,
        ),
      );
    }
  }

  function toggleActiveContentTypeFilters(
    targetFilter: ContentTypeFilter | undefined,
  ) {
    const contentTypeDropdownVariant = getContentTypeDropdownVariant(
      contentTypeFilterExperimentValue,
    );
    let updatedFilters: ContentTypeFilter[] = [];

    if (!targetFilter) {
      // Clear the selected filter
      logFilterSelected({
        dashFilterName: 'file_type_any',
      });
    } else if (contentTypeDropdownVariant === 'multi_select') {
      // toggle off already selected filter
      if (isActiveContentTypeFilter(targetFilter)) {
        updatedFilters = activeContentTypeFilters.filter((afilter) => {
          return !isEqual(afilter, targetFilter);
        });
      }
      //toggle on filter
      else {
        logFilterSelected({
          dashFilterName: targetFilter.id as DashFilterName,
        });
        updatedFilters = [...activeContentTypeFilters];
        updatedFilters.push(targetFilter);
      }
    } else if (!isActiveContentTypeFilter(targetFilter)) {
      // For single-select
      logFilterSelected({
        dashFilterName: targetFilter.id as DashFilterName,
      });
      updatedFilters = [targetFilter];
    }

    setActiveContentTypeFilters(updatedFilters);
    if (onFilterChange) {
      onFilterChange(
        merge(
          activeConnectorFilters,
          activeUpdatedAtFilter,
          activePersonFilter,
          updatedFilters,
        ),
      );
    }
  }

  function handleLastUpdatedFilterSelection(
    updatedAtFiler: LastUpdatedFilter | undefined,
  ) {
    setActiveUpdatedAtFilter(updatedAtFiler);

    if (onFilterChange) {
      onFilterChange(
        merge(
          activeConnectorFilters,
          updatedAtFiler,
          activePersonFilter,
          activeContentTypeFilters,
        ),
      );
    }
  }

  function handlePersonFilterSelection(
    _person: PersonFilter | undefined,
    peopleSearchQuery: string,
  ) {
    let personFilter = _person;

    // Clear the filter if parameters are undefined (person is deselected)
    if (!personFilter?.parameters) {
      personFilter = undefined;
    }

    if (personFilter) {
      logFilterSelected({
        dashFilterName: 'people',
        peopleSearchQuery,
        peopleSelected: personFilter?.parameters?.email,
      });
    }

    setActivePersonFilter(personFilter);
    if (onFilterChange) {
      onFilterChange(
        merge(
          activeConnectorFilters,
          activeUpdatedAtFilter,
          personFilter,
          activeContentTypeFilters,
        ),
      );
    }
  }

  const { reportPapEvent, searchAttemptSessionManager, searchSessionManager } =
    useMirageAnalyticsContext();

  function logFilterSelected({
    dashFilterName,
    peopleSearchQuery,
    peopleSelected,
  }: {
    dashFilterName: DashFilterName;
    peopleSearchQuery?: string;
    peopleSelected?: string;
  }) {
    let hasQuery = false;
    searchSessionManager.extendOrCreateSession('select_filter');
    const searchAttemptSession = searchAttemptSessionManager.getSession();
    if (searchAttemptSession) {
      const query = searchAttemptSession?.properties.query;
      hasQuery = query?.length > 0;

      if (hasQuery) {
        searchSessionManager.updateProperties({
          hasQuery: true,
        });
      }
    }
    reportPapEvent(
      PAP_Select_DashFilter({
        dashFilterName,
        dashFilterType: 'filter_pill',
        hasQuery,
        isTypeahead: false,
        featureLine: 'search',
        actionSurface: 'serp',
        actionSurfaceComponent: 'search_bar',
        peopleSearchQuery,
        peopleSelected,
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      }),
    );
  }

  const getOrderedConnectorFilters = useCallback(() => {
    const result = connectors
      .filter((connector: Connector) => connector.id_attrs?.type)
      .map((connector: Connector) => {
        if (
          !connector.id_attrs?.type ||
          !connector.branding?.display_name ||
          !connector.branding?.icon_src
        ) {
          return;
        }

        const connectorName = connector.id_attrs.type;
        const connectorLabel = connector.branding.display_name;
        const connectorIcon = connector.branding.icon_src;

        const filterDefinition = {
          id: connectorName,
          type: 'connector',
          parameters: {
            connectorId: connectorName,
          },
        } as ConnectorFilter;

        const isSelected = isActiveConnectorFilter(filterDefinition);

        const toggleFilter = () => {
          toggleActiveConnectorFilters(filterDefinition);
          if (!isSelected) {
            logFilterSelected({
              dashFilterName: connectorName as DashFilterName,
            });
          }
        };

        return {
          key: connectorName,
          label: connectorLabel,
          icon: (
            <ConnectorIcon
              connectorName={connectorName}
              iconUrl={connectorIcon}
              size={16}
            />
          ),
          onSelect: () => {
            toggleFilter();
          },
          onKeyDown: (e: React.KeyboardEvent) => {
            switch (e.key) {
              case KeyCodes.arrowDown:
                focusResultAtIdx(0);
                break;
              case KeyCodes.arrowUp:
                focusSearchInput();
                break;
              case KeyCodes.enter:
              case KeyCodes.space:
                toggleFilter();
                break;
            }
          },
          selected: isSelected,
        };
      })

      .filter(Boolean) as Filter[];

    // sort alphabetically
    return sortDisplayFilters(result);
  }, [connectors, isActiveConnectorFilter]);

  return (
    <FilterBar
      connectorFilters={getOrderedConnectorFilters()}
      activeFilters={activeFilters}
      displayConnectMoreApps={noConnectors || someConnectors}
      onLastUpdatedFilterSelect={handleLastUpdatedFilterSelection}
      onContentTypeFilterSelect={toggleActiveContentTypeFilters}
      onPersonFilterSelect={handlePersonFilterSelection}
      activePersonFilter={activePersonFilter}
      activeUpdatedAtFilter={activeUpdatedAtFilter}
      activeContentTypeFilters={activeContentTypeFilters}
      clearFilters={clearAllFilters}
      clearConnectorFilters={clearConnectorFilters}
      clearContentTypeFilters={clearContentTypeFilters}
      isPersonFilterEnabled={isPersonFilterEnabled}
      contentTypeFilterExperimentValue={contentTypeFilterExperimentValue}
    />
  );
}
