import { tagged } from '@mirage/service-logging';
import * as primitives from '@mirage/service-typeahead-search/service/primitives';
import * as scoring from '@mirage/service-typeahead-search/service/scoring';
import {
  CacheKey,
  TypeaheadCache,
} from '@mirage/service-typeahead-search/service/typeahead-cache';
import { SourceId } from '@mirage/service-typeahead-search/service/types';
import * as wrappers from '@mirage/service-typeahead-search/service/utils/wrappers';
import tokenize from '@mirage/shared/search/tokenizers';
import * as rx from 'rxjs';

import type { typeahead } from '@mirage/service-typeahead-search/service/types';
import type { Recommendation } from '@mirage/shared/search/recommendation';
import type { Observable } from 'rxjs';

const logger = tagged('typeahead/recommendations');

const SOURCE_RESULT_LIMIT = 20;

/**
 * API
 */

export const search = wrappers.wrapped(SourceId.Recommendations, raw);

export function raw(
  query: string,
  cache: TypeaheadCache,
): Observable<typeahead.TaggedResult> {
  return rx.defer(() => {
    let results = cache.all(CacheKey.Recommendations) as Recommendation[];
    results = removeContacts(results);

    if (query === '') {
      logger.debug(`Getting matches with empty query`);
      results = matchesWithEmptyQuery(results);
    } else {
      results = matchesWithQuery(query, results);
    }

    return rx.from(tagAndInjectHits(results, cache));
  });
}

/**
 * Match with query
 */

function matchesWithQuery(
  query: string,
  recommendations: Recommendation[],
): Recommendation[] {
  // First get exact matches
  const exactMatches = getExactMatches(
    query,
    recommendations,
    SOURCE_RESULT_LIMIT,
  );

  const DEBUG = false;
  DEBUG satisfies false;

  // Return early if we've hit the limit (for performance reasons)
  if (exactMatches.length === SOURCE_RESULT_LIMIT) {
    if (DEBUG) {
      logger.debug(
        `exactMatches:`,
        exactMatches.length,
        'tyopoMatches: N/A, limit hit with exact matches',
      );
    }
    return exactMatches;
  }

  // Search remaining for typo matches, still stop when we hit
  // SOURCE_RESULT_LIMIT
  const typoMatchesLimit = SOURCE_RESULT_LIMIT - exactMatches.length;
  const typoRemainingCandidates = recommendations.filter((candidate) => {
    const exactMatchesUuids = exactMatches.map((match) => match.uuid);
    if (exactMatchesUuids.includes(candidate.uuid)) return false;
    return true;
  });
  const typoMatches = getTypoMatches(
    query,
    typoRemainingCandidates,
    typoMatchesLimit,
  );

  if (DEBUG) {
    logger.debug(
      `exactMatches:`,
      exactMatches.length,
      `typoMatches:`,
      typoMatches.length,
    );
  }

  // Exact matches go first, otherwise we create a wierd user experience where
  // typo result suddenly get mixed in when TYPO_EVALUATION_THRESHOLD is
  // exceeded
  const combined = [...exactMatches, ...typoMatches] as Recommendation[];

  if (DEBUG) logger.debug(`combined`, combined.slice(0, 3));

  return combined;
}

function getExactMatches(
  query: string,
  recommendations: Recommendation[],
  limit: number,
): Recommendation[] {
  // lowercase query
  const lcquery = query.toLowerCase();

  const results: Recommendation[] = [];

  for (const recommendation of recommendations) {
    // exact substring matching
    if (recommendation.title.toLowerCase().includes(lcquery)) {
      results.push(recommendation);
    }

    // For optimal performance, stop searching at `limit`
    if (results.length === limit) return results;
  }

  return results;
}

function getTypoMatches(
  query: string,
  recommendations: Recommendation[],
  limit: number,
): Recommendation[] {
  const results: Recommendation[] = [];

  for (const recommendation of recommendations) {
    // tokenize query
    const qtokens = tokenize(query.toLowerCase());

    // tokenize title
    const ttokens = tokenize(recommendation.title.toLowerCase());

    // allow typos in search via token similarity
    if (scoring.typoMatch(qtokens, ttokens)) {
      results.push(recommendation);
    }

    // For optimal performance, stop searching at `limit`
    if (results.length === limit) return results;
  }

  return results;
}

/**
 * Matches with empty query
 */

function matchesWithEmptyQuery(
  recommendations: Recommendation[],
): Recommendation[] {
  return sortLastClickedMsDesc(recommendations);
}

function sortLastClickedMsDesc(
  recommendations: Recommendation[],
): Recommendation[] {
  const sorted = recommendations.sort(
    (a: Recommendation, b: Recommendation) =>
      (b.lastClickedMs || 0) - (a.lastClickedMs || 0),
  );
  const limited = sorted.slice(0, SOURCE_RESULT_LIMIT);
  return limited;
}

/**
 * Helpers
 */

function removeContacts(candidates: Recommendation[]): Recommendation[] {
  return candidates.filter(
    (candidate) => candidate.recordType?.['.tag'] !== 'contact',
  );
}

function tagAndInjectHits(results: Recommendation[], cache: TypeaheadCache) {
  const scoreWithoutQuery = scoring.score('');
  return results.map((result) => {
    // we can have click tracking from the server through recommendations,
    // and we can have click tracking locally. The server can be delayed,
    // so in order to provide the best user experience, we use whichever
    // source has the highest non-title-match score for the recommendation
    // that way when the server catches up, it'll just start transparently
    // using the server-side score over the client-side score

    const resultWithLocalHits = primitives.recommendation(
      result.uuid,
      result,
      cache.getHits(result.uuid),
    );
    const resultWithRemoteHits = primitives.recommendation(
      result.uuid,
      result,
      {
        history: result.clicks,
        mostRecentMs: result.lastClickedMs,
      },
    );

    const localScore = scoreWithoutQuery(resultWithLocalHits).score;
    const remoteScore = scoreWithoutQuery(resultWithRemoteHits).score;

    return localScore > remoteScore
      ? resultWithLocalHits
      : resultWithRemoteHits;
  });
}
