// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3011
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'select.dash_search_result_labels'
// Description: Event captured when our (internal) users submit labels for the search results. We use this information for creating a golden dataset to train our rankers.
// Owner: otc-search-infra
export type Select_DashSearchResultLabels = PAPEventGeneric<
  'dash',
  'select',
  'dash_search_result_labels',
  {
    // unique id for a /dash/search query. Used by the ML team to get feedback on search result ranking
    searchRequestId?: string;
    // Unique identifier (UUIDv5 ) of the search result
    resultUuid?: string;
    // The zero-index returned position of search result in a list of search results.
    resultPosition?: number;
    // score in a feedback form
    feedbackScore?: number;
    // The result most desired by the user.
    goldenResult?: string;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // The contents of a search query
    queryString?: string;
  }
>;

export function PAP_Select_DashSearchResultLabels(
  properties?: Select_DashSearchResultLabels['properties'],
): Select_DashSearchResultLabels {
  return <Select_DashSearchResultLabels>{
    class: 'dash',
    action: 'select',
    object: 'dash_search_result_labels',
    properties,
  };
}
