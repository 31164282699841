import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { fileTypeScore } from './components/file-type';
import { frequentlyClickedScore } from './components/frequently-clicked';
import { lastBrowserViewedScore } from './components/last-browser-viewed';
import { lastClickedScore } from './components/last-clicked';
import { titleMatchScore } from './components/title-match';
import { getScore as getSuggestedQueryScore } from './suggested-query-score';
import { proportion } from './utils';
import { getWeights } from './weights';

export function score(query: string) {
  return function layered(
    result: typeahead.TaggedResult,
  ): typeahead.ScoredResult {
    if (result.type === typeahead.ResultType.SuggestedQuery) {
      return suggestedQueryScoredResult(result);
    }

    const scores: typeahead.Scores = {
      titleMatchScore: query === '' ? null : titleMatchScore(query, result),
      lastClickedScore: lastClickedScore(result),
      frequentlyClickedScore: frequentlyClickedScore(result),
      fileTypeScore: fileTypeScore(result),
      lastBrowserViewedScore: lastBrowserViewedScore(result),
    };

    return {
      ...result,
      ...buildFinalScore(scores),
    };
  };
}

/**
 * builds the final score and score components.
 *
 * this will zero out the weights for any components that have a null score!
 */
function buildFinalScore(scores: typeahead.Scores) {
  const weights = { ...getWeights() };

  for (const key of Object.keys(scores) as typeahead.WeightKey[]) {
    if (scores[key] == null) {
      weights[key] = 0;
    }
  }
  const nullableScoreComponents: [number | null, number][] = [
    [scores.titleMatchScore, weights.titleMatchScore],
    [scores.lastClickedScore, weights.lastClickedScore],
    [scores.frequentlyClickedScore, weights.frequentlyClickedScore],
    [scores.fileTypeScore, weights.fileTypeScore],
    [scores.lastBrowserViewedScore, weights.lastBrowserViewedScore],
  ];

  const components = nullableScoreComponents.map<typeahead.Components[number]>(
    ([score, weight]) => [score ?? 0, weight],
  ) as typeahead.Components;

  const score = proportion(components);

  return {
    score,
    weights,
    scores,
    components,
  };
}

function suggestedQueryScoredResult(
  result: typeahead.TaggedResult,
): typeahead.ScoredResult {
  return {
    ...result,
    score: getSuggestedQueryScore(),
    scores: {
      titleMatchScore: null,
      lastClickedScore: null,
      frequentlyClickedScore: null,
      fileTypeScore: null,
      lastBrowserViewedScore: null,
    },
    weights: {
      titleMatchScore: 0,
      lastClickedScore: 0,
      frequentlyClickedScore: 0,
      fileTypeScore: 0,
      lastBrowserViewedScore: 0,
    },
    components: [],
  };
}
