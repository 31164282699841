import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { ConsolaInstance } from 'consola';
import { Observable, Subject } from 'rxjs';

export interface Service {
  logout(): Promise<void>;
  listenForLogout(service: ServiceId): Observable<boolean>;
}

export type ServiceAdapter = {
  logout(): Promise<void>;
};

interface LoggingServiceContract {
  tagged: (tag: string) => ConsolaInstance;
}

export default function logoutService(
  loggingService: LoggingServiceContract,
  adapter: ServiceAdapter,
) {
  const logger = loggingService.tagged('logout-service');
  const subject = new Subject<boolean>();

  async function logout() {
    logger.debug('Logout called');
    subject.next(true);
    return adapter.logout();
  }

  function listenForLogout(service: ServiceId) {
    logger.debug(`Registering ${service} for logout`);
    return subject.asObservable();
  }

  const service = {
    logout,
    listenForLogout,
  };

  return services.provide<Service>(ServiceId.LOGOUT, service, []);
}
