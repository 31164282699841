import { Chip } from '@dropbox/dig-components/chip';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine } from '@dropbox/dig-icons/assets';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { FeatureValue } from '@mirage/service-experimentation/features';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { AppFilterDropdown } from './AppFilterDropdown';
import {
  ContentTypeFilterDropdown,
  getContentTypeDropdownVariant,
} from './ContentTypeFilter/ContentTypeFilterDropdown';
import styles from './FilterBar.module.css';
import { LastUpdatedFilterDropdown } from './LastUpdatedFilter';
import { PersonFilterDropdown } from './PersonFilterDropdown';
import { FilterProps } from './types';

import type {
  ContentTypeFilter,
  LastUpdatedFilter,
  PersonFilter,
  SearchFilter,
} from '@mirage/shared/search/search-filters';

type Props = {
  connectorFilters?: Array<FilterProps>;
  activeFilters?: SearchFilter[];
  disabled?: boolean;
  displayConnectMoreApps?: boolean;
  onLastUpdatedFilterSelect: (filter: LastUpdatedFilter | undefined) => void;
  onContentTypeFilterSelect: (filter: ContentTypeFilter | undefined) => void;
  onPersonFilterSelect: (
    filter: PersonFilter | undefined,
    query: string,
  ) => void;
  activeUpdatedAtFilter: LastUpdatedFilter | undefined;
  activePersonFilter: PersonFilter | undefined;
  activeContentTypeFilters: ContentTypeFilter[];
  clearFilters?: () => void;
  clearConnectorFilters?: () => void;
  clearContentTypeFilters: () => void;
  isPersonFilterEnabled: boolean;
  contentTypeFilterExperimentValue: FeatureValue;
};

export default function FilterBar({
  connectorFilters = [],
  activeFilters = [],
  onLastUpdatedFilterSelect,
  onContentTypeFilterSelect,
  onPersonFilterSelect,
  activeUpdatedAtFilter,
  activePersonFilter,
  activeContentTypeFilters,
  clearFilters,
  clearConnectorFilters,
  clearContentTypeFilters,
  isPersonFilterEnabled,
  contentTypeFilterExperimentValue,
}: Props) {
  const anyFilterActive = activeFilters.length > 0;
  const isMobileSize = useIsMobileSize();
  const showClearAll = anyFilterActive;

  const isContentTypeFilterEnabled =
    contentTypeFilterExperimentValue === 'V1' ||
    contentTypeFilterExperimentValue === 'V2';

  return (
    <div
      className={classnames(styles.filterBarContainer, {
        [styles.isMobile]: isMobileSize,
      })}
    >
      {showClearAll && (
        <DigTooltip title={i18n.t('clear_filters')} placement="bottom">
          <Chip
            className={styles.clearFiltersChip}
            onClick={clearFilters}
            data-uxa-log={createUxaElementId('clear_filters_button', {
              actionSurfaceComponent: 'filter_chip',
              featureLine: 'search',
            })}
          >
            <Chip.IconAccessory>
              <UIIcon src={CloseLine} />
            </Chip.IconAccessory>
          </Chip>
        </DigTooltip>
      )}

      <div
        className={classnames(styles.filterChipContainer, {
          [styles.isMobile]: isMobileSize,
        })}
      >
        <AppFilterDropdown
          filters={connectorFilters}
          onDeselectAll={() => clearConnectorFilters?.()}
        />
        {isContentTypeFilterEnabled && (
          <ContentTypeFilterDropdown
            onDeselectAll={() => clearContentTypeFilters()}
            activeFilters={activeContentTypeFilters}
            onSelect={onContentTypeFilterSelect}
            selected={!!activeContentTypeFilters.length}
            variant={getContentTypeDropdownVariant(
              contentTypeFilterExperimentValue,
            )}
          />
        )}
        <LastUpdatedFilterDropdown
          onSelect={onLastUpdatedFilterSelect}
          selected={!!activeUpdatedAtFilter}
          activeUpdatedAtFilter={activeUpdatedAtFilter}
        />
        {isPersonFilterEnabled && (
          <PersonFilterDropdown
            onSelect={onPersonFilterSelect}
            selectedPerson={activePersonFilter}
          />
        )}
      </div>
    </div>
  );
}
