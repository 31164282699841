import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';

export interface Service {
  openURL(url: string): Promise<void>;

  // WARN: Only use for local apps and local files. Be weary of untrusted user
  // input! Electron `openExternal` does no sanitization!
  openLocalPath(path: string): Promise<void>;

  copyToClipboard(text: string): Promise<void>;
}

export type PlatformActionMode = 'direct' | 'background';

export interface PlatformActionsAdapter extends Service {
  /**
   * Sets the action mode for each app.
   * - Electron: background
   * - Webapp: direct
   */
  getActionMode(): PlatformActionMode;
}

let syncService: PlatformActionsAdapter | undefined;

export function getSyncService() {
  return syncService;
}

export default function platformActions(adapter: PlatformActionsAdapter) {
  if (adapter.getActionMode() === 'direct') {
    syncService = adapter;
  }

  services.provide<Service>(ServiceId.PLATFORM_ACTIONS, adapter, []);
}
