export type ExperimentSource = 'growthbook' | 'stormcrow';

export const StormcrowValues = [
  'OFF',
  'ON',
  'CONTROL',
  'V1',
  'V2',
  'V3',
] as const;

// This is a common list for ON values, but is by no means exhaustive.
export const FEATURE_ON_STRING_VALUES: FeatureValue[] = [
  'ON',
  'V1',
  'V2',
  'V3',
];

// This is a common list for OFF values, but is by no means exhaustive.
export const FEATURE_OFF_STRING_VALUES: FeatureValue[] = ['OFF', 'CONTROL'];

/**
 * Note that the server can put any value, so this list might not always be
 * correct. If any exceptions, we'll let the dev handle special cases.
 */
export type FeatureStringValue = (typeof StormcrowValues)[number];

export type FeatureValue =
  | FeatureStringValue
  | boolean
  | number
  | object
  | undefined;

type _FeatureFlag<PossibleFeatureNames extends string> = {
  source: ExperimentSource;

  // Value of the feature.
  value?: FeatureValue;
  featureName: PossibleFeatureNames;
  userOverrideDisabled?: boolean;
  overrideValue?: FeatureValue;
};

export const featuresList = [
  {
    featureName: 'test_feature_ring_internal_team',
    source: 'growthbook',
    userOverrideDisabled: true,
  },
  {
    featureName: 'test_feature_ring_internal_dash',
    source: 'growthbook',
    userOverrideDisabled: true,
  },
  {
    featureName: 'test_feature_ring_internal_dbx',
    source: 'growthbook',
    userOverrideDisabled: true,
  },
  {
    featureName: 'test_feature_ring_external_beta',
    source: 'growthbook',
    userOverrideDisabled: true,
  },
  {
    featureName: 'test_feature_ring_lts',
    source: 'growthbook',
    userOverrideDisabled: true,
  },

  {
    featureName: 'dash_2024_05_15_typeahead_filter_out_events',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_06_05_august_revision',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_06_14_app_feedback',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_06_24_search_summary',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_06_27_local_typeahead_stack_items',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_07_29_expose_summarize_action',
    source: 'growthbook',
  },
  {
    featureName: 'dash_answers_suggested_questions',
    source: 'growthbook',
  },
  {
    featureName: 'dash_answers_multiturn',
    source: 'growthbook',
  },
  {
    featureName: 'dash_question_suggestions',
    source: 'stormcrow',
  },
  {
    featureName: 'dash_fullstory',
    source: 'growthbook',
  },
  {
    featureName: 'dash_research_sentiment',
    source: 'stormcrow',
  },
  {
    featureName: 'dash_server_result_pinning_threshold',
    source: 'growthbook',
  },
  {
    featureName: 'dash_typeahead_source_recent_files',
    source: 'growthbook',
  },
  {
    featureName: 'dash_typeahead_source_local_apps',
    source: 'growthbook',
  },
  {
    featureName: 'dash_typeahead_2024_06_05_suggested_query_score',
    source: 'growthbook',
  },
  {
    featureName: 'dash_typeahead_weights',
    source: 'growthbook',
  },
  {
    featureName: 'ask_dash_button',
    source: 'stormcrow',
  },
  {
    featureName: 'dash_enable_app_type_option',
    source: 'stormcrow',
  },
  {
    featureName: 'dash_link_description',
    source: 'growthbook',
  },
  {
    featureName: 'dash_search_source_local_files',
    source: 'growthbook',
  },
  {
    featureName: 'dash_use_dash_components',
    source: 'growthbook',
  },
  {
    featureName: 'dash_preview_suggested_stacks',
    source: 'growthbook',
  },
  {
    featureName: 'dash_extension_local_webapp',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2024_05_09_redirect_dropboxers_staging',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_05_17_startpage_connector_modules',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2024_05_23_sharing_modal_update',
    source: 'growthbook',
  },
  {
    featureName: 'dash_for_business_enabled',
    source: 'stormcrow',
  },
  {
    featureName: 'dash_2024_05_10_preview_public_stacks',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2024_05_23_archive_stacks',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_06_10_stack_item_summary',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_06_13_activity_feed',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_06_18_cloud_docs',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_06_20_search_people_filter',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2024_07_03_clone_stacks',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_06_19_share_stacks_with_groups',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_07_04_start_page_august_revision',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_07_04_all_stacks_august_revision',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_07_04_stack_page_august_revision',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_07_08_search_content_type_filter',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_07_23_typeahead_launch_indicator',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_07_24_retrieve_all_day_calendar_events',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_07_29_typeahead_null_state_uses_recommendations',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_07_29_link_enrichment',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_08_01_activity_feed_filters',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_08_01_activity_feed_filter_applications',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_08_01_activity_feed_filter_only_mine',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_08_01_activity_feed_filter_people',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_08_06_recents_data_source',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_08_09_slotted_search_ranking',
    source: 'growthbook',
  },
] as const satisfies Readonly<_FeatureFlag<string>[]>;

/** A union of all possible feature names */
export type FeatureName = (typeof featuresList)[number]['featureName'];

/** A fully typed feature flag, only allows valid featureNames */
export type FeatureFlag = _FeatureFlag<FeatureName>;

/** A full record of all feature flags by name */
export type FeatureFlags = Record<FeatureName, FeatureFlag>;

export const features = featuresList.reduce(
  (acc, feature) => ({
    ...acc,
    [feature.featureName]: feature,
  }),
  {} as FeatureFlags,
);

// Checks if there are any new feature flags added in the `newFlags` array
// compared to the `oldFlags` array.
export const areNewFlagsAdded = (
  oldFlags: FeatureFlag[],
  newFlags: FeatureFlag[],
) => {
  const oldFlagSet = new Set(oldFlags.map((item) => item.featureName));

  for (const flag of newFlags) {
    if (!oldFlagSet.has(flag.featureName)) {
      return true;
    }
  }

  return false;
};
