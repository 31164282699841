import { ChatEntryPoint } from '@mirage/conversations/types';
import { SearchResult } from '@mirage/service-dbx-api';
import { AnswerResponse } from '@mirage/service-dbx-api/service/answers';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export type DynamicPanelPapProperties = {
  entryPoint: ChatEntryPoint;

  // Search result related properties used for ML purposes
  resultPosition?: number;
  resultUuid?: string;

  // ID from search API, used for ML logging purposes
  searchRequestId?: string;
};

/**
 * For now we're switching the panel view and replacing the entire contents on the
 * panel based on this state. If we want this to be more flexible in the future we may want to
 * consider the atom holding a series of panel nodes that can be configured that could be
 * easier for building back/forth interactions with an agent or maintaining history. For now though,
 * this is simpler.
 */
export type DynamicPanelData = {
  answers?: {
    pap?: DynamicPanelPapProperties | null;
    attachment?: SearchResult;

    initialQuery?: string;
    initialAnswer?: AnswerResponse;

    isSummarize?: boolean;
  };
};

export const DEFAULT_PANEL_DATA: DynamicPanelData = {};

export type DynamicPanelView = keyof DynamicPanelData | 'closed';

export const DEFAULT_PANEL_VIEW: DynamicPanelView = 'closed';

// Do not export, use `dynamicPanelDataAtom` instead
const dynamicPanelDataStateAtom = atom<DynamicPanelData>(DEFAULT_PANEL_DATA);

export const dynamicPanelViewAtom = atom<DynamicPanelView>(DEFAULT_PANEL_VIEW);

/**
 * This atom is used to track whether the user has interacted with the dynamic panel.
 * If they haven't, we will show the panel automatically if there are suggested stacks
 */
export const interactedWithDynamicPanelAtom = atomWithStorage<boolean>(
  'interactedWithDynamicPanel',
  false,
);

/** See `DynamicPanel` */
export const dynamicPanelDataAtom = atom(
  // Read from dynamicPanelDataStateAtom
  (get) => get(dynamicPanelDataStateAtom),
  (
    get,
    set,
    update: {
      key: keyof DynamicPanelData;
      value: DynamicPanelData[keyof DynamicPanelData];
    },
  ) => {
    // Get the current state from dynamicPanelDataStateAtom
    const currentPanelData = get(dynamicPanelDataStateAtom);
    set(dynamicPanelDataStateAtom, {
      // Set the updated state to dynamicPanelDataStateAtom
      ...currentPanelData,
      [update.key]: update.value, // Update the specific key with the new value
    });
  },
);
