// Scout specific root pages.
const SCOUT_START_PAGE_PATHNAMES = [`/index.html`, `/start.html`];

export function getRoutePathname() {
  // We have to rely on hash in Hornet and Scout, as pathname is always '/' in
  // electron renderer, and could be '/start.html' or '/index.html' in Scout.
  const hash = window.location.hash;
  let pathname = window.location.pathname;

  // Desktop uses a hash router, so we need to extract the pathname from the hash
  if (hash) {
    // Remove the '#' symbol and split by '?' to exclude query parameters
    pathname = hash.slice(1).split('?')[0];
  } else if (SCOUT_START_PAGE_PATHNAMES.includes(pathname)) {
    pathname = '/';
  }

  return pathname;
}
