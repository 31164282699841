export type TitleToken =
  | { type: 'string'; value: string }
  | { type: 'parameter'; value: string; matched: boolean };

export type CachedURLShortcut = {
  uuid: string;
  hotwords: string[];
  urlTemplate: string;
  titleTemplate: string;
  dropboxersOnly: boolean;
  icon?: {
    lightUrl: string;
    darkUrl: string;
  };
};

export type URLShortcut = CachedURLShortcut & {
  parameters: {
    url: string;
    count: number;
    chunks: number;
    matches: string[];
    highlightWords: string[];
    complete: boolean;
    matched: boolean;
    title: TitleToken[];
    activeHotword: string;
  };
};

export const CREATE_STACK_ACTION_UUID = '59bb3fac-59f3-445e-b0d9-2374d2860d07';
