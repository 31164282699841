import { ContentIcon } from '@dropbox/dash-component-library';
import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Split } from '@dropbox/dig-foundations';
import {
  ReportPapEventFn,
  useMirageAnalyticsContext,
} from '@mirage/analytics/AnalyticsProvider';
import { PAP_Collapse_DashCard } from '@mirage/analytics/events/types/collapse_dash_card';
import { PAP_Expand_DashCard } from '@mirage/analytics/events/types/expand_dash_card';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import {
  TileCard,
  TileCardGrid,
} from '@mirage/dash-component-library/components/TileCard';
import { FavIcon } from '@mirage/link-list/Favicon/Favicon';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { openURL } from '@mirage/service-platform-actions';
import {
  filterRecentContents,
  useCombinedRecents,
} from '@mirage/service-recent-content/hooks';
import {
  RecentConnectorContent,
  RecentContent,
} from '@mirage/service-recent-content/types';
import { useMetadataForUrls } from '@mirage/service-url-metadata/hooks';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { ModuleHeader } from '@mirage/shared/two-column-grid/ModuleHeader';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import {
  urlToDomainName,
  urlToService,
} from '@mirage/shared/util/urlRulesUtil';
import { useDynamicExpandableContent } from '@mirage/stacks/FullScreenStack/useDynamicExpandableContent';
import i18n from '@mirage/translations';
import { CSSProperties, forwardRef, useMemo } from 'react';
import styles from './RecentsModule.module.css';

const MAX_VISIBLE_COUNT = 6;
const EMPTY_ARRAY: RecentContent[] = [];

export const RecentsModule = () => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { content, isLoading } = useCombinedRecents();
  const { connectors } = useConnectors();
  const { expanded, setExpanded, showMore, numVisible, wrapperRef } =
    useDynamicExpandableContent(`div.${styles.recentCard}`);

  const recentContent = useMemo(() => {
    return isLoading ? EMPTY_ARRAY : filterRecentContents(content, connectors);
  }, [connectors, content, isLoading]);

  const recentContentForDisplay = useMemo(() => {
    return recentContent.slice(0, 2 * MAX_VISIBLE_COUNT);
  }, [recentContent]);

  // If recents have loaded, but there are non, hide the recents module
  if (!isLoading && numVisible === 0) return null;

  const handleToggle = () => {
    if (expanded) {
      reportPapEvent(
        PAP_Collapse_DashCard({
          featureLine: 'recents',
          actionSurfaceComponent: 'recents',
          dashCardType: 'recents',
        }),
      );
    } else {
      reportPapEvent(
        PAP_Expand_DashCard({
          featureLine: 'recents',
          actionSurfaceComponent: 'recents',
          dashCardType: 'recents',
        }),
      );
    }
    setExpanded(!expanded);
  };

  return (
    <Split direction="vertical" gap="Micro Small">
      <Split.Item>
        <RecentsHeader
          showToggle={showMore}
          expanded={expanded}
          onToggle={handleToggle}
        />
      </Split.Item>
      <Split.Item>
        <RecentsList
          isLoading={isLoading}
          expanded={expanded}
          contentList={recentContentForDisplay}
          visibleCount={numVisible}
          ref={wrapperRef}
        />
      </Split.Item>
    </Split>
  );
};

const RecentsHeader = ({
  expanded,
  showToggle,
  onToggle,
}: {
  expanded: boolean;
  showToggle: boolean;
  onToggle: () => void;
}) => {
  return (
    <ModuleHeader
      title={i18n.t('recents')}
      actions={
        showToggle && (
          <Button variant="transparent" size="small" onClick={onToggle}>
            {i18n.t(expanded ? 'show_less_answer' : 'show_more_answer')}
          </Button>
        )
      }
    />
  );
};

const RecentsList = forwardRef<
  HTMLDivElement,
  {
    isLoading: boolean;
    expanded: boolean;
    contentList: RecentContent[];
    visibleCount: number;
  }
>(({ isLoading, expanded, contentList, visibleCount }, ref) => {
  const recentContentUrls = contentList.map((c) => c.url);
  const urlMetadata = useMetadataForUrls(recentContentUrls) || {};
  const { reportPapEvent } = useMirageAnalyticsContext();

  return (
    <TileCardGrid
      expanded={expanded}
      className={styles.recentsWrapper}
      tileCardClassName={styles.recentCard}
      isLoading={isLoading}
      loadingTileCount={MAX_VISIBLE_COUNT}
      ref={ref}
    >
      {contentList.map((content, i) => (
        <RecentCard
          key={content.uuid}
          // Need to hide card so we aren't able to tab to cards hidden by overflow
          hidden={i >= visibleCount}
          content={content}
          iconSrc={
            urlMetadata[content.url]?.faviconUrl ||
            faviconSrcForSrcUrl(content.url)
          }
          reportPapEvent={reportPapEvent}
        />
      ))}
    </TileCardGrid>
  );
});
RecentsList.displayName = 'RecentsList';

const RecentCard = ({
  hidden,
  style,
  content,
  iconSrc,
  reportPapEvent,
}: {
  hidden?: boolean;
  style?: CSSProperties;
  content: RecentContent;
  iconSrc: string;
  reportPapEvent: ReportPapEventFn;
}) => {
  const serviceName =
    'displayName' in content.connectorInfo
      ? content.connectorInfo.displayName
      : urlToService(content.url) || urlToDomainName(content.url);
  const icon =
    content.connectorInfo.type === 'connector' ? (
      <FileContentIcon content={content as RecentConnectorContent} />
    ) : (
      <ContentIcon
        icon={<FavIcon src={iconSrc} pureImg />}
        size="medium"
        hasBackground
      />
    );
  const handleOpen = () => {
    if (content.url) {
      openURL(content.url);
      reportPapEvent(
        PAP_Open_DashLink({
          featureLine: 'recents',
          actionSurfaceComponent: 'recents',
          dashConnectorId:
            'connectorName' in content.connectorInfo
              ? content.connectorInfo.connectorName
              : undefined,
        }),
      );
    }
  };

  return (
    <TileCard
      className={styles.recentCard}
      style={style}
      hidden={hidden}
      onClick={handleOpen}
      clickPath={content.url}
      title={content.title}
      titleLinesBeforeTruncate={2}
      icon={icon}
      metadata={
        <Text color="subtle" tagName="div">
          <DashTruncateWithTooltip
            lines={1}
            tooltipProps={{
              title: serviceName,
            }}
          >
            {serviceName}
          </DashTruncateWithTooltip>
        </Text>
      }
    />
  );
};
