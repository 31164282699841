import { BaseKeys } from './baseKeys';

const common = {
  select: 'command+a',
  cut: 'command+x',
  copy: 'command+c',
  paste: 'command+v',
  quit: 'command+q',
  escape: 'escape',
  checkForUpdates: 'command+u',
  settings: 'command+,',
} as const;

const search = {
  copySelected: 'command+c',
  launchResult: 'enter',
} as const;

const typeahead = {
  copySelected: 'command+c',
  launchResult: 'enter',
  moveUp: 'arrowup',
  moveDown: 'arrowdown',
  clearInput: 'escape',
  searchQuery: 'command+enter',
} as const;

const resultItem = {
  launch: 'enter',
  copy: 'command+c',
  moveDown: 'arrowdown',
  moveUp: 'arrowup',
} as const;

// Keys should be KeyboardEvent.key Value, not the OS value
export const darwinSymbols = {
  command: String.fromCharCode(8984),
  alt: String.fromCharCode(8997),
  control: String.fromCharCode(8963),
  up: String.fromCharCode(8593),
  down: String.fromCharCode(8595),
  right: String.fromCharCode(8594),
  left: String.fromCharCode(8592),
  shift: String.fromCharCode(8679),
  enter: String.fromCharCode(8617),
} as const;

export const modifierKeys = {
  metaKey: 'command',
  ctrlKey: 'control',
} as const;

export const mousetrapMap = {
  Meta: 'command',
  Alt: 'alt',
  Control: 'super',
} as const;

export const darwinKeys: BaseKeys = {
  common,
  search,
  launchResult: 'Enter',
  typeahead,
  resultItem,
};

export default darwinKeys;
