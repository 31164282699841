import { ServiceAdapter } from "@mirage/service-logout/service";
import { DBID_KEY, UID_KEY } from "@mirage/webapp/helpers/readUrlParam";
import { RoutePath } from "@mirage/webapp/routeTypes";

import { AUTH_STORAGE } from "../AuthStorage";
import { removeWebAuthCookie, removeWebEnvCookie } from "../shared/webCookies";
import { sharedWorkerDatabase } from "../shared-worker/sharedWorkerStorage";

export class LogoutAdapter implements ServiceAdapter {
  async logout() {
    return logout();
  }
}

/**
 * Service specific teardowns are done in their respective services.
 * In case the services are not initialized we clear the AUTH_STORAGE directly.
 */
async function logout() {
  // Preserve installId upon logout.

  const installId = await AUTH_STORAGE.get("installId");
  const dbid = localStorage.getItem(DBID_KEY);
  const uid = localStorage.getItem(UID_KEY);

  // Local storage can only be cleared after logout as the
  // logout interferes with the local storage.
  await sharedWorkerDatabase.clear();

  localStorage.clear();

  // Clear Cookies
  removeWebAuthCookie();
  removeWebEnvCookie();

  await AUTH_STORAGE.clear();

  // Preserve installId upon logout.
  if (installId) {
    await AUTH_STORAGE.set("installId", installId);
  }

  if (dbid) {
    localStorage.setItem(DBID_KEY, dbid);
  }

  if (uid) {
    localStorage.setItem(UID_KEY, uid);
  }

  // Cannot use navigate because we are switching from a logged in state to a
  // logged out state, so do full reload to refresh the Single-Page Application.
  // Not easy to do a full instant cleanup of all background services otherwise.
  location.href = RoutePath.ROOT + location.search;
}
