import { Button } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Typeahead } from '@dropbox/dig-components/typeahead';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { SearchLine, SendLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useDebounce } from '@mirage/shared/hooks/useDebounce';
import { AvatarMember } from '@mirage/stacks/ShareModal/Avatar';
import {
  SharingMember,
  SharingMemberKey,
  SharingUserContactKey,
} from '@mirage/stacks/ShareModal/Types';
import {
  getSharingMemberIdentifier,
  sharingMemberKeysMatch,
  validateEmail,
} from '@mirage/stacks/ShareModal/Utils';
import i18n from '@mirage/translations';
import { useCallback, useEffect, useState } from 'react';
import styles from './ShareModal.module.css';

export const SharingTypeahead = ({
  stackMemberKeys,
  onShare,
  shareText,
  onShareTextChange: setShareText,
  isSharing,
  excludedMembers,
  getSharingMembers,
  shareOnSelection = false,
  allowExternalShare = false,
}: {
  stackMemberKeys: SharingMemberKey[];
  onShare: (members: (SharingMember | SharingUserContactKey)[]) => void;
  shareText: string;
  onShareTextChange: (value: string) => void;
  isSharing: boolean;
  excludedMembers: SharingMemberKey[];
  getSharingMembers: (searchText: string) => Promise<SharingMember[]>;
  shareOnSelection?: boolean;
  allowExternalShare?: boolean;
}) => {
  const [sharingContactsResults, setSharingContactsResults] = useState<
    SharingMember[]
  >([]);
  const [isSearchingSharingContacts, setIsSearchingSharingContacts] =
    useState(false);
  const [selectedSharingMembers, setSelectedSharingMembers] = useState<
    (SharingMember | SharingUserContactKey)[]
  >([]);
  // Counter to reset state after clicking share button.
  // Otherwise, the typeahead menu will stay up in the multi-select mode.
  const [sharedCount, setSharedCount] = useState(0);

  const updateSharingContacts = async () => {
    // TODO: remove if not needed
    // if (shareText === '') return;

    setIsSearchingSharingContacts(true);
    const members = await getSharingMembers(shareText);
    const filteredMembers = members
      .filter((member) => {
        return (
          !stackMemberKeys.some((memberKey) =>
            sharingMemberKeysMatch(memberKey, member),
          ) &&
          !selectedSharingMembers.some((selectedMember) =>
            sharingMemberKeysMatch(selectedMember, member),
          ) &&
          !excludedMembers.some((memberKey) =>
            sharingMemberKeysMatch(memberKey, member),
          )
        );
      })
      .slice(0, 3);
    setSharingContactsResults(filteredMembers);
    setIsSearchingSharingContacts(false);
  };

  // Debounce request to update suggestions until user stops modifying it for 2s
  const debouncedUpdateSharingContacts = useDebounce(
    () => void updateSharingContacts(),
    500,
  );

  useEffect(() => {
    debouncedUpdateSharingContacts();
  }, [shareText, debouncedUpdateSharingContacts]);

  useEffect(() => {
    return () => {
      debouncedUpdateSharingContacts.cancel();
    };
  }, [debouncedUpdateSharingContacts]);

  const renderTypeaheadRow = useCallback((result: SharingMember) => {
    return (
      <Typeahead.Row
        key={getSharingMemberIdentifier(result)}
        value={result}
        withTitle={result.displayName}
        withSubtitle={
          result['.tag'] === 'user'
            ? result.email
            : `${i18n.t('company_accesslevel')} · ${i18n.t('num_members', {
                count: result.memberCount,
              })}`
        }
        withLeftAccessory={<AvatarMember member={result} hasNoOutline />}
      />
    );
  }, []);

  const selectMember = useCallback(
    (member: SharingMember | SharingUserContactKey): void => {
      setShareText('');

      if (shareOnSelection) {
        onShare([member]);
      } else {
        setSelectedSharingMembers((currentMembers) => {
          if (
            currentMembers.some((currentMember) =>
              sharingMemberKeysMatch(currentMember, member),
            )
          ) {
            return currentMembers;
          }
          return [...currentMembers, member];
        });
      }

      debouncedUpdateSharingContacts();
    },
    [shareOnSelection, onShare, setShareText, debouncedUpdateSharingContacts],
  );

  const deselectMember = useCallback((member: SharingMemberKey): void => {
    setSelectedSharingMembers((currentMembers) =>
      currentMembers.filter(
        (currentMember) => !sharingMemberKeysMatch(currentMember, member),
      ),
    );
  }, []);

  const findSuggestedMember = useCallback(
    (memberKey: SharingUserContactKey) => {
      const member = sharingContactsResults.find((result) =>
        sharingMemberKeysMatch(result, memberKey),
      );

      if (allowExternalShare) {
        // Only accept a validated email address if we're allowing external sharing
        return member || memberKey;
      }
      return member;
    },
    [sharingContactsResults, allowExternalShare],
  );

  const handleInputChange = useCallback(
    (value: string) => {
      const lastChar = value.slice(-1);

      if (!shareOnSelection && (lastChar === ' ' || lastChar === ',')) {
        const trimmedValue = value.replace(',', '').trim();
        setShareText(trimmedValue);
        if (validateEmail(trimmedValue)) {
          const member = findSuggestedMember({
            '.tag': 'user',
            email: trimmedValue,
          });
          if (member) {
            selectMember(member);
            return;
          }
        }
      }

      setShareText(value);
      debouncedUpdateSharingContacts();
    },
    [
      shareOnSelection,
      selectMember,
      setShareText,
      debouncedUpdateSharingContacts,
      findSuggestedMember,
    ],
  );

  const handleKeyDown: React.KeyboardEventHandler = useCallback(
    (e) => {
      if (shareOnSelection) {
        return;
      }
      let member: SharingMember | SharingUserContactKey | undefined;

      switch (e.key) {
        case 'Backspace':
          if (shareText === '') {
            setSelectedSharingMembers((currentMembers) => {
              if (currentMembers.length === 0) {
                return currentMembers;
              }
              const lastMember = currentMembers.slice(-1)[0];
              if ('displayName' in lastMember) {
                debouncedUpdateSharingContacts();
              }
              return currentMembers.slice(0, -1);
            });
          }
          break;
        case 'Enter':
          if (!validateEmail(shareText)) {
            // Searches that resolve to a suggestion will still be
            // selected by the typeahead.
            break;
          }
          member = findSuggestedMember({ '.tag': 'user', email: shareText });
          if (member) {
            selectMember(member);
          }
          break;
      }
    },
    [
      shareOnSelection,
      shareText,
      debouncedUpdateSharingContacts,
      selectMember,
      findSuggestedMember,
    ],
  );

  const handleShareButtonClicked = useCallback(() => {
    setSharedCount((previous) => previous + 1);
    onShare(selectedSharingMembers);
    setSelectedSharingMembers([]);
  }, [
    onShare,
    selectedSharingMembers,
    setSelectedSharingMembers,
    setSharedCount,
  ]);

  const isShareable = selectedSharingMembers.length !== 0 && !isSharing;

  const shareButton = (
    <Button
      variant="filled"
      className={styles.shareButton}
      onClick={handleShareButtonClicked}
      disabled={!isShareable}
      withIconStart={<UIIcon src={SendLine} />}
      size="large"
      fullWidth={true}
      data-testid="Stacks-shareStackButton"
    >
      <Text isBold={isShareable} color={isShareable ? 'standard' : 'faint'}>
        {i18n.t('share_this_stack')}
      </Text>
    </Button>
  );

  return (
    <Typeahead.Wrapper
      key={sharedCount}
      onSelection={selectMember}
      shouldHighlightFirstRow
      isPortaled={false}
      closeOnSelection={false}
    >
      {({ getTriggerProps, getContentProps }) => (
        <>
          <TextInput.Container size="large" {...getTriggerProps()}>
            <TextInput.Accessory position="start">
              <UIIcon src={SearchLine} className={styles.searchIcon} />
            </TextInput.Accessory>
            <TextInput.ChipsContainer>
              {shareOnSelection ||
                selectedSharingMembers.map(
                  (sharingMember: SharingMember | SharingMemberKey) => (
                    <Chip
                      key={getSharingMemberIdentifier(sharingMember)}
                      size="small"
                      variant={
                        sharingMember['.tag'] === 'user' &&
                        !validateEmail(sharingMember.email)
                          ? 'warning'
                          : undefined
                      }
                      onDelete={() => deselectMember(sharingMember)}
                    >
                      {'displayName' in sharingMember && (
                        <Chip.AvatarAccessory>
                          <AvatarMember
                            member={sharingMember as SharingMember}
                            avatarSize="small"
                          />
                        </Chip.AvatarAccessory>
                      )}
                      <Chip.Content>
                        {'displayName' in sharingMember
                          ? sharingMember.displayName
                          : (sharingMember as SharingUserContactKey).email}
                      </Chip.Content>
                    </Chip>
                  ),
                )}
              <TextInput.Input
                onChange={(e) => handleInputChange(e.currentTarget.value)}
                value={isSharing ? i18n.t('sharing_ellipses') : shareText}
                placeholder={i18n.t('invite_someone_via_email')}
                disabled={isSharing}
                onKeyDown={handleKeyDown}
                data-testid="Stacks-shareStackByEmailInput"
              />
            </TextInput.ChipsContainer>
          </TextInput.Container>
          <Typeahead.Container
            isEmptyQuery={
              !shareOnSelection && sharingContactsResults.length === 0
            }
            emptyPrompt={<EmptyPrompt shareText={shareText} />}
            loading={isSearchingSharingContacts}
            {...getContentProps()}
          >
            <Typeahead.Results
              results={sharingContactsResults}
              renderRow={renderTypeaheadRow}
            />
            {shareOnSelection || shareButton}
          </Typeahead.Container>
        </>
      )}
    </Typeahead.Wrapper>
  );
};

const EmptyPrompt = ({ shareText }: { shareText: string }) => {
  if (!validateEmail(shareText)) {
    return (
      <Typeahead.EmptyPrompt
        placeholderText={i18n.t('sharing_typeahead_empty_hint')}
      />
    );
  }
  return (
    <Typeahead.Prompt>
      <Text>{i18n.t('sharing_typeahead_completion_hint')}</Text>
    </Typeahead.Prompt>
  );
};
