import {
  getPersonFilter,
  SearchFilter,
} from '@mirage/shared/search/search-filters';
import { AuthorInfo } from '@mirage/shared/search/search-result';
import {
  getEventTimeString,
  getMinutesAndHoursUntilOrFromStartOfEvent,
} from '@mirage/shared/util/calendar';
import { getTimeAgoString } from '@mirage/shared/util/time';
import i18n, { I18nKey } from '@mirage/translations';
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  isToday,
  isTomorrow,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import type { SearchResult } from '@mirage/service-dbx-api';

function getTimeToEvent(startTime: number, endTime: number): string {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const currentDate = new Date();

  const sDelta = differenceInSeconds(startDate, currentDate);
  const mDelta = differenceInMinutes(startDate, currentDate);
  const hDelta = differenceInHours(startDate, currentDate);

  if (startDate < currentDate) {
    // Event has already started
    if (sDelta > -60) {
      return 'Just now';
    } else if (currentDate < endDate) {
      return 'Now';
    } else {
      return getTimeAgoString(endTime, true);
    }
  } else {
    // Event is in the future
    if (sDelta < 60) {
      return 'Less than 1 min';
    } else if (mDelta < 60) {
      return mDelta === 1 ? 'In 1 minute' : `In ${mDelta} minutes`;
    } else if (isToday(startDate) && hDelta >= 1) {
      return hDelta === 1 ? `In 1 hour` : `In ${hDelta} hours`;
    } else if (isTomorrow(startDate)) {
      return 'Tomorrow';
    } else {
      return format(startDate, 'MMM d, yyyy');
    }
  }
}

function epochMsToDisplayTime(epoch: number): string {
  return new Date(epoch)
    .toLocaleString('en-US', { timeStyle: 'short' })
    .toLowerCase()
    .replace(/\s/g, '');
}

export function getCalendarSubtitle(result: SearchResult) {
  const { isAllDay, startTime, endTime } = result;

  let timeRange = null;
  if (isAllDay) {
    timeRange = 'All day';
  } else if (startTime && startTime > 0 && endTime && endTime > 0) {
    timeRange = `${epochMsToDisplayTime(startTime)} - ${epochMsToDisplayTime(
      endTime,
    )}`;
  }

  let timeUntilOrFrom = null;
  if (startTime && startTime > 0 && endTime && endTime > 0) {
    const now = Date.now();
    if (!isAllDay && startTime < now && now < endTime) {
      const { hours, minutes } = getMinutesAndHoursUntilOrFromStartOfEvent(
        result,
        'in_progress',
      );
      timeUntilOrFrom = getEventTimeString('in_progress', hours, minutes);
    } else {
      timeUntilOrFrom = getTimeToEvent(
        // TODO: Temporary fix until https://jira.dropboxer.net/browse/OTCIP-46 is
        // resolved and the API provides just the date for these all-day events.
        isAllDay ? utcToZonedTime(startTime, 'UTC').getTime() : startTime,
        isAllDay ? utcToZonedTime(endTime, 'UTC').getTime() : endTime,
      );
    }
  }

  if (timeRange && timeUntilOrFrom) {
    return `${timeRange} · ${timeUntilOrFrom}`;
  } else if (!timeRange && timeUntilOrFrom) {
    return timeUntilOrFrom;
  } else if (timeRange && !timeUntilOrFrom) {
    return timeRange;
  }
  return '';
}

export function getLocalFileTimestamp(result: SearchResult) {
  let timestamp: string | null = null;
  if (result.providerUpdateAtMs) {
    const timeAgo = getTimeAgoString(result.providerUpdateAtMs);
    timestamp = i18n.t('updated_ago', { timeAgo });
  }
  return timestamp;
}

export function isMeetingJoinable(result: SearchResult) {
  if (
    result?.recordType?.['.tag'] === 'event' &&
    result?.conferenceLinks?.length
  ) {
    return true;
  }
  return false;
}

export const getTimeAgoStringFromTimestamp = (pastDate?: number | null) => {
  let timeAgo = '';

  if (pastDate) {
    const timeAgoString = getTimeAgoString(pastDate);
    timeAgo = i18n.t('updated_ago', { timeAgo: timeAgoString });
  }

  return timeAgo;
};

type NullableAuthorInfo = AuthorInfo | null | undefined;

export const getPersonForMetadata = ({
  sender,
  creator,
  lastModifier,
  activeFilters,
}: {
  sender: NullableAuthorInfo;
  creator: NullableAuthorInfo;
  lastModifier: NullableAuthorInfo;
  activeFilters?: SearchFilter[];
}): {
  i18nKey: I18nKey | undefined;
  person: NullableAuthorInfo;
} => {
  const activePersonFilter = getPersonFilter(activeFilters || []);
  const emailToMatch = activePersonFilter?.parameters?.email;

  const persons: {
    person: NullableAuthorInfo;
    i18nKey: I18nKey;
  }[] = [
    // Note: This is ordered by priority of which author type we want to display
    { person: sender, i18nKey: 'result_sent_by' },
    { person: lastModifier, i18nKey: 'result_updated_by' },
    { person: creator, i18nKey: 'result_created_by' },
  ];

  // If a person filter is active, try to match it first
  if (emailToMatch) {
    for (const { person, i18nKey } of persons) {
      if (person?.email === emailToMatch) {
        return { person, i18nKey };
      }
    }
  }

  // Fallback to the first available person with displayable attributes
  for (const { person, i18nKey } of persons) {
    if (person?.displayName || person?.email) {
      return { person, i18nKey };
    }
  }

  // Default return if no matches or data available
  return { person: undefined, i18nKey: undefined };
};
