// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1898
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashQueryType } from '../enums/dash_query_type';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'click.dash_submit_question'
// Description: Dash Answers - when user submits query.
// Owner: dash-web
export type Click_DashSubmitQuestion = PAPEventGeneric<
  'dash',
  'click',
  'dash_submit_question',
  {
    // For dash answers, determines whether the question asked was suggested by Dash or user typed in manually.
    dashIsSuggestedQuestion?: boolean;
    // The ID of the Dash answers session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashAnswersSessionId?: string;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // Dash Answers - number of questions asked in an answers session. This is a COUNT.
    numQuestionsAsked?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // An entry point string, used to describe how users are triggering the event as relevant (e.g. browse_file_row_context_menu)
    entryPoint?: string;
    answerId?: string;
    // The type of query submitted
    dashQueryType?: DashQueryType;
    // The zero-index returned position of search result in a list of search results.
    resultPosition?: number;
    // Unique identifier (UUIDv5 ) of the search result
    resultUuid?: string;

    // Important note: The below properties are L0 properties that are manually added and MUST be re-added after running the PAP codegen
    // These properties will be properly handled with PAP-1249 (https://jira.dropboxer.net/browse/PAP-1249)
    queryString?: string;
    // This property will be imported automatically once approved by the PAP team
    searchRequestId?: string;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // The surface in which an action was taken on Dash.
    dashActionSurface?: DashActionSurface;
    // unique id for a /dash/conversation query. Used by the ML team to get feedback on answer quality
    dashAnswerRequestId?: string;
    // Identifier for a search query
    searchQueryUuid?: string;
  }
>;

export function PAP_Click_DashSubmitQuestion(
  properties?: Click_DashSubmitQuestion['properties'],
): Click_DashSubmitQuestion {
  return <Click_DashSubmitQuestion>{
    class: 'dash',
    action: 'click',
    object: 'dash_submit_question',
    properties,
  };
}
