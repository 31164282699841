// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1015
import type { PAPEventGeneric } from '../base/event';
import type { DashConnectAccountFailureReason } from '../enums/dash_connect_account_failure_reason';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'add.dash_connected_account'
// Description: When a user connects a new third party to Dash client.
// Owner: connector-foundations
export type Add_DashConnectedAccount = PAPEventGeneric<
  'dash',
  'add',
  'dash_connected_account',
  {
    // The account ID associated with a Dash connector, e.g. Trello account.
    dashAccountId?: string;
    // Which Dash connector an event is associated with, e.g. Trello.
    dashConnectorId?: string;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // whether the user is currently in an onboarding flow when they perform the action being logged.
    inOnboardingFlow?: boolean;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // The reason why the user failed to connect an account to dash
    dashConnectAccountFailureReason?: DashConnectAccountFailureReason;
  }
>;

export function PAP_Add_DashConnectedAccount(
  properties?: Add_DashConnectedAccount['properties'],
): Add_DashConnectedAccount {
  return <Add_DashConnectedAccount>{
    class: 'dash',
    action: 'add',
    object: 'dash_connected_account',
    properties,
  };
}
