import Lottie from 'lottie-react';
import { Suspense, useEffect, useState } from 'react';

export enum LottieAnimationType {
  ONBOARDING_CHECK = 'dash-onboarding-check',
}

type LottieAnimationProps = {
  animationType?: LottieAnimationType;
  autoplay?: boolean;
  loop?: boolean;
  className?: string;
};

export const LottieAnimation = ({
  animationType,
  autoplay,
  loop,
  className,
}: LottieAnimationProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [animationData, setAnimationData] = useState({});

  useEffect(() => {
    (async () => {
      let animationData;

      /**
       * We can't pass in a variable to the URL otherwise an error is through about critical dependencies on the import
       * So this is the best way I could tell to dynamically import.
       * Pretty unhappy with this, but for now it works.
       */
      switch (animationType) {
        case LottieAnimationType.ONBOARDING_CHECK:
          animationData = await import('./assets/Dash-Onboarding-Check.json');
          break;
        default:
          break;
      }

      if (animationData) {
        setAnimationData(animationData);
        setIsLoaded(true);
      }
    })();
  }, [animationType]);

  if (!isLoaded) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <Lottie
        animationData={animationData}
        autoplay={autoplay}
        loop={loop}
        className={className}
      />
    </Suspense>
  );
};
