import type { Client } from '@sentry/types';

interface SurfaceSentry {
  getClient(): Client | undefined;
}
let surfaceSentry: null | SurfaceSentry = null;
export function exposeSurfaceSentryInstance<T extends SurfaceSentry>(
  surface: T,
) {
  surfaceSentry = surface;
}

const clientProxy = new Proxy(
  {},
  {
    get(_, property, receiver) {
      if (!surfaceSentry) {
        throw new Error(
          `You must provide the surface's Sentry client before interacting with the wrapper.`,
        );
      }
      const target = surfaceSentry.getClient();
      if (!target) {
        throw new Error('Surface did not return a valid Sentry client!');
      }
      return Reflect.get(target, property, receiver);
    },
  },
) as Readonly<Client>;

export default clientProxy;
