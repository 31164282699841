import { Text } from '@dropbox/dig-components/typography';
import styles from './ErrorMessage.module.css';
import { ConversationMessage } from './types';

type ErrorMessageProps = {
  message: ConversationMessage;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <div className={styles.container}>
      <div className={styles.message}>
        <Text>{message.content}</Text>
      </div>
    </div>
  );
};
