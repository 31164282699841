import { dash_feed } from '@dropbox/api-v2-client';
import { Box, Stack } from '@dropbox/dig-foundations';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { ActivityFeedFilters } from '@mirage/service-feed/types';
import { areFiltersSet } from '@mirage/service-feed/util/helpers';
import { ActivityListEmptyState } from '../ActivityListEmptyState/ActivityListEmptyState';
import { ActivityListEmptyStateResetFilters } from '../ActivityListEmptyState/ActivityListEmptyStateResetFilters';
import { InfiniteScroll } from '../InfiniteScroll/InfiniteScroll';
import { ActivityListItem, ActivityListItemSkeleton } from './ActivityListItem';
import { NoMoreActivityItems } from './NoMoreActivityItems';

type ActivityListProps = {
  activities: dash_feed.ActivityItem[];
  showLoadingPlaceholders: boolean;
  filters: ActivityFeedFilters;
  clearFilters: () => void;
  onReachEnd?: () => void;
  hasMore: boolean;
};

const NUM_LOADER_ITEMS = 10;

export const ActivityList = ({
  activities,
  showLoadingPlaceholders,
  onReachEnd,
  hasMore,
  filters,
  clearFilters,
}: ActivityListProps) => {
  const useInfiniteScroll = !!onReachEnd && hasMore && !showLoadingPlaceholders;
  const currentAccount = useDropboxAccount();
  const showEndOfFeed = !hasMore && !showLoadingPlaceholders;

  if (activities.length === 0 && !showLoadingPlaceholders) {
    if (areFiltersSet(filters)) {
      return <ActivityListEmptyStateResetFilters clearFilters={clearFilters} />;
    } else {
      return <ActivityListEmptyState />;
    }
  }

  return (
    <Box>
      <Stack gap="10">
        {activities.map((activity, index) => (
          <ActivityListItem
            key={index}
            activity={activity}
            currentAccount={currentAccount}
          />
        ))}

        {showLoadingPlaceholders &&
          Array.from({ length: NUM_LOADER_ITEMS }).map((_, index) => (
            <ActivityListItemSkeleton key={index} />
          ))}

        {useInfiniteScroll && <InfiniteScroll onTrigger={onReachEnd} />}
      </Stack>

      {showEndOfFeed && <NoMoreActivityItems />}
    </Box>
  );
};
