import { IconButton } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CopyLine,
  ThumbsDownFill,
  ThumbsDownLine,
  ThumbsUpFill,
  ThumbsUpLine,
} from '@dropbox/dig-icons/assets';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { FeedbackOptions } from '@mirage/shared/types';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { useRef, useState } from 'react';
import styles from './MessageActions.module.css';

import type { AnswersActionComponent } from './types';
import type { FC } from 'react';

type MessageActionsProps = {
  onClickFeedback: (feedback: FeedbackOptions) => void;
  onCopyMessage?: () => void;
  actionSurfaceComponent?: AnswersActionComponent;
};

export const MessageActions: FC<MessageActionsProps> = ({
  onClickFeedback,
  onCopyMessage,
  actionSurfaceComponent = 'inline_answer',
}) => {
  const positiveBtnRef = useRef<HTMLElement>(null);
  const negativeBtnRef = useRef<HTMLElement>(null);
  const copydBtnRef = useRef<HTMLElement>(null);
  const [hovered, setHovered] = useState<
    'positive' | 'negative' | 'copy' | undefined
  >();

  const [recordedFeedback, setRecordedFeedback] = useState<FeedbackOptions>();

  const handleClickFeedback = (feedback: FeedbackOptions) => {
    if (recordedFeedback === feedback) {
      setRecordedFeedback(undefined);
    } else {
      setRecordedFeedback(feedback);
      onClickFeedback(feedback);
    }

    setHovered(undefined);
  };

  const positiveIcon =
    recordedFeedback === FeedbackOptions.Positive ? ThumbsUpFill : ThumbsUpLine;
  const negativeIcon =
    recordedFeedback === FeedbackOptions.Negative
      ? ThumbsDownFill
      : ThumbsDownLine;

  return (
    <div className={styles.feedbackContainer}>
      <Text isBold size="small">
        {i18n.t('was_this_helpful')}
      </Text>
      <IconButton
        ref={positiveBtnRef}
        variant="borderless"
        onClick={() => handleClickFeedback(FeedbackOptions.Positive)}
        onMouseEnter={() => setHovered('positive')}
        onMouseLeave={() => setHovered(undefined)}
        onFocus={() => setHovered('positive')}
        onBlur={() => setHovered(undefined)}
        aria-label={i18n.t('helpful')}
        aria-pressed={recordedFeedback === FeedbackOptions.Positive}
        data-uxa-log={createUxaElementId('feedback_positive', {
          actionSurfaceComponent,
          featureLine: 'answers',
        })}
      >
        <UIIcon size="small" src={positiveIcon} />
      </IconButton>
      <IconButton
        ref={negativeBtnRef}
        variant="borderless"
        onClick={() => handleClickFeedback(FeedbackOptions.Negative)}
        onMouseEnter={() => setHovered('negative')}
        onMouseLeave={() => setHovered(undefined)}
        onFocus={() => setHovered('negative')}
        onBlur={() => setHovered(undefined)}
        aria-label={i18n.t('not_helpful')}
        aria-pressed={recordedFeedback === FeedbackOptions.Negative}
        data-uxa-log={createUxaElementId('feedback_negative', {
          actionSurfaceComponent,
          featureLine: 'answers',
        })}
      >
        <UIIcon size="small" src={negativeIcon} />
      </IconButton>

      {onCopyMessage && (
        <IconButton
          className={styles.copyButton}
          ref={copydBtnRef}
          variant="borderless"
          onClick={onCopyMessage}
          onMouseEnter={() => setHovered('copy')}
          onMouseLeave={() => setHovered(undefined)}
          onFocus={() => setHovered('copy')}
          onBlur={() => setHovered(undefined)}
          aria-label={i18n.t('copy')}
          data-uxa-log={createUxaElementId('copy_answer_button', {
            actionSurfaceComponent,
            featureLine: 'answers',
          })}
        >
          <UIIcon size="small" src={CopyLine} />
        </IconButton>
      )}

      {/* Tooltips for buttons. We have to manage each tooltip open state manually to play nicely with DIG modals */}
      {onCopyMessage && (
        <DigTooltip.Control
          triggerRef={copydBtnRef}
          open={hovered === 'copy'}
          placement="top"
          shouldReturnFocus={false}
        >
          {i18n.t('copy')}
        </DigTooltip.Control>
      )}
      <DigTooltip.Control
        triggerRef={positiveBtnRef}
        open={hovered === 'positive'}
        placement="top"
        shouldReturnFocus={false}
      >
        {i18n.t('helpful')}
      </DigTooltip.Control>
      <DigTooltip.Control
        triggerRef={negativeBtnRef}
        open={hovered === 'negative'}
        placement="top"
        shouldReturnFocus={false}
      >
        {i18n.t('not_helpful')}
      </DigTooltip.Control>
    </div>
  );
};
