import { useCallback, useEffect, useState } from 'react';
import { getShouldUseStageBackend, setShouldUseStageBackend } from './index';

export default function useShouldUseStageBackend() {
  const [shouldUseStageBackend, setState] = useState(false);

  useEffect(() => {
    getShouldUseStageBackend().then(setState);
  }, []);

  const onChange = useCallback((useStage: boolean) => {
    setState(useStage);
    setShouldUseStageBackend(useStage);
  }, []);

  return [shouldUseStageBackend, onChange] as const;
}
