import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { Service } from '@mirage/service-typeahead-search/service';
import { PreviousQuery } from '@mirage/shared/search/cache-result';
import * as rx from 'rxjs';
import { typeahead } from './service/types';

import type { CachingStrategy } from '@mirage/service-search/service';

export type { Service };

const service = services.get<Service>(ServiceId.TYPEAHEAD_SEARCH);

export const search = service.search;

export function cacheRecentQueries(queries: string[]) {
  return rx.firstValueFrom(service.cacheRecentQueries(queries));
}

export function getRecentQueries() {
  return rx.firstValueFrom(service.getRecentQueries());
}

export function getRecommendations() {
  return rx.firstValueFrom(service.getRecommendations());
}

export async function removePreviousQueryFromCache(
  result: typeahead.ScoredPreviousQuery,
) {
  return rx.firstValueFrom(service.remove(result as unknown as PreviousQuery));
}

export const clear = service.clear;

export function tearDown() {
  return rx.firstValueFrom(service.tearDown());
}

export function startSyncRecommendations() {
  return rx.firstValueFrom(service.startSyncRecommendations());
}

export function cancelSyncRecommendations() {
  return rx.firstValueFrom(service.cancelSyncRecommendations());
}

export function startSyncWeights() {
  return rx.firstValueFrom(service.startSyncWeights());
}

export function cancelSyncWeights() {
  return rx.firstValueFrom(service.cancelSyncWeights());
}

export function startSyncSuggestedQueryScore() {
  return rx.firstValueFrom(service.startSyncSuggestedQueryScore());
}

export function cancelSyncSuggestedQueryScore() {
  return rx.firstValueFrom(service.cancelSyncSuggestedQueryScore());
}

export function registerHit(uuid: string) {
  return rx.firstValueFrom(service.registerHit(uuid));
}

export const searchServiceCachingStrategy: CachingStrategy = {
  cacheQuery: (query) => {
    cacheRecentQueries([query]);
  },
};
