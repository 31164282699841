import { ButtonProps } from '@dropbox/dig-components/buttons';
import {
  fancyShortcut,
  isDarwinSymbol,
  splitShortcutString,
} from '@mirage/shared/hotkeys';
import KeyCap, { KeyCapCasing } from './KeyCap';
import styles from './PrettyShortcuts.module.css';

type KeycapContainerProps = ButtonProps & {
  editable: boolean;
};

type Props = Readonly<{
  shortcut: string | string[];
  isDarwin: boolean;
  handleToggle?: () => void;
  editable?: boolean;
  casingOverride?: KeyCapCasing;
}>;

export function PrettyShortcuts({
  shortcut,
  isDarwin,
  handleToggle,
  editable = false,
  casingOverride,
}: Props) {
  if (!shortcut) return null;
  // default casing for Windows vs. Mac for branding consistency purposes
  // Mac examples: Command, Enter, Option, etc.
  // Windows examples: CTRL, ALT, ENTER, etc.
  const casing = casingOverride || (isDarwin ? 'capitalize' : 'uppercase');

  const shortcutArray = Array.isArray(shortcut)
    ? shortcut
    : splitShortcutString(shortcut);

  const shortcutParts = fancyShortcut(isDarwin, shortcutArray);
  return (
    <KeycapContainer
      editable={editable}
      variant="borderless"
      className={styles.privateButtonOverride}
      onClick={handleToggle}
      aria-label={shortcutParts.join(' + ')}
    >
      <div className={styles.keycapContainer}>
        {shortcutParts.map((key) => (
          <KeyCap
            key={key}
            symbol={isDarwinSymbol(key)}
            onClick={handleToggle}
            casing={casing}
          >
            {key}
          </KeyCap>
        ))}
      </div>
    </KeycapContainer>
  );
}

const KeycapContainer = ({
  editable,
  className,
  children,
}: KeycapContainerProps) => {
  if (editable) {
    return <div className={className}>{children}</div>;
  }
  return <>{children}</>;
};
