import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { MoreHorizontalLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { useEffect, useState } from 'react';
import styles from './MetadataItem.module.css';

export function TruncatedFilePath({
  pathSegments = [],
}: {
  pathSegments: Array<string>;
}) {
  const [visiblePaths, overflowPaths] = useTruncateFolderPath(pathSegments);

  if (!visiblePaths.length) {
    return null;
  }

  if (!overflowPaths.length) {
    return <span>{pathSegments.join(' > ')}</span>;
  }

  const first = visiblePaths[0];
  const remaining = visiblePaths.slice(1).join(' > ');
  return (
    <>
      <span>{`${first} > `}</span>
      <DigTooltip
        title={overflowPaths.join(' > ')}
        openDelay={300}
        maxWidth={MAX_PATH_SEGMENT_WIDTH}
      >
        <IconButton variant="borderless" shape="circular">
          <UIIcon
            src={MoreHorizontalLine}
            size="small"
            className={styles.pathOverflowIcon}
          />
        </IconButton>
      </DigTooltip>
      <span>{` > ${remaining}`}</span>
    </>
  );
}

// 315px is the max width of the filepath container at min width, if we want to make this dynamic we
// can pass a container ref and hook it up to a window resize listener
const MAX_PATH_SEGMENT_WIDTH = 325;

// This hook truncates a list of path segments to fit within a certain width, can be reused for confluence
// and other apps if we want down the line
function useTruncateFolderPath(
  pathSegments: Array<string>,
): Array<Array<string>> {
  const [visiblePaths, setVisible] = useState<Array<string>>([]);
  const [overflowPaths, setOverflow] = useState<Array<string>>([]);

  useEffect(() => {
    const updateVisiblePaths = () => {
      // if we've only got 1 or 2 segments, we don't want to truncate
      if (!pathSegments.length) return;
      if (pathSegments.length < 3) {
        setVisible(pathSegments);
        setOverflow([]);
        return;
      }

      // based on the container width, we'll put as many segments as we can, starting with last
      const visible = [pathSegments[pathSegments.length - 1]];

      // Dummy span to measure text width
      const span = document.createElement('span');
      span.style.visibility = 'hidden';
      span.style.whiteSpace = 'nowrap';
      document.body.appendChild(span);

      let innerText =
        pathSegments[0] + ' > ' + pathSegments[pathSegments.length - 1];
      span.innerText = innerText;
      let elementWidth = span.offsetWidth;

      // add elements from the tail of the path until we hit max width
      for (let i = pathSegments.length - 2; i > 0; i--) {
        innerText += ' > ' + pathSegments[i];
        span.innerText = innerText;
        elementWidth = span.offsetWidth;
        if (elementWidth < MAX_PATH_SEGMENT_WIDTH) {
          visible.unshift(pathSegments[i]);
        } else {
          setOverflow(pathSegments.slice(1, i + 1));
          break;
        }
      }
      // add the first segment (which is always visible and included in the max width calcs above)
      visible.unshift(pathSegments[0]);
      setVisible(visible);
      document.body.removeChild(span);
    };
    updateVisiblePaths();
  }, []); // pathsegments will never change

  return [visiblePaths, overflowPaths];
}
