import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef } from 'react';

/**
 * Debounces a callback function by updating reference to new function
 * definition that uses state value.
 * taken from https://www.developerway.com/posts/debouncing-in-react
 * @param callback
 * @param time
 * @returns
 */
export const useDebounce = <T>(callback: () => T, time: number) => {
  const ref = useRef(callback);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, time);
  }, [time]);

  return debouncedCallback;
};
