import { stacks } from '@dropbox/api-v2-client';
import { getInitialsFromName, isDefined } from '@mirage/shared/util/tiny-utils';
import { DashFacepileMember, Member } from './types';

export function getInitialsForMember(member: DashFacepileMember): string {
  // If display name exists, grab initials from that
  // Otherwise, grab the first letter of the email
  return member.displayName
    ? getInitialsFromName(member.displayName)
    : member.email?.charAt(0)?.toUpperCase() || '';
}

export const normalizeMembers = (members: Member[]): DashFacepileMember[] => {
  return members
    .map((member) => {
      const email = 'email' in member ? member.email ?? '' : '';
      return {
        profilePhotoUrl:
          'profile_image_url' in member
            ? member.profile_image_url
            : (member as stacks.StackSharingUser).profile_photo_url,
        displayName: member.display_name || email,
        email,
      };
    })
    .filter(isDefined);
};
