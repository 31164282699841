import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_CalendarEmptyState } from '@mirage/analytics/events/types/open_calendar_empty_state';
import { PAP_Shown_CalendarEmptyState } from '@mirage/analytics/events/types/shown_calendar_empty_state';
import { useAddCalendarModal } from '@mirage/mosaics/WorkingSet/Calendar/AddCalendar';
import { useConnectedCalendars } from '@mirage/mosaics/WorkingSet/Calendar/useConnectedCalendars';
import { useDismissableModule } from '@mirage/service-onboarding/useDismissableModule';
import { showSnackbar } from '@mirage/shared/snackbar';
import { UIConnector } from '@mirage/shared/types';
import i18n from '@mirage/translations';
import { useEffect, useRef } from 'react';
import { CalendarEmptyStateCard } from './CalendarEmptyStateCard/CalendarEmptyStateCard';

export const DISMISSED_EMPTY_STATE_KEY = 'dismissedCalendarEmptyState';

export const CalendarEmptyStateV2 = () => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { dismissed, setDismissed } = useDismissableModule(
    DISMISSED_EMPTY_STATE_KEY,
  );
  const { refreshConnectionsList } = useConnectedCalendars();
  const showAddCalendarModal = useAddCalendarModal();
  const hasShown = useRef(false);

  useEffect(() => {
    if (!hasShown.current && !dismissed) {
      reportPapEvent(
        PAP_Shown_CalendarEmptyState({
          featureLine: 'calendar',
          calendarEmptyStateType: 'no_events',
        }),
      );
      hasShown.current = true;
    }
  }, [reportPapEvent, hasShown, dismissed]);

  const handleDismiss = () => {
    setDismissed(true);
  };

  const handleEmptyStateClick = () => {
    reportPapEvent(
      PAP_Open_CalendarEmptyState({
        actionSurfaceComponent: 'calendar',
      }),
    );
    showAddCalendarModal({
      callback: (connector?: UIConnector) => {
        if (connector) {
          const connectorName = connector?.branding?.display_name;
          if (connectorName) {
            const title = i18n.t('app_connected', { connectorName });
            showSnackbar({ title });
            refreshConnectionsList();
          }
        }
      },
    });
  };

  if (dismissed) return <></>;

  return (
    <CalendarEmptyStateCard
      onClick={handleEmptyStateClick}
      onDismiss={handleDismiss}
    />
  );
};
