import { createContext, useContext } from 'react';
import useSprigSurvey from './useSprigSurvey';

import type { SprigSurveyContext } from './useSprigSurvey';
import type { DashSurface } from '@mirage/analytics/events/enums/dash_surface';

const SurveyContext = createContext<SprigSurveyContext | null>(null);

type SurveyProviderProps = {
  children: React.ReactNode;
  surface: DashSurface;
  environmentId: string;
  email?: string;
  disabled?: boolean;
  publicAccountId?: string;
};

export const SurveyContextProvider: React.FC<SurveyProviderProps> = ({
  children,
  ...props
}) => {
  const sprigContext = useSprigSurvey(props);
  return (
    <SurveyContext.Provider value={sprigContext}>
      {children}
    </SurveyContext.Provider>
  );
};

export const useSurveyContext = (): SprigSurveyContext => {
  const context = useContext(SurveyContext);
  if (!context) {
    throw new Error(
      'useSurveyContext must be used within a SurveyContextProvider',
    );
  }
  return context;
};
