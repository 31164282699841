import { getStacks, listAllStackItemsFromCache } from '@mirage/service-stacks';
import * as primitives from '@mirage/service-typeahead-search/service/primitives';
import * as scoring from '@mirage/service-typeahead-search/service/scoring';
import { SourceId } from '@mirage/service-typeahead-search/service/types';
import * as wrappers from '@mirage/service-typeahead-search/service/utils/wrappers';
import tokenize from '@mirage/shared/search/tokenizers';
import Sentry from '@mirage/shared/sentry';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import { TypeaheadCache } from '../typeahead-cache';
import { buildStackItem } from '../utils/stack-items';

import type { stacks } from '@dropbox/api-v2-client';
import type { typeahead } from '@mirage/service-typeahead-search/service/types';
import type { StackItem } from '@mirage/shared/search/stack-item';
import type { Observable } from 'rxjs';

const SOURCE_RESULT_LIMIT = 5;

export const search = wrappers.wrapped(SourceId.StackItems, raw);

export function raw(
  query: string,
  cache: TypeaheadCache,
): Observable<typeahead.TaggedResult> {
  return rx.defer(() => {
    // url -> nsid | We are indexing on url to check if items are unique
    const urlsToNsid: { [key: string]: Set<string> } = {};

    const candidates = Promise.all([listAllStackItemsFromCache(), getStacks()])
      .then(([stackMap, stacks]) => {
        if (!stackMap) return [] as StackItem[];
        const nsidToStack: { [nsid: string]: stacks.Stack } = {};
        stacks?.forEach((stack) => {
          if (stack.namespace_id) {
            nsidToStack[stack.namespace_id] = stack;
          }
        });

        return Object.entries(stackMap)
          .map(([stackId, stackItems]) =>
            stackItems.map((stackItem) => {
              // store stack item attribution
              if (stackItem['.tag'] === 'shortcut' && stackItem.url) {
                if (!(stackItem.url in urlsToNsid)) {
                  urlsToNsid[stackItem.url] = new Set<string>();
                }
                urlsToNsid[stackItem.url].add(stackId);
              }

              return buildStackItem(stackItem);
            }),
          )
          .flat(1)
          .map((stackItem) => {
            if (stackItem) {
              stackItem.stackIds = [...urlsToNsid[stackItem.url]].map(
                (nsid) => ({
                  name: nsidToStack[nsid].stack_data?.name,
                  namespaceId: nsid,
                }),
              );
            }
            return stackItem;
          })
          .filter(Boolean) as StackItem[];
      })
      .then((stackItems) => {
        // find query matches
        return stackItems.filter((stackItem) => {
          // skip stacks results missing a name/title
          const lctitle = stackItem.name.toLowerCase();
          const lcdescription =
            stackItem.description?.content?.toLocaleLowerCase();
          if (!lctitle) return false;

          // exact substring match
          const lcquery = query.toLowerCase();
          if (lctitle?.includes(lcquery)) {
            return true;
          }
          if (lcdescription?.includes(lcquery)) {
            return true;
          }

          // tokenize stack title
          const ttokens = tokenize(lctitle);

          // allow typos
          const qtokens = tokenize(query.toLowerCase());
          if (scoring.typoMatch(qtokens, ttokens)) return true;

          // no exact or similar match found
          return false;
        });
      })
      .then((stackItems) => stackItems.slice(0, SOURCE_RESULT_LIMIT))
      .catch((e) => {
        Sentry.captureException(e);
        return [] as StackItem[];
      });

    return rx
      .from(candidates)
      .pipe(op.mergeMap((result) => result))
      .pipe(
        op.map((result) =>
          primitives.stackItem(result.uuid, result, cache.getHits(result.uuid)),
        ),
      );
  });
}
