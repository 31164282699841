import { Text } from '@dropbox/dig-components/typography';
import ReactHighlightWords from 'react-highlight-words';

type HighlighterProps = Readonly<{
  title: string;
  disableHighlighting?: boolean;
  highlightWords?: ReadonlyArray<string>;
}>;

const Bold: React.FC = ({ children }) => (
  <Text tagName="span" isBold>
    {children}
  </Text>
);

export function Highlighter({
  title = '',
  disableHighlighting = false,
  highlightWords = [],
}: HighlighterProps) {
  if (disableHighlighting || typeof title !== 'string') {
    return <span>{title}</span>;
  }

  return (
    <ReactHighlightWords
      highlightClassName="highlight"
      searchWords={highlightWords as string[]} // third party package requires writable array, unfortunately
      autoEscape={true}
      textToHighlight={title}
      caseSensitive={false}
      highlightTag={Bold}
    />
  );
}
