import { atom, useSetAtom } from 'jotai';
import { useCallback } from 'react';

export const DRAGGABLE_CSS_CLASS = '--drag-if-possible';

// Consumers can store visibility of all overlays in this atom
// This will let us determine draggability based on whether any overlay is visible
type OverlayStateMap = Record<string, boolean>;

const overlayStateMapAtom = atom<OverlayStateMap>({});

export const useAppDragManager = () => {
  const setOverlayStateMap = useSetAtom(overlayStateMapAtom);

  const setOverlayEnabled = useCallback(
    (overlayKey: string, draggable: boolean) => {
      setOverlayStateMap((prev) => {
        // Update the state map with new value for given overlay
        const updatedStateMap = {
          ...prev,
          [overlayKey]: draggable,
        };

        // If at least one overlay is enabled, we should disable dragging
        const shouldDisableDrag = Object.values(updatedStateMap).some(Boolean);

        // Set app draggability as needed
        document.documentElement.style.setProperty(
          DRAGGABLE_CSS_CLASS,
          shouldDisableDrag ? 'nodrag' : 'drag',
        );

        return updatedStateMap;
      });
    },
    [setOverlayStateMap],
  );

  return { setOverlayEnabled };
};
