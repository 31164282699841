import { Skeleton } from '@dropbox/dig-components/skeleton';
import { Box } from '@dropbox/dig-foundations';
import classnames from 'classnames';
import { CSSProperties, forwardRef, ReactNode, Ref } from 'react';
import { TileCard } from './TileCard';
import styles from './TileCardGrid.module.css';

export const TileCardGrid = forwardRef(
  (
    {
      expanded,
      maxRows = 2,
      className,
      style,
      tileCardClassName,
      isLoading = false,
      loadingTileCount = 4,
      loadingTileCardProps,
      children,
    }: {
      expanded: boolean;
      maxRows?: number;
      className?: string;
      style?: CSSProperties;
      tileCardClassName?: string;
      isLoading?: boolean;
      loadingTileCount?: number;
      loadingTileCardProps?: Record<string, unknown>;
      children: ReactNode;
    },
    ref?: Ref<HTMLDivElement>,
  ) => {
    return (
      <div
        className={classnames(styles.wrapper, className, {
          [styles.expanded]: expanded,
        })}
        style={
          {
            '--dash-tile-card-grid__max-rows': maxRows,
            ...style,
          } as CSSProperties
        }
        ref={ref}
      >
        {isLoading && (
          <>
            {Array.from({ length: loadingTileCount }).map((_, i) => (
              <TileCard
                key={i}
                className={tileCardClassName}
                icon={<Skeleton.Box width={32} height={32} />}
                title={<Skeleton.Box height={14} />}
                metadata={
                  <Box style={{ height: 22, width: '50%' }}>
                    <Skeleton.Box height={14} />
                  </Box>
                }
                {...loadingTileCardProps}
              />
            ))}
          </>
        )}
        {!isLoading && children}
      </div>
    );
  },
);

TileCardGrid.displayName = 'TileCardGrid';
