import { BaseKeys } from './baseKeys';

const common = {
  select: 'ctrl+a',
  cut: 'ctrl+x',
  copy: 'ctrl+c',
  paste: 'ctrl+v',
  quit: 'alt+f4',
  escape: 'escape',
  checkForUpdates: 'ctrl+u',
  settings: 'ctrl+,',
} as const;

const search = {
  copySelected: 'ctrl+c',
  launchResult: 'enter',
} as const;

const typeahead = {
  copySelected: 'ctrl+c',
  moveUp: 'arrowup',
  moveDown: 'arrowdown',
  launchResult: 'enter',
  clearInput: 'escape',
  searchQuery: 'ctrl+enter',
} as const;

const resultItem = {
  launch: 'enter',
  copy: 'ctrl+c',
  moveDown: 'arrowdown',
  moveUp: 'arrowup',
} as const;

export const win32Symbols = {
  ctrl: 'CTRL',
  control: 'CTRL', // for invite page
  alt: 'ALT',
  super: 'WIN',
  up: 'UP',
  down: 'DOWN',
  right: 'RIGHT',
  left: 'LEFT',
  tab: 'TAB',
  shift: 'SHIFT',
} as const;

export const modifierKeys = {
  metaKey: 'super',
  ctrlKey: 'ctrl',
} as const;

export const mousetrapMap = {
  Control: 'ctrl',
  Meta: 'super',
  Alt: 'alt',
} as const;

export const win32Keys: BaseKeys = {
  common,
  search,
  launchResult: 'Enter',
  typeahead,
  resultItem,
};

export default win32Keys;
