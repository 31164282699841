import { Text } from '@dropbox/dig-components/typography';
import { Markdown } from '@mirage/conversations/Markdown/Markdown';
import classnames from 'classnames';
import styles from './AnswerBox.module.css';

interface AnswerBoxProps {
  body?: string;
  busy?: boolean;
  label?: string;
}

export const AnswerBox: React.FC<AnswerBoxProps> = ({ body, busy, label }) => {
  return (
    <div className={styles.answerBoxContainer}>
      {label && (
        <Text
          className={classnames(styles.answerBoxLabel, {
            [styles.smoothInText]: busy,
          })}
          variant="label"
          size="medium"
          isBold
        >
          {label}
        </Text>
      )}
      {body && (
        <div className={styles.outerWrapper}>
          <Markdown body={body} />
        </div>
      )}
    </div>
  );
};
