import { Banner } from '@dropbox/dig-components/banner';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { LockLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { Link } from '@mirage/link/Link';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import classnames from 'classnames';
import * as React from 'react';
import { Trans } from 'react-i18next';
import styles from './Onboarding.module.css';

export const PageContainer: React.FC = ({ children }) => {
  const isMobile = useIsMobileSize();
  return (
    <div
      className={classnames([
        styles.rootContainer,
        isMobile && styles.isMobile,
      ])}
    >
      {children}
    </div>
  );
};

export const PrivacyFooter = () => (
  <div className={styles.privacyFooter}>
    <Banner
      type="attention"
      className={styles.bannerPrivacyPolicy}
      withLeftIcon={<UIIcon src={LockLine} />}
    >
      <Banner.Message>
        <Text>
          <Trans
            i18nKey={'privacy_info'}
            components={{
              ppLink: (
                <Link href="https://www.dropbox.com/privacy" target="_blank" />
              ),
              faqLink: (
                <Link
                  href="https://help.dropbox.com/accounts-billing/security/privacy-policy-faq"
                  target="_blank"
                />
              ),
            }}
          />
        </Text>
      </Banner.Message>
    </Banner>
  </div>
);
