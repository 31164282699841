import { JaroWinklerDistance as jaroWinkler } from './jaro-winkler';
import { isStopWord } from './stop-words';

const DEFAULT_JARO_WINKLER_THRESHOLD = 0.9;
const TYPO_EVALUATION_THRESHOLD = 5;
const MAX_CHARACTER_DELTA = 2;

type Options = {
  scoreThreshold: number;
  evaluationThreshold: number;
  removeStopWords: boolean;
};

const doptions: Options = {
  scoreThreshold: DEFAULT_JARO_WINKLER_THRESHOLD,
  evaluationThreshold: TYPO_EVALUATION_THRESHOLD,
  removeStopWords: true,
};

// TODO: consider dynamic thresholds based on query length
export function typoMatch(
  qtokens: string[],
  ttokens: string[],
  options: Options = doptions,
): boolean {
  // strip stop words from query and title tokens
  if (options.removeStopWords) {
    qtokens = qtokens.filter((qtoken) => !isStopWord(qtoken));
    ttokens = ttokens.filter((ttoken) => !isStopWord(ttoken));
  }

  // check if query is too short for evaluation
  if (qtokens.every((qtoken) => qtoken.length < options.evaluationThreshold)) {
    return false;
  }

  // remove insignificant tokens
  qtokens = qtokens.filter(
    (qtoken) => qtoken.length >= options.evaluationThreshold,
  );

  ttokens = ttokens.filter(
    (ttoken) => ttoken.length >= options.evaluationThreshold,
  );

  // any exact prefix matches on significant query vs. title tokens?
  const prefixMatch = qtokens.some((qtoken) => {
    return ttokens.some((ttoken) => {
      return ttoken.startsWith(qtoken);
    });
  });

  if (prefixMatch) return true;

  // jaro winkler similarity
  return qtokens.some((qtoken) => {
    return ttokens.some((ttoken) => {
      // don't consider tokens of significantly different length
      const delta = Math.abs(qtoken.length - ttoken.length);
      if (delta >= MAX_CHARACTER_DELTA) return false;

      // don't consider tokens that start with different characters
      if (qtoken[0] !== ttoken[0]) return false;

      return jaroWinkler(qtoken, ttoken) >= options.scoreThreshold;
    });
  });
}
