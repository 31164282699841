import { atom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

export const mouseActivityAtom = atom<boolean>(true);

// Mouse hover can effect impact keyboard navigation, so we need to track
// when it's active and when it's not
export function useInitDetectMouseActivity() {
  // Store the activity state in an atom so components can read it without setting up redundant event liseteners
  const setIsMouseActive = useSetAtom(mouseActivityAtom);

  useEffect(() => {
    function activateMouse() {
      setIsMouseActive(true);
    }
    function deactivateMouse() {
      setIsMouseActive(false);
    }

    document.addEventListener('mousemove', activateMouse);
    document.addEventListener('keydown', deactivateMouse);

    return () => {
      document.removeEventListener('mousemove', activateMouse);
      document.removeEventListener('keydown', deactivateMouse);
    };
  }, [setIsMouseActive]);
}
