import { dash_feed } from '@dropbox/api-v2-client';
import { Text } from '@dropbox/dig-components/typography';
import { Trans } from 'react-i18next';
import { ActivityLink } from '../ActivityLink/ActivityLink';

type EditStackActivityActionProps = {
  activity: dash_feed.ActivityItem;
  action: dash_feed.object_action_unionEdit;
};

export const EditStackActivityAction = ({
  activity,
  action,
}: EditStackActivityActionProps) => {
  if (!action.updates || action.updates.length == 0) {
    return null;
  }

  const firstUpdate = action.updates[0].update;
  if (firstUpdate?.['.tag'] == 'stack_update') {
    const updatedField = firstUpdate.field;
    switch (updatedField?.['.tag']) {
      case 'name':
        return (
          <EditStackNameActivityAction
            activity={activity}
            update={updatedField}
          />
        );
      case 'description':
        return (
          <EditStackDescriptionActivityAction
            activity={activity}
            update={updatedField}
          />
        );
      case 'is_archived':
        return (
          <ArchiveStackActivityAction
            activity={activity}
            update={updatedField}
          />
        );
      default:
        return null;
    }
  }

  return null;
};

type EditStackNameActivityActionProps = {
  activity: dash_feed.ActivityItem;
  update: dash_feed.field_unionName;
};

const EditStackNameActivityAction = ({
  activity,
  update,
}: EditStackNameActivityActionProps) => {
  const actorName = activity.actor!.name;
  const newTitle = update.new_value;
  const oldTitle = update.old_value;

  // John Doe changed the title of your “Old Title” stack to “New Title”
  return (
    <Text variant="label" isBold>
      <Trans
        i18nKey="activity_feed_action_edit_stack_name_owner"
        values={{ actorName, oldTitle, newTitle }}
        components={{
          stackLink: <ActivityLink href={activity.object!.url!} />,
        }}
      />
    </Text>
  );
};

type EditStackDescriptionActivityActionProps = {
  activity: dash_feed.ActivityItem;
  update: dash_feed.field_unionDescription;
};

const EditStackDescriptionActivityAction = ({
  activity,
  update,
}: EditStackDescriptionActivityActionProps) => {
  const actorName = activity.actor!.name;
  const stackName = activity.object!.name;
  const newDescription = update.new_value;
  const oldDescription = update.old_value;

  // John Doe changed the description of your Project Planning stack from “ABC Description” to “XYZ Description”
  return (
    <Text variant="label" isBold>
      <Trans
        i18nKey="activity_feed_action_edit_stack_description_owner"
        values={{ actorName, stackName, newDescription, oldDescription }}
        components={{
          stackLink: <ActivityLink href={activity.object!.url!} />,
        }}
      />
    </Text>
  );
};

type ArchiveStackActivityActionProps = {
  activity: dash_feed.ActivityItem;
  update: dash_feed.field_unionIsArchived;
};

const ArchiveStackActivityAction = ({
  activity,
  update,
}: ArchiveStackActivityActionProps) => {
  const actorName = activity.actor!.name;
  const stackName = activity.object!.name;
  const isArchived = update.new_value;

  if (isArchived) {
    // John Doe changed the title of your “Old Title” stack to “New Title”
    return (
      <Text variant="label" isBold>
        <Trans
          i18nKey="activity_feed_action_archive_stack_owner"
          values={{ actorName, stackName }}
          components={{
            stackLink: <ActivityLink href={activity.object!.url!} />,
          }}
        />
      </Text>
    );
  } else {
    // we currently don't display activities if they are not archived
    return null;
  }
};

type ShareStackActivityActionProps = {
  activity: dash_feed.ActivityItem;
  action: dash_feed.object_action_unionShare;
};

export const ShareStackActivityAction = ({
  activity,
}: ShareStackActivityActionProps) => {
  const actorName = activity.actor!.name;
  const stackName = activity.object!.name;

  // John Doe shared Project Planning stack with you
  return (
    <Text variant="label" isBold>
      <Trans
        i18nKey="activity_feed_action_share_stack_with_you"
        values={{ actorName, stackName }}
        components={{
          stackLink: <ActivityLink href={activity.object!.url!} />,
        }}
      />
    </Text>
  );
};

type AddStackItemActivityActionProps = {
  activity: dash_feed.ActivityItem;
  action: dash_feed.object_action_unionAddChild;
};

export const AddStackItemActivityAction = ({
  activity,
  action,
}: AddStackItemActivityActionProps) => {
  const actorName = activity.actor?.name;
  const addedItemName = action.name;
  const stackName = activity.object!.name;

  return (
    <Text variant="label" isBold>
      <Trans
        i18nKey="activity_feed_action_add_stack_item_owner"
        values={{ actorName, addedItemName, stackName }}
        components={{
          addedItemLink: <ActivityLink href={action.url!} />,
          stackLink: <ActivityLink href={activity.object!.url!} />,
        }}
      />
    </Text>
  );
};

type RemoveChildActivityActionProps = {
  activity: dash_feed.ActivityItem;
  action: dash_feed.object_action_unionRemoveChild;
};

export const RemoveStackItemActivityAction = ({
  activity,
  action,
}: RemoveChildActivityActionProps) => {
  const actorName = activity.actor?.name;
  const removedItemName = action.name;
  const stackName = activity.object!.name;

  // John Doe removed Klien Project Planning from your Project Planning stack
  return (
    <Text variant="label" isBold>
      <Trans
        i18nKey="activity_feed_action_remove_stack_item_owner"
        values={{ actorName, removedItemName, stackName }}
        components={{
          removedItemLink: <ActivityLink href={action.url!} />,
          stackLink: <ActivityLink href={activity.object!.url!} />,
        }}
      />
    </Text>
  );
};
