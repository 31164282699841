import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Add_DashConnectedAccount } from '@mirage/analytics/events/types/add_dash_connected_account';
import { PAP_Shown_DashConnectAppPage } from '@mirage/analytics/events/types/shown_dash_connect_app_page';
import { listConnections, listConnectors } from '@mirage/service-connectors';
import { Connector, ConnectorConnection } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { useConnectConnector } from '@mirage/settings/hooks/connectors/useConnectConnector';
import i18n from '@mirage/translations';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GrantPermissionPage from './GrantPermissionPage';
import { useFinishOnboarding } from './utils';

import type { UIConnector } from '@mirage/shared/types';

const logger = tagged('OneClickOnboarding');

type OneClickOnboardingStage =
  | 'auth-prompt'
  | 'auth-clicked'
  | 'syncing-progress';

export default function OneClickOnboarding() {
  const navigate = useNavigate();
  const [dbxConnector, setDbxConnector] = useState<UIConnector>();
  const [onboardingStage, setOnboardingStage] =
    useState<OneClickOnboardingStage>('auth-prompt');
  const { reportPapEvent } = useMirageAnalyticsContext();
  const connectConnector = useConnectConnector();
  const finishOnboarding = useFinishOnboarding(reportPapEvent, 'ONE_CLICK_V1');

  useEffect(() => {
    reportPapEvent(
      PAP_Shown_DashConnectAppPage({
        inOnboardingFlow: true,
        actionEntryEntryPoint: 'dbx_one_click_onboarding',
      }),
    );

    listConnectors().then((data) => {
      data.map((connector: Connector) => {
        if (connector?.id_attrs?.type === 'dropbox') {
          setDbxConnector({
            ...connector,
            loading: false,
          });
        }
      });
    });
  }, [reportPapEvent]);

  useEffect(() => {
    listConnections().then((data) => {
      const dbxConnection = data.find(
        (connection: ConnectorConnection) =>
          connection.id_attributes?.connector?.type === 'dropbox',
      );
      if (dbxConnection) {
        navigate(RoutePath.ROOT);
      }
    });
  }, [navigate]);

  const connectDropbox = async () => {
    if (dbxConnector) {
      setOnboardingStage('auth-clicked');
      try {
        const result = await connectConnector(dbxConnector, undefined);
        if (result.success) {
          setOnboardingStage('syncing-progress');
          reportPapEvent(PAP_Add_DashConnectedAccount());
        } else {
          setOnboardingStage('auth-prompt');
        }
      } catch (e) {
        logger.error(e);
      }
    }
  };

  useEffect(() => {
    if (onboardingStage === 'syncing-progress') {
      finishOnboarding();
    }
  }, [onboardingStage, finishOnboarding]);

  switch (onboardingStage) {
    case 'auth-prompt':
      return (
        <GrantPermissionPage
          loading={!dbxConnector}
          buttonText={i18n.t('onboarding_add_files_btn')}
          onClick={connectDropbox}
          onSkip={finishOnboarding}
        />
      );
    case 'auth-clicked':
      return (
        <GrantPermissionPage
          loading={true}
          buttonText={i18n.t('onboarding_add_files_btn')}
          onClick={connectDropbox}
          onSkip={finishOnboarding}
        />
      );
    default:
      return <></>;
  }
}
