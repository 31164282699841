import { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { FullPageSpinner } from "@mirage/mosaics/FullPageSpinner";
import { SplashPage } from "@mirage/mosaics/Splash";
import { isIframe } from "@mirage/shared/util/tiny-utils";
import i18n from "@mirage/translations";
import { RoutePath } from "@mirage/webapp/routeTypes";

import { Config } from "../shared/config";

import { REDIRECT_TO_PATH_URL_PARAM } from "./Login";

/**
 * Note that this page can be viewed when logged in or logged out, as the
 * login state doesn't really affect what we need to display and do.
 */
export const Welcome: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const pendingTeamInvite = params.get("pending_team_invite");

  const [loggingOut] = useState(false);

  const handleLogin = useCallback(() => {
    const query = new URLSearchParams(location.search);
    const redirectURI = query.get("redirect_uri");
    let redirectPath = "";
    // verify URI is encoded
    if (redirectURI && decodeURIComponent(redirectURI) === redirectURI) {
      redirectPath = `?${REDIRECT_TO_PATH_URL_PARAM}=${encodeURIComponent(
        redirectURI,
      )}`;
    } else if (Config.OAUTH_REDIRECT_URL) {
      const redirectURL = `${location.protocol}//${location.host}${RoutePath.OAUTH}`;
      redirectPath = `?${REDIRECT_TO_PATH_URL_PARAM}=${encodeURIComponent(
        redirectURL,
      )}`;
    }

    const path = `${RoutePath.LOGIN}${redirectPath}`;

    if (isIframe()) {
      // If we're in an iframe, open the login page in a new tab
      window.open(path, "_blank", "noopener,noreferrer");
    } else {
      navigate(path);
    }
  }, [navigate]);

  if (loggingOut) {
    return <FullPageSpinner spinnerId="Welcome_loggingOut" />;
  }

  return (
    <SplashPage
      stage="login"
      onLogin={handleLogin}
      snackbarText={
        pendingTeamInvite ? i18n.t("logout_screen_purchaser_note") : undefined
      }
    />
  );
};
