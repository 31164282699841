import { dash } from '@dropbox/api-v2-client';
import { Text } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Click_JoinMeeting } from '@mirage/analytics/events/types/click_join_meeting';
import { LinkComponent, LinkList } from '@mirage/link-list';
import { ListItemSize } from '@mirage/link-list/types';
import { useConnectedCalendars } from '@mirage/mosaics/WorkingSet/Calendar/useConnectedCalendars';
import { useUpcomingCalendarEvents } from '@mirage/service-calendar-events/hooks';
import {
  HomePageModule,
  MetricPageName,
} from '@mirage/service-operational-metrics/module/constants';
import { useRecordModuleLatency } from '@mirage/service-operational-metrics/module/module';
import {
  getFreshLoginTags,
  useFreshLoginTags,
} from '@mirage/service-operational-metrics/module/tags';
import { DefaultCustomizableModuleId } from '@mirage/service-settings/service/customize';
import { CollapsedCardKey } from '@mirage/service-settings/service/types';
import { useCollapsedCardSettings } from '@mirage/service-settings/useCollapsedCardSettings';
import { useCustomizableModuleIsHidden } from '@mirage/service-settings/useCustomizableModuleSettings';
import { EventResultIcon } from '@mirage/shared/icons/EventResultIcon';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import {
  getEventTimeString,
  getMinutesAndHoursUntilOrFromStartOfEvent,
} from '@mirage/shared/util/calendar';
import i18n from '@mirage/translations';
import { useEffect, useMemo } from 'react';
import {
  convertTimeToHumanReadable,
  getHeaderEventIds,
  useCalendarEventState,
} from './calendar-util';
import { CalendarEmptyStateV2 } from './CalendarEmptyStateV2';
import styles from './CalendarModuleV2.module.css';

const MAX_EVENTS_TO_SHOW_WHILE_COLLAPSED = 3;
const MAX_EVENTS_TO_SHOW_WHILE_EXPANDED = 8;

export const CalendarModuleV2 = () => {
  const isHidden = useCustomizableModuleIsHidden(
    DefaultCustomizableModuleId.CALENDAR,
  );

  return isHidden ? null : <CalendarModuleV2Actual />;
};

const CalendarModuleV2Actual = () => {
  const freshLoginTags = useFreshLoginTags();

  const settingId: CollapsedCardKey = 'calendar';
  const { isCollapsed } = useCollapsedCardSettings(settingId);

  const { markModuleLoadComplete } = useRecordModuleLatency(
    MetricPageName.HOME_V2,
    HomePageModule.CALENDAR_V2,
    freshLoginTags || getFreshLoginTags,
  );

  const { upcomingCalendarEvents: calendarEvents } =
    useUpcomingCalendarEvents();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { calendars } = useConnectedCalendars();

  const headerEventIds = useMemo(
    () => getHeaderEventIds(calendarEvents ?? []).map((event) => event.id),
    [calendarEvents],
  );

  // Get the zoom link if there is one, otherwise default to the calendar link itself
  const getUrlFromEvent = (event: dash.SearchResult) => {
    if (
      event.conference_links &&
      event.conference_links.length > 0 &&
      event.conference_links[0].link
    ) {
      return event.conference_links[0].link;
    }
    return event.url;
  };

  const onJoinMeeting = (event: dash.SearchResult) => {
    reportPapEvent(
      PAP_Click_JoinMeeting({
        meetingId: event.uuid,
        featureLine: 'calendar',
      }),
    );
  };

  useEffect(() => {
    if (calendarEvents) {
      markModuleLoadComplete();
    }
  }, [calendarEvents, markModuleLoadComplete]);

  // Wait until they are fetched to show empty state
  if (calendarEvents === undefined) {
    return null;
  }

  // If the user doesn't have a calendar connector, return nothing
  if (!calendars.length && calendarEvents.length === 0) {
    return <CalendarEmptyStateV2 />;
  }

  const isAlwaysCollapsed =
    calendarEvents.length > MAX_EVENTS_TO_SHOW_WHILE_COLLAPSED;
  const hasNoEvents = calendarEvents.length === 0;
  const calendarEventsToShow = calendarEvents.slice(
    0,
    isCollapsed
      ? MAX_EVENTS_TO_SHOW_WHILE_COLLAPSED
      : MAX_EVENTS_TO_SHOW_WHILE_EXPANDED,
  );

  return (
    <TwoColumnGridCard
      settingId={settingId}
      cardTypeProps={{
        cardType: CardHeaderType.INLINE_SUBTITLE,
        title: i18n.t('upcoming_meetings'),
        actionSurfaceComponent: 'calendar',
        featureLine: 'calendar',
      }}
      showDividerLine={false}
      isAlwaysCollapsed={isAlwaysCollapsed}
      subtitle={hasNoEvents ? i18n.t('calendar_empty_text') : undefined}
    >
      {hasNoEvents ? (
        <> </>
      ) : (
        <div>
          <LinkList listItemSize={ListItemSize.Large}>
            {calendarEventsToShow.map((event) => {
              const metadata = [
                <CalendarSubtext
                  event={event}
                  isInHeader={false}
                  determineEventState={headerEventIds.includes(
                    event.uuid ?? '',
                  )}
                  key={event.uuid}
                />,
              ];
              return (
                <LinkComponent
                  link={{
                    title: event.title,
                    id: event.uuid,
                    url: getUrlFromEvent(event) ?? '',
                  }}
                  icon={<EventResultIcon event={event} size="large" />}
                  listItemSize={ListItemSize.Large}
                  key={event.uuid}
                  metadata={metadata}
                  onOpenLink={() => onJoinMeeting(event)}
                  forceContentRowEnabled={true}
                />
              );
            })}
          </LinkList>
        </div>
      )}
    </TwoColumnGridCard>
  );
};

type CalendarSubtextProps = {
  event: dash.SearchResult;
  isInHeader: boolean;
  determineEventState?: boolean;
};

export const CalendarSubtext: React.FC<CalendarSubtextProps> = ({
  event,
  isInHeader,
  determineEventState = false,
}) => {
  const eventState = useCalendarEventState(
    event,
    isInHeader || determineEventState,
  );

  const showMoreMeetingText = eventState !== 'not_relevant';
  const meetingText = `${convertTimeToHumanReadable(
    event.start_time,
    event.end_time,
  )}
  ${showMoreMeetingText ? ' ·' : ''}`;
  const { hours, minutes } = showMoreMeetingText
    ? getMinutesAndHoursUntilOrFromStartOfEvent(event, eventState)
    : { hours: 0, minutes: 0 };

  return (
    <div className={styles.meetingTimeContainer}>
      <Text size={isInHeader ? 'medium' : 'small'} color="faint">
        {meetingText}
      </Text>
      {showMoreMeetingText && (
        <Text
          isBold
          size={isInHeader ? 'medium' : 'small'}
          style={{
            color:
              eventState === 'in_progress'
                ? 'var(--dig-color__success__base--state-1)'
                : 'var(--dig-color__primary__base)',
          }}
        >
          {getEventTimeString(eventState, hours, minutes)}
        </Text>
      )}
    </div>
  );
};
