import { Button, ButtonProps } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  EditLine,
  PersonHidePictogram,
  ShowLine,
} from '@dropbox/dig-icons/assets';
import i18n from '@mirage/translations';
import React from 'react';
import { UnthemedWrapper } from '../UnthemedWrapper';
import styles from './ShareModal.module.css';
import { SharingStackPermission, StackPermission } from './Types';
import { textForPermission } from './Utils';

export const POSSIBLE_SHARING_PERMISSIONS: SharingStackPermission[] = [
  StackPermission.WRITE,
  StackPermission.READ,
];

export const PermissionsMenu = ({
  sharePermissions: sharePermissions,
  buttonProps,
  withFaintStyle,
  isLocalUserMenu,
  onSelectSharePermissions,
  onRevoke,
  marchUpdateEnabled,
}: {
  sharePermissions: SharingStackPermission;
  buttonProps: ButtonProps;
  withFaintStyle: boolean;
  // If the menu is controlling the local user
  isLocalUserMenu: boolean;
  onSelectSharePermissions: (permissions: SharingStackPermission) => void;
  onRevoke?: () => void;
  marchUpdateEnabled: boolean;
}) => {
  const MenuComponent = marchUpdateEnabled ? Menu.ActionItem : Menu.SelectItem;
  return (
    <UnthemedWrapper>
      <Menu.Wrapper
        onSelection={(permissions: SharingStackPermission | 'revoke') => {
          if (permissions === 'revoke') {
            onRevoke?.();
          } else {
            onSelectSharePermissions(permissions);
          }
        }}
      >
        {({ getContentProps, getTriggerProps }) => (
          <>
            <Button
              {...getTriggerProps()}
              {...buttonProps}
              size="large"
              withDropdownIcon={!marchUpdateEnabled}
            >
              {marchUpdateEnabled ? (
                <NewPermissionIcon
                  permission={sharePermissions}
                  withFaintStyle={withFaintStyle}
                />
              ) : (
                <Text
                  size={withFaintStyle ? 'small' : undefined}
                  isBold={withFaintStyle}
                  className={
                    withFaintStyle ? styles.faintText : styles.standardText
                  }
                >
                  {textForPermission(sharePermissions)}
                </Text>
              )}
            </Button>
            <Menu.Content
              {...getContentProps()}
              placement="bottom-end"
              className={marchUpdateEnabled ? styles.userPermissionMenu : ''}
            >
              {!isLocalUserMenu && (
                <Menu.Segment>
                  {POSSIBLE_SHARING_PERMISSIONS.map((permissions) => (
                    <MenuComponent
                      key={permissions}
                      value={permissions}
                      selected={
                        !marchUpdateEnabled && sharePermissions === permissions
                      }
                    >
                      <div className={styles.permissionRow}>
                        {textForPermission(permissions)}
                        {marchUpdateEnabled ? (
                          <NewPermissionIcon
                            permission={permissions}
                            withFaintStyle={false}
                          />
                        ) : null}
                      </div>
                    </MenuComponent>
                  ))}
                </Menu.Segment>
              )}
              {onRevoke && (
                <Menu.Segment>
                  <MenuComponent
                    key="revoke"
                    value="revoke"
                    selected={false}
                    withLeftAccessory={
                      !marchUpdateEnabled ? (
                        <UIIcon src={PersonHidePictogram} />
                      ) : undefined
                    }
                  >
                    {i18n.t(isLocalUserMenu ? 'leave' : 'remove')}
                  </MenuComponent>
                </Menu.Segment>
              )}
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
    </UnthemedWrapper>
  );
};

export const NewPermissionIcon = ({
  permission,
  withFaintStyle,
}: {
  permission: StackPermission;
  withFaintStyle: boolean;
}) => {
  switch (permission) {
    case StackPermission.OWNER:
      return (
        <Text
          size={withFaintStyle ? 'small' : undefined}
          isBold={withFaintStyle}
          className={withFaintStyle ? styles.faintText : styles.standardText}
        >
          <UIIcon src={EditLine} />
        </Text>
      );
    case StackPermission.WRITE:
      return <UIIcon src={EditLine} />;
    case StackPermission.READ:
      return <UIIcon src={ShowLine} />;
  }
};
