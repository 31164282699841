import { Badge } from '@dropbox/dig-components/badge';
import { Box } from '@dropbox/dig-foundations';
import { DashWordmark, WordmarkLogo } from '@dropbox/dig-logos';
import i18n from '@mirage/translations';
import * as React from 'react';
import styles from './OnboardingHeader.module.css';
import { Stepper } from './Stepper';

export const OnboardingHeader: React.FC<{ justifyCenter?: boolean }> = ({
  justifyCenter = false,
}) => {
  return (
    <Box
      className={styles.header}
      justifyContent={justifyCenter ? 'center' : undefined}
    >
      <DefaultOnboardingHeaderContents />
    </Box>
  );
};

const DefaultOnboardingHeaderContents: React.FC = () => {
  return (
    <>
      <WordmarkLogo
        color="var(--dig-color__text__base)"
        size={32}
        src={DashWordmark}
      />
      <Badge style={{ userSelect: 'none' }}>{i18n.t('beta')}</Badge>
    </>
  );
};

interface Props {
  currentStep: number;
  totalSteps: number;
}
export const OnboardingHeaderWithStepper: React.FC<Props> = ({
  currentStep,
  totalSteps,
}) => {
  return (
    <div className={styles.header}>
      <DefaultOnboardingHeaderContents />
      <div className={styles.stepper}>
        <Stepper currentStep={currentStep} totalSteps={totalSteps} />
      </div>
    </div>
  );
};
