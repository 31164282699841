import { Banner } from '@dropbox/dig-components/banner';
import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { GlobalBannerProps } from '@mirage/mosaics/GlobalBanners/GlobalBannersProps';
import useConnectors from '@mirage/service-connectors/useConnectors';
import i18n from '@mirage/translations';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useConnectorsUI } from '../SettingsPage/useConnectorsUI';
import styles from './ConnectorIssuesNotificationBanner.module.css';

export default function ConnectorIssuesNotificationBanner(
  props: GlobalBannerProps,
) {
  // Hooks
  const { openConnectors } = useConnectorsUI();
  const {
    hasReauthConnections,
    hasExpiredConnections,
    refreshConnectionsList,
  } = useConnectors();
  const location = useLocation();

  // State
  const [displayBannerExpired, setDisplayBannerExpired] =
    useState<boolean>(false);
  const [displayBannerReauth, setDisplayBannerReauth] =
    useState<boolean>(false);

  useEffect(() => {
    refreshConnectionsList();
  }, [refreshConnectionsList]);

  useEffect(() => {
    setDisplayBannerReauth(hasReauthConnections);
  }, [hasReauthConnections]);

  useEffect(() => {
    setDisplayBannerExpired(hasExpiredConnections);
  }, [hasExpiredConnections]);

  // No need to display the banner on the settings page.
  if (
    location.pathname.indexOf('/settings') == 0 ||
    !(displayBannerExpired || displayBannerReauth)
  ) {
    return null;
  }

  return (
    <div className={props.bannerClass}>
      <Banner type="alert" className={styles.messageBannerContainer}>
        <Banner.Message>
          <Text>
            {displayBannerExpired
              ? i18n.t('connected_apps_issue')
              : i18n.t('connected_apps_issue_reauth')}
          </Text>
        </Banner.Message>
        <Button variant="transparent" onClick={() => openConnectors()}>
          {i18n.t('connected_apps_issue_ack_btn')}
        </Button>
      </Banner>
    </div>
  );
}
