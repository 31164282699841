import { Avatar } from '@dropbox/dig-components/avatar';
import { Box } from '@dropbox/dig-foundations';
import { getInitialsFromPerson } from '@mirage/shared/account';
import { PersonObject } from '@mirage/shared/search/search-filters';
import { AuthorInfo } from '@mirage/shared/search/search-result';
import i18n, { I18nKey } from '@mirage/translations';
import React from 'react';
import styles from './PersonMetadata.module.css';

export const PersonMetadata = ({
  i18nKey,
  person,
  onClickPerson,
}: {
  i18nKey: I18nKey;
  person: AuthorInfo | undefined | null;
  onClickPerson?: (person: PersonObject) => void;
}) => {
  const displayName = person?.displayName || person?.email;
  const initials = getInitialsFromPerson({
    displayName: person?.displayName,
    email: person?.email || '',
  });

  const handleClickPerson = (person: AuthorInfo | undefined | null) => {
    const { email = '', displayName, profilePhotoUrl } = person || {};
    onClickPerson?.({
      email,
      displayName,
      profilePhotoUrl,
    });
  };

  return (
    <div className={styles.container}>
      <Avatar
        size="xsmall"
        hasNoOutline
        src={person?.profilePhotoUrl}
        alt={initials}
        className={styles.avatar}
      >
        {initials}
      </Avatar>
      <span>
        {i18n.t(i18nKey)}
        &nbsp;
        {onClickPerson ? (
          <Box
            as="button"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              e.stopPropagation();
              handleClickPerson(person);
            }}
            fontFamily="Text"
            color={{
              default: 'Text Subtle',
              hover: 'Text Base',
            }}
            className={styles.button}
          >
            {displayName}
          </Box>
        ) : (
          displayName
        )}
      </span>
    </div>
  );
};
