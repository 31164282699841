import { Button } from '@dropbox/dig-components/buttons';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Snackbar } from '@dropbox/dig-components/snackbar';
import i18n from '@mirage/translations';
import { useCallback, useEffect, useState } from 'react';
import styles from './SnackbarView.module.css';
import { SnackbarProps } from './types';

export function SnackbarView({
  title,
  timeoutMs = 5000,
  onClose,
  buttons,
  showSpinner,
  onDismiss: onDismissProp,
  marginBottom,
}: SnackbarProps) {
  const [open, setOpen] = useState(false);

  // Enable open animation.
  useEffect(() => {
    setOpen(true);
  }, []);

  const onDismiss = useCallback(
    (userClickedDismissButton?: boolean) => {
      onDismissProp?.(userClickedDismissButton ?? false);
      // Enable close animation.
      setOpen(false);
      setTimeout(() => onClose?.(), 300);
    },
    [onClose, onDismissProp],
  );

  return (
    <div
      className={styles.container}
      style={{ marginBottom: `${marginBottom}px` }}
    >
      <Snackbar
        open={open}
        preferComposition
        timeout={timeoutMs}
        onRequestClose={onDismiss}
        className={styles.snackbar}
      >
        {showSpinner && (
          <Snackbar.Accessory>
            <Spinner aria-valuetext={i18n.t('loading')} size="small" />
          </Snackbar.Accessory>
        )}
        <Snackbar.Content>
          <Snackbar.Message className={styles.message}>
            {title}
          </Snackbar.Message>
          {(!!onClose || (buttons && buttons.length > 0)) && (
            <Snackbar.Actions>
              {(buttons ?? []).map(({ label, onClick }, index) => (
                <Button
                  key={index}
                  variant="transparent"
                  onClick={() => {
                    onClick();
                    onDismiss();
                  }}
                >
                  {label}
                </Button>
              ))}
              {!!onClose && (
                <Button variant="transparent" onClick={() => onDismiss(true)}>
                  Dismiss
                </Button>
              )}
            </Snackbar.Actions>
          )}
        </Snackbar.Content>
      </Snackbar>
    </div>
  );
}
