import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type { APIv2AuthType, Service } from '@mirage/service-dbx-api/service';
import type { SearchResult } from '@mirage/service-dbx-api/service/search';
export type { SearchResult };
import {
  LinkSummarySuccess,
  Question,
  QuestionAnswer,
} from '@mirage/shared/stack-item-summary/types';
import {
  DocSummaryQnaResult,
  DocSummaryQnaSuccess,
} from './service/grpc/context_engine_apiv2/types';
import { APIResponse, APIRoute } from './service/types';

import type { dash, Dropbox } from '@dropbox/api-v2-client';
import type {
  Connector,
  ConnectorConnection,
} from '@mirage/service-connectors/service';
import type { SearchFilter } from '@mirage/shared/search/search-filters';

export type { Service };
export { ConnectorConnection, Connector };

const service = services.get<Service>(ServiceId.DBX_API);

export function callApiV2<Route extends APIRoute>(
  apiName: Route,
  args: Parameters<Dropbox[Route]>[0],
  // Important: default value must be specified here.
  authType: APIv2AuthType = 'user',
): Promise<APIResponse<Route>> {
  return rx.firstValueFrom(
    service.callApiV2(apiName, args, authType),
  ) as Promise<APIResponse<Route>>;
}

export function fetchTypeaheadRecommendations() {
  return rx.firstValueFrom(service.fetchTypeaheadRecommendations());
}

export function fetchSearchResults(
  searchQuery: string,
  filters: SearchFilter[] = [],
) {
  return rx.firstValueFrom(service.fetchSearchResults(searchQuery, filters));
}

export function fetchUpstreamForConnection(
  searchQuery: string,
  connection: ConnectorConnection,
  filters: SearchFilter[] = [],
) {
  return rx.firstValueFrom(
    service.fetchUpstreamForConnection(searchQuery, connection, filters),
  );
}

export function fetchImplicitAnswer(searchQuery: string) {
  return rx.firstValueFrom(service.fetchImplicitAnswer(searchQuery));
}

export function fetchAnswersForQuery(searchQuery: string) {
  return rx.firstValueFrom(service.fetchAnswersForQuery(searchQuery));
}

export function fetchConversation(
  query: string,
  attachments?: dash.Attachment[],
  conversationId?: string,
) {
  return rx.firstValueFrom(
    service.fetchConversation(query, attachments, conversationId),
  );
}

export function getLinkSummary(
  url: string,
  regenerate: boolean,
): rx.Observable<LinkSummarySuccess> {
  return service.grpc.getLinkSummary(url, regenerate);
}

export function getAnswerForQuestion(
  summary: LinkSummarySuccess,
  question: Question,
  id: string,
): rx.Observable<QuestionAnswer> {
  return service.grpc.getAnswerForQuestion(summary, question, id);
}

export function getDocSummary(
  id: string,
  answerId: string,
  initialQuery: string,
  connectorName: string,
  recordType: string,
): rx.Observable<DocSummaryQnaResult> {
  return service.grpc.getDocSummary(
    id,
    answerId,
    initialQuery,
    connectorName,
    recordType,
  );
}

export function getDocAnswer(
  id: string,
  answerId: string,
  question: string,
  previousSummary?: DocSummaryQnaSuccess,
): rx.Observable<DocSummaryQnaResult> {
  return service.grpc.getDocAnswer(id, answerId, question, previousSummary);
}
