import * as React from "react";
import {
  Box,
  type TShirtSizes,
  type Atoms,
  withShade,
} from "@dropbox/dig-foundations";
import css from "./Layout.module.css";
import { ButtonAnchor } from "../button-anchor";
import classNames from "classnames";
import { sizeFactory } from "../../providers/size";

export type IconSizes = Exclude<TShirtSizes, "xsmall">;
export interface IconLayoutProps extends React.AriaAttributes {
  /**
   * The size of the icon.
   * The `small` size will automatically hide what has been placed in `withBadge`.
   *
   * @default xlarge
   */
  size?: IconSizes;
  /**
   * This will place a badge containing an icon in one of the four corners of the `IconLayout`.
   */
  withBadge?: React.ReactNode;
  /**
   * The content of the `IconLayout`. Ideally an SVG or img element.
   */
  content: React.ReactNode;
  /**
   * If the icon should have a background color.
   *
   * @default false
   */
  hasBackground?: boolean;
  /**
   * If the icon should have a border.
   *
   * @default false
   */
  hasBorder?: boolean;
  children?: never;
  className?: string;
  role?: React.AriaRole;
  style?: React.CSSProperties;

  /**
   * The background color of the icon.
   *
   * @default Background Base
   */
  backgroundColor?: Atoms["backgroundColor"];

  /**
   * The color of text within the `IconLayout`.
   */
  textColor?: Atoms["color"];

  /**
   * If this should accept a click callback, if this is used this will render as a `button` element.
   */
  onClick?: React.DOMAttributes<
    HTMLAnchorElement | HTMLButtonElement
  >["onClick"];

  /**
   * If this should change the route, pass this component an `href` which will render this as a `a` element.
   * If both `onClick` and `href` are passed, `href` will take precedence and render an `a` element.
   */
  href?: string;

  /**
   * If the content of the Icon layout should be constrained in size.
   * @default true
   */
  shouldConstrainContent?: boolean;

  /**
   * The variant of the shape of the icon.
   * @default square
   */
  shapeVariant?: "circle" | "square";
}

export const { useSize: useIconSize, SizeProvider: IconSizeProvider } =
  sizeFactory<IconSizes>({
    defaultSizeContext: { size: "xlarge" },
  });

/**
 * This component serves as the basis for all other icon component layouts in the library.
 * It provides a consistent layout for icons and their badge placements.
 *
 * **Note** The small size will automatically hide the `IconLayout.Badge` component.
 */
export const _IconLayout: React.FC<IconLayoutProps> = ({
  size: _size,
  withBadge,
  className,
  content,
  hasBackground,
  hasBorder,
  role = "presentation",
  backgroundColor = "Background Base",
  shouldConstrainContent = true,
  textColor,
  shapeVariant = "square",
  onClick,
  href,
  ...rest
}) => {
  const size = useIconSize({ size: _size });
  // If we have a href we need to render an anchor element, if we have an onClick we need to render a button element
  // if both `href` and `onClick` are passed, we will render an anchor element.
  // If neither are passed, we can safely assume this is a non-interactive element and render a span.
  const elementToRender = href ? "a" : onClick ? "button" : "span";
  const isButton = elementToRender === "button";
  const isInteractive = isButton || elementToRender === "a";
  // If this is an interactive element we should not set a role, as the element will already have a semantic role.
  const _role = isInteractive ? undefined : role;
  const cls = classNames(css["root"], className, {
    [css["root-size-small"]]: size === "small",
    [css["root-size-medium"]]: size === "medium",
    [css["root-size-large"]]: size === "large",
    [css["root-size-xlarge"]]: size === "xlarge",
    [css["root-has-border"]]: hasBorder,
    [css["root-shape-variant-circle"]]: shapeVariant === "circle",
  });

  const { className: shadeClass, style: shadeStyle } = withShade({});
  const shouldShowBadge = !!withBadge && size !== "small";
  return (
    <Box
      as={elementToRender === "span" ? elementToRender : ButtonAnchor}
      backgroundColor={hasBackground ? backgroundColor : "transparent"}
      color={textColor}
      className={cls}
      role={_role}
      onClick={onClick}
      outline={isInteractive ? { focusVisible: "Focus Outline" } : undefined}
      {...rest}
      // @ts-expect-error This works, the types are just funky cause `Box` doesn't know if its a `button`, `a` or a `span` at this point
      type={isButton ? "button" : undefined}
      href={href}
    >
      {/* If this is a button we need a hover state, but we can't attach this to the root container
      since it implies a `overflow: hidden` which means we end up cutting off the badge */}
      {isInteractive && (
        <Box
          as="span"
          aria-hidden="true"
          style={shadeStyle}
          className={classNames(css["root-hover-effect"], shadeClass)}
        />
      )}
      <Box
        as="span"
        className={classNames(css["content"], {
          [css["content-remove-inner-constraint"]]: !shouldConstrainContent,
        })}
      >
        {content}
      </Box>
      {shouldShowBadge && withBadge}
    </Box>
  );
};
