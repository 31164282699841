import { stacks } from '@dropbox/api-v2-client';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Add_DashSearchToStack } from '@mirage/analytics/events/types/add_dash_search_to_stack';
import { PAP_Copy_DashSearch } from '@mirage/analytics/events/types/copy_dash_search';
import { PAP_Open_DashSearchResult } from '@mirage/analytics/events/types/open_dash_search_result';
import { PAP_Shown_DashSearchResult } from '@mirage/analytics/events/types/shown_dash_search_result';
import { stackDerivePAPProps } from '@mirage/service-stacks';
import {
  getSearchFilterNamesForLogging,
  SearchFilter,
} from '@mirage/shared/search/search-filters';
import useSearchQueryId from './useSearchQueryId';

import type { Recommendation } from '../search/recommendation';
import type { StackItem } from '../search/stack-item';
import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { DashSourceType } from '@mirage/analytics/events/enums/dash_source_type';
import type { LaunchMethod } from '@mirage/analytics/events/enums/launch_method';
import type { TypeaheadResultType } from '@mirage/analytics/events/enums/typeahead_result_type';
import type { SearchResult } from '@mirage/service-dbx-api';
import type {
  MathCalculation,
  typeahead,
} from '@mirage/service-typeahead-search/service/types';
import type { PreviousQuery } from '@mirage/shared/search/cache-result';
import type { URLShortcut } from '@mirage/shared/search/url-shortcut';

type DefaultProps = {
  query: string;
  filters: Array<SearchFilter>;
  results: SearchResult[] | typeahead.ScoredResult[];

  // Optional Params
  actionSurfaceComponent?: ActionSurfaceComponent;
};

export type SearchResultDefaultProps = DefaultProps & {
  searchResult: SearchResult;
};

export type TypeaheadResultDefaultProps = DefaultProps & {
  result: typeahead.ScoredResult;
};

export type StackResultDefaultProps = DefaultProps & {
  uuid: string;
  stack: stacks.Stack;
};

export type StackItemResultDefaultProps = DefaultProps & {
  stackItem: StackItem;
};

export type PreviousQueryDefaultProps = DefaultProps & {
  previousQuery: PreviousQuery;
};

export type RecommendationDefaultProps = DefaultProps & {
  recommendation: Recommendation;
};

export type URLShortcutDefaultProps = DefaultProps & {
  urlShortcut: URLShortcut;
};

export type MathCalculationDefaultProps = DefaultProps & {
  mathCalculation: MathCalculation;
};

export type DesktopFileDefaultProps = DefaultProps & {
  desktopFile: SearchResult;
};

export type DesktopApplicationDefaultProps = DefaultProps & {
  desktopApplication: SearchResult;
};

type DashResultDefaultParams = {
  resultPosition: number;
  rightRailOpened: boolean;
  resultCount: number;
  isTypeahead: boolean;

  /**
   * If the result is selected via typeahead
   *
   * content: Search Result is acted upon
   * filter: Inline filter is acted upon
   * query_suggestion: Suggested query is acted upon
   * serp_cta: It shows the query itself and clicking this opens the search result page.
   */
  typeaheadResultType?: TypeaheadResultType;
  launchMethod?: LaunchMethod;
  dashSourceType?: DashSourceType;
};

type DashSearchResultParams = SearchResultDefaultProps &
  DashResultDefaultParams;
type StackResultParams = StackResultDefaultProps & DashResultDefaultParams;
type StackItemResultParams = StackItemResultDefaultProps &
  DashResultDefaultParams;
type PreviousQueryParams = PreviousQueryDefaultProps & DashResultDefaultParams;
type RecommendationResultParams = RecommendationDefaultProps &
  DashResultDefaultParams;
type URLShortcutResultParams = URLShortcutDefaultProps &
  DashResultDefaultParams;
type MathCalculationParams = MathCalculationDefaultProps &
  DashResultDefaultParams;
type DesktopFileParams = DesktopFileDefaultProps & DashResultDefaultParams;
type DesktopApplicationParams = DesktopApplicationDefaultProps &
  DashResultDefaultParams;

export default function useDashSearchResultLogger() {
  const { reportPapEvent, searchAttemptSessionManager, searchSessionManager } =
    useMirageAnalyticsContext();
  const { searchQueryUuid } = useSearchQueryId();

  const logSearchResultOpen = (
    defaultProps: SearchResultDefaultProps,
    rightRailOpened: boolean,
    isTypeahead: boolean,
    launchMethod: LaunchMethod,
    actionSurfaceComponent?: ActionSurfaceComponent,
    typeaheadResultType?: TypeaheadResultType,
    dashSourceType: DashSourceType = 'connector',
  ) => {
    const { results } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === defaultProps.searchResult.uuid,
    );
    const params: DashSearchResultParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened,
      resultCount,
      isTypeahead,
      launchMethod,
      actionSurfaceComponent,
      typeaheadResultType,
      dashSourceType,
    };

    const event = getOpenDashSearchResult(params);

    reportEvent(event);
  };

  const logStackResultOpen = (
    defaultProps: StackResultDefaultProps,
    rightRailOpened: boolean,
    isTypeahead: boolean,
    launchMethod: LaunchMethod,
    actionSurfaceComponent: ActionSurfaceComponent,
    typeaheadResultType?: TypeaheadResultType,
  ) => {
    const { results } = defaultProps;
    const resultCount = results.length;

    // The core Stack type (`defaultProps.result.result`) doesn't have uuid,
    // only namespace_id. Use locally-generated uuid for finding
    // resultPosition.
    const resultPosition = results.findIndex(
      (result: SearchResult | typeahead.ScoredResult) => {
        return result?.uuid === defaultProps.uuid;
      },
    );

    const params: StackResultParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened,
      resultCount,
      isTypeahead,
      launchMethod,
      typeaheadResultType,
      actionSurfaceComponent,
    };

    const event = getOpenStackResult(params);

    reportEvent(event);
  };

  const logStackItemResultOpen = (
    defaultProps: StackItemResultDefaultProps,
    isTypeahead: boolean,
    rightRailOpened: boolean,
    launchMethod: LaunchMethod,
    actionSurfaceComponent: ActionSurfaceComponent,
    typeaheadResultType?: TypeaheadResultType,
  ) => {
    const { results } = defaultProps;
    const resultCount = results.length;

    const resultPosition = results.findIndex(
      (result: SearchResult | typeahead.ScoredResult) => {
        return result?.uuid === defaultProps.stackItem.uuid;
      },
    );

    const params: StackItemResultParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened,
      resultCount,
      isTypeahead,
      launchMethod,
      typeaheadResultType,
      actionSurfaceComponent,
    };

    const event = getOpenStackItemResult(params);

    reportEvent(event);
  };

  const logPreviousQueryOpen = (
    defaultProps: PreviousQueryDefaultProps,
    rightRailOpened: boolean,
    launchMethod: LaunchMethod,
    actionSurfaceComponent: ActionSurfaceComponent,
    typeaheadResultType?: TypeaheadResultType,
  ) => {
    const { results } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === defaultProps.previousQuery.uuid,
    );
    const params: PreviousQueryParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened,
      resultCount,
      isTypeahead: true,
      launchMethod,
      typeaheadResultType,
      actionSurfaceComponent,
    };

    const event = getOpenPreviousResult(params);

    reportEvent(event);
  };

  const logRecommendationOpen = (
    defaultProps: RecommendationDefaultProps,
    rightRailOpened: boolean,
    isTypeahead: boolean,
    launchMethod: LaunchMethod,
    actionSurfaceComponent?: ActionSurfaceComponent,
    typeaheadResultType?: TypeaheadResultType,
    dashSourceType: DashSourceType = 'connector',
  ) => {
    const { results } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === defaultProps.recommendation.uuid,
    );
    const params: RecommendationResultParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened,
      resultCount,
      isTypeahead,
      launchMethod,
      actionSurfaceComponent,
      typeaheadResultType,
      dashSourceType,
    };

    const event = getOpenRecommendationResult(params);

    reportEvent(event);
  };

  const logURLShortcutOpen = (
    defaultProps: URLShortcutDefaultProps,
    rightRailOpened: boolean,
    isTypeahead: boolean,
    launchMethod: LaunchMethod,
    actionSurfaceComponent?: ActionSurfaceComponent,
    typeaheadResultType?: TypeaheadResultType,
    dashSourceType: DashSourceType = 'connector',
  ) => {
    const { results } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === defaultProps.urlShortcut.uuid,
    );
    const params: URLShortcutResultParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened,
      resultCount,
      isTypeahead,
      launchMethod,
      actionSurfaceComponent,
      typeaheadResultType,
      dashSourceType,
    };

    const event = getOpenOpenURLShortcutResult(params);

    reportEvent(event);
  };

  const logMathCalculationOpen = (
    defaultProps: MathCalculationDefaultProps,
    rightRailOpened: boolean,
    isTypeahead: boolean,
    launchMethod: LaunchMethod,
    actionSurfaceComponent?: ActionSurfaceComponent,
    typeaheadResultType?: TypeaheadResultType,
    dashSourceType: DashSourceType = 'local',
  ) => {
    const { results } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === defaultProps.mathCalculation.uuid,
    );
    const params: MathCalculationParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened,
      resultCount,
      isTypeahead,
      launchMethod,
      actionSurfaceComponent,
      typeaheadResultType,
      dashSourceType,
    };

    const event = getOpenMathCalculationResult(params);

    reportEvent(event);
  };

  const logDocumentShown = (
    defaultProps: SearchResultDefaultProps,
    isTypeahead: boolean,
    actionSurfaceComponent?: ActionSurfaceComponent,
    typeaheadResultType?: TypeaheadResultType,
  ) => {
    const { results } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === defaultProps.searchResult.uuid,
    );
    const params: DashSearchResultParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened: false,
      resultCount,
      isTypeahead,
      actionSurfaceComponent,
      typeaheadResultType,
    };

    const event = getShownDashSearchResult(params);

    reportEvent(event);
  };

  const logRecommendationShown = (
    defaultProps: RecommendationDefaultProps,
    isTypeahead: boolean,
    actionSurfaceComponent?: ActionSurfaceComponent,
    typeaheadResultType?: TypeaheadResultType,
  ) => {
    const { results } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === defaultProps.recommendation.uuid,
    );
    const params: RecommendationResultParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened: false,
      resultCount,
      isTypeahead,
      actionSurfaceComponent,
      typeaheadResultType,
    };

    const event = getShownRecommendationResult(params);

    reportEvent(event);
  };

  const logDocumentCopy = (defaultProps: SearchResultDefaultProps) => {
    const searchResult = defaultProps.searchResult as SearchResult;
    const { query, results } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === defaultProps.searchResult.uuid,
    );
    const dashConnectorId = searchResult.connectorInfo.connectorId;
    const event = PAP_Copy_DashSearch({
      queryString: query,
      resultRank: resultPosition,
      dashConnectorId,
      resultCount,
      dashSearchResultType: searchResult.recordType?.['.tag'],
      searchResultSource: searchResult.searchResultSource,
      searchRequestId: searchResult.searchRequestId,
      resultUuid: searchResult.uuid,
      searchQueryUuid,
      featureLine: 'search',
    });

    reportEvent(event);
  };

  const logTypeaheadResultCopy = (
    defaultProps: TypeaheadResultDefaultProps,
    actionSurfaceComponent?: ActionSurfaceComponent,
    typeaheadResultType?: TypeaheadResultType,
  ) => {
    const { result, query, results } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === defaultProps.result.uuid,
    );
    const event = PAP_Copy_DashSearch({
      queryString: query,
      resultRank: resultPosition,
      resultCount,
      resultUuid: result.uuid,
      searchQueryUuid,
      featureLine: 'search',
      isTypeahead: true,
      actionSurfaceComponent,
      typeaheadResultType,
    });

    reportEvent(event);
  };

  const logAddDocumentToStack = (
    defaultProps: SearchResultDefaultProps,
    stack?: stacks.Stack,
    actionSurfaceComponent?: ActionSurfaceComponent,
  ) => {
    const { query, filters, results } = defaultProps;
    const searchResult = defaultProps.searchResult as SearchResult;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === searchResult.uuid,
    );
    const dashConnectorId = searchResult.connectorInfo.connectorId;
    const stackProps = stack ? { ...stackDerivePAPProps(stack) } : {};
    const activeFilters = getActiveFilters(filters);

    const event = PAP_Add_DashSearchToStack({
      queryString: query,
      resultPosition,
      dashConnectorId,
      resultUuid: searchResult.uuid,
      connector: searchResult.connectorInfo?.connectorName,
      dashSearchResultType: searchResult.recordType?.['.tag'],
      searchResultSource: searchResult.searchResultSource,
      searchRequestId: searchResult.searchRequestId,
      resultCount,
      activeFilters,
      featureLine: 'search',
      dashSourceType: 'connector',
      isSuccess: !!stack,
      actionSurfaceComponent,
      searchQueryUuid,
      ...stackProps,
    });

    reportEvent(event);
  };

  const logStackResultShown = (
    defaultProps: StackResultDefaultProps,
    actionSurfaceComponent?: ActionSurfaceComponent,
    typeaheadResultType?: TypeaheadResultType,
  ) => {
    const { results, uuid } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex((result) => result.uuid === uuid);
    const params: StackResultParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened: false,
      resultCount,
      isTypeahead: true,
      typeaheadResultType,
      actionSurfaceComponent,
    };

    const event = getShownStackResult(params);
    reportEvent(event);
  };
  const logStackItemResultShown = (
    defaultProps: StackItemResultDefaultProps,
    actionSurfaceComponent?: ActionSurfaceComponent,
    typeaheadResultType?: TypeaheadResultType,
  ) => {
    const { results, stackItem } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === stackItem.uuid,
    );
    const params: StackItemResultParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened: false,
      resultCount,
      isTypeahead: true,
      typeaheadResultType,
      actionSurfaceComponent,
    };

    const event = getShownStackItemResult(params);
    reportEvent(event);
  };

  const logPreviousQueryShown = (
    defaultProps: PreviousQueryDefaultProps,
    actionSurfaceComponent?: ActionSurfaceComponent,
    typeaheadResultType?: TypeaheadResultType,
  ) => {
    const { results, previousQuery } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === previousQuery.uuid,
    );
    const params: PreviousQueryParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened: false,
      resultCount,
      isTypeahead: true,
      typeaheadResultType,
      actionSurfaceComponent,
    };

    const event = getShownPreviousQuery(params);
    reportEvent(event);
  };

  const logURLShortcutShown = (
    defaultProps: URLShortcutDefaultProps,
    actionSurfaceComponent?: ActionSurfaceComponent,
  ) => {
    const { results, urlShortcut } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === urlShortcut.uuid,
    );
    const params: URLShortcutResultParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened: false,
      resultCount,
      isTypeahead: true,
      typeaheadResultType: 'url_shortcut',
      actionSurfaceComponent,
    };

    const event = getShownURLShortcut(params);
    reportEvent(event);
  };

  const logMathCalculationShown = (
    defaultProps: MathCalculationDefaultProps,
    actionSurfaceComponent?: ActionSurfaceComponent,
  ) => {
    const { results, mathCalculation } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === mathCalculation.uuid,
    );

    const params: MathCalculationParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened: false,
      resultCount,
      isTypeahead: true,
      typeaheadResultType: 'math_calculation',
      actionSurfaceComponent,
    };

    const event = getShownMathCalculation(params);
    reportEvent(event);
  };

  const logDesktopFileShown = (
    defaultProps: DesktopFileDefaultProps,
    actionSurfaceComponent?: ActionSurfaceComponent,
  ) => {
    const { results, desktopFile } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === desktopFile.uuid,
    );

    const params: DesktopFileParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened: false,
      resultCount,
      isTypeahead: true,
      typeaheadResultType: 'local_file',
      actionSurfaceComponent,
    };

    const event = getShownDesktopFile(params);
    reportEvent(event);
  };

  const logDesktopApplicationShown = (
    defaultProps: DesktopApplicationDefaultProps,
    actionSurfaceComponent?: ActionSurfaceComponent,
  ) => {
    const { results, desktopApplication } = defaultProps;
    const resultCount = results.length;
    const resultPosition = results.findIndex(
      (result) => result.uuid === desktopApplication.uuid,
    );

    const params: DesktopApplicationParams = {
      ...defaultProps,
      resultPosition,
      rightRailOpened: false,
      resultCount,
      isTypeahead: true,
      typeaheadResultType: 'local_app',
      actionSurfaceComponent,
    };

    const event = getShownDesktopApplication(params);
    reportEvent(event);
  };

  function getOpenDashSearchResult(params: DashSearchResultParams) {
    const {
      query,
      filters,
      resultPosition,
      rightRailOpened,
      resultCount,
      isTypeahead,
      typeaheadResultType,
      launchMethod,
      actionSurfaceComponent,
      dashSourceType,
    } = params;
    const searchResult = params.searchResult;
    const activeFilters = getActiveFilters(filters);
    const hasQuery = query.length > 0;

    return PAP_Open_DashSearchResult({
      resultUuid: searchResult.uuid,
      connector: searchResult?.connectorInfo?.connectorName,
      activeFilters,
      queryString: query,
      title: searchResult.title ? searchResult.title : '',
      dashSearchResultType: searchResult.recordType?.['.tag'],
      searchRequestId: searchResult.searchRequestId,
      resultPosition: resultPosition >= 0 ? resultPosition : undefined,
      dashSourceType,
      resultUpdatedAtMs: searchResult.updatedAtMs ?? undefined,
      rightRailOpened,
      totalScore: searchResult.score ?? undefined,
      hitCount: undefined,
      resultCount,
      searchResultSource: searchResult.searchResultSource,
      fromUpstream: searchResult.searchResultSource === 'upstream',
      isPinned: false,
      latency: searchResult.latency ?? undefined,
      hasQuery,
      isTypeahead,
      typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
      launchMethod,
      featureLine: 'search',
      actionSurfaceComponent,
      searchQueryUuid,
      serpFinalRanking: searchResult.serpFinalRanking,
    });
  }

  function getOpenStackResult(params: StackResultParams) {
    const {
      uuid,
      stack,
      query,
      filters,
      resultPosition,
      rightRailOpened,
      resultCount,
      isTypeahead,
      typeaheadResultType,
      actionSurfaceComponent,
    } = params;
    const hasQuery = query.length > 0;
    const activeFilters = getActiveFilters(filters);

    return PAP_Open_DashSearchResult({
      title: stack?.stack_data?.name,
      resultUuid: uuid,
      activeFilters,
      queryString: query,
      resultPosition: resultPosition >= 0 ? resultPosition : undefined,
      dashSourceType: 'connector',
      searchResultSource: 'local_cache',
      rightRailOpened,
      hitCount: undefined,
      resultCount,
      fromUpstream: false,
      isPinned: false,
      hasQuery,
      isTypeahead,
      typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
      actionSurfaceComponent,
      searchQueryUuid,
      featureLine: 'search',
    });
  }

  function getOpenStackItemResult(params: StackItemResultParams) {
    const {
      stackItem,
      query,
      filters,
      resultPosition,
      rightRailOpened,
      resultCount,
      isTypeahead,
      typeaheadResultType,
      actionSurfaceComponent,
    } = params;
    const hasQuery = query.length > 0;
    const activeFilters = getActiveFilters(filters);

    return PAP_Open_DashSearchResult({
      title: stackItem.name,
      resultUuid: stackItem.uuid,
      activeFilters,
      queryString: query,
      resultPosition: resultPosition >= 0 ? resultPosition : undefined,
      dashSourceType: 'stack',
      searchResultSource: 'local_cache',
      rightRailOpened,
      hitCount: undefined,
      resultCount,
      fromUpstream: false,
      isPinned: false,
      hasQuery,
      isTypeahead,
      typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
      actionSurfaceComponent,
      searchQueryUuid,
      featureLine: 'search',
    });
  }

  function getOpenPreviousResult(params: PreviousQueryParams) {
    const {
      previousQuery,
      query,
      filters,
      resultPosition,
      rightRailOpened,
      resultCount,
      isTypeahead,
      typeaheadResultType,
      actionSurfaceComponent,
    } = params;

    const hasQuery = query.length > 0;
    const activeFilters = getActiveFilters(filters);

    return PAP_Open_DashSearchResult({
      title: previousQuery.query,
      activeFilters,
      queryString: query,
      resultPosition: resultPosition >= 0 ? resultPosition : undefined,
      dashSourceType: 'connector',
      searchResultSource: 'local_cache',
      rightRailOpened,
      hitCount: undefined,
      resultCount,
      fromUpstream: false,
      isPinned: false,
      hasQuery,
      isTypeahead,
      typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
      actionSurfaceComponent,
      searchQueryUuid,
      featureLine: 'search',
    });
  }

  function getOpenRecommendationResult(params: RecommendationResultParams) {
    const {
      recommendation,
      query,
      filters,
      resultPosition,
      rightRailOpened,
      resultCount,
      launchMethod,
      actionSurfaceComponent,
      dashSourceType,
    } = params;
    const activeFilters = getActiveFilters(filters);
    const hasQuery = query.length > 0;

    return PAP_Open_DashSearchResult({
      resultUuid: recommendation.uuid,
      connector: recommendation?.connectorInfo?.connectorName,
      activeFilters,
      queryString: query,
      title: recommendation.title ? recommendation.title : '',
      dashSearchResultType: recommendation.recordType?.['.tag'],
      searchRequestId: undefined,
      resultPosition: resultPosition >= 0 ? resultPosition : undefined,
      dashSourceType,
      resultUpdatedAtMs: undefined,
      rightRailOpened,
      totalScore: undefined,
      hitCount: undefined,
      resultCount,
      searchResultSource: 'local_cache',
      fromUpstream: false,
      isPinned: false,
      latency: undefined,
      hasQuery,
      isTypeahead: true,
      typeaheadResultType: 'recommendation',
      launchMethod,
      featureLine: 'search',
      actionSurfaceComponent,
      searchQueryUuid,
    });
  }

  function getOpenOpenURLShortcutResult(params: URLShortcutResultParams) {
    const {
      urlShortcut,
      query,
      filters,
      resultPosition,
      rightRailOpened,
      resultCount,
      launchMethod,
      actionSurfaceComponent,
    } = params;
    const activeFilters = getActiveFilters(filters);
    const hasQuery = query.length > 0;

    return PAP_Open_DashSearchResult({
      resultUuid: urlShortcut.uuid,
      connector: undefined,
      activeFilters,
      queryString: query,
      title: urlShortcut.parameters.activeHotword,
      dashSearchResultType: 'url_shortcut',
      searchRequestId: undefined,
      resultPosition: resultPosition >= 0 ? resultPosition : undefined,
      dashSourceType: 'url_shortcut',
      resultUpdatedAtMs: undefined,
      rightRailOpened,
      totalScore: undefined,
      hitCount: undefined,
      resultCount,
      searchResultSource: 'local_cache',
      fromUpstream: false,
      isPinned: false,
      latency: undefined,
      hasQuery,
      isTypeahead: true,
      typeaheadResultType: 'url_shortcut',
      launchMethod,
      featureLine: 'search',
      actionSurfaceComponent,
      searchQueryUuid,
    });
  }

  function getOpenMathCalculationResult(params: MathCalculationParams) {
    const {
      mathCalculation,
      query,
      filters,
      resultPosition,
      rightRailOpened,
      resultCount,
      launchMethod,
      actionSurfaceComponent,
    } = params;
    const activeFilters = getActiveFilters(filters);
    const hasQuery = query.length > 0;

    return PAP_Open_DashSearchResult({
      resultUuid: mathCalculation.uuid,
      connector: undefined,
      activeFilters,
      queryString: query,
      title: mathCalculation.answer,
      dashSearchResultType: 'math',
      searchRequestId: undefined,
      resultPosition: resultPosition >= 0 ? resultPosition : undefined,
      dashSourceType: 'local',
      resultUpdatedAtMs: undefined,
      rightRailOpened,
      totalScore: undefined,
      hitCount: undefined,
      resultCount,
      searchResultSource: 'local_cache',
      fromUpstream: false,
      isPinned: false,
      latency: undefined,
      hasQuery,
      isTypeahead: true,
      typeaheadResultType: 'math_calculation',
      launchMethod,
      featureLine: 'search',
      actionSurfaceComponent,
      searchQueryUuid,
    });
  }

  function getShownDashSearchResult(params: DashSearchResultParams) {
    const {
      query,
      filters,
      resultPosition,
      resultCount,
      rightRailOpened,
      isTypeahead,
      actionSurfaceComponent,
      typeaheadResultType,
    } = params;
    const searchResult = params.searchResult;
    const activeFilters = getActiveFilters(filters);
    const hasQuery = query.length > 0;

    return PAP_Shown_DashSearchResult({
      activeFilters,
      queryString: query,
      title: searchResult.title,
      resultUuid: searchResult.uuid,
      connector: searchResult?.connectorInfo?.connectorName,
      totalScore: searchResult.score ?? 0,
      sourceScore: searchResult.score ?? 0,
      searchResultSource: searchResult.searchResultSource,
      fromUpstream: searchResult.searchResultSource === 'upstream',
      resultUpdatedAtMs: searchResult.providerUpdateAtMs || undefined,
      dashSearchResultType: searchResult.recordType?.['.tag'],
      searchRequestId: searchResult.searchRequestId,
      resultCount,
      rightRailOpened,
      resultPosition,
      dashSourceType: 'connector',
      hasQuery,
      isTypeahead,
      typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
      actionSurfaceComponent,
      featureLine: 'search',
      searchQueryUuid,
      serpFinalRanking: searchResult.serpFinalRanking,
    });
  }

  function getShownRecommendationResult(params: RecommendationResultParams) {
    const {
      recommendation,
      query,
      filters,
      resultPosition,
      resultCount,
      rightRailOpened,
      actionSurfaceComponent,
      dashSourceType,
    } = params;
    const activeFilters = getActiveFilters(filters);
    const hasQuery = query.length > 0;

    return PAP_Shown_DashSearchResult({
      activeFilters,
      queryString: query,
      title: recommendation.title ? recommendation.title : '',
      resultUuid: recommendation.uuid,
      connector: recommendation?.connectorInfo?.connectorName,
      searchResultSource: 'local_cache',
      fromUpstream: false,
      isPinned: false,
      resultUpdatedAtMs: undefined,
      dashSearchResultType: recommendation.recordType?.['.tag'],
      resultCount,
      rightRailOpened,
      resultPosition,
      dashSourceType,
      hasQuery,
      isTypeahead: true,
      typeaheadResultType: 'recommendation',
      actionSurfaceComponent,
      featureLine: 'search',
      searchQueryUuid,
      searchRequestId: undefined,
    });
  }

  function getShownStackResult(params: StackResultParams) {
    const {
      stack,
      query,
      filters,
      resultPosition,
      resultCount,
      rightRailOpened,
      isTypeahead,
      typeaheadResultType,
      actionSurfaceComponent,
    } = params;

    const hasQuery = query.length > 0;
    const activeFilters = getActiveFilters(filters);

    return PAP_Shown_DashSearchResult({
      activeFilters,
      queryString: query,
      title: stack.stack_data?.name,
      resultCount,
      rightRailOpened,
      resultPosition,
      dashSourceType: 'stack',
      searchResultSource: 'local_cache',
      fromUpstream: false,
      hasQuery,
      isTypeahead,
      typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
      featureLine: 'search',
      actionSurfaceComponent,
      searchQueryUuid,
    });
  }
  function getShownStackItemResult(params: StackItemResultParams) {
    const {
      stackItem,
      query,
      filters,
      resultPosition,
      resultCount,
      rightRailOpened,
      isTypeahead,
      typeaheadResultType,
      actionSurfaceComponent,
    } = params;

    const hasQuery = query.length > 0;
    const activeFilters = getActiveFilters(filters);

    return PAP_Shown_DashSearchResult({
      activeFilters,
      queryString: query,
      title: stackItem.name,
      resultCount,
      rightRailOpened,
      resultPosition,
      dashSourceType: 'stack',
      searchResultSource: 'local_cache',
      fromUpstream: false,
      hasQuery,
      isTypeahead,
      typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
      featureLine: 'search',
      actionSurfaceComponent,
      searchQueryUuid,
    });
  }

  function getShownPreviousQuery(params: PreviousQueryParams) {
    const {
      previousQuery,
      query,
      filters,
      resultPosition,
      resultCount,
      rightRailOpened,
      isTypeahead,
      typeaheadResultType,
      actionSurfaceComponent,
    } = params;

    const hasQuery = query.length > 0;
    const activeFilters = getActiveFilters(filters);

    return PAP_Shown_DashSearchResult({
      activeFilters,
      queryString: query,
      title: previousQuery.query,
      resultCount,
      rightRailOpened,
      resultPosition,
      dashSourceType: 'connector',
      searchResultSource: 'local_cache',
      fromUpstream: false,
      hasQuery,
      isTypeahead,
      typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
      featureLine: 'search',
      actionSurfaceComponent,
      searchQueryUuid,
    });
  }

  function getShownURLShortcut(params: URLShortcutResultParams) {
    const {
      urlShortcut,
      query,
      filters,
      resultPosition,
      resultCount,
      rightRailOpened,
      isTypeahead,
      typeaheadResultType,
      actionSurfaceComponent,
    } = params;

    const hasQuery = query.length > 0;
    const activeFilters = getActiveFilters(filters);

    return PAP_Shown_DashSearchResult({
      activeFilters,
      queryString: query,
      title: urlShortcut.parameters.activeHotword,
      resultUuid: urlShortcut.uuid,
      resultCount,
      rightRailOpened,
      resultPosition,
      dashSourceType: 'connector',
      searchResultSource: 'local_cache',
      fromUpstream: false,
      hasQuery,
      isTypeahead,
      typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
      featureLine: 'search',
      actionSurfaceComponent,
      searchQueryUuid,
    });
  }

  function getShownMathCalculation(params: MathCalculationParams) {
    const {
      mathCalculation,
      query,
      filters,
      resultPosition,
      resultCount,
      rightRailOpened,
      isTypeahead,
      typeaheadResultType,
      actionSurfaceComponent,
    } = params;

    const hasQuery = query.length > 0;
    const activeFilters = getActiveFilters(filters);

    return PAP_Shown_DashSearchResult({
      activeFilters,
      queryString: query,
      title: mathCalculation.answer,
      resultUuid: mathCalculation.uuid,
      resultCount,
      rightRailOpened,
      resultPosition,
      fromUpstream: false,
      hasQuery,
      isTypeahead,
      typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
      featureLine: 'search',
      actionSurfaceComponent,
      searchQueryUuid,
    });
  }

  function getShownDesktopFile(params: DesktopFileParams) {
    const {
      desktopFile,
      query,
      filters,
      resultPosition,
      resultCount,
      rightRailOpened,
      isTypeahead,
      typeaheadResultType,
      actionSurfaceComponent,
    } = params;

    const hasQuery = query.length > 0;
    const activeFilters = getActiveFilters(filters);

    return PAP_Shown_DashSearchResult({
      activeFilters,
      queryString: query,
      title: desktopFile.title,
      resultUuid: desktopFile.uuid,
      resultCount,
      rightRailOpened,
      resultPosition,
      fromUpstream: false,
      hasQuery,
      isTypeahead,
      typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
      featureLine: 'search',
      actionSurfaceComponent,
      searchQueryUuid,
    });
  }

  function getShownDesktopApplication(params: DesktopApplicationParams) {
    const {
      desktopApplication,
      query,
      filters,
      resultPosition,
      resultCount,
      rightRailOpened,
      isTypeahead,
      typeaheadResultType,
      actionSurfaceComponent,
    } = params;

    const hasQuery = query.length > 0;
    const activeFilters = getActiveFilters(filters);

    return PAP_Shown_DashSearchResult({
      activeFilters,
      queryString: query,
      title: desktopApplication.title,
      resultUuid: desktopApplication.uuid,
      resultCount,
      rightRailOpened,
      resultPosition,
      fromUpstream: false,
      hasQuery,
      isTypeahead,
      typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
      featureLine: 'search',
      actionSurfaceComponent,
      searchQueryUuid,
    });
  }

  function getActiveFilters(filters: Array<SearchFilter>) {
    if (!filters) return '';
    const filterNames = getSearchFilterNamesForLogging(filters);
    return (filterNames || []).join(',');
  }

  function reportEvent(event: PAPEvent) {
    if (!event) return;
    const defaultProperties = {
      searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      searchAttemptId: searchAttemptSessionManager.getSessionIdOrUndefined(),
    };

    const combinedProperties = {
      ...defaultProperties,
      ...event.properties,
    };

    reportPapEvent({ ...event, properties: combinedProperties });
  }

  return {
    // Open
    logDocumentOpen: logSearchResultOpen,
    logRecommendationOpen,
    logURLShortcutOpen,
    logStackResultOpen,
    logStackItemResultOpen,
    logPreviousQueryOpen,
    logMathCalculationOpen,
    // Shown
    logStackResultShown,
    logStackItemResultShown,
    logDocumentShown,
    logRecommendationShown,
    logPreviousQueryShown,
    logMathCalculationShown,
    logDesktopFileShown,
    logDesktopApplicationShown,
    logTypeaheadResultCopy,
    logURLShortcutShown,
    // Copy
    logDocumentCopy,
    // Misc
    logAddDocumentToStack,
  };
}
