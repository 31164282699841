// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2077
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashFilterName } from '../enums/dash_filter_name';
import type { DashFilterType } from '../enums/dash_filter_type';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'select.dash_filter'
// Description: This event fires when a user selects a filter tab within the Dash application
// Owner: otc
export type Select_DashFilter = PAPEventGeneric<
  'dash',
  'select',
  'dash_filter',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The type of filter being opened by the user
    dashFilterType?: DashFilterType;
    // Represents the filter that the user has acted upon.  This can be set via filter pills or tips within the Dash application.
    dashFilterName?: DashFilterName;
    // Set to true when the search field has a value upon search
    hasQuery?: boolean;
    // Set to true when the search option is selected from typeahead search
    isTypeahead?: boolean;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The surface in which an action was taken on Dash.
    dashActionSurface?: DashActionSurface;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // The user query for people search on dash search page filter.
    // This is L0 data, and will be copied to restricted_otc hive table according to this TDD: https://www.dropbox.com/scl/fi/0r38gxrobgr6yvrft4yzu/OTCUS-Machine-Learning-Restricted-Logging-via-PAP-TDD.paper?dl=0&rlkey=7yvsba510fyeufi8cjea9juqk
    peopleSearchQuery?: string;
    // The identifier(s) of selected people during people search for people filters on Dash search. Initially this will be user emails.
    // This is L1 data, and will be copied to restricted_otc hive table according to this TDD: https://www.dropbox.com/scl/fi/0r38gxrobgr6yvrft4yzu/OTCUS-Machine-Learning-Restricted-Logging-via-PAP-TDD.paper?dl=0&rlkey=7yvsba510fyeufi8cjea9juqk
    peopleSelected?: string;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
  }
>;

export function PAP_Select_DashFilter(
  properties?: Select_DashFilter['properties'],
): Select_DashFilter {
  return <Select_DashFilter>{
    class: 'dash',
    action: 'select',
    object: 'dash_filter',
    properties,
  };
}
