import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { Service } from '@mirage/service-recent-content/service';
import * as rx from 'rxjs';

const service = services.get<Service>(ServiceId.RECENT_CONTENT);

export const recentBrowserHistory = service.recentBrowserHistory;
export const recentEntities = service.recentEntities;

export async function refreshRecentContent() {
  await rx.firstValueFrom(service.refreshRecentContent());
}

export async function latestLastViewedStackInfo() {
  return await rx.firstValueFrom(service.latestLastViewedStackInfo());
}

export async function reportViewedStack(namespaceId: string) {
  await rx.firstValueFrom(service.reportViewedStack(namespaceId));
}

export function startSyncRecentEntities() {
  return rx.firstValueFrom(service.startSyncRecentEntities());
}

export function cancelSyncRecentEntities() {
  return rx.firstValueFrom(service.cancelSyncRecentEntities());
}
