import { atom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

const DefaultNavigationAvatarState = {
  showSettings: true,
  showLogout: true,
  showPrivacyBanner: true,
  showDownloadDesktop: false,
};

type NavigationAvatarState = typeof DefaultNavigationAvatarState;

export const navigationAvatarAtom = atom(DefaultNavigationAvatarState);

export function useInitNavigationAvatarState(state: NavigationAvatarState) {
  const setState = useSetAtom(navigationAvatarAtom);

  useEffect(() => {
    setState(state);
  }, [setState, state]);
}
