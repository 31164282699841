import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Box } from '@dropbox/dig-foundations';
import { AnswerBox } from '@mirage/conversations/AnswerBox/AnswerBox';
import { MessageActions } from '@mirage/conversations/MessageActionsV2/MessageActions';
import { QuestionSuggestions } from '@mirage/conversations/QuestionSuggestionsV2';
import { LoadingStatus } from '@mirage/service-dbx-api/service/grpc/context_engine_apiv2/doc_summarization';
import i18n from '@mirage/translations';
import { useResizeObserver } from '@react-hookz/web';
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import styles from './Responses.module.css';

import type { QnaAnswer } from '@mirage/service-dbx-api/service/grpc/context_engine_apiv2/types';
import type { FeedbackOptions } from '@mirage/shared/types';

// TODO (OSE-3825) do we need this
const OFFSET = 128;

type ResponsesProps = {
  responses: QnaAnswer[];
  busy: boolean;
  onClickFeedback: (feedback: FeedbackOptions, answerIndex?: number) => void;
  onCopyMessage?: (text: string, answerIndex: number) => void;
  resultQuestion: ({
    queryString,
    role,
  }: {
    queryString: string;
    role: QnaAnswer['role'];
  }) => (() => void) | undefined;
};

export const Responses: React.FC<ResponsesProps> = ({
  responses,
  busy,
  onClickFeedback,
  onCopyMessage,
  resultQuestion,
}: ResponsesProps) => {
  const [parentHeight, setParentHeight] = useState<number>(0);
  const [childHeight, setChildHeight] = useState<number>(0);
  const [isAutoScrollDisabled, setIsAutoScrollDisabled] = useState(false);
  const [enableQuestions, setEnableQuestions] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // enable questions the first time we get responses
    if (responses?.length > 0 && !busy) {
      setEnableQuestions(true);
      // disable questions on reset
    } else if (enableQuestions && responses?.length === 0) {
      setEnableQuestions(false);
    }
  }, [busy, responses]);

  const onResizeParent = (entry: ResizeObserverEntry) => {
    setParentHeight(entry.contentRect.height);
  };

  const onResizeChild = (entry: ResizeObserverEntry) => {
    setChildHeight(entry.contentRect.height);
  };

  useResizeObserver(
    parentRef as React.MutableRefObject<HTMLDivElement>,
    onResizeParent,
  );

  useResizeObserver(
    lastItemRef as React.MutableRefObject<HTMLDivElement>,
    onResizeChild,
  );

  useEffect(() => {
    if (childHeight + OFFSET > parentHeight) {
      setIsAutoScrollDisabled(true);
    }
  }, [childHeight, parentHeight]);

  useEffect(() => {
    if (!isAutoScrollDisabled && parentRef.current) {
      parentRef.current.scrollTop = parentRef.current.scrollHeight;
    }
  }, [responses, isAutoScrollDisabled]);

  useEffect(() => {
    setIsAutoScrollDisabled(false);
  }, [responses.length]);

  if (responses.length === 0) return null;

  return (
    <div ref={parentRef} className={styles.responsesContainer}>
      {responses.map((response, i) => (
        <div
          data-testid="ResponseContainer"
          key={response.id}
          className={styles.responseContainer}
          ref={i === responses.length - 1 ? lastItemRef : null}
        >
          <div className={styles.responseHeader}>
            <AnswerBox
              label={response.queryString}
              body={response.text}
              busy={busy}
            />
            {response?.status === LoadingStatus.pending && (
              <Box display="flex" alignItems="center">
                <Spinner size="xsmall" aria-valuetext={i18n.t('loading')} />
              </Box>
            )}
          </div>
          {!!onClickFeedback && (
            <div
              className={classnames(styles.responseQuestions, {
                [styles.active]: enableQuestions,
              })}
            >
              <MessageActions
                onCopyMessage={() => {
                  if (onCopyMessage) onCopyMessage(response.text || '', i);
                }}
                onClickFeedback={onClickFeedback}
                actionSurfaceComponent="ask_dialog"
                busy={busy}
                index={i}
                existingFeedback={response.feedback}
              />
            </div>
          )}
        </div>
      ))}
      <div
        className={classnames(styles.responseQuestions, {
          [styles.active]: enableQuestions,
        })}
      >
        <QuestionSuggestions
          busy={busy}
          onSelectSuggestion={resultQuestion}
          responses={responses}
        />
      </div>
    </div>
  );
};
