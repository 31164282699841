import { dash_connectors } from '@dropbox/api-v2-client';
import { Badge } from '@dropbox/dig-components/badge';
import { useConnectorsUI } from '@mirage/mosaics/SettingsPage/useConnectorsUI';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { ConnectorIcon } from '@mirage/shared/icons';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { KeyCodes } from '@mirage/shared/util/constants';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { getRoutePathname } from '@mirage/shared/util/route-pathname';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useMemo } from 'react';
import styles from './ConnectedAppsIndicator.module.css';

export type ConnectedAppsIndicatorProps = {
  onOpenConnectors: () => void;
};

const MAX_SHOWN_APPS_LARGE = 3;
const MAX_SHOWN_APPS_SMALL = 2;

export const ConnectedAppsIndicator = ({
  onOpenConnectors,
}: ConnectedAppsIndicatorProps) => {
  const connectors = useConnectors();
  const isMobile = useIsMobileSize();
  const { appSettingsPath } = useConnectorsUI();

  /*
   Connector icons should be displayed in this order:
   1. Dropbox FSS -> 'dropbox'
   2. One of Google Drive or OneDrive => 'googledrive', 'onedrive'
   3. One of email apps connected => 'gmail', 'outlook'
  */
  const orderedConnectors = useMemo(() => {
    const pinned: dash_connectors.Connector[] = [];

    const connectorsById = { ...connectors.connectorsById };

    if (connectorsById['dropbox']) {
      pinned.push(connectorsById['dropbox']);
      delete connectorsById['dropbox'];
    }

    if (connectorsById['googledrive']) {
      pinned.push(connectorsById['googledrive']);
      delete connectorsById['googledrive'];
    } else if (connectorsById['onedrive']) {
      pinned.push(connectorsById['onedrive']);
      delete connectorsById['onedrive'];
    }

    if (connectorsById['gmail']) {
      pinned.push(connectorsById['gmail']);
      delete connectorsById['gmail'];
    } else if (connectorsById['outlook']) {
      pinned.push(connectorsById['outlook']);
      delete connectorsById['outlook'];
    }

    const remainingSorted = Object.values(connectorsById).sort((a, b) =>
      (a.id_attrs?.type ?? '').localeCompare(b.id_attrs?.type ?? ''),
    );
    return pinned.concat(remainingSorted);
  }, [connectors.connectorsById]);

  const truncatedOrderedConnectors = orderedConnectors.slice(
    0,
    isMobile ? MAX_SHOWN_APPS_SMALL : MAX_SHOWN_APPS_LARGE,
  );
  const excessConnectorCount =
    connectors.connectors.length - truncatedOrderedConnectors.length;
  const hasExcessConnectors = excessConnectorCount > 0;

  if (
    !connectors.hasFinishedInitialFetch ||
    truncatedOrderedConnectors.length === 0
  ) {
    return null;
  }

  const pathname = getRoutePathname();
  // If the user is on the settings page the button for viewing connected apps will behave differently
  // but this will never be true for desktop app
  const disableAppConnectors = pathname === appSettingsPath;

  return (
    <DigTooltip
      openDelay={0}
      title={
        !disableAppConnectors && i18n.t('connected_apps_indicator_tooltip')
      }
    >
      <div
        role="button"
        className={classnames(styles.connectedAppsIndicatorContainer, {
          [styles.isMobile]: isMobile,
        })}
        onClick={(event) => {
          // If is on the settings page and clicks the button, do not redirect user
          if (disableAppConnectors) {
            return;
          }
          event.preventDefault();
          onOpenConnectors();
        }}
        onKeyDown={(event) => {
          if (event.key === KeyCodes.enter) {
            event.preventDefault();
            onOpenConnectors();
          }
        }}
        tabIndex={0}
      >
        {truncatedOrderedConnectors.map((connector) => {
          if (!connector.branding || !connector.id_attrs) {
            return null;
          }
          const connectorName = connector.id_attrs.type ?? '';
          const connectorIcon = connector.branding.icon_src;
          return (
            <ConnectorIcon
              key={connector.id_attrs?.id}
              connectorName={connectorName}
              iconUrl={connectorIcon}
              size={20}
            />
          );
        })}
        {hasExcessConnectors && (
          <Badge size="xsmall">+ {excessConnectorCount}</Badge>
        )}
      </div>
    </DigTooltip>
  );
};
