import { callApiV2 } from '@mirage/service-dbx-api';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { tagged } from '@mirage/service-logging';
import { getBrowserType } from '@mirage/shared/util/browser';
import debounce from 'lodash/debounce';

import type { stacks } from '@dropbox/api-v2-client';
import type {
  Metric,
  MetricSink,
  Tags,
} from '@mirage/service-operational-metrics/service';

const logger = tagged('metricSink');

type APIMetric = stacks.Metric['metric'];

export default function apiv2MetricSink(): MetricSink {
  const queue: stacks.Metric['metric'][] = [];

  async function flush() {
    if (!queue.length) return;

    const metrics = queue.splice(0, queue.length);
    try {
      await callApiV2('stacksReportClientMetrics', {
        tags: {
          channel: EnvCtx.buildChannel,
          platform: { '.tag': EnvCtx.surface },
          environment: { '.tag': getBrowserType() ?? 'other_browser' },
        },
        metrics: metrics.map((metric) => ({ metric })),
      });
    } catch (e) {
      logger.warn('failed to report metrics', e);
    }
  }

  // Flush metrics when the page is hidden to avoid metrics being lost.
  addEventListener('visibilitychange', flush);

  // Flush metrics when there is no more metric for 5 seconds.
  const debouncedFlush = debounce(flush, 5000);

  function logMetric(metric: APIMetric) {
    queue.push(metric);

    debouncedFlush();
  }

  async function counter(metric: Metric, value: number, tags?: Tags) {
    logMetric({
      '.tag': 'generic_counter',
      metric_name: `${metric.ns}/${metric.name}`,
      tag: takeFirstTagIfExists(tags),
      count: value,
    });
  }

  async function stats(metric: Metric, value: number, tags?: Tags) {
    const packet = {
      '.tag': 'generic_histogram',
      metric_name: `${metric.ns}/${metric.name}`,
      tag: takeFirstTagIfExists(tags),
      value,
    } as const;

    logMetric(packet);

    // Note: Use `console` and not `logger` because we never need to send this
    // to datadog, and using `logger` will unnecessarily increase IPC traffic
    // and slow down the app.
    // eslint-disable-next-line no-console
    console.debug(`log stats: ${JSON.stringify(packet)}`);
  }

  return { counter, stats };
}

// Due to current impl take the first tag from any reported results or undef
function takeFirstTagIfExists(tags: Tags | undefined): string | undefined {
  if (!tags) return undefined;

  const keys = Object.keys(tags);
  if (!keys.length) return undefined;

  // The tag is always logged with name `tag`, so we need to put the actual
  // tag name into the value itself to make it readable in grafana.
  const key = keys[0];
  return `${key}=${tags[key]}`;
}
