import { Text } from '@dropbox/dig-components/typography';
import { PrettyShortcuts } from '@mirage/shared/keyboard-shortcuts/PrettyShortcuts';
import i18n from '@mirage/translations';
import styles from './TypeaheadShortcutBanner.module.css';

type TypeaheadKeyboardShortcutBannerProps = {
  isDarwin: boolean;
};

export const TypeaheadKeyboardShortcutBanner = ({
  isDarwin,
}: TypeaheadKeyboardShortcutBannerProps) => (
  <div className={styles.container}>
    <div className={styles.shortcut}>
      <PrettyShortcuts
        shortcut={['/new']}
        isDarwin={true}
        editable={false}
        casingOverride="lowercase"
      />
      <Text size={'small'} monospace className={styles.text}>
        {i18n.t('typeahead_shortcut_create')}
      </Text>
    </div>

    <div className={styles.shortcut}>
      <PrettyShortcuts
        shortcut={[isDarwin ? '⌘' : 'CTRL', 'C']}
        editable={false}
        isDarwin={isDarwin}
      />
      <Text size={'small'} monospace className={styles.text}>
        {i18n.t('copy_link')}
      </Text>
    </div>
  </div>
);
