import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { dropTargetForExternal } from '@atlaskit/pragmatic-drag-and-drop/external/adapter';
import { containsURLs } from '@atlaskit/pragmatic-drag-and-drop/external/url';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { Link } from '@mirage/link-list/types';
import { DEFAULT_SECTION_ID } from '@mirage/service-stacks/service/utils';
import { MAX_STACK_SECTION_COUNT } from '@mirage/shared/util/constants';
import classnames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useShadowSections } from '../hooks';
import styles from './DragAndDrop.module.css';
import { useDnDContext } from './Provider';
import { isDraggableData, tryExtractLinkFromHTMLDragElement } from './utils';

interface DroppableEmptySectionProps {
  sectionId: string;
  index: number;
  mustCreateNewSection: boolean;
  isHoveringForMoment: boolean;
  setIsHoveringForMoment: (hovering: boolean) => void;
}
export const DroppableEmptySection: React.FC<DroppableEmptySectionProps> = ({
  sectionId,
  index,
  mustCreateNewSection,
  isHoveringForMoment,
  setIsHoveringForMoment,
}) => {
  const { allSections: sections } = useShadowSections();
  const hasTooManySections = sections
    ? sections.length >= MAX_STACK_SECTION_COUNT
    : false;
  const ref = useRef<HTMLDivElement | null>(null);
  const [isCurrentlyOver, setIsCurrentlyOver] = useState(false);
  const [isHoveringForMomentTimeout, setIsHoveringForMomentTimeout] =
    useState<NodeJS.Timeout | null>(null);
  const {
    createSectionAndMoveStackItem,
    moveStackItemIntoSection,
    createSectionAndAddSuggestion,
    moveSuggestionIntoSection,
    isDisabled,
    isItemDragging,
  } = useDnDContext();

  const handleOnDropStackItem = useCallback(
    (itemId: string) => {
      if (mustCreateNewSection) {
        createSectionAndMoveStackItem(sectionId, itemId);
      } else {
        moveStackItemIntoSection(sectionId, itemId, 0);
      }
    },
    [
      createSectionAndMoveStackItem,
      moveStackItemIntoSection,
      mustCreateNewSection,
      sectionId,
    ],
  );

  const handleOnDropSuggestion = useCallback(
    (link: Link) => {
      if (mustCreateNewSection) {
        createSectionAndAddSuggestion(sectionId, link);
      } else {
        moveSuggestionIntoSection(sectionId, link, 0);
      }
    },
    [
      createSectionAndAddSuggestion,
      moveSuggestionIntoSection,
      mustCreateNewSection,
      sectionId,
    ],
  );

  const handleStartDrag = useCallback(() => {
    setIsCurrentlyOver(true);
    // When drag starts on any empty section, we know that isHoveringForMoment
    // should be false since we just entered a (potentially different) section.
    // This is to help fix a bug where isHoveringForMoment would remain true
    // even after dragging outside of the empty section
    setIsHoveringForMoment(false);
    if (isHoveringForMomentTimeout) {
      clearTimeout(isHoveringForMomentTimeout);
    }
    const interval = setTimeout(() => {
      setIsHoveringForMoment(true);
    }, 500);
    setIsHoveringForMomentTimeout(interval);
  }, [isHoveringForMomentTimeout, setIsHoveringForMoment]);

  const handleStopDrag = useCallback(() => {
    if (isHoveringForMomentTimeout) {
      clearTimeout(isHoveringForMomentTimeout);
    }
    setIsHoveringForMoment(false);
    setIsCurrentlyOver(false);
  }, [isHoveringForMomentTimeout, setIsHoveringForMoment]);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    return combine(
      dropTargetForExternal({
        element,
        canDrop: ({ source }) => {
          if (isDisabled || !containsURLs({ source })) {
            return false;
          }
          if (hasTooManySections && !mustCreateNewSection) {
            return false;
          }
          return true;
        },
        onDragEnter: handleStartDrag,
        onDragLeave: handleStopDrag,
        onDrop: ({ source }) => {
          handleStopDrag();
          const newLink = tryExtractLinkFromHTMLDragElement(source);
          if (newLink) {
            handleOnDropSuggestion(newLink);
          }
        },
      }),
      dropTargetForElements({
        element,
        canDrop: () => !isDisabled,
        onDragEnter: handleStartDrag,
        onDragLeave: handleStopDrag,
        onDrop: ({ source }) => {
          handleStopDrag();
          if (!isDraggableData(source.data)) {
            return;
          }
          if (source.data.type === 'stackitem') {
            handleOnDropStackItem(source.data.id);
          } else if (source.data.type === 'suggestion') {
            handleOnDropSuggestion(source.data.payload);
          }
        },
      }),
    );
  }, [
    sectionId,
    index,
    isDisabled,
    createSectionAndMoveStackItem,
    moveStackItemIntoSection,
    mustCreateNewSection,
    createSectionAndAddSuggestion,
    moveSuggestionIntoSection,
    handleOnDropStackItem,
    handleOnDropSuggestion,
    handleStartDrag,
    handleStopDrag,
    hasTooManySections,
  ]);

  useEffect(() => {
    return () => {
      if (isHoveringForMomentTimeout) {
        clearTimeout(isHoveringForMomentTimeout);
      }
    };
  }, [isHoveringForMomentTimeout]);

  const hoveringOverNewSection = isHoveringForMoment && mustCreateNewSection;
  const augustRevision = useStackPageAugustRevisionEnabled();

  return (
    <div
      ref={ref}
      className={classnames(
        styles.droppableEmptySection,
        mustCreateNewSection &&
          (augustRevision
            ? styles.augustRevisionDroppableEmptySectionSpacing
            : styles.droppableEmptySectionSpacing),
        !mustCreateNewSection &&
          sectionId !== DEFAULT_SECTION_ID &&
          styles.droppableEmptySectionNoSpacing,
      )}
    >
      <div
        className={classnames(
          // If any item is being dragged, and the empty section
          // already exists (but is empty), increase the height of the this area
          // to show the user the item can fit in the empty section
          isItemDragging &&
            !mustCreateNewSection &&
            styles.droppableBottomSection,
          // If an item is being dragged over this empty section, and the section
          // does not exist, render the empty section with a larger height, background,
          // and border to indicate the user can create a new section
          hoveringOverNewSection && styles.droppableNewSectionWhileDraggingOver,
        )}
      >
        {/* 
          Only show the DropIndicator when the current empty section is hovered
          over and it is an existing empty section     
      */}
        {isCurrentlyOver && (!isHoveringForMoment || !mustCreateNewSection) && (
          <DropIndicator edge="top" gap={augustRevision ? '-10px' : '-16px'} />
        )}
        {augustRevision && !hoveringOverNewSection && mustCreateNewSection && (
          <hr className={styles.augustRevisionDroppableSectionDivider} />
        )}
      </div>
    </div>
  );
};
