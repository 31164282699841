import { IconButton } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { BlockQuoteLine } from '@dropbox/dig-icons/assets';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './Markdown.module.css';

interface MarkdownProps {
  body: string;
}

function CollapsibleBlockquote({ children }: { children: React.ReactNode }) {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div>
      <IconButton
        size="small"
        shape="circular"
        variant="opacity"
        onClick={() => setIsVisible(!isVisible)}
      >
        <UIIcon src={BlockQuoteLine} />
      </IconButton>
      {isVisible && (
        <blockquote className={styles.markdownQuote}>
          <div className={styles.markdownQuoteText}>{children}</div>
        </blockquote>
      )}
    </div>
  );
}

const components = {
  blockquote: CollapsibleBlockquote,
};

// don't allow images in markdown.
const disallowedElements = ['img'];

export const Markdown: React.FC<MarkdownProps> = ({ body }) => {
  return (
    <div className={styles.markdownContainer}>
      <Text size="medium">
        <ReactMarkdown
          disallowedElements={disallowedElements}
          components={components}
        >
          {body}
        </ReactMarkdown>
      </Text>
    </div>
  );
};
