import {
  latestLastViewedStackInfo,
  reportViewedStack,
} from '@mirage/service-recent-content';
import { useEffect, useState } from 'react';
import { LastViewedStackInfo } from '../types';

export const useLastViewedStackInfo = () => {
  const [_lastViewedStackInfo, setLastViewedStackInfo] =
    useState<LastViewedStackInfo>({});

  useEffect(() => {
    latestLastViewedStackInfo().then((result) =>
      setLastViewedStackInfo(result),
    );
  }, []);

  return _lastViewedStackInfo;
};

export const useReportViewedStack = (namespaceId: string | undefined) => {
  useEffect(() => {
    // Mark this stack as viewed
    if (namespaceId) {
      reportViewedStack(namespaceId);
    }
  }, [namespaceId]);
};
