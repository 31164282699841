import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import * as jobs from '@mirage/shared/util/jobs';
import { getValueChangedFunc } from '@mirage/shared/util/value-changed';
import WithDefaults from '@mirage/storage/with-defaults';
import * as rx from 'rxjs';
import { getUpcomingCalendarEvents } from './api';

import type { dash } from '@dropbox/api-v2-client';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';
import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

export type Service = {
  calendarEventsUpdates(): Observable<dash.SearchResult[]>;
  getCachedCalendarEvents(): Promise<dash.SearchResult[] | undefined>;
  refreshCalendarEvents(includeAllDay: boolean): Promise<void>;
  startRefreshCalendarEvents(includeAllDay: boolean): void;
  stopRefreshCalendarEvents(): void;
};

export type StoredCalendarEvents = {
  events: dash.SearchResult[] | undefined;
};

type DbxApiServiceContract = {
  callApiV2: APIv2Callable;
};

type LogoutServiceContract = {
  listenForLogout(service: string): Observable<boolean>;
};

export default function provideCalendarEventsService(
  rawStorage: KVStorage<StoredCalendarEvents>,
  dbxApiService: DbxApiServiceContract,
  logoutService: LogoutServiceContract,
) {
  const adapter = new WithDefaults(rawStorage, {
    events: undefined,
  });
  const eventsChanged = getValueChangedFunc<dash.SearchResult[]>();
  const eventsSubject = new rx.Subject<dash.SearchResult[]>();

  function startRefreshCalendarEvents(includeAllDay: boolean = false) {
    async function doRefresh() {
      return await refreshCalendarEvents(includeAllDay);
    }
    // Start a job to refresh calendar events every 5 minutes.
    // Care should be taken to only start one job at a time.
    // See ../hooks/useUpcomingCalendarEvents.ts for where this is managed.
    jobs.register(
      'refreshCalendarEvents',
      ONE_MINUTE_IN_MILLIS * 5,
      true,
      doRefresh,
    );
  }

  function stopRefreshCalendarEvents() {
    // Sibling to startRefreshCalendarEvents()
    jobs.unregister('refreshCalendarEvents');
  }

  async function refreshCalendarEvents(includeAllDay: boolean) {
    const results = await getUpcomingCalendarEvents(
      includeAllDay,
      dbxApiService.callApiV2,
    );
    if (results && eventsChanged(results)) {
      await adapter.set('events', results);
      eventsSubject.next(results);
    }
  }

  async function getCachedCalendarEvents(): Promise<
    dash.SearchResult[] | undefined
  > {
    const events = await adapter.get('events');
    if (events === null) {
      return undefined;
    }
    return events;
  }

  function calendarEventsUpdates(): Observable<dash.SearchResult[]> {
    return eventsSubject.asObservable();
  }

  function tearDown() {
    adapter.clear();
  }

  logoutService.listenForLogout(ServiceId.CALENDAR_EVENTS).subscribe(() => {
    tearDown();
  });

  services.provide<Service>(
    ServiceId.CALENDAR_EVENTS,
    {
      getCachedCalendarEvents,
      calendarEventsUpdates,
      refreshCalendarEvents,
      startRefreshCalendarEvents,
      stopRefreshCalendarEvents,
    },
    [ServiceId.DBX_API],
  );
}
