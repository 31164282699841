import { dash_feed, users } from '@dropbox/api-v2-client';
import { LabelGroup, Metadata } from '@dropbox/dash-component-library';
import { Badge } from '@dropbox/dig-components/badge';
import { Skeleton } from '@dropbox/dig-components/skeleton';
import { Box, Split } from '@dropbox/dig-foundations';
import { DashCard } from '@mirage/dash-component-library/components/DashCard';
import { isStackActivityObject } from '@mirage/service-feed/util/helpers';
import { getConnectorDisplayNameFromConnectorInfo } from '@mirage/shared/connectors';
import { toActivityResult } from '@mirage/shared/feed/activity-result';
import { getTimeAgoString } from '@mirage/shared/util/time';
import i18n from '@mirage/translations';
import { ActivityAction } from '../ActivityAction/ActivityAction';
import {
  ActivityThumbnail,
  SkeletonActivityThumbnail,
} from '../ActivityThumbnail/ActivityThumbnail';
import styles from './ActivityListItem.module.css';

type ActivityListItemProps = {
  activity: dash_feed.ActivityItem;
  currentAccount?: users.FullAccount;
};

export const ActivityListItem = ({
  activity,
  currentAccount,
}: ActivityListItemProps) => {
  return (
    <DashCard breakout="xsmall">
      <Split direction="horizontal" alignY="center">
        <Split.Item className={styles.mainContent}>
          {!activity.is_read && (
            <Badge
              size="xsmall"
              variant="attention"
              className={styles.unreadBadge}
            />
          )}
          <LabelGroup
            verticalAlignment="center"
            accessoryGap="Macro Small"
            withStartAccessory={<ActivityThumbnail activity={activity} />}
            withLabel={
              <ActivityAction
                currentAccount={currentAccount}
                activity={activity}
              />
            }
            withMeta={<ActivityMetadata activity={activity} />}
          />
        </Split.Item>
      </Split>
    </DashCard>
  );
};

const ActivityLabel = ({ activity }: ActivityListItemProps) => {
  let label: string;
  if (isStackActivityObject(activity)) {
    label = i18n.t('activity_feed_item_type_stack');
  } else {
    const result = toActivityResult(activity);
    if (!result || !result.connectorInfo) {
      return <></>;
    }
    label = getConnectorDisplayNameFromConnectorInfo(result.connectorInfo);
  }
  return <>{label}</>;
};

const ActivityMetadata = ({ activity }: ActivityListItemProps) => {
  return (
    <Metadata size="medium">
      <Metadata.Item>
        <Metadata.Label>
          <ActivityLabel activity={activity} />
        </Metadata.Label>
      </Metadata.Item>
      <Metadata.Item>{getTimeAgoString(activity.ts!)}</Metadata.Item>
    </Metadata>
  );
};

export const ActivityListItemSkeleton = () => {
  return (
    <DashCard>
      <Box
        as={LabelGroup}
        paddingX="Micro Medium"
        verticalAlignment="center"
        accessoryGap="Macro Small"
        withStartAccessory={<SkeletonActivityThumbnail />}
        withLabel={<Skeleton.Text withBorderRadius="xsmall" width={300} />}
        withMeta={<Skeleton.Text withBorderRadius="xsmall" width={150} />}
      />
    </DashCard>
  );
};
