import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_View_DashStartPage } from '@mirage/analytics/events/types/view_dash_start_page';
import { UserActivityFeedModule } from '@mirage/feed/components/UserActivityFeedModule/UserActivityFeedModule';
import { useIsActivityFeedEnabled } from '@mirage/service-feed/hooks/features';
import { useDismissableModule } from '@mirage/service-onboarding/useDismissableModule';
import { TwoColumnGrid } from '@mirage/shared/two-column-grid/TwoColumnGrid';
import { DISMISSED_EMPTY_STATE_KEY } from '@mirage/working-set/CalendarEventWorkingSetCard/CalendarEmptyStateV2';
import { useMountEffect } from '@react-hookz/web';
import { useConnectedCalendars } from '../WorkingSet/Calendar/useConnectedCalendars';
import { CalendarModule } from './Calendar';
import { RecentsModule } from './RecentsModule';

function useReportViewDashStartPage() {
  const { reportPapEvent } = useMirageAnalyticsContext();
  useMountEffect(() => {
    reportPapEvent(
      PAP_View_DashStartPage({
        featureLine: 'session_tracking',
      }),
    );
  });
}

export const StartPage = () => {
  useReportViewDashStartPage();
  const isActivityFeedEnabled = useIsActivityFeedEnabled();
  const { connections: calendarConnections } = useConnectedCalendars();
  const { dismissed: calendarDismissed } = useDismissableModule(
    DISMISSED_EMPTY_STATE_KEY,
  );

  return (
    <TwoColumnGrid
      mainRowGap="Macro Large"
      mainChildren={
        <>
          <RecentsModule />
          {isActivityFeedEnabled && <UserActivityFeedModule />}
        </>
      }
      sidebarChildren={
        (calendarConnections.length > 0 || !calendarDismissed) && (
          <CalendarModule />
        )
      }
    />
  );
};
