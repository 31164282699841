import { cmde } from '@dropbox/api-v2-client';
import {
  ChromeExternalLogo,
  FirefoxExternalLogo,
  MicrosoftEdgeExternalLogo,
  PuzzlePieceFill,
} from '@dropbox/dig-icons/assets';
import { callApiV2 } from '@mirage/service-dbx-api';
import { openURL } from '@mirage/service-platform-actions';
import SvgChrome from '@mirage/shared/icons/connector-icons/Chrome';
import SvgEdge from '@mirage/shared/icons/connector-icons/Edge';
import SvgFirefox from '@mirage/shared/icons/connector-icons/Firefox';
import SvgSafari from '@mirage/shared/icons/connector-icons/Safari';
import { BrowserNameTypes, WebExtensionProps } from '@mirage/shared/types';
import {
  CHROME_EXTENSION_WEB_STORE,
  EDGE_EXTENSION_WEB_STORE,
  FIREFOX_EXTENSION_WEB_STORE,
} from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import { useEffect, useState } from 'react';

export async function fetchBrowserExtensionData(
  isExtensionAlpha = false,
): Promise<WebExtensionProps[]> {
  const currentBrowser = getCurrentBrowser() as BrowserNameTypes;
  const currentBrowserExtensionIsInstalled = isExtensionInstalled();

  function currentBrowserIsConnected(id: BrowserNameTypes) {
    return id === currentBrowser && currentBrowserExtensionIsInstalled;
  }

  // all extensions we have
  const allExtensions: { [key in WebExtensionProps['id']]: WebExtensionProps } =
    {
      chrome: {
        id: 'chrome',
        label: 'Chrome Extension',
        icon: SvgChrome({ size: 24 }),
        description: i18n.t('connectors_settings_browser_description'),
        connected: currentBrowserIsConnected('chrome'),
        authenticated: true as const,
        isWebExtension: true as const,
        installUrl: '', // populated by API below
      },
      firefox: {
        id: 'firefox',
        label: 'Firefox Extension',
        icon: SvgFirefox({ size: 24 }),
        description: i18n.t('connectors_settings_browser_description'),
        connected: currentBrowserIsConnected('firefox'),
        authenticated: true as const,
        isWebExtension: true as const,
        installUrl: '', // populated by API below
      },
      safari: {
        id: 'safari',
        label: 'Safari Extension',
        icon: SvgSafari({ size: 24 }),
        description: i18n.t('connectors_settings_browser_description'),
        connected: currentBrowserIsConnected('safari'),
        authenticated: true as const,
        isWebExtension: true as const,
        installUrl: '', // populated by API below
      },
      edge: {
        id: 'edge',
        label: 'Edge Extension',
        icon: SvgEdge({ size: 24 }),
        description: i18n.t('connectors_settings_browser_description'),
        connected: currentBrowserIsConnected('edge'),
        authenticated: true as const,
        isWebExtension: true as const,
        installUrl: '', // populated by API below
      },
    };

  // among them, select only ones that we're currently launching (returned by API)
  const supportedExtensions = await callApiV2(
    'cmdeGetSupportedBrowserListings',
    {},
  );
  const supportedBrowsers = Object.keys(supportedExtensions) as Array<
    keyof cmde.GetSupportedBrowserListingsResponse
  >;
  const result: WebExtensionProps[] = [];

  supportedBrowsers.forEach((browser) => {
    const info = supportedExtensions[browser];
    if (info) {
      result.push({
        // all basic stuff, like extension icon, description, etc
        ...allExtensions[browser],
        // extension URL (return from API)
        installUrl: (isExtensionAlpha ? info.internal_url : info.external_url)!,
      });
    }
  });

  return result;
}

export function getCurrentBrowser(): BrowserNameTypes | undefined {
  const userAgentString = navigator.userAgent;
  // userAgent of Edge returns the user family with Chrome at the beginning so
  // we should detect Edge first.
  if (userAgentString.includes('Edg')) {
    return 'edge';
  } else if (userAgentString.includes('Chrome')) {
    return 'chrome';
  } else if (userAgentString.includes('Safari')) {
    return 'safari';
  } else if (userAgentString.includes('Firefox')) {
    return 'firefox';
  }
  return undefined;
}

export const browserNameForCurrentBrowser = () => {
  const browser = getCurrentBrowser();
  switch (browser) {
    case 'edge':
      return 'Edge';
    case 'firefox':
      return 'Firefox';
    case 'safari':
      return 'Safari';
    default:
      return 'Chrome';
  }
};

export const logoForCurrentBrowser = (showGenericLogo: boolean) => {
  if (showGenericLogo) {
    return PuzzlePieceFill;
  }

  // TODO: add safari once it's available
  const browser = getCurrentBrowser();
  switch (browser) {
    case 'edge':
      return MicrosoftEdgeExternalLogo;
    case 'firefox':
      return FirefoxExternalLogo;
    default:
      return ChromeExternalLogo;
  }
};

export function SvgForCurrentBrowserWebExtension() {
  const browser = getCurrentBrowser();
  switch (browser) {
    case 'edge':
      return SvgEdge({ size: 100 });
    case 'firefox':
      return SvgFirefox({ size: 100 });
    case 'safari':
      return SvgSafari({ size: 100 });
    default:
      return SvgChrome({ size: 100 });
  }
}

export const extensionLinkForCurrentBrowser = () => {
  const browser = getCurrentBrowser();

  // TODO: add safari once available
  switch (browser) {
    case 'edge':
      return EDGE_EXTENSION_WEB_STORE;
    case 'firefox':
      return FIREFOX_EXTENSION_WEB_STORE;
    default:
      return CHROME_EXTENSION_WEB_STORE;
  }
};

export const openExtensionLinkForCurrentBrowser = () => {
  const browser = getCurrentBrowser();
  const extensionUrl = extensionLinkForCurrentBrowser();
  // Firefox opens a direct download link whereas the other browsers are navigated to their browser extension page,
  // Without this a blank window opens in Firefox.
  if (browser === 'firefox') {
    window.location.href = extensionUrl;
  } else {
    openURL(extensionUrl);
  }
};

function readCookie(name: string) {
  for (const c of document.cookie.split(';')) {
    const key = c.split('=', 1)[0];
    if (key.trim() === name) {
      return c.substring(key.length + 1, c.length).trim();
    }
  }

  return undefined;
}

export const isExtensionInstalled = (): boolean => {
  const cookie = readCookie('DropboxDashBrowserExtensionInstalled');

  return (
    Boolean(cookie) ||
    document.getElementsByTagName('save-to-dropbox-container').length > 0
  );
};

export const useIsExtensionInstalled = () => {
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (!isInstalled) {
      intervalId = setInterval(() => {
        const installed = isExtensionInstalled();

        if (installed) {
          setIsInstalled(true);
          clearInterval(intervalId);
        }
      }, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isInstalled]);

  return isInstalled;
};

export const isExtensionAvailableForBrowser = () => {
  const AVAILABLE_BROWSER_EXTENSIONS = ['Chrome', 'Edge'];
  const browser = browserNameForCurrentBrowser();

  return AVAILABLE_BROWSER_EXTENSIONS.includes(browser);
};
