import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  BugLine,
  CloseLine,
  CopyLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useAccountIsDropboxer } from '@mirage/service-auth/useDropboxAccount';
import { tagged } from '@mirage/service-logging';
import { copyToClipboard } from '@mirage/service-platform-actions';
import { set as setSetting } from '@mirage/service-settings';
import useSettings from '@mirage/service-settings/useSettings';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { showSnackbar } from '@mirage/shared/snackbar';
import { Fragment, useEffect, useMemo, useState } from 'react';
import styles from './DebugCard.module.css';

import type { ScoredResult } from '@mirage/service-search/service';

const logger = tagged('combined-result-debugger');

// TODO wish list items:
// ConnectionID - this doesn't exist yet on search obj
// Is result pinned due to being higher than the relevance score threshold?
// Number of upstream calls made
// All the config values used (threshold, pinned results, etc)
// Highest ranking upstream result
// Total number of results
// What batch the result was rendered in. For example the first batch is pinned server results, 2nd batch is quick upstream, 3rd batch is slow upstream, etc

/**
 * Should contain all of the information we want to know for any debug results.
 *
 * Because they come from various sources, they all don't have the same
 * information provided for each result, so this pulls out the common
 * info we want to display.
 */
type NormalizedDebugResult = {
  type: 'typeahead' | 'search';
  uuid: string;
  title: string;
  serverScore?: number | string;
  clientScore?: number;
  clientScoreComponents?: [number, number][];
  source: string;
  connector?: string;
  id3p?: string | null;
  originalResult: typeahead.ScoredResult | ScoredResult;
};

// this component should be passed either the searchResults OR the typeaheadResults, but not both.
export type Props =
  | {
      searchResults: ScoredResult[];
      typeaheadResults?: undefined;
    }
  | {
      searchResults?: undefined;
      typeaheadResults: typeahead.ScoredResult[];
    };

/**
 * A debug overlay that shows information about the search results you hover over.
 *
 * It checks if it should show or not internally, so you can just drop it in
 * your component tree with the correct results prop and it'll handle the rest.
 */
export const DebugCard = ({ searchResults, typeaheadResults }: Props) => {
  const { settings } = useSettings(['appDebug']);
  const { isDropboxer } = useAccountIsDropboxer();

  const isDebuggerEnabled = !!settings?.appDebug && !!isDropboxer;

  const results = useMemo(() => {
    if (typeaheadResults) {
      return typeaheadResults.map<NormalizedDebugResult>((result) => {
        const common = {
          type: 'typeahead',
          uuid: result.uuid,
          clientScore: result.score,
          clientScoreComponents: result.components,
          source: result.metadata?.source ?? 'Unknown',
          originalResult: result,
        } as const;

        switch (result.type) {
          case typeahead.ResultType.DesktopFile:
          case typeahead.ResultType.DesktopApplication:
          case typeahead.ResultType.SearchResult:
          case typeahead.ResultType.Recommendation: {
            return {
              ...common,
              title: result.result.title,
            };
          }
          case typeahead.ResultType.PreviousQuery:
          case typeahead.ResultType.SuggestedQuery: {
            return {
              ...common,
              title: result.result.query,
            };
          }
          case typeahead.ResultType.Stack: {
            return {
              ...common,
              title: result.result.stack_data?.name ?? 'Unknown',
            };
          }
          case typeahead.ResultType.StackItem: {
            return {
              ...common,
              title: result.result.name,
            };
          }
          case typeahead.ResultType.URLShortcut: {
            return {
              ...common,
              title: result.result.parameters.title
                .map((titleToken) => titleToken.value)
                .join(' '),
            };
          }
          case typeahead.ResultType.MathCalculation: {
            return {
              ...common,
              title: result.result.answer,
            };
          }
          default:
            result satisfies never;
            logger.warn('Unrecognized typeahead result type');
            return undefined as never;
        }
      });
    }

    if (searchResults) {
      return searchResults.map<NormalizedDebugResult>((result) => ({
        type: 'search',
        uuid: result.uuid,
        title: result.title,
        serverScore:
          result.searchResultSource === 'upstream'
            ? 'N/A - upstream result'
            : result.relevanceScore,
        clientScore: result.score,
        source: result.searchResultSource ?? 'Unknown',
        connector: result.connectorInfo.displayName,
        id3p: result.id3p,
        originalResult: result,
      }));
    }

    return [];
  }, [searchResults, typeaheadResults]);

  const [result, setResult] = useState<NormalizedDebugResult | null>(null);

  useEffect(() => {
    const listenForHover = (event: MouseEvent) => {
      // Start with the event target
      let targetElement = event.target as HTMLElement | null;

      // Traverse up the DOM tree to find the closest ancestor with the data attribute
      while (
        targetElement !== null &&
        !targetElement.hasAttribute('data-x-uuid')
      ) {
        targetElement = targetElement.parentElement;
      }

      // If an element with the data attribute was found, grab the UUID
      if (targetElement !== null && targetElement.hasAttribute('data-x-uuid')) {
        const uuid = targetElement.getAttribute('data-x-uuid');

        // then find the result with that UUID and setting it in state. we want the null to store here if it's not found so the debugger hides
        setResult(results.find((r) => r.uuid === uuid) ?? null);
      }
    };

    if (isDebuggerEnabled) {
      document.addEventListener('mouseover', listenForHover);
    }
    return () => {
      document.removeEventListener('mouseover', listenForHover);
    };
  }, [isDebuggerEnabled, results]);

  if (!isDebuggerEnabled || !result) {
    return null;
  } else {
    const rows = [
      {
        title: 'Title',
        value: result.title,
        onCopy: () => {
          copyToClipboard(result.title);
        },
      },
      {
        title: 'Result Type',
        value: result.type,
        onCopy: () => {
          copyToClipboard(result.type);
        },
      },
      {
        title: 'UUID',
        value: result.uuid,
        onCopy: () => {
          copyToClipboard(result.uuid);
        },
      },
      {
        title: 'Server Score',
        value: result.serverScore ?? '-',
        onCopy: () => {
          result.serverScore && copyToClipboard(result.serverScore.toString());
        },
      },
      {
        title: 'Client Score',
        value: `${result.clientScore} - components: ${
          result.clientScoreComponents
            ? JSON.stringify(
                result.clientScoreComponents?.map(([score, weight]) => [
                  Math.round(score * 1000) / 1000,
                  weight,
                ]),
              )
            : 'N/A'
        }`,
        onCopy: () => {
          copyToClipboard(
            `${result.clientScore} ${result.clientScoreComponents}`,
          );
        },
      },
      {
        title: 'Source',
        value: result.source,
        onCopy: () => {
          copyToClipboard(result.source);
        },
      },
      {
        title: 'Connector',
        value: result.connector ?? '-',
        onCopy: () => {
          result.connector && copyToClipboard(result.connector);
        },
      },
      {
        title: 'ID3P',
        value: result.id3p ?? '-',
        onCopy: () => {
          result.id3p && copyToClipboard(result.id3p);
        },
      },
    ];

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <Title className={styles.title} size="small">
            <UIIcon src={BugLine} />
            Debug
          </Title>
          <div className={styles.buttonGroup}>
            <Button
              variant="outline"
              size="small"
              onClick={() => {
                copyToClipboard(JSON.stringify(result, undefined, 2));
                showSnackbar({ title: 'Copied result to clipboard' });
              }}
            >
              Copy Result
            </Button>

            <Button
              variant="outline"
              size="small"
              onClick={() => {
                copyToClipboard(JSON.stringify(results, undefined, 2));
                showSnackbar({ title: 'Copied all results to clipboard' });
              }}
            >
              Copy All Results
            </Button>
            <IconButton
              variant="outline"
              size="small"
              onClick={() => {
                setSetting('appDebug', 0);
              }}
            >
              <UIIcon src={CloseLine} />
            </IconButton>
          </div>
        </div>
        <div className={styles.gridContainer}>
          {rows.map(({ title, value, onCopy }) => {
            return (
              <Fragment key={`title_${title}`}>
                <div className={styles.gridItemHeader}>
                  <Text size={'small'} isBold>
                    {title}
                  </Text>
                </div>
                <div key={`value_${title}`} className={styles.gridItem}>
                  <Text size={'small'} monospace>
                    {value}
                  </Text>
                </div>
                <div key={`copy_${title}`} className={styles.gridItem}>
                  <IconButton
                    variant="borderless"
                    size="small"
                    onClick={onCopy}
                  >
                    <UIIcon src={CopyLine} />
                  </IconButton>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    );
  }
};
