import "./App.css";
// CSS imports do not affect JS tree shaking.
// eslint-disable-next-line no-restricted-imports
import "@dropbox/dig-components/dist/index.web.css";
import "@dropbox/dig-fonts/dist/index.min.css";
import "@dropbox/dig-fonts/dist/sharp_grotesk_23.min.css";
import "@dropbox/dig-foundations/dist/index.css";

import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import { OnboardingChecklistEnabledAtom } from "@mirage/growth";
import { FullPageSpinner } from "@mirage/mosaics/FullPageSpinner";
import {
  FeatureFlagsStatus,
  useInitFeatureFlags,
} from "@mirage/service-experimentation/useInitFeatureFlags";
import { tagged } from "@mirage/service-logging";
import { logPageLoadMilestoneOnce } from "@mirage/service-operational-metrics/page-load";
import { SmoothShow } from "@mirage/shared/smooth-show/SmoothShow";
import { AppShell } from "@mirage/webapp/components/AppShell";
import { useInitPriorityAuthedJobs } from "@mirage/webapp/hooks/useInitAuthedJobs";
import { useSetAtom } from "jotai";

import { useRoute } from "./Routes";
import { AuthedContainer } from "./components/AuthedContainer";
import { useAuthCheck } from "./hooks/useAuthCheck";
import { useFullstory } from "./hooks/useFullstory";
import { useInitSentryReplay } from "./hooks/useInitSentryReplay";
import { useRedirectToStagingIfNeeded } from "./hooks/useRedirectToStagingIfNeeded";

const logger = tagged("App.tsx");

const AppContent = () => {
  logPageLoadMilestoneOnce("AppContent start");

  const setOnboardingChecklistEnabled = useSetAtom(
    OnboardingChecklistEnabledAtom,
  );
  useFullstory();
  const route = useRoute();
  const loggedIn = useAuthCheck(route);
  const featureFlagsStatus = useInitFeatureFlags(loggedIn);

  useEffect(() => {
    setOnboardingChecklistEnabled(true);
  }, [setOnboardingChecklistEnabled]);

  useEffect(() => {
    if (loggedIn && window.opener) {
      logger.info("App - in embed mode since window.opener is populated");
      window.opener?.postMessage("oauth-complete");
    }
  }, [loggedIn]);

  if (loggedIn) {
    logger.debug(`AppContent loggedIn`);
    return <PostAuthCheckContainer featureFlagsStatus={featureFlagsStatus} />;
  }

  if (
    route.requiresAuthentication ||
    featureFlagsStatus !== FeatureFlagsStatus.LOGGED_OUT_READY
  ) {
    // Wait for useAuthCheck to take us to the login page
    // or for feature flags to be ready before mounting the
    // "public" components.
    logger.debug(
      `AppContent spinner: ` +
        `requiresAuthentication=${route.requiresAuthentication}, ` +
        `featureFlagsStatus=${featureFlagsStatus}`,
    );
    return <FullPageSpinner spinnerId="App_notLoggedIn" />;
  }

  return (
    <SmoothShow>
      <route.Component />
    </SmoothShow>
  );
};

const PostAuthCheckContainer: React.FC<{
  featureFlagsStatus: FeatureFlagsStatus;
}> = ({ featureFlagsStatus }) => {
  logPageLoadMilestoneOnce("PostAuthCheckContainer start");

  useRedirectToStagingIfNeeded();
  useInitSentryReplay();
  useInitPriorityAuthedJobs();

  return featureFlagsStatus === FeatureFlagsStatus.LOGGED_IN_READY ? (
    <AuthedContainer />
  ) : (
    <FullPageSpinner spinnerId="App_initAuth" />
  );
};

export const App: React.FC = () => {
  logPageLoadMilestoneOnce("App start");

  return (
    <BrowserRouter>
      <AppShell>
        <AppContent />
      </AppShell>
    </BrowserRouter>
  );
};
