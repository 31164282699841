import {
  ONE_HOUR_IN_MILLIS,
  ONE_MINUTE_IN_MILLIS,
} from '@mirage/shared/util/constants';

import type { dash } from '@dropbox/api-v2-client';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';

export const getUpcomingCalendarEvents = (
  includeAllDay: boolean = false,
  callApiV2: APIv2Callable,
): Promise<dash.SearchResult[] | undefined> => {
  // Get meetings that started 10 minutes ago to 12 hours from now
  const startTime = Date.now() - ONE_MINUTE_IN_MILLIS * 10;
  const endTime = Date.now() + ONE_HOUR_IN_MILLIS * 12;
  return callApiV2('dcsGetCalendarEvents', {
    start_datetime: new Date(startTime).toISOString(),
    end_datetime: new Date(endTime).toISOString(),
    include_ongoing: true,
    include_all_day: includeAllDay,
  })
    .then((r) => {
      return r.events?.sort((a, b) => {
        // Shorter events first
        if (a.start_time === b.start_time) {
          return (a.end_time ?? 0) - (b.end_time ?? 0);
        }
        return (a.start_time ?? 0) - (b.start_time ?? 0);
      });
    })
    .catch(() => undefined);
};
