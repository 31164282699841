import { Button } from '@dropbox/dig-components/buttons';
import { Toggle } from '@dropbox/dig-components/controls';
import { LayerContext } from '@dropbox/dig-components/layer';
import { Modal } from '@dropbox/dig-components/modal';
import { Snackbar } from '@dropbox/dig-components/snackbar';
import { TextArea } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { CheckmarkLine, LinkLine } from '@dropbox/dig-icons/assets';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import i18n from '@mirage/translations';
import { useCallback, useEffect, useState } from 'react';
import Header from '../FullScreenStack/Header';
import { AccessRow } from './AccessRow';
import { AccessRowV2 } from './AccessRowV2';
import { InvitePeople } from './InvitePeople';
import styles from './ShareModal.module.css';
import { SharingTypeahead } from './SharingTypeahead';
import { ShowMembers } from './ShowMembers';
import {
  SharingMember,
  SharingMemberKey,
  SharingMemberKeyWithPermission,
  SharingStackPermission,
  SharingUserContactKey,
  StackAccessLevel,
  StackInfo,
  StackMember,
  StackPermission,
} from './Types';
import { validateEmail } from './Utils';

import type { stacks } from '@dropbox/api-v2-client';

const InviteForm: React.FC<{
  shareNotificationEnabled: boolean;
  setShareNotificationEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  notificationNoteText: string;
  setNotificationNoteText: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  shareNotificationEnabled,
  setShareNotificationEnabled,
  notificationNoteText,
  setNotificationNoteText,
}) => {
  return (
    <>
      <div className={styles.notificationToggleContainer}>
        <Toggle
          isToggled={shareNotificationEnabled}
          onChange={() =>
            setShareNotificationEnabled(!shareNotificationEnabled)
          }
        />
        <Text className={styles.standardText}>
          {i18n.t('send_email_notification')}
        </Text>
      </div>
      {shareNotificationEnabled && (
        <TextArea
          className={styles.noteTextArea}
          placeholder={i18n.t('add_a_note')}
          value={notificationNoteText}
          onChange={(e) => {
            setNotificationNoteText(e.currentTarget.value);
          }}
        />
      )}
    </>
  );
};

const FooterButtons: React.FC<{
  isInvitingMembers: boolean;
  handleCopyStackShareLink: () => void;
  shareWithMembers: () => void;
  namespaceId: string | undefined;
  isSharingStack: boolean;
  marchUpdateEnabled: boolean;
}> = ({
  isInvitingMembers,
  handleCopyStackShareLink,
  shareWithMembers,
  namespaceId,
  isSharingStack,
  marchUpdateEnabled,
}) => {
  const [linkCopiedAtTimestamp, setLinkCopiedAtTimestamp] = useState<
    number | undefined
  >(undefined);
  const linkCopied = linkCopiedAtTimestamp !== undefined;

  const handleClickCopyLink = () => {
    handleCopyStackShareLink();
    setLinkCopiedAtTimestamp(Date.now());
  };

  useEffect(() => {
    // Note that if the timestamp changes because the user clicks again, the 5s timer is reset.
    if (linkCopiedAtTimestamp !== undefined) {
      const id = setTimeout(() => setLinkCopiedAtTimestamp(undefined), 5000);
      return () => clearTimeout(id);
    }
    return undefined;
  }, [linkCopiedAtTimestamp]);

  return isInvitingMembers ? (
    <div className={styles.sendButtonParent}>
      <Button
        variant="primary"
        onClick={shareWithMembers}
        disabled={!namespaceId}
        size="large"
        isLoading={isSharingStack}
      >
        <Text inverse>{i18n.t('send')}</Text>
      </Button>
    </div>
  ) : (
    <Button
      variant={marchUpdateEnabled ? 'filled' : 'outline'}
      inverse={marchUpdateEnabled}
      onClick={handleClickCopyLink}
      disabled={!namespaceId}
      size="large"
      fullWidth={true}
      className={styles.copyLinkButton}
      data-testid="Stacks-copyLinkButton"
    >
      <UIIcon src={linkCopied ? CheckmarkLine : LinkLine} />
      <Text className={styles.standardText} isBold>
        {i18n.t(linkCopied ? 'link_copied2' : 'copy_link')}
      </Text>
    </Button>
  );
};

const SharingTypeaheadWithState = ({
  stackInfo,
  share,
  isSharing,
  getSharingMembers,
}: {
  stackInfo: StackInfo;
  share: (members: (SharingMember | SharingUserContactKey)[]) => void;
  isSharing: boolean;
  getSharingMembers: (searchText: string) => Promise<SharingMember[]>;
}) => {
  const [shareText, setShareText] = useState('');
  return (
    <SharingTypeahead
      stackMemberKeys={stackInfo.members}
      onShare={share}
      shareText={shareText}
      onShareTextChange={setShareText}
      isSharing={isSharing}
      excludedMembers={[]}
      getSharingMembers={getSharingMembers}
      allowExternalShare={true}
    />
  );
};

export const ShareModalInternal = ({
  isOpen,
  onCancel,
  currentMember,
  stackInfo,
  getSharingMembers,
  handleCopyStackShareLink,
  handleShareStackWithMembers,
  handleRevokeStackMember,
  handleUpdateStackSharedLink,
}: {
  isOpen: boolean;
  onCancel: () => void;
  currentMember: StackMember;
  stackInfo: StackInfo;
  getSharingMembers: (searchText: string) => Promise<SharingMember[]>;
  handleCopyStackShareLink: () => void;
  handleShareStackWithMembers: (
    sendNotifications: boolean,
    notificationMessage: string,
    members: SharingMemberKeyWithPermission[],
    onFailure: () => void,
  ) => Promise<void>;
  handleRevokeStackMember: (
    member: StackMember,
  ) => Promise<stacks.RemoveStackSharingMembersResponse | undefined>;
  handleUpdateStackSharedLink: (
    accessLevel: StackAccessLevel,
    permission: SharingStackPermission,
  ) => Promise<boolean>;
}) => {
  const me = useDropboxAccount();
  const [isSharingStack, setIsSharingStack] = useState(false);
  const [invitees, setInvitees] = useState<
    (SharingMember | SharingUserContactKey)[]
  >([]);
  const [inviteePermission, setInviteePermission] =
    useState<SharingStackPermission>(StackPermission.WRITE);
  const [shareNotificationEnabled, setShareNotificationEnabled] =
    useState(true);
  const [notificationNoteText, setNotificationNoteText] = useState('');

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const closeSnackbar = () => {
    setIsSnackbarOpen(false);
    setSnackbarMessage('');
  };
  const openSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setIsSnackbarOpen(true);
  };
  const marchUpdateEnabled =
    useFeatureFlagValue(
      'dash_web_2024_05_23_sharing_modal_update',
      false /* includeExposureLogging */,
    ) === 'ON';

  const isInvitingMembers = invitees.length > 0;

  const shareWithMemberKeys = useCallback(
    async (
      memberKeys: SharingMemberKey[],
      notifEnabled: boolean = marchUpdateEnabled, // new sharing modal does not have a toggle for notifications, so default it to on
      notifText: string = '',
    ) => {
      setIsSharingStack(true);
      // Add api call to async queue.
      handleShareStackWithMembers(
        notifEnabled,
        notifText,
        memberKeys
          .filter(
            (memberKey) =>
              memberKey['.tag'] === 'group' || validateEmail(memberKey.email),
          )
          .map((memberKey) => ({ memberKey, permission: inviteePermission })),
        () => setSnackbarMessage(i18n.t('failed_to_add_sharing_members')),
      );

      setIsSharingStack(false);
    },
    [handleShareStackWithMembers, inviteePermission, marchUpdateEnabled],
  );

  const shareWithCollectedMembers = useCallback(async () => {
    setInvitees([]);

    shareWithMemberKeys(
      invitees,
      shareNotificationEnabled,
      notificationNoteText,
    );
  }, [
    shareWithMemberKeys,
    invitees,
    shareNotificationEnabled,
    notificationNoteText,
  ]);

  return (
    // This element is not user-interactable.
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      // Important: Prevent a random click from closing the modal.
      onClick={(e) => {
        !marchUpdateEnabled && e.stopPropagation();
      }}
      // Prevent the modal anchor from changing the size of a parent component with a flex box
      // and gap property set
      style={{ display: 'none' }}
    >
      <Modal
        open={isOpen}
        withCloseButton={i18n.t('close')}
        isCentered
        onRequestClose={onCancel}
        shouldCloseOnOverlayClick={marchUpdateEnabled}
        className={
          marchUpdateEnabled ? styles.sharingModalNew : styles.sharingModal
        }
        data-testid="ShareModal"
        width={marchUpdateEnabled ? 486 : 450}
      >
        <Modal.Header
          className={marchUpdateEnabled ? styles.sharingModalHeaderNew : ''}
        >
          {marchUpdateEnabled ? (
            <Header
              defaultUserInfo={{
                displayName: me?.name.display_name ?? '',
                profilePhotoUrl: me?.profile_photo_url,
              }}
              triggerEditStackRef={undefined}
              withImmediateAction={<></>}
              canEditOverride={false}
              additionalContainerStyles={styles.stackHeader}
            />
          ) : (
            <Modal.Title size="small" className={styles.sharingModalTitle}>
              {i18n.t('modal_title', { stackName: stackInfo.name })}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <LayerContext.Provider
            value={{
              zIndex: 1_000_010,
              portalRootElement: document.documentElement,
            }}
          >
            {marchUpdateEnabled ? (
              <SharingTypeaheadWithState
                stackInfo={stackInfo}
                share={shareWithMemberKeys}
                isSharing={isSharingStack}
                getSharingMembers={getSharingMembers}
              />
            ) : (
              <InvitePeople
                stackInfo={stackInfo}
                getSharingMembers={getSharingMembers}
                invitees={invitees}
                setInvitees={setInvitees}
                inviteePermission={inviteePermission}
                setInviteePermission={setInviteePermission}
              />
            )}
            {isInvitingMembers ? (
              <InviteForm
                setNotificationNoteText={setNotificationNoteText}
                notificationNoteText={notificationNoteText}
                shareNotificationEnabled={shareNotificationEnabled}
                setShareNotificationEnabled={setShareNotificationEnabled}
              />
            ) : (
              <>
                <ShowMembers
                  stackInfo={stackInfo}
                  currentMember={currentMember}
                  handleRevokeStackMember={handleRevokeStackMember}
                  handleShareStackWithMembers={handleShareStackWithMembers}
                  openSnackbar={openSnackbar}
                  marchUpdateEnabled={marchUpdateEnabled}
                />
                {marchUpdateEnabled ? (
                  <AccessRowV2
                    stackInfo={stackInfo}
                    handleUpdateStackSharedLink={handleUpdateStackSharedLink}
                    openSnackbar={openSnackbar}
                  />
                ) : (
                  <AccessRow
                    stackInfo={stackInfo}
                    handleUpdateStackSharedLink={handleUpdateStackSharedLink}
                    openSnackbar={openSnackbar}
                  />
                )}
              </>
            )}
          </LayerContext.Provider>
        </Modal.Body>
        <Modal.Footer className={marchUpdateEnabled ? '' : styles.footerBlock}>
          <FooterButtons
            isInvitingMembers={isInvitingMembers}
            shareWithMembers={shareWithCollectedMembers}
            isSharingStack={isSharingStack}
            handleCopyStackShareLink={handleCopyStackShareLink}
            namespaceId={stackInfo.namespaceId}
            marchUpdateEnabled={marchUpdateEnabled}
          />
          <Snackbar
            className={styles.snackbarContainer}
            open={isSnackbarOpen}
            onRequestClose={closeSnackbar}
            preferComposition
          >
            <Snackbar.Content>
              <Snackbar.Message>{snackbarMessage}</Snackbar.Message>
              <Snackbar.Actions>
                <Button variant="transparent" onClick={closeSnackbar}>
                  {i18n.t('dismiss')}
                </Button>
              </Snackbar.Actions>
            </Snackbar.Content>
          </Snackbar>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
