import { dash_feed, users } from '@dropbox/api-v2-client';
import { Text } from '@dropbox/dig-components/typography';
import { Trans } from 'react-i18next';
import { ActivityLink } from '../ActivityLink/ActivityLink';

type EditFileActivityActionProps = {
  activity: dash_feed.ActivityItem;
  currentAccount?: users.FullAccount;
  action: dash_feed.object_action_unionEdit;
};

export const EditFileActivityAction = ({
  activity,
  currentAccount,
}: EditFileActivityActionProps) => {
  const fileName = activity.object!.name;
  if (activity.actor) {
    const isMe = activity.actor.email === currentAccount?.email;
    const actorName = activity.actor.name;
    // Naomi Triller modified your Klein Blue One Pager file
    return (
      <Text variant="label" isBold>
        <Trans
          i18nKey={
            isMe
              ? 'activity_feed_action_edit_file_me'
              : 'activity_feed_action_edit_file'
          }
          values={{ actorName, fileName }}
          components={{
            fileLink: <ActivityLink key={0} href={activity.object!.url!} />,
          }}
        />
      </Text>
    );
  } else {
    // we don't always know who did the edit
    // Klein Blue One Pager was modified
    return (
      <Text variant="label" isBold>
        <Trans
          i18nKey="activity_feed_action_edit_file_no_actor"
          values={{ fileName }}
          components={{
            fileLink: <ActivityLink key={0} href={activity.object!.url!} />,
          }}
        />
      </Text>
    );
  }
};
