import { Truncate } from '@dropbox/dig-components/truncate';
import { Text, TextColorType } from '@dropbox/dig-components/typography';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import classNames from 'classnames';
import * as React from 'react';
import styles from './BaseListItem.module.css';

type Props = Readonly<{
  className?: string;
  /** left hand side icon */
  icon?: React.ReactNode;
  textColor?: TextColorType | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  /** default title */
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  swapLayout?: boolean;

  role?: React.AriaRole;
  ariaLabel?: string;
  disabled?: boolean;
  tooltipTitle?: string;
}>;

export default function BaseListItem(props: Props) {
  const {
    className,
    icon,
    textColor = 'standard',
    onClick,
    title = '',
    subtitle,
    swapLayout = false,
    role,
    ariaLabel,
    children,
    disabled = false,
    tooltipTitle,
  } = props;
  const Icon = icon || null;

  const mouseClick = React.useCallback(
    (_e) => onClick && !disabled && onClick(),
    [onClick, disabled],
  );

  return (
    <DigTooltip title={tooltipTitle}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={classNames(styles.row, className)}
        onClick={onClick ? mouseClick : undefined}
        role={role}
        aria-label={ariaLabel}
      >
        {!swapLayout && children}
        {Icon && <div className={styles.iconContainer}>{Icon}</div>}
        <div className={styles.rowContentContainer}>
          <Text color={disabled ? 'disabled' : textColor}>
            {typeof title === 'string' ? (
              <Truncate>
                <div className={styles.title}>{title}</div>
              </Truncate>
            ) : (
              <div className={styles.title}>{title}</div>
            )}
          </Text>
          {subtitle && (
            <Text size="small" variant="label" color="subtle">
              {subtitle}
            </Text>
          )}
        </div>
        {swapLayout && children}
      </div>
    </DigTooltip>
  );
}
