/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
// XXX: for some reason, regardless of the contents of "lib" in tsconfig, when
// hornet imports this from mirage it fails to make tsc happy. this is the
// easiest solution in the interim
/// <reference lib="es2021.weakref" />
import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { batchedIntervalFunc } from '@mirage/shared/util/batching';
import { getReadableTime } from '@mirage/shared/util/tiny-utils';
// import redact from '@mirage/service-logging/redact';
import { createConsola, LogLevels } from 'consola';
import { serializeError } from 'serialize-error';
import { vsprintf } from 'sprintf-js';

// import { ConsolaReporter} from 'consola'/
import type { LogMessage, Service } from '@mirage/service-logging/service';
import type { LogLevel, LogObject } from 'consola';

export type { Service };
// each context will have its own instance of consola's logger and allow for
// sub-context creation via tagging. these outputs will be fed into a custom
// reporter which sends information to the service to aggregate and transport
// log messages to the appropriate sinks
const service = services.getp<Service>(ServiceId.LOGGING);
const instance = createConsola({ level: LogLevels.debug });
const loggers: Map<
  string,
  WeakRef<ReturnType<typeof createConsola>>
> = new Map();

const batchedSink = batchedIntervalFunc(
  (batch: LogMessage[]) => service.transport(batch),
  // Batch to logging per second. This shouldn't be set too high as it might
  // cause us to lose some logs, but shouldn't be set too low as most of the
  // logs we have are not needed urgently.
  1000,
);

// create a transport specific to our service-based cross-proc ingestion
const log = (log: LogObject) => {
  if (!serializable(log)) {
    console.warn('attempting to log non-transferrable data, dropping!');
    return;
  }

  batchedSink(normalize(log));
};
instance.addReporter({ log });

// we have to manage setting our reporters and child reporters when the log
// level changes, track local state here as well as our service ingest reporter
const SERVICE_REPORTER = { log };
let currentLogLevel = LogLevels.info;
service
  .getLevel()
  .then((level) => {
    // update our local state on what log level we are targeting
    currentLogLevel = level;
    for (const [tag, ref] of loggers.entries()) {
      // this child may have went out of scope and got gc'd, do cleanup here
      const child = ref.deref();
      if (!child) {
        loggers.delete(tag);
        continue;
      }
      child.setReporters([
        SERVICE_REPORTER,
        // this ensures that the console mirrors our log level without being
        // impacted by the consola logger level setting (i.e. filtering out
        // logs that need to go to other sinks)
        new BrowserReporter(currentLogLevel),
      ]);
    }
  })
  .catch((e) =>
    console.warn('failed to get current log level, using "debug"', e),
  );

// of course, nothing is as easy as it seems when it comes to software. we want
// to be able to programatically set the levels of _all_ loggers simultaneously,
// which, due to how consola creates tagged instances, we need to track them
// all locally when they are created as they are not referencing their parent
export const tagged = (tag: string) => {
  const child = instance.withTag(tag);
  child.level = instance.level;
  loggers.set(tag, new WeakRef(child));
  return child;
};

export const exportLogs = () => {
  service.exportLogs();
};

//
// internal helper api
// (to make sure we don't shoot ourselves in the foot)
//------------------------------------------------------------------------------
// not everything can be sent over ipc, attempt a structured clone like the ipc
// channel will do behind the scenes and see if it fails!
function serializable(log: LogObject) {
  try {
    structuredClone(log);
  } catch (e) {
    return false;
  }
  return true;
}

function normalize(log: LogObject): LogMessage {
  const [message, extra] = flatten(log);
  return {
    message,
    extra: extra,
    date: log.date,
    level: log.type,
    label: log.tag,
  };
}

const formatRegExp = /%[scdjifoO%]/g;
const escapedPercent = /%%/g;

// sprintf and some other message concatenation to make reported logs look more
// normal when not using string interpolation with template strings
type Flattened = [string, Array<unknown | object | Array<unknown>>];
function flatten(log: LogObject): Flattened {
  const [first, ...splat] = log.args;

  // in the event the first entry in the args array is an object, default to
  // serializing via json since we have no good way to manage integrating it
  if (first instanceof Object) return [JSON.stringify(log.args), []];

  // attempt to perform string replacement
  let msg = first;
  const tokens = msg && msg.match && msg.match(formatRegExp);
  if (tokens) {
    const percents = msg.match(escapedPercent);
    const escapes = (percents && percents.length) || 0;
    const args = splat.splice(0, tokens.length - escapes);
    msg = vsprintf(msg, args);
  }

  // if there are remaining splat values after handling string replacement,
  // attempt to concatenate them with the message
  while (splat.length && !(splat[0] instanceof Object)) {
    msg = `${msg} ${splat.splice(0, 1)[0]}`;
  }

  // attempt to keep error information (since it will be lost going over ipc)
  // XXX: i previously had iterated across objects looking for errors, but this
  // is probably _good enough_ without writing a serialization library :|
  const mapped = splat.map((value) => {
    return value instanceof Error ? serializeError(value) : value;
  });

  return [msg, mapped];
}

// consola does not expose these externally, so we are kinda fucked trying to do
// any sort of level-setting without a direct import

export class BrowserReporter {
  level: LogLevel;
  defaultColor: string;
  levelColorMap: Record<number, string>;
  typeColorMap: Record<string, string>;

  constructor(level: any) {
    this.level = level;

    this.defaultColor = '#7f8c8d'; // Gray
    this.levelColorMap = {
      0: '#c0392b', // Red
      1: '#f39c12', // Yellow
      3: '#00BCD4', // Cyan
    };
    this.typeColorMap = {
      success: '#2ecc71', // Green
    };
  }

  _getLogFn(level: number) {
    if (level < 1) return (console as any).__error || console.error;
    if (level === 1) return (console as any).__warn || console.warn;
    return (console as any).__log || console.log;
  }

  log(logObj: LogObject) {
    // ignore if verbosity is greater than our targeted level
    if (logObj.level > this.level) return;

    const consoleLogFn = this._getLogFn(logObj.level);
    const type = logObj.type === 'log' ? '' : logObj.type;
    const tag = logObj.tag || '';
    const color =
      this.typeColorMap[logObj.type] ||
      this.levelColorMap[logObj.level] ||
      this.defaultColor;

    const tagStyle = `
      background: ${color};
      border-radius: 0.5em;
      color: white;
      font-weight: bold;
      padding: 2px 0.5em;
    `;

    const timeStyle = `
      font-weight: bold;
      padding: 2px 0.5em;
      margin-right: 0.25em;
      border-radius: 0.5em;
      border: 1px solid ${color}
    `;

    const badge = `%c${[tag, type].filter(Boolean).join(':')}`;
    const timestamp = `%c${getReadableTime()}`;

    // Log to the console
    if (typeof logObj.args[0] === 'string') {
      consoleLogFn(
        `${timestamp}${badge}%c ${logObj.args[0]}`,
        timeStyle,
        tagStyle,
        // Empty string as style resets to default console style
        '',
        ...logObj.args.slice(1),
      );
    } else {
      consoleLogFn(timestamp, badge, timeStyle, tagStyle, ...logObj.args);
    }
  }
}
