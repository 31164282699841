import { dash_feed } from '@dropbox/api-v2-client';
import { ContentIcon, IconLayout } from '@dropbox/dash-component-library';
import { Avatar } from '@dropbox/dig-components/avatar';
import { Skeleton } from '@dropbox/dig-components/skeleton';
import { DashStackIcon } from '@mirage/dash-component-library/components/DashStackIcon';
import { isStackActivityObject } from '@mirage/service-feed/util/helpers';
import { getInitialsFromDisplayName } from '@mirage/shared/account';
import { toActivityResult } from '@mirage/shared/feed/activity-result';
import { FileTypeIcon } from '@mirage/shared/icons';
import styles from './ActivityThumbnail.module.css';

type ActivityThumbnailProps = {
  activity: dash_feed.ActivityItem;
};

export const SkeletonActivityThumbnail = () => {
  return (
    <IconLayout
      content={<Skeleton.Avatar className={styles['dig-Avatar--auto']} />}
      shouldConstrainContent={false}
      size="large"
    />
  );
};

export const ActivityThumbnail = ({ activity }: ActivityThumbnailProps) => {
  let avatar = null;
  if (activity.actor) {
    avatar = (
      <Avatar
        hasNoOutline={true}
        src={activity.actor.avatar_img}
        className={styles['dig-Avatar--auto']}
      >
        {getInitialsFromDisplayName(activity.actor.name!)}
      </Avatar>
    );
  } else {
    avatar = (
      <Avatar
        hasNoOutline={true}
        isGuest={!activity.actor}
        className={styles['dig-Avatar--auto']}
      />
    );
  }
  return (
    <IconLayout
      content={avatar}
      size="large"
      shouldConstrainContent={false}
      withBadge={
        <IconLayout.Badge isStyled={false} position="bottom-right">
          <ActivityObjectIcon activity={activity} />
        </IconLayout.Badge>
      }
    />
  );
};

type ActivityObjectIconProps = {
  activity: dash_feed.ActivityItem;
};

const ActivityObjectIcon = ({ activity }: ActivityObjectIconProps) => {
  if (isStackActivityObject(activity)) {
    return (
      <StackActivityObjectIcon
        stack={
          activity.object?.details
            ?.object_details as dash_feed.object_details_unionStack
        }
      />
    );
  } else {
    return <FileActivityObjectIcon activity={activity} />;
  }
};

type StackActivityObjectIconProps = {
  stack: dash_feed.object_details_unionStack;
};

const StackActivityObjectIcon = ({ stack }: StackActivityObjectIconProps) => {
  return (
    <DashStackIcon
      size="small"
      colorIndex={stack.color_index}
      emoji={stack.emoji}
      shapeVariant="circle"
    />
  );
};

type FileActivityObjectIconProps = {
  activity: dash_feed.ActivityItem;
};

const FileActivityObjectIcon = ({ activity }: FileActivityObjectIconProps) => {
  const result = toActivityResult(activity);
  if (!result) {
    return null;
  }
  return (
    <ContentIcon
      size="small"
      shapeVariant="circle"
      hasBackground
      icon={<FileTypeIcon result={result} size="100%" />}
    />
  );
};
