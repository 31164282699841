import { Text } from '@dropbox/dig-components/typography';
import { highlightTokenizer } from '@mirage/shared/util/stop-words';
import ReactHighlightWords from 'react-highlight-words';
import styles from './TitleHighlighter.module.css';

type DIGTextSize = 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall';

type TitleHighlighterProps = Readonly<{
  title: string;
  highlightWords?: string[];
  textSize?: DIGTextSize;
}>;

const HighlightTag = (textSize: DIGTextSize) => {
  const Inner: React.FC = ({ children }) => (
    <Text size={textSize} tagName="span" className={styles.highlightedText}>
      {children}
    </Text>
  );
  return Inner;
};

export function TitleHighlighter({
  title = '',
  highlightWords = [],
  textSize = 'medium',
}: TitleHighlighterProps) {
  return (
    <div className={styles.container}>
      <ReactHighlightWords
        className={styles.titleHighlighter}
        searchWords={highlightTokenizer(highlightWords) as string[]} // third party package requires writable array, unfortunately
        autoEscape={true}
        textToHighlight={title}
        caseSensitive={false}
        highlightTag={HighlightTag(textSize)}
      />
    </div>
  );
}
