import { getStacks } from '@mirage/service-stacks';
import * as primitives from '@mirage/service-typeahead-search/service/primitives';
import * as scoring from '@mirage/service-typeahead-search/service/scoring';
import { SourceId } from '@mirage/service-typeahead-search/service/types';
import * as wrappers from '@mirage/service-typeahead-search/service/utils/wrappers';
import tokenize from '@mirage/shared/search/tokenizers';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import { v5 as uuidv5 } from 'uuid';
import { TypeaheadCache } from '../typeahead-cache';

import type { stacks } from '@dropbox/api-v2-client';
import type { typeahead } from '@mirage/service-typeahead-search/service/types';
import type { Observable } from 'rxjs';

const STACK_RESULT_NAMESPACE = 'de19e086-03f2-11ef-bbd8-325096b39f47';

const SOURCE_RESULT_LIMIT = 5;

export const search = wrappers.wrapped(SourceId.Stacks, raw);

export function raw(
  query: string,
  cache: TypeaheadCache,
): Observable<typeahead.TaggedResult> {
  return rx.defer(() => {
    // query transformations
    const qtokens = tokenize(query.toLowerCase());
    const lcquery = query.toLowerCase();
    const candidates = getStacks()
      .then((stacks) => {
        // handle potential undefined response
        if (!stacks) return [] as stacks.Stack[];

        // only keep stacks that have a namespace id... we can't launch
        // a stack result without it bc it is needed to form the route
        return stacks.filter((stack) => !!stack.namespace_id);
      })
      .then((stacks) => {
        // find query matches
        return stacks.filter((stack) => {
          const lctitle = stack?.stack_data?.name?.toLowerCase();

          // skip stacks results missing a name/title
          if (!lctitle) return false;

          // exact substring match
          if (lctitle?.includes(lcquery)) {
            return true;
          }

          // tokenize stack title
          const ttokens = tokenize(lctitle);

          // allow typos
          if (scoring.typoMatch(qtokens, ttokens)) return true;

          // no exact or similar match found
          return false;
        });
      })
      .then((stacks) => {
        return stacks.slice(0, SOURCE_RESULT_LIMIT);
      })
      .catch(() => []);

    return rx
      .from(candidates)
      .pipe(op.mergeMap((result) => result))
      .pipe(
        op.map((result) => {
          // Stacks don't have a `uuid` field, they only have a `namespace_id`
          // field. We need a `uuid` field to handle the results in the
          // front-end and PAP, so create a uuid out of `namespace_id`
          const uuid = uuidv5(
            result?.namespace_id || 'stack',
            STACK_RESULT_NAMESPACE,
          );
          return primitives.stack(uuid, result, cache.getHits(uuid));
        }),
      );
  });
}
