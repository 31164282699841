import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine, FolderLine } from '@dropbox/dig-icons/dist/mjs/assets';
import {
  openFolderPicker,
  removeCustomFolder,
  toggleLocalFilesEnabled,
  toggleSystemDirEnabled,
} from '@mirage/service-local-file-settings';
import useSettings from '@mirage/service-settings/useSettings';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';

import type {
  PreferenceOption,
  PreferenceSection,
} from '@mirage/settings/GeneralSettings';

export function useLocalFileSettings(isLocalFilesEnabled: boolean) {
  const { settings } = useSettings(['localFiles']);
  const localFiles = settings?.localFiles;

  // No React Hooks below this line pls
  if (!isLocalFilesEnabled || !localFiles) return [];

  const enableLocalFilesOption: PreferenceOption = {
    name: 'enableLocalFiles',
    label: 'Allow Dash to search your local files',
    componentType: 'checkbox',
    onClick: (value) => {
      toggleLocalFilesEnabled(Boolean(value));
    },
    value: localFiles.enabled ? 1 : 0,
  };

  const systemFolderPreferences: PreferenceOption[] =
    localFiles.systemDirectories.map((dir) => ({
      name: dir.id,
      label: dir.displayName,
      componentType: 'checkbox',
      value: dir.enabled ? 1 : 0,
      onClick: (value) => {
        toggleSystemDirEnabled(dir.id, Boolean(value)).then((success) => {
          // If a user denies access via the system prompt, the OS remembers that for future requests and
          // will no longer prompt the user. We need to inform the user that they need to manually enable permissions.
          if (!success) {
            showSnackbar({
              title: i18n.t('local_file_permission_denied', {
                directory: dir.displayName,
              }),
            });
          }
        });
      },
      icon: <UIIcon src={FolderLine} />,
      disabled: !localFiles.enabled,
    }));

  const currentCustomFolders: PreferenceOption[] =
    localFiles.customDirectories.map((dir) => ({
      name: dir.id,
      label: dir.displayName,
      componentType: 'button',
      value: dir.enabled ? 1 : 0,
      icon: <UIIcon src={FolderLine} />,
      disabled: !localFiles.enabled,
      displayActions: <RemoveButton dirId={dir.id} />,
      displayActionsOnHover: true,
      subtitle: dir.path,
    }));

  const customFolderPreferences: PreferenceOption[] = [
    {
      name: 'addCustomFolder',
      label: i18n.t('local_file_add_user_folder'),
      componentType: 'button',
      onClick: openFolderPicker,
      icon: <UIIcon src={AddLine} />,
    },
    ...currentCustomFolders,
  ];

  const localFileSections: PreferenceSection[] = [
    {
      name: i18n.t('local_file_settings_preferences_section'),
      key: 'preferences',
      preferences: [enableLocalFilesOption],
    },
    {
      name: i18n.t('local_file_settings_system_section'),
      key: 'system-folders',
      preferences: systemFolderPreferences,
    },
    {
      name: i18n.t('local_file_settings_user_section'),
      key: 'user-folders',
      preferences: customFolderPreferences,
    },
  ];
  return localFileSections;
}

const RemoveButton = ({ dirId }: { dirId: string }) => {
  return (
    <Button variant="outline" onClick={() => removeCustomFolder(dirId)}>
      {i18n.t('local_file_remove_user_folder')}
    </Button>
  );
};
