import { Layout } from '@mirage/mosaics/Layout';
import { DashSpinnerAutofitContainer } from '@mirage/shared/dash-spinner/DashSpinnerAutofitContainer';
import { Suspense } from 'react';

export const WebLayout: React.FC = ({ children }) => {
  return (
    <Layout showBetaBadge={false} globalNavAlwaysCollapsed={false}>
      {/* Keep the spinner inside the layout so that it doesn't flicker the page */}
      <Suspense fallback={<DashSpinnerAutofitContainer />}>{children}</Suspense>
    </Layout>
  );
};
