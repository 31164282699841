import { useEffect } from "react";

import { useMirageAnalyticsContext } from "@mirage/analytics/AnalyticsProvider";
import { PAP_Start_DashLogin } from "@mirage/analytics/events/types/start_dash_login";
import { FullPageSpinner } from "@mirage/mosaics/FullPageSpinner";
import { authenticate } from "@mirage/service-auth";
import { RedirectState } from "@mirage/service-auth/types";
import { useDashTitle } from "@mirage/shared/hooks/DashTitle";
import i18n from "@mirage/translations";
import { startLoginMetricTiming } from "@mirage/webapp/performance/LoginMetrics";

export const REDIRECT_TO_PATH_URL_PARAM = "redirectToPathEncoded";

export const Login = () => {
  useDashTitle(i18n.t("sign_in"));
  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    // Start timing for regular login flow.
    startLoginMetricTiming("normal");
    reportPapEvent(PAP_Start_DashLogin(), true);

    const query = new URLSearchParams(location.search);
    const redirectToPathEncoded = query.get(REDIRECT_TO_PATH_URL_PARAM);
    const redirectState: RedirectState | null = redirectToPathEncoded
      ? { redirectToPathEncoded }
      : null;
    authenticate(redirectState);
  }, [reportPapEvent]);

  return <FullPageSpinner spinnerId="Login" />;
};
