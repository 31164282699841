import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { CheckmarkCircleFill } from '@dropbox/dig-icons/assets';
import { FeatureBanner } from '@mirage/growth/components/FeatureBanner';
import { getFirstLoginTime } from '@mirage/service-onboarding';
import {
  useIsUsersDay0,
  useOnboardingValues,
} from '@mirage/service-onboarding/hooks';
import { openURL } from '@mirage/service-platform-actions';
import {
  isExtensionAvailableForBrowser,
  openExtensionLinkForCurrentBrowser,
  useIsExtensionInstalled,
} from '@mirage/settings/utils/webExtensionsHelpers';
import { DESKTOP_DOWNLOAD_URL } from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import React, { useEffect, useState } from 'react';
import styles from './SettingsBanner.module.css';

const SETTINGS_BANNER_ONBOARDING_KEY = 'dismissedSettingsBanner';

const primaryAsset =
  'https://assets.dropbox.com/images/dashweb/platform-large.png';
const mobileAsset =
  'https://assets.dropbox.com/images/dashweb/platform-small-v2.png';

type ActionButtonProps = {
  text: string;
  complete: boolean;
  onClick: () => void;
};

const ActionButton: React.FC<ActionButtonProps> = ({
  text,
  complete,
  onClick,
}) => {
  return (
    <Button
      variant="outline"
      onClick={onClick}
      withIconStart={
        complete ? (
          <UIIcon
            src={CheckmarkCircleFill}
            color="var(--dig-color__success__base--state-1)"
            className={styles.checkmarkIcon}
          />
        ) : undefined
      }
    >
      {text}
    </Button>
  );
};

export const SettingsBanner: React.FC = () => {
  const [dismissed, setDismissed] = useState(true);
  const isDay0 = useIsUsersDay0();
  const [desktopAppInstalled, setDesktopAppInstalled] = useState(false);
  const { getOnboardingValue, setOnboardingValue } = useOnboardingValues();
  const extensionInstalled = useIsExtensionInstalled();
  const extensionAvailableForBrowser = isExtensionAvailableForBrowser();
  // If extension is available and installed or not available for browser
  const hasBrowserExtensionInstalled =
    (extensionAvailableForBrowser && extensionInstalled) ||
    !extensionAvailableForBrowser;

  // Track whether the user has dismissed the banner
  // If both the desktop app is installed and the browser extension, dismiss the banner
  useEffect(() => {
    const doSetDismissed = async () => {
      const isDismissed = await getOnboardingValue(
        SETTINGS_BANNER_ONBOARDING_KEY,
      );
      setDismissed(isDismissed as boolean);
    };
    doSetDismissed();
  }, [getOnboardingValue]);

  // Set whether the user has installed the desktop app
  useEffect(() => {
    const getInstalledDesktop = async () => {
      const hasInstalledDesktop = !!(await getFirstLoginTime());
      setDesktopAppInstalled(hasInstalledDesktop);
    };
    getInstalledDesktop();
  }, []);

  const handleDismissed = () => {
    setOnboardingValue(SETTINGS_BANNER_ONBOARDING_KEY, true);
    setDismissed(true);
  };

  const handleInstallDesktopApp = () => {
    openURL(DESKTOP_DOWNLOAD_URL);
  };

  const handleInstallBrowserExtension = () => {
    openExtensionLinkForCurrentBrowser();
  };

  const allAppsInstalled = desktopAppInstalled && hasBrowserExtensionInstalled;

  if (dismissed || !isDay0 || allAppsInstalled) return null;

  return (
    <FeatureBanner
      className={styles.banner}
      title={i18n.t('settings_banner_title')}
      description={i18n.t('settings_banner_message')}
      onDismiss={handleDismissed}
      primaryAsset={
        <img
          src={primaryAsset}
          aria-label={i18n.t('settings_banner_alt')}
          className={styles.primaryAsset}
        />
      }
      mobileAsset={
        <img src={mobileAsset} aria-label={i18n.t('settings_banner_alt')} />
      }
      actions={
        <>
          {extensionAvailableForBrowser && (
            <ActionButton
              text={i18n.t('extension_call_to_action')}
              complete={hasBrowserExtensionInstalled}
              onClick={handleInstallBrowserExtension}
            />
          )}
          <ActionButton
            text={i18n.t('desktop_app_call_to_action')}
            complete={desktopAppInstalled}
            onClick={handleInstallDesktopApp}
          />
        </>
      }
    />
  );
};
