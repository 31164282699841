import { useCallback, useEffect, useState } from 'react';
import { activityFeed, loadMoreActivities, setActivityFeedFilters } from '../';
import { DEFAULT_FILTERS } from '../service';
import { UserActivityFeed } from '../types';

export const useActivityFeed = () => {
  const [userActivityFeed, setUserActivityFeed] = useState<UserActivityFeed>({
    activities: undefined,
    isRefreshing: true,
    isLoadingMore: false,
    hasMore: false,
    filters: DEFAULT_FILTERS,
  });

  useEffect(() => {
    const sub = activityFeed().subscribe(setUserActivityFeed);

    return () => {
      sub.unsubscribe();
    };
  }, []);

  const clearFilters = useCallback(() => {
    setActivityFeedFilters(DEFAULT_FILTERS);
  }, []);

  return {
    setFilters: setActivityFeedFilters,
    clearFilters,
    loadMore: loadMoreActivities,
    ...userActivityFeed,
  };
};
