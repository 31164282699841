import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import jobs from '@mirage/service-typeahead-search/service/jobs';
import {
  cancelSyncSuggestedQueryScore,
  cancelSyncWeights,
  startSyncSuggestedQueryScore,
  startSyncWeights,
} from '@mirage/service-typeahead-search/service/scoring';
import search from '@mirage/service-typeahead-search/service/search';
import {
  CacheKey,
  TypeaheadCache,
} from '@mirage/service-typeahead-search/service/typeahead-cache';

import type { PersistentCacheShape } from '@mirage/service-typeahead-search/service/typeahead-cache';
import type { typeahead as typeahead_ } from '@mirage/service-typeahead-search/service/types';
import type { PreviousQuery } from '@mirage/shared/search/cache-result';
import type { Recommendation } from '@mirage/shared/search/recommendation';
import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

export type Service = {
  cacheRecentQueries(queries: string[]): Promise<void>;

  getRecentQueries(): Promise<PreviousQuery[]>;
  getRecommendations(): Promise<Recommendation[]>;

  clear(key: CacheKey): Promise<void>;
  tearDown(): Promise<void>;
  remove(previousQuery: PreviousQuery): Promise<void>;

  search(
    query: string,
    config: typeahead_.Config,
  ): Observable<typeahead_.ScoredResult[]>;

  startSyncRecommendations(): void;
  cancelSyncRecommendations(): void;

  startSyncWeights(): void;
  cancelSyncWeights(): void;

  startSyncSuggestedQueryScore(): void;
  cancelSyncSuggestedQueryScore(): void;

  registerHit(uuid: string): Promise<void>;
};

interface LogoutServiceContract {
  listenForLogout(service: string): Observable<boolean>;
}

export default function typeahead(
  adapter: KVStorage<PersistentCacheShape>,
  auxiliarySources: typeahead_.Source[] = [],
  logoutService: LogoutServiceContract,
) {
  const cache = new TypeaheadCache(adapter);

  async function cacheRecentQueries(queries: string[]): Promise<void> {
    await cache.cacheRecentQueries(queries);
  }

  async function getRecentQueries(): Promise<PreviousQuery[]> {
    return cache.all(CacheKey.RecentQueries) as PreviousQuery[];
  }

  async function getRecommendations(): Promise<Recommendation[]> {
    return cache.all(CacheKey.Recommendations) as Recommendation[];
  }

  async function clear(key: CacheKey) {
    cache.clear(key);
  }

  // used to reset the cache when the user logs out
  async function tearDown() {
    cache.tearDown();
  }

  async function remove(previousQuery: PreviousQuery): Promise<void> {
    return cache.remove(previousQuery);
  }

  async function registerHit(uuid: string): Promise<void> {
    return cache.registerHit(uuid, Date.now());
  }

  logoutService.listenForLogout(ServiceId.TYPEAHEAD_SEARCH).subscribe(() => {
    tearDown();
  });

  return services.provide<Service>(
    ServiceId.TYPEAHEAD_SEARCH,
    {
      cacheRecentQueries,
      getRecentQueries,
      getRecommendations,
      clear,
      tearDown,
      remove,
      search: (query, config) => search(query, cache, auxiliarySources, config),
      startSyncRecommendations: () => jobs.recommendations.start(cache),
      cancelSyncRecommendations: () => jobs.recommendations.cancel(),
      startSyncWeights,
      cancelSyncWeights,
      startSyncSuggestedQueryScore,
      cancelSyncSuggestedQueryScore,
      registerHit,
    },
    [ServiceId.DBX_API, ServiceId.EXPERIMENTATION],
  );
}
