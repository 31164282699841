import { BoltSignalClient } from './client';
import {
  ChannelId,
  compareRevisions,
  getChannelIdString,
  SignedChannelState,
} from './utils';

export class BoltDriver {
  private boltSignalClient: BoltSignalClient | null = null;
  private _started = false;
  private channelStates = new Map<string, SignedChannelState>();
  private handleUpdateSync: ((revision: string) => Promise<void>) | null = null;
  private handleRefreshSync: (() => Promise<void>) | null = null;

  public constructor(
    public readonly boltOrigin: string,
    public readonly log: (s: string) => void,
    private readonly isTest: boolean,
  ) {}

  public get started() {
    return this._started;
  }

  public getChannelStates(): Readonly<Map<string, SignedChannelState>> {
    return this.channelStates;
  }

  /**
   * Set the channel states to the given list of states.
   * @param states List of states to set. Any states not in the list will be removed.
   */
  public setChannelStates(states: SignedChannelState[]): void {
    // Remove states that are no longer in the list
    for (const channelId of this.channelStates.keys()) {
      if (!states.find((s) => getChannelIdString(s.channel_id) === channelId)) {
        this.channelStates.delete(channelId);
      }
    }

    // Add new states
    for (const state of states) {
      this.setChannelState(state);
    }
  }

  public getChannelState(
    channelId: ChannelId,
  ): Readonly<SignedChannelState> | undefined {
    return this.channelStates.get(getChannelIdString(channelId));
  }

  public setChannelState(state: SignedChannelState): void {
    this.channelStates.set(getChannelIdString(state.channel_id), state);
  }

  protected createNewBoltClient(): void {
    const channelsArray = Array.from(this.channelStates.values());

    // Don't start client if there are no channels to listen to
    if (channelsArray.length) {
      this.log(
        'Starting bolt client, listening to channels: ' +
          JSON.stringify(
            channelsArray.map((c) => ({
              [c.channel_id.unique_id]: c.revision,
            })),
          ),
      );

      this.boltSignalClient = new BoltSignalClient({
        log: this.log,
        boltOrigin: this.boltOrigin,
        signedChannelStates: channelsArray,
        updateCallback: this.updateCallback.bind(this),
        refreshCallback: this.refreshCallback.bind(this),
        isTest: this.isTest,
      });

      this.boltSignalClient.start();
      this._started = true;
    }
  }

  // Caller does not need to await on this method to initialize.
  public async startListening(
    handleUpdateSync: (revision: string) => Promise<void>,
    handleRefreshSync: () => Promise<void>,
  ): Promise<void> {
    if (this._started) return;

    this.handleUpdateSync = handleUpdateSync;
    this.handleRefreshSync = handleRefreshSync;
    this.createNewBoltClient();
  }

  public stopListening(): void {
    this._started = false;
    this.channelStates = new Map<string, SignedChannelState>();
    this.boltSignalClient?.stop();
    this.boltSignalClient = null;
  }

  private async updateCallback(updates: SignedChannelState[]): Promise<void> {
    this.log(
      'Receiving bolt update ' +
        JSON.stringify(
          updates.map((u) => ({ [u.channel_id.unique_id]: u.revision })),
        ),
    );

    // Get max revision value from updates
    const maxRevision = updates.reduce(
      (max, u) => (compareRevisions(u.revision, max) > 0 ? u.revision : max),
      '0',
    );

    // Store existing channel information, specifically revision information
    const oldChannels = Array.from(this.channelStates.values());

    await this.handleUpdateSync?.(maxRevision);

    // Get new channel information
    const newChannels = Array.from(this.channelStates.values());

    // Merge the old channels with the new ones, keeping the old revisions.
    const mergedChannels = newChannels.map((newChannel) => {
      const oldChannel = oldChannels.find(
        (c) => c.channel_id.unique_id === newChannel.channel_id.unique_id,
      );
      return oldChannel ? oldChannel : newChannel;
    });

    // Update the channel states with the new revisions.
    const updatedChannels = mergedChannels.map((channel) => {
      const update = updates.find(
        (u) => u.channel_id.unique_id === channel.channel_id.unique_id,
      );
      return update ? update : channel;
    });

    // Check if we discarded any old ones
    const discardedChannels = oldChannels.filter(
      (c) => !mergedChannels.includes(c),
    );

    // If so, we need to run clearAndUpdate to reset the channel states
    if (discardedChannels.length > 0) {
      this.boltSignalClient?.clearAndUpdateStates(updatedChannels);
    } else {
      this.boltSignalClient?.updateStates(updatedChannels);
    }
  }

  private async refreshCallback(_invalidChannels: ChannelId[]): Promise<void> {
    this.log(
      'Receiving bolt refresh, invalid channels: ' +
        JSON.stringify(_invalidChannels),
    );

    await this.handleRefreshSync?.();

    // If bolt client has stopped for some reason, unsubscribe, then restart
    if (!this._started || !this.boltSignalClient) {
      this.stopListening();
      this.createNewBoltClient();
    } else {
      // Otherwise, just update the states.
      this.boltSignalClient.clearAndUpdateStates(
        Array.from(this.channelStates.values()),
      );
    }
  }
}
