import ConnectorIssuesNotificationBanner from '@mirage/mosaics/GlobalBanners/ConnectorIssuesNotificationBanner';
import styles from './GlobalBanners.module.css';

export default function GlobalBanners() {
  return (
    <div className={styles.banners}>
      <ConnectorIssuesNotificationBanner bannerClass={styles.banner} />
    </div>
  );
}
