import { TourTooltip } from '@mirage/shared/tooltips/TourTooltip';
import { useStackDetailsTour } from '../StackDetailsTour/useStackDetailsTour';

interface StackDetailsTourTooltipProps {
  stepKey: string;
  triggerRef: React.RefObject<HTMLElement | Element>;
}

export const StackDetailsTourTooltip: React.FC<
  StackDetailsTourTooltipProps
> = ({ stepKey, triggerRef }) => {
  const {
    getCurrentStep,
    logTourExposure,
    totalSteps,
    nextStep,
    previousStep,
    dismissTour,
    isTourStepEnabled,
  } = useStackDetailsTour();

  const handleClose = () => dismissTour('close');
  const handleSkip = () => dismissTour('skip');

  if (!stepKey || !triggerRef || !isTourStepEnabled(stepKey)) return null;

  logTourExposure();

  return (
    <TourTooltip
      currentStep={getCurrentStep()}
      totalSteps={totalSteps}
      triggerRef={triggerRef}
      onClose={handleClose}
      onSkip={handleSkip}
      onNext={nextStep}
      onPrevious={previousStep}
    />
  );
};
