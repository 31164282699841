import { Space } from '@dropbox/dig-foundations';
import { useAugustRevisionEnabled } from '@mirage/august-revision-hook';
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { SmoothShowClassName } from '../smooth-show/SmoothShow';
import styles from './TwoColumnGrid.module.css';
import { convertSpaceToCSSVariable } from './utils';

type TwoColumnGridVersion =
  /* @default Use whichever version is globally used */
  | 'inherit'

  /* The standard layout if no flags are applied */
  | 'standard'

  /** Temporarily define the different layout versions possible within this Grid.
   * Post rollout, these should be removed in favor of Standard.
   */
  | 'august';

type TwoColumndGridProps = {
  mainChildren: React.ReactNode;
  sidebarChildren?: React.ReactNode;
  headerChildren?: React.ReactNode;
  containerRowGap?: Space;
  mainRowGap?: Space;
  sidebarRowGap?: Space;
  version?: TwoColumnGridVersion;
};

const ONE_COLUMN_MAX_WIDTH = 656;
const AUGUST_ONE_COLUMN_MAX_WIDTH = 752;

export const TwoColumnGrid: React.FC<TwoColumndGridProps> = ({
  mainChildren,
  sidebarChildren,
  headerChildren,
  containerRowGap,
  mainRowGap,
  sidebarRowGap,
  version = 'inherit',
}) => {
  const alwaysOneColumn = sidebarChildren === undefined;
  const [useOneColumnLayout, setUseOneColumnLayout] = useState(alwaysOneColumn);
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const masterAugustRevision = useAugustRevisionEnabled();
  const augustRevision =
    version === 'inherit' ? masterAugustRevision : version === 'august';
  const maxColumnWidth = augustRevision
    ? AUGUST_ONE_COLUMN_MAX_WIDTH
    : ONE_COLUMN_MAX_WIDTH;

  useEffect(() => {
    if (!alwaysOneColumn) {
      const gridComponent = gridContainerRef.current;

      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { width } = entry.contentRect;
          setUseOneColumnLayout(width < maxColumnWidth);
        }
      });

      if (gridComponent) {
        resizeObserver.observe(gridComponent);
      }

      return () => {
        if (gridComponent) {
          resizeObserver.unobserve(gridComponent);
        }
      };
    }
  }, [alwaysOneColumn, maxColumnWidth]);

  return (
    <div
      className={classnames(styles.gridContainer, {
        [styles.augustLayout]: augustRevision,
      })}
      style={{
        rowGap: convertSpaceToCSSVariable(containerRowGap),
      }}
      ref={gridContainerRef}
    >
      {headerChildren && (
        <div className={classnames(styles.headerRow)}>{headerChildren}</div>
      )}
      <div
        className={classnames(styles.columnGroup, {
          [styles.singleColumnGroup]: useOneColumnLayout,
        })}
      >
        {sidebarChildren && (
          <div
            className={classnames(
              styles.column,
              styles.columnGap,
              styles.sidebarColumn,
              SmoothShowClassName,
              {
                [styles.singleColumn]: useOneColumnLayout,
              },
            )}
            style={{
              rowGap: convertSpaceToCSSVariable(sidebarRowGap),
            }}
          >
            {sidebarChildren}
          </div>
        )}
        <div
          className={classnames(
            styles.column,
            styles.columnGap,
            styles.mainColumn,
            SmoothShowClassName,
            {
              [styles.singleColumn]: useOneColumnLayout,
            },
          )}
          style={{
            rowGap: convertSpaceToCSSVariable(mainRowGap),
          }}
        >
          {mainChildren}
        </div>
      </div>
    </div>
  );
};
