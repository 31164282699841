import { tagged } from '@mirage/service-logging';

import type { ScoredResult } from '@mirage/service-search/service';

const logger = tagged('slotted-search');

/**
 * This is known "hacks on hacks" directed for the August 2024 release. Sorry in advance lol.
 *
 * 1. Mapping with Original Rank: The results array is mapped to include an originalRank to track their initial positions.
 * 2. Filtering: The results are divided into two arrays: nonZeroResults (elements with a non-zero relevanceScore) and zeroResults (elements with a relevanceScore of 0).
 * 3. Sorting: The nonZeroResults array is sorted by relevanceScore in descending order.
 * 4. Merging: The sorted nonZeroResults and zeroResults arrays are merged back into the original order, maintaining the original positions for elements with a relevanceScore of 0.
 * 5. Cleaning Up: The originalRank field is removed before logging the final result.
 */

const DEBUG = false;

type ScoredResultWithOriginalRank = {
  result: ScoredResult;
  originalRank: number;
};

type ScoredResultWithNewRank = {
  result: ScoredResult;
  originalRank: number;
  newRank: number;
};

export function slottedSearchRanking(results: ScoredResult[]): ScoredResult[] {
  if (DEBUG) logger.debug('before', results);

  const withRanks_NonServer: ScoredResultWithOriginalRank[] = [];
  const withRanks_Server: ScoredResultWithOriginalRank[] = [];
  const availableSlots: Set<number> = new Set();

  for (const [index, result] of results.entries()) {
    availableSlots.add(index);
    if (result.searchResultSource === 'server') {
      withRanks_Server.push({
        result,
        originalRank: index,
      });
    } else {
      withRanks_NonServer.push({
        result,
        originalRank: index,
      });
    }
  }

  const withNewRanks: ScoredResultWithNewRank[] = new Array(results.length);

  // Place the non-server results in their original position

  for (const { result, originalRank } of withRanks_NonServer) {
    withNewRanks[originalRank] = {
      result,
      originalRank,
      newRank: originalRank,
    };
    availableSlots.delete(originalRank);
  }

  // Slot the server results into the remaining position, but in guaranteed
  // descending order per the server score (`relevanceScore`)

  withRanks_Server.sort(
    (a, b) => b.result.relevanceScore - a.result.relevanceScore,
  );

  for (const { result, originalRank } of withRanks_Server) {
    const nextAvailableIndex = availableSlots.values().next().value;
    withNewRanks[nextAvailableIndex] = {
      result,
      originalRank,
      newRank: nextAvailableIndex,
    };
    availableSlots.delete(nextAvailableIndex);
  }

  if (DEBUG) logger.debug(`after`, withNewRanks);

  const newResults = withNewRanks.map(({ result }) => ({
    ...result,
    serpFinalRanking: 'slotted_search',
  })) as ScoredResult[];

  return newResults;
}
