import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import i18n from '@mirage/translations';
import styles from './NetworkErrorModal.module.css';

export const NetworkErrorModal: React.FC<{
  retry: () => void;
}> = ({ retry }) => {
  return (
    <Modal open={true}>
      <Modal.Header hasBottomSpacing="title-small">
        <Modal.Title>{i18n.t('network_error_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text>{i18n.t('network_error_message')}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button className={styles.button} variant="primary" onClick={retry}>
          {i18n.t('logging_in_try_again')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
