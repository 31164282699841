import { Card } from '@dropbox/dash-component-library';
import { Text } from '@dropbox/dig-components/typography';
import { Box, Stack } from '@dropbox/dig-foundations';
import { DashCard } from '@mirage/dash-component-library/components/DashCard';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import classnames from 'classnames';
import { CSSProperties, ReactNode } from 'react';
import styles from './TileCard.module.css';

interface TileCardProps {
  icon: ReactNode;

  /**
   * Title can be a string or a ReactNode. If a string, it will be
   * truncated with an ellipsis if it exceeds the available space.
   * ReactNode should only be used for Skeleton loader states.
   */
  title: string | ReactNode;
  titleLinesBeforeTruncate?: number;
  metadata: ReactNode;

  /**
   * An optional icon/button/action to be rendered in the top right
   * corner of the card.  It will sit above the card contents and can
   * be interactive if `withInteractiveAccessory` is true.
   */
  withAccessory?: ReactNode;
  withInteractiveAccessory?: boolean;
  interactiveAccessoryClassName?: string;

  /**
   * Defines the behavior when the card is clicked. Expected to be
   * navigation along with analytics event tracking
   */
  onClick?: () => void;
  /**
   * The path to show in the browser when hovering over the card
   * Will not be used for actual navigation
   */
  clickPath?: string;

  /**
   * If true, the card will be hidden from the DOM using visibility: hidden
   * and opacity: 0. This allows it to still occupy space for animations
   * while removing it from the tab order and screen readers.
   */
  hidden?: boolean;

  className?: string;
  style?: CSSProperties;

  children?: never;
}

export const TileCard = ({
  icon,
  title,
  titleLinesBeforeTruncate = 1,
  metadata,
  withAccessory,
  withInteractiveAccessory = false,
  interactiveAccessoryClassName,
  onClick,
  clickPath,
  hidden = false,
  className,
  style,
}: TileCardProps) => {
  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent,
  ) => {
    if (onClick) {
      // Avoid the default behavior of the anchor tag
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }
  };

  const titleStyle = {
    // In order to set a minimum height, define the number of lines before
    // truncation to enforce a consistent height for all cards,
    // and allow metadata to bottom align in the card
    '--dash-tile-card__title__linecount': titleLinesBeforeTruncate,
  } as CSSProperties;

  return (
    <DashCard
      onClick={onClick}
      style={style}
      className={classnames(styles.card, className, {
        [styles.hidden]: hidden,
      })}
      breakout="xsmall"
    >
      <Stack gap="Micro Medium">
        <div>{icon}</div>
        <Stack gap="Micro XSmall">
          <Card.Link
            as="a"
            href={clickPath}
            style={{
              textDecoration: 'none',
            }}
            onClick={handleClick}
            onKeyDown={onKeyDownCommitFn(handleClick)}
          >
            {typeof title === 'string' ? (
              <Text
                size="medium"
                isBold
                tagName="div"
                style={titleStyle}
                className={styles.title}
              >
                <DashTruncateWithTooltip
                  lines={titleLinesBeforeTruncate}
                  tooltipProps={{
                    title,
                  }}
                >
                  {title}
                </DashTruncateWithTooltip>
              </Text>
            ) : (
              <Box style={titleStyle} className={styles.title}>
                {title}
              </Box>
            )}
          </Card.Link>
          {metadata}
        </Stack>
      </Stack>
      {withAccessory && (
        <div
          className={classnames(
            styles.accessoryButton,
            {
              [styles.interactiveAccessory]: withInteractiveAccessory,
            },
            interactiveAccessoryClassName,
          )}
        >
          {withAccessory}
        </div>
      )}
    </DashCard>
  );
};
