import { stacks } from '@dropbox/api-v2-client';
import { Avatar } from '@dropbox/dig-components/avatar';
import { Chip } from '@dropbox/dig-components/chip';
import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { ArchiveLine, PinFill } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import {
  PAPEvent,
  PAPEventDefaultProperties,
} from '@mirage/analytics/events/base/event';
import { PAP_Initiate_UpdateStackEmoji } from '@mirage/analytics/events/types/initiate_update_stack_emoji';
import { PAP_Rename_DashStack } from '@mirage/analytics/events/types/rename_dash_stack';
import { PAP_Update_StacksDescription } from '@mirage/analytics/events/types/update_stacks_description';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook/hook';
import { DashStackIcon } from '@mirage/dash-component-library/components/DashStackIcon';
import {
  getDefaultColorIndex,
  getTheme,
  useCurrentBackgroundTheme,
} from '@mirage/dash-component-library/themes/Stacks';
import { stackDerivePAPProps, upsertStack } from '@mirage/service-stacks';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { getInitialsFromName } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import { Ref, useCallback, useMemo, useState } from 'react';
import { PAP_Update_StackEmoji } from '../../analytics/events/types/update_stack_emoji';
import {
  EMOJI_CLEARED_SENTINEL_VALUE,
  stackIsArchived,
} from '../Helpers/Utils';
import {
  accessLevelIconSrcForMemberCount,
  accessLevelTitleForAccessLevel,
  descriptionForStackAccessLevel,
} from '../ShareModal/Utils';
import {
  EditStackDetails,
  StackDescriptionEditor,
  StackNameEditor,
} from './EditStackDetails';
import { EmojiPicker } from './EmojiPicker';
import {
  shadowMutationRequestIdAtom,
  shadowSessionIdAtom,
  shadowStackAtom,
  shadowStackHasWritePermissionsAtom,
} from './fullPageShadowAtoms';
import styles from './Header.module.css';
import { useIsArchiveStackEnabled } from './hooks';
import { StackDerivedPAPPropsWithFeature, UserInfo } from './types';
import { asStackUpsertId, newStackPapEvent } from './utils';

interface HeaderProps {
  withImmediateAction: React.ReactNode;
  triggerEditStackRef: Ref<HTMLInputElement> | undefined;
  defaultUserInfo: UserInfo;
  canEditOverride?: boolean;
  publicPreview?: boolean;
  withRightAction?: React.ReactNode;
  additionalContainerStyles?: string; // Class names
}

const Header: React.FC<HeaderProps> = ({
  withImmediateAction,
  triggerEditStackRef,
  defaultUserInfo,
  canEditOverride,
  publicPreview,
  withRightAction,
  additionalContainerStyles,
}) => {
  const stack = useAtomValue(shadowStackAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const mutationId = useAtomValue(shadowMutationRequestIdAtom);
  const sessionId = useAtomValue(shadowSessionIdAtom);
  const isAugustRev = useStackPageAugustRevisionEnabled();

  const isNewStack = stack === null;

  const canEditStack = useAtomValue(shadowStackHasWritePermissionsAtom);
  const canEdit = canEditOverride ?? canEditStack;

  // Cache this so we're not redefining for every event
  const stackDerivedPAPPropsWithFeature = useMemo(
    () =>
      isNewStack || !stack.namespace_id
        ? ({ featureLine: 'stacks' } as const)
        : ({
            ...stackDerivePAPProps(stack),
            featureLine: 'stacks',
          } as const),
    [stack, isNewStack],
  );

  const updateStackProperty = useCallback(
    async (
      updates: stacks.StackDataUpdate[],
      eventCreators: Array<(props: PAPEventDefaultProperties) => PAPEvent>,
    ) => {
      const { stack: createdStack } = await upsertStack(
        asStackUpsertId(stack?.namespace_id, mutationId),
        updates,
      );

      if (stack) {
        for (const eventCreator of eventCreators) {
          reportPapEvent(
            eventCreator({
              ...stackDerivePAPProps(stack),
              featureLine: 'stacks',
            }),
          );
        }
      } else {
        reportPapEvent(newStackPapEvent(createdStack?.namespace_id, sessionId));
      }
    },
    [stack, reportPapEvent, mutationId, sessionId],
  );

  const updateStackEmoji = useCallback(
    (newEmoji: string) =>
      updateStackProperty(
        [
          {
            field: { emoji_update: newEmoji, '.tag': 'emoji_update' },
          },
        ],
        [PAP_Update_StackEmoji],
      ),
    [updateStackProperty],
  );

  const updateStackNameAndDescription = useCallback(
    (newName: string, newDescription: string) => {
      const updates: stacks.StackDataUpdate[] = [
        {
          field: { name_update: newName, '.tag': 'name_update' },
        },
        {
          field: {
            description_update: newDescription,
            '.tag': 'description_update',
          },
        },
      ];

      updateStackProperty(updates, [
        PAP_Rename_DashStack,
        PAP_Update_StacksDescription,
      ]);
    },
    [updateStackProperty],
  );

  const updateEmoji =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (emoji: any): Promise<void> => {
      updateStackEmoji(emoji.native);
      setEmojiPickerVisible(false);
    };

  if (isAugustRev) {
    return (
      <HeaderAugustRev
        canEdit={canEdit}
        emojiPickerVisible={emojiPickerVisible}
        setEmojiPickerVisible={setEmojiPickerVisible}
        updateEmoji={updateEmoji}
        stackDerivedPAPPropsWithFeature={
          stackDerivedPAPPropsWithFeature as StackDerivedPAPPropsWithFeature
        }
        withImmediateAction={withImmediateAction}
        triggerEditStackRef={triggerEditStackRef}
        defaultUserInfo={defaultUserInfo}
        updateStackNameAndDescription={updateStackNameAndDescription}
        publicPreview={publicPreview}
        buttonActions={withRightAction}
        additionalContainerStyles={additionalContainerStyles}
      />
    );
  }

  return (
    <HeaderV1
      canEdit={canEdit}
      emojiPickerVisible={emojiPickerVisible}
      setEmojiPickerVisible={setEmojiPickerVisible}
      updateEmoji={updateEmoji}
      stackDerivedPAPPropsWithFeature={
        stackDerivedPAPPropsWithFeature as StackDerivedPAPPropsWithFeature
      }
      withImmediateAction={withImmediateAction}
      triggerEditStackRef={triggerEditStackRef}
      defaultUserInfo={defaultUserInfo}
      updateStackNameAndDescription={updateStackNameAndDescription}
    />
  );
};

export default Header;

type UpdateStackNameAndDescription = (
  newName: string,
  newDescription: string,
) => void;

interface HeaderCoreProps {
  canEdit: boolean;
  emojiPickerVisible: boolean;
  setEmojiPickerVisible: (visible: boolean) => void;
  updateEmoji: (emoji: string) => void;
  stackDerivedPAPPropsWithFeature: StackDerivedPAPPropsWithFeature;
  withImmediateAction: React.ReactNode;
  triggerEditStackRef: Ref<HTMLInputElement> | undefined;
  defaultUserInfo: UserInfo;
  updateStackNameAndDescription: UpdateStackNameAndDescription;
  publicPreview?: boolean;
  additionalContainerStyles?: string;
}

const HeaderV1: React.FC<HeaderCoreProps> = ({
  canEdit,
  emojiPickerVisible,
  setEmojiPickerVisible,
  updateEmoji,
  stackDerivedPAPPropsWithFeature,
  withImmediateAction,
  triggerEditStackRef,
  defaultUserInfo,
  updateStackNameAndDescription,
}) => {
  const stack = useAtomValue(shadowStackAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isNewStack = stack === null;

  const newStackColorIndex = getDefaultColorIndex();
  const newStackEmoji = EMOJI_CLEARED_SENTINEL_VALUE;
  const newStackDescription = '';

  const stackTitle = stack?.stack_data?.name ?? '';

  return (
    <ThemeProvider
      overrides={
        getTheme(
          isNewStack ? newStackColorIndex : stack.stack_data?.color_index,
        ).primary
      }
    >
      {({ getThemeProps }) => (
        <div
          {...getThemeProps({
            className: styles.headerContainer,
          })}
        >
          <div className={styles.headerMain}>
            <div className={styles.headerActions}>
              <DashStackIcon
                colorIndex={
                  isNewStack
                    ? newStackColorIndex
                    : stack.stack_data?.color_index || getDefaultColorIndex()
                }
                emoji={
                  isNewStack
                    ? newStackEmoji
                    : stack.stack_data?.emoji || EMOJI_CLEARED_SENTINEL_VALUE
                }
                onClick={
                  canEdit
                    ? () => {
                        setEmojiPickerVisible(!emojiPickerVisible);
                        reportPapEvent(
                          PAP_Initiate_UpdateStackEmoji(
                            stackDerivedPAPPropsWithFeature,
                          ),
                        );
                      }
                    : undefined
                }
                tooltipProps={{
                  title: i18n.t('change_stack_icon'),
                }}
              />
              {emojiPickerVisible && (
                <EmojiPicker
                  setEmojiPickerVisible={setEmojiPickerVisible}
                  onEmojiSelect={updateEmoji}
                  papProps={stackDerivedPAPPropsWithFeature}
                />
              )}
              {withImmediateAction}
            </div>
            <EditStackDetails
              triggerEditStackRef={triggerEditStackRef}
              isNewStack={isNewStack}
              stackDerivedPAPPropsWithFeature={stackDerivedPAPPropsWithFeature}
              stackName={stackTitle}
              stackDescription={
                isNewStack
                  ? newStackDescription
                  : stack.stack_data?.description || ''
              }
              withStackInfo={
                stack && (
                  <StackInfoAndMembers
                    stack={stack}
                    defaultUserInfo={defaultUserInfo}
                  />
                )
              }
              updateStackNameAndDescription={updateStackNameAndDescription}
              hasWritePermissionsOverride={canEdit}
            />
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};

interface HeaderAugustRevProps {
  buttonActions?: React.ReactNode;
}

const HeaderAugustRev: React.FC<HeaderCoreProps & HeaderAugustRevProps> = ({
  canEdit,
  emojiPickerVisible,
  setEmojiPickerVisible,
  updateEmoji,
  stackDerivedPAPPropsWithFeature,
  withImmediateAction: withAction,
  updateStackNameAndDescription,
  buttonActions,
  defaultUserInfo,
  additionalContainerStyles,
}) => {
  const stack = useAtomValue(shadowStackAtom);
  const backgroundTheme = useCurrentBackgroundTheme(stack);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isMobile = useIsMobileSize();
  const isNewStack = stack === null;

  const newStackColorIndex = getDefaultColorIndex();
  const newStackEmoji = EMOJI_CLEARED_SENTINEL_VALUE;

  const stackTitle = stack?.stack_data?.name ?? '';
  const stackDescription = stack?.stack_data?.description ?? '';

  return (
    <ThemeProvider overrides={backgroundTheme}>
      <ThemeContainer>
        <div
          className={classnames(
            styles.headerContainerAug,
            additionalContainerStyles,
          )}
        >
          <div className={styles.headerMainRow}>
            <div className={styles.headerIconContainer}>
              <DashStackIcon
                className={styles.stackIcon}
                colorIndex={
                  isNewStack
                    ? newStackColorIndex
                    : stack.stack_data?.color_index || getDefaultColorIndex()
                }
                emoji={
                  isNewStack
                    ? newStackEmoji
                    : stack.stack_data?.emoji || EMOJI_CLEARED_SENTINEL_VALUE
                }
                size={'xlarge'}
                onClick={
                  canEdit
                    ? () => {
                        setEmojiPickerVisible(!emojiPickerVisible);
                        reportPapEvent(
                          PAP_Initiate_UpdateStackEmoji(
                            stackDerivedPAPPropsWithFeature,
                          ),
                        );
                      }
                    : undefined
                }
              />
              {emojiPickerVisible && (
                <EmojiPicker
                  setEmojiPickerVisible={setEmojiPickerVisible}
                  onEmojiSelect={updateEmoji}
                  papProps={stackDerivedPAPPropsWithFeature}
                />
              )}
            </div>
            <StackTitleArea
              stackTitle={stackTitle}
              isNewStack={isNewStack}
              updateStackNameAndDescription={updateStackNameAndDescription}
              stackDerivedPAPPropsWithFeature={stackDerivedPAPPropsWithFeature}
              withImmediateAction={withAction}
              withRightAction={buttonActions}
              canEdit={canEdit}
              withInfo={
                stack && (
                  <StackInfoAndMembers
                    stack={stack}
                    defaultUserInfo={defaultUserInfo}
                    hidePinned
                  />
                )
              }
            />
          </div>
          <StackDescriptionRow
            stackDescription={stackDescription}
            isNewStack={isNewStack}
            updateStackNameAndDescription={updateStackNameAndDescription}
            stackDerivedPAPPropsWithFeature={stackDerivedPAPPropsWithFeature}
            canEdit={canEdit}
          />
          {isMobile && <StackHeaderActions action={buttonActions} />}
        </div>
      </ThemeContainer>
    </ThemeProvider>
  );
};

interface StackTitleAreaProps {
  stackTitle: string;
  isNewStack: boolean;
  updateStackNameAndDescription: UpdateStackNameAndDescription;
  stackDerivedPAPPropsWithFeature: StackDerivedPAPPropsWithFeature;
  withImmediateAction?: React.ReactNode;
  withRightAction?: React.ReactNode;
  withInfo?: React.ReactNode;
  canEdit?: boolean;
}
const StackTitleArea: React.FC<StackTitleAreaProps> = ({
  stackTitle,
  isNewStack,
  updateStackNameAndDescription,
  stackDerivedPAPPropsWithFeature,
  withImmediateAction,
  withRightAction,
  withInfo,
  canEdit,
}) => {
  const [isEditingStackName, setIsEditingStackName] = useState(false);
  const isMobile = useIsMobileSize();

  return (
    <div className={styles.stackTitleArea}>
      <div className={styles.stackTitleRow}>
        <div className={styles.stackTitleContainer}>
          <StackNameEditor
            stackName={stackTitle}
            isNewStack={isNewStack}
            updateStackNameAndDescription={updateStackNameAndDescription}
            stackDerivedPAPPropsWithFeature={stackDerivedPAPPropsWithFeature}
            hasWritePermissionsOverride={canEdit}
            onEditStatusUpdate={setIsEditingStackName}
          />
          {!isEditingStackName && (
            <div className={styles.immediateActions}>{withImmediateAction}</div>
          )}
        </div>
        {!isMobile && <StackHeaderActions action={withRightAction} />}
      </div>
      {withInfo}
    </div>
  );
};

interface StackHeaderActionsProps {
  action: React.ReactNode;
}

const StackHeaderActions: React.FC<StackHeaderActionsProps> = ({ action }) => {
  return <div className={styles.headerButtons}>{action}</div>;
};

interface StackDescriptionRowProps {
  stackDescription: string;
  isNewStack: boolean;
  updateStackNameAndDescription: UpdateStackNameAndDescription;
  stackDerivedPAPPropsWithFeature: StackDerivedPAPPropsWithFeature;
  canEdit?: boolean;
}

const StackDescriptionRow: React.FC<StackDescriptionRowProps> = ({
  stackDescription,
  isNewStack,
  updateStackNameAndDescription,
  stackDerivedPAPPropsWithFeature,
  canEdit,
}) => {
  return (
    // Hide description on view only stacks with no description
    ((canEdit || stackDescription) && (
      <StackDescriptionEditor
        stackDescription={stackDescription}
        isNewStack={isNewStack}
        updateStackNameAndDescription={updateStackNameAndDescription}
        stackDerivedPAPPropsWithFeature={stackDerivedPAPPropsWithFeature}
        hasWritePermissionsOverride={canEdit}
      />
    )) ||
    null
  );
};

interface StackInfoAndMemberProps {
  stack: stacks.Stack;
  defaultUserInfo: UserInfo;
  hidePinned?: boolean;
}

const StackInfoAndMembers: React.FC<StackInfoAndMemberProps> = ({
  stack,
  defaultUserInfo,
  hidePinned = false,
}) => {
  const isAugRev = useStackPageAugustRevisionEnabled();
  const stackOwners =
    stack?.sharing_data?.members?.filter(
      (member) => member.permission?.['.tag'] === 'owner',
    ) ?? [];
  const owner = stackOwners.length > 0 ? stackOwners[0] : undefined;
  const ownerInitials = owner
    ? getInitialsFromName(
        (owner.subject?.['.tag'] === 'user'
          ? owner.subject.display_name
          : undefined) ?? '',
      )
    : undefined;
  const accessLevelIcon = accessLevelIconSrcForMemberCount(
    stack?.sharing_data?.members?.length ?? 0,
  );

  const archiveStackIsEnabled = useIsArchiveStackEnabled();
  const isWelcomeStack =
    stack.stack_data?.creation_type?.['.tag'] === 'welcome_stack';

  return (
    <div className={styles.stackInfoAndMembers}>
      <Chip variant="transparent" size="small" className={styles.shortChip}>
        {archiveStackIsEnabled && stackIsArchived(stack) && (
          <>
            <Chip.IconAccessory
              className={isAugRev ? styles.subtleText : undefined}
            >
              <UIIcon src={ArchiveLine} />
            </Chip.IconAccessory>
            <Chip.Content className={isAugRev ? styles.subtleText : undefined}>
              {i18n.t('archived')}
            </Chip.Content>
            <Chip.Content>·</Chip.Content>
          </>
        )}
        {!hidePinned && stack?.user_data?.is_pinned && (
          <>
            <Chip.IconAccessory
              className={isAugRev ? styles.subtleText : undefined}
            >
              <DigTooltip title={i18n.t('stack_pinned')}>
                <div style={{ lineHeight: 0 }}>
                  <UIIcon src={PinFill} />
                </div>
              </DigTooltip>
            </Chip.IconAccessory>
            <Chip.Content>·</Chip.Content>
          </>
        )}
        {accessLevelIcon && (
          <Chip.IconAccessory
            className={isAugRev ? styles.subtleText : undefined}
          >
            <DigTooltip
              title={descriptionForStackAccessLevel(
                stack?.sharing_data?.shared_link?.access_level,
                stack?.sharing_data?.shared_link?.team_name,
              )}
            >
              <div style={{ lineHeight: 0 }}>
                <UIIcon src={accessLevelIcon} size="large" />
              </div>
            </DigTooltip>
          </Chip.IconAccessory>
        )}
        <Chip.Content className={isAugRev ? styles.subtleText : undefined}>
          {accessLevelTitleForAccessLevel(
            stack?.sharing_data?.shared_link?.access_level,
            stack?.sharing_data?.members?.length ?? 0,
          )}
        </Chip.Content>
        <Chip.Content>·</Chip.Content>
        <Chip.Content className={isAugRev ? styles.subtleText : undefined}>
          {isWelcomeStack
            ? i18n.t('created_by_dropbox_dash')
            : i18n.t('created_by')}
        </Chip.Content>
      </Chip>
      {!isWelcomeStack && (
        <Chip size="small" variant={isAugRev ? 'transparent' : undefined}>
          <Chip.AvatarAccessory>
            <Avatar
              src={
                owner?.subject?.['.tag'] === 'user'
                  ? owner.subject.profile_photo_url
                  : defaultUserInfo.profilePhotoUrl
              }
            >
              {ownerInitials}
            </Avatar>
          </Chip.AvatarAccessory>
          <Chip.Content className={isAugRev ? styles.subtleText : undefined}>
            {owner?.subject?.['.tag'] === 'user'
              ? owner.subject.display_name
              : defaultUserInfo.displayName}
          </Chip.Content>
        </Chip>
      )}
    </div>
  );
};
