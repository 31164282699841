import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import { scorePerson } from '../scoring/score-person';
import { extractValidPersonFromRecommendation } from '../util';

import type { search_suggestion, sharing } from '@dropbox/api-v2-client';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';
import type { Recommendation } from '@mirage/shared/search/recommendation';
import type { PersonObject } from '@mirage/shared/search/search-filters';
import type { ConsolaInstance } from 'consola';
import type { Observable } from 'rxjs';

const SUGGESTIONS_CACHE_TTL = ONE_MINUTE_IN_MILLIS * 5;
const MIN_SCORE_THRESHOLD = 0.15; // Minimum score to help filter out garbage local cache results

export interface Service {
  getPeopleSuggestions(): Promise<PersonObject[]>;
  performPeopleSearch(query: string): Promise<PersonObject[]>;
  tearDown(): Promise<void>;
}

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

interface TypeaheadSearchServiceContract {
  getRecommendations(): Promise<Recommendation[]>;
}

interface LogoutServiceContract {
  listenForLogout(service: ServiceId): Observable<boolean>;
}

interface LoggingServiceContract {
  tagged: (tag: string) => ConsolaInstance;
}

export default function peopleService(
  { callApiV2 }: DbxApiServiceContract,
  { getRecommendations }: TypeaheadSearchServiceContract,
  { listenForLogout }: LogoutServiceContract,
  { tagged }: LoggingServiceContract,
) {
  const logger = tagged('people-service');

  let suggestionsCache: {
    suggestions: PersonObject[];
    lastFetchMs: number;
  } = {
    suggestions: [],
    lastFetchMs: 0,
  };
  let fetchPromise: Promise<PersonObject[]> | null = null;

  const isCacheValid = () => {
    return Date.now() - suggestionsCache.lastFetchMs < SUGGESTIONS_CACHE_TTL;
  };

  const getPeopleSuggestions = async () => {
    if (isCacheValid()) {
      logger.debug('[suggestions] returning results from cache');
      return suggestionsCache.suggestions;
    }

    if (!fetchPromise) {
      fetchPromise = (async () => {
        try {
          const res = await callApiV2('searchSuggestionGetPeopleSuggestion', {
            num_items: 5,
          });

          const response = (res.people_suggestions ?? []).map(
            (user: search_suggestion.SuggestedUser) => ({
              displayName: user.display_name ?? '',
              email: user.email ?? '',
              profilePhotoUrl: user.photo_url,
              sortKey: String(user.score ?? 0),
            }),
          );
          suggestionsCache = {
            suggestions: response,
            lastFetchMs: Date.now(),
          };

          logger.debug('[suggestions] returning results from API');
          return response;
        } catch (err) {
          // silently handle errors. Suggestions are a bonus, not required.
          logger.debug(
            '[suggestions] caught error calling searchSuggestionGetPeopleSuggestion',
            err,
          );
        }
        return [];
      })();
    }

    try {
      return await fetchPromise;
    } finally {
      fetchPromise = null;
    }
  };

  const fetchPeopleFromServer = async (
    query: string,
  ): Promise<PersonObject[]> => {
    const res = await callApiV2('sharingTargetsSearch', {
      limit: 5,
      query,
    });

    return res.entries
      .reduce(
        (
          filtered: PersonObject[],
          entry:
            | sharing.GroupTargetReference
            | sharing.TargetReference
            | sharing.UserTargetReference,
        ) => {
          if (entry['.tag'] === 'user' && 'name' in entry) {
            filtered.push({
              displayName: entry.name,
              email: entry.email,
              profilePhotoUrl: entry.photo_url,
              sortKey: entry.sort_key,
            });
          }
          return filtered;
        },
        [],
      )
      .sort((entry1: PersonObject, entry2: PersonObject) => {
        if (!entry1.sortKey && !entry2.sortKey) {
          return 0;
        } else if (!entry1.sortKey) {
          return 1;
        } else if (!entry2.sortKey) {
          return -1;
        }
        return entry1.sortKey < entry2.sortKey ? 1 : -1;
      });
  };

  const fetchPeopleFromCache = async (): Promise<PersonObject[]> => {
    const recs = await getRecommendations();
    return recs
      .map(extractValidPersonFromRecommendation)
      .filter((person): person is PersonObject => person !== null); // Filters out null values with a type guard
  };

  const performPeopleSearch = async (query: string) => {
    logger.debug('[search] searching for people');

    const [peopleFromCache, peopleFromServer] = await Promise.all([
      fetchPeopleFromCache(),
      fetchPeopleFromServer(query),
    ]);

    const peopleMap = new Map<string, PersonObject>();

    // Add server people to map, overriding any existing entries with the same email
    peopleFromServer.forEach((person) => {
      peopleMap.set(person.email, {
        ...person,
        score: scorePerson(person, query),
      });
    });

    // Add cache people to map only if they don't already exist to avoid overriding server data
    peopleFromCache.forEach((person) => {
      if (!peopleMap.has(person.email)) {
        peopleMap.set(person.email, {
          ...person,
          score: scorePerson(person, query),
        });
      }
    });

    return Array.from(peopleMap.values())
      .filter(({ score = 0 }) => score > MIN_SCORE_THRESHOLD)
      .sort(({ score: scoreA = 0 }, { score: scoreB = 0 }) => scoreB - scoreA);
  };

  const tearDown = async () => {
    fetchPromise = null;
    suggestionsCache = {
      suggestions: [],
      lastFetchMs: 0,
    };
  };

  const service = {
    getPeopleSuggestions,
    performPeopleSearch,
    tearDown,
  };

  listenForLogout(ServiceId.PEOPLE).subscribe(() => {
    tearDown();
  });

  services.provide<Service>(ServiceId.PEOPLE, service, [
    ServiceId.DBX_API,
    ServiceId.TYPEAHEAD_SEARCH,
  ]);
}
