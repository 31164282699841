import { CloseDashChatType } from '@mirage/analytics/events/enums/close_dash_chat_type';
import {
  DynamicPanelData,
  DynamicPanelView,
} from '@mirage/mosaics/DynamicPanel/atoms';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import { useEffect, useRef } from 'react';
import { AnswersConversation } from '../AnswersConversation';

type AnswersPanelProps = {
  onClose: (closeChatType: CloseDashChatType) => void;
  panelData: DynamicPanelData['answers'];
  refreshPanel: (view: DynamicPanelView) => void;
};

export const AnswersPanel = ({
  onClose,
  panelData,
  refreshPanel,
}: AnswersPanelProps) => {
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      // if user is switching attachment, or from summarize -> non-summarize
      // we should 'refresh' the panel by opening/closing
      refreshPanel('answers');
    }
  }, [panelData?.attachment?.uuid, panelData?.isSummarize, refreshPanel]);

  return (
    <AnswersConversation
      onClose={onClose}
      papData={panelData?.pap || undefined}
      attachment={panelData?.attachment}
      isSummarize={panelData?.isSummarize}
      onOpenFeedbackSnackbar={() => {
        showSnackbar({
          title: i18n.t('feedback_received'),
        });
      }}
      onOpenCopyTextSnackbar={() => {
        showSnackbar({ title: i18n.t('copied_answer') });
      }}
      initialQuery={panelData?.initialQuery}
      initialAnswer={panelData?.initialAnswer}
    />
  );
};
