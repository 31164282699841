import { dash_feed } from '@dropbox/api-v2-client';
import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { APIv2Callable } from '@mirage/service-dbx-api/service';
import WithDefaults from '@mirage/storage/with-defaults';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import { ActivityFeedFilters, UserActivityFeed } from '../types';
import { listActivityFeed } from './api';

import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

export type Service = ReturnType<typeof provideFeedService>;

export type StoredActivityFeed = {
  activities: dash_feed.ActivityItem[] | undefined;
  filters: ActivityFeedFilters;
  lastViewed: number | undefined;
};

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

interface LogoutServiceContract {
  listenForLogout(service: string): Observable<boolean>;
}

export const DEFAULT_FILTERS: ActivityFeedFilters = {
  isOnlyMine: false,
  applications: undefined,
  actor: undefined,
};

export default function provideFeedService(
  storage: KVStorage<StoredActivityFeed>,
  { callApiV2 }: DbxApiServiceContract,
  logoutService: LogoutServiceContract,
) {
  const adapter = new WithDefaults(storage, {
    activities: undefined,
    lastViewed: undefined,
    filters: DEFAULT_FILTERS,
  });

  const isRefreshingActivities$ = new rx.BehaviorSubject<boolean>(false);
  const isLoadingMoreActivities$ = new rx.BehaviorSubject<boolean>(false);
  const hasMoreActivities$ = new rx.BehaviorSubject<boolean>(false);
  const activities$ = new rx.BehaviorSubject<
    dash_feed.ActivityItem[] | undefined
  >(undefined);

  const filters$ = new rx.BehaviorSubject<ActivityFeedFilters>(DEFAULT_FILTERS);

  // we do not persist the cursor past the current session since we reload everything on refresh
  let currentCursor: string | undefined;

  // Load data from storage
  adapter.get('activities').then((activities) => activities$.next(activities));
  adapter.get('filters').then((filters) => filters$.next(filters));

  const activityFeed$: rx.Observable<UserActivityFeed> = rx
    .combineLatest([
      activities$,
      isRefreshingActivities$,
      isLoadingMoreActivities$,
      hasMoreActivities$,
      filters$,
    ])
    .pipe(
      op.map(([activities, isRefreshing, isLoadingMore, hasMore, filters]) => ({
        activities,
        isRefreshing,
        isLoadingMore,
        hasMore,
        filters,
      })),
    );

  async function getCurrentFilters() {
    return await adapter.get('filters');
  }

  async function setActivityFeedFilters(filters: ActivityFeedFilters) {
    const oldFilters = await getCurrentFilters();
    const newFilters = { ...filters };
    // currently isOnlyMine and actor filters are not compatible with eachother
    // because you cannot search for multiple people (mine vs someone else)
    // so one one changes, we should unset the other
    if (filters.isOnlyMine !== oldFilters.isOnlyMine) {
      newFilters.actor = undefined;
    } else if (filters.actor?.email !== oldFilters.actor?.email) {
      newFilters.isOnlyMine = false;
    }

    filters$.next(newFilters);
    await adapter.set('filters', newFilters);
    await refreshActivityFeed(true);
  }

  async function setActivities(
    activities: dash_feed.ActivityItem[] | undefined,
  ) {
    await adapter.set('activities', activities);
    activities$.next(activities);
  }

  async function clearActivities() {
    await setActivities(undefined);
  }

  // used to prevent "old" refresh requests from overwriting newer requests
  let refreshActivityFeedRequestId = 0;

  // loads a fresh user activity feed
  async function refreshActivityFeed(shouldClearActivities = false) {
    const currentRequestId = ++refreshActivityFeedRequestId;
    try {
      isRefreshingActivities$.next(true);
      if (shouldClearActivities) {
        await clearActivities();
      }
      const items = await _listFeed();
      if (currentRequestId == refreshActivityFeedRequestId) {
        await setActivities(items);
        await adapter.set('lastViewed', Date.now());
        isRefreshingActivities$.next(false);
      }
    } catch (e) {
      isRefreshingActivities$.next(false);
    }
  }

  // loads the next page of activities using the current cursor
  async function loadMoreActivities() {
    try {
      isLoadingMoreActivities$.next(true);
      const items = await _listFeed(currentCursor);
      const currentActivities = activities$.getValue() || [];
      await setActivities(currentActivities.concat(items));
    } finally {
      isLoadingMoreActivities$.next(false);
    }
  }

  async function _listFeed(nextCursor?: string) {
    try {
      const limit = 25;
      const lastViewed = await adapter.get('lastViewed');
      const activityFeedFilters = await getCurrentFilters();
      const response = await listActivityFeed(
        callApiV2,
        limit,
        nextCursor,
        lastViewed,
        activityFeedFilters,
      );
      const items = response.items || [];
      currentCursor = response.cursor;
      hasMoreActivities$.next(!!currentCursor);
      return items;
    } catch (e) {
      // if we fail to load more activities, we should reset the cursor
      currentCursor = undefined;
      hasMoreActivities$.next(false);
      throw e;
    }
  }

  function activityFeed(): Observable<UserActivityFeed> {
    return activityFeed$;
  }

  function tearDown() {
    adapter.clear();
  }

  logoutService.listenForLogout(ServiceId.FEED).subscribe(() => {
    tearDown();
  });

  return services.provide(
    ServiceId.FEED,
    {
      activityFeed,
      refreshActivityFeed,
      loadMoreActivities,
      setActivityFeedFilters,
    },
    [ServiceId.DBX_API],
  );
}
