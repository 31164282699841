import { stacks } from '@dropbox/api-v2-client';
import { useAtomValue } from 'jotai';
import { shadowStackAtom } from '../fullPageShadowAtoms';
import { NoSectionsStackItems } from './NoSectionsStackItems';

type SearchResultsStackItemsSectionProps = {
  items: stacks.StackItemShortcut[];
  onEditItem: (item: stacks.StackItemShortcut) => void;
};

export const SearchResultsStackItemsSection: React.FC<
  SearchResultsStackItemsSectionProps
> = ({ items, onEditItem }) => {
  const stack = useAtomValue(shadowStackAtom);
  if (!stack) {
    return null;
  }

  return (
    <NoSectionsStackItems
      header=""
      stack={stack}
      items={items}
      onEditItem={onEditItem}
    />
  );
};
