import { stacks } from '@dropbox/api-v2-client';
import { IconButton } from '@dropbox/dig-components/buttons';
import { OverlayVirtualElement } from '@dropbox/dig-components/overlay';
import {
  TextInput,
  TextInputRefObject,
} from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AddLine,
  RotateRightLine,
  SearchLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { DashNewLinkType } from '@mirage/analytics/events/enums/dash_new_link_type';
import { PAP_Change_DashNewLinkSearchQuery } from '@mirage/analytics/events/types/change_dash_new_link_search_query';
import { PAP_Click_DashNewLink } from '@mirage/analytics/events/types/click_dash_new_link';
import { PAP_Initiate_DashNewLinkSearch } from '@mirage/analytics/events/types/initiate_dash_new_link_search';
import { PAP_Refresh_DashSuggestedLink } from '@mirage/analytics/events/types/refresh_dash_suggested_link';
import { getTitleOfUrl } from '@mirage/service-stacks/service/url-title';
import { stackDerivePAPProps } from '@mirage/service-stacks/service/utils';
import { useMetadataForUrls } from '@mirage/service-url-metadata/hooks';
import { UrlMetadata } from '@mirage/service-url-metadata/types';
import { useDebounce } from '@mirage/shared/hooks/useDebounce';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { KeyCodes } from '@mirage/shared/util/constants';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { isUrl } from '@mirage/shared/util/tiny-utils';
import { constructAbsoluteURL } from '@mirage/shared/util/urls';
import i18n from '@mirage/translations';
import { useResizeObserver } from '@react-hookz/web';
import {
  forwardRef,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { fetchSearchResultsFromAPI } from '../Helpers/Utils';
import { useDefaultItemsToAdd } from '../hooks';
import { AddLinkToStack } from '../types';
import styles from './AddStackItemBox.module.css';
import { LinkSuggestion } from './LinkSuggestion';
import { LoadingSuggestions } from './LoadingSuggestions';
import { TabSuggestion } from './types';

interface AddStackItemBoxAugRevProps {
  stack: stacks.Stack | null;
  existingItems: stacks.StackItemShortcut[] | null;
  sectionIdToAddItem: string;
  addLinkToStack: AddLinkToStack;
  createStackSessionId: string;
  contentSuggestions: TabSuggestion[];
  loadingSuggestions: boolean;
  includeHeader?: boolean;
  suggestionsClassName?: string;
  onLinkAdded?: (item: TabSuggestion) => void;
  onOpenLink?: (tabSuggestion: TabSuggestion) => void;
  onShowLinks?: (links: TabSuggestion[]) => void;
  focusOnLoad?: boolean;
  useResize?: boolean;
  recommendedEmptyState?: boolean;
  triggerElement?: HTMLElement | Element | OverlayVirtualElement | null;
  providedInputString?: string;
}

const NUM_SUGGESTIONS_TO_SHOW = 3;

interface KeyActions {
  onEnter?: (e: KeyboardEvent<HTMLElement>) => void;
  onArrowDown?: (e: KeyboardEvent<HTMLElement>) => void;
  onArrowUp?: (e: KeyboardEvent<HTMLElement>) => void;
}

type OnKeyPress = (
  actions: KeyActions,
) => (e: KeyboardEvent<HTMLElement>) => void;

export const AddStackItemBoxAugRev = forwardRef<
  HTMLDivElement | null,
  AddStackItemBoxAugRevProps
>(function AddStackItemBoxAugRev(
  {
    stack,
    existingItems,
    createStackSessionId,
    sectionIdToAddItem,
    addLinkToStack,
    contentSuggestions,
    loadingSuggestions,
    includeHeader = false,
    suggestionsClassName,
    onLinkAdded,
    onOpenLink,
    onShowLinks,
    focusOnLoad = false,
    useResize = false,
    recommendedEmptyState = false,
    triggerElement,
    providedInputString,
  },
  providedRef,
) {
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [inputStringState, setInputString] = useState('');
  const [searchResults, setSearchResults] = useState<TabSuggestion[]>([]);
  const [recommendationsIndex, setRecommendationsIndex] = useState(0);

  const dashLinkSearchSessionId = useRef('');
  const dashNewLinkSessionId = useRef('');
  const inputRef = useRef<TextInputRefObject | null>(null);
  const linkRefs = useRef<(HTMLElement | null)[]>([]);
  const searchResultsDivRef = useRef<HTMLDivElement | null>(null);

  const { reportPapEvent } = useMirageAnalyticsContext();
  const isMobileSize = useIsMobileSize();

  // This is a bit counter-intuitive. Make the font bigger for
  // mobile size because in iOS Safari, the browser will auto-zoom
  // the entire page when the input font is too small.
  const inputSize = isMobileSize ? 'large' : 'medium';
  const inputString = providedInputString ?? inputStringState;
  const inputStringIsUrl = Boolean(inputString && isUrl(inputString));
  const urlMetadataForInputString = useMetadataForUrls(
    inputStringIsUrl ? [inputString] : [],
  );
  const defaultItemsToAdd = useDefaultItemsToAdd(
    existingItems,
    contentSuggestions,
  );

  // Don't let suggestions refresh on optimistic stack creation, wait until
  // we get a real namespace id. This makes creation feel smoother instead
  // of seeing a double load of this box.
  const loading =
    isSearchLoading || loadingSuggestions || !!(stack && !stack.namespace_id);

  const existingItemUrls = useMemo(
    () =>
      existingItems
        ? new Set(existingItems.map((item) => item.url))
        : new Set(),
    [existingItems],
  );

  const recommendationsToShow = useMemo(
    () =>
      contentSuggestions
        .filter((link) => !existingItemUrls.has(link.url))
        .slice(
          recommendationsIndex,
          recommendationsIndex + NUM_SUGGESTIONS_TO_SHOW,
        ),
    [contentSuggestions, existingItemUrls, recommendationsIndex],
  );

  const results = useMemo(() => {
    // If the recommendedEmptyState is true, show a slice of
    // suggestions as recommended items.
    if (recommendedEmptyState && inputString.length === 0) {
      return recommendationsToShow.length ? recommendationsToShow : [];
    }

    if (searchResults.length === 0) {
      if (inputString.length === 0) {
        return defaultItemsToAdd;
      } else {
        // search results loading
        return [];
      }
    }

    return searchResults;
  }, [
    searchResults,
    inputString,
    defaultItemsToAdd,
    recommendationsToShow,
    recommendedEmptyState,
  ]);

  // Convenience function to get the linkRef at index.
  const linkRefAt = (index: number) => {
    const a = linkRefs.current;
    if (index < 0) index = a.length + index;
    return index >= 0 ? a[index] : undefined;
  };

  const addLink = useCallback(
    async (
      dashNewLinkType: DashNewLinkType,
      link: string | TabSuggestion,
      title?: string,
    ): Promise<boolean> => {
      let item: TabSuggestion;
      if (typeof link === 'string') {
        const url = link.trim();
        if (url === '') {
          return false;
        }
        item = {
          url: constructAbsoluteURL(url),
          title: getTitleOfUrl(url, title),
        };
      } else {
        item = link;
      }
      const success = await addLinkToStack(
        sectionIdToAddItem,
        item,

        dashNewLinkSessionId.current,
        dashLinkSearchSessionId.current,
        dashNewLinkType,
      );
      if (success) {
        onLinkAdded?.(item);
      }

      return success;
    },
    [addLinkToStack, sectionIdToAddItem, onLinkAdded],
  );

  // Debounce request to update suggestions until user stops modifying it for 1s
  const debouncedUpdateSuggestions = useDebounce(
    async function getSearchResults() {
      if (stack?.namespace_id) {
        dashLinkSearchSessionId.current = uuidv4();

        reportPapEvent(
          PAP_Initiate_DashNewLinkSearch({
            ...(stack ? stackDerivePAPProps(stack) : undefined),
            featureLine: 'stacks',
            createStackSessionId,
            dashNewLinkSessionId: dashNewLinkSessionId.current,
            dashLinkSearchSessionId: dashLinkSearchSessionId.current,
          }),
        );
      }

      try {
        const links = await fetchSearchResultsFromAPI(inputString);
        if (links) {
          setSearchResults(links);
        }
      } finally {
        setIsSearchLoading(false);
      }
    },
    1000,
  );

  const updateSearchResultsHeight = useCallback(() => {
    const ref = searchResultsDivRef.current;
    if (!ref) return;

    if (!useResize) {
      // set max height to 262px to show 5.5 search results
      ref.style.maxHeight = '262px';
      return;
    }
    const rect = ref.getBoundingClientRect();

    // 24px - Need to leave a gap between bottom of screen to the bottom of
    // the popup menu, otherwise the menu will get cut off.
    let maxY = window.scrollY + window.innerHeight - 24;

    // Prevent popup menu from overlapping with trigger element.
    if (triggerElement) {
      const triggerRect = triggerElement.getBoundingClientRect();
      const isAboveTrigger = rect.top < triggerRect.top;
      // Note: Cannot check for overlap as DIG will adjust the height to
      // counter it, and the popup menu will not stop shaking.
      // const isOverlapping = rect.bottom >= triggerRect.top;

      if (isAboveTrigger) {
        // 4px - gap between bottom of popup menu and trigger element.
        maxY = triggerRect.top - 4;
      }
    }

    // Max 262px: This will show 5.5 search results, as we want to make sure users know
    // they can scroll for more results.
    // Min 69px: This will show 1.5 search results, and is the minimum possible height.
    ref.style.maxHeight = Math.max(Math.min(maxY - rect.top, 262), 69) + 'px';
  }, [triggerElement, useResize]);

  const showRecommendedHeader =
    recommendedEmptyState &&
    inputString.length === 0 &&
    results.length > 0 &&
    !loading;

  const manualLinkAdd = async (
    url: string,
    urlMetadata: { [key: string]: UrlMetadata } | undefined,
  ) => {
    const title =
      urlMetadata && url in urlMetadata ? urlMetadata[url].title : undefined;

    const success = await addLink('manually_entered', url, title);
    if (success) {
      setInputString('');
    }
  };

  const handleKeyPress: OnKeyPress = ({ onEnter, onArrowDown, onArrowUp }) => {
    return (e) => {
      if (e.key === KeyCodes.enter) {
        e.preventDefault();
        onEnter?.(e);
      } else if (e.key === KeyCodes.arrowDown) {
        e.preventDefault();
        onArrowDown?.(e);
      } else if (e.key === KeyCodes.arrowUp) {
        e.preventDefault();
        onArrowUp?.(e);
      }
    };
  };

  useEffect(() => {
    return () => {
      debouncedUpdateSuggestions.cancel();
    };
  }, [debouncedUpdateSuggestions]);

  useEffect(() => {
    // When inputString is not empty, don't override the search results.
    if (inputString === '') {
      setSearchResults(defaultItemsToAdd);
    }
  }, [inputString, defaultItemsToAdd]);

  useEffect(() => {
    // If input is a link, then stop searching.
    // Make the detection logic stricter by only recognizing `http...` strings.
    if (
      inputString === '' ||
      (inputString &&
        isUrl(inputString) &&
        (inputString.startsWith('http://') ||
          inputString.startsWith('https://')))
    ) {
      return;
    }
    setIsSearchLoading(true);
    debouncedUpdateSuggestions();
  }, [inputString, debouncedUpdateSuggestions]);

  useEffect(() => {
    if (loadingSuggestions) {
      // Reset index when the suggestions are re-fetched.
      setRecommendationsIndex(0);
    }
  }, [loadingSuggestions]);

  useEffect(() => {
    if (recommendationsToShow.length === 0 && recommendationsIndex > 0) {
      setRecommendationsIndex(0);
    }
  }, [recommendationsToShow, recommendationsIndex]);

  useEffect(() => {
    if (stack?.namespace_id) {
      dashNewLinkSessionId.current = uuidv4();
      PAP_Click_DashNewLink({
        ...(stack ? stackDerivePAPProps(stack) : undefined),
        featureLine: 'stacks',
        createStackSessionId,
        dashNewLinkSessionId: dashNewLinkSessionId.current,
      });

      if (focusOnLoad && searchResultsDivRef.current) {
        // Need to delay until the component fully renders to focus
        requestAnimationFrame(() => {
          if (searchResultsDivRef.current) {
            const rect = searchResultsDivRef.current.getBoundingClientRect();
            const isInView = rect.top < window.innerHeight && rect.bottom >= 0;
            if (isInView) {
              inputRef.current?.focus();
            }
          }
        });
      }
    }
  }, [stack, inputRef, createStackSessionId, focusOnLoad]);

  useEffect(() => {
    if (stack?.namespace_id && inputString) {
      reportPapEvent(
        PAP_Change_DashNewLinkSearchQuery({
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
          createStackSessionId,
          dashNewLinkSessionId: dashNewLinkSessionId.current,
          dashLinkSearchSessionId: dashLinkSearchSessionId.current,
          queryLength: inputString.length,
        }),
      );
    }
  }, [createStackSessionId, inputString, reportPapEvent, stack]);

  useEffect(() => {
    onShowLinks?.(results);
  }, [results, onShowLinks]);

  useEffect(() => {
    linkRefs.current = Array(results.length).fill(null);
  }, [results]);

  useResizeObserver(searchResultsDivRef, updateSearchResultsHeight);

  return (
    <div className={styles.box} ref={providedRef}>
      {includeHeader && (
        <div className={styles.header}>
          <Text size={'small'} isBold>
            {i18n.t('add_to_stack')}
          </Text>
        </div>
      )}
      <TextInput
        ref={inputRef}
        wrapperProps={{ className: styles.searchTextInputWrapper }}
        withLeftAccessory={
          <UIIcon src={SearchLine} className={styles.searchIcon} />
        }
        withRightAccessory={
          inputStringIsUrl && (
            <DigTooltip title={i18n.t('add_to_stack')}>
              <IconButton
                variant="borderless"
                onClick={() => {
                  manualLinkAdd(inputString, urlMetadataForInputString);
                }}
              >
                <UIIcon src={AddLine} size="large" />
              </IconButton>
            </DigTooltip>
          )
        }
        isTransparent
        size={inputSize}
        value={inputString}
        placeholder={i18n.t('search_item_placeholder')}
        onChange={(e) => setInputString(e.target.value)}
        onKeyDown={handleKeyPress({
          onEnter: () => {
            if (inputStringIsUrl) {
              manualLinkAdd(inputString, urlMetadataForInputString);
            }
          },
          onArrowDown: () => {
            if (recommendationsToShow.length > 0) {
              linkRefAt(0)?.focus();
            }
          },
        })}
      />
      <div
        className={styles.searchResults}
        ref={(ref) => {
          searchResultsDivRef.current = ref;
          updateSearchResultsHeight();
        }}
      >
        {showRecommendedHeader && (
          <RecommendedHeader
            stack={stack}
            createStackSessionId={createStackSessionId}
            refreshSuggestions={() => {
              setRecommendationsIndex(
                (prevIndex) => prevIndex + NUM_SUGGESTIONS_TO_SHOW,
              );
            }}
          />
        )}
        {loading ? (
          <LoadingSuggestions />
        ) : (
          results.map((tabSuggestion, index) => (
            <LinkSuggestion
              key={tabSuggestion.url}
              className={suggestionsClassName}
              setRef={(ref) => (linkRefs.current[index] = ref)}
              url={tabSuggestion.url}
              addLink={addLink}
              tabSuggestion={tabSuggestion}
              onOpenLink={onOpenLink}
              onKeyDown={handleKeyPress({
                onArrowDown: () => {
                  if (index >= results.length - 1) {
                    inputRef.current?.focus();
                  } else {
                    linkRefAt(index + 1)?.focus();
                  }
                },
                onArrowUp: () => {
                  if (index === 0) {
                    inputRef.current?.focus();
                  } else {
                    linkRefAt(index - 1)?.focus();
                  }
                },
              })}
            />
          ))
        )}
        {!loading && inputString.length > 0 && results.length === 0 && (
          <Text color="faint" className={styles.noSearchResults}>
            {i18n.t('no_search_results')}
          </Text>
        )}
      </div>
    </div>
  );
});

interface RecommendedHeaderProps {
  stack: stacks.Stack | null;
  createStackSessionId: string;
  refreshSuggestions: () => void;
}

const RecommendedHeader: React.FC<RecommendedHeaderProps> = ({
  stack,
  createStackSessionId,
  refreshSuggestions,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  return (
    <div className={styles.recommendedHeader}>
      <div className={styles.recommendedHeaderText}>
        <Text size="small" isBold color="faint">
          {i18n.t('recommended_header')}
        </Text>
      </div>
      <IconButtonWithTooltip
        tooltipProps={{
          title: i18n.t('refresh'),
        }}
        variant="borderless"
      >
        <UIIcon
          src={RotateRightLine}
          onClick={() => {
            if (stack) {
              reportPapEvent(
                PAP_Refresh_DashSuggestedLink({
                  featureLine: 'content_suggestions',
                  createStackSessionId,
                  ...stackDerivePAPProps(stack),
                }),
              );
            }
            refreshSuggestions();
          }}
        />
      </IconButtonWithTooltip>
    </div>
  );
};
