// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1349
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { OperatingSystem } from '../enums/operating_system';
import type { RightSidebarState } from '../enums/right_sidebar_state';
import type { TabGroupType } from '../enums/tab_group_type';

// Event_name: 'view.dash_stack'
// Description: Fires when user opens a stack to view the stack details, including on the sidebar or on the Start Page Suggested Focus card (+N more, Go to stack buttons)
// Owner: content-ingest
export type View_DashStack = PAPEventGeneric<
  'stacks',
  'view',
  'dash_stack',
  {
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // The number of links in the stack
    numberOfLinks?: number;
    // The stack id that represents the stack
    stackId?: string;
    // Generated random request id for tracking suggestions in Dash
    dashRequestId?: string;
    // A hashed integer for unique predictions recommended in Dash
    predictionIdHash?: number;
    // A hashed integer for items/content recommended in Dash
    itemIdHash?: number;
    // Bool that indicates if the item acted upon is pinned
    isPinned?: boolean;
    // Visual state of the right sidebar
    rightSidebarState?: RightSidebarState;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // User_id associated with the creator of the content being acted upon
    creatorId?: string;
    // Rank of the individual suggestion within the group
    predictionPosition?: number;
    // The zero-index returned position of search result in a list of search results.
    resultPosition?: number;
    // Index position of the dash dot navigation clicked
    dotPosition?: number;
    // Type of Tab groups provided by dash
    tabGroupType?: TabGroupType;
    // stormcrow feature name
    feature?: string;
    // Total result score of ranking.
    totalScore?: number;
    // Meeting IDs that are uuidv4s as generated by neo4j. Used to tie together Dash meetings and related content.
    meetingId?: string;
    // 0 indexed position of the calendar event/item
    calendarItemPosition?: number;
  }
>;

export function PAP_View_DashStack(
  properties?: View_DashStack['properties'],
): View_DashStack {
  return <View_DashStack>{
    class: 'stacks',
    action: 'view',
    object: 'dash_stack',
    properties,
  };
}
