import { Dropbox } from '@dropbox/api-v2-client';
import {
  PAPEvent,
  PAPEventDefaultProperties,
} from '@mirage/analytics/events/base/event';
import { DashSurface } from '@mirage/analytics/events/enums/dash_surface';
import { OperatingSystem } from '@mirage/analytics/events/enums/operating_system';
import * as authService from '@mirage/service-auth';
import { tagged } from '@mirage/service-logging';
import Sentry from '@mirage/shared/sentry';
import { getCombineAsyncRequestsFunc } from '@mirage/shared/util/combine-async-requests';
import { getOperatingSystem } from '@mirage/shared/util/os';
import { nonEmpty } from '@mirage/shared/util/tiny-utils';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import { AnalyticsClient, TRANSPORT_TYPE } from 'pap-client';
import { validateEvent } from './validation';

const logger = tagged('pap');

interface UniversalProperties {
  deviceId?: string;
  operatingSystem?: OperatingSystem | undefined;
}

export type PapClientConfig =
  | {
      transportType: 'fetch';
    }
  | {
      transportType: 'sdk';
      clientId: string;
      clientSecret: string;
    };

export class PapClient {
  private papClient: AnalyticsClient | undefined;
  private creatorId: string | undefined;
  private getPapClientCombined: typeof this.getPapClientActual;

  public constructor(
    private readonly clientConfig: PapClientConfig,
    private readonly dashSurface: DashSurface,
    private readonly environment: 'development' | 'production',
    private readonly logging: boolean,
    private readonly isDev: boolean,
    private readonly reportValidationErrorsToSentry: boolean,
  ) {
    // Avoid race condition with multiple concurrent callers.
    this.getPapClientCombined = getCombineAsyncRequestsFunc(() =>
      this.getPapClientActual(),
    );
  }

  private async getPapClientActual() {
    if (this.creatorId && this.papClient) return this.papClient;

    if (!this.papClient) {
      switch (this.clientConfig.transportType) {
        case TRANSPORT_TYPE.fetch:
          {
            const fetcher: typeof fetch = async (resource, options) => {
              const accessToken = await authService.getAccessToken();
              if (!accessToken) {
                logger.warn(
                  'Unable to log pap event due to missing access token.',
                );
                throw new Error(
                  'access token missing, unable to send pap event!',
                );
              }
              const headers = new Headers(options?.headers || []);
              headers.append('Authorization', `Bearer ${accessToken}`);
              const init: RequestInit = { ...(options || {}), headers };
              return fetch(`https://api.dropboxapi.com/${resource}`, init);
            };

            this.papClient = new AnalyticsClient({
              transport: {
                type: TRANSPORT_TYPE.fetch,
                fetch: fetcher,
              },
            });
          }
          break;
        case TRANSPORT_TYPE.sdk: // fallthrough
        default:
          nonEmpty(
            this.clientConfig.clientId,
            'dropboxOptions.clientId must be set to use the PAP client',
          );
          nonEmpty(
            this.clientConfig.clientSecret,
            'dropboxOptions.clientSecret must be set to use the PAP client',
          );

          this.papClient = new AnalyticsClient({
            transport: {
              type: TRANSPORT_TYPE.sdk,
              sdk: new Dropbox(this.clientConfig),
            },
          });
      }
    }

    const account = await authService.getCurrentAccount();
    if (account) {
      this.creatorId = account.account_id;

      this.papClient.setPersistentFields({
        identity: {
          // NOTE: foreignUserProvider='dbx_account_id' will trigger the PAP
          // data pipeline to decrypt the foreignUserId value and set the
          // unencrypted value in the user_id column.
          foreignUserProvider: 'dbx_account_id',
          foreignUserId: account.account_id,
          teamId: Number(account.team?.id),
        },
      });
    }

    return this.papClient;
  }

  private static constantUniversalProperties: UniversalProperties | undefined;

  private async getConstantUniversalProperties() {
    if (!PapClient.constantUniversalProperties) {
      PapClient.constantUniversalProperties = {
        operatingSystem: getOperatingSystem(),
      };
    }

    try {
      if (!PapClient.constantUniversalProperties.deviceId) {
        PapClient.constantUniversalProperties.deviceId =
          await authService.getInstallId();
      }
    } catch (e) {
      logger.warn(
        `Unable to configure universal property "deviceId" with error: ${e}`,
      );
      PapClient.constantUniversalProperties.deviceId = 'unknown';
    }

    return PapClient.constantUniversalProperties;
  }

  private async addUniversalProperties(event: PAPEvent) {
    if (this.isDev) {
      const props = 'properties' in event ? event.properties ?? {} : {};

      // Check list of fields that caller must specify.
      const missingFields: string[] = [];
      for (const field of ['dashSurface']) {
        if (!(field in props)) missingFields.push(field);
      }

      // Check list of fields that caller should not specify.
      const uneededFields: string[] = [];
      for (const field of ['environment', 'operatingSystem', 'deviceId']) {
        if (field in props) uneededFields.push(field);
      }

      if (this.logging && (missingFields.length || uneededFields.length)) {
        logger.warn(
          `Check PAP event universal properties:` +
            ` missing=[${missingFields}]` +
            `, unneeded=[${uneededFields}]` +
            `, event=${JSON.stringify(event)}`,
        );
      }
    }

    const universalProperties = await this.getConstantUniversalProperties();

    // Do basic type-checking here.
    const properties: Record<string, string | number | boolean> = {
      ...universalProperties,
      environment: this.environment,
      startTimeMs: event.properties?.startTimeMs ?? Date.now(),
      ...event.properties,
    };

    return { ...event, properties };
  }

  /// XXX: for backwards compatibility with the extension app, we need to hash
  /// the stackId before sending it to PAP.  this is temporary and should be
  /// removed once we have enough data with the new stackId format.
  private sanitizeEventStackId = (
    properties: PAPEventDefaultProperties & { stackId?: string },
    isDev: boolean,
  ) => {
    const hmacKey = isDev
      ? 'eb1ab9f6cd90b01f7ce309235ab9d4fc' // AMPLITUDE_KEY Dash (Dev)
      : '60246feb4cba47df5a9df207133afb1f'; // Dash prod
    const unhashedStackId = properties.stackId;
    if (unhashedStackId) {
      return {
        ...properties,
        unhashedStackId,
        stackId: hmacSHA512(unhashedStackId, hmacKey).toString(),
      };
    }
    return properties;
  };

  public resetPapClient() {
    this.papClient = undefined;
  }

  public async reportPapEvent(
    event: PAPEvent,
    flush?: boolean,
  ): Promise<PAPEvent | undefined> {
    const client = await this.getPapClientCombined();
    if (!client) {
      logger.error(
        `Dropping event ${event.action}.${event.object} due to no PAP client`,
      );
      return undefined;
    }
    const newEvent = await this.addUniversalProperties(event);
    newEvent.properties = this.sanitizeEventStackId(
      newEvent.properties,
      this.isDev,
    );
    newEvent.properties.dashSurface = this.dashSurface;
    if (
      this.creatorId &&
      newEvent.properties.stackId &&
      !newEvent.properties.creatorId
    ) {
      newEvent.properties.creatorId = this.creatorId;
    }

    if (this.reportValidationErrorsToSentry) {
      validateEvent(newEvent);
    }

    client.logEvent(
      newEvent,
      {
        flush,
        onFailedToSendEvent: (willRetry: boolean) => {
          Sentry.captureMessage(
            `Error reporting PAP Event - Retry: ${willRetry}`,
            willRetry ? 'info' : 'error',
            {
              data: {
                eventAction: newEvent.action,
                eventClass: newEvent.class,
              },
            },
          );
        },
      },
      'cmde',
    );

    return newEvent;
  }
}
