import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';
import { getSyncService, Service } from './service';

const service = services.get<Service>(ServiceId.PLATFORM_ACTIONS);

export function isSynchronous() {
  return Boolean(getSyncService());
}

/**
 * Webapp: This has to be performed synchronously, otherwise the action will
 * get blocked by the pop-up blocker in mobile browsers.
 * Electron: This has to be performed in the background.
 */
export async function openURL(url?: string): Promise<void> {
  if (!url) {
    return Promise.resolve();
  }
  const s = getSyncService();
  return s ? s.openURL(url) : rx.firstValueFrom(service.openURL(url));
}

// WARN: Only use for local apps and local files. Be weary of untrusted user
// input! Electron `openExternal` does no sanitization!
export async function openLocalPath(path: string): Promise<void> {
  const s = getSyncService();
  return s
    ? s.openLocalPath(path)
    : rx.firstValueFrom(service.openLocalPath(path));
}

export function copyToClipboard(text: string): Promise<void> {
  const s = getSyncService();
  return s
    ? s.copyToClipboard(text)
    : rx.firstValueFrom(service.copyToClipboard(text));
}
