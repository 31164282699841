import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { DashGettingStartedChecklistItem } from '@mirage/analytics/events/enums/dash_getting_started_checklist_item';
import { DashGettingStartedChecklistItemAction } from '@mirage/analytics/events/enums/dash_getting_started_checklist_item_action';
import { PAP_Dismiss_DashGettingStartedChecklist } from '@mirage/analytics/events/types/dismiss_dash_getting_started_checklist';
import { PAP_Finish_DashGettingStartedChecklistItem } from '@mirage/analytics/events/types/finish_dash_getting_started_checklist_item';
import { PAP_Open_DashGettingStartedChecklistItem } from '@mirage/analytics/events/types/open_dash_getting_started_checklist_item';
import { PAP_Select_DashGettingStartedChecklistItemInformation } from '@mirage/analytics/events/types/select_dash_getting_started_checklist_item_information';
import { PAP_Shown_DashGettingStartedChecklist } from '@mirage/analytics/events/types/shown_dash_getting_started_checklist';
import { useConnectorsUI } from '@mirage/mosaics/SettingsPage/useConnectorsUI';
import { getFirstLoginTime } from '@mirage/service-onboarding';
import { useOnboardingValues } from '@mirage/service-onboarding/hooks';
import {
  getOnboardingChecklist,
  markChecklistItemComplete,
} from '@mirage/service-onboarding-checklist';
import { OnboardingChecklistItem } from '@mirage/service-onboarding-checklist/service';
import { openURL } from '@mirage/service-platform-actions';
import { stackGetShareId } from '@mirage/service-stacks/service/utils';
import {
  isExtensionAvailableForBrowser,
  openExtensionLinkForCurrentBrowser,
  useIsExtensionInstalled,
} from '@mirage/settings/utils/webExtensionsHelpers';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { DESKTOP_DOWNLOAD_URL } from '@mirage/shared/urls';
import { ONE_DAY_IN_MILLIS } from '@mirage/shared/util/constants';
import { useSortedStacks } from '@mirage/stacks/hooks';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { SVGProps, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as modules from './modules';

export type OnboardingChecklistAction = {
  key?: DashGettingStartedChecklistItemAction;
  label: string;
  action?: () => void;
  iconStart?: (props: SVGProps<SVGSVGElement>) => React.ReactElement;
  markComplete?: boolean;
};

export type OnboardingChecklistStep = OnboardingChecklistItem & {
  id: number;
  title: string;
  message: string;
  actions: OnboardingChecklistAction[];
  infoLink?: string;
};

type OnboardingChecklistStepPartial = {
  id: number;
  moduleId: string;
  title: string;
  message: string;
  actions: OnboardingChecklistAction[];
  infoLink?: string;
  milestoneKeys: string[];
};

const DISMISSED_GETTING_STARTED_CHECKLIST = 'dismissedGettingStartedChecklist';
const SEEN_GETTING_STARTED_CHECKLIST = 'seenGettingStartedChecklist';
const ONBOARDING_CHECKLIST_TYPE = 'is_dash_checklist';
const OPEN_DELAY_ON_LOAD = 1500; // 1.5 seconds
const MARK_SEEN_DELAY = 5000;

const getOnboardingChecklistSteps = (
  extensionAvailableForBrowser: boolean,
): OnboardingChecklistStepPartial[] => {
  const checklistItems = [];

  checklistItems.push(
    modules.search,
    modules.getStartedStack,
    modules.createStack,
    extensionAvailableForBrowser ? modules.installApps : modules.installDesktop,
  );

  return checklistItems.map((item, idx) => ({ ...item, id: idx }));
};

export const OpenTypeaheadAtom = atomWithStorage<boolean>(
  'openTypeaheadAtom',
  false,
);

export const OnboardingChecklistEnabledAtom = atomWithStorage<boolean>(
  'onboardingChecklistEnabledAtom',
  false,
);

const OnboardingChecklistDirtyAtom = atomWithStorage<boolean>(
  'onboardingChecklistDirtyAtom',
  false,
);

const OnboardingChecklistAtom = atomWithStorage<OnboardingChecklistStep[]>(
  'onboardingChecklistItems',
  [],
);

export default function useOnboardingChecklist() {
  const { checklistItems, fetchChecklistItems } = useOnboardingChecklistItems();
  const { displayed, setDismissed } = useChecklistDisplayed();
  const { progress } = useChecklistProgress();
  const [openId, setOpenId] = useState('');
  const [open, setOpen] = useState(false);
  const { openLearnMore } = useChecklistActions(setOpen);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const previousIncompleteItem = useRef('');

  const { getOnboardingValue, setOnboardingValue } = useOnboardingValues();

  const handleOpenId = useCallback(
    (moduleId: string) => {
      setOpenId(moduleId);

      if (!open) return;
      reportPapEvent(
        PAP_Open_DashGettingStartedChecklistItem({
          dashGettingStartedChecklistItem:
            moduleId as DashGettingStartedChecklistItem,
          actionSurfaceComponent: 'getting_started_checklist',
          featureLine: 'getting_started_checklist',
        }),
      );
    },
    [reportPapEvent, open],
  );

  useEffect(() => {
    if (!checklistItems?.length) {
      return;
    }

    const completeItemCount = checklistItems.filter((m) => m.isComplete).length;
    if (!completeItemCount) {
      return;
    }

    const firstIncompleteItem = checklistItems.find((m) => !m.isComplete);
    if (
      firstIncompleteItem &&
      firstIncompleteItem.moduleId !== previousIncompleteItem.current
    ) {
      handleOpenId(firstIncompleteItem.moduleId);
      previousIncompleteItem.current = firstIncompleteItem.moduleId;
      setOpen(true);
    } else if (checklistItems.length > 0) {
      // All checklist items have been completed
      // If last checklist item was completed yesterday, dismiss the checklist
      const lastCompletedItem = checklistItems.sort((a, b) => {
        if (!a.completedAtMs || !b.completedAtMs) {
          return 0;
        }
        if (a.completedAtMs < b.completedAtMs) {
          return 1;
        }
        return -1;
      })[0];
      const lastCompletedMs = lastCompletedItem.completedAtMs;
      const yesterdayMs = Date.now() - ONE_DAY_IN_MILLIS;
      if (lastCompletedMs && lastCompletedMs < yesterdayMs) {
        // If the last item was completed over 24 hours ago, automatically dismiss the checklist
        setDismissed(false);
      }
    }
  }, [checklistItems, handleOpenId, setDismissed]);

  // If displayed, fetch the latest getting started checklist progress and show if not seen
  useEffect(() => {
    const fetchShownOnLoad = async () => {
      if (displayed) {
        if (checklistItems?.length > 0) {
          const seen = await getOnboardingValue(SEEN_GETTING_STARTED_CHECKLIST);
          if (!seen) {
            setTimeout(() => {
              // Delay the initial open by 1.5 seconds
              setOpen(true);
            }, OPEN_DELAY_ON_LOAD);
          }
        }
        fetchChecklistItems();
      }
    };

    fetchShownOnLoad();
  }, [
    checklistItems?.length,
    displayed,
    fetchChecklistItems,
    getOnboardingValue,
  ]);

  const handleShown = () => {
    setTimeout(() => {
      // Delay marking as shown in order to ignore instances of checklist that are shown before onboarding redirect
      setOnboardingValue(SEEN_GETTING_STARTED_CHECKLIST, true);
    }, MARK_SEEN_DELAY);

    reportPapEvent(
      PAP_Shown_DashGettingStartedChecklist({
        actionSurfaceComponent: 'getting_started_checklist',
        featureLine: 'getting_started_checklist',
      }),
    );
  };

  return {
    checklistItems,
    fetchChecklistItems,
    displayed,
    progress,
    open,
    openId,
    setDismissed,
    setOpenId: handleOpenId,
    openLearnMore,
    setOpen,
    handleShown,
  };
}

export function useOnboardingChecklistItems() {
  const setOpenTypeahead = useSetAtom(OpenTypeaheadAtom);
  const { openConnectors } = useConnectorsUI();
  const navigate = useNavigate();
  const extensionInstalled = useIsExtensionInstalled();
  const extensionAvailableForBrowser = isExtensionAvailableForBrowser();
  const hasBrowserExtensionInstalled =
    extensionInstalled && extensionAvailableForBrowser;
  const [checklistItems, setChecklistItems] = useAtom(OnboardingChecklistAtom);
  const [isDirty, setIsDirty] = useAtom(OnboardingChecklistDirtyAtom);
  const stacks = useSortedStacks();
  const onboardingChecklistSteps = getOnboardingChecklistSteps(
    extensionAvailableForBrowser,
  );

  const addBrowserExtension = () => {
    openExtensionLinkForCurrentBrowser();
  };

  const installDesktopApp = () => {
    openURL(DESKTOP_DOWNLOAD_URL);
  };

  const fetchChecklistItems = useCallback(async () => {
    const items = await getOnboardingChecklist(ONBOARDING_CHECKLIST_TYPE);
    const hasInstalledDesktop = !!(await getFirstLoginTime());
    const uChecklistItems = onboardingChecklistSteps
      .map((uiModule) => {
        const { moduleId } = uiModule;
        const module = items?.find((m) => m.moduleId === moduleId);
        if (!module) return undefined;

        const newChecklistItem = {
          ...module,
          ...uiModule,
        };
        switch (moduleId) {
          case 'perform_dash_search_module':
            newChecklistItem.actions[0].action = () => {
              // Open the typeahead
              setOpenTypeahead(true);
            };
            break;
          case 'create_stack_module':
            newChecklistItem.actions[0].action = () => {
              // Navigate to Create Stack page
              navigate('/stacks/new');
            };
            break;

          case 'explore_get_started_stack_module':
            {
              const gettingStartedStack = stacks?.find(
                (s) =>
                  s.stack_data?.creation_type?.['.tag'] === 'welcome_stack',
              );
              if (!gettingStartedStack) {
                return undefined;
              }
              newChecklistItem.actions[0].action = () => {
                // Navigate to Welcome Stack page
                navigate(`/stacks/${stackGetShareId(gettingStartedStack)}`);
              };
            }
            break;
          case 'install_dash_desktop_and_extension_module': {
            if (extensionAvailableForBrowser) {
              newChecklistItem.actions[0].markComplete =
                hasBrowserExtensionInstalled;
              newChecklistItem.actions[0].action = () => {
                // Open browser extension download link
                addBrowserExtension();
              };
            }
            const indexOfAction = extensionAvailableForBrowser ? 1 : 0;
            newChecklistItem.actions[indexOfAction].markComplete =
              hasInstalledDesktop;
            newChecklistItem.actions[indexOfAction].action = () => {
              // Open the desktop app download link
              installDesktopApp();
            };
            break;
          }
        }
        return newChecklistItem;
      })
      .filter(Boolean) as OnboardingChecklistStep[];

    setChecklistItems(uChecklistItems);
    setIsDirty(false);
    // If we add in the missing dependency, an infinite loop is created
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasBrowserExtensionInstalled,
    navigate,
    openConnectors,
    setChecklistItems,
    setOpenTypeahead,
    stacks,
    isExtensionAvailableForBrowser,
  ]);

  // If the checklist is 'dirty' that means we've marked checklist items
  // as complete and we need to perform a refresh
  useEffect(() => {
    const doChecklistItemRefresh = async () => {
      if (isDirty) fetchChecklistItems();
    };
    doChecklistItemRefresh();
  }, [fetchChecklistItems, isDirty]);

  return {
    checklistItems,
    fetchChecklistItems,
  };
}

function useChecklistProgress() {
  const { checklistItems } = useOnboardingChecklistItems();
  const [progress, setProgress] = useState(0);
  const previousProgress = useRef(0);

  const updateProgress = (val: number) => {
    setProgress(val);
    previousProgress.current = val;
  };

  useEffect(() => {
    // Checklist items can be changed from separate instances of the hook
    // If we listen for changes we can update the progress appropriately
    if (!checklistItems) {
      updateProgress(0);
      return;
    }

    const completeItemCount = checklistItems.filter((m) => m.isComplete).length;
    if (!completeItemCount) {
      updateProgress(0);
      return;
    }
    const updatedProgress = (completeItemCount / checklistItems.length) * 100;
    if (updatedProgress === previousProgress.current) return;
    updateProgress(updatedProgress);
  }, [checklistItems]);

  return { progress };
}

export function useChecklistActions(setOpen: (open: boolean) => void) {
  const { checklistItems } = useOnboardingChecklistItems();
  const setIsDirty = useSetAtom(OnboardingChecklistDirtyAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const hasBrowserExtensionInstalled = useIsExtensionInstalled();
  const stacks = useSortedStacks();
  const hasMarkedStack = useRef(false);
  const hasMarkedAppsInstalled = useRef(false);
  const markChecklistItemComplete = useMarkChecklistItemComplete();
  const extensionAvailableForBrowser = isExtensionAvailableForBrowser();
  const onboardingChecklistSteps = getOnboardingChecklistSteps(
    extensionAvailableForBrowser,
  );

  const markComplete = useCallback(
    (moduleId: string) => {
      const asyncMarkComplete = async () => {
        const success = await markChecklistItemComplete(moduleId);
        if (!success) return;
        setIsDirty(true);
        setOpen(true);
      };
      asyncMarkComplete();
    },
    [markChecklistItemComplete, setIsDirty, setOpen],
  );

  // If a stack is added, mark create_stack_module module as complete
  useEffect(() => {
    const addAppModule = checklistItems.find(
      (m) => m.moduleId === 'create_stack_module' && !m.isComplete,
    );
    if (addAppModule && stacks?.length && !hasMarkedStack.current) {
      const ownedStacks = stacks.filter(
        (s) =>
          s.permission?.['.tag'] === 'owner' &&
          s.stack_data?.creation_type?.['.tag'] !== 'welcome_stack',
      );
      if (ownedStacks.length) {
        hasMarkedStack.current = true;
        markComplete('create_stack_module');
      }
    }
  }, [stacks, checklistItems, markComplete]);

  // If the browser extension and desktop application have been installed,
  // mark install_dash_desktop_and_extension_module module as complete
  useEffect(() => {
    const asyncMarkComplete = async () => {
      const hasInstalledDesktop = !!(await getFirstLoginTime());

      // If extension is available and installed or not available
      const browserExtensionInstalled =
        (hasBrowserExtensionInstalled && extensionAvailableForBrowser) ||
        !extensionAvailableForBrowser;

      if (
        !hasMarkedAppsInstalled.current &&
        hasInstalledDesktop &&
        browserExtensionInstalled
      ) {
        hasMarkedAppsInstalled.current = true;
        markComplete('install_dash_desktop_and_extension_module');
      }
    };
    asyncMarkComplete();
  }, [
    extensionAvailableForBrowser,
    hasBrowserExtensionInstalled,
    markComplete,
  ]);

  const openLearnMore = (moduleId: string) => {
    const module = onboardingChecklistSteps.find(
      (m) => m.moduleId === moduleId,
    );
    if (!module) return;
    const { infoLink } = module;
    reportPapEvent(
      PAP_Select_DashGettingStartedChecklistItemInformation({
        dashGettingStartedChecklistItem:
          moduleId as DashGettingStartedChecklistItem,
        actionSurfaceComponent: 'getting_started_checklist',
        featureLine: 'getting_started_checklist',
      }),
    );
    return openURL(infoLink);
  };

  return { markComplete, openLearnMore };
}

function useChecklistDisplayed() {
  const enabled = useAtomValue(OnboardingChecklistEnabledAtom);
  const [dismissed, setDismissed] = useState<boolean>();
  const [userStale, setUserStale] = useState<boolean>();
  const [seen, setSeen] = useState(false);
  const isMobile = useIsMobileSize();
  const { reportPapEvent } = useMirageAnalyticsContext();

  const { getOnboardingValue, setOnboardingValue } = useOnboardingValues();

  const displayed =
    !isMobile && !dismissed && enabled && (userStale === false || seen);

  const isUserStale = async () => {
    const firstLoginTime = await getFirstLoginTime();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    // If user is over 24 hours old, they're 'stale'
    if (!firstLoginTime || firstLoginTime < yesterday.getTime()) {
      return true;
    }
    return false;
  };

  const handleDismissed = useCallback(
    (log = true) => {
      if (!dismissed) {
        setDismissed(true);
        setOnboardingValue(DISMISSED_GETTING_STARTED_CHECKLIST, true);
      }

      if (log) {
        reportPapEvent(
          PAP_Dismiss_DashGettingStartedChecklist({
            actionSurfaceComponent: 'getting_started_checklist',
            featureLine: 'getting_started_checklist',
          }),
        );
      }
    },
    [dismissed, reportPapEvent, setOnboardingValue],
  );

  // Checks if the getting started checklist has been dismissed
  useEffect(() => {
    const getDismissed = async () => {
      const isDismissed = await getOnboardingValue(
        DISMISSED_GETTING_STARTED_CHECKLIST,
      );
      setDismissed(isDismissed);
    };
    getDismissed();
  }, [getOnboardingValue]);

  useEffect(() => {
    const getSeen = async () => {
      const userSeen = await getOnboardingValue(SEEN_GETTING_STARTED_CHECKLIST);
      setSeen(userSeen);
    };
    getSeen();
  }, [getOnboardingValue]);

  useEffect(() => {
    const fetchUserStale = async () => {
      const userStale = await isUserStale();
      setUserStale(userStale);
    };

    fetchUserStale();
  }, []);

  return {
    displayed,
    setDismissed: handleDismissed,
  };
}

export function useMarkChecklistItemComplete() {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const setIsDirty = useSetAtom(OnboardingChecklistDirtyAtom);
  const extensionAvailableForBrowser = isExtensionAvailableForBrowser();
  const onboardingChecklistSteps = getOnboardingChecklistSteps(
    extensionAvailableForBrowser,
  );

  const markComplete = useCallback(
    async (moduleId: string) => {
      const moduleIndex = onboardingChecklistSteps.findIndex(
        (m) => m.moduleId === moduleId,
      );
      const module = onboardingChecklistSteps[moduleIndex];
      if (!module) return false;
      const success = await markChecklistItemComplete(module.milestoneKeys);
      if (!success) return;

      setIsDirty(true);

      reportPapEvent(
        PAP_Finish_DashGettingStartedChecklistItem({
          dashGettingStartedChecklistItem:
            moduleId as DashGettingStartedChecklistItem,
          actionSurfaceComponent: 'getting_started_checklist',
          featureLine: 'getting_started_checklist',
        }),
      );
      return true;
    },
    [reportPapEvent, setIsDirty],
  );
  return markComplete;
}
