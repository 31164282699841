import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { tagged } from '@mirage/service-logging';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { getConnectorDisplayNameFromConnectorInfo } from '@mirage/shared/connectors';
import { usePrevious } from '@mirage/shared/util/hooks';
import i18n from '@mirage/translations';
import { type RefObject, useCallback, useLayoutEffect, useMemo } from 'react';

import type { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';

const logger = tagged('useTypeaheadAutocomplete');

/** the max length we will autocomplete to and show in the CTA */
const MAX_AUTOCOMPLETE_LENGTH = 80;
/** if query is below this, we don't autocomplete or show a CTA or anything */
const MIN_QUERY_LENGTH = 2;

/**
 * Handles the logic for inline autocomplete and inline CTAs in the search input.
 *
 * This had to use some tricky data flow and some hacks to get around react 17 and
 * prevent infinite loops. A diagram of the data flow can be found at:
 * https://dropbox.atlassian.net/wiki/spaces/Dash/pages/1611794096/mirage+search+search+input+autocomplete
 */
export function useTypeaheadAutocomplete(
  /** The query as react knows it, this should never include the autocompleted text */
  query: string,
  searchResults: TypeaheadResult[] | null,
  selectedIdx: number,
  inputRef: RefObject<HTMLInputElement>,
  typeaheadIsOpen: boolean,
  wasAutocompleteRemoved: boolean,
  /**
   * Called when the user presses backspace to clear out the autocompleted portion
   * used to set the priority of the search dash result over the launch (until the user types again)
   *
   * This will be reset every time the user types a new character!
   */
  onAutocompleteRemoved: (autocompleteRemoved: boolean) => void,
) {
  const enableTypeaheadLaunchIndicator =
    useFeatureFlagValue('dash_2024_07_23_typeahead_launch_indicator') === 'ON';

  //// Build Autocomplete Text ////
  const selectedItemIsSearch =
    searchResults?.[selectedIdx]?.scoredResult?.type ===
    typeahead.ResultType.SuggestedQuery;
  // if search is the item selected, we want to autocomplete to the second
  // item as that is what will be autocompleted-to if the user continues typing
  const autocompleteableResult = selectedItemIsSearch
    ? searchResults?.[1]
    : searchResults?.[selectedIdx];
  // we need the string "unmodified" values, so we are using the scoredResult
  // rather than the typeahead result which can be all JSX and doesn't include
  // score info
  const autocompleteCandidateText =
    enableTypeaheadLaunchIndicator && !!autocompleteableResult?.scoredResult
      ? getInlineAutocompleteText(autocompleteableResult.scoredResult)
      : null;
  const inlineAutocompletePortion = autocompleteCandidateText?.slice(
    query.length,
    MAX_AUTOCOMPLETE_LENGTH * 2, // double it here because we use css to apply a nice ellipsis so it takes up the right amount of space
  );

  //// Check if Autocompletion Should Happen ////
  const previousSelectedIdx = usePrevious(selectedIdx);
  const autocompleteTextStartsWithQuery =
    !!autocompleteCandidateText &&
    autocompleteCandidateText.toLowerCase().startsWith(query.toLowerCase());

  const shouldAutocomplete =
    typeaheadIsOpen &&
    // don't autocomplete for queries that are too short
    query.length >= MIN_QUERY_LENGTH &&
    // don't autocomplete for search dash
    !selectedItemIsSearch &&
    // query is a prefix of the autocompleted text
    autocompleteTextStartsWithQuery &&
    // current query is shorter or equal to the autocomplete text (i.e. the user isn't typing past the autocompleted text)
    query.length <= autocompleteCandidateText.length &&
    // either the user is typing normally and not backspacing
    (!wasAutocompleteRemoved ||
      // or the user has re-selected the first item
      (selectedIdx === 0 && previousSelectedIdx !== 0));

  let newFullInputValue = query;
  let shouldApplyHighlight = false;
  if (shouldAutocomplete) {
    newFullInputValue += inlineAutocompletePortion;
    shouldApplyHighlight = true;
    logger.debug(`Autocompleting users query`);
  }

  //// Apply Text Highlighting ////
  useLayoutEffect(() => {
    if (inputRef.current && shouldApplyHighlight) {
      inputRef.current.setSelectionRange(
        query.length,
        newFullInputValue.length,
      );
      logger.debug('Applied highlighting for Autocomplete');
    }
  }, [inputRef, newFullInputValue.length, query.length, shouldApplyHighlight]);

  //// Build InlineCTA ////
  const ctaResult = searchResults?.[selectedIdx] ?? searchResults?.[0];
  const shouldCTACoverQuery =
    enableTypeaheadLaunchIndicator &&
    !shouldApplyHighlight &&
    !selectedItemIsSearch &&
    selectedIdx !== 0;
  const inlineCTAText =
    enableTypeaheadLaunchIndicator &&
    typeaheadIsOpen &&
    query.length >= MIN_QUERY_LENGTH &&
    !!ctaResult?.scoredResult
      ? getInlineCTAText(ctaResult.scoredResult)
      : null;
  const fakeAutocompleteText: string =
    shouldCTACoverQuery && autocompleteCandidateText
      ? sliceWithEllipsis(autocompleteCandidateText, MAX_AUTOCOMPLETE_LENGTH)
      : '';

  //// Calculate InlineCTA Position ////
  const suffixPosition = useMemo(() => {
    if (!inlineCTAText || !inputRef.current) return null;

    if (shouldCTACoverQuery) {
      // just go ahead and cover the query with the CTA to simulate the effect
      // of replacing the whole input
      return 0;
    }

    const textWidth = calculateTextWidth(newFullInputValue, inputRef.current);
    return textWidth + inputRef.current.offsetLeft + 1;
  }, [inlineCTAText, inputRef, newFullInputValue, shouldCTACoverQuery]);

  //// Calculate Selected Icon ////
  const selectedIcon =
    enableTypeaheadLaunchIndicator && !selectedItemIsSearch
      ? searchResults?.[selectedIdx]?.icon
      : null;

  //// Handle calling onAutocompleteRemoved on Backspace ////
  const onSearchInputKeyDown = useCallback(
    // this should stay as keydown event to prevent pressing and holding
    // backspace from acting weird
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!enableTypeaheadLaunchIndicator) return;

      const { selectionStart, selectionEnd } = event.target as HTMLInputElement;
      if (
        event.key === 'Backspace' &&
        !wasAutocompleteRemoved &&
        // this ensures the user hasn't changed selection. If they did, all
        // bets are off, and we shouldn't prevent the default behavior
        selectionStart === query.length &&
        selectionEnd === newFullInputValue.length
      ) {
        // one final check to ensure there is something actually highlighted
        if (selectionStart !== selectionEnd) {
          event.preventDefault();
        }

        logger.debug(
          'Autocomplete disabled and removed as the user typed backspace',
        );
        onAutocompleteRemoved(true);
      } else if (
        shouldAutocomplete &&
        (event.metaKey || event.ctrlKey) &&
        event.key === 'c'
      ) {
        // prevent ctrl/cmd+c from copying the highlighted results since we forced the user to highlight it
        event.preventDefault();
      }
    },
    [
      enableTypeaheadLaunchIndicator,
      newFullInputValue.length,
      onAutocompleteRemoved,
      query.length,
      shouldAutocomplete,
      wasAutocompleteRemoved,
    ],
  );

  //// Handle Re-enabling Autocomplete ////
  const previousNewFullInputValue = usePrevious(newFullInputValue);
  useLayoutEffect(() => {
    if (
      enableTypeaheadLaunchIndicator &&
      (previousNewFullInputValue?.length ?? 0) < newFullInputValue.length &&
      newFullInputValue.length > MIN_QUERY_LENGTH &&
      wasAutocompleteRemoved
    ) {
      logger.debug('Autocompletion re-enabled as the user kept typing');
      onAutocompleteRemoved(false);
    }
  }, [
    onAutocompleteRemoved,
    previousNewFullInputValue?.length,
    newFullInputValue.length,
    wasAutocompleteRemoved,
    enableTypeaheadLaunchIndicator,
  ]);

  return {
    inputValue: newFullInputValue,
    onSearchInputKeyDown,
    inlineCTAText,
    suffixPosition,
    shouldHighlightCTA: shouldApplyHighlight,
    fakeAutocompleteText,
    selectedIcon,
  };
}

/**
 * This should only return a string for types that support inline autocompletion
 */
function getInlineAutocompleteText(
  typeaheadResult: typeahead.ScoredResult,
): string {
  switch (typeaheadResult?.type) {
    case typeahead.ResultType.DesktopFile:
      return typeaheadResult.result.title;
    case typeahead.ResultType.DesktopApplication:
      return typeaheadResult.result.title;
    case typeahead.ResultType.Stack:
      return (
        typeaheadResult.result.stack_data?.name ||
        i18n.t('stacks_typeahead_default_title')
      );
    case typeahead.ResultType.StackItem:
      return typeaheadResult.result.name;
    case typeahead.ResultType.SearchResult:
      return typeaheadResult.result.title;
    case typeahead.ResultType.Recommendation:
      return typeaheadResult.result.title;
    case typeahead.ResultType.PreviousQuery:
      return typeaheadResult.result.query;
    case typeahead.ResultType.URLShortcut:
      return typeaheadResult.result.parameters.activeHotword;
    default:
      typeaheadResult.type satisfies
        | typeahead.ResultType.MathCalculation
        | typeahead.ResultType.SuggestedQuery;
      return '';
  }
}

/**
 * This should only return a string for types that support an inline CTA
 */
function getInlineCTAText(typeaheadResult: typeahead.ScoredResult): string {
  switch (typeaheadResult.type) {
    case typeahead.ResultType.Recommendation:
    case typeahead.ResultType.SearchResult: {
      const appName = getConnectorDisplayNameFromConnectorInfo(
        typeaheadResult.result.connectorInfo,
      );
      return ' — ' + i18n.t('search_inline_cta_search_result', { appName });
    }
    case typeahead.ResultType.DesktopFile:
      return ' — ' + i18n.t('search_inline_cta_local_file');
    case typeahead.ResultType.Stack:
      return ' — ' + i18n.t('search_inline_cta_stack');
    case typeahead.ResultType.StackItem:
      return ' — ' + i18n.t('search_inline_cta_stack_item');
    case typeahead.ResultType.DesktopApplication:
      return ' — ' + i18n.t('search_inline_cta_desktop_app');
    case typeahead.ResultType.SuggestedQuery:
    case typeahead.ResultType.PreviousQuery:
      return ' — ' + i18n.t('search_query_byline');
    case typeahead.ResultType.URLShortcut:
      return ' — ' + i18n.t('search_inline_cta_shortcut');
    case typeahead.ResultType.MathCalculation:
      return ' = ' + typeaheadResult.result.answer;
    default:
      typeaheadResult satisfies never;
      return '';
  }
}

const canvas = new OffscreenCanvas(window.outerWidth, 200);
const context = canvas.getContext('2d')!;

/**
 * Used to calculate the width of text in the input box by using an offscreen canvas.
 *
 * I timed this and it took an average of 0.1ms to calculate the width of a 250 character string
 **/
function calculateTextWidth(text: string, input: HTMLInputElement) {
  context.font = window.getComputedStyle(input).font;
  return context.measureText(text).width;
}

function sliceWithEllipsis<T extends string | null | undefined>(
  text: T,
  maxLength: number,
) {
  if (text && text.length > maxLength) {
    return text.slice(0, maxLength).trim() + '...';
  }
  return text;
}
