import { Text } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Shown_DashNoResults } from '@mirage/analytics/events/types/shown_dash_no_results';
import {
  ConnectorDisplayConfig,
  ConnectorName,
  getConnectorDisplayConfig,
} from '@mirage/shared/connectors';
import { KeyCodes } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { Error } from './Error';
import styles from './NoResults.module.css';

import type { Connector } from '@mirage/service-dbx-api';
import type { SearchFilter } from '@mirage/shared/search/search-filters';

type Props = {
  query: string;
  connectors: Connector[];
  activeFilters: SearchFilter[];
  openURL: (url: string) => Promise<void>;
  resetAllFilters: () => void;
};

export function NoResults({
  query,
  connectors,
  openURL,
  activeFilters,
  resetAllFilters,
}: Props) {
  const [cannedResultConfigs, setCannedResultConfigs] = useState<
    ConnectorDisplayConfig[]
  >([]);
  const { reportPapEvent, searchAttemptSessionManager, searchSessionManager } =
    useMirageAnalyticsContext();

  useEffect(() => {
    reportPapEvent(
      PAP_Shown_DashNoResults({
        searchAttemptId: searchAttemptSessionManager.getSessionIdOrUndefined(),
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        dashActionSurface: 'search_result_page',
        featureLine: 'search',
      }),
    );
  }, []);

  useEffect(() => {
    const connectorConfigs = connectors
      .filter((connector) => {
        if (!activeFilters.length) return true;
        if (!connector?.id_attrs?.type) return false;

        const filterDefinition = {
          type: 'connector',
          parameters: {
            connectorId: connector?.id_attrs?.type,
          },
        };

        return activeFilters.some((afilter) =>
          isEqual(afilter, filterDefinition),
        );
      })
      ?.map((connector) =>
        getConnectorDisplayConfig(connector?.id_attrs?.type as ConnectorName),
      )
      ?.filter((config) => Boolean(config?.webSearchBaseUrl));
    setCannedResultConfigs(connectorConfigs);
  }, [activeFilters, connectors]);

  return (
    <div className={styles.container}>
      <Error
        title={i18n.t('no_search_results_title')}
        subtitle={i18n.t('no_search_results_subtitle')}
        hideAction={activeFilters.length === 0}
        actionText={i18n.t('clear_all_filters')}
        onActionClick={resetAllFilters}
      />
      <div className={styles.moreActions}>
        {cannedResultConfigs.length > 0 && (
          <Text isBold color="faint">
            {i18n.t('more_actions_title')}
          </Text>
        )}
        {cannedResultConfigs.map((config) => {
          const Icon = config.icon;
          const handleOpen = () => {
            openURL(`${config.webSearchBaseUrl}${query}`);
          };
          return (
            <div
              tabIndex={0}
              className={styles.cannedResultRow}
              role="link"
              aria-label={i18n.t('aria_search_results_more_action', {
                query,
                displayName: config.displayName,
              })}
              key={config.connectorName}
              onClick={handleOpen}
              onKeyDown={(e) => {
                if (e.key === KeyCodes.enter) {
                  handleOpen();
                }
              }}
            >
              <Icon size={36} />
              <Text size="large">{`Search for "${query}" in ${config.displayName}...`}</Text>
            </div>
          );
        })}
      </div>
    </div>
  );
}
