import {
  ContentIcon,
  type ContentIconProps,
} from '@dropbox/dash-component-library';
import { UIIcon } from '@dropbox/dig-icons';
import { ComputerLine } from '@dropbox/dig-icons/dist/mjs/assets';
import {
  BrandedSiteIcon,
  DesktopFileIcon,
  FileTypeIcon,
  SearchResultIcon,
} from '@mirage/shared/icons';
import { isDesktopLocalFile } from '../search/search-result';
import styles from './FileContentIcon.module.css';

import type { SearchResult } from '@mirage/service-dbx-api';
import type { RecentConnectorContent } from '@mirage/service-recent-content/types';
import type { Recommendation } from '@mirage/shared/search/recommendation';

type IconContent = SearchResult | RecentConnectorContent | Recommendation;

type Props = {
  content: IconContent;
  size?: ContentIconProps['size'];
  icon?: React.ReactNode;
  badge?: React.ReactNode;
};

// type guard for SearchResult
function isSearchResult(content: IconContent): content is SearchResult {
  return (content as SearchResult)?.recordType !== undefined;
}

type IconProps = Pick<Props, 'badge' | 'content' | 'icon'>;
function getIconProps({ icon, badge, content }: IconProps): {
  icon: React.ReactNode;
  badgeIcon?: React.ReactNode;
  shouldConstrainContent?: boolean;
} {
  if (icon) {
    return {
      icon: icon,
      badgeIcon: badge ?? <SearchResultIcon size="100%" result={content} />,
    };
  }

  if (content?.fileTypeInfo?.icon) {
    return {
      icon: <FileTypeIcon result={content} size="100%" />,
      badgeIcon: badge ?? <SearchResultIcon size="100%" result={content} />,
    };
  }

  if (content?.brandedSiteInfo?.icon) {
    return {
      icon: <BrandedSiteIcon size="100%" result={content} />,
      badgeIcon: badge ?? <SearchResultIcon size="100%" result={content} />,
    };
  }

  if (isSearchResult(content) && isDesktopLocalFile(content)) {
    return {
      icon: <DesktopFileIcon size="100%" result={content} />,
      badgeIcon: badge ?? (
        <UIIcon className={styles.badgeIcon} src={ComputerLine} />
      ),
      shouldConstrainContent: false,
    };
  }

  return {
    icon: <SearchResultIcon size="100%" result={content} />,
  };
}

export function FileContentIcon({
  content,
  size = 'medium',
  icon,
  badge,
}: Props) {
  const {
    badgeIcon,
    icon: iconProp,
    shouldConstrainContent = true,
  } = getIconProps({ content, icon, badge });
  return (
    <ContentIcon
      icon={iconProp}
      badgeIcon={badgeIcon}
      shouldConstrainContent={shouldConstrainContent}
      hasBackground={true}
      size={size}
    />
  );
}
