import { Text } from '@dropbox/dig-components/typography';
import { LaunchMethod } from '@mirage/analytics/events/enums/launch_method';
import {
  DATA_ATTRIBUTE_SELECTED,
  DATA_ATTRIBUTE_SELECTION_ID,
} from '@mirage/mosaics/GlobalNav/KeyboardNavigation';
import { TitleHighlighter } from '@mirage/search/SearchResults/TitleHighlighter';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { copyToClipboard } from '@mirage/service-platform-actions';
import { mouseActivityAtom } from '@mirage/shared/hooks/useInitDetectMouseActivity';
import { hotkeys } from '@mirage/shared/hotkeys';
import {
  getKeyMaps,
  getMousetrapMap,
  getOsModifierKeys,
} from '@mirage/shared/hotkeys/hotkeysKeyMap';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ResultActions } from './ResultActions';
import styles from './ResultItem.module.css';

import type { DropdownItem } from '@mirage/search/General/Dropdown';
import type { DisplayAction } from '@mirage/search/SearchResults/ResultRow';
import type { Handler, Handlers } from '@mirage/shared/hotkeys';

type ResultItemProps = {
  title: string;
  query?: string;
  selectionId: string;
  subtext?: React.ReactNode;
  bodyContent?: React.ReactNode;
  icon: React.ReactNode;
  selected?: boolean;
  onSelectItem: (selectionId: string) => void;
  onLaunch: (launchMethod: LaunchMethod) => void;
  onShown?: () => void;
  displayedActions: DisplayAction[];
  dropdownActions?: Array<Array<DropdownItem>>;
  colorVariant?: 'normal' | 'subtle';
  onOpenDropdown?: (isOpen: boolean) => void;
  peekResultActions?: boolean;
  debugUuid?: string;
  stringForClipboard?: string; // If defined, user can copy this string to clipboard by pressing CMD+C on the result.
  onCopy?: () => void; // Callback when CMD+C is pressed on the result.
};

export const ResultItem = ({
  stringForClipboard,
  selectionId,
  title,
  subtext,
  bodyContent,
  icon,
  selected,
  onSelectItem,
  onLaunch,
  onShown,
  displayedActions,
  dropdownActions,
  colorVariant = 'normal',
  onOpenDropdown,
  peekResultActions = false,
  debugUuid,
  onCopy,
}: ResultItemProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [hovered, setHovered] = useState(false);
  const [hasBodyContent, setHasBodyContent] = useState(true);
  const firedShownCallback = useRef(false);
  const isMouseActive = useAtomValue(mouseActivityAtom);
  const isMobileSize = useIsMobileSize();
  const showHoverState = isMouseActive && hovered;
  const showResultActions =
    (peekResultActions || selected || showHoverState) && !isMobileSize;
  const isDesktop = EnvCtx.surface === 'desktop';
  const platform = EnvCtx.platform;

  // Monitor when result item comes into view and fire onShown callback
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !firedShownCallback.current) {
        firedShownCallback.current = true;
        onShown?.();
      }
    });

    const containerElement = containerRef.current;
    if (containerElement) {
      observer.observe(containerElement);
    }
    return () => {
      if (containerElement) {
        observer.unobserve(containerElement);
      }
    };
  }, [containerRef, onShown]);

  useEffect(() => {
    const element = bodyRef.current;
    const checkHeight = () => {
      if (element) {
        const height = element.offsetHeight;
        setHasBodyContent(height > 0);
      }
    };
    checkHeight();
    const resizeObserver = new ResizeObserver(() => {
      checkHeight();
    });

    if (element) {
      resizeObserver.observe(element);
    }
    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, [bodyRef]);

  const handleOpenItem = useCallback(
    (launchMethod: LaunchMethod) => {
      onLaunch(launchMethod);
      onSelectItem(selectionId);
    },
    [onLaunch, onSelectItem, selectionId],
  );

  const moveUpOrDown = useCallback<Handler>(() => {
    const container = containerRef?.current;
    const { activeElement } = document;
    if (container?.contains(activeElement) && container !== activeElement) {
      container?.focus();
    }
  }, []);

  const copy = useCallback<Handler>(() => {
    if (stringForClipboard) {
      copyToClipboard(stringForClipboard);
      showSnackbar({ title: i18n.t('copied_link_to_clipboard') });
    }

    onCopy?.();
  }, [stringForClipboard, onCopy]);

  const launch = useCallback<Handler>(() => {
    const container = containerRef?.current;
    const { activeElement } = document;
    // ensure focus isn't on result action or dropdown
    if (container === activeElement) {
      handleOpenItem('enter');
    }
  }, [handleOpenItem]);

  const keyHandlers = useMemo<Handlers<'resultItem'>>(
    () => ({
      moveUp: moveUpOrDown,
      moveDown: moveUpOrDown,
      launch,
      copy,
    }),
    [moveUpOrDown, copy, launch],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const keyMap = getKeyMaps(platform).resultItem;
      const osModifiers = getOsModifierKeys(platform);
      const mapEventToMousetrap = getMousetrapMap(platform);

      hotkeys(event, keyMap, keyHandlers, {
        osModifiers,
        mapEventToMousetrap,
      });
    },
    [keyHandlers, platform],
  );

  const dataAttributes = {
    [DATA_ATTRIBUTE_SELECTION_ID]: selectionId,
    [DATA_ATTRIBUTE_SELECTED]: selected,
  };

  return (
    <div
      tabIndex={0}
      role="link"
      ref={containerRef}
      data-x-uuid={debugUuid}
      className={classNames(
        styles.container,
        'cursor-override', // Allows hornet to override the cursor behavior
        {
          [styles.selected]: selected,
          [styles.colorSubtle]: colorVariant === 'subtle',
          [styles.hovered]: showHoverState,
          [styles.isMobile]: isMobileSize,
          [styles.isDesktop]: isDesktop,
        },
      )}
      onMouseOver={() => setHovered(true)}
      onFocus={(e) => {
        // If the container gains focus, mark it as selected for keyboard nav
        if (containerRef.current === e.target) {
          onSelectItem(selectionId);
        }
      }}
      onMouseLeave={() => setHovered(false)}
      onClick={() => handleOpenItem('click')}
      onKeyDown={handleKeyDown}
      {...dataAttributes}
    >
      <div className={styles.topRow}>
        <div
          className={classNames(styles.iconContainer, {
            [styles.colorSubtle]: colorVariant === 'subtle',
          })}
        >
          {icon}
        </div>
        <div className={styles.textContainer}>
          <Text
            isBold={true}
            variant="label"
            size="medium"
            className={classNames(styles.titleContainer, {
              [styles.colorSubtle]: colorVariant === 'subtle',
            })}
          >
            <TitleHighlighter textSize="medium" title={title} />
          </Text>
          {subtext}
        </div>
        <ResultActions
          displayedActions={displayedActions}
          dropdownActions={dropdownActions}
          isResultSelected={selected}
          onOpenDropdown={onOpenDropdown}
          showResultActions={showResultActions}
        />
      </div>

      <div ref={bodyRef}>
        {bodyContent && (
          <div
            className={classNames(styles.snippetText, {
              [styles.removeMargins]: !hasBodyContent,
            })}
          >
            {bodyContent}
          </div>
        )}
      </div>

      {isMobileSize && (
        <ResultActions
          displayedActions={displayedActions}
          dropdownActions={dropdownActions}
          onOpenDropdown={onOpenDropdown}
          isResultSelected={selected}
        />
      )}
    </div>
  );
};
