import {
  StackFilterOption,
  StackSortPreference,
} from '@mirage/service-settings/service/types';
import useSettings from '@mirage/service-settings/useSettings';
import { atom, useAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';

const sortPreferenceAtom = atom<StackSortPreference | undefined>(undefined);
const filterPreferenceAtom = atom<StackFilterOption | undefined>(undefined);

export const useStackPagePreferences = () => {
  const { settings, set: setSettings } = useSettings([
    'stackPageSortPreference',
    'stackPageFilterPreference',
  ]);
  const {
    stackPageSortPreference: sortPreference,
    stackPageFilterPreference: filterPreference,
  } = settings ?? {};

  useHydrateAtoms([
    [sortPreferenceAtom, sortPreference],
    [filterPreferenceAtom, filterPreference],
  ]);

  const [stackPageSortPreference, _setStackPageSortPreference] =
    useAtom(sortPreferenceAtom);
  const [stackPageFilterPreference, _setStackPageFilterPreference] =
    useAtom(filterPreferenceAtom);

  const setStackPageSortPreference = (sortPreference: StackSortPreference) => {
    _setStackPageSortPreference(sortPreference);
    setSettings('stackPageSortPreference', sortPreference);
  };

  const setStackPageFilterPreference = (
    filterPreference: StackFilterOption,
  ) => {
    _setStackPageFilterPreference(filterPreference);
    setSettings('stackPageFilterPreference', filterPreference);
  };

  return {
    stackPageSortPreference,
    setStackPageSortPreference,
    stackPageFilterPreference,
    setStackPageFilterPreference,
  };
};
