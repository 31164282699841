import { LabelGroup, Metadata } from '@dropbox/dash-component-library';
import { Text } from '@dropbox/dig-components/typography';
import { Split } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { DashCard } from '@mirage/dash-component-library/components/DashCard';
import { DashStackIcon } from '@mirage/dash-component-library/components/DashStackIcon';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import { getTimeAgoString } from '@mirage/shared/util/time';
import i18n from '@mirage/translations';
import { WorkingSetCardData } from '@mirage/working-set';
import styles from './AllStacksList.module.css';

export const AllStacksListItem = ({
  workingSetCardData,
}: {
  workingSetCardData: WorkingSetCardData;
}) => {
  const isMobileSize = useIsMobileSize();
  const onClick = workingSetCardData.onClickCard;
  return (
    <DashCard breakout="xsmall" isLink>
      <div className={styles.allStacksListItem}>
        <div
          role="button"
          tabIndex={0}
          onClick={onClick}
          onKeyDown={onKeyDownCommitFn(onClick)}
          className={styles.allStacksListItemContents}
        >
          <Split
            alignY="center"
            className={styles.fullWidth}
            direction={isMobileSize ? 'vertical' : 'horizontal'}
            gap={'Micro Large'}
          >
            <Split.Item marginRight="auto">
              <StacksMetadata workingSetCardData={workingSetCardData} />
            </Split.Item>
            <Split.Item>
              {workingSetCardData.memberCount > 1 ? (
                workingSetCardData.getMembersComponent('small')
              ) : (
                <Text color="subtle" className={styles.accessIcon}>
                  <UIIcon src={workingSetCardData.accessLevelIcon} />
                  {workingSetCardData.accessLevel}
                </Text>
              )}
            </Split.Item>
          </Split>
        </div>
        <div className={styles.actionMenuComponent}>
          {workingSetCardData.actionMenuComponent}
        </div>
      </div>
    </DashCard>
  );
};

const StacksMetadata = ({
  workingSetCardData,
}: {
  workingSetCardData: WorkingSetCardData;
}) => {
  // first check for maxHlcMs (includes the time even links were added), then fallback to lastModifiedMs
  const updatedAtMs =
    (workingSetCardData.maxHlcMs || workingSetCardData.lastModifiedMs) ?? 0;
  return (
    <LabelGroup
      accessoryGap="Micro XLarge"
      verticalAlignment="top"
      withLabel={
        <Text size="medium" isBold>
          <DashTruncateWithTooltip
            lines={3}
            tooltipProps={{
              title: workingSetCardData.title,
            }}
          >
            {workingSetCardData.title}
          </DashTruncateWithTooltip>
        </Text>
      }
      withMeta={
        <Metadata>
          <Metadata.Item>
            <Metadata.Label>
              {i18n.t('num_items', {
                count: workingSetCardData.links.length,
              })}
            </Metadata.Label>
          </Metadata.Item>
          {Boolean(updatedAtMs) && (
            <Metadata.Item>
              <Metadata.Label>
                {i18n.t('updated_ago', {
                  timeAgo: getTimeAgoString(updatedAtMs),
                })}
              </Metadata.Label>
            </Metadata.Item>
          )}
        </Metadata>
      }
      withStartAccessory={
        <DashStackIcon
          size="xlarge"
          colorIndex={workingSetCardData.colorIndex}
          emoji={workingSetCardData.emoji}
        />
      }
    />
  );
};
