import { Button } from '@dropbox/dig-components/buttons';
import { createUxaElementId } from '@mirage/analytics/uxa';
import i18n from '@mirage/translations';
import { useResizeObserver } from '@react-hookz/web';
import { useRef, useState } from 'react';
import { Source } from './Source';
import styles from './Sources.module.css';
import { AnswersActionComponent } from './types';

import type { SearchResult } from '@mirage/service-dbx-api';

const INITIAL_SOURCES_NUM = 4;

type SourcesProps = {
  sources?: SearchResult[];
  onSourceClick: (result: SearchResult) => void;
  actionSurfaceComponent?: AnswersActionComponent;
};

export const Sources: React.FC<SourcesProps> = ({
  sources,
  onSourceClick,
  actionSurfaceComponent,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showAll, setShowAll] = useState(false);
  const [containerSize, setContainerSize] = useState<'small' | 'large'>(
    'large',
  );

  useResizeObserver(containerRef, (entry) => {
    setContainerSize(entry.contentRect.width < 500 ? 'small' : 'large');
  });

  if (!sources) {
    return null;
  }

  const visibleSources = showAll
    ? sources
    : sources.slice(0, INITIAL_SOURCES_NUM);

  return (
    <div className={styles.sourcesContainer} ref={containerRef}>
      {visibleSources.map((source, index) => (
        <Source
          key={index}
          source={source}
          onClick={onSourceClick}
          sourceMaxWidth={
            containerSize === 'small'
              ? '100%'
              : 'calc(50% - var(--spacing__unit--1))'
          }
        />
      ))}
      {sources.length > INITIAL_SOURCES_NUM && !showAll && (
        <div className={styles.showAllContainer}>
          <Button
            onClick={() => setShowAll(true)}
            aria-expanded={showAll}
            variant="transparent"
            size="small"
            data-uxa-log={createUxaElementId('show_all_sources_button', {
              actionSurfaceComponent,
              featureLine: 'answers',
            })}
          >
            {i18n.t('show_all_sources')}
          </Button>
        </div>
      )}
      {sources.length > INITIAL_SOURCES_NUM && showAll && (
        <div
          aria-live="polite"
          aria-label={i18n.t('aria_answers_announce_show_all_sources')}
        />
      )}
    </div>
  );
};
