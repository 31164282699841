import { useEffect } from "react";

import { extensionConnectedStateAtom } from "@mirage/shared/atoms/extensionConnection";
import { useAtomValue } from "jotai";

import { useConnectToExtension } from "../helpers/extensionConnector";

export const ExtensionConnector: React.FC = () => {
  const connectToExtension = useConnectToExtension();
  const extensionConnection = useAtomValue(extensionConnectedStateAtom);

  useEffect(() => {
    const connectIfDisconnected = () => {
      if (!extensionConnection.connected) {
        connectToExtension();
      }
    };

    connectIfDisconnected();
    // Try to connect to the extension every minute.
    const intervalId = setInterval(connectIfDisconnected, 60_000);

    return () => {
      clearInterval(intervalId);
    };
  }, [connectToExtension, extensionConnection.connected]);

  return <></>;
};
