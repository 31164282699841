import { Button, IconButton } from '@dropbox/dig-components/buttons';
// eslint-disable-next-line no-restricted-imports
import { Tooltip } from '@dropbox/dig-components/tooltips';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine } from '@dropbox/dig-icons/dist/mjs/assets';
import i18n from '@mirage/translations';
import * as React from 'react';
import styles from './TourTooltip.module.css';

export type TourStep = {
  key: string;
  stepNumber: number;
  title: string;
  message: string;
};

type Props = {
  currentStep: TourStep | undefined;
  totalSteps: number;
  triggerRef: React.RefObject<HTMLElement | Element>;
  onClose: () => void;
  onSkip: () => void;
  onNext: () => void;
  onPrevious: () => void;
};

export const TourTooltip = ({
  currentStep,
  totalSteps,
  triggerRef,
  onClose,
  onSkip,
  onNext,
  onPrevious,
}: Props) => {
  if (!currentStep) return null;
  return (
    <Tooltip.Control
      variant="rich"
      open={true}
      triggerRef={triggerRef}
      auto
      placement="bottom"
      className={styles.tourTooltip}
      maxWidth={300}
    >
      <Text size="large" isBold>
        {currentStep.title}
      </Text>
      <IconButton
        variant="borderless"
        onClick={onClose}
        className={styles.dismissButton}
        aria-label={i18n.t('close')}
      >
        <UIIcon src={CloseLine} />
      </IconButton>
      <Text tagName="p" size="medium">
        {currentStep.message}
      </Text>
      <div className={styles.actionRow}>
        <Text className={styles.stepCount}>
          {i18n.t('query_suggestion_count', {
            startIndex: currentStep.stepNumber,
            endIndex: totalSteps,
          })}
        </Text>

        {currentStep.stepNumber === 1 ? (
          <Button
            variant="borderless"
            className={styles.backBtn}
            onClick={onSkip}
          >
            {i18n.t('skip_tour')}
          </Button>
        ) : (
          <Button
            variant="borderless"
            className={styles.backBtn}
            onClick={onPrevious}
          >
            {i18n.t('back')}
          </Button>
        )}

        <Button variant="outline" onClick={onNext}>
          {i18n.t(
            currentStep.stepNumber === totalSteps ? 'done_tour' : 'next_tour',
          )}
        </Button>
      </div>
    </Tooltip.Control>
  );
};
