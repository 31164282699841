import { Chip } from '@dropbox/dig-components/chip';
import { UIIcon } from '@dropbox/dig-icons';
import { CheckmarkLine, ClockLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { DashFilterName } from '@mirage/analytics/events/enums/dash_filter_name';
import { PAP_Select_DashFilter } from '@mirage/analytics/events/types/select_dash_filter';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { Dropdown } from '@mirage/search/General/Dropdown';
import {
  LastUpdatedFilter,
  SearchFilter,
  SearchFilterType,
} from '@mirage/shared/search/search-filters';
import i18n from '@mirage/translations';
import { endOfDay, format, startOfDay, sub } from 'date-fns';
import { useState } from 'react';
import { LastUpdatedFilterModal } from './LastUpdatedFilterModal';

// Type only import will not affect tree-shaking.
// eslint-disable-next-line no-restricted-imports
import type {
  DatePickerOnSelectionOptions,
  DatePickerRangeSelection,
  DatePickerSelection,
} from '@dropbox/dig-components/dist/date_picker/typings';

type Props = {
  selected: boolean;
  onSelect: (filter: LastUpdatedFilter | undefined) => void;
  activeUpdatedAtFilter: SearchFilter | undefined;
};

type LastUpdatedFilterConfig = {
  key: string;
  title: string;
  onClick: () => void;
  selected: boolean;
  uxaAnalyticsId: string;
};

export const enum LastUpdatedFilterKeys {
  Any_Date = 'any-date',
  Last_24_Hours = 'last-24-hours',
  Last_7_Days = 'last-7-days',
  Last_30_Days = 'last-30-days',
  Last_90_Days = 'last-90-days',
  Last_12_Months = 'last-12-months',
  Custom = 'custom',
}

export function LastUpdatedFilterDropdown({
  selected,
  onSelect,
  activeUpdatedAtFilter,
}: Props) {
  const now = new Date();

  // UI state management
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { reportPapEvent, searchAttemptSessionManager, searchSessionManager } =
    useMirageAnalyticsContext();

  // custom date picker range state and handlers
  const DEFAULT_CUSTOM_START_DATE = sub(now, { weeks: 1 });
  const DEFAULT_CUSTOM_END_DATE = now;

  const [customDateRange, setCustomDateRange] =
    useState<DatePickerRangeSelection>([
      DEFAULT_CUSTOM_START_DATE,
      DEFAULT_CUSTOM_END_DATE,
    ]);

  const handleRangeSelection = ({
    startDate,
    endDate,
  }: DatePickerOnSelectionOptions) => {
    setCustomDateRange([startDate, endDate]);
  };

  const handleRangeStartChange = (date: DatePickerSelection) => {
    setCustomDateRange([date, customDateRange[1]]);
  };

  const handleRangeEndChange = (endDate: DatePickerSelection) => {
    setCustomDateRange([customDateRange[0], endDate]);
  };

  const onModalSubmit = () => {
    logFilterSelected('date_custom');
    onSelect({
      id: 'custom',
      type: SearchFilterType.LastUpdated,
      parameters: {
        start: startOfDay(customDateRange[0] || DEFAULT_CUSTOM_START_DATE),
        end: endOfDay(customDateRange[1] || DEFAULT_CUSTOM_END_DATE),
      },
    });

    setIsModalOpen(false);
  };

  const logFilterSelected = (dashFilterName: DashFilterName) => {
    let isTypeahead = false;
    let hasQuery = false;
    searchSessionManager.extendOrCreateSession('select_filter');
    const searchAttemptSession = searchAttemptSessionManager.getSession();
    if (searchAttemptSession) {
      const query = searchAttemptSession?.properties.query;
      hasQuery = query?.length > 0;
      isTypeahead = !!searchAttemptSession?.properties.isTypeahead;
      if (hasQuery) {
        searchSessionManager.updateProperties({
          hasQuery: true,
        });
      }
    }

    reportPapEvent(
      PAP_Select_DashFilter({
        dashFilterName,
        dashFilterType: 'last_updated',
        hasQuery,
        isTypeahead,
        featureLine: 'search',
        actionSurface: 'serp',
        actionSurfaceComponent: 'search_bar',
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      }),
    );
  };

  // time filter config definitions
  const filters: LastUpdatedFilterConfig[] = [
    {
      key: LastUpdatedFilterKeys.Any_Date,
      title: i18n.t('last_updated_filter_any_date'),
      onClick: () => {
        onSelect(undefined); // clear the filter
        logFilterSelected('date_any_date');
      },
      selected: activeUpdatedAtFilter === undefined, // i.e., no filter applied
      uxaAnalyticsId: createUxaElementId('filter_date_any_date', {
        actionSurfaceComponent: 'filter_chip',
        featureLine: 'search',
      }),
    },
    {
      key: LastUpdatedFilterKeys.Last_24_Hours,
      title: i18n.t('last_updated_filter_last_24_hours'),
      onClick: () => {
        logFilterSelected('date_last_24_hours');
        onSelect({
          id: LastUpdatedFilterKeys.Last_24_Hours,
          type: SearchFilterType.LastUpdated,
          parameters: {
            start: sub(now, { hours: 24 }),
            end: now,
          },
        });
      },
      selected:
        activeUpdatedAtFilter?.id === LastUpdatedFilterKeys.Last_24_Hours,
      uxaAnalyticsId: createUxaElementId('filter_date_last_24_hours', {
        actionSurfaceComponent: 'filter_chip',
        featureLine: 'search',
      }),
    },
    {
      key: LastUpdatedFilterKeys.Last_7_Days,
      title: i18n.t('last_updated_filter_last_7_days'),
      onClick: () => {
        logFilterSelected('date_last_7_days');
        onSelect({
          id: LastUpdatedFilterKeys.Last_7_Days,
          type: SearchFilterType.LastUpdated,
          parameters: {
            start: sub(now, { days: 7 }),
            end: now,
          },
        });
      },
      selected: activeUpdatedAtFilter?.id === LastUpdatedFilterKeys.Last_7_Days,
      uxaAnalyticsId: createUxaElementId('filter_date_last_7_days', {
        actionSurfaceComponent: 'filter_chip',
        featureLine: 'search',
      }),
    },
    {
      key: LastUpdatedFilterKeys.Last_30_Days,
      title: i18n.t('last_updated_filter_last_30_days'),
      onClick: () => {
        logFilterSelected('date_last_30_days');
        onSelect({
          id: LastUpdatedFilterKeys.Last_30_Days,
          type: SearchFilterType.LastUpdated,
          parameters: {
            start: sub(now, { days: 30 }),
            end: now,
          },
        });
      },
      selected:
        activeUpdatedAtFilter?.id === LastUpdatedFilterKeys.Last_30_Days,
      uxaAnalyticsId: createUxaElementId('filter_date_last_30_days', {
        actionSurfaceComponent: 'filter_chip',
        featureLine: 'search',
      }),
    },
    {
      key: LastUpdatedFilterKeys.Last_90_Days,
      title: i18n.t('last_updated_filter_last_90_days'),
      onClick: () => {
        logFilterSelected('date_last_90_days');
        onSelect({
          id: LastUpdatedFilterKeys.Last_90_Days,
          type: SearchFilterType.LastUpdated,
          parameters: {
            start: sub(now, { days: 90 }),
            end: now,
          },
        });
      },
      selected:
        activeUpdatedAtFilter?.id === LastUpdatedFilterKeys.Last_90_Days,
      uxaAnalyticsId: createUxaElementId('filter_date_last_90_days', {
        actionSurfaceComponent: 'filter_chip',
        featureLine: 'search',
      }),
    },
    {
      key: LastUpdatedFilterKeys.Last_12_Months,
      title: i18n.t('last_updated_filter_last_12_months'),
      onClick: () => {
        logFilterSelected('date_last_12_months');
        onSelect({
          id: LastUpdatedFilterKeys.Last_12_Months,
          type: SearchFilterType.LastUpdated,
          parameters: {
            start: sub(now, { months: 12 }),
            end: now,
          },
        });
      },
      selected:
        activeUpdatedAtFilter?.id === LastUpdatedFilterKeys.Last_12_Months,
      uxaAnalyticsId: createUxaElementId('filter_date_last_12_months', {
        actionSurfaceComponent: 'filter_chip',
        featureLine: 'search',
      }),
    },
    {
      key: LastUpdatedFilterKeys.Custom,
      title: i18n.t('last_updated_filter_custom'),
      onClick: () => setIsModalOpen(true),
      selected: activeUpdatedAtFilter?.id === LastUpdatedFilterKeys.Custom,
      uxaAnalyticsId: createUxaElementId('filter_date_custom', {
        actionSurfaceComponent: 'filter_chip',
        featureLine: 'search',
      }),
    },
  ];

  // filter chip labeling based on selected filter
  const filter = filters.find(({ selected }) => selected);
  const initialLabel = filter?.title || i18n.t('last_updated_filter_title');
  let label = initialLabel;
  if (filter?.key === 'any-date') label = i18n.t('last_updated_filter_title');
  if (filter?.key == 'custom') {
    const labelStart = format(
      customDateRange[0] || DEFAULT_CUSTOM_START_DATE,
      'M/d/yyyy',
    );
    const labelEnd = format(
      customDateRange[1] || DEFAULT_CUSTOM_END_DATE,
      'M/d/yyyy',
    );
    label = `${labelStart} - ${labelEnd}`;
  }

  return (
    <div>
      <LastUpdatedFilterModal
        selectedRange={customDateRange}
        handleSelection={handleRangeSelection}
        handleChangeStart={handleRangeStartChange}
        handleChangeEnd={handleRangeEndChange}
        isOpen={isModalOpen}
        onSubmit={onModalSubmit}
        onCancel={() => setIsModalOpen(false)}
      />
      <LastUpdatedDropdown
        selected={selected}
        label={selected ? label : i18n.t('last_updated_filter_title')}
        filters={filters}
        accessoryIcon={
          <Chip.IconAccessory>
            <UIIcon src={ClockLine} />
          </Chip.IconAccessory>
        }
      />
    </div>
  );
}

type LastUpdatedDropdownProps = {
  selected?: boolean;
  label: string;
  filters: LastUpdatedFilterConfig[];
  accessoryIcon?: React.ReactNode;
};

const LastUpdatedDropdown = ({
  selected = false,
  label,
  filters,
  accessoryIcon = null,
}: LastUpdatedDropdownProps) => {
  return (
    <Dropdown
      minWidth={200}
      anchor={
        <Chip
          withDropdownIcon={true}
          isSelected={selected}
          selectedStyle="stroke"
          data-uxa-log={createUxaElementId('filter_last_updated', {
            actionSurfaceComponent: 'filter_chip',
            featureLine: 'search',
          })}
        >
          {accessoryIcon}
          <Chip.Content>{label}</Chip.Content>
        </Chip>
      }
      placement={'bottom-start'}
      config={{
        sections: [
          filters.map((filter) => ({
            key: filter.key,
            title: filter.title,
            leftAccessory: filter.selected ? (
              <UIIcon src={CheckmarkLine} />
            ) : (
              <></>
            ),
            onClick: filter.onClick,
            analyticsAttr: { 'data-uxa-log': filter.uxaAnalyticsId },
          })),
        ],
      }}
      closeOnMouseLeave={true}
    />
  );
};
