import { dash } from '@dropbox/api-v2-client';
import { getEventTimeString } from '@mirage/shared/util/calendar';
import i18n from '@mirage/translations';
import {
  add,
  differenceInHours,
  differenceInMinutes,
  format,
  isBefore,
  isFuture,
  isPast,
  isSameDay,
  isSameMinute,
  isSameMonth,
  startOfDay,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import escapeRegExp from 'lodash.escaperegexp';
import {
  ALL_DAY_EVENT_THRESHOLD,
  ALL_DAY_TITLE_BLOCKLIST,
  UPCOMING_EVENT_THRESHOLD,
} from './constants';

export type CalendarEvent = {
  uuid: string;
  startTime: number;
  endTime: number;
  allDay: boolean;
  title: string;
  description: {
    contentType: 'text/html' | 'text/plain';
    content: string;
  };
  attachments: dash.EventAttachment[];
  attendees: dash.Author[];
  attendeesCount: number;
  calendarUrl?: string;
  meetingUrl?: string;
};

export function convertToCalendarEvents(
  results: dash.SearchResult[] | undefined,
): CalendarEvent[] | undefined {
  return (
    results
      // Convert to CalendarEvent
      ?.map((event) => buildCalendarEvent(event))
      // Promote long upcoming or in-progress events to all-day status
      ?.map((event) => {
        event.allDay ||= !!(
          event.startTime &&
          event.endTime &&
          event.endTime - event.startTime >= ALL_DAY_EVENT_THRESHOLD &&
          isPast(event.startTime - UPCOMING_EVENT_THRESHOLD) &&
          isFuture(event.endTime)
        );
        return event;
      })
      // Drop any "noisy" events like Working Location or Out-of-office blocks
      ?.filter((event) => {
        return (
          !event.allDay ||
          !ALL_DAY_TITLE_BLOCKLIST.some((title) => event.title === title)
        );
      })
      // Sort the events by all-day status, start-time, then meeting length
      ?.sort(compareCalendarEvents)
  );
}

function buildCalendarEvent(event: dash.SearchResult): CalendarEvent {
  const [startTime, endTime] = event.is_all_day
    ? fixAllDayTimes(event.start_time || 0, event.end_time || 0)
    : [event.start_time || 0, event.end_time || 0];
  return {
    uuid: event.uuid || '',
    startTime: startTime,
    endTime: endTime,
    allDay: event.is_all_day || false,
    title: event.title || '',
    description: event.description
      ? {
          contentType: 'text/html',
          content: getDescription(event.title || '', event.description),
        }
      : {
          contentType: 'text/plain',
          content: event.highlights?.body?.text?.[0] || '',
        },

    attachments: event.attachments ? event.attachments : [],
    attendees: event.attendees || [],
    attendeesCount: event.attendees_count || 0,
    calendarUrl: event.url,
    meetingUrl: event.conference_links?.[0]?.link,
  };
}

// TODO: Temporary fix until https://jira.dropboxer.net/browse/OTCIP-46 is
// resolved and the API provides just the date for these all-day events.
export function fixAllDayTimes(
  startTime: number,
  endTime: number,
): [number, number] {
  return [
    utcToZonedTime(startTime, 'UTC').getTime(),
    utcToZonedTime(endTime, 'UTC').getTime(),
  ];
}

function getDescription(title: string, description: string): string {
  // Connectors prefixes the event description with the title, followed by a newline
  // This is redundant, so we strip it out here
  return description.replace(new RegExp(`^${escapeRegExp(title)}\n`), '');
}

export function compareCalendarEvents(a: CalendarEvent, b: CalendarEvent) {
  return compareAllDay(a, b) || compareStartTime(a, b) || compareEndTime(a, b);
}

function compareAllDay(a: CalendarEvent, b: CalendarEvent) {
  return a.allDay === b.allDay ? 0 : a.allDay ? -1 : 1;
}

function compareStartTime(a: CalendarEvent, b: CalendarEvent) {
  return a.startTime - b.startTime;
}

function compareEndTime(a: CalendarEvent, b: CalendarEvent) {
  if (a.endTime) {
    return b.endTime ? a.endTime - b.endTime : -1;
  }
  return b.endTime ? 1 : 0;
}

export function getShowableAttendees(
  attendees: dash.Author[],
  maxToShow: number,
): dash.Author[] {
  return attendees
    .filter(
      (attendee) =>
        attendee.profile_image_url || attendee.display_name || attendee.email,
    )
    .sort((a, b) => {
      return (
        (b.profile_image_url ? 1 : 0) - (a.profile_image_url ? 1 : 0) ||
        (b.display_name ? 1 : 0) - (a.display_name ? 1 : 0)
      );
    })
    .slice(0, maxToShow);
}

export function getNamesFromAttendees(
  shownAttendees: dash.Author[],
  numAttendees: number,
): string {
  const names = shownAttendees
    .filter((attendee) => attendee.display_name)
    .map((attendee) => (attendee.display_name as string).split(/\s+/)[0])
    .filter((name) => name); // Probably unnecessary, but just in case

  switch (names.length) {
    case 0:
      return i18n.t('num_attendees', { count: numAttendees });
    case 1:
      if (numAttendees === 1) {
        return names[0];
      } else {
        return i18n.t('one_known_attendee', {
          attendee_one: names[0],
          count: numAttendees - 1,
        });
      }
    case 2:
      if (numAttendees === 2) {
        return i18n.t('two_known_attendees', {
          attendee_one: names[0],
          attendee_two: names[1],
          count: 0,
        });
      }
      // Same as default case, but fall-throughs are prohibited
      return i18n.t('two_known_attendees', {
        attendee_one: names[0],
        attendee_two: names[1],
        count: numAttendees - 2,
      });
    default:
      return i18n.t('two_known_attendees', {
        attendee_one: names[0],
        attendee_two: names[1],
        count: numAttendees - 2,
      });
  }
}

export function getProximateTimeString(
  startTime: number,
  _endTime: number,
): string | undefined {
  const now = Date.now();
  const inProgress = isPast(startTime);
  const eventState = inProgress ? 'in_progress' : 'upcoming';
  const { hours, minutes } = inProgress
    ? {
        hours: differenceInHours(now, startTime),
        minutes:
          differenceInMinutes(now, startTime, { roundingMethod: 'floor' }) % 60,
      }
    : {
        hours: differenceInHours(startTime, now),
        minutes:
          differenceInMinutes(startTime, now, { roundingMethod: 'ceil' }) % 60,
      };
  return getEventTimeString(eventState, hours, minutes);
}

export function getTimespanString(
  startTime: number,
  endTime: number,
  isAllDay: boolean,
): string {
  const now = new Date();
  const endTimeExclusive = endTime - 1;
  const noonStartDay = add(startOfDay(startTime), { hours: 12 });
  const noonEndDay = add(startOfDay(endTimeExclusive), { hours: 12 });
  const spansNoon =
    isBefore(startTime, noonStartDay) &&
    (isSameMinute(noonEndDay, endTime) || isBefore(noonEndDay, endTime));
  const singleDay = isSameDay(startTime, endTimeExclusive);
  const singleMonth = isSameMonth(startTime, endTimeExclusive);

  if (isAllDay) {
    if (singleDay) {
      return i18n.t('all_day', {
        day: isSameDay(startTime, now)
          ? i18n.t('today')
          : format(startTime, 'LLLL do'),
      });
    } else {
      const startIsToday = isSameDay(startTime, now);
      const endIsToday = isSameDay(endTimeExclusive, now);
      return i18n.t('all_day_multi_day', {
        start: startIsToday ? i18n.t('today') : format(startTime, 'LLLL do'),
        end: endIsToday
          ? i18n.t('today')
          : format(
              endTimeExclusive,
              singleMonth && !startIsToday ? 'do' : 'LLLL do',
            ),
      });
    }
  } else {
    return `${format(startTime, spansNoon ? 'h:mmaaa' : 'h:mm')} - ${format(
      endTime,
      'h:mmaaa',
    )}`;
  }
}

export function getClickedAnchor(
  node: Node | null,
  root: Node,
): HTMLAnchorElement | null {
  while (node && node !== root) {
    if (node.nodeName === 'A') {
      return node as HTMLAnchorElement;
    }
    node = node.parentElement;
  }
  return null;
}
