import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AddLine,
  SettingsLine,
  ShareArrowLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Change_StackUserPermissions } from '@mirage/analytics/events/types/change_stack_user_permissions';
import { PAP_Send_DashShareModal } from '@mirage/analytics/events/types/send_dash_share_modal';
import { PAP_Shown_DashCard } from '@mirage/analytics/events/types/shown_dash_card';
import { DashFacepile } from '@mirage/dash-component-library/components/DashFacepile';
import { getTheme } from '@mirage/dash-component-library/themes/Stacks';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import {
  MetricPageName,
  StackDetailsModule,
} from '@mirage/service-operational-metrics/module/constants';
import { useRecordModuleLatency } from '@mirage/service-operational-metrics/module/module';
import {
  getStacksDataCachedTags,
  useStacksDataCachedTags,
} from '@mirage/service-operational-metrics/module/tags';
import { useIsPublicSharingAllowed } from '@mirage/service-stack-admin-settings/hooks';
import { previewStack } from '@mirage/service-stacks';
import {
  stackDerivePAPProps,
  stackGetShareId,
} from '@mirage/service-stacks/service/utils';
import { showSnackbar } from '@mirage/shared/snackbar';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import { membersFromStack } from '@mirage/stacks/Helpers/Utils';
import {
  addOrUpdateSharingMembers,
  getSharingMembers,
  getSharingUserContacts,
  removeSharingMember,
} from '@mirage/stacks/ShareModal/Api';
import { AvatarMember } from '@mirage/stacks/ShareModal/Avatar';
import { useShareModal } from '@mirage/stacks/ShareModal/ShareModal';
import { SharingTypeahead } from '@mirage/stacks/ShareModal/SharingTypeahead';
import {
  SharingMember,
  SharingMemberKey,
  SharingUserContact,
  SharingUserContactKey,
  StackPermission,
} from '@mirage/stacks/ShareModal/Types';
import {
  coerceStackAccessLevel,
  memberTextForStack,
  sharingMemberKeysMatch,
  stackMemberFromSharingMember,
  validateEmail,
} from '@mirage/stacks/ShareModal/Utils';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './SharingModule.module.css';

import type { stacks } from '@dropbox/api-v2-client/types';

const featureLine = 'stacks';
const actionSurfaceComponent = 'sharing_module';

type SharingModuleProps = {
  stack: stacks.Stack;
};

export const SharingModule: React.FC<SharingModuleProps> = ({ stack }) => {
  const [sharingContacts, setSharingContacts] = useState<SharingUserContact[]>(
    [],
  );

  const { reportPapEvent } = useMirageAnalyticsContext();
  const isPublicSharingAllowed = useIsPublicSharingAllowed();
  const currentAccount = useDropboxAccount();
  const [shareText, setShareText] = useState('');
  const [isSharingViaTextField, setIsSharingViaTextField] = useState(false);
  const [membersBeingSharedWith, setMembersBeingSharedWith] = useState<
    SharingMemberKey[]
  >([]);
  const [membersThatWereSharedWith, setMembersThatWereSharedWith] = useState<
    SharingMemberKey[]
  >([]);
  const { openShareModal, shareModalJsx } = useShareModal({
    stack,
  });
  const hasLoggedImpression = useRef(false);
  const groupSharingEnabled =
    useFeatureFlagValue('dash_2024_06_19_share_stacks_with_groups') === 'ON';

  const members = membersFromStack(stack);

  const namespaceId = useMemo(
    () => nonNil(stack?.namespace_id, 'namespace_id'),
    [stack],
  );
  const shareId = useMemo(
    () => nonNil(stackGetShareId(stack), 'shareId'),
    [stack],
  );
  const reloadStack = useCallback(() => {
    setTimeout(() => previewStack(shareId, { refresh: true }), 500);
  }, [shareId]);

  const markSharingModuleLoadComplete = useSharingModulePerformanceTracking();

  const handleGetSharingMembers = useCallback(
    async (searchText: string) => {
      return await getSharingMembers(searchText, groupSharingEnabled);
    },
    [groupSharingEnabled],
  );

  // Get suggestions and filter out members already on the stack or
  // members that we just shared with.
  useEffect(() => {
    getSharingUserContacts('').then((result) => {
      const suggestedContacts = result.filter(
        (contact) =>
          // Only need to check for user member type since
          // getSharingUserContacts() only returns user members.
          !stack.sharing_data?.members?.some(
            (member) =>
              member.subject?.['.tag'] === 'user' &&
              member.subject.email === contact.email,
          ) &&
          !membersThatWereSharedWith.some((member) =>
            sharingMemberKeysMatch(member, contact),
          ),
      );

      setSharingContacts(suggestedContacts);

      if (!hasLoggedImpression.current) {
        reportPapEvent(
          PAP_Shown_DashCard({
            actionSurfaceComponent,
            featureLine,
            dashCardType: 'sharing_module',
            emptyState: suggestedContacts.length === 0,
          }),
        );
        hasLoggedImpression.current = true;
      }

      // Module load is complete when the sharing contacts are ready.
      markSharingModuleLoadComplete();
    });
  }, [
    reportPapEvent,
    stack.sharing_data?.members,
    membersThatWereSharedWith,
    markSharingModuleLoadComplete,
  ]);

  const undoShare = async (member: SharingMemberKey) => {
    reportPapEvent(
      PAP_Change_StackUserPermissions({
        ...stackDerivePAPProps(stack),
        stackAccessLevel: coerceStackAccessLevel(
          stack.sharing_data?.shared_link?.access_level,
        ),
        featureLine,
        actionSurfaceComponent,
        isPublicSharingAllowed: isPublicSharingAllowed,
        stackSharedUserPermissionType: 'remove',
      }),
    );

    const response = await removeSharingMember(namespaceId, member);

    setMembersThatWereSharedWith((currentMembers) =>
      currentMembers.filter(
        (currentMember) => !sharingMemberKeysMatch(currentMember, member),
      ),
    );

    if (response) {
      reloadStack();
    }
  };

  const shareStack = async (
    member: SharingMember | SharingUserContactKey,
    fromTypeahead: boolean,
  ) => {
    if (member['.tag'] === 'user' && !validateEmail(member.email)) {
      return;
    }

    if (fromTypeahead) {
      setIsSharingViaTextField(true);
    } else {
      setMembersBeingSharedWith((currentMembers) => [
        ...currentMembers,
        member,
      ]);
    }
    reportPapEvent(
      PAP_Send_DashShareModal({
        ...stackDerivePAPProps(stack),
        stackEmailNotificationsOn: true,
        stackPermissions: 'editor',
        actionSurfaceComponent,
        featureLine,
        isPublicSharingAllowed: isPublicSharingAllowed,
        stackSendShareType: fromTypeahead
          ? 'entered_by_user'
          : 'suggested_collaborator',
        sharedWithGroup: member['.tag'] === 'group',
        sharedWithExternal:
          member['.tag'] === 'user' && !('displayName' in member),
      }),
    );

    try {
      const result = await addOrUpdateSharingMembers(
        namespaceId,
        [{ memberKey: member, permission: StackPermission.WRITE }],
        true,
        '',
      );

      setMembersThatWereSharedWith((currentMembers) => [
        ...currentMembers,
        member,
      ]);

      // Group add results are returned as a nested object
      const memberResult =
        member['.tag'] === 'user' ? result : result.group_result;
      if ((memberResult?.added?.length ?? 0) > 0) {
        showSnackbar({
          title: i18n.t('shared_with', {
            name: member['.tag'] === 'user' ? member.email : member.displayName,
          }),
          buttons: [
            { label: i18n.t('undo'), onClick: () => undoShare(member) },
          ],
        });
        reloadStack();
      }
    } catch {
      showSnackbar({ title: i18n.t('failed_to_add_sharing_members') });
    } finally {
      if (fromTypeahead) {
        setIsSharingViaTextField(false);
      } else {
        setMembersBeingSharedWith((currentMembers) =>
          [...currentMembers].filter(
            (currentMemmber) => !sharingMemberKeysMatch(currentMemmber, member),
          ),
        );
      }
    }
  };

  return (
    <>
      <TwoColumnGridCard
        settingId={`stack_sharing:${namespaceId}`}
        cardTypeProps={{
          title: i18n.t('share_this_stack'),
          cardType: CardHeaderType.INLINE_SUBTITLE,
          actionSurfaceComponent,
          featureLine,
        }}
        isAlwaysCollapsed={false}
        theme={getTheme(stack?.stack_data?.color_index).subtle}
        headerActions={
          <Button
            variant="primary"
            onClick={openShareModal}
            withIconStart={<UIIcon src={ShareArrowLine} />}
          >
            {i18n.t('share')}
          </Button>
        }
      >
        <div className={styles.bodyContainer}>
          <div
            className={classnames(
              styles.defaultPadding,
              styles.membersContainer,
            )}
          >
            <DashFacepile members={members} size="small" />
            <Text color="faint" size="small" className={styles.memberText}>
              {memberTextForStack(stack, currentAccount?.email ?? '')}
            </Text>
            <IconButton variant="transparent" onClick={openShareModal}>
              <UIIcon
                src={SettingsLine}
                color="var(--dig-color__text__subtle)"
              />
            </IconButton>
          </div>
          <div className={styles.textInputContainer}>
            <SharingTypeahead
              stackMemberKeys={
                stack.sharing_data?.members?.map(
                  stackMemberFromSharingMember,
                ) ?? []
              }
              onShare={([member]) => shareStack(member, true)}
              shareText={shareText}
              onShareTextChange={setShareText}
              isSharing={isSharingViaTextField}
              excludedMembers={membersThatWereSharedWith}
              getSharingMembers={handleGetSharingMembers}
              shareOnSelection={true}
            />
          </div>
          {sharingContacts.length > 0 && (
            <>
              <div className={styles.defaultPadding}>
                <Text size="small" isBold>
                  {i18n.t('suggested_collaborators')}
                </Text>
              </div>
              <div className={styles.sharingContactsContainer}>
                {sharingContacts.slice(0, 3).map((contact) => (
                  <div
                    key={contact.email}
                    className={styles.sharingContactContainer}
                    role="button"
                    tabIndex={0}
                    onClick={() => shareStack(contact, false)}
                    onKeyDown={onKeyDownCommitFn(() =>
                      shareStack(contact, false),
                    )}
                  >
                    <div className={styles.avatarNameContainer}>
                      <AvatarMember member={contact} avatarSize="small" />
                      <Text>{contact.displayName}</Text>
                    </div>
                    <div className={styles.rightDecoration}>
                      {membersBeingSharedWith.some((member) =>
                        sharingMemberKeysMatch(member, contact),
                      ) ? (
                        <Spinner size="small" />
                      ) : (
                        <UIIcon
                          src={AddLine}
                          color="var(--dig-color__text__subtle)"
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </TwoColumnGridCard>
      {shareModalJsx}
    </>
  );
};

function useSharingModulePerformanceTracking() {
  const tags = useStacksDataCachedTags();

  const { markModuleLoadComplete } = useRecordModuleLatency(
    MetricPageName.STACK_DETAILS,
    StackDetailsModule.SHARING,
    tags || getStacksDataCachedTags,
  );

  return markModuleLoadComplete;
}
