import { useOnboardingValues } from '@mirage/service-onboarding/hooks';
import { OnboardingStorage } from '@mirage/service-onboarding/service';
import { useEffect, useState } from 'react';

export function useDismissableModule(moduleName: keyof OnboardingStorage) {
  const [dismissed, setDismissed] = useState(true);
  const { getOnboardingValue, setOnboardingValue } = useOnboardingValues();

  useEffect(() => {
    const getDismissed = async () => {
      const isDismissed = await getOnboardingValue(moduleName);
      setDismissed(isDismissed as boolean);
    };
    getDismissed();
  }, [getOnboardingValue, moduleName]);

  const handleOnDismissed = (val: boolean) => {
    setOnboardingValue(moduleName, val);
    setDismissed(val);
  };

  return { dismissed, setDismissed: handleOnDismissed };
}
