import { Text } from '@dropbox/dig-components/typography';
import { LaunchMethod } from '@mirage/analytics/events/enums/launch_method';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { dispatchElementClicked } from '@mirage/analytics/uxa/dispatchElementClicked';
import { selectedItemAtom } from '@mirage/mosaics/GlobalNav/KeyboardNavigation';
import { ResultItem } from '@mirage/mosaics/Results/ResultItem';
import ResultIcon from '@mirage/search/SearchResults/ResultIcon';
import { ResultAnnotations } from '@mirage/search/SearchResults/ResultRow/ResultAnnotations';
import {
  PersonObject,
  SearchFilter,
} from '@mirage/shared/search/search-filters';
import { useAtom } from 'jotai';
import { ResultSubtitle } from '../Metadata/ResultSubtitle';
import { SnippetText } from './SnippetText';

import type { ContentIconProps } from '@dropbox/dash-component-library';
import type {
  DisplayAction,
  ResultActionConfig,
} from '@mirage/search/SearchResults/ResultRow/types';
import type { SearchResult } from '@mirage/service-dbx-api';

type ResultRowProps = {
  result: SearchResult;
  query: string;
  iconSize?: ContentIconProps['size'];
  onLaunch: DisplayAction['onClick'];
  snippets: string[];
  resultActionConfig: ResultActionConfig;
  onOpenDropdown?: (isOpen: boolean) => void;
  onCopyResult: (result: SearchResult) => void;
  onShownResult?: (result: SearchResult) => void;
  resultPosition: number;
  annotationsEnabled?: boolean;
  peekResultActionsEnabled?: boolean;
  onClickPerson?: (person: PersonObject) => void;
  activeFilters?: SearchFilter[];
};

export const ResultRow = ({
  result,
  query,
  onLaunch,
  snippets,
  resultActionConfig,
  onCopyResult,
  onShownResult,
  resultPosition,
  iconSize,
  annotationsEnabled,
  peekResultActionsEnabled,
  onClickPerson,
  activeFilters,
}: ResultRowProps) => {
  const [selectedItem, setSelectedItem] = useAtom(selectedItemAtom);
  const handleSelectItem = (selectionId: string) => {
    setSelectedItem({ selectionId });
  };

  const snippetsContent = snippets.length > 0 && (
    <SnippetText textToHighlight={snippets[0]} searchWords={query.split(' ')} />
  );

  return (
    <ResultItem
      query={query}
      title={result.title}
      selectionId={result.uuid}
      debugUuid={result.uuid}
      onCopy={() => onCopyResult(result)}
      selected={selectedItem?.selectionId === result.uuid}
      icon={<ResultIcon result={result} size={iconSize} />}
      onShown={() => onShownResult?.(result)}
      subtext={
        <div>
          <Text size="small" color="subtle">
            <ResultSubtitle
              result={result}
              onClickPerson={onClickPerson}
              activeFilters={activeFilters}
            />
          </Text>
        </div>
      }
      bodyContent={
        <div>
          <div>{snippetsContent}</div>
          {annotationsEnabled && (
            <ResultAnnotations
              result={result}
              resultPosition={resultPosition}
              query={query}
            />
          )}
        </div>
      }
      onSelectItem={handleSelectItem}
      onLaunch={(launchMethod: LaunchMethod) => {
        // Don't trigger click if user is highlighting text
        if (!window?.getSelection()?.toString()) {
          // Manually trigger UXA event
          dispatchElementClicked(
            createUxaElementId('search_result_row', {
              actionSurfaceComponent: 'result_row',
              featureLine: 'search',
            }),
            result.uuid,
            result.analyticsTraceId,
          );

          onLaunch?.(launchMethod);
        }
      }}
      displayedActions={resultActionConfig.displayActions || []}
      peekResultActions={peekResultActionsEnabled}
    />
  );
};
