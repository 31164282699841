import { IconButton } from '@dropbox/dig-components/buttons';
import { ClickOutside } from '@dropbox/dig-components/click_outside';
import { Overlay } from '@dropbox/dig-components/overlay';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Dismiss_DownloadDashUpsell } from '@mirage/analytics/events/types/dismiss_download_dash_upsell';
import i18n from '@mirage/translations';
import { DownloadDashItem } from './DownloadDashItem';
import styles from './DownloadDashUpsell.module.css';
import { DownloadDashEntry } from './useDownloadDashUpsell';

type DownloadDashUpsellProps = {
  anchor: React.RefObject<HTMLElement>;
  installers: DownloadDashEntry[];
  enabled: boolean;
  open: boolean;
  onOpen: (val: boolean) => void;
  onDismissed: (val: boolean) => void;
};

export const DownloadDashUpsell = ({
  anchor,
  installers,
  open,
  enabled,
  onOpen,
  onDismissed,
}: DownloadDashUpsellProps) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const handleClickOutside = () => {
    onOpen(false);
  };

  const handleDismissClick = () => {
    onOpen(false);
    onDismissed(true);
    reportPapEvent(PAP_Dismiss_DownloadDashUpsell());
  };

  if (!open || !enabled) return null;

  return (
    <ClickOutside
      isActive={open}
      onClickOutside={handleClickOutside}
      isClickThroughPortaled={false}
    >
      <Overlay placement="top" anchorRef={anchor} setWidthSameAsAnchor={true}>
        <div className={styles.container}>
          <Box
            className={styles.titleRow}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Text className={styles.title} isBold>
              {i18n.t('download_dash')}
            </Text>
            <IconButton
              aria-label={i18n.t('aria_dismiss_icon')}
              variant="transparent"
              onClick={handleDismissClick}
            >
              <UIIcon src={CloseLine} className={styles.dismissButton} />
            </IconButton>
          </Box>
          <Box
            className={styles.listContainer}
            display="flex"
            flexDirection="column"
          >
            {installers.map((installer) => (
              <DownloadDashItem {...installer} key={installer.title} />
            ))}
          </Box>
        </div>
      </Overlay>
    </ClickOutside>
  );
};
