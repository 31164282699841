export type DisplayLabelType = 'invitePeople';

export const enum StackPermission {
  READ = 'read',
  WRITE = 'write',
  OWNER = 'owner',
}

export const enum StackAccessLevel {
  INVITED = 'invited',
  PUBLIC = 'public',
  TEAM = 'team',
}

// Note that owner is excluded for sharing options
// (sharing with users or the stack's sharing link)
export type SharingStackPermission =
  | StackPermission.WRITE
  | StackPermission.READ;

export const DEFAULT_STACK_PERMISSION: StackPermission = StackPermission.OWNER;

export interface SharingUserContactKey {
  '.tag': 'user';
  email: string;
}

export interface SharingUserContact extends SharingUserContactKey {
  displayName: string;
  profilePhotoUrl?: string;
}

export interface SharingGroupKey {
  '.tag': 'group';
  groupId: string;
}
export interface SharingGroup extends SharingGroupKey {
  displayName: string;
  memberCount: number;
}

export type SharingMemberKey = SharingUserContactKey | SharingGroupKey;
export type SharingMember = SharingUserContact | SharingGroup;

export interface SharingMemberKeyWithPermission {
  memberKey: SharingMemberKey;
  permission: SharingStackPermission;
}

export type StackMember = SharingMember & {
  permission: StackPermission;
  isSameTeamAsOwner: boolean;
};

export interface StackInfo {
  name: string;
  namespaceId: string;
  teamName?: string;
  latestHash: string;
  permission: StackPermission;
  linkPermission: SharingStackPermission;
  accessLevel: StackAccessLevel;
  members: StackMember[];
}
